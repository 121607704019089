import { FC, MouseEventHandler, useState } from 'react'
import { useTheme } from '@emotion/react'
import { EntityCommentView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useUserPublic } from '@/hooks'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type ParentCommentProps = {
  comment: EntityCommentView
  onUserNameClick: MouseEventHandler<HTMLButtonElement>
  onBackwardClick: MouseEventHandler<HTMLButtonElement>
}

export const ParentComment: FC<ParentCommentProps> = ({
  comment,
  onUserNameClick,
  onBackwardClick,
}) => {
  const theme = useTheme()
  const { message, userId: commentAuthorUserId } = comment

  // comment author
  const { user: commentAuthor, isLoading: isCommentAuthorLoading } = useUserPublic(
    commentAuthorUserId || '',
  )
  const { name: authorName, avatarId: authorAvatarId } = commentAuthor ?? {}
  const authorLoadingPlaceholder = 'Loading...'
  const authorNamePlaceholder = '-'

  const authorHasAvatar = !!authorAvatarId
  const authorAvatar = useFile(authorAvatarId || '')
  const [authorAvatarLoadError, setAuthorAvatarLoadError] = useState(false)

  return (
    <div css={styles(theme).card.main}>
      <div css={styles(theme).card.left.main}>
        <div css={styles(theme).card.left.avatar.main}>
          {authorHasAvatar && !authorAvatarLoadError ? (
            <img
              css={styles(theme).card.left.avatar.image}
              onError={() => setAuthorAvatarLoadError(true)}
              loading="lazy"
              width="30px"
              height="30px"
              src={authorAvatar}
              alt="post author avatar"
            />
          ) : (
            <icons.User css={styles(theme).card.left.avatar.icon} />
          )}
        </div>
        <div css={styles(theme).card.left.nav.main}>
          <button
            css={styles(theme).card.left.nav.button}
            onClick={(e) => onBackwardClick(e)}
            type="button"
            aria-label="navigate to previous comment"
          >
            <icons.ArrowLeft />
          </button>
        </div>
      </div>
      <div css={styles(theme).card.right.main}>
        <div css={styles(theme).card.right.name}>
          <button
            css={styles(theme).card.right.name}
            onClick={(e) => onUserNameClick(e)}
            type="button"
            aria-label="explore author profile"
          >
            <span>
              {isCommentAuthorLoading
                ? authorLoadingPlaceholder
                : authorName || authorNamePlaceholder}
            </span>
          </button>
        </div>
        <div css={styles(theme).card.right.message}>{message}</div>
      </div>
    </div>
  )
}

ParentComment.propTypes = {}

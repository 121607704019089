import { createAsyncThunk } from '@reduxjs/toolkit'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import {
  EntityUserView,
  EntityPostView,
  EntityTrackView,
} from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// config
import { Environment, Platform } from '@/config'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // notification
  notificationSetNotificationTipRetried,
  notificationSetNotificationUnlockRetried,
  // payment
  PaymentOperationType,
  paymentClosePaymentModal,
  // transaction
  transactionResetState,
  // subscription
  subscriptionAddUserSubscription,
  // unlock
  unlockAddPostUnlock,
  unlockAddTrackUnlock,
  // user
  userAppendSubscribedArtist,
  // post
  postAppendUnlockedPost,
  // track
  trackAppendUnlockedTrack,
  // player
  playerAudioSetCurrentTrack,
} from '@/features'

export const paymentCreatePayment = createAsyncThunk<
  { error: string },
  { stripe: Stripe | null; elements: StripeElements | null },
  { state: RootState; rejectValue: undefined }
>('payment/paymentCreatePayment', async (inp, { dispatch, getState }) => {
  // if stripe is not loaded
  if (!inp.stripe || !inp.elements) {
    // return error message in payload
    return {
      error: 'Stripe is failed to load',
    }
  }

  // get client secret, operation type and operation subject
  const { operationType, operationSubject } = getState().payment.paymentModal

  // define redirect url
  const redirectUrl =
    process.env.ENVIRONMENT === Environment.Production
      ? Platform.ProductionURL
      : Platform.StagingURL

  try {
    // confirm stripe payment
    const { error } = await inp.stripe.confirmPayment({
      elements: inp.elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: redirectUrl,
      },
    })

    // if error returned
    if (error) {
      // launch error alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Error,
            message: `Payment failed: ${error.message}`,
          },
        }),
      )

      // return error message in payload
      return {
        error: error.message || '',
      }
    }

    // launch success alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: `Payment succeeded!`,
        },
      }),
    )

    // close payment modal
    dispatch(paymentClosePaymentModal())

    // reset transaction state
    dispatch(transactionResetState())

    // get notification state
    const { notification } = getState().notification

    // update application state based on operation type
    switch (operationType) {
      // on tip operation type
      case PaymentOperationType.Tip:
        // if notification exists - then tip payment was retried from notification view
        if (notification) {
          dispatch(notificationSetNotificationTipRetried())
        }
        break

      // on subscription operation type
      case PaymentOperationType.Subscription:
        // add subscription to user subscriptions
        dispatch(subscriptionAddUserSubscription({ userId: operationSubject?.id || '' }))
        // append user to subscribed artists list
        dispatch(
          userAppendSubscribedArtist({ artist: operationSubject as EntityUserView }),
        )
        break

      // on post unlock operation type
      case PaymentOperationType.PostUnlock:
        // add unlock to post unlocks
        dispatch(unlockAddPostUnlock({ postId: operationSubject?.id || '' }))
        // append post to unlocked posts list
        dispatch(postAppendUnlockedPost({ post: operationSubject as EntityPostView }))

        // TODO:
        // - implement open unlocked post

        // if notification exists - then unlock payment was retried from notification view
        if (notification) {
          dispatch(notificationSetNotificationUnlockRetried())
        }
        break

      // on track unlock operation type
      case PaymentOperationType.TrackUnlock:
        // add unlock to track unlocks
        dispatch(unlockAddTrackUnlock({ trackId: operationSubject?.id || '' }))
        // append track to unlocked tracks list
        dispatch(trackAppendUnlockedTrack({ track: operationSubject as EntityTrackView }))

        // play unlocked track
        dispatch(
          playerAudioSetCurrentTrack({ track: operationSubject as EntityTrackView }),
        )

        // if notification exists - then unlock payment was retried from notification view
        if (notification) {
          dispatch(notificationSetNotificationUnlockRetried())
        }
        break
      default:
    }

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    // return error message in payload
    return {
      error: err.message || '',
    }
  }
})

export const paymentCreateDefaultPayment = createAsyncThunk<
  { error: string },
  { stripe: Stripe | null },
  { state: RootState; rejectValue: undefined }
>('payment/paymentCreateDefaultPayment', async (inp, { dispatch, getState }) => {
  // if stripe is not loaded
  if (!inp.stripe) {
    // return error message in payload
    return {
      error: 'Stripe is failed to load',
    }
  }

  // get client secret, operation type, and operation subject
  const { clientSecret, defaultCustomerPaymentMethod, operationType, operationSubject } =
    getState().payment.paymentModal

  try {
    // confirm stripe payment
    const { error } = await inp.stripe.confirmCardPayment(clientSecret, {
      payment_method: defaultCustomerPaymentMethod?.paymentMethodId,
    })

    // if error returned
    if (error) {
      // launch error alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Error,
            message: `Payment failed: ${error.message}`,
          },
        }),
      )

      // return error message in payload
      return {
        error: error.message || '',
      }
    }

    // launch success alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: `Payment succeeded!`,
        },
      }),
    )

    // close payment modal
    dispatch(paymentClosePaymentModal())

    // reset transaction state
    dispatch(transactionResetState())

    // get notification state
    const { notification } = getState().notification

    // update application state based on operation type
    switch (operationType) {
      // on tip operation type
      case PaymentOperationType.Tip:
        // if notification exists - then tip payment was retried from notification view
        if (notification) {
          dispatch(notificationSetNotificationTipRetried())
        }
        break

      // on subscription operation type
      case PaymentOperationType.Subscription:
        // add subscription to user subscriptions
        dispatch(subscriptionAddUserSubscription({ userId: operationSubject?.id || '' }))
        // append user to subscribed artists list
        dispatch(
          userAppendSubscribedArtist({ artist: operationSubject as EntityUserView }),
        )
        break

      // on post unlock operation type
      case PaymentOperationType.PostUnlock:
        // add unlock to post unlocks
        dispatch(unlockAddPostUnlock({ postId: operationSubject?.id || '' }))
        // append post to unlocked posts list
        dispatch(postAppendUnlockedPost({ post: operationSubject as EntityPostView }))

        // TODO:
        // - implement open unlocked post

        // if notification exists - then unlock payment was retried from notification view
        if (notification) {
          dispatch(notificationSetNotificationUnlockRetried())
        }
        break

      // on track unlock operation type
      case PaymentOperationType.TrackUnlock:
        // add unlock to track unlocks
        dispatch(unlockAddTrackUnlock({ trackId: operationSubject?.id || '' }))
        // append track to unlocked tracks list
        dispatch(trackAppendUnlockedTrack({ track: operationSubject as EntityTrackView }))

        // play unlocked track
        dispatch(
          playerAudioSetCurrentTrack({ track: operationSubject as EntityTrackView }),
        )

        // if notification exists - then unlock payment was retried from notification view
        if (notification) {
          dispatch(notificationSetNotificationUnlockRetried())
        }
        break

      default:
    }

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    // return error message in payload
    return {
      error: err.message || '',
    }
  }
})

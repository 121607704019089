import { FC, ReactNode } from 'react'
import { SerializedStyles, css } from '@emotion/react'

// styles
import { styles } from './styles'

type ContentContainerProps = {
  children: ReactNode

  // css override
  overrideCss?: {
    main: SerializedStyles
  }
}

export const ContentContainer: FC<ContentContainerProps> = ({
  children,
  overrideCss = { main: css`` },
}) => <div css={[styles().container.main, overrideCss?.main]}>{children}</div>

ContentContainer.propTypes = {}

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type InputDescriptionProps = {
  description: string
}

export const InputDescription: FC<InputDescriptionProps> = ({ description }) => {
  const theme = useTheme()
  return <div css={styles(theme).option.main}>{description}</div>
}

InputDescription.propTypes = {}

import { FC, memo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityTrackVisibility } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // track
  TrackTile,
  TrackTileLocked,
  trackSelectArtistExclusiveTracks,
  trackSelectArtistExclusiveTracksCount,
  trackSelectArtistExclusiveTracksLength,
  trackSelectArtistExclusiveTracksFetched,
  trackGetArtistExclusiveTracks,
  trackUtilGetTrackTileSkeletonSections,
  // player
  playerAudioSetCurrentTrack,
  // subscription
  subscriptionSelectUserSubscriptions,
  // unlock
  unlockOpenUnlockTrackModal,
  unlockSelectTrackUnlocks,
} from '@/features'
// components
import {
  ContentLayoutVirtualized,
  ContentPlaceholder,
  SkeletonVertical,
} from '@/components'
// icons
import { icons } from '@/assets'
// styles
import { contentLayout } from '@/styles'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const trackUnlocks = useAppSelector(unlockSelectTrackUnlocks)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const tracks = useAppSelector(trackSelectArtistExclusiveTracks)
  const track = tracks[index]

  // if track not fetched
  if (!track) {
    return (
      <div style={style}>
        <SkeletonVertical
          containerWidth="180px"
          sections={trackUtilGetTrackTileSkeletonSections('track-list-user-exclusive')}
        />
      </div>
    )
  }

  const { id: currentUserId } = profile ?? {}
  const { id: trackId, userId: trackAuthorUserId, visibility } = track

  const isContentLocked = visibility === EntityTrackVisibility.Subscribers
  const isCurrentUserContentAuthor = currentUserId === trackAuthorUserId
  const isCurrentUserUnlockedContent = trackUnlocks[trackId || '']
  const isCurrentUserSubscribedToContentAuthor =
    userSubscriptions[trackAuthorUserId || '']

  const renderLocked =
    isContentLocked &&
    !isCurrentUserContentAuthor &&
    !isCurrentUserUnlockedContent &&
    !isCurrentUserSubscribedToContentAuthor

  // if content is locked
  if (renderLocked) {
    return (
      <div css={styles().tracks.main} style={style}>
        <TrackTileLocked
          track={track}
          onClick={() => dispatch(unlockOpenUnlockTrackModal({ track }))}
          onAuthorNameClick={() => navigate(`${Path.User}/${trackAuthorUserId}`)}
        />
      </div>
    )
  }

  return (
    <div css={styles().tracks.main} style={style}>
      <TrackTile
        track={track}
        onClick={() => dispatch(playerAudioSetCurrentTrack({ track }))}
        onAuthorNameClick={() => navigate(`${Path.User}/${trackAuthorUserId}`)}
      />
    </div>
  )
})

type TrackListUserExclusiveProps = {}

export const TrackListUserExclusive: FC<TrackListUserExclusiveProps> = () => {
  const { userId } = useParams()

  const dispatch = useAppDispatch()
  const artistExclusiveTracks = useAppSelector(trackSelectArtistExclusiveTracks)
  const artistExclusiveTracksCount = useAppSelector(trackSelectArtistExclusiveTracksCount)
  const artistExclusiveTracksLength = useAppSelector(
    trackSelectArtistExclusiveTracksLength,
  )
  const artistExclusiveTracksFetched = useAppSelector(
    trackSelectArtistExclusiveTracksFetched,
  )

  const trackBlockWidth = 180
  const trackBlockMarginRight = 20
  const itemWidth = trackBlockWidth + trackBlockMarginRight

  const trackBlockHeight = 230
  const trackBlockMarginBottom = 0
  const itemHeight = trackBlockHeight + trackBlockMarginBottom

  const defaultItemsCount = 1000
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!artistExclusiveTracks[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(
      trackGetArtistExclusiveTracks({
        artistId: userId,
        limit: itemsToFetch,
        offset: startIndex,
      }),
    )
  }

  // if exclusive tracks are fetched but length is 0
  if (artistExclusiveTracksFetched && artistExclusiveTracksLength === 0) {
    // return place holder
    return (
      <ContentLayoutVirtualized
        title="Exclusive"
        icon={icons.Lock}
        overrideCss={contentLayout.user}
        itemWidth={itemWidth}
        itemsCount={artistExclusiveTracksCount}
      >
        <ContentPlaceholder
          icon={icons.MusicNotes}
          height="230px"
          title="Couldn't find any exclusive tracks"
        />
      </ContentLayoutVirtualized>
    )
  }

  return (
    <ContentLayoutVirtualized
      title="Exclusive"
      icon={icons.Lock}
      overrideCss={contentLayout.user}
      itemWidth={itemWidth}
      itemsCount={artistExclusiveTracksCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={artistExclusiveTracksCount || defaultItemsCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={artistExclusiveTracksCount || defaultItemsCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

TrackListUserExclusive.propTypes = {}

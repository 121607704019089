import { FC, useState } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { authSelectLoading, authLoginWithEmailAndPassword } from '@/features'
// utils
import { validateEmail } from '@/utils'
// components
import { Loader, ButtonLarge } from '@/components'

// components
import { FormInput } from './components'
// styles
import { styles } from './LoginForm.styles'

type LogInFormProps = {}

export const LoginForm: FC<LogInFormProps> = () => {
  const dispatch = useAppDispatch()
  const authLoading = useAppSelector(authSelectLoading)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const [password, setPassword] = useState('')

  const submitDisabled =
    password.length < 6 || email.length < 4 || emailError !== '' || authLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(authLoginWithEmailAndPassword({ email, password }))
        }}
      >
        <div css={styles().form.content.email}>
          <FormInput
            name="email"
            label="email"
            type="email"
            placeholder="Enter your email"
            value={email}
            error={emailError.length > 0}
            errorMessage={emailError}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            onBlur={() => setEmailError(validateEmail(email))}
            onFocus={() => setEmailError('')}
          />
        </div>
        <div css={styles().form.content.password}>
          <FormInput
            name="password"
            label="password"
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="log in"
            disabled={submitDisabled}
            appearance="primary"
          >
            {authLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Log in'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

LoginForm.propTypes = {}

import { FC, ElementType } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type ModalCardTitleProps = {
  text: string
  icon?: ElementType
}

export const ModalCardTitle: FC<ModalCardTitleProps> = ({
  text,
  icon: Icon = undefined,
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).title.main}>
      {Icon && (
        <div css={styles(theme).title.icon}>
          <Icon />
        </div>
      )}
      <div css={styles(theme).title.text}>{text}</div>
    </div>
  )
}

ModalCardTitle.propTypes = {}

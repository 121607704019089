import { css } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = () => ({
  list: {
    main: css`
      ${helpers.app.screen.width.full};
      display: flex;
      flex-direction: column;

      overflow: hidden;
      max-width: 800px;

      margin-bottom: 50px;
    `,

    title: css`
      margin-bottom: 16px;
    `,

    comment: css`
      width: 100%;

      margin-bottom: 16px;
    `,

    content: css`
      width: 100%;

      margin-bottom: 16px;
    `,

    form: css`
      width: 100%;
    `,
  },

  comments: {
    main: css``,
  },
})

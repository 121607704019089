import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type ContentHeaderProps = {
  text: string

  placement?: 'content' | 'default'
}

export const ContentHeader: FC<ContentHeaderProps> = ({
  text,
  placement = 'default',
}) => {
  const theme = useTheme()

  return (
    <h2
      css={[
        styles(theme).title.text,
        placement === 'default' && styles(theme).title.placement.default,
        placement === 'content' && styles(theme).title.placement.content,
      ]}
    >
      {text}
    </h2>
  )
}

ContentHeader.propTypes = {}

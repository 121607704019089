import { FC, useState } from 'react'
import { useTheme } from '@emotion/react'

// genres
import { musicGenres, maxUserPreferredGenresCount } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  authSelectProfile,
  onboardingSelectLoading,
  onboardingUpdateGenres,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// components
import { Genre } from './components'
// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const onboardingLoading = useAppSelector(onboardingSelectLoading)
  const profile = useAppSelector(authSelectProfile)

  const [selectedGenres, setSelectedGenres] = useState(profile?.preferredGenres || [])

  const submitDisabled =
    selectedGenres.length <= 0 ||
    selectedGenres.length > maxUserPreferredGenresCount ||
    onboardingLoading

  const onGenreClick = (selected: boolean, genre: string) => {
    if (selected) {
      setSelectedGenres((state) => [...state.filter((el) => el !== genre)])
    }

    if (!selected && selectedGenres.length < maxUserPreferredGenresCount) {
      setSelectedGenres((state) => [...state, genre])
    }
  }

  return (
    <div css={styles(theme).form.main}>
      <div css={styles(theme).form.genres.main}>
        <div css={styles(theme).form.genres.rows.main}>
          {musicGenres.map((genre) => {
            const selected = selectedGenres.includes(genre)
            return (
              <Genre
                key={`onboarding-genres-${genre}`}
                genre={genre}
                onClick={() => onGenreClick(selected, genre)}
                selected={selected}
              />
            )
          })}
        </div>

        <div css={styles(theme).form.genres.selected.main}>
          Selected{' '}
          <span css={styles(theme).form.genres.selected.num}>
            {selectedGenres.length}
          </span>{' '}
          out of{' '}
          <span css={styles(theme).form.genres.selected.num}>
            {maxUserPreferredGenresCount}
          </span>
        </div>
      </div>

      <form
        css={styles(theme).form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(onboardingUpdateGenres({ preferredGenres: selectedGenres }))
        }}
      >
        <div css={styles(theme).form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="select genres"
            disabled={submitDisabled}
            appearance="primary"
          >
            {onboardingLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Select'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

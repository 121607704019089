import { FC, useEffect } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // onboarding
  onboardingSetPrevStage,
  // track
  TrackListOnboardingUploaded,
  trackSelectTrackCreateLoading,
  trackSelectUploadedTracksLength,
  trackGetUploadedTracks,
} from '@/features'
// icons
import { icons } from '@/assets'
// components
import { ButtonRound, Error, Subtitle } from '@/components'

// components
import { Divider, Form } from './components'
// styles
import { styles } from './styles'

type UploadTracksProps = {}

export const UploadTracks: FC<UploadTracksProps> = () => {
  const dispatch = useAppDispatch()
  const trackCreateLoading = useAppSelector(trackSelectTrackCreateLoading)
  const uploadedTracksLength = useAppSelector(trackSelectUploadedTracksLength)
  const onboardingError = ''
  const trackCreateError = ''

  useEffect(() => {
    // fetch profile tracks
    dispatch(trackGetUploadedTracks({}))
  }, [dispatch])

  return (
    <div css={styles().stage.main}>
      <div css={styles().stage.backward.button}>
        <ButtonRound
          aria-label="set previous onboarding stage"
          appearance="secondary"
          onClick={() => dispatch(onboardingSetPrevStage())}
        >
          <icons.ArrowLeft css={styles().stage.backward.icon} />
        </ButtonRound>
      </div>

      <div css={styles().stage.content.main}>
        <div css={styles().stage.content.title}>
          <Subtitle text="Upload your first track to RYDDM!" />
        </div>

        {!trackCreateLoading && uploadedTracksLength > 0 && (
          <div css={styles().stage.content.tracks}>
            <TrackListOnboardingUploaded />
            <Divider />
          </div>
        )}

        <div css={styles().stage.content.form}>
          <Form />
        </div>

        <div css={styles().stage.content.error}>
          <Error error={onboardingError || trackCreateError} />
        </div>
      </div>
    </div>
  )
}

UploadTracks.propTypes = {}

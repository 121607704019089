import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  EntityNotificationEvent,
  EntitySubscriptionView,
} from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// icons
import { icons } from '@/assets'

// components
import { Control } from './components'

type SubscriptionControlsProps = {
  event: EntityNotificationEvent
  subscription: EntitySubscriptionView
  isDisabled: boolean
}

export const SubscriptionControls: FC<SubscriptionControlsProps> = ({
  event,
  subscription,
  isDisabled,
}) => {
  const navigate = useNavigate()

  // if notification event is subscription created
  if (event === EntityNotificationEvent.AppSubscriptionCreated) {
    return <div />
  }

  // if notification event is subscription payment failed
  if (event === EntityNotificationEvent.AppSubscriptionPaymentFailed) {
    return (
      <Control
        appearance="neutral"
        label="navigate to target user page"
        icon={icons.UserCircle}
        text="View user"
        disabled={isDisabled}
        onClick={() => navigate(`${Path.User}/${subscription?.targetUserId}`)}
      />
    )
  }

  // if notification event is subscription payment succeeded
  if (event === EntityNotificationEvent.AppSubscriptionPaymentSucceeded) {
    return (
      <Control
        appearance="neutral"
        label="navigate to target user page"
        icon={icons.UserCircle}
        text="View user"
        disabled={isDisabled}
        onClick={() => navigate(`${Path.User}/${subscription?.targetUserId}`)}
      />
    )
  }

  return <div />
}

SubscriptionControls.propTypes = {}

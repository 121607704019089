import { ReactNode } from 'react'

type RenderArtistActionsInput = {
  isCurrentUserFollowingArtist: boolean
  isCurrentUserSubscribedToArtist: boolean
  isCurrentUserSubscribedToArtistAtPeriodEnd: boolean
  isArtistCanReceivePayments: boolean

  createUserFollowNode: ReactNode
  deleteUserFollowNode: ReactNode
  createSubscriptionNode: ReactNode
  renewSubscriptionNode: ReactNode
  cancelSubscriptionNode: ReactNode
  defaultNode: ReactNode
}

export const userUtilRenderArtistActions = ({
  isCurrentUserFollowingArtist,
  isCurrentUserSubscribedToArtist,
  isCurrentUserSubscribedToArtistAtPeriodEnd,
  isArtistCanReceivePayments,

  createUserFollowNode,
  deleteUserFollowNode,
  createSubscriptionNode,
  renewSubscriptionNode,
  cancelSubscriptionNode,
  defaultNode,
}: RenderArtistActionsInput) => {
  // if artist is not followed
  if (!isCurrentUserFollowingArtist) {
    return createUserFollowNode
  }

  // if user is subscribed to the artist and not at period end
  if (isCurrentUserSubscribedToArtist && !isCurrentUserSubscribedToArtistAtPeriodEnd) {
    return cancelSubscriptionNode
  }

  // if artist is followed
  if (isCurrentUserFollowingArtist) {
    // if artist can receive payments
    if (isArtistCanReceivePayments) {
      if (isCurrentUserSubscribedToArtistAtPeriodEnd) {
        return renewSubscriptionNode
      }

      return createSubscriptionNode
    }

    return deleteUserFollowNode
  }

  // default return is empty
  return defaultNode
}

export const userUtilGetArtistCardSkeletonSections = (sectionName: string) => [
  {
    name: `${sectionName}-avatar`,
    width: '140px',
    height: '100px',
    isStatic: true,
    isDivider: false,
  },
  {
    name: `${sectionName}-divider-1`,
    width: '140px',
    height: '8px',
    isStatic: true,
    isDivider: true,
  },
  {
    name: `${sectionName}-name`,
    width: '140px',
    height: '40px',
    isStatic: true,
    isDivider: false,
  },
  {
    name: `${sectionName}-divider-2`,
    width: '140px',
    height: '8px',
    isStatic: true,
    isDivider: true,
  },
  {
    name: `${sectionName}-username`,
    width: '140px',
    height: '62px',
    isStatic: true,
    isDivider: false,
  },
]

import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  cover: {
    main: css`
      position: relative;
      width: 136px;
      height: 136px;

      border-radius: 4px;
      overflow: hidden;
    `,

    button: css`
      ${helpers.resetDefaults};

      width: 100%;
      height: 100%;

      cursor: pointer;
    `,

    image: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;

        width: 136px;
        height: 136px;

        background-color: ${theme.colors.secondary.darker};

        overflow: hidden;
      `,
      content: css`
        ${helpers.image};

        border-radius: 4px;
      `,
      icon: css`
        width: 40px;
        height: 40px;

        & > path {
          fill: ${theme.colors.secondary.normal};
        }
      `,
    },
  },
})

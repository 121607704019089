import { Suspense, ReactNode } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { ReduxProvider } from './ReduxProvider'
import { ThemeProvider } from './ThemeProvider'
import { AuthProvider } from './AuthProvider'

// components
import { ErrorFallback, SuspenseMainFallback } from '@/components'

type AppProviderProps = {
  children: ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => (
  <Suspense fallback={<SuspenseMainFallback />}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ReduxProvider>
        <ThemeProvider>
          <AuthProvider>
            <Router>{children}</Router>
          </AuthProvider>
        </ThemeProvider>
      </ReduxProvider>
    </ErrorBoundary>
  </Suspense>
)

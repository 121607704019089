import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type SubtitleProps = {
  text: string
  accent?: { accentStart: number; accentEnd: number }
  link?: { linkStart: number; linkEnd: number; href: string }
}

export const Subtitle: FC<SubtitleProps> = ({
  text,
  accent = undefined,
  link = undefined,
}) => {
  const theme = useTheme()

  const renderTitle = () => {
    if (accent) {
      const { accentStart, accentEnd } = accent
      return (
        <h2 css={styles(theme).title.text}>
          {text.slice(0, accentStart)}
          <span css={styles(theme).title.accent}>
            {text.slice(accentStart, accentEnd)}
          </span>
          {text.slice(accentEnd, text.length)}
        </h2>
      )
    }

    if (link) {
      const { linkStart, linkEnd, href } = link
      return (
        <h2 css={styles(theme).title.text}>
          {text.slice(0, linkStart)}
          <a
            css={styles(theme).title.link}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text.slice(linkStart, linkEnd)}
          </a>
          {text.slice(linkEnd, text.length)}
        </h2>
      )
    }

    return <h2 css={styles(theme).title.text}>{text}</h2>
  }

  return <div css={styles(theme).title.main}>{renderTitle()}</div>
}

Subtitle.propTypes = {}

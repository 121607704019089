import { FC } from 'react'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppDispatch, useTrack } from '@/hooks'
// features
import {
  // player
  playerAudioSetCurrentTrack,
} from '@/features'
// icons
import { icons } from '@/assets'

// components
import { Control } from './components'

type TrackControlsProps = {
  trackId: string
}

export const TrackControls: FC<TrackControlsProps> = ({ trackId }) => {
  const dispatch = useAppDispatch()

  // track
  const { track, isLoading } = useTrack(trackId)
  const isDisabled = isLoading || !track

  return (
    <Control
      appearance="neutral"
      label="play track"
      icon={icons.PlayCircleBold}
      text="Play track"
      disabled={isDisabled}
      onClick={() =>
        dispatch(playerAudioSetCurrentTrack({ track: track as EntityTrackView }))
      }
    />
  )
}

TrackControls.propTypes = {}

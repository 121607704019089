import { FC, ReactNode, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type ModalLayoutProps = {
  background?: 'solid' | 'opaque'
  children: ReactNode

  onOutsideClick: MouseEventHandler<HTMLDivElement>
}

export const ModalLayout: FC<ModalLayoutProps> = ({
  background = 'solid',
  children,
  onOutsideClick,
}) => {
  const theme = useTheme()

  return (
    <div
      css={[
        styles(theme).modal.main,
        background === 'solid' && styles(theme).modal.solid,
        background === 'opaque' && styles(theme).modal.opaque,
      ]}
      onClick={(e) => onOutsideClick(e)}
      aria-hidden="true"
    >
      {children}
    </div>
  )
}

ModalLayout.propTypes = {}

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { reportSelectReportUserModalOpen, reportCloseReportUserModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { ReportUserCard } from './components'
// styles
import { styles } from './styles'

type ReportUserModalProps = {}

export const ReportUserModal: FC<ReportUserModalProps> = () => {
  const dispatch = useAppDispatch()
  const reportUserModalOpen = useAppSelector(reportSelectReportUserModalOpen)

  return (
    <div css={styles().modal.main}>
      {reportUserModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(reportCloseReportUserModal())}
        >
          <div css={styles().modal.content}>
            <ReportUserCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

ReportUserModal.propTypes = {}

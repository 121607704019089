import { css, Theme } from '@emotion/react'
import { helpers, fonts, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  button: {
    main: css`
      ${helpers.resetDefaults};

      display: flex;
      align-items: center;

      font-family: ${fonts.primary};
      font-size: ${typography.p};
      font-weight: bold;
      white-space: nowrap;

      border-radius: 20px;
      padding: 12px 36px;

      height: 44px;
      width: 100%;
      overflow: hidden;

      transition: color 0.2s, background-color 0.2s, border 0.2s;

      min-width: 0;

      & svg > path {
        transition: fill 0.2s, color 0.2s;
      }
    `,
    primary: css`
      cursor: pointer;

      color: ${theme.colors.primary.normal};
      background-color: ${theme.colors.accent.normal};
      border: 1px solid ${theme.colors.accent.normal};

      & svg > path {
        fill: ${theme.colors.primary.normal};
      }

      &:hover {
        color: ${theme.colors.primary.normal};
        border: 1px solid ${theme.colors.accent.normal};
        background-color: ${theme.colors.accent.normalHover};

        & svg > path {
          fill: ${theme.colors.primary.normal};
        }
      }
    `,
    secondary: css`
      cursor: pointer;

      color: ${theme.colors.secondary.normal};
      background-color: ${theme.colors.primary.normal};
      border: 1px solid ${theme.colors.secondary.normal};

      & svg > path {
        fill: ${theme.colors.secondary.normal};
      }

      &:hover {
        color: ${theme.colors.accent.normal};
        border: 1px solid ${theme.colors.accent.normal};
        background-color: ${theme.colors.primary.normal};

        & svg > path {
          fill: ${theme.colors.accent.normal};
        }
      }
    `,
    disabled: css`
      cursor: not-allowed;

      color: ${theme.colors.secondary.dark};
      background-color: ${theme.colors.primary.normal};
      border: 1px solid ${theme.colors.secondary.dark};

      & svg > path {
        fill: ${theme.colors.secondary.dark};
      }

      &:hover {
        color: ${theme.colors.secondary.dark};
        border: 1px solid ${theme.colors.secondary.dark};
        background-color: ${theme.colors.primary.normal};

        & svg > path {
          fill: ${theme.colors.secondary.dark};
        }
      }
    `,

    content: {
      main: css`
        display: flex;
        justify-content: center;

        width: 100%;
        min-width: 0;
      `,
      children: css`
        ${helpers.textOverflowEllipsis};
      `,
    },
  },
})

import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch, useSubscriptionPrice } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // user
  ArtistCardProduct,
  // subscription
  subscriptionSelectSubscriptionCreateSubscriptionLoading,
  subscriptionSelectSubscriptionCreateModalArtist,
  subscriptionCreateSubscription,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type SubscriptionCreateFormProps = {}

export const SubscriptionCreateForm: FC<SubscriptionCreateFormProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const subscriptionCreateLoading = useAppSelector(
    subscriptionSelectSubscriptionCreateSubscriptionLoading,
  )
  const profile = useAppSelector(authSelectProfile)
  const modalArtist = useAppSelector(subscriptionSelectSubscriptionCreateModalArtist)

  const { id: profileUserId } = profile ?? {}
  const { id: artistUserId } = modalArtist ?? {}

  const isCurrentUser = profileUserId === artistUserId
  const submitDisabled = isCurrentUser || subscriptionCreateLoading

  // subscription price
  const {
    subscriptionPrice: artistSubscriptionPrice,
    isLoading: isArtistSubscriptionPriceLoading,
  } = useSubscriptionPrice(artistUserId || '')
  const artistSubscriptionPriceLoadingPlaceholder = 'Loading...'

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(subscriptionCreateSubscription())
        }}
      >
        {/* artist product */}
        <div css={styles().form.content.product}>
          <ArtistCardProduct
            artist={modalArtist || {}}
            onNameClick={() => navigate(`${Path.User}/${artistUserId}`)}
            onUsernameClick={() => navigate(`${Path.User}/${artistUserId}`)}
          />
        </div>

        {/* submit */}
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="subscribe"
            disabled={submitDisabled}
            appearance="primary"
          >
            {subscriptionCreateLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Subscribe for $${
                isArtistSubscriptionPriceLoading
                  ? artistSubscriptionPriceLoadingPlaceholder
                  : artistSubscriptionPrice && artistSubscriptionPrice / 100
              }`
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

SubscriptionCreateForm.propTypes = {}

import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  input: {
    main: css`
      display: flex;
      flex-direction: column;
    `,

    label: css`
      display: none;
    `,

    range: {
      main: css`
        display: flex;
        justify-content: space-between;

        font-size: ${typography.h6};
      `,
      min: {
        inactive: css`
          color: ${theme.colors.secondary.dark};
        `,
        active: css`
          color: ${theme.colors.accent.normal};
          font-weight: bold;
        `,
      },
      max: {
        inactive: css`
          color: ${theme.colors.secondary.dark};
        `,
        active: css`
          color: ${theme.colors.accent.normal};
          font-weight: bold;
        `,
      },
    },

    data: {
      main: css``,

      input: css`
        ${helpers.resetDefaults};

        width: 100%;
        height: 28px;

        // reset default style
        appearance: none;
        -webkit-appearance: none;
        background: transparent;
        cursor: pointer;

        // track style
        &::-webkit-slider-runnable-track {
          background: ${theme.colors.accent.normal};
          height: 4px;
        }
        &::-moz-range-track {
          background: ${theme.colors.accent.normal};
          height: 4px;
        }

        // thumb style
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;

          // center thumb
          // (track hight in px / 2) - (thumb height in px / 2)
          margin-top: -6px;

          height: 16px;
          width: 16px;
          border-radius: 50%;
          background-color: ${theme.colors.accent.normal};
          border: 2px solid ${theme.colors.primary.normal};
        }
        &::-moz-range-thumb {
          height: 16px;
          width: 16px;
          border-radius: 50%;
          background-color: ${theme.colors.accent.normal};
          border: 2px solid ${theme.colors.primary.normal};
        }
      `,
    },
  },
})

import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  signup: {
    main: css`
      display: flex;
      justify-content: center;
    `,
    button: css`
      width: 180px;
      flex-shrink: 0;

      ${mq.mobile(css`
        width: 100%;
      `)}
    `,
  },
})

import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityPostVisibility } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  PostCardUploaded,
  postSelectPostDeleteLoading,
  postSelectUploadedPosts,
  postSelectUploadedPostsCount,
  postSelectUploadedPostsLength,
  postSelectUploadedPostsFetched,
  postGetUploadedPosts,
  postOpenPostDeleteModal,
  postUtilGetPostCardSkeletonSections,
} from '@/features'
// components
import {
  ContentLayoutVirtualized,
  ContentPlaceholder,
  SkeletonVertical,
} from '@/components'
// icons
import { icons } from '@/assets'
// styles
import { contentLayout } from '@/styles'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const postDeleteLoading = useAppSelector(postSelectPostDeleteLoading)
  const posts = useAppSelector(postSelectUploadedPosts)
  const post = posts[index]

  // if post not fetched
  if (!post) {
    return (
      <div style={style}>
        <SkeletonVertical
          containerWidth="180px"
          sections={postUtilGetPostCardSkeletonSections('post-list-studio-uploaded')}
        />
      </div>
    )
  }

  const { id: postId, visibility } = post

  // if post is locked or loading - artist should not be able to delete it
  const deleteDisabled =
    visibility === EntityPostVisibility.Subscribers || postDeleteLoading

  return (
    <div css={styles().posts.main} style={style} key={`uploaded-post-${postId}`}>
      <PostCardUploaded
        post={post}
        deleteDisabled={deleteDisabled}
        onClick={() => navigate(`${Path.Post}/${postId}`)}
        onEditClick={() => navigate(`${Path.Studio}${Path.Post}/${postId}`)}
        onDeleteClick={() => dispatch(postOpenPostDeleteModal({ post }))}
      />
    </div>
  )
})

type PostListStudioUploadedProps = {}

export const PostListStudioUploaded: FC<PostListStudioUploadedProps> = () => {
  const dispatch = useAppDispatch()
  const uploadedPosts = useAppSelector(postSelectUploadedPosts)
  const uploadedPostsCount = useAppSelector(postSelectUploadedPostsCount)
  const uploadedPostsLength = useAppSelector(postSelectUploadedPostsLength)
  const uploadedPostsFetched = useAppSelector(postSelectUploadedPostsFetched)

  const postCardWidth = 180
  const postCardMarginRight = 20
  const itemWidth = postCardWidth + postCardMarginRight

  const postCardHeight = 294
  const postCardMarginBottom = 0
  const itemHeight = postCardHeight + postCardMarginBottom

  const defaultContentCount = 1000
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!uploadedPosts[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(postGetUploadedPosts({ limit: itemsToFetch, offset: startIndex }))
  }

  // if uploaded posts are fetched but length is 0
  if (uploadedPostsFetched && uploadedPostsLength === 0) {
    // return place holder
    return (
      <ContentLayoutVirtualized
        title="Posts"
        overrideCss={contentLayout.studio}
        itemWidth={itemWidth}
        itemsCount={uploadedPostsCount}
      >
        <ContentPlaceholder
          icon={icons.Posts}
          height="294px"
          title="Couldn't find any uploaded posts"
        />
      </ContentLayoutVirtualized>
    )
  }

  return (
    <ContentLayoutVirtualized
      title="Posts"
      overrideCss={contentLayout.studio}
      itemWidth={itemWidth}
      itemsCount={uploadedPostsCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={uploadedPostsCount || defaultContentCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={uploadedPostsCount || defaultContentCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

PostListStudioUploaded.propTypes = {}

import { FC, useState, ChangeEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { playerAudioSelectOpen, playerAudioToggleOpen } from '@/features'

// icons
import { icons as iconsLocal } from './assets'
// styles
import { styles } from './styles'

type NavigationProps = {
  volume: number
  onVolumeChange: ChangeEventHandler<HTMLInputElement>
}

export const Navigation: FC<NavigationProps> = ({ volume, onVolumeChange }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isAudioPlayerOpen = useAppSelector(playerAudioSelectOpen)

  const [isVolumeOpen, setIsVolumeOpen] = useState(false)

  const label = 'Audio Player Volume'
  const name = 'audio-player-progress'

  const renderVolumeIcon = () => {
    // if volume is muted
    if (volume === 0) {
      // render muted icon
      return <iconsLocal.AudioVolumeMute />
    }

    // if volume is low
    if (volume <= 0.5) {
      // render min icon
      return <iconsLocal.AudioVolumeMin />
    }

    // else render max icon
    return <iconsLocal.AudioVolumeMax />
  }

  return (
    <div css={styles(theme).nav.main}>
      {/* volume */}
      <div
        css={styles(theme).nav.volume.main}
        onMouseLeave={() => setIsVolumeOpen(false)}
      >
        {isVolumeOpen && (
          <div css={styles(theme).nav.volume.control.main}>
            <label css={styles(theme).nav.volume.control.label} htmlFor={name}>
              {label}
            </label>
            <input
              css={styles(theme).nav.volume.control.input(volume * 100)}
              aria-label="audio player volume"
              id={name}
              name={name}
              type="range"
              min={0}
              step={0.05}
              max={1}
              value={volume}
              onChange={(e) => onVolumeChange(e)}
            />
          </div>
        )}

        <button
          css={[
            styles(theme).nav.volume.button.shared,
            isVolumeOpen
              ? styles(theme).nav.volume.button.open
              : styles(theme).nav.volume.button.closed,
          ]}
          onClick={() => setIsVolumeOpen((state) => !state)}
          type="button"
          aria-label="open audio volume control"
        >
          {renderVolumeIcon()}
        </button>
      </div>

      {/* open */}
      <div css={styles(theme).nav.open.main}>
        <button
          css={[
            styles(theme).nav.open.button.shared,
            isAudioPlayerOpen
              ? styles(theme).nav.open.button.open
              : styles(theme).nav.open.button.closed,
          ]}
          onClick={() => dispatch(playerAudioToggleOpen())}
          type="button"
          aria-label="open audio player"
        >
          <iconsLocal.AudioNav />
        </button>
      </div>
    </div>
  )
}

Navigation.propTypes = {}

import { css, Theme } from '@emotion/react'
import { helpers, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 60px;

      cursor: pointer;
    `,

    data: {
      main: css`
        display: flex;
        align-items: center;

        height: 40px;
      `,
      icon: css`
        display: flex;
        flex: 0 0 40px;

        margin-right: 10px;

        ${mq.tablet(css`
          display: none;
        `)}

        & > svg {
          height: 22px;
          & > path {
            fill: ${theme.colors.secondary.normal};
          }
        }
      `,
      identifier: css`
        ${helpers.textOverflowEllipsis};

        flex: 1 0 100px;
        margin-right: 10px;

        ${mq.mobile(css`
          flex: 1 0 80px;
        `)}
      `,
      type: css`
        ${helpers.textOverflowEllipsis};

        flex: 1 0 60px;
        max-width: 100px;
        margin-right: 10px;

        ${mq.tablet(css`
          display: none;
        `)}
      `,
      status: css`
        ${helpers.textOverflowEllipsis};

        flex: 1 0 60px;
        max-width: 100px;
        margin-right: 10px;

        ${mq.mobile(css`
          flex: 1 0 40px;
          max-width: 60px;
        `)}
      `,
      date: css`
        ${helpers.textOverflowEllipsis};

        flex: 1 0 60px;
        max-width: 100px;

        ${mq.mobile(css`
          flex: 1 0 40px;
          max-width: 60px;
        `)}
      `,
    },

    divider: css`
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.secondary.dark};
    `,
  },
})

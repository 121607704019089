import { FC, useState } from 'react'
import { useTheme } from '@emotion/react'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useUserPublic } from '@/hooks'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TrackProps = {
  track: EntityTrackView
}

export const Track: FC<TrackProps> = ({ track }) => {
  const theme = useTheme()
  const [coverLoadError, setCoverLoadError] = useState(false)
  const { userId, coverId, name } = track

  const trackHasCover = !!coverId
  const coverSource = useFile(coverId || '')

  // track author
  const { user: postAuthor, isLoading: isPostAuthorLoading } = useUserPublic(userId || '')
  const { name: authorName } = postAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'

  return (
    <div css={styles(theme).track.main}>
      <div css={styles(theme).track.cover.main}>
        {trackHasCover && !coverLoadError ? (
          <img
            css={styles(theme).track.cover.image}
            onError={() => setCoverLoadError(true)}
            loading="lazy"
            width="30px"
            height="30px"
            src={coverSource}
            alt="track album cover"
          />
        ) : (
          <icons.MusicNote css={styles(theme).track.cover.icon} />
        )}
      </div>
      <div css={styles(theme).track.description.main}>
        <span css={styles(theme).track.description.name}>{name}</span>
        <span css={styles(theme).track.description.authorName}>
          {isPostAuthorLoading
            ? userLoadingPlaceholder
            : authorName || userNamePlaceholder}
        </span>
      </div>
      <div css={styles(theme).track.play}>
        <icons.PlayCircle />
      </div>
    </div>
  )
}

Track.propTypes = {}

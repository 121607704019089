import { css, Theme } from '@emotion/react'

export const styles = (theme: Theme) => ({
  checkbox: {
    main: css``,

    label: {
      main: css`
        display: flex;

        width: 30px;
        height: 30px;
      `,
      mark: {
        shared: css`
          display: inline-block;
          position: relative;

          width: 30px;
          height: 30px;
          border-radius: 8px;

          cursor: pointer;
        `,
        checked: css`
          background-color: ${theme.colors.accent.normal};
          border: 1px solid ${theme.colors.accent.normal};

          &::after {
            content: '';
            position: absolute;

            left: 50%;
            top: 50%;
            width: 8px;
            height: 14px;
            border: solid ${theme.colors.primary.normal};;
            border-width: 0 3px 3px 0;
            transform: translate(-50%,-58%) rotate(45deg);
        `,
        unchecked: css`
          background-color: ${theme.colors.primary.normal};
          border: 1px solid ${theme.colors.secondary.darkHover};
        `,
      },
      text: css`
        display: none;
      `,
    },

    input: css`
      display: none;
    `,
  },
})

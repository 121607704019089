import { css } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = () => ({
  container: {
    main: css`
      ${helpers.hideScrollbar};

      width: 100%;
      overflow-y: scroll;
    `,
  },
})

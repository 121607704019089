import { css } from '@emotion/react'

export const styles = () => ({
  form: {
    main: css``,
    content: {
      main: css``,
      track: css`
        display: flex;
        justify-content: center;

        margin-bottom: 24px;
      `,
      submit: css``,
    },
  },
})

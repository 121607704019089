import { FC } from 'react'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // track
  TrackTile,
  trackSelectTrackBlockLoading,
  trackSelectTrackBlockModalTrack,
  trackBlock,
  // player
  playerAudioSetCurrentTrack,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type TrackBlockFormProps = {}

export const TrackBlockForm: FC<TrackBlockFormProps> = () => {
  const dispatch = useAppDispatch()
  const trackBlockLoading = useAppSelector(trackSelectTrackBlockLoading)
  const track = useAppSelector(trackSelectTrackBlockModalTrack)

  const submitDisabled = trackBlockLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(trackBlock({ id: track?.id || '' }))
        }}
      >
        <div css={styles().form.content.track}>
          <TrackTile
            track={track || {}}
            onClick={() =>
              dispatch(
                playerAudioSetCurrentTrack({
                  track: track as EntityTrackView,
                }),
              )
            }
            onAuthorNameClick={() => {}}
          />
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="block track"
            disabled={submitDisabled}
            appearance="primary"
          >
            {trackBlockLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Block'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

TrackBlockForm.propTypes = {}

import { FC, ReactNode } from 'react'

// styles
import { styles } from './styles'

type MainLayoutProps = {
  children: ReactNode
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => (
  <main css={styles().layout.main}>{children}</main>
)

MainLayout.propTypes = {}

import { FC, useState } from 'react'

// config
import { maxReportComplaintLength, complaintTypeSelectOptions } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  authSelectProfile,
  reportSelectReportCreateUserReportComplaintLoading,
  reportSelectReportUserModalUser,
  reportCreateUserReportComplaint,
} from '@/features'
// utils
import { validateReportComplaint } from '@/utils'
// components
import { Loader, ButtonLarge, InputArea, InputSelect } from '@/components'

// styles
import { styles } from './styles'

type ReportUserFormProps = {}

export const ReportUserForm: FC<ReportUserFormProps> = () => {
  const dispatch = useAppDispatch()
  const reportCreateUserReportComplaintLoading = useAppSelector(
    reportSelectReportCreateUserReportComplaintLoading,
  )
  const profile = useAppSelector(authSelectProfile)
  const modalUser = useAppSelector(reportSelectReportUserModalUser)

  const [complaintType, setComplaintType] = useState<string>('')
  const [complaint, setComplaint] = useState('')
  const [complaintError, setComplaintError] = useState('')

  const { id: profileUserId } = profile ?? {}
  const { id: currentUserId } = modalUser ?? {}

  const isCurrentUser = profileUserId === currentUserId

  const submitDisabled =
    complaint.length > maxReportComplaintLength ||
    !complaintType ||
    isCurrentUser ||
    reportCreateUserReportComplaintLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(
            reportCreateUserReportComplaint({
              complaintType,
              complaint,
            }),
          )
        }}
      >
        <div css={styles().form.content.type}>
          <InputSelect
            placeholder={{
              id: 'report-user-select-placeholder',
              label: 'Select complaint type',
              value: 'select_complaint_type',
            }}
            options={complaintTypeSelectOptions}
            setValue={(value: string) => setComplaintType(value)}
          />
        </div>
        <div css={styles().form.content.complaint}>
          <InputArea
            name="report-complaint"
            label="report complaint"
            id="report-complaint"
            placeholder="Describe the details of your concern"
            value={complaint}
            maxLength={maxReportComplaintLength}
            error={complaintError.length > 0}
            errorMessage={complaintError}
            rows={6}
            onChange={(e) => setComplaint(e.target.value)}
            onBlur={() => {
              if (complaint !== '') {
                setComplaintError(validateReportComplaint(complaint))
              }
            }}
            onFocus={() => setComplaintError('')}
          />
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="create complaint"
            disabled={submitDisabled}
            appearance="primary"
          >
            {reportCreateUserReportComplaintLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Report`
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

ReportUserForm.propTypes = {}

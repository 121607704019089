import { FC } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type Step = {
  id: string
  description: string
}

interface StepperProps {
  steps: Step[]
  currentStep: number
}

export const Stepper: FC<StepperProps> = ({ steps, currentStep }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).stepper.main}>
      {/* progress */}
      <div css={styles(theme).stepper.progress} />

      {/* steps */}
      <div css={styles(theme).stepper.steps.main}>
        {steps.map(({ id, description }, i) => {
          const checked = currentStep - 1 > i
          const current = currentStep - 1 === i
          const following = !checked && !current

          return (
            <div css={styles(theme).stepper.steps.step.main} key={id}>
              <div css={styles(theme).stepper.steps.step.icon.main}>
                {checked && (
                  <div
                    css={[
                      styles(theme).stepper.steps.step.icon.circle.shared,
                      styles(theme).stepper.steps.step.icon.circle.checked,
                    ]}
                  >
                    <icons.Check css={styles(theme).stepper.steps.step.icon.check} />
                  </div>
                )}

                {current && (
                  <div
                    css={[
                      styles(theme).stepper.steps.step.icon.circle.shared,
                      styles(theme).stepper.steps.step.icon.circle.current,
                    ]}
                  />
                )}

                {following && (
                  <div
                    css={[
                      styles(theme).stepper.steps.step.icon.circle.shared,
                      styles(theme).stepper.steps.step.icon.circle.following,
                    ]}
                  />
                )}
              </div>
              <div css={styles(theme).stepper.steps.step.description}>{description}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

Stepper.propTypes = {}

import { FC, ElementType, MouseEventHandler } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react'
import { Link } from 'react-router-dom'

// styles
import { styles } from './styles'

type NavLinkProps = {
  to: string
  name: string
  active: boolean
  onClick: MouseEventHandler<HTMLAnchorElement>
  icon: ElementType
}

export const NavLink: FC<NavLinkProps> = ({ to, name, active, onClick, icon: Icon }) => {
  const theme = useTheme()

  return (
    <Link
      css={[styles(theme).link.main, active && styles(theme).link.active]}
      to={to}
      onClick={(e) => onClick(e)}
    >
      <div css={styles(theme).link.body}>
        <div css={styles(theme).link.icon}>
          <Icon />
        </div>
        <div css={styles(theme).link.text}>{name}</div>
      </div>
    </Link>
  )
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
}

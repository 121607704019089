import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityNotificationEntityType } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  NotificationCard,
  notificationSelectNotificationGetNotificationsLoading,
  notificationSelectNotificationReadNotificationsLoading,
  notificationSelectNotifications,
  notificationSelectNotificationsCount,
  notificationSelectNotificationsLength,
  notificationSelectNotificationsFetched,
  notificationSelectNotificationsFilter,
  notificationSetNotificationsFilter,
  notificationGetNotifications,
  notificationReadNotifications,
} from '@/features'
// components
import {
  NavigationScrollable,
  ContentPlaceholder,
  SkeletonHorizontal,
  ButtonMedium,
  Loader,
} from '@/components'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const notifications = useAppSelector(notificationSelectNotifications)
  const notification = notifications[index]

  // if notification not fetched
  if (!notification) {
    return (
      <div style={style}>
        <SkeletonHorizontal
          sections={[
            {
              name: 'notification-list-notifications-notification',
              width: '100%',
              height: '60px',
              isStatic: false,
              isDivider: false,
            },
          ]}
        />
      </div>
    )
  }

  const { id: notificationId } = notification

  return (
    <div css={styles().notification.main} style={style}>
      <NotificationCard
        notification={notification}
        onClick={() =>
          navigate(`${Path.Notifications}${Path.Notification}/${notificationId}`)
        }
      />
    </div>
  )
})

type NotificationListNotificationsProps = {}

export const NotificationListNotifications: FC<
  NotificationListNotificationsProps
> = () => {
  const dispatch = useAppDispatch()
  const notificationGetNotificationsLoading = useAppSelector(
    notificationSelectNotificationGetNotificationsLoading,
  )
  const notifications = useAppSelector(notificationSelectNotifications)
  const notificationsCount = useAppSelector(notificationSelectNotificationsCount)
  const notificationsLength = useAppSelector(notificationSelectNotificationsLength)
  const notificationsFetched = useAppSelector(notificationSelectNotificationsFetched)
  const notificationsFilter = useAppSelector(notificationSelectNotificationsFilter)
  const notificationReadNotificationsLoading = useAppSelector(
    notificationSelectNotificationReadNotificationsLoading,
  )

  const notificationCardHeight = 60
  const notificationCardMarginBottom = 20
  const itemHeight = notificationCardHeight + notificationCardMarginBottom
  const itemsToFit = 6

  const defaultContentCount = 1000
  const defaultListWidth = '100%'
  const defaultListHeight = itemsToFit * itemHeight
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!notifications[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(notificationGetNotifications({ limit: itemsToFetch, offset: startIndex }))
  }

  // renders notification list navigation
  const renderNotificationListNavigation = () => (
    <div css={styles().list.nav.main}>
      <NavigationScrollable
        options={[
          {
            id: 'ebb827c4-2091-4dc2-8f5d-621ce926677d',
            label: 'User',
            enabled: notificationsFilter === EntityNotificationEntityType.User,
            onClick: () => {
              // set notifications filter
              dispatch(
                notificationSetNotificationsFilter({
                  filter: EntityNotificationEntityType.User,
                }),
              )

              // refetch notifications
              dispatch(notificationGetNotifications({ limit: 30, offset: 0 }))
            },
          },
          {
            id: '8dceb189-e6e8-4f1c-85a0-31fcde6fe99d',
            label: 'Track',
            enabled: notificationsFilter === EntityNotificationEntityType.Track,
            onClick: () => {
              // set notifications filter
              dispatch(
                notificationSetNotificationsFilter({
                  filter: EntityNotificationEntityType.Track,
                }),
              )

              // refetch notifications
              dispatch(notificationGetNotifications({ limit: 30, offset: 0 }))
            },
          },
          {
            id: '37740ab4-aea0-4c54-8448-400a1fab5096',
            label: 'Post',
            enabled: notificationsFilter === EntityNotificationEntityType.Post,
            onClick: () => {
              // set notifications filter
              dispatch(
                notificationSetNotificationsFilter({
                  filter: EntityNotificationEntityType.Post,
                }),
              )

              // refetch notifications
              dispatch(notificationGetNotifications({ limit: 30, offset: 0 }))
            },
          },
          {
            id: 'b5200260-910e-43f5-aa44-8c7883dee6ae',
            label: 'Comment',
            enabled: notificationsFilter === EntityNotificationEntityType.Comment,
            onClick: () => {
              // set notifications filter
              dispatch(
                notificationSetNotificationsFilter({
                  filter: EntityNotificationEntityType.Comment,
                }),
              )

              // refetch notifications
              dispatch(notificationGetNotifications({ limit: 30, offset: 0 }))
            },
          },
          {
            id: 'cb4d6974-7986-4054-b7b4-ba644cd2d0f6',
            label: 'Tip',
            enabled: notificationsFilter === EntityNotificationEntityType.Tip,
            onClick: () => {
              // set notifications filter
              dispatch(
                notificationSetNotificationsFilter({
                  filter: EntityNotificationEntityType.Tip,
                }),
              )

              // refetch notifications
              dispatch(notificationGetNotifications({ limit: 30, offset: 0 }))
            },
          },
          {
            id: 'e26df361-c035-42c7-82d9-18ceab7d9d56',
            label: 'Unlock',
            enabled: notificationsFilter === EntityNotificationEntityType.Unlock,
            onClick: () => {
              // set notifications filter
              dispatch(
                notificationSetNotificationsFilter({
                  filter: EntityNotificationEntityType.Unlock,
                }),
              )

              // refetch notifications
              dispatch(notificationGetNotifications({ limit: 30, offset: 0 }))
            },
          },
          {
            id: '1a2c0ce2-edff-4878-a25d-8bca6509d185',
            label: 'Sub',
            enabled: notificationsFilter === EntityNotificationEntityType.Subscription,
            onClick: () => {
              // set notifications filter
              dispatch(
                notificationSetNotificationsFilter({
                  filter: EntityNotificationEntityType.Subscription,
                }),
              )

              // refetch notifications
              dispatch(notificationGetNotifications({ limit: 30, offset: 0 }))
            },
          },
        ]}
      />
    </div>
  )

  // if notifications are fetched but length is 0
  if (
    !notificationGetNotificationsLoading &&
    notificationsFetched &&
    notificationsLength === 0
  ) {
    // return place holder
    return (
      // used to be content layout virtualized
      <div css={styles().list.main}>
        {renderNotificationListNavigation()}
        <div css={styles().list.content.main}>
          <ContentPlaceholder
            icon={icons.Bell}
            height={`${defaultListHeight}px`}
            title="There are no notifications"
          />
        </div>
      </div>
    )
  }

  return (
    // used to be content layout virtualized
    <div css={styles().list.main}>
      {renderNotificationListNavigation()}
      <div css={styles().list.content.main}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          itemCount={notificationsCount || defaultContentCount}
          minimumBatchSize={itemsToFetch}
          threshold={itemThreshold}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className="virtualized-list"
              height={defaultListHeight}
              itemSize={itemHeight}
              itemCount={notificationsCount || defaultContentCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={defaultListWidth}
              layout="vertical"
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </div>
      <div css={styles().list.actions.main}>
        <ButtonMedium
          type="button"
          aria-label="read all notifications"
          disabled={notificationReadNotificationsLoading}
          appearance="secondary"
          onClick={() => dispatch(notificationReadNotifications())}
        >
          {notificationReadNotificationsLoading ? (
            <Loader width={56} height={16} appearance="secondary" />
          ) : (
            `Read all`
          )}
        </ButtonMedium>
      </div>
    </div>
  )
}

NotificationListNotifications.propTypes = {}

import { FC, MouseEventHandler, useState } from 'react'
import { useTheme } from '@emotion/react'
import { EntityReportComplaintView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useUserPublic } from '@/hooks'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type ReportComplaintCardProps = {
  reportComplaint: EntityReportComplaintView
  onUserNameClick: MouseEventHandler<HTMLButtonElement>
  onClick: MouseEventHandler<HTMLDivElement>
}

export const ReportComplaintCard: FC<ReportComplaintCardProps> = ({
  reportComplaint,
  onUserNameClick,
  onClick,
}) => {
  const theme = useTheme()
  const {
    userId: reportComplaintAuthorUserId,
    complaint,
    complaintType,
  } = reportComplaint

  // report complaint author
  const { user: reportComplaintAuthor, isLoading: isReportComplaintAuthorLoading } =
    useUserPublic(reportComplaintAuthorUserId || '')
  const { name: authorName, avatarId: authorAvatarId } = reportComplaintAuthor ?? {}
  const authorLoadingPlaceholder = 'Loading...'
  const authorNamePlaceholder = '-'

  const authorHasAvatar = !!authorAvatarId
  const authorAvatar = useFile(authorAvatarId || '')
  const [authorAvatarLoadError, setAuthorAvatarLoadError] = useState(false)

  return (
    <div css={styles(theme).card.main} onClick={(e) => onClick(e)} aria-hidden="true">
      <div css={styles(theme).card.data.main}>
        <div css={styles(theme).card.data.avatar.main}>
          {authorHasAvatar && !authorAvatarLoadError ? (
            <img
              css={styles(theme).card.data.avatar.image}
              onError={() => setAuthorAvatarLoadError(true)}
              loading="lazy"
              width="30px"
              height="30px"
              src={authorAvatar}
              alt="post author avatar"
            />
          ) : (
            <icons.User css={styles(theme).card.data.avatar.icon} />
          )}
        </div>
        <div css={styles(theme).card.data.content.main}>
          <div
            css={styles(theme).card.data.content.name}
            onClick={(e) => e.stopPropagation()}
            aria-hidden="true"
          >
            <button
              css={styles(theme).card.data.content.name}
              onClick={(e) => onUserNameClick(e)}
              type="button"
              aria-label="explore author profile"
            >
              <span>
                {isReportComplaintAuthorLoading
                  ? authorLoadingPlaceholder
                  : authorName || authorNamePlaceholder}
              </span>
            </button>
          </div>
          <div css={styles(theme).card.data.content.complaint}>{complaint}</div>
        </div>
      </div>
      <div css={styles(theme).card.type.main}>
        <div css={styles(theme).card.type.text}>
          {complaintType?.toString().replace('_', ' ')}
        </div>
      </div>
    </div>
  )
}

ReportComplaintCard.propTypes = {}

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type ControlProps = {
  appearance?: 'positive' | 'negative' | 'neutral'
  label: string
  icon: any
  text: string
  disabled: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const Control: FC<ControlProps> = ({
  appearance = 'neutral',
  label,
  icon: Icon,
  text,
  disabled,
  onClick,
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).control.main}>
      <button
        css={[
          styles(theme).control.content.main,
          appearance === 'positive' && styles(theme).control.content.positive,
          appearance === 'negative' && styles(theme).control.content.negative,
          appearance === 'neutral' && styles(theme).control.content.neutral,
          disabled && styles(theme).control.content.disabled,
        ]}
        onClick={(e) => onClick(e)}
        type="button"
        aria-label={label}
        disabled={disabled}
      >
        <div
          css={[
            styles(theme).control.content.icon.main,
            appearance === 'positive' && styles(theme).control.content.icon.positive,
            appearance === 'negative' && styles(theme).control.content.icon.negative,
            appearance === 'neutral' && styles(theme).control.content.icon.neutral,
            disabled && styles(theme).control.content.icon.disabled,
          ]}
        >
          <Icon />
        </div>
        <div
          css={[
            styles(theme).control.content.text.main,
            appearance === 'positive' && styles(theme).control.content.text.positive,
            appearance === 'negative' && styles(theme).control.content.text.negative,
            appearance === 'neutral' && styles(theme).control.content.text.neutral,
            disabled && styles(theme).control.content.text.disabled,
          ]}
        >
          {text}
        </div>
      </button>
    </div>
  )
}

Control.propTypes = {}

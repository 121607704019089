import { css, Theme } from '@emotion/react'
import { mq, helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  header: {
    main: css`
      ${helpers.app.padding.sides};
      ${helpers.app.header.height};
      ${helpers.app.header.width};

      display: flex;
      align-items: center;
      justify-content: space-between;

      background-color: ${theme.colors.primary.normal};
    `,

    logo: css`
      display: none;
      margin-right: 10px;

      ${mq.tablet(css`
        display: inline-block;

        & > svg {
          width: 140px;
          height: 24px;
        }
      `)}

      ${mq.mobile(css`
        & > svg {
          width: 120px;
          height: 20px;
        }
      `)}
    `,

    search: css`
      margin-right: 10px;
      max-width: 236px;

      ${mq.tablet(css`
        display: none;
      `)}
    `,

    actions: {
      main: css`
        display: flex;

        ${mq.tablet(css`
          display: none;
        `)}
      `,

      notifications: css`
        margin-right: 20px;
      `,

      avatar: css``,
    },

    buttons: css`
      display: flex;

      & > div:nth-of-type(1) {
        margin-right: 10px;
      }

      ${mq.tablet(css`
        display: none;
      `)}
    `,

    menu: {
      main: css`
        display: none;

        ${mq.tablet(css`
          display: flex;
        `)}
      `,

      notifications: css`
        margin-right: 10px;
      `,

      actions: {
        main: css`
          display: flex;
        `,
        button: css`
          ${helpers.resetDefaults};

          display: flex;
          align-items: center;
          justify-content: center;

          width: 40px;
          height: 40px;

          cursor: pointer;

          &:hover > svg > path {
            color: ${theme.colors.accent.normal};
          }
        `,

        icon: {
          shared: css`
            & > path {
              transition: color 0.2s;
              color: ${theme.colors.secondary.normal};
            }
          `,
          close: css`
            width: 30px;
            height: 30px;
          `,
          open: css`
            width: 30px;
            height: 30px;
          `,
        },
      },
    },
  },
})

import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import {
  EntityPostView,
  EntityPostMediaType,
  EntityTrackVisibility,
} from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useUserPublic, useTracksPublic } from '@/hooks'
// icons
import { icons } from '@/assets'
// utils
import { numbersFormatter, stringsCutter } from '@/utils'
// components
import { ButtonSmall } from '@/components'

// components
import { Track, TrackLocked } from './components'
// styles
import { styles } from './styles'

type PostCardPublicProps = {
  post: EntityPostView

  overlayAction: {
    name: string
    label: string
    onClick: MouseEventHandler<HTMLButtonElement>
  }
}

export const PostCardPublic: FC<PostCardPublicProps> = ({
  post,

  overlayAction,
}) => {
  const theme = useTheme()
  const [mediaLoadError, setMediaLoadError] = useState(false)
  const [overlayAvatarError, setOverlayAvatarError] = useState(false)
  const [headerAvatarError, setHeaderAvatarError] = useState(false)
  const [hover, setHover] = useState(false)

  const { id: postId, userId, trackIds, message, mediaType, mediaId, likesCount } = post

  // post author
  const { user: postAuthor, isLoading: isPostAuthorLoading } = useUserPublic(userId || '')
  const {
    name: authorName,
    username: authorUsername,
    avatarId: authorAvatarId,
  } = postAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'
  const userUsernamePlaceholder = 'unknown'

  // post tracks
  const { tracks: postTracks, isLoading: isPostTracksLoading } = useTracksPublic(
    trackIds || [],
  )
  const tracksLoadingPlaceholder = 'Loading...'

  const authorHasAvatar = !!authorAvatarId
  const avatarSource = useFile(authorAvatarId || '')

  const postHasMedia = !!mediaId
  const postMedia = useFile(mediaId || '')

  const maxPostDescriptionLength = 84

  return (
    <div
      css={styles(theme).card.main}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {/* overlay */}
      {overlayAction && (
        <div css={styles(theme).card.overlay.main(hover)}>
          <div css={styles(theme).card.overlay.avatar.main}>
            {authorHasAvatar && !overlayAvatarError ? (
              <img
                css={styles(theme).card.overlay.avatar.image}
                onError={() => setOverlayAvatarError(true)}
                loading="lazy"
                width="80px"
                height="80px"
                src={avatarSource}
                alt="post author avatar"
              />
            ) : (
              <icons.User css={styles(theme).card.overlay.avatar.icon} />
            )}
          </div>
          <div css={styles(theme).card.overlay.author.main}>
            <div css={styles(theme).card.overlay.author.name}>
              {isPostAuthorLoading
                ? userLoadingPlaceholder
                : authorName || userNamePlaceholder}
            </div>
            <div css={styles(theme).card.overlay.author.username}>
              {isPostAuthorLoading
                ? userLoadingPlaceholder
                : `@${authorUsername || userUsernamePlaceholder}`}
            </div>
          </div>
          <div css={styles(theme).card.overlay.follow}>
            <ButtonSmall
              aria-label={overlayAction.label}
              appearance="primary"
              onClick={(e) => overlayAction.onClick(e)}
            >
              {overlayAction.name}
            </ButtonSmall>
          </div>
        </div>
      )}

      {/* header */}
      <div css={styles(theme).card.header.main}>
        <div css={styles(theme).card.header.content.main}>
          <div css={styles(theme).card.header.content.avatar.main}>
            {authorHasAvatar && !headerAvatarError ? (
              <img
                css={styles(theme).card.header.content.avatar.image}
                onError={() => setHeaderAvatarError(true)}
                loading="lazy"
                width="30px"
                height="30px"
                src={avatarSource}
                alt="post author avatar"
              />
            ) : (
              <icons.User css={styles(theme).card.header.content.avatar.icon} />
            )}
          </div>
          <div css={styles(theme).card.header.content.author.main}>
            <div css={styles(theme).card.header.content.author.name}>
              {isPostAuthorLoading
                ? userLoadingPlaceholder
                : authorName || userNamePlaceholder}
            </div>
            <div css={styles(theme).card.header.content.author.username}>
              {isPostAuthorLoading
                ? userLoadingPlaceholder
                : `@${authorUsername || userUsernamePlaceholder}`}
            </div>
          </div>
          <div css={styles(theme).card.header.content.likes.main}>
            <div css={styles(theme).card.header.content.likes.icon}>
              <icons.HeartFilled />
            </div>
            <div css={styles(theme).card.header.content.likes.likes}>
              {numbersFormatter(likesCount || 0)}
            </div>
          </div>
        </div>
      </div>

      {/* media */}
      <div css={styles(theme).card.media.main}>
        {postHasMedia && !mediaLoadError ? (
          <>
            {mediaType && mediaType === EntityPostMediaType.Image && (
              <img
                css={styles(theme).card.media.image}
                onError={() => setMediaLoadError(true)}
                loading="lazy"
                width="180px"
                height="150px"
                src={postMedia}
                alt="post media"
              />
            )}
            {mediaType && mediaType === EntityPostMediaType.Video && (
              <>
                <video
                  css={styles(theme).card.media.video.main}
                  onError={() => setMediaLoadError(true)}
                  width="180"
                  muted
                  preload="metadata"
                  // autoPlay
                  // loop
                >
                  <source src={postMedia} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div css={styles(theme).card.media.video.play}>
                  <icons.PlayCircle />
                </div>
              </>
            )}
          </>
        ) : (
          <div css={styles(theme).card.media.placeholder}>
            <icons.Ryddm />
          </div>
        )}
      </div>

      {/* content */}
      <div css={styles(theme).card.content.main}>
        <div css={styles(theme).card.content.description}>
          {stringsCutter(maxPostDescriptionLength, message || '')}
        </div>
        <div css={styles(theme).card.content.tracks}>
          {isPostTracksLoading
            ? tracksLoadingPlaceholder
            : postTracks &&
              postTracks.map((track) => {
                const { visibility } = track

                const isContentLocked = visibility === EntityTrackVisibility.Subscribers
                const renderLocked = isContentLocked

                if (renderLocked) {
                  return (
                    <TrackLocked
                      key={`public-post-${postId}-track-${track.id}`}
                      track={track}
                    />
                  )
                }

                return (
                  <Track key={`public-post-${postId}-track-${track.id}`} track={track} />
                )
              })}
        </div>
      </div>

      {/* footer */}
      <div css={styles(theme).card.footer} />
    </div>
  )
}

PostCardPublic.propTypes = {}

import AlertTriangle from './alert_triangle.svg'
import ArrowLeft from './arrow_left.svg'
import ArrowRight from './arrow_right.svg'
import Bell from './bell.svg'
import CaretDown from './caret_down.svg'
import CheckCircle from './check_circle.svg'
import CheckSquare from './check_square.svg'
import Check from './check.svg'
import Clock from './clock.svg'
import CommentFilled from './comment_filled.svg'
import Comment from './comment.svg'
import CreditCardIncome from './credit_card_income.svg'
import CreditCardOutgoing from './credit_card_outgoing.svg'
import CreditCard from './credit_card.svg'
import CrossCircle from './cross_circle.svg'
import CrossSquare from './cross_square.svg'
import Crown from './crown.svg'
import DollarCircle from './dollar_circle.svg'
import Download from './download.svg'
import Edit from './edit.svg'
import Fire from './fire.svg'
import HeartBroken from './heart_broken.svg'
import HeartFilled from './heart_filled.svg'
import Heart from './heart.svg'
import ImageDownload from './image_download.svg'
import Lock from './lock.svg'
import MusicNote from './music_note.svg'
import MusicNotes from './music_notes.svg'
import MenuDots from './menu_dots.svg'
import MenuLines from './menu_lines.svg'
import Pause from './pause.svg'
import PlayCircle from './play_circle.svg'
import PlayCircleBold from './play_circle_bold.svg'
import Play from './play.svg'
import PlusCircle from './plus_circle.svg'
import Posts from './posts.svg'
import Ryddm from './ryddm.svg'
import Scope from './scope.svg'
import Search from './search.svg'
import SignOut from './sign_out.svg'
import Speaker from './speaker.svg'
import Time from './time.svg'
import Trash from './trash.svg'
import Unlock from './unlock.svg'
import Upload from './upload.svg'
import UserCircle from './user_circle.svg'
import UserPlus from './user_plus.svg'
import User from './user.svg'

export const icons = {
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  Bell,
  CaretDown,
  CheckCircle,
  CheckSquare,
  Check,
  Clock,
  CommentFilled,
  Comment,
  CreditCardIncome,
  CreditCardOutgoing,
  CreditCard,
  CrossCircle,
  CrossSquare,
  Crown,
  DollarCircle,
  Download,
  Edit,
  Fire,
  HeartBroken,
  HeartFilled,
  Heart,
  ImageDownload,
  Lock,
  MenuDots,
  MenuLines,
  MusicNote,
  MusicNotes,
  Pause,
  PlayCircle,
  Play,
  PlayCircleBold,
  PlusCircle,
  Posts,
  Ryddm,
  Scope,
  Search,
  SignOut,
  Speaker,
  Time,
  Trash,
  Unlock,
  Upload,
  UserCircle,
  UserPlus,
  User,
}

import { FC, ChangeEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// components
import { Checkbox } from '@/components'

// styles
import { styles } from './styles'

type CheckboxOptionProps = {
  name: string
  description: string
  icon: any
  checked: boolean
  onCheckboxClick: ChangeEventHandler<HTMLInputElement>
}

export const CheckboxOption: FC<CheckboxOptionProps> = ({
  name,
  description,
  icon: Icon,
  checked,
  onCheckboxClick,
}) => {
  const theme = useTheme()
  return (
    <div css={styles(theme).option.main}>
      <div css={styles(theme).option.data.main}>
        <div css={styles(theme).option.data.title.main}>
          <div css={styles(theme).option.data.title.icon}>
            <Icon />
          </div>
          <div css={styles(theme).option.data.title.name}>{name}</div>
        </div>
        <div css={styles(theme).option.data.description}>{description}</div>
      </div>
      <div css={styles(theme).option.checkbox}>
        <Checkbox
          name={name}
          label={name}
          checked={checked}
          onChange={(e) => onCheckboxClick(e)}
        />
      </div>
    </div>
  )
}

CheckboxOption.propTypes = {}

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type InputLabelProps = {
  text: string
}

export const InputLabel: FC<InputLabelProps> = ({ text }) => {
  const theme = useTheme()
  return <div css={styles(theme).label.main}>{text}</div>
}

InputLabel.propTypes = {}

import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  reportSelectReportResolveReportLoading,
  reportSelectReportResolveModalReport,
  reportResolveReport,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type ReportResolveFormProps = {}

export const ReportResolveForm: FC<ReportResolveFormProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const reportResolveReportLoading = useAppSelector(
    reportSelectReportResolveReportLoading,
  )
  const modalReport = useAppSelector(reportSelectReportResolveModalReport)

  const { id: reportId, shortId: shortReportId } = modalReport ?? {}

  const submitDisabled = reportResolveReportLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(
            reportResolveReport({
              reportId: reportId || '',
              cb: () => {
                // navigate to reports page
                navigate(`${Path.Reports}`)
              },
            }),
          )
        }}
      >
        <div css={styles().form.content.report}>{`#${shortReportId}`}</div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="create complaint"
            disabled={submitDisabled}
            appearance="primary"
          >
            {reportResolveReportLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Resolve`
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

ReportResolveForm.propTypes = {}

import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  modal: {
    main: css``,
    content: css`
      ${mq.tablet(css`
        width: 100%;
        height: 100%;
      `)}
    `,
  },
})

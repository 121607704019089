import { css } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = () => ({
  composition: {
    main: css`
      display: flex;
      justify-content: center;
    `,
    grid: {
      main: css`
        display: grid;
        grid-template-rows: repeat(2, 145px);
        grid-template-columns: repeat(6, 145px);
        grid-gap: 10px;
      `,
      item: (index: number) => {
        switch (index) {
          case 4:
            return css`
              grid-row: 1 / span 2;
              grid-column: 3 / span 2;
            `
          default:
            return css``
        }
      },
      image: css`
        ${helpers.image};

        border-radius: 4px;
      `,
    },
  },
})

import { css, Theme } from '@emotion/react'
import { helpers, typography, z } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: css`
      flex-grow: 0;
      flex-shrink: 0;

      position: relative;

      width: 180px;
      height: 282px;

      cursor: pointer;
      overflow: hidden;
    `,

    // header
    header: {
      main: css`
        z-index: ${z.features.post.card.header};

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 60px;

        padding: 8px 8px 18px 8px;

        background: linear-gradient(
          180deg,
          ${theme.colors.primary.normal} 0%,
          ${theme.colors.primary.normal}00 75.79%
        );
      `,
      content: {
        main: css`
          display: flex;
        `,
        likes: {
          main: css`
            display: flex;
            align-items: center;
            margin-right: 8px;
            min-width: 0;
          `,
          icon: css`
            display: flex;
            margin-right: 4px;

            & > svg {
              width: 14px;
              height: 14px;
            }
          `,
          count: css`
            ${helpers.textOverflowEllipsis};

            font-size: ${typography.sp};
          `,
        },
        comments: {
          main: css`
            display: flex;
            align-items: center;
            margin-right: 8px;
            min-width: 0;
          `,
          icon: css`
            display: flex;
            margin-right: 4px;

            & > svg {
              width: 12px;
              height: 12px;
            }
          `,
          count: css`
            ${helpers.textOverflowEllipsis};

            font-size: ${typography.sp};
          `,
        },
        unlocks: {
          main: css`
            display: flex;
            align-items: center;
            min-width: 0;
          `,
          icon: css`
            display: flex;
            margin-right: 4px;

            & > svg {
              width: 14px;
              height: 14px;
            }
          `,
          count: css`
            ${helpers.textOverflowEllipsis};

            font-size: ${typography.sp};
          `,
        },
      },
    },

    // media
    media: {
      main: css`
        position: relative;

        height: 150px;
        border-radius: 4px;
      `,
      image: css`
        ${helpers.image};

        border-radius: 4px 4px 0px 0px;
      `,
      video: {
        main: css`
          ${helpers.image};

          border-radius: 4px 4px 0px 0px;
        `,
        play: css`
          position: absolute;
          top: 60%;
          left: 50%;

          transform: translate(-50%, -60%);

          & > svg {
            width: 40px;
            height: 40px;
          }

          & > svg > path {
            fill: ${theme.colors.secondary.normal};
          }

          & > svg > circle {
            stroke: ${theme.colors.secondary.normal};
          }
        `,
      },
      placeholder: css`
        height: 100%;
        background-color: ${theme.colors.primary.darker};

        & > svg {
          position: absolute;
          top: 60%;
          left: 50%;

          width: 30px;
          height: 40px;

          transform: translate(-50%, -60%);
        }

        & > svg > path {
          fill: ${theme.colors.secondary.normal};
        }
      `,
    },

    // content
    content: {
      main: css`
        height: 130px;

        overflow: hidden;

        padding: 8px 8px 0px 8px;
        border-radius: 0px 0px 4px 4px;

        background-color: ${theme.colors.primary.darker};
      `,

      description: css`
        font-size: ${typography.sp};
        color: ${theme.colors.secondary.normal};
        line-height: 120%;

        word-break: break-word;
        height: 62px;
        overflow: hidden;

        margin-bottom: 8px;
      `,

      tracks: css`
        & > div:not(:last-of-type) {
          margin-bottom: 8px;
        }
      `,
    },

    // footer
    footer: {
      main: css`
        z-index: ${z.features.post.card.footer};

        display: flex;
        align-items: flex-end;

        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 60px;

        // padding: 8px 8px 18px 8px;
        // padding: px 0px 0px 0px;

        background: linear-gradient(
          180deg,
          ${theme.colors.primary.normal}00 25.21%,
          ${theme.colors.primary.normal} 100%
        );
      `,

      buttons: {
        main: css`
          display: flex;
          align-items: center;
          background-color: ${theme.colors.primary.darker}aa;
          border-radius: 0px 0px 4px 4px;

          height: 40px;
          width: 100%;
        `,

        edit: {
          main: css`
            display: flex;
            justify-content: center;
            width: 100%;
          `,
          button: {
            shared: css`
              ${helpers.resetDefaults};

              display: flex;
              align-items: center;
              justify-content: center;

              width: 100%;
            `,
            disabled: css`
              cursor: not-allowed;

              & > svg > path {
                fill: ${theme.colors.secondary.darker};
              }

              &:hover svg > path {
                fill: ${theme.colors.secondary.darker};
              }
            `,
            enabled: css`
              cursor: pointer;

              & > svg > path {
                fill: ${theme.colors.secondary.normal};
              }

              &:hover svg > path {
                fill: ${theme.colors.accent.normal};
              }
            `,
          },
          icon: css`
            width: 20px;
            height: 20px;

            & > path {
              transition: fill 0.2s, color 0.2s;
            }
          `,
        },

        delete: {
          main: css`
            display: flex;
            justify-content: center;
            width: 100%;
          `,
          button: {
            shared: css`
              ${helpers.resetDefaults};

              display: flex;
              align-items: center;
              justify-content: center;

              width: 100%;
            `,
            disabled: css`
              cursor: not-allowed;

              & > svg > path {
                fill: ${theme.colors.secondary.darker};
              }

              &:hover svg > path {
                fill: ${theme.colors.secondary.darker};
              }
            `,
            enabled: css`
              cursor: pointer;

              & > svg > path {
                fill: ${theme.colors.secondary.normal};
              }

              &:hover svg > path {
                fill: ${theme.colors.accent.normal};
              }
            `,
          },
          icon: css`
            width: 20px;
            height: 20px;

            & > path {
              transition: fill 0.2s, color 0.2s;
            }
          `,
        },
      },
    },
  },
})

import { css, Theme } from '@emotion/react'
import { helpers, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  role: {
    main: {
      shared: (hover: boolean) => css`
        ${helpers.app.padding.full};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        transition: opacity 0.4s;

        ${!hover &&
        css`
          opacity: 0.1;
        `}

        ${mq.tablet(css`
          opacity: 1;
        `)}
      `,
      primary: css`
        background-color: ${theme.colors.accent.normal};
      `,
      secondary: css`
        background-color: ${theme.colors.primary.darker};
      `,
    },

    content: {
      main: css`
        display: flex;
        flex-direction: column;

        max-width: 740px;
        width: 100%;
      `,
      title: css`
        margin-bottom: 60px;

        ${mq.mobile(css`
          margin-bottom: 30px;
        `)}
      `,
      features: css`
        margin-bottom: 60px;

        & > div:not(:last-of-type) {
          margin-bottom: 50px;
        }

        ${mq.mobile(css`
          & > div:not(:last-of-type) {
            margin-bottom: 20px;
          }
        `)}
      `,
      next: css`
        display: flex;
        justify-content: center;

        & > button {
          max-width: 380px;
        }

        ${mq.mobile(css`
          margin-bottom: 20px;
        `)}
      `,
    },
  },
})

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type SkeletonHorizontalSection = {
  name: string
  width: string
  height: string
  isStatic: boolean
  isDivider: boolean
}

type SkeletonHorizontalProps = {
  sections: SkeletonHorizontalSection[]
}

export const SkeletonHorizontal: FC<SkeletonHorizontalProps> = ({ sections }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).skeleton.main}>
      {sections.map(({ name, width, height, isStatic, isDivider }, i) => {
        const isOnlySection = sections.length === 1
        const isFirstSection = i === 0 && !isOnlySection
        const isLastSection = i === sections.length - 1 && !isOnlySection
        const isCenterSection = !isFirstSection && !isLastSection && !isDivider

        return (
          <div
            css={[
              isDivider && styles(theme).skeleton.divider(width, height, isStatic),
              isOnlySection &&
                styles(theme).skeleton.section.full(width, height, isStatic),
              isFirstSection &&
                styles(theme).skeleton.section.start(width, height, isStatic),
              isLastSection &&
                styles(theme).skeleton.section.end(width, height, isStatic),
              isCenterSection &&
                styles(theme).skeleton.section.center(width, height, isStatic),
            ]}
            key={name}
          />
        )
      })}
    </div>
  )
}

SkeletonHorizontal.propTypes = {}

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'
// hooks
import { useAppDispatch } from '@/hooks'
// features
import { reportCloseReportCommentModal } from '@/features'
// components
import { CardHeaderMobile, ModalCardTitle, ModalCardSubtitle, Error } from '@/components'

// components
import { ReportCommentForm } from './components'
// styles
import { styles } from './styles'

type ReportCommentCardProps = {}

export const ReportCommentCard: FC<ReportCommentCardProps> = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const reportCommentError = ''

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile
          onCloseClick={() => dispatch(reportCloseReportCommentModal())}
        />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <ModalCardTitle text="Report" icon={icons.AlertTriangle} />
      </div>

      {/* subtitle */}
      <div css={styles(theme).card.subtitle}>
        <ModalCardSubtitle text="Kindly provide details of your concern regarding this comment." />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* form */}
        <div css={styles(theme).card.content.form}>
          <ReportCommentForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={reportCommentError} />
        </div>
      </div>
    </div>
  )
}

ReportCommentCard.propTypes = {}

import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityPostVisibility, EntityUserRole } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // comment
  CommentCard,
  commentSelectCommentGetCommentsLoading,
  commentSelectComments,
  commentSelectCommentsCount,
  commentSelectCommentsLength,
  commentSelectCommentsFetched,
  commentSelectCommentsDepth,
  commentAddDepth,
  commentRemoveDepth,
  commentGetComments,
  commentOpenCommentBlockModal,
  commentOpenCommentDeleteModal,
  // like
  likeSelectCommentLikes,
  likeCreateCommentLike,
  likeDeleteCommentLike,
  // post
  postSelectPost,
  // subscription
  subscriptionSelectUserSubscriptions,
  // unlock
  unlockSelectPostUnlocks,
  // report
  reportOpenReportCommentModal,
} from '@/features'
// components
import { ContentPlaceholder, SkeletonHorizontal } from '@/components'
// icons
import { icons } from '@/assets'

// components
import { Form, ParentComment, Title } from './components'
// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const commentLikes = useAppSelector(likeSelectCommentLikes)
  const comments = useAppSelector(commentSelectComments)
  const comment = comments[index]

  // if comment not fetched
  if (!comment) {
    return (
      <div style={style}>
        <SkeletonHorizontal
          sections={[
            {
              name: 'comment-list-post',
              width: '100%',
              height: '70px',
              isStatic: false,
              isDivider: false,
            },
          ]}
        />
      </div>
    )
  }

  const { id: currentUserId, role: currentUserRole } = profile ?? {}
  const { id: commentId, userId: commentAuthorUserId, commentId: depth } = comment
  const isCommentLiked = commentLikes[commentId || '']

  const isCurrentUserCommentAuthor = currentUserId === commentAuthorUserId
  const isCurrentUserAdmin = currentUserRole === EntityUserRole.Admin

  return (
    <div css={styles().comments.main} style={style}>
      <CommentCard
        comment={comment}
        reply={!!depth}
        isCurrentUserCommentAuthor={isCurrentUserCommentAuthor}
        isCurrentUserAdmin={isCurrentUserAdmin}
        isLiked={isCommentLiked}
        onUserNameClick={() => navigate(`${Path.User}/${commentAuthorUserId}`)}
        onLikeClick={() => dispatch(likeCreateCommentLike({ comment }))}
        onDislikeClick={() => dispatch(likeDeleteCommentLike({ comment }))}
        onReplyClick={() => dispatch(commentAddDepth({ comment }))}
        onBlockClick={() => dispatch(commentOpenCommentBlockModal({ comment }))}
        onDeleteClick={() => dispatch(commentOpenCommentDeleteModal({ comment }))}
        onReportClick={() => dispatch(reportOpenReportCommentModal({ comment }))}
      />
    </div>
  )
})

type CommentListPostProps = {}

export const CommentListPost: FC<CommentListPostProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const profile = useAppSelector(authSelectProfile)
  const postUnlocks = useAppSelector(unlockSelectPostUnlocks)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const post = useAppSelector(postSelectPost)

  const { id: postId, userId: postAuthorUserId, visibility } = post ?? {}
  const { id: profileUserId } = profile ?? {}

  const commentGetCommentsLoading = useAppSelector(commentSelectCommentGetCommentsLoading)
  const comments = useAppSelector(commentSelectComments)
  const commentsCount = useAppSelector(commentSelectCommentsCount)
  const commentsLength = useAppSelector(commentSelectCommentsLength)
  const commentsFetched = useAppSelector(commentSelectCommentsFetched)

  const commentsDepth = useAppSelector(commentSelectCommentsDepth)
  const commentDepthCurrent = commentsDepth[0] || undefined
  const { id: currentDepthId } = commentDepthCurrent ?? {}

  const commentCardHeight = 70
  const commentCardMarginBottom = 10
  const itemHeight = commentCardHeight + commentCardMarginBottom

  const defaultContentCount = 1000
  const defaultListWidth = '100%'
  const defaultListHeight = 400
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  // if post is not fetched
  if (!post) {
    // return card post skeleton
    return (
      <div css={styles().list.main}>
        {/* title */}
        <div css={styles().list.title}>
          <Title text="Comments" />
        </div>

        {/* skeleton */}
        <div css={styles().list.content}>
          <SkeletonHorizontal
            sections={[
              {
                name: 'comment-list-post-card',
                width: '100%',
                height: '400px',
                isStatic: false,
                isDivider: false,
              },
            ]}
          />
        </div>

        {/* form */}
        <div css={styles().list.content}>
          <Form />
        </div>
      </div>
    )
  }

  const isItemLoaded = (index: number) => !!comments[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(
      commentGetComments({
        postId: postId || '',
        commentId: currentDepthId,
        limit: itemsToFetch,
        offset: startIndex,
      }),
    )
  }

  const isContentLocked = visibility === EntityPostVisibility.Subscribers
  const isCurrentUserContentAuthor = profileUserId === postAuthorUserId
  const isCurrentUserUnlockedContent = postUnlocks[postId || '']
  const isCurrentUserSubscribedToContentAuthor = userSubscriptions[postAuthorUserId || '']

  const renderLocked =
    isContentLocked &&
    !isCurrentUserContentAuthor &&
    !isCurrentUserUnlockedContent &&
    !isCurrentUserSubscribedToContentAuthor

  // if post is locked
  if (renderLocked) {
    // return placeholder
    return (
      <div css={styles().list.main}>
        {/* title */}
        <div css={styles().list.title}>
          <Title text="Comments" />
        </div>

        {/* placeholder */}
        <div css={styles().list.content}>
          <ContentPlaceholder
            icon={icons.Lock}
            height="400px"
            title="Content is locked"
          />
        </div>
      </div>
    )
  }

  // if comments are fetched but length is 0
  if (!commentGetCommentsLoading && commentsFetched && commentsLength === 0) {
    // return placeholder
    return (
      <div css={styles().list.main}>
        {/* title */}
        <div css={styles().list.title}>
          <Title text="Comments" />
        </div>

        {/* parent comment */}
        {commentDepthCurrent && (
          <div css={styles().list.comment}>
            <ParentComment
              comment={commentDepthCurrent}
              onUserNameClick={() =>
                navigate(`${Path.User}/${commentDepthCurrent.userId}`)
              }
              onBackwardClick={() => dispatch(commentRemoveDepth())}
            />
          </div>
        )}

        {/* placeholder */}
        <div css={styles().list.content}>
          <ContentPlaceholder
            icon={icons.Comment}
            height="400px"
            title={
              commentDepthCurrent
                ? 'Could not find any replies'
                : 'Could not find any comments'
            }
          />
        </div>

        {/* form */}
        <div css={styles().list.form}>
          <Form />
        </div>
      </div>
    )
  }

  return (
    <div css={styles().list.main}>
      {/* title */}
      <div css={styles().list.title}>
        <Title text="Comments" />
      </div>

      {/* parent comment */}
      {commentDepthCurrent && (
        <div css={styles().list.comment}>
          <ParentComment
            comment={commentDepthCurrent}
            onUserNameClick={() => navigate(`${Path.User}/${commentDepthCurrent.userId}`)}
            onBackwardClick={() => dispatch(commentRemoveDepth())}
          />
        </div>
      )}

      {/* comments or replies */}
      <div css={styles().list.content}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          itemCount={commentsCount || defaultContentCount}
          minimumBatchSize={itemsToFetch}
          threshold={itemThreshold}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className="virtualized-list"
              height={defaultListHeight}
              itemSize={itemHeight}
              itemCount={commentsCount || defaultContentCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={defaultListWidth}
              layout="vertical"
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </div>

      {/* form */}
      <div css={styles().list.form}>
        <Form />
      </div>
    </div>
  )
}

CommentListPost.propTypes = {}

import { FC } from 'react'

// images
import { images } from './assets'
// styles
import { styles } from './styles'

type ImageCompositionProps = {}

export const ImageComposition: FC<ImageCompositionProps> = () => (
  <div css={styles().composition.main}>
    <div css={styles().composition.grid.main}>
      {images.map((image, i) => (
        <div key={image.id} css={styles().composition.grid.item(i)}>
          <img
            css={styles().composition.grid.image}
            src={image.image}
            alt="track album cover"
          />
        </div>
      ))}
    </div>
  </div>
)
ImageComposition.propTypes = {}

import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  track: {
    main: css`
      display: flex;
    `,
    cover: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 30px;
        height: 30px;

        background-color: ${theme.colors.primary.darker};
        border-radius: 4px;
        overflow: hidden;

        margin-right: 8px;
      `,

      icon: css`
        width: 16px;
        height: 16px;

        & > path {
          color: ${theme.colors.secondary.normal};
        }
        & > circle {
          color: ${theme.colors.secondary.normal};
        }
      `,
    },
    description: {
      main: css`
        display: flex;
        flex-direction: column;
        justify-content: center;

        min-width: 0;
        margin-right: auto;

        color: ${theme.colors.secondary.normal};
      `,
      name: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.sp};
        font-weight: bold;
        line-height: 12px;
      `,
      authorName: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.c};
      `,
    },
    play: css`
      display: flex;
      align-items: center;

      margin-left: 8px;

      & > svg {
        width: 24px;
        height: 24px;
      }

      & > svg > path {
        fill: ${theme.colors.secondary.dark};
        stroke: ${theme.colors.secondary.dark};
      }

      & > svg > circle {
        stroke: ${theme.colors.secondary.dark};
      }
    `,
  },
})

import AudioNav from './audio_nav.svg'
import AudioVolumeMax from './audio_volume_max.svg'
import AudioVolumeMin from './audio_volume_min.svg'
import AudioVolumeMute from './audio_volume_mute.svg'

export const icons = {
  AudioNav,
  AudioVolumeMax,
  AudioVolumeMin,
  AudioVolumeMute,
}

import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useUserPublic } from '@/hooks'
// icon
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TrackTileSelectProps = {
  track: EntityTrackView
  selected: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  onAuthorNameClick: MouseEventHandler<HTMLButtonElement>
}

export const TrackTileSelect: FC<TrackTileSelectProps> = ({
  track,
  selected,
  onClick,
  onAuthorNameClick,
}) => {
  const theme = useTheme()
  const [coverLoadError, setCoverLoadError] = useState(false)

  const { coverId, name: trackName, userId } = track

  const trackHasCover = !!coverId
  const source = useFile(coverId || '')

  // track author
  const { user: trackAuthor, isLoading: isTrackAuthorLoading } = useUserPublic(
    userId || '',
  )
  const { name: authorName } = trackAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'

  return (
    <div css={styles(theme).track.main}>
      {/* track cover */}
      <div css={styles(theme).track.cover.main}>
        <button
          css={styles(theme).track.cover.button}
          onClick={(e) => onClick(e)}
          type="button"
          aria-label="play track"
        >
          {selected && (
            <div css={styles(theme).track.cover.overlay.main}>
              <div css={styles(theme).track.cover.overlay.icon}>
                <icons.CheckCircle />
              </div>
            </div>
          )}

          {trackHasCover && !coverLoadError ? (
            <img
              css={styles(theme).track.cover.image}
              onError={() => setCoverLoadError(true)}
              loading="lazy"
              width="180px"
              height="180px"
              src={source}
              alt="track album cover"
            />
          ) : (
            <div css={styles(theme).track.cover.placeholder}>
              <icons.MusicNote />
            </div>
          )}
        </button>
      </div>

      {/* track description */}
      <div css={styles(theme).track.description.main}>
        <div css={styles(theme).track.description.name}>{trackName}</div>
        <button
          css={styles(theme).track.description.author}
          onClick={(e) => onAuthorNameClick(e)}
          type="button"
          aria-label="explore track author"
        >
          {isTrackAuthorLoading
            ? userLoadingPlaceholder
            : authorName || userNamePlaceholder}
        </button>
      </div>
    </div>
  )
}

TrackTileSelect.propTypes = {}

import { FC, useState } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'
// hooks
import { useFile } from '@/hooks'

// styles
import { styles } from './styles'

interface AvatarProps {
  avatarId?: string
}

export const Avatar: FC<AvatarProps> = ({ avatarId = '' }) => {
  const theme = useTheme()
  const [avatarLoadError, setAvatarLoadError] = useState(false)

  const hasAvatar = !!avatarId
  const source = useFile(avatarId || '')

  return (
    <div css={styles(theme).avatar.main}>
      {hasAvatar && !avatarLoadError ? (
        <img
          css={styles(theme).avatar.content}
          onError={() => setAvatarLoadError(true)}
          loading="lazy"
          width="60px"
          height="60px"
          src={source}
          alt="source user avatar"
        />
      ) : (
        <icons.User css={styles(theme).avatar.icon} />
      )}
    </div>
  )
}

import { FC, useState, useEffect } from 'react'
import { EntityNotificationConfigScope } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  onboardingSelectLoading,
  onboardingSetNextStage,
  notificationSelectNotificationConfig,
  notificationGetNotificationConfig,
  notificationUpdateNotificationConfig,
} from '@/features'
// components
import { Loader, ButtonLarge, Checkbox } from '@/components'

// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const dispatch = useAppDispatch()
  const onboardingLoading = useAppSelector(onboardingSelectLoading)
  const notificationConfig = useAppSelector(notificationSelectNotificationConfig)
  const initialScope = notificationConfig?.scope

  const [notificationConfigScope, setNotificationConfigScope] = useState(
    EntityNotificationConfigScope.Enabled,
  )

  useEffect(() => {
    // get notifications config
    dispatch(notificationGetNotificationConfig())

    // update local state with initial scope
    if (initialScope) {
      setNotificationConfigScope(initialScope)
    }
  }, [dispatch, initialScope])

  const submitDisabled = onboardingLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(onboardingSetNextStage())
          dispatch(
            notificationUpdateNotificationConfig({ scope: notificationConfigScope }),
          )
        }}
      >
        <div css={styles().form.content.checkbox.main}>
          <Checkbox
            name="notifications-enabled"
            label="enable all notifications"
            checked={notificationConfigScope === EntityNotificationConfigScope.Enabled}
            onChange={() => {
              setNotificationConfigScope(EntityNotificationConfigScope.Enabled)
            }}
          />

          <div css={styles().form.content.checkbox.label}>
            {`I'd like to receive all notifications.`}
          </div>
        </div>

        <div css={styles().form.content.checkbox.main}>
          <Checkbox
            name="notifications-custom"
            label="receive only important notifications"
            checked={notificationConfigScope === EntityNotificationConfigScope.Custom}
            onChange={() => {
              setNotificationConfigScope(EntityNotificationConfigScope.Custom)
            }}
          />

          <div css={styles().form.content.checkbox.label}>
            I prefer to receive only important notifications.
          </div>
        </div>

        <div css={styles().form.content.checkbox.main}>
          <Checkbox
            name="notifications-disabled"
            label="do not receive any notifications"
            checked={notificationConfigScope === EntityNotificationConfigScope.Disabled}
            onChange={() => {
              setNotificationConfigScope(EntityNotificationConfigScope.Disabled)
            }}
          />

          <div css={styles().form.content.checkbox.label}>
            {`I'd rather not receive notifications at the moment.`}
          </div>
        </div>

        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="configure notifications"
            disabled={submitDisabled}
            appearance="primary"
          >
            {onboardingLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Next'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

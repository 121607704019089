import { FC, useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import {
  EntityPostMediaType,
  EntityPostVisibility,
  EntityUserRole,
} from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// utils
import { numbersFormatter } from '@/utils'
// hooks
import {
  useAppSelector,
  useAppDispatch,
  useUser,
  useFile,
  useConnectReady,
} from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // user
  userUtilRenderArtistActions,
  // post
  postSelectPost,
  postSelectPostFetched,
  postOpenPostBlockModal,
  // like
  likeCreateUserFollow,
  likeDeleteUserFollow,
  likeCreatePostLike,
  likeDeletePostLike,
  likeSelectUserFollows,
  likeSelectPostLikes,
  // stream
  streamCreatePostStream,
  // subscribe
  subscriptionOpenSubscriptionCancelModal,
  subscriptionOpenSubscriptionCreateModal,
  subscriptionOpenSubscriptionRenewModal,
  subscriptionSelectUserSubscriptions,
  subscriptionSelectUserSubscriptionsAtPeriodEnd,
  // tip
  tipOpenTipModal,
  // unlock
  unlockSelectPostUnlocks,
  // report
  reportOpenReportPostModal,
} from '@/features'
// components
import { ButtonMedium, ContentPlaceholder, SkeletonVertical } from '@/components'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type PostViewMediaPostProps = {}

export const PostViewMediaPost: FC<PostViewMediaPostProps> = () => {
  const navigate = useNavigate()

  const theme = useTheme()
  const dispatch = useAppDispatch()

  const profile = useAppSelector(authSelectProfile)
  const userFollows = useAppSelector(likeSelectUserFollows)
  const postLikes = useAppSelector(likeSelectPostLikes)
  const postUnlocks = useAppSelector(unlockSelectPostUnlocks)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const userSubscriptionsAtPeriodEnd = useAppSelector(
    subscriptionSelectUserSubscriptionsAtPeriodEnd,
  )

  const post = useAppSelector(postSelectPost)
  const postFetched = useAppSelector(postSelectPostFetched)

  const { id: profileUserId, role: profileUserRole } = profile ?? {}

  const {
    // id
    id: postId,

    // author
    userId: postAuthorUserId,

    // content
    mediaId,
    mediaType,
    message,

    // data
    commentsCount,
    likesCount,

    // visibility
    visibility,
  } = post ?? {}

  // post author
  const { user: postAuthor, isLoading: isPostAuthorLoading } = useUser(
    postAuthorUserId || '',
  )
  const {
    name: authorName,
    username: authorUsername,
    avatarId: authorAvatarId,
  } = postAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'
  const userUsernamePlaceholder = 'unknown'

  const authorHasAvatar = !!authorAvatarId
  const authorAvatar = useFile(authorAvatarId || '')
  const [authorAvatarLoadError, setAuthorAvatarLoadError] = useState(false)

  const postHasMedia = !!mediaId
  const postMedia = useFile(mediaId || '')
  const [postMediaLoadError, setPostMediaLoadError] = useState(false)

  // connect ready
  const { ready: isAuthorCanReceivePayments } = useConnectReady(postAuthorUserId || '')

  const followDisabled = false
  const unfollowDisabled = false
  const subscribeDisabled = false
  const unsubscribeDisabled = false
  const tipDisabled = false
  const blockDisabled = false
  const reportDisabled = false

  const isCurrentUserAdmin = profileUserRole === EntityUserRole.Admin

  const isPostAuthorFollowed = userFollows[postAuthorUserId || '']
  const isPostLiked = postLikes[postId || '']
  const isPostCommented = false

  const isContentLocked = visibility === EntityPostVisibility.Subscribers
  const isCurrentUserContentAuthor = profileUserId === postAuthorUserId
  const isCurrentUserUnlockedContent = postUnlocks[postId || '']
  const isCurrentUserSubscribedToContentAuthor = userSubscriptions[postAuthorUserId || '']
  const isCurrentUserSubscribedToContentAuthorAtPeriodEnd =
    userSubscriptionsAtPeriodEnd[postAuthorUserId || '']

  const renderLocked =
    isContentLocked &&
    !isCurrentUserContentAuthor &&
    !isCurrentUserUnlockedContent &&
    !isCurrentUserSubscribedToContentAuthor

  // on post view
  useEffect(() => {
    const tenSeconds = 10000

    const id = setTimeout(() => {
      // if post exists and is not locked
      if (post && !renderLocked) {
        // after n seconds create post stream
        dispatch(streamCreatePostStream({ post }))
      }
    }, tenSeconds)

    return () => clearInterval(id)
  }, [post, renderLocked, dispatch])

  // if post is locked
  if (renderLocked) {
    return (
      <div css={styles(theme).view.main}>
        <ContentPlaceholder icon={icons.Lock} height="504px" title="Content is locked" />
      </div>
    )
  }

  // if post not found
  if (postFetched && !post) {
    return (
      <div css={styles(theme).view.main}>
        <ContentPlaceholder
          icon={icons.Posts}
          height="504px"
          title="Could not find post"
        />
      </div>
    )
  }

  const renderPostMedia = () => {
    // if post media type is image
    if (mediaType === EntityPostMediaType.Image && postHasMedia && !postMediaLoadError) {
      // render image
      return (
        <img
          css={styles(theme).view.media.image}
          onError={() => setPostMediaLoadError(true)}
          loading="lazy"
          src={postMedia}
          alt="post media content"
        />
      )
    }

    // if post media type is video
    if (mediaType === EntityPostMediaType.Video && postHasMedia && !postMediaLoadError) {
      // render video
      return (
        /* eslint-disable-next-line jsx-a11y/media-has-caption */
        <video
          css={styles(theme).view.media.video}
          onError={() => setPostMediaLoadError(true)}
          src={postMedia}
          controls
          preload="metadata"
          // muted
          // autoPlay
          // loop
        >
          <source src={postMedia} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }

    return (
      <div css={styles(theme).view.media.placeholder}>
        <icons.Ryddm />
      </div>
    )
  }

  const renderArtistActionsInput = {
    isCurrentUserFollowingArtist: isPostAuthorFollowed,
    isCurrentUserSubscribedToArtist: isCurrentUserSubscribedToContentAuthor,
    isCurrentUserSubscribedToArtistAtPeriodEnd:
      isCurrentUserSubscribedToContentAuthorAtPeriodEnd,
    isArtistCanReceivePayments: !!isAuthorCanReceivePayments,

    createUserFollowNode: (
      <div css={styles(theme).view.footer.buttons.follow}>
        <ButtonMedium
          type="button"
          aria-label="follow author"
          disabled={followDisabled}
          appearance="primary"
          onClick={() => dispatch(likeCreateUserFollow({ user: { ...postAuthor } }))}
        >
          Follow
        </ButtonMedium>
      </div>
    ),
    deleteUserFollowNode: (
      <div css={styles(theme).view.footer.buttons.unfollow}>
        <ButtonMedium
          type="button"
          aria-label="unfollow author"
          disabled={unfollowDisabled}
          appearance="secondary"
          onClick={() => dispatch(likeDeleteUserFollow({ user: { ...postAuthor } }))}
        >
          Unfollow
        </ButtonMedium>
      </div>
    ),
    createSubscriptionNode: (
      <div css={styles(theme).view.footer.buttons.subscribe}>
        <ButtonMedium
          type="button"
          aria-label="subscribe on author"
          disabled={subscribeDisabled}
          appearance="primary"
          onClick={() =>
            dispatch(
              subscriptionOpenSubscriptionCreateModal({ artist: { ...postAuthor } }),
            )
          }
        >
          Subscribe
        </ButtonMedium>
      </div>
    ),
    renewSubscriptionNode: (
      <div css={styles(theme).view.footer.buttons.subscribe}>
        <ButtonMedium
          type="button"
          aria-label="subscribe on author"
          disabled={subscribeDisabled}
          appearance="primary"
          onClick={() =>
            dispatch(
              subscriptionOpenSubscriptionRenewModal({ artist: { ...postAuthor } }),
            )
          }
        >
          Subscribe
        </ButtonMedium>
      </div>
    ),
    cancelSubscriptionNode: (
      <div css={styles(theme).view.footer.buttons.unsubscribe}>
        <ButtonMedium
          type="button"
          aria-label="unsubscribe from author"
          disabled={unsubscribeDisabled}
          appearance="secondary"
          onClick={() =>
            dispatch(
              subscriptionOpenSubscriptionCancelModal({ artist: { ...postAuthor } }),
            )
          }
        >
          Unsubscribe
        </ButtonMedium>
      </div>
    ),
    defaultNode: <div />,
  }

  // if post found
  if (postFetched && post) {
    return (
      <div css={styles(theme).view.main}>
        {/* media */}
        <div css={styles(theme).view.media.main}>{renderPostMedia()}</div>

        {/* message */}
        <div css={styles(theme).view.message.main}>
          <span css={styles(theme).view.message.text}>{message}</span>
        </div>

        {/* author */}
        <div css={styles(theme).view.footer.main}>
          <div css={styles(theme).view.footer.author.main}>
            <div css={styles(theme).view.footer.author.avatar.main}>
              {authorHasAvatar && !authorAvatarLoadError ? (
                <img
                  css={styles(theme).view.footer.author.avatar.image}
                  onError={() => setAuthorAvatarLoadError(true)}
                  loading="lazy"
                  width="30px"
                  height="30px"
                  src={authorAvatar}
                  alt="post author avatar"
                />
              ) : (
                <icons.User css={styles(theme).view.footer.author.avatar.icon} />
              )}
            </div>
            <div css={styles(theme).view.footer.author.info.main}>
              <button
                css={styles(theme).view.footer.author.info.name}
                onClick={() => navigate(`${Path.User}/${postAuthorUserId}`)}
                type="button"
                aria-label="explore author profile"
              >
                {isPostAuthorLoading
                  ? userLoadingPlaceholder
                  : authorName || userNamePlaceholder}
              </button>
              <button
                css={styles(theme).view.footer.author.info.username}
                onClick={() => navigate(`${Path.User}/${postAuthorUserId}`)}
                type="button"
                aria-label="explore author profile"
              >
                {isPostAuthorLoading
                  ? userLoadingPlaceholder
                  : `@${authorUsername || userUsernamePlaceholder}`}
              </button>
            </div>
          </div>
          <div css={styles(theme).view.footer.buttons.main}>
            {!isCurrentUserContentAuthor &&
              userUtilRenderArtistActions(renderArtistActionsInput)}

            {!isCurrentUserContentAuthor && isAuthorCanReceivePayments && (
              <div css={styles(theme).view.footer.buttons.tip}>
                <ButtonMedium
                  type="button"
                  aria-label="send tip to author"
                  disabled={tipDisabled}
                  appearance="primary"
                  onClick={() => dispatch(tipOpenTipModal({ artist: { ...postAuthor } }))}
                >
                  Tip
                </ButtonMedium>
              </div>
            )}

            {!isCurrentUserContentAuthor &&
              (isCurrentUserAdmin ? (
                <div css={styles(theme).view.footer.buttons.block}>
                  <ButtonMedium
                    type="button"
                    aria-label="block post"
                    disabled={blockDisabled}
                    appearance="secondary"
                    onClick={() => dispatch(postOpenPostBlockModal({ post }))}
                  >
                    Block
                  </ButtonMedium>
                </div>
              ) : (
                <div css={styles(theme).view.footer.buttons.report}>
                  <ButtonMedium
                    type="button"
                    aria-label="report post"
                    disabled={reportDisabled}
                    appearance="secondary"
                    onClick={() => dispatch(reportOpenReportPostModal({ post }))}
                  >
                    Report
                  </ButtonMedium>
                </div>
              ))}
          </div>
          <div css={styles(theme).view.footer.info.main}>
            <div css={styles(theme).view.footer.info.comments.main}>
              <div css={styles(theme).view.footer.info.comments.buttons}>
                {isPostCommented ? (
                  <button
                    css={[
                      styles(theme).view.footer.info.comments.button.shared,
                      styles(theme).view.footer.info.comments.button.commented,
                    ]}
                    onClick={() => {}}
                    type="button"
                    aria-label="comment on post"
                  >
                    <icons.CommentFilled />
                  </button>
                ) : (
                  <button
                    css={[
                      styles(theme).view.footer.info.comments.button.shared,
                      styles(theme).view.footer.info.comments.button.uncommented,
                    ]}
                    onClick={() => {}}
                    type="button"
                    aria-label="comment on post"
                  >
                    <icons.Comment />
                  </button>
                )}
              </div>
              <div css={styles(theme).view.footer.info.comments.count}>
                {numbersFormatter(commentsCount || 0)}
              </div>
            </div>
            <div css={styles(theme).view.footer.info.likes.main}>
              <div css={styles(theme).view.footer.info.likes.buttons}>
                {isPostLiked ? (
                  <button
                    css={[
                      styles(theme).view.footer.info.likes.button.shared,
                      styles(theme).view.footer.info.likes.button.dislike,
                    ]}
                    onClick={() => dispatch(likeDeletePostLike({ post }))}
                    type="button"
                    aria-label="dislike post"
                  >
                    <icons.HeartFilled />
                  </button>
                ) : (
                  <button
                    css={[
                      styles(theme).view.footer.info.likes.button.shared,
                      styles(theme).view.footer.info.likes.button.like,
                    ]}
                    onClick={() => dispatch(likeCreatePostLike({ post }))}
                    type="button"
                    aria-label="like post"
                  >
                    <icons.Heart />
                  </button>
                )}
              </div>
              <div css={styles(theme).view.footer.info.likes.count}>
                {numbersFormatter(likesCount || 0)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // else render skeleton
  return (
    <div css={styles(theme).view.main}>
      <SkeletonVertical
        containerWidth="100%"
        sections={[
          {
            name: `post-media-view-post-media`,
            width: '100%',
            height: '400px',
            isStatic: true,
            isDivider: false,
          },
          {
            name: `post-media-view-post-divider`,
            width: '100%',
            height: '16px',
            isStatic: true,
            isDivider: true,
          },
          {
            name: `post-media-view-post-description`,
            width: '100%',
            height: '88.4px',
            isStatic: false,
            isDivider: false,
          },
        ]}
      />
    </div>
  )
}

PostViewMediaPost.propTypes = {}

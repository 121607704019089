import { css, Theme } from '@emotion/react'
import { helpers, fonts, typography, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  button: {
    main: css`
      ${helpers.resetDefaults};

      display: flex;
      align-items: center;

      font-family: ${fonts.primary};
      font-size: ${typography.p};
      font-weight: bold;
      white-space: nowrap;

      border-radius: 20px;
      padding: 12px 32px;
      cursor: pointer;

      width: 100%;
      overflow: hidden;

      transition: border 0.2s, color 0.2s, background-color 0.2s;

      ${mq.tablet(css`
        justify-content: center;
      `)}

      ${mq.mobile(css`
        padding: 8px 26px;
      `)}
    `,
    enabled: css`
      cursor: pointer;

      color: ${theme.colors.secondary.normal};
      border: 1px solid ${theme.colors.secondary.normal};

      &:hover {
        border: 1px solid ${theme.colors.accent.normal};
      }

      & > div:nth-of-type(1) > svg > g > circle {
        fill: ${theme.colors.secondary.normal};
      }

      & > div:nth-of-type(1) > svg > circle {
        fill: ${theme.colors.secondary.normal};
      }
    `,
    disabled: css`
      cursor: not-allowed;

      color: ${theme.colors.secondary.darker};
      border: 1px solid ${theme.colors.secondary.darker};

      & > div:nth-of-type(1) > svg > g > circle {
        fill: ${theme.colors.secondary.darker};
      }

      & > div:nth-of-type(1) > svg > circle {
        fill: ${theme.colors.secondary.darker};
      }
    `,

    content: {
      icon: css`
        display: flex;
        align-items: center;

        margin-right: 32px;

        & > svg {
          width: 32px;
          height: 32px;
        }

        ${mq.mobile(css`
          margin-right: 26px;
        `)}
      `,

      text: {
        main: css`
          display: flex;
          flex-direction: column;
          justify-content: center;

          min-width: 0;
        `,

        text: css`
          ${helpers.textOverflowEllipsis};
        `,
      },
    },
  },
})

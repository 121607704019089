import { createAsyncThunk } from '@reduxjs/toolkit'
import { EntityTrackView, EntityTrackVisibility } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // report
  reportSetReportEntityBlocked,
  // track
  trackSetPublicTracks,
  trackSetPopularTracks,
  trackSetExclusiveTracks,
  trackSetLatestTracks,
  trackSetTrendingTracks,
  trackSetRecommendedTracks,
  trackSetLikedTracks,
  trackSetUnlockedTracks,
  trackSetUploadedTracks,
  trackSetArtistPopularTracks,
  trackSetArtistExclusiveTracks,
  trackSetArtistLatestTracks,
  trackSetPostTracks,
  trackUpdateTrack,
  trackFilterTrack,
  trackCloseTrackBlockModal,
  trackCloseTrackDeleteModal,
  // player
  playerAudioFilterPlaylistTrack,
  playerAudioUpdatePlaylistTrack,
} from '@/features'

export const trackGetPublicTracks = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('track/trackGetPublicTracks', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve tracks
    const { tracks, count } = await api.trackApi.getPublicTracks()

    // set public tracks
    dispatch(
      trackSetPublicTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetPopularTracks = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetPopularTracks', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve tracks
    const { tracks, count } = await api.trackApi.getPopularTracks(
      undefined,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set popular tracks
    dispatch(
      trackSetPopularTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetExclusiveTracks = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetExclusiveTracks', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve tracks
    const { tracks, count } = await api.trackApi.getExclusiveTracks(
      undefined,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set exclusive tracks
    dispatch(
      trackSetExclusiveTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetLatestTracks = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetLatestTracks', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve tracks
    const { tracks, count } = await api.trackApi.getLatestTracks(
      undefined,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set latest tracks
    dispatch(
      trackSetLatestTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetTrendingTracks = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetTrendingTracks', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve tracks
    const { tracks, count } = await api.trackApi.getTrendingTracks(
      undefined,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set trending tracks
    dispatch(
      trackSetTrendingTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetRecommendedTracks = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetRecommendedTracks', async (inp, { dispatch, getState }) => {
  // get user profile
  const { profile } = getState().auth
  const userPreferredGenres = profile?.preferredGenres?.join(',')

  // get api service
  const api = apiGetService()

  try {
    // retrieve recommended tracks
    const { tracks, count } = await api.trackApi.getRecommendedTracks(
      userPreferredGenres,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set recommended tracks
    dispatch(
      trackSetRecommendedTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetLikedTracks = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetLikedTracks', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve liked tracks
    const { tracks, count } = await api.trackApi.getLikedTracks(
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set liked tracks
    dispatch(
      trackSetLikedTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetUnlockedTracks = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetUnlockedTracks', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve liked tracks
    const { tracks, count } = await api.trackApi.getUnlockedTracks(
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set liked tracks
    dispatch(
      trackSetUnlockedTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetUploadedTracks = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetUploadedTracks', async (inp, { getState, dispatch }) => {
  // get api service
  const api = apiGetService()

  // get currently logged in user id
  const { profile } = getState().auth
  const userId = profile?.id || ''

  try {
    // retrieve tracks
    const { tracks, count } = await api.trackApi.getArtistTracks(
      userId,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set uploaded tracks
    dispatch(
      trackSetUploadedTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetArtistPopularTracks = createAsyncThunk<
  { error: string },
  { artistId?: string; limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetArtistPopularTracks', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve tracks
    const { tracks, count } = await api.trackApi.getPopularTracks(
      inp.artistId,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set tracks
    dispatch(
      trackSetArtistPopularTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetArtistExclusiveTracks = createAsyncThunk<
  { error: string },
  { artistId?: string; limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetArtistExclusiveTracks', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve tracks
    const { tracks, count } = await api.trackApi.getExclusiveTracks(
      inp.artistId,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set tracks
    dispatch(
      trackSetArtistExclusiveTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetArtistLatestTracks = createAsyncThunk<
  { error: string },
  { artistId?: string; limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('track/trackGetArtistLatestTracks', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve tracks
    const { tracks, count } = await api.trackApi.getLatestTracks(
      inp.artistId,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set tracks
    dispatch(
      trackSetArtistLatestTracks({
        tracks: tracks || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackGetPostTracks = createAsyncThunk<
  { error: string },
  { trackIds: string[] },
  { state: RootState; rejectValue: undefined }
>('track/trackGetPostTracks', async ({ trackIds }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve tracks
    const { tracks } = await api.trackApi.getTracksByIds(trackIds?.join(','))

    // set post tracks
    dispatch(
      trackSetPostTracks({
        tracks: tracks || [],
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackCreate = createAsyncThunk<
  { error: string },
  {
    name: string
    track?: File
    cover?: File
    genres?: string[]
    visibility?: EntityTrackVisibility
    unlockPrice?: number
  },
  { state: RootState; rejectValue: undefined }
>(
  'track/trackCreate',
  async ({ name, track, cover, genres, visibility, unlockPrice }, { dispatch }) => {
    // get api service
    const api = apiGetService()

    try {
      // create form data
      const formData = new FormData()

      // append name
      formData.append('name', name)

      // if track provided
      if (track) {
        // append track file
        formData.append('track', track)
      }

      // if cover provided
      if (cover) {
        // append cover file
        formData.append('cover', cover)
      }

      // if genres provided
      if (genres && genres.length > 0) {
        // append genres
        formData.append('genres', genres.join(','))
      }

      // if visibility provided
      if (visibility) {
        // append visibility
        formData.append('visibility', visibility.toString())
      }

      // if visibility is subscribers
      // and unlock price provided
      if (visibility === EntityTrackVisibility.Subscribers && unlockPrice) {
        // append unlock price in cents
        // if unlockPrice = 1 (one dollar)
        // 1 * 100 = 100 (hundred cents)
        formData.append('unlockPrice', (unlockPrice * 100).toString())
      }

      // create track
      await api.trackApi.createTrack({ body: formData })

      // refetch uploaded tracks
      dispatch(trackGetUploadedTracks({ limit: 30, offset: 0 }))

      // launch info alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Info,
            message: 'Successfully uploaded track!',
          },
        }),
      )

      // return empty payload
      return {
        error: '',
      }
    } catch (err: any) {
      const { message } = await apiHandleError(err)

      // launch error alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Error,
            message: `Failed to upload track: ${message}`,
          },
        }),
      )

      // return error message in payload
      return {
        error: message,
      }
    }
  },
)

export const trackUpdate = createAsyncThunk<
  { error: string },
  {
    track: EntityTrackView
    name?: string
    cover?: File
    genres?: string[]
  },
  { state: RootState; rejectValue: undefined }
>('track/trackUpdate', async ({ track, name, cover, genres }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // create form data
    const formData = new FormData()

    // append track id
    formData.append('trackId', track.id || '')

    // if name provided
    if (name) {
      // append name
      formData.append('name', name)
    }

    // if cover provided
    if (cover) {
      // append cover
      formData.append('cover', cover)
    }

    // if genres provided
    if (genres && genres.length > 0) {
      // append genres
      formData.append('genres', genres.join(','))
    }

    // update track
    const { track: updatedTrack } = await api.trackApi.updateTrack({ body: formData })

    // update track in track state
    dispatch(trackUpdateTrack({ updatedTrack: updatedTrack || {} }))

    // update track in player state
    dispatch(playerAudioUpdatePlaylistTrack({ updatedTrack: updatedTrack || {} }))

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully updated track!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to update track: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackBlock = createAsyncThunk<
  { error: string },
  { id: string },
  { state: RootState; rejectValue: undefined }
>('track/trackBlock', async ({ id }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // block track
    await api.trackApi.blockTrack({ trackId: id })

    // filter blocked track from tracks
    dispatch(trackFilterTrack({ id }))

    // filter blocked track from audio player
    dispatch(playerAudioFilterPlaylistTrack({ id }))

    // set track as blocked in report state
    dispatch(reportSetReportEntityBlocked())

    // close track block modal
    dispatch(trackCloseTrackBlockModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully blocked track!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to block track: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const trackDelete = createAsyncThunk<
  { error: string },
  { id: string },
  { state: RootState; rejectValue: undefined }
>('track/trackDelete', async ({ id }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // delete track
    await api.trackApi.deleteTrack(id)

    // filter deleted track from tracks
    dispatch(trackFilterTrack({ id }))

    // filter deleted track from audio player
    dispatch(playerAudioFilterPlaylistTrack({ id }))

    // close track delete modal
    dispatch(trackCloseTrackDeleteModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully deleted track!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to delete track: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

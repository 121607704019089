// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// import { getAnalytics } from 'firebase/analytics'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  User,
} from 'firebase/auth'

// config
import { Environment, firebaseConfigProd, firebaseConfigStag } from '@/config'

import { firebaseAuthErrors } from './errors'

// initialize firebase app
const app = initializeApp(
  process.env.ENVIRONMENT === Environment.Production
    ? firebaseConfigProd
    : firebaseConfigStag,
)

// initialize firebase analytics
// export const firebaseAnalytics = getAnalytics(app)

// initialize firebase authentication
const firebaseAuth = getAuth(app)
firebaseAuth.useDeviceLanguage()

// firebaseHandleError - handles firebase errors.
const firebaseHandleError = (err: any) => {
  // retrieve firebase error message
  const errorCode = err?.code as keyof typeof firebaseAuthErrors
  const errorMessage = firebaseAuthErrors[errorCode]

  return errorMessage || 'Authentication error occurred.'
}

// FirebaseAuthData - represents information required for user authentication.
type FirebaseAuthData = {
  idToken: string
  emailVerified: boolean
  errorMessage: string
}

// FirebaseResponse - represents firebase response data.
type FirebaseResponse = {
  errorMessage: string
}

// firebaseSignupWithEmailAndPassword - sign user up with email and password.
export const firebaseSignupWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<FirebaseAuthData> => {
  try {
    // create user with email and password
    const res = await createUserWithEmailAndPassword(firebaseAuth, email, password)

    // send email verification
    await sendEmailVerification(res.user)

    // get user id token
    const idToken = await firebaseAuth.currentUser?.getIdToken()
    const emailVerified = firebaseAuth.currentUser?.emailVerified

    return {
      idToken: idToken || '',
      emailVerified: emailVerified || false,
      errorMessage: '',
    }
  } catch (err) {
    // handle firebase error
    const errorMessage = firebaseHandleError(err)

    return {
      idToken: '',
      emailVerified: false,
      errorMessage,
    }
  }
}

// firebaseLoginWithEmailAndPassword - logs user in with email and password.
export const firebaseLoginWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<FirebaseAuthData> => {
  try {
    // sign in with email and password
    await signInWithEmailAndPassword(firebaseAuth, email, password)

    // get user id token
    const idToken = await firebaseAuth.currentUser?.getIdToken()
    const emailVerified = firebaseAuth.currentUser?.emailVerified

    return {
      idToken: idToken || '',
      emailVerified: emailVerified || false,
      errorMessage: '',
    }
  } catch (err: any) {
    // handle firebase error
    const errorMessage = firebaseHandleError(err)

    return {
      idToken: '',
      emailVerified: false,
      errorMessage,
    }
  }
}

// firebaseLoginWithGoogle - log user in with google credentials.
export const firebaseLoginWithGoogle = async (): Promise<FirebaseAuthData> => {
  // initialize google provider
  const googleProvider = new GoogleAuthProvider()
  googleProvider.setCustomParameters({
    prompt: 'select_account',
  })

  // add required scopes
  googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile')
  googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email')
  // googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly')

  try {
    // sign in with popup
    await signInWithPopup(firebaseAuth, googleProvider)

    // get google credentials
    // const creds = GoogleAuthProvider.credentialFromResult(res)

    // get user id token
    const idToken = await firebaseAuth.currentUser?.getIdToken()
    const emailVerified = firebaseAuth.currentUser?.emailVerified

    return {
      idToken: idToken || '',
      emailVerified: emailVerified || false,
      errorMessage: '',
    }
  } catch (err: any) {
    // handle firebase error
    const errorMessage = firebaseHandleError(err)

    return {
      idToken: '',
      emailVerified: false,
      errorMessage,
    }
  }
}

// firebaseLoginWithFacebook - log user in with facebook credentials.
export const firebaseLoginWithFacebook = async (): Promise<FirebaseAuthData> => {
  // initialize facebook provider
  const facebookProvider = new FacebookAuthProvider()
  facebookProvider.setCustomParameters({
    prompt: 'select_account',
    display: 'popup',
  })

  // add required scopes
  facebookProvider.addScope('public_profile')
  facebookProvider.addScope('email')

  try {
    // sign in with popup
    await signInWithPopup(firebaseAuth, facebookProvider)

    // get facebook credentials
    // const creds = FacebookAuthProvider.credentialFromResult(res)

    // get user id token
    const idToken = await firebaseAuth.currentUser?.getIdToken()
    const emailVerified = firebaseAuth.currentUser?.emailVerified

    return {
      idToken: idToken || '',
      emailVerified: emailVerified || false,
      errorMessage: '',
    }
  } catch (err: any) {
    // handle firebase error
    const errorMessage = firebaseHandleError(err)

    return {
      idToken: '',
      emailVerified: false,
      errorMessage,
    }
  }
}

// firebaseSendPasswordResetEmail - sends password reset email.
export const firebaseSendPasswordResetEmail = async (
  email: string,
): Promise<FirebaseResponse> => {
  try {
    // send email
    await sendPasswordResetEmail(firebaseAuth, email)

    return {
      errorMessage: '',
    }
  } catch (err: any) {
    // handle firebase error
    const errorMessage = firebaseHandleError(err)

    return {
      errorMessage,
    }
  }
}

// firebaseSendVerificationEmail - sends verification email.
export const firebaseSendVerificationEmail = async () => {
  try {
    // get current user
    const user = firebaseAuth.currentUser

    // send email verification
    await sendEmailVerification(user!)

    return {
      errorMessage: '',
    }
  } catch (err: any) {
    // handle firebase error
    const errorMessage = firebaseHandleError(err)

    return {
      errorMessage,
    }
  }
}

// firebaseSignout - signs user out.
export const firebaseSignout = async (): Promise<FirebaseResponse> => {
  try {
    // sign user out from firebase
    await signOut(firebaseAuth)

    return {
      errorMessage: '',
    }
  } catch (err: any) {
    // handle firebase error
    const errorMessage = firebaseHandleError(err)

    return {
      errorMessage,
    }
  }
}

// FirebaseGetEmailVerifiedResponse - represents firebaseGetEmailVerified response data.
type FirebaseGetEmailVerifiedResponse = {
  emailVerified: boolean
  errorMessage: string
}

// firebaseGetCurrentUserState - wraps observer to get current user state status.
const firebaseGetCurrentUserState = (): Promise<User | null> =>
  new Promise((res) => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (authUser) => {
      res(authUser)
      unsubscribe()
    })
  })

// firebaseGetEmailVerified - checks if current user email is verified.
export const firebaseGetEmailVerified =
  async (): Promise<FirebaseGetEmailVerifiedResponse> => {
    try {
      // get current user
      const user = await firebaseGetCurrentUserState()

      // reload user data
      await user?.reload()

      // check email verification status
      const emailVerified = firebaseAuth.currentUser?.emailVerified

      return {
        emailVerified: emailVerified!,
        errorMessage: '',
      }
    } catch (err: any) {
      // handle firebase error
      const errorMessage = firebaseHandleError(err)

      return {
        emailVerified: false,
        errorMessage,
      }
    }
  }

import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  button: {
    shared: css`
      ${helpers.resetDefaults};

      display: flex;
      align-items: center;

      & > svg > path {
        transition: fill 0.2s;
      }
    `,
    enabled: css`
      cursor: pointer;

      &:hover > svg > path {
        fill: ${theme.colors.accent.normal};
      }
    `,
    disabled: css`
      cursor: not-allowed;

      & > svg > path {
        fill: ${theme.colors.secondary.darker};
      }
    `,
  },
})

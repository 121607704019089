import { css, Theme } from '@emotion/react'
import { typography, mq, helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  view: {
    main: css`
      ${helpers.app.padding.sides};

      margin-bottom: 50px;
    `,

    user: {
      main: css`
        ${helpers.app.screen.width.full};
      `,

      profile: {
        main: css`
          display: flex;

          margin-bottom: 24px;

          ${mq.mobile(css`
            flex-direction: column;
            // align-items: center;
          `)}
        `,
        avatar: {
          main: css`
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            flex-shrink: 0;

            width: 100px;
            height: 100px;

            background-color: ${theme.colors.primary.darker};
            border-radius: 50%;
            overflow: hidden;

            margin-right: 16px;

            ${mq.mobile(css`
              margin-right: 0px;
              margin-bottom: 16px;
            `)}
          `,
          image: css`
            ${helpers.image};
          `,
          icon: css`
            width: 36px;
            height: 36px;

            & > path {
              fill: ${theme.colors.secondary.normal};
            }
          `,
        },
        content: {
          main: css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            min-width: 0;

            ${mq.mobile(css`
              text-align: center;
            `)}
          `,
          data: {
            main: css`
              display: flex;
              flex-direction: column;

              min-width: 0;

              margin-bottom: 4px;
            `,
            name: css`
              ${helpers.textOverflowEllipsis};

              font-size: ${typography.h6};
              font-weight: bold;
            `,
            username: css`
              ${helpers.textOverflowEllipsis};

              font-size: ${typography.p};
            `,
          },
          genres: css`
            ${helpers.textOverflowEllipsis}

            font-size: ${typography.p};
          `,
        },
      },

      buttons: {
        main: css`
          display: flex;
          flex-wrap: wrap;
          min-height: 56px;

          & > div {
            margin-right: 20px;
            margin-bottom: 12px;
          }

          ${mq.mobile(css`
            flex-direction: column;
            flex-wrap: nowrap;

            & > div {
              margin-right: 0px;
              margin-bottom: 12px;
            }
          `)}
        `,
        follow: {
          main: css`
            // width: 160px;

            ${mq.mobile(css`
              width: 100%;
            `)}
          `,
          content: {
            main: css`
              display: flex;
              align-items: center;
            `,
            icon: css`
              flex-shrink: 0;

              width: 22px;
              height: 22px;
              margin-right: 12px;

              & > svg {
                & > path {
                  fill: ${theme.colors.secondary.normal};
                }
              }
            `,
            text: css`
              ${mq.mobile(css`
                ${helpers.textOverflowEllipsis};
              `)}
            `,
          },
        },
        unfollow: {
          main: css`
            ${mq.mobile(css`
              width: 100%;
            `)}
          `,
          content: {
            main: css`
              display: flex;
              align-items: center;
            `,
            icon: css`
              flex-shrink: 0;

              width: 22px;
              height: 22px;
              margin-right: 12px;

              & > svg {
                & > path {
                  fill: ${theme.colors.secondary.normal};
                }
              }
            `,
            text: css`
              ${mq.mobile(css`
                ${helpers.textOverflowEllipsis};
              `)}
            `,
          },
        },
        subscribe: {
          main: css`
            ${mq.mobile(css`
              width: 100%;
            `)}
          `,
          content: {
            main: css`
              display: flex;
              align-items: center;
            `,
            icon: css`
              flex-shrink: 0;

              width: 22px;
              height: 22px;
              margin-right: 12px;

              & > svg {
                color: ${theme.colors.primary.normal};
              }
            `,
            text: css`
              ${mq.mobile(css`
                ${helpers.textOverflowEllipsis};
              `)}
            `,
          },
        },
        unsubscribe: {
          main: css`
            ${mq.mobile(css`
              width: 100%;
            `)}
          `,
          content: {
            main: css`
              display: flex;
              align-items: center;
            `,
            icon: css`
              flex-shrink: 0;

              width: 22px;
              height: 22px;
              margin-right: 12px;

              & > svg {
                color: ${theme.colors.primary.normal};
              }
            `,
            text: css`
              ${mq.mobile(css`
                ${helpers.textOverflowEllipsis};
              `)}
            `,
          },
        },
        tip: {
          main: css`
            ${mq.mobile(css`
              width: 100%;
            `)}
          `,
          content: {
            main: css`
              display: flex;
              align-items: center;
            `,
            icon: css`
              flex-shrink: 0;

              width: 22px;
              height: 22px;
              margin-right: 12px;

              & > svg {
                & > path {
                  fill: ${theme.colors.primary.normal};
                }
              }
            `,
            text: css`
              ${mq.mobile(css`
                ${helpers.textOverflowEllipsis};
              `)}
            `,
          },
        },
        block: {
          main: css`
            ${mq.mobile(css`
              width: 100%;
            `)}
          `,
          content: {
            main: css`
              display: flex;
              align-items: center;
            `,
            icon: css`
              flex-shrink: 0;

              width: 22px;
              height: 22px;
              margin-right: 12px;

              & > svg {
                & > path {
                  fill: ${theme.colors.secondary.normal};
                }
              }
            `,
            text: css`
              ${mq.mobile(css`
                ${helpers.textOverflowEllipsis};
              `)}
            `,
          },
        },
        report: {
          main: css`
            ${mq.mobile(css`
              width: 100%;
            `)}
          `,
          content: {
            main: css`
              display: flex;
              align-items: center;
            `,
            icon: css`
              flex-shrink: 0;

              width: 22px;
              height: 22px;
              margin-right: 12px;

              & > svg {
                & > path {
                  fill: ${theme.colors.secondary.normal};
                }
              }
            `,
            text: css`
              ${mq.mobile(css`
                ${helpers.textOverflowEllipsis};
              `)}
            `,
          },
        },
      },
    },
  },
})

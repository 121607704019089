import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  commentSelectCommentDeleteModalOpen,
  commentCloseCommentDeleteModal,
} from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { CommentDeleteCard } from './components'
// styles
import { styles } from './styles'

type CommentDeleteModalProps = {}

export const CommentDeleteModal: FC<CommentDeleteModalProps> = () => {
  const dispatch = useAppDispatch()
  const deleteCommentModalOpen = useAppSelector(commentSelectCommentDeleteModalOpen)

  return (
    <div css={styles().modal.main}>
      {deleteCommentModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(commentCloseCommentDeleteModal())}
        >
          <div css={styles().modal.content}>
            <CommentDeleteCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

CommentDeleteModal.propTypes = {}

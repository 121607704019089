// import { FC, useState } from 'react'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  authOpenLoginModal,
  authOpenSignupModal,
  authSelectProfile,
  navigationAppNavigationToggle,
  navigationSelectAppNavigationOpen,
  // searchSetTerm,
  // searchSelectTerm,
} from '@/features'
// icons
import { logos, icons } from '@/assets'
// components
import { ButtonSmall } from '@/components'

// components
// import { Notifications, SearchInput, UserMenu } from './components'
import { Notifications, UserMenu } from './components'
// styles
import { styles } from './styles'

type AppHeaderProps = {}

export const AppHeader: FC<AppHeaderProps> = () => {
  const theme = useTheme()
  const { pathname } = useLocation()

  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const isNavigationOpen = useAppSelector(navigationSelectAppNavigationOpen)

  // TODO: consider to make search global or local
  // const searchTerm = useAppSelector(searchSelectTerm)
  // const [searchTerm, setSearchTerm] = useState('')

  return (
    <header css={styles(theme).header.main}>
      {/* logo for tablet view */}
      <div css={styles(theme).header.logo}>
        <logos.RyddmLogo />
      </div>

      {/* search for library and charts */}
      {pathname === Path.Library || pathname === Path.Charts ? (
        <div css={styles(theme).header.search}>
          {/* <SearchInput
            name="search"
            label="search"
            type="search"
            placeholder="Search"
            value={searchTerm}
            icon={icons.Search}
            onChange={(e) => {
              // local - useState (better)
              setSearchTerm(e.target.value)

              // global - redux (not good)
              // dispatch(searchSetTerm(e.target.value))

              // if pathname === library
              // debounce send request to get x with search term
              // if pathname === charts
              // debounce send request to get x with search term
            }}
          /> */}
        </div>
      ) : (
        <div />
      )}

      {/* avatar and notifications for authenticated users || buttons for non authenticated */}
      {profile ? (
        <div css={styles(theme).header.actions.main}>
          <div css={styles(theme).header.actions.notifications}>
            <Notifications />
          </div>
          <div css={styles(theme).header.actions.avatar}>
            <UserMenu />
          </div>
        </div>
      ) : (
        <div css={styles(theme).header.buttons}>
          <div>
            <ButtonSmall
              aria-label="sign up"
              appearance="secondary"
              onClick={() => dispatch(authOpenSignupModal())}
            >
              Sign up
            </ButtonSmall>
          </div>
          <div>
            <ButtonSmall
              aria-label="log in"
              appearance="primary"
              onClick={() => dispatch(authOpenLoginModal())}
            >
              Log in
            </ButtonSmall>
          </div>
        </div>
      )}

      {/* nav menu for mobile and tablet view */}
      <div css={styles(theme).header.menu.main}>
        {profile && (
          <div css={styles(theme).header.menu.notifications}>
            <Notifications />
          </div>
        )}
        <div css={styles(theme).header.menu.actions.main}>
          <button
            css={styles(theme).header.menu.actions.button}
            onClick={() => dispatch(navigationAppNavigationToggle())}
            type="button"
            aria-label="toggle navigation menu"
          >
            {isNavigationOpen ? (
              <icons.CrossCircle
                css={[
                  styles(theme).header.menu.actions.icon.shared,
                  styles(theme).header.menu.actions.icon.close,
                ]}
              />
            ) : (
              <icons.MenuLines
                css={[
                  styles(theme).header.menu.actions.icon.shared,
                  styles(theme).header.menu.actions.icon.open,
                ]}
              />
            )}
          </button>
        </div>
      </div>
    </header>
  )
}

AppHeader.propTypes = {}

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  subscriptionSelectSubscriptionCreateModalOpen,
  subscriptionCloseSubscriptionCreateModal,
} from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { SubscriptionCreateCard } from './components'
// styles
import { styles } from './styles'

type SubscriptionCreateModalProps = {}

export const SubscriptionCreateModal: FC<SubscriptionCreateModalProps> = () => {
  const dispatch = useAppDispatch()
  const subscriptionCreateModalOpen = useAppSelector(
    subscriptionSelectSubscriptionCreateModalOpen,
  )

  return (
    <div css={styles().modal.main}>
      {subscriptionCreateModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(subscriptionCloseSubscriptionCreateModal())}
        >
          <div css={styles().modal.content}>
            <SubscriptionCreateCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

SubscriptionCreateModal.propTypes = {}

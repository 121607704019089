import { FC, useState } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type SelectOption = {
  id: string
  label: string
  value: string
}

interface InputSelectProps {
  placeholder: SelectOption
  options: SelectOption[]
  setValue: (value: string) => void
}

export const InputSelect: FC<InputSelectProps> = ({ placeholder, options, setValue }) => {
  const theme = useTheme()

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<SelectOption>(placeholder)

  return (
    <div css={styles(theme).input.main}>
      {/* select */}
      <div
        css={[
          styles(theme).input.header.main.shared,
          isOpen && styles(theme).input.header.main.open,
        ]}
        onClick={() => setIsOpen((state) => !state)}
        aria-hidden="true"
      >
        <div
          css={styles(theme).input.header.option}
          key={`${selectedOption.id}-selected`}
          aria-label={selectedOption.label}
        >
          {selectedOption.label}
        </div>
        <div
          css={[
            styles(theme).input.header.icon.shared,
            isOpen && styles(theme).input.header.icon.open,
          ]}
        >
          <icons.CaretDown />
        </div>
      </div>

      {/* options */}
      <div
        css={[
          styles(theme).input.options.main.shared,
          isOpen && styles(theme).input.options.main.open,
        ]}
      >
        {options.map((option) => {
          const { id, label, value } = option

          return (
            <div
              css={[
                styles(theme).input.options.option.shared,
                selectedOption.id === id && styles(theme).input.options.option.selected,
              ]}
              key={id}
              aria-label={label}
              onClick={() => {
                setSelectedOption(option)
                setIsOpen(false)
                setValue(value)
              }}
              aria-hidden="true"
            >
              {label}
            </div>
          )
        })}
      </div>
    </div>
  )
}

InputSelect.propTypes = {}

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// components
import { Loader } from '@/components'

// styles
import { styles } from './styles'

type SuspenseContentFallbackProps = {
  position?: 'fit' | 'full'
}

export const SuspenseContentFallback: FC<SuspenseContentFallbackProps> = ({
  position = 'full',
}) => {
  const theme = useTheme()

  return (
    <div
      css={[
        styles(theme).fallback.shared,
        position === 'fit' && styles(theme).fallback.fit,
        position === 'full' && styles(theme).fallback.full,
      ]}
    >
      <Loader width={80} height={40} appearance="accent" />
    </div>
  )
}

SuspenseContentFallback.propTypes = {}

import { EntityNotificationEvent, EntityUserView } from '@ryddm-inc/ryddm-apiclient'

// utils
import { numbersFormatter } from '@/utils'
// icons
import { icons } from '@/assets'

export const getNotificationTitle = (event: EntityNotificationEvent): string => {
  // USER

  if (event === EntityNotificationEvent.AppUserFollowCreated) {
    return 'started following you'
  }

  // TRACK

  if (event === EntityNotificationEvent.AppTrackCreated) {
    return 'released new track'
  }

  if (event === EntityNotificationEvent.AppTrackBlocked) {
    return 'Your track is blocked'
  }

  if (event === EntityNotificationEvent.AppTrackLikeCreated) {
    return 'liked your track'
  }

  // POST

  if (event === EntityNotificationEvent.AppPostCreated) {
    return 'shared new post'
  }

  if (event === EntityNotificationEvent.AppPostBlocked) {
    return 'Your post is blocked'
  }

  if (event === EntityNotificationEvent.AppPostLikeCreated) {
    return 'liked your post'
  }

  // COMMENT

  if (event === EntityNotificationEvent.AppCommentCreated) {
    return 'commented on your post'
  }

  if (event === EntityNotificationEvent.AppCommentReplyCreated) {
    return 'replied to your comment'
  }

  if (event === EntityNotificationEvent.AppCommentBlocked) {
    return 'Your comment is blocked'
  }

  if (event === EntityNotificationEvent.AppCommentLikeCreated) {
    return 'liked your comment'
  }

  // TIP

  if (event === EntityNotificationEvent.AppTipPaymentSucceeded) {
    return 'Tip payment succeeded'
  }

  if (event === EntityNotificationEvent.AppTipPaymentFailed) {
    return 'Tip payment failed'
  }

  if (event === EntityNotificationEvent.AppTipCreated) {
    return 'has sent you a tip'
  }

  // TRACK UNLOCK

  if (event === EntityNotificationEvent.AppTrackUnlockPaymentSucceeded) {
    return 'Unlock payment succeeded'
  }

  if (event === EntityNotificationEvent.AppTrackUnlockPaymentFailed) {
    return 'Unlock payment failed'
  }

  if (event === EntityNotificationEvent.AppTrackUnlockCreated) {
    return 'unlocked your track'
  }

  // POST UNLOCK

  if (event === EntityNotificationEvent.AppPostUnlockPaymentSucceeded) {
    return 'Unlock payment succeeded'
  }

  if (event === EntityNotificationEvent.AppPostUnlockPaymentFailed) {
    return 'Unlock payment failed'
  }

  if (event === EntityNotificationEvent.AppPostUnlockCreated) {
    return 'unlocked your post'
  }

  // SUBSCRIPTION

  if (event === EntityNotificationEvent.AppSubscriptionPaymentSucceeded) {
    return 'Subscription payment succeeded'
  }

  if (event === EntityNotificationEvent.AppSubscriptionPaymentFailed) {
    return 'Subscription payment failed'
  }

  if (event === EntityNotificationEvent.AppSubscriptionCreated) {
    return 'subscribed to you'
  }

  // PAYOUT

  if (event === EntityNotificationEvent.AppPayoutPaid) {
    return 'Payout payment succeeded'
  }

  if (event === EntityNotificationEvent.AppPayoutFailed) {
    return 'Payout payment failed'
  }

  return ''
}

export const getNotificationOthers = (
  sourceUserIds: string[],
  sourceUserIdsCount: number,
): string => {
  if (sourceUserIds.length >= 3 && sourceUserIdsCount > 3) {
    return `and ${numbersFormatter(sourceUserIdsCount - sourceUserIds.length)} other(s)`
  }

  return ''
}

export const formantNotificationSources = (sourceUsers: EntityUserView[]) =>
  sourceUsers.reduce(
    (prev, { username }) => (prev === '' ? `@${username}` : `${prev}, @${username}`),
    '',
  )

export const getNotificationIcon = (event: EntityNotificationEvent) => {
  // TRACK

  if (event === EntityNotificationEvent.AppTrackBlocked) {
    return icons.AlertTriangle
  }

  // POST

  if (event === EntityNotificationEvent.AppPostBlocked) {
    return icons.AlertTriangle
  }

  // COMMENT

  if (event === EntityNotificationEvent.AppCommentBlocked) {
    return icons.AlertTriangle
  }

  // TIP

  if (event === EntityNotificationEvent.AppTipPaymentSucceeded) {
    return icons.DollarCircle
  }

  if (event === EntityNotificationEvent.AppTipPaymentFailed) {
    return icons.AlertTriangle
  }

  // UNLOCK TRACK

  if (event === EntityNotificationEvent.AppTrackUnlockPaymentSucceeded) {
    return icons.Unlock
  }

  if (event === EntityNotificationEvent.AppTrackUnlockPaymentFailed) {
    return icons.AlertTriangle
  }

  // UNLOCK POST

  if (event === EntityNotificationEvent.AppPostUnlockPaymentSucceeded) {
    return icons.Unlock
  }

  if (event === EntityNotificationEvent.AppPostUnlockPaymentFailed) {
    return icons.AlertTriangle
  }

  // SUBSCRIPTION

  if (event === EntityNotificationEvent.AppSubscriptionPaymentSucceeded) {
    return icons.Unlock
  }

  if (event === EntityNotificationEvent.AppSubscriptionPaymentFailed) {
    return icons.AlertTriangle
  }

  // PAYOUT

  if (event === EntityNotificationEvent.AppPayoutPaid) {
    return icons.DollarCircle
  }

  if (event === EntityNotificationEvent.AppPayoutFailed) {
    return icons.AlertTriangle
  }

  return icons.CheckCircle
}

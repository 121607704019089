import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type BalanceProps = {
  amount: number
  subtitle: string
}

export const Balance: FC<BalanceProps> = ({ amount, subtitle }) => {
  const theme = useTheme()
  return (
    <div css={styles(theme).balance.main}>
      <div css={styles(theme).balance.amount}>${amount / 100}</div>
      <div css={styles(theme).balance.subtitle}>{subtitle}</div>
    </div>
  )
}

Balance.propTypes = {}

import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useUserPublic, useFile } from '@/hooks'
// features
import {
  // report
  reportSelectReportComplaint,
  reportSelectReportComplaintFetched,
} from '@/features'
// components
import { ContentPlaceholder, SkeletonHorizontal } from '@/components'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type ReportComplaintViewReportComplaintProps = {}

export const ReportComplaintViewReportComplaint: FC<
  ReportComplaintViewReportComplaintProps
> = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const reportComplaint = useAppSelector(reportSelectReportComplaint)
  const reportComplaintFetched = useAppSelector(reportSelectReportComplaintFetched)

  const {
    userId: reportComplaintAuthorUserId,
    complaint,
    complaintType,
  } = reportComplaint ?? {}

  // report complaint author
  const { user: reportComplaintAuthor, isLoading: isReportComplaintAuthorLoading } =
    useUserPublic(reportComplaintAuthorUserId || '')
  const { name: authorName, avatarId: authorAvatarId } = reportComplaintAuthor ?? {}
  const authorLoadingPlaceholder = 'Loading...'
  const authorNamePlaceholder = '-'

  const authorHasAvatar = !!authorAvatarId
  const authorAvatar = useFile(authorAvatarId || '')
  const [authorAvatarLoadError, setAuthorAvatarLoadError] = useState(false)

  // if report complaint not found
  if (reportComplaintFetched && !reportComplaint) {
    return (
      <div css={styles(theme).view.main}>
        <ContentPlaceholder
          icon={icons.AlertTriangle}
          height="133px"
          title="Could not find report complaint"
        />
      </div>
    )
  }

  // if report complaint found
  if (reportComplaintFetched && reportComplaint) {
    return (
      <div css={styles(theme).view.main}>
        <div css={styles(theme).view.data.main}>
          <div css={styles(theme).view.data.avatar.main}>
            {authorHasAvatar && !authorAvatarLoadError ? (
              <img
                css={styles(theme).view.data.avatar.image}
                onError={() => setAuthorAvatarLoadError(true)}
                loading="lazy"
                width="30px"
                height="30px"
                src={authorAvatar}
                alt="post author avatar"
              />
            ) : (
              <icons.User css={styles(theme).view.data.avatar.icon} />
            )}
          </div>
          <div css={styles(theme).view.data.content.main}>
            <div css={styles(theme).view.data.content.name}>
              <button
                css={styles(theme).view.data.content.name}
                onClick={() => navigate(`${Path.User}/${reportComplaintAuthorUserId}`)}
                type="button"
                aria-label="explore author profile"
              >
                <span>
                  {isReportComplaintAuthorLoading
                    ? authorLoadingPlaceholder
                    : authorName || authorNamePlaceholder}
                </span>
              </button>
            </div>
            <div css={styles(theme).view.data.content.complaint}>{complaint}</div>
          </div>
        </div>
        <div css={styles(theme).view.type.main}>
          <div css={styles(theme).view.type.text}>
            {complaintType?.toString().replace('_', ' ')}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div css={styles(theme).view.main}>
      <SkeletonHorizontal
        sections={[
          {
            name: 'report-complaint-view-report-complaint',
            width: '100%',
            height: '133px',
            isStatic: false,
            isDivider: false,
          },
        ]}
      />
    </div>
  )
}

ReportComplaintViewReportComplaint.propTypes = {}

import { FC, InputHTMLAttributes } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface LoaderProps extends InputHTMLAttributes<HTMLInputElement> {
  width: number
  height: number

  appearance?: 'primary' | 'secondary' | 'accent'
}

export const Loader: FC<LoaderProps> = ({ width, height, appearance = 'primary' }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).loader.main}>
      <div
        css={[
          styles(theme).loader.bars.shared(width, height),

          appearance === 'primary' && styles(theme).loader.bars.primary(width, height),
          appearance === 'secondary' &&
            styles(theme).loader.bars.secondary(width, height),
          appearance === 'accent' && styles(theme).loader.bars.accent(width, height),
        ]}
      />
    </div>
  )
}

Loader.propTypes = {}

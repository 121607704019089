import { css, Theme } from '@emotion/react'
import { z, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  stepper: {
    main: css`
      position: relative;
    `,

    progress: css`
      z-index: ${z.onboarding.stepper.progress};

      position: absolute;
      top: 18px;
      left: 12%;

      width: 76%;
      height: 4px;

      background-color: ${theme.colors.accent.normal};

      ${mq.tablet(css`
        top: 14px;
      `)}

      ${mq.mobile(css`
        height: 2px;
        top: 15px;
      `)}
    `,

    steps: {
      main: css`
        z-index: ${z.onboarding.stepper.circle};

        display: flex;
        position: relative;
      `,

      step: {
        main: css`
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
        `,
        icon: {
          main: css`
            margin-bottom: 16px;
          `,
          circle: {
            shared: css`
              display: flex;
              align-items: center;
              justify-content: center;

              width: 40px;
              height: 40px;
              border-radius: 50%;

              border: 4px solid transparent;

              ${mq.tablet(css`
                width: 36px;
                height: 36px;
              `)}

              ${mq.mobile(css`
                width: 32px;
                height: 32px;
              `)}
            `,
            checked: css`
              background-color: ${theme.colors.accent.normal};
              border: 4px solid ${theme.colors.primary.normal};
            `,
            current: css`
              background-color: ${theme.colors.accent.normal};
              border: 6px solid ${theme.colors.primary.normal};
            `,
            following: css`
              background-color: ${theme.colors.primary.normal};
              border: 2px solid ${theme.colors.accent.normal};
            `,
          },
          check: css`
            width: 24px;
            height: 24px;

            & > path {
              fill: ${theme.colors.primary.normal};
            }
          `,
        },
        description: css`
          text-align: center;
          word-break: break-word;
          max-width: 98px;

          ${mq.tablet(css`
            max-width: 88px;
          `)}

          ${mq.mobile(css`
            max-width: 78px;
          `)}
        `,
      },
    },
  },
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import { EntityConnectStatus } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // transaction
  transactionGetOutgoingTransactions,
} from '@/features'

// types
import { ConnectLinkType } from './types'

export const connectGetConnectStatus = createAsyncThunk<
  { error: string; status: EntityConnectStatus | undefined },
  undefined,
  { state: RootState; rejectValue: undefined }
>('connect/connectGetConnectStatus', async () => {
  // get api service
  const api = apiGetService()

  try {
    // get connect status
    const { status } = await api.connectApi.getConnectStatus()

    // return payload
    return {
      status,
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      status: undefined,
      error: message,
    }
  }
})

export const connectGetConnectBalance = createAsyncThunk<
  { error: string; balance: { available: number; pending: number } },
  undefined,
  { state: RootState; rejectValue: undefined }
>('connect/connectGetConnectBalance', async () => {
  // get api service
  const api = apiGetService()

  try {
    // get connect balance
    const { availableBalance, pendingBalance } = await api.connectApi.getConnectBalance()

    // return payload
    return {
      balance: {
        available: availableBalance || 0,
        pending: pendingBalance || 0,
      },
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      balance: {
        available: 0,
        pending: 0,
      },
      error: message,
    }
  }
})

export const connectGetConnectOnboardingLink = createAsyncThunk<
  { error: string; onboardingLink: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('connect/connectGetConnectOnboardingLink', async () => {
  // get api service
  const api = apiGetService()

  try {
    // get connect onboarding link
    const { link } = await api.connectApi.getConnectLink(ConnectLinkType.Onboarding)

    // return balance payload
    return {
      onboardingLink: link || '',
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      onboardingLink: '',
      error: message,
    }
  }
})

export const connectGetConnectLoginLink = createAsyncThunk<
  { error: string; loginLink: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('connect/connectGetConnectLoginLink', async () => {
  // get api service
  const api = apiGetService()

  try {
    // get connect update link
    const { link } = await api.connectApi.getConnectLoginLink()

    // return payload
    return {
      loginLink: link || '',
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      loginLink: '',
      error: message,
    }
  }
})

export const connectCreatePayout = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('connect/connectCreatePayout', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // create payout
    await api.connectApi.createConnectPayout()

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully created payout!',
        },
      }),
    )

    // fetch outgoing transactions
    dispatch(transactionGetOutgoingTransactions({ limit: 30, offset: 0 }))

    // fetch balance
    dispatch(connectGetConnectBalance())

    // return payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to create payout: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

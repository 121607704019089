import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityTrackView, EntityTrackVisibility } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile } from '@/hooks'
// icon
import { icons } from '@/assets'
// utils
import { numbersFormatter } from '@/utils'

// styles
import { styles } from './styles'

type TrackCardUploadedProps = {
  track: EntityTrackView
  editDisabled?: boolean
  deleteDisabled?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  onEditClick?: MouseEventHandler<HTMLButtonElement>
  onDeleteClick?: MouseEventHandler<HTMLButtonElement>
}

export const TrackCardUploaded: FC<TrackCardUploadedProps> = ({
  track,
  editDisabled = false,
  deleteDisabled = false,
  onClick,
  onEditClick = undefined,
  onDeleteClick = undefined,
}) => {
  const theme = useTheme()
  const [coverLoadError, setCoverLoadError] = useState(false)

  const { coverId, name: trackName, genres, visibility, likesCount, unlocksCount } = track

  const trackHasCover = !!coverId
  const source = useFile(coverId || '')

  return (
    <div css={styles(theme).track.main}>
      {/* track cover */}
      <div css={styles(theme).track.cover.main}>
        <div css={styles(theme).track.cover.overlay.main}>
          <button
            css={styles(theme).track.cover.overlay.button}
            onClick={(e) => onClick(e)}
            type="button"
            aria-label="play track"
          >
            <icons.Play />
          </button>
        </div>

        {trackHasCover && !coverLoadError ? (
          <img
            css={styles(theme).track.cover.image}
            onError={() => setCoverLoadError(true)}
            loading="lazy"
            width="60px"
            height="60px"
            src={source}
            alt="track album cover"
          />
        ) : (
          <div css={styles(theme).track.cover.placeholder}>
            <icons.MusicNote />
          </div>
        )}
      </div>

      {/* track description */}
      <div css={styles(theme).track.description.main}>
        <div css={styles(theme).track.description.name}>{trackName}</div>
        <div css={styles(theme).track.description.author}>
          {genres?.length
            ? genres?.map((genre, i) =>
                genres.length - 1 === i ? `${genre}` : `${genre}, `,
              )
            : `-`}
        </div>
        <div css={styles(theme).track.description.stats.main}>
          <div css={styles(theme).track.description.stats.likes.main}>
            <div css={styles(theme).track.description.stats.likes.icon}>
              <icons.Heart />
            </div>
            <div css={styles(theme).track.description.stats.likes.count}>
              {numbersFormatter(likesCount || 0)}
            </div>
          </div>

          {visibility === EntityTrackVisibility.Subscribers && (
            <div css={styles(theme).track.description.stats.unlocks.main}>
              <div css={styles(theme).track.description.stats.unlocks.icon}>
                <icons.Lock />
              </div>
              <div css={styles(theme).track.description.stats.unlocks.count}>
                {numbersFormatter(unlocksCount || 0)}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* options */}
      <div css={styles(theme).track.options.main}>
        {onEditClick && (
          <div css={styles(theme).track.options.edit.main}>
            <button
              css={[
                styles(theme).track.options.edit.button.shared,
                editDisabled
                  ? styles(theme).track.options.edit.button.disabled
                  : styles(theme).track.options.edit.button.enabled,
              ]}
              onClick={(e) => onEditClick(e)}
              disabled={editDisabled}
              type="button"
              aria-label="edit track"
            >
              <icons.Edit css={styles(theme).track.options.edit.icon} />
            </button>
          </div>
        )}

        {onDeleteClick && (
          <div css={styles(theme).track.options.delete.main}>
            <button
              css={[
                styles(theme).track.options.delete.button.shared,
                deleteDisabled
                  ? styles(theme).track.options.delete.button.disabled
                  : styles(theme).track.options.delete.button.enabled,
              ]}
              onClick={(e) => onDeleteClick(e)}
              disabled={deleteDisabled}
              type="button"
              aria-label="delete track"
            >
              <icons.Trash css={styles(theme).track.options.delete.icon} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

TrackCardUploaded.propTypes = {}

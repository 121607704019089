import { FC, useEffect, useState, useRef, ChangeEvent } from 'react'

// config
import { minTrackNameLength, maxTrackNameLength } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // onboarding
  onboardingSelectLoading,
  onboardingSetNextStage,
  // track
  trackSelectUploadedTracksLength,
  trackSelectTrackCreateLoading,
  trackCreate,
} from '@/features'
// utils
import { validateCoverFile, validateTrackFile, validateTrackName } from '@/utils'
// components
import { Loader, InputBar, ButtonSmall, ButtonLarge, Error } from '@/components'

// components
import { Cover, Track } from './components'
// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const dispatch = useAppDispatch()

  const onboardingLoading = useAppSelector(onboardingSelectLoading)
  const uploadedTracksLength = useAppSelector(trackSelectUploadedTracksLength)
  const trackCreateLoading = useAppSelector(trackSelectTrackCreateLoading)

  // cover state
  const [cover, setCover] = useState<File | undefined>(undefined)
  const [coverPreview, setCoverPreview] = useState('')
  const coverFileInputRef = useRef<HTMLInputElement | null>(null)
  const [coverError, setCoverError] = useState('')

  // track state
  const [track, setTrack] = useState<File | undefined>(undefined)
  const [trackFileName, setTrackFileName] = useState('')
  const trackFileInputRef = useRef<HTMLInputElement | null>(null)
  const [trackError, setTrackError] = useState('')

  // name state
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')

  const submitDisabled = onboardingLoading || trackCreateLoading

  const uploadDisabled =
    name.length < minTrackNameLength ||
    name.length > maxTrackNameLength ||
    track === undefined ||
    coverError !== '' ||
    trackError !== '' ||
    nameError !== '' ||
    trackCreateLoading ||
    onboardingLoading

  const revokePreviewURL = (url: string) => {
    URL.revokeObjectURL(url)
  }

  const cleanupState = () => {
    setCover(undefined)
    setTrack(undefined)
    setName('')
    setTrackFileName('')

    // if cover preview was set
    if (coverPreview) {
      // clean up to avoid memory leaks
      revokePreviewURL(coverPreview)
      setCoverPreview('')
    }
  }

  const onCoverClick = () => {
    coverFileInputRef.current?.click()
    setCoverError('')
  }

  const onTrackClick = () => {
    trackFileInputRef.current?.click()
    setTrackError('')
  }

  const onCoverFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    // if file selected
    if (files && files.length > 0) {
      const file = files[0]

      // validate file
      const error = validateCoverFile(file)
      if (error) {
        setCoverError(error)
        return
      }

      // if cover preview was set
      if (coverPreview) {
        // clean up to avoid memory leaks
        revokePreviewURL(coverPreview)
      }

      // set file as a preview
      setCoverPreview(URL.createObjectURL(file))

      // store file in state
      setCover(file)
    }
  }

  const onTrackFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    // if file selected
    if (files && files.length > 0) {
      const file = files[0]

      // validate file
      const error = validateTrackFile(file)
      if (error) {
        setTrackError(error)
        return
      }

      // store file name in state
      setTrackFileName(file.name)

      // store file in state
      setTrack(file)
    }
  }

  useEffect(() => () => {
    // if cover preview was set
    if (coverPreview) {
      // clean up to avoid memory leaks
      revokePreviewURL(coverPreview)
    }
  })

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(onboardingSetNextStage())
        }}
      >
        <div css={styles().form.content.inputs.main}>
          <div css={styles().form.content.inputs.cover.main}>
            <label css={styles().form.content.inputs.cover.label} htmlFor="cover">
              cover
              <input
                css={styles().form.content.inputs.cover.input}
                name="cover"
                type="file"
                id="cover"
                ref={coverFileInputRef}
                accept="image/jpeg,image/webp,image/png"
                onChange={(e) => onCoverFileChange(e)}
              />
            </label>
            <Cover onClick={() => onCoverClick()} source={coverPreview} />
          </div>
          <div css={styles().form.content.inputs.track.main}>
            <div css={styles().form.content.inputs.track.name.main}>
              <InputBar
                name="name"
                label="name"
                type="text"
                placeholder="Enter track name"
                value={name}
                error={nameError.length > 0}
                errorMessage={nameError}
                min={minTrackNameLength}
                max={maxTrackNameLength}
                onChange={(e) => {
                  setName(e.target.value)
                }}
                onBlur={() => setNameError(validateTrackName(name))}
                onFocus={() => setNameError('')}
              />
            </div>
            <div css={styles().form.content.inputs.track.file.main}>
              <label css={styles().form.content.inputs.track.file.label} htmlFor="track">
                track
                <input
                  css={styles().form.content.inputs.track.file.input}
                  name="track"
                  type="file"
                  id="track"
                  ref={trackFileInputRef}
                  accept=".mp3"
                  onChange={(e) => onTrackFileChange(e)}
                />
              </label>
              <Track onClick={() => onTrackClick()} fileName={trackFileName} />
            </div>
          </div>
          <div css={styles().form.content.inputs.upload}>
            <ButtonSmall
              type="button"
              aria-label="upload track"
              appearance="primary"
              disabled={uploadDisabled}
              onClick={() => {
                dispatch(trackCreate({ name, genres: [], track, cover }))
                cleanupState()
              }}
            >
              {trackCreateLoading ? (
                <Loader width={40} height={20} appearance="primary" />
              ) : (
                'Upload'
              )}
            </ButtonSmall>
          </div>
        </div>

        <div css={styles().form.content.error}>
          <Error error={coverError || trackError} />
        </div>

        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="proceed to next onboarding stage"
            disabled={submitDisabled}
            appearance={uploadedTracksLength > 0 ? 'primary' : 'secondary'}
          >
            {onboardingLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Next'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

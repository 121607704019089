import { css, Theme } from '@emotion/react'

export const styles = (theme: Theme) => ({
  link: css`
    color: ${theme.colors.secondary.dark};
    transition: color 0.2s;
    text-decoration: none;
    cursor: pointer;

    &:link,
    &:visited {
    }

    &:hover,
    &:active {
      color: ${theme.colors.secondary.darkHover};
    }
  `,
})

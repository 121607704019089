import { css, Theme } from '@emotion/react'
import { mq, helpers, fonts, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  track: {
    main: css`
      position: relative;

      width: 100%;
    `,

    button: {
      shared: css`
        ${helpers.resetDefaults};

        width: 100%;
        height: 56px;

        font-family: ${fonts.primary};
        font-size: ${typography.h6};

        border-radius: 20px;
        padding: 14px 32px;

        transition: border 0.2s, color 0.2s;
        cursor: pointer;

        ${mq.mobile(css`
          height: 50px;
          padding: 12px 26px;
        `)}
      `,
      empty: css`
        border: 1px solid ${theme.colors.secondary.darkHover};
        color: ${theme.colors.secondary.darkHover};
      `,
      filled: css`
        border: 1px solid ${theme.colors.secondary.normal};
        color: ${theme.colors.secondary.normal};
      `,

      content: {
        main: css`
          display: flex;
        `,
        name: css`
          ${helpers.textOverflowEllipsis};

          margin-right: auto;
        `,
        icon: css`
          display: flex;
          align-items: center;

          margin-left: 6px;

          & > svg {
            width: 24px;
            height: 24px;
          }
        `,
      },
    },
  },
})

import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

// components
import { SuspenseContentFallback } from '@/components'
// utils
import { importsLazy } from '@/utils'

const { LockPage } = importsLazy(() => import('@/pages'), 'LockPage')

const App = () => (
  <Suspense fallback={<SuspenseContentFallback position="full" />}>
    <Outlet />
  </Suspense>
)

export const lockRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <LockPage />,
      },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
]

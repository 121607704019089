import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type ModalFooterDesktopProps = {
  onCloseClick: MouseEventHandler<HTMLButtonElement>
}

export const ModalFooterDesktop: FC<ModalFooterDesktopProps> = ({ onCloseClick }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).footer.main}>
      <button
        css={styles(theme).footer.button}
        onClick={(e) => onCloseClick(e)}
        type="button"
        aria-label="close modal"
      >
        Close
      </button>
    </div>
  )
}

ModalFooterDesktop.propTypes = {}

import { css, Theme } from '@emotion/react'
import { typography, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  method: {
    main: css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,

    type: css`
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      & > svg {
        height: 40px;
      }
    `,

    data: {
      main: css`
        display: flex;
        align-items: center;

        cursor: pointer;
        transition: color 0.2s;

        color: ${theme.colors.secondary.normal};

        & > div:not(:last-of-type) {
          margin-right: 16px;

          ${mq.mobile(css`
            margin-right: 10px;
          `)}
        }
      `,

      circles: css`
        font-size: ${typography.p};
      `,
      digits: css`
        font-weight: bold;
      `,
    },
  },
})

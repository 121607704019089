import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  EntityUser,
  EntityUserRole,
  EntityOnboardingStage,
} from '@ryddm-inc/ryddm-apiclient'

// features
import {
  // auth
  authSetPlatformUnlocked,
  // like
  likeGetFollowedUserIds,
  likeGetLikedPostIds,
  likeGetLikedTrackIds,
  likeGetLikedCommentIds,
  // notification
  notificationGetNotificationsUnreadCount,
  // onboarding
  onboardingSetCurrentStep,
  // subscription
  subscriptionGetSubscribedUserIds,
  subscriptionGetSubscribedAtPeriodEndUserIds,
  // unlock
  unlockGetUnlockedPostIds,
  unlockGetUnlockedTrackIds,
} from '@/features'
// libs
import {
  apiGetService,
  apiHandleError,
  firebaseSignupWithEmailAndPassword,
  firebaseLoginWithEmailAndPassword,
  firebaseLoginWithGoogle,
  firebaseLoginWithFacebook,
  firebaseSendPasswordResetEmail,
  firebaseSendVerificationEmail,
  firebaseGetEmailVerified,
  firebaseSignout,
  jwtDecode,
  jwtGetExpiresInDays,
  storageGetAuthToken,
  storageSetAuthToken,
  storageSetSentPasswordResetEmailDate,
  storageSetSentVerificationEmailDate,
} from '@/lib'

// config
import { RootState } from '@/stores'

// AuthData - represents authentication payload.
type AuthData = {
  profile: EntityUser | null
  emailVerified: boolean
  error: string
}

// authAuthenticateUser - encapsulates authentication logic
// used internally by other authentication mechanisms
export const authAuthenticateUser = createAsyncThunk<
  AuthData,
  { idToken: string; emailVerified: boolean },
  { state: RootState; rejectValue: undefined }
>('auth/authAuthenticateUser', async (inp, { dispatch }) => {
  // get api service
  let api = apiGetService()

  try {
    // exchange auth token
    const { token } = await api.authApi.exchangeToken({
      authToken: inp.idToken,
    })

    // save token to local storage
    storageSetAuthToken(token!)

    // update api service with auth token
    api = api.updateApiKey(token!)

    // get subscribed user ids
    dispatch(subscriptionGetSubscribedUserIds())
    dispatch(subscriptionGetSubscribedAtPeriodEndUserIds())
    // get unlocked entity ids
    dispatch(unlockGetUnlockedPostIds())
    dispatch(unlockGetUnlockedTrackIds())
    // get liked entity ids
    dispatch(likeGetFollowedUserIds())
    dispatch(likeGetLikedPostIds())
    dispatch(likeGetLikedTrackIds())
    dispatch(likeGetLikedCommentIds())
    // get notification unread count
    dispatch(notificationGetNotificationsUnreadCount())

    // fetch user profile
    const { user } = await api.userApi.getUserProfile()

    // if onboarding is not finished
    if (user?.onboardingStage !== EntityOnboardingStage.Finished) {
      const userRole = user?.role as EntityUserRole
      const currentOnboardingStage = user?.onboardingStage as EntityOnboardingStage

      // set appropriate onboarding step for stepper
      dispatch(onboardingSetCurrentStep({ userRole, currentOnboardingStage }))
    }

    // return profile and token in payload
    return {
      profile: user || {},
      emailVerified: inp.emailVerified,
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      profile: null,
      emailVerified: false,
      error: message,
    }
  }
})

export const authRefresh = createAsyncThunk<
  AuthData,
  undefined,
  { state: RootState; rejectValue: undefined }
>('auth/authRefresh', async (_, { dispatch }) => {
  // get auth token from storage
  const tokenFromStorage = storageGetAuthToken()

  // if no token in local storage
  if (!tokenFromStorage) {
    // return empty payload (sign user out)
    return {
      profile: null,
      emailVerified: false,
      error: '',
    }
  }

  // decode token
  const decodedToken = jwtDecode(tokenFromStorage)

  // calculate token expiration
  const expiresInDays = jwtGetExpiresInDays(decodedToken)
  const tokenExpired = expiresInDays <= 0
  const tokenExpiresSoon = expiresInDays > 0 && expiresInDays <= 7

  // check if email is verified
  const { emailVerified, errorMessage } = await firebaseGetEmailVerified()

  // if error occurred
  if (errorMessage) {
    // return empty payload (sign user out)
    return {
      profile: null,
      emailVerified: false,
      error: errorMessage,
    }
  }

  // get api service
  let api = apiGetService()

  // if token is expired
  if (tokenExpired) {
    // clean local storage
    storageSetAuthToken('')

    // return empty payload (sign user out)
    return {
      profile: null,
      emailVerified: false,
      error: '',
    }
  }

  try {
    // if token expires soon (in 7 days or less)
    if (tokenExpiresSoon) {
      // refresh token
      const { token: freshToken } = await api.authApi.refreshToken()

      // update token in local storage
      storageSetAuthToken(freshToken!)

      // update token in api service
      api = api.updateApiKey(freshToken!)
    }

    // get subscribed user ids
    dispatch(subscriptionGetSubscribedUserIds())
    dispatch(subscriptionGetSubscribedAtPeriodEndUserIds())
    // get unlocked entity ids
    dispatch(unlockGetUnlockedPostIds())
    dispatch(unlockGetUnlockedTrackIds())
    // get liked entity ids
    dispatch(likeGetFollowedUserIds())
    dispatch(likeGetLikedPostIds())
    dispatch(likeGetLikedTrackIds())
    dispatch(likeGetLikedCommentIds())
    // get notification unread count
    dispatch(notificationGetNotificationsUnreadCount())

    // fetch profile
    const { user } = await api.userApi.getUserProfile()

    // if onboarding is not finished
    if (user?.onboardingStage !== EntityOnboardingStage.Finished) {
      const userRole = user?.role as EntityUserRole
      const currentOnboardingStage = user?.onboardingStage as EntityOnboardingStage

      // set appropriate onboarding step for stepper
      dispatch(onboardingSetCurrentStep({ userRole, currentOnboardingStage }))
    }

    // return profile and token in payload
    return {
      profile: user || {},
      emailVerified: emailVerified!,
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      profile: null,
      emailVerified: false,
      error: message,
    }
  }
})

export const authSignupWithEmailAndPassword = createAsyncThunk<
  { error: string },
  { email: string; password: string },
  { state: RootState; rejectValue: undefined }
>('auth/authSignupWithEmailAndPassword', async (inp, { dispatch }) => {
  // firebase sign up with email and password
  const { idToken, emailVerified, errorMessage } =
    await firebaseSignupWithEmailAndPassword(inp.email, inp.password)

  // update trigger date
  storageSetSentVerificationEmailDate(Date.now().toString())

  // if error occurred during authentication
  if (errorMessage) {
    // return error message in payload
    return {
      error: errorMessage,
    }
  }

  // authenticate user
  dispatch(authAuthenticateUser({ idToken, emailVerified }))

  return {
    error: '',
  }
})

export const authLoginWithEmailAndPassword = createAsyncThunk<
  { error: string },
  { email: string; password: string },
  { state: RootState; rejectValue: undefined }
>('auth/authLoginWithEmailAndPassword', async (inp, { dispatch }) => {
  // firebase login with email and password
  const { idToken, emailVerified, errorMessage } =
    await firebaseLoginWithEmailAndPassword(inp.email, inp.password)

  // if error occurred during authentication
  if (errorMessage) {
    // return error message in payload
    return {
      error: errorMessage,
    }
  }

  // authenticate user
  dispatch(authAuthenticateUser({ idToken, emailVerified }))

  return {
    error: '',
  }
})

export const authLoginWithGoogle = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('auth/authLoginWithGoogle', async (_, { dispatch }) => {
  // firebase login with google
  const { idToken, emailVerified, errorMessage } = await firebaseLoginWithGoogle()

  // if error occurred during authentication
  if (errorMessage) {
    // return error message in payload
    return {
      error: errorMessage,
    }
  }

  // authenticate user
  dispatch(authAuthenticateUser({ idToken, emailVerified }))

  return {
    error: '',
  }
})

export const authLoginWithFacebook = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('auth/authLoginWithFacebook', async (_, { dispatch }) => {
  // firebase login with facebook
  const { idToken, emailVerified, errorMessage } = await firebaseLoginWithFacebook()

  // if error occurred during authentication
  if (errorMessage) {
    // return error message in payload
    return {
      error: errorMessage,
    }
  }

  // authenticate user
  dispatch(authAuthenticateUser({ idToken, emailVerified }))

  return {
    error: '',
  }
})

export const authSendPasswordResetEmail = createAsyncThunk<
  { error: string },
  { email: string },
  { state: RootState; rejectValue: undefined }
>('auth/authSendPasswordResetEmail', async (inp) => {
  // firebase send password reset email
  const { errorMessage } = await firebaseSendPasswordResetEmail(inp.email)

  // update trigger date
  storageSetSentPasswordResetEmailDate(Date.now().toString())

  return {
    error: errorMessage,
  }
})

export const authSendVerificationEmail = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('auth/authSendVerificationEmail', async () => {
  // firebase send verification email
  const { errorMessage } = await firebaseSendVerificationEmail()

  // update trigger date
  storageSetSentVerificationEmailDate(Date.now().toString())

  return {
    error: errorMessage,
  }
})

export const authSignout = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('auth/authSignout', async () => {
  // firebase sign out
  const { errorMessage } = await firebaseSignout()

  // clean up token from local storage
  storageSetAuthToken('')

  // state is reset in authSignout.fulfilled

  // return error message in payload
  return {
    error: errorMessage,
  }
})

// AuthCheckVerificationStatusResponse - represents auth response payload.
type AuthCheckVerificationStatusResponse = {
  emailVerified: boolean
  error: string
}

export const authCheckVerificationStatus = createAsyncThunk<
  AuthCheckVerificationStatusResponse,
  undefined,
  { state: RootState; rejectValue: undefined }
>('auth/authCheckVerificationStatus', async () => {
  // check email verification status
  const { emailVerified, errorMessage } = await firebaseGetEmailVerified()

  return {
    emailVerified,
    error: errorMessage,
  }
})

export const authUnlockPlatform = createAsyncThunk<
  { error: string },
  { passphrase: string },
  { state: RootState; rejectValue: undefined }
>('auth/authUnlockPlatform', async (inp, { dispatch }) => {
  // assert passphrase
  const passphrase = 'ryddm2024'
  if (inp.passphrase === passphrase) {
    dispatch(authSetPlatformUnlocked())

    return {
      error: '',
    }
  }

  return {
    error: 'Invalid passphrase',
  }
})

import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/stores'

export interface NavigationState {
  app: {
    navigation: {
      open: boolean
    }
  }
  library: {
    categories: {
      likedTracks: boolean
      likedPosts: boolean
      unlockedTracks: boolean
      unlockedPosts: boolean
      followedArtists: boolean
      subscribedArtists: boolean
    }
  }
}

const initialState: NavigationState = {
  app: {
    navigation: {
      open: false,
    },
  },
  library: {
    categories: {
      likedTracks: true,
      likedPosts: false,
      unlockedTracks: false,
      unlockedPosts: false,
      followedArtists: false,
      subscribedArtists: false,
    },
  },
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    // app navigation
    navigationAppNavigationToggle: (state) => ({
      ...state,
      app: {
        navigation: {
          open: !state.app.navigation.open,
        },
      },
    }),
    navigationAppNavigationClose: (state) => ({
      ...state,
      app: {
        navigation: {
          open: false,
        },
      },
    }),

    // library navigation
    navigationLibraryCategoriesToggleLikedTracks: (state) => ({
      ...state,
      library: {
        categories: {
          ...state.library.categories,
          likedTracks: !state.library.categories.likedTracks,
        },
      },
    }),
    navigationLibraryCategoriesToggleLikedPosts: (state) => ({
      ...state,
      library: {
        categories: {
          ...state.library.categories,
          likedPosts: !state.library.categories.likedPosts,
        },
      },
    }),
    navigationLibraryCategoriesToggleUnlockedTracks: (state) => ({
      ...state,
      library: {
        categories: {
          ...state.library.categories,
          unlockedTracks: !state.library.categories.unlockedTracks,
        },
      },
    }),
    navigationLibraryCategoriesToggleUnlockedPosts: (state) => ({
      ...state,
      library: {
        categories: {
          ...state.library.categories,
          unlockedPosts: !state.library.categories.unlockedPosts,
        },
      },
    }),
    navigationLibraryCategoriesToggleFollowedArtists: (state) => ({
      ...state,
      library: {
        categories: {
          ...state.library.categories,
          followedArtists: !state.library.categories.followedArtists,
        },
      },
    }),
    navigationLibraryCategoriesToggleSubscribedArtists: (state) => ({
      ...state,
      library: {
        categories: {
          ...state.library.categories,
          subscribedArtists: !state.library.categories.subscribedArtists,
        },
      },
    }),
  },
})

export const {
  navigationAppNavigationToggle,
  navigationAppNavigationClose,

  navigationLibraryCategoriesToggleLikedTracks,
  navigationLibraryCategoriesToggleLikedPosts,
  navigationLibraryCategoriesToggleUnlockedTracks,
  navigationLibraryCategoriesToggleUnlockedPosts,
  navigationLibraryCategoriesToggleFollowedArtists,
  navigationLibraryCategoriesToggleSubscribedArtists,
} = navigationSlice.actions

export const navigationSelectAppNavigationOpen = (state: RootState) =>
  state.navigation.app.navigation.open
export const navigationSelectLibraryCategories = (state: RootState) =>
  state.navigation.library.categories

export const navigationReducer = navigationSlice.reducer

// export default navigationSlice.reducer

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch, useInterval } from '@/hooks'
// features
import { authCheckVerificationStatus, authSelectEmailVerified } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { VerificationCard } from './components'
// styles
import { styles } from './AuthVerificationModal.styles'

type AuthVerificationModalProps = {}

export const AuthVerificationModal: FC<AuthVerificationModalProps> = () => {
  const dispatch = useAppDispatch()
  const emailVerified = useAppSelector(authSelectEmailVerified)

  useInterval(() => {
    if (!emailVerified) {
      dispatch(authCheckVerificationStatus())
    }
  }, 4000)

  return (
    <div css={styles().modal.main}>
      <ModalLayout background="solid" onOutsideClick={() => {}}>
        <VerificationCard />
      </ModalLayout>
    </div>
  )
}

AuthVerificationModal.propTypes = {}

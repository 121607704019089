import { css, Theme } from '@emotion/react'
import { mq, helpers, constants } from '@/styles'

export const styles = (theme: Theme) => ({
  header: {
    main: css`
      display: none;
      flex-shrink: 0;

      display: flex;
      align-items: center;
      justify-content: space-between;
      height: ${constants.app.header.desktop.height};

      ${mq.mobile(css`
        height: ${constants.app.header.mobile.height};
      `)}
    `,
    menu: css`
      ${helpers.resetDefaults};

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      width: 40px;
      height: 40px;

      & > svg {
        width: 30px;
        height: 30px;
      }

      & > svg > path {
        transition: fill 0.2s;
        fill: ${theme.colors.secondary.light};
      }

      &:hover {
        & > svg > path {
          transition: fill 0.2s;
          fill: ${theme.colors.accent.normal};
        }
      }
    `,
  },
})

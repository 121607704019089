import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type TitleProps = {
  text: string
}

export const Title: FC<TitleProps> = ({ text }) => {
  const theme = useTheme()
  return <div css={styles(theme).label.main}>{text}</div>
}

Title.propTypes = {}

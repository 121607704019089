import { css } from '@emotion/react'

export const styles = () => ({
  form: {
    main: css``,
    content: {
      main: css``,
      avatar: {
        main: css`
          display: flex;
          align-items: center;
          justify-content: center;

          margin-bottom: 32px;
        `,
        label: css`
          display: none;
        `,
        input: css`
          display: none;
        `,
      },
      name: css`
        margin-bottom: 32px;
      `,
      username: css`
        margin-bottom: 50px;
      `,
      submit: css``,
    },
  },
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityTransactionView, EntityTransactionType } from '@ryddm-inc/ryddm-apiclient'

// utils
import { mergeArraysAtIndex } from '@/utils'
// stores
import { RootState } from '@/stores'
// features
import {
  transactionGetIncomeTransactions,
  transactionGetOutgoingTransactions,
} from '@/features'

export interface TransactionState {
  incomeTransactions: EntityTransactionView[]
  incomeTransactionsCount: number
  incomeTransactionsFetched: boolean

  outgoingTransactions: EntityTransactionView[]
  outgoingTransactionsCount: number
  outgoingTransactionsFetched: boolean

  incomeTransactionsFilter: EntityTransactionType | undefined
  outgoingTransactionsFilter: EntityTransactionType | undefined

  transactionGetIncomeTransactionsLoading: boolean
  transactionGetOutgoingTransactionsLoading: boolean
}

const initialState: TransactionState = {
  incomeTransactions: [],
  incomeTransactionsCount: 0,
  incomeTransactionsFetched: false,

  outgoingTransactions: [],
  outgoingTransactionsCount: 0,
  outgoingTransactionsFetched: false,

  incomeTransactionsFilter: undefined,
  outgoingTransactionsFilter: undefined,

  transactionGetIncomeTransactionsLoading: false,
  transactionGetOutgoingTransactionsLoading: false,
}

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    // reset state
    transactionResetState: () => ({
      incomeTransactions: [],
      incomeTransactionsCount: 0,
      incomeTransactionsFetched: false,

      outgoingTransactions: [],
      outgoingTransactionsCount: 0,
      outgoingTransactionsFetched: false,

      incomeTransactionsFilter: undefined,
      outgoingTransactionsFilter: undefined,

      transactionGetIncomeTransactionsLoading: false,
      transactionGetOutgoingTransactionsLoading: false,
    }),

    // set income transactions
    transactionSetIncomeTransactions: (
      state,
      action: PayloadAction<{
        transactions: EntityTransactionView[]
        count: number
        offset: number
      }>,
    ) => {
      const { transactions, count, offset } = action.payload

      // mutate count
      state.incomeTransactionsCount = count
      // mutate income transactions array
      state.incomeTransactions = mergeArraysAtIndex(
        state.incomeTransactions,
        transactions,
        offset,
      )
    },

    // set outgoing transactions
    transactionSetOutgoingTransactions: (
      state,
      action: PayloadAction<{
        transactions: EntityTransactionView[]
        count: number
        offset: number
      }>,
    ) => {
      const { transactions, count, offset } = action.payload

      // mutate count
      state.outgoingTransactionsCount = count
      // mutate outgoing transactions array
      state.outgoingTransactions = mergeArraysAtIndex(
        state.outgoingTransactions,
        transactions,
        offset,
      )
    },

    // set income transactions filter
    transactionSetIncomeTransactionsFilter: (
      state,
      action: PayloadAction<{ filter: EntityTransactionType }>,
    ) => {
      const { filter } = action.payload

      // cleanup income transactions
      state.incomeTransactionsCount = 0
      state.incomeTransactions = []

      // mutate income transactions filter
      state.incomeTransactionsFilter =
        state.incomeTransactionsFilter === filter ? undefined : filter
    },

    // set outgoing transactions filter
    transactionSetOutgoingTransactionsFilter: (
      state,
      action: PayloadAction<{ filter: EntityTransactionType }>,
    ) => {
      const { filter } = action.payload

      // cleanup outgoing transactions
      state.outgoingTransactionsCount = 0
      state.outgoingTransactions = []

      // mutate outgoing transactions filter
      state.outgoingTransactionsFilter =
        state.outgoingTransactionsFilter === filter ? undefined : filter
    },
  },
  extraReducers: (builder) => {
    // get income transactions
    builder
      .addCase(transactionGetIncomeTransactions.pending, (state) => ({
        ...state,
        transactionGetIncomeTransactionsLoading: true,
      }))
      .addCase(transactionGetIncomeTransactions.fulfilled, (state) => ({
        ...state,
        incomeTransactionsFetched: true,
        transactionGetIncomeTransactionsLoading: false,
      }))

    // get outgoing transactions
    builder
      .addCase(transactionGetOutgoingTransactions.pending, (state) => ({
        ...state,
        transactionGetOutgoingTransactionsLoading: true,
      }))
      .addCase(transactionGetOutgoingTransactions.fulfilled, (state) => ({
        ...state,
        outgoingTransactionsFetched: true,
        transactionGetOutgoingTransactionsLoading: false,
      }))
  },
})

export const {
  transactionResetState,
  transactionSetIncomeTransactions,
  transactionSetOutgoingTransactions,
  transactionSetIncomeTransactionsFilter,
  transactionSetOutgoingTransactionsFilter,
} = transactionSlice.actions

export const transactionSelectIncomeTransactions = (state: RootState) =>
  state.transaction.incomeTransactions
export const transactionSelectIncomeTransactionsLength = (state: RootState) =>
  state.transaction.incomeTransactions.length
export const transactionSelectIncomeTransactionsCount = (state: RootState) =>
  state.transaction.incomeTransactionsCount
export const transactionSelectIncomeTransactionsFetched = (state: RootState) =>
  state.transaction.incomeTransactionsFetched

export const transactionSelectOutgoingTransactions = (state: RootState) =>
  state.transaction.outgoingTransactions
export const transactionSelectOutgoingTransactionsLength = (state: RootState) =>
  state.transaction.outgoingTransactions.length
export const transactionSelectOutgoingTransactionsCount = (state: RootState) =>
  state.transaction.outgoingTransactionsCount
export const transactionSelectOutgoingTransactionsFetched = (state: RootState) =>
  state.transaction.outgoingTransactionsFetched

export const transactionSelectIncomeTransactionsFilter = (state: RootState) =>
  state.transaction.incomeTransactionsFilter
export const transactionSelectOutgoingTransactionsFilter = (state: RootState) =>
  state.transaction.outgoingTransactionsFilter

export const transactionSelectTransactionGetIncomeTransactionsLoading = (
  state: RootState,
) => state.transaction.transactionGetIncomeTransactionsLoading
export const transactionSelectTransactionGetOutgoingTransactionsLoading = (
  state: RootState,
) => state.transaction.transactionGetOutgoingTransactionsLoading

export const transactionReducer = transactionSlice.reducer

// export default transactionSlice.reducer

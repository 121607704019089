import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityUserView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useConnectReady } from '@/hooks'
// features
import { userUtilRenderArtistActions } from '@/features'
// icon
import { icons } from '@/assets'
// utils
import { numbersFormatter } from '@/utils'
// components
import { ButtonSmall } from '@/components'

// styles
import { styles } from './styles'

type ArtistCardProps = {
  artist: EntityUserView
  artistActions?: {
    isFollowed: boolean
    isSubscribed: boolean
    isSubscribedAtPeriodEnd: boolean
    isPublic?: boolean

    onFollowClick: MouseEventHandler<HTMLButtonElement>
    onUnfollowClick: MouseEventHandler<HTMLButtonElement>
    onCreateSubscriptionClick: MouseEventHandler<HTMLButtonElement>
    onRenewSubscriptionClick: MouseEventHandler<HTMLButtonElement>
    onCancelSubscriptionClick: MouseEventHandler<HTMLButtonElement>
  }

  onNameClick: MouseEventHandler<HTMLButtonElement>
  onUsernameClick: MouseEventHandler<HTMLButtonElement>
}

export const ArtistCard: FC<ArtistCardProps> = ({
  artist,
  artistActions = undefined,
  onNameClick,
  onUsernameClick,
}) => {
  const theme = useTheme()
  const [avatarLoadError, setAvatarLoadError] = useState(false)

  const { id: artistId, avatarId, name, username } = artist
  const {
    isFollowed,
    isSubscribed,
    isSubscribedAtPeriodEnd,
    isPublic,
    onFollowClick,
    onUnfollowClick,
    onCreateSubscriptionClick,
    onRenewSubscriptionClick,
    onCancelSubscriptionClick,
  } = artistActions ?? {}

  // connect ready
  const { ready: connectReady } = useConnectReady(isPublic ? '' : artistId || '')

  const artistHasAvatar = !!avatarId
  const source = useFile(avatarId || '')

  const renderArtistActionsInput = {
    isCurrentUserFollowingArtist: !!isFollowed,
    isCurrentUserSubscribedToArtist: !!isSubscribed,
    isCurrentUserSubscribedToArtistAtPeriodEnd: !!isSubscribedAtPeriodEnd,
    isArtistCanReceivePayments: !!connectReady,

    createUserFollowNode: (
      <ButtonSmall
        css={styles(theme).artist.buttons.button}
        aria-label="follow artist"
        appearance="primary"
        onClick={onFollowClick ? (e) => onFollowClick(e) : undefined}
      >
        Follow
      </ButtonSmall>
    ),
    deleteUserFollowNode: (
      <ButtonSmall
        css={styles(theme).artist.buttons.button}
        aria-label="unfollow artist"
        appearance="secondary"
        onClick={onUnfollowClick ? (e) => onUnfollowClick(e) : undefined}
      >
        Unfollow
      </ButtonSmall>
    ),
    createSubscriptionNode: (
      <ButtonSmall
        css={styles(theme).artist.buttons.button}
        aria-label="subscribe"
        appearance="primary"
        onClick={
          onCreateSubscriptionClick ? (e) => onCreateSubscriptionClick(e) : undefined
        }
      >
        Subscribe
      </ButtonSmall>
    ),
    renewSubscriptionNode: (
      <ButtonSmall
        css={styles(theme).artist.buttons.button}
        aria-label="subscribe"
        appearance="primary"
        onClick={
          onRenewSubscriptionClick ? (e) => onRenewSubscriptionClick(e) : undefined
        }
      >
        Subscribe
      </ButtonSmall>
    ),
    cancelSubscriptionNode: (
      <ButtonSmall
        css={styles(theme).artist.buttons.button}
        aria-label="unsubscribe"
        appearance="secondary"
        onClick={
          onCancelSubscriptionClick ? (e) => onCancelSubscriptionClick(e) : undefined
        }
      >
        Unsubscribe
      </ButtonSmall>
    ),
    defaultNode: <div />,
  }

  return (
    <div css={styles(theme).artist.main}>
      <div css={styles(theme).artist.avatar.main}>
        {artistHasAvatar && !avatarLoadError ? (
          <img
            css={styles(theme).artist.avatar.image}
            onError={() => setAvatarLoadError(true)}
            loading="lazy"
            width="100px"
            height="100px"
            src={source}
            alt="artist avatar"
          />
        ) : (
          <icons.User css={styles(theme).artist.avatar.icon} />
        )}
      </div>
      <div css={styles(theme).artist.info.main}>
        <button
          css={styles(theme).artist.info.name}
          onClick={(e) => onNameClick(e)}
          type="button"
          aria-label="explore artist"
        >
          {name}
        </button>
        <button
          css={styles(theme).artist.info.username}
          onClick={(e) => onUsernameClick(e)}
          type="button"
          aria-label="explore artist"
        >
          @{username}
        </button>
      </div>
      <div css={styles(theme).artist.followers.main}>
        <span css={styles(theme).artist.followers.count}>
          {numbersFormatter(artist.followersCount || 0)} follower(s)
        </span>
      </div>
      <div css={styles(theme).artist.buttons.main}>
        {artistActions ? userUtilRenderArtistActions(renderArtistActionsInput) : <div />}
      </div>
    </div>
  )
}

ArtistCard.propTypes = {}

import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  card: {
    main: css`
      margin: 50px 0px;
    `,
    content: {
      main: css`
        max-width: 800px;

        ${mq.tablet(css`
          width: 100%;
        `)}
      `,

      title: css`
        margin-bottom: 22px;
      `,

      methods: {
        main: css`
          margin-bottom: 32px;
        `,
      },

      buttons: {
        main: css`
          display: flex;
          margin-bottom: 32px;

          width: 400px;

          ${mq.tablet(css`
            flex-direction: column;
            width: 100%;
          `)}
        `,
        delete: css`
          margin-right: 20px;

          & > button {
            width: 130px;
          }

          ${mq.tablet(css`
            margin-right: 0px;
            margin-bottom: 20px;

            & > button {
              width: 100%;
            }
          `)}
        `,
        create: css`
          margin-right: 20px;

          & > button {
            width: 130px;
          }

          ${mq.tablet(css`
            margin-right: 0px;
            margin-bottom: 20px;

            & > button {
              width: 100%;
            }
          `)}
        `,
        set: css`
          width: 100%;
        `,
      },
    },
  },
})

import { FC, memo } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  trackSelectTrackDeleteLoading,
  trackSelectUploadedTracks,
  trackSelectUploadedTracksCount,
  trackGetUploadedTracks,
  trackDelete,
} from '@/features'
// components
import { ContentLayoutVirtualized } from '@/components'
// styles
import { contentLayout } from '@/styles'

// components
import { TrackCardUploaded, TrackCardUploadedSkeleton } from './components'
// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const dispatch = useAppDispatch()
  const trackDeleteLoading = useAppSelector(trackSelectTrackDeleteLoading)
  const tracks = useAppSelector(trackSelectUploadedTracks)
  const track = tracks[index]

  // if track not fetched
  if (!track) {
    return (
      <div style={style}>
        <TrackCardUploadedSkeleton />
      </div>
    )
  }

  const { id: trackId } = track

  // if track is loading - artist should not be able to delete it
  const deleteDisabled = trackDeleteLoading

  return (
    <div css={styles().tracks.main} style={style}>
      <TrackCardUploaded
        track={track}
        deleteDisabled={deleteDisabled}
        onDelete={() => dispatch(trackDelete({ id: trackId || '' }))}
      />
    </div>
  )
})

type TrackListOnboardingUploadedProps = {}

export const TrackListOnboardingUploaded: FC<TrackListOnboardingUploadedProps> = () => {
  const dispatch = useAppDispatch()
  const uploadedTracks = useAppSelector(trackSelectUploadedTracks)
  const uploadedTracksCount = useAppSelector(trackSelectUploadedTracksCount)

  const trackBlockWidth = 330
  const trackBlockMarginRight = 20
  const itemWidth = trackBlockWidth + trackBlockMarginRight

  const trackBlockHeight = 72
  const trackBlockMarginBottom = 0
  const itemHeight = trackBlockHeight + trackBlockMarginBottom

  const defaultContentCount = 1000
  const defaultListWidth = 700
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!uploadedTracks[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(trackGetUploadedTracks({ limit: 30, offset: startIndex }))
  }

  return (
    <ContentLayoutVirtualized
      title="Uploaded Tracks"
      overrideCss={contentLayout.onboarding}
      itemWidth={itemWidth}
      itemsCount={uploadedTracksCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={uploadedTracksCount || defaultContentCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={uploadedTracksCount || defaultContentCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

TrackListOnboardingUploaded.propTypes = {}

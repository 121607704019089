import { css } from '@emotion/react'
import { mq, typography } from '@/styles'

export const styles = () => ({
  form: {
    main: css`
      display: flex;
      justify-content: center;
    `,
    content: {
      main: css`
        display: flex;
        flex-direction: column;

        width: 510px;

        ${mq.tablet(css`
          width: 380px;
        `)}

        ${mq.mobile(css`
          width: 100%;
        `)}
      `,

      checkbox: {
        main: css`
          display: flex;
          align-items: center;
          justify-content: left;

          margin-bottom: 24px;
        `,
        label: css`
          display: flex;
          font-size: ${typography.h6};

          margin-left: 16px;
        `,
      },

      submit: css`
        align-self: center;
        margin-top: 36px;

        ${mq.mobile(css`
          width: 100%;
        `)}

        & > button {
          width: 380px;

          ${mq.mobile(css`
            width: 100%;
          `)}
        }
      `,
    },
  },
})

import { css } from '@emotion/react'
import { mq, z } from '@/styles'

export const styles = () => ({
  stage: {
    main: css`
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
    `,

    backward: {
      button: css`
        z-index: ${z.onboarding.backward};

        position: fixed;
        top: 20px;
        left: 20px;
      `,
      icon: css`
        width: 24px;
        height: 24px;
      `,
    },

    content: {
      main: css`
        display: flex;
        flex-direction: column;

        width: 760px;

        ${mq.mobile(css`
          width: 100%;
        `)}
      `,

      title: css`
        margin-bottom: 50px;
      `,

      composition: css`
        margin-bottom: 50px;
      `,

      form: css`
        margin-bottom: 50px;
      `,

      error: css``,
    },
  },
})

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { onboardingSelectLoading, onboardingSetNextStage } from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const dispatch = useAppDispatch()
  const onboardingLoading = useAppSelector(onboardingSelectLoading)

  const submitDisabled = onboardingLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(onboardingSetNextStage())
        }}
      >
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="follow artists"
            disabled={submitDisabled}
            appearance="primary"
          >
            {onboardingLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Next'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

import { FC, useState } from 'react'
import { useTheme } from '@emotion/react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  TrackViewAudioPlaylist,
  TrackListAudioPlaylist,
  playerAudioSelectOpen,
  playerAudioSelectCurrentTrack,
  playerAudioSetClosed,
} from '@/features'

// styles
import { styles } from './styles'

type PlayerAudioPlaylistProps = {}

export const PlayerAudioPlaylist: FC<PlayerAudioPlaylistProps> = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const isAudioPlayerOpen = useAppSelector(playerAudioSelectOpen)
  const currentTrack = useAppSelector(playerAudioSelectCurrentTrack)

  // on playlist touch states
  const [touchStartPosition, setTouchStartPosition] = useState({ x: 0, y: 0 })

  return (
    <div
      css={[
        styles(theme).playlist.main,
        isAudioPlayerOpen ? styles(theme).playlist.open : styles(theme).playlist.closed,
      ]}
      onTouchStart={(e) => {
        const { clientX, clientY } = e.changedTouches[0]
        setTouchStartPosition({ x: clientX, y: clientY })
      }}
      onTouchEnd={(e) => {
        const { clientY } = e.changedTouches[0]

        // calculate touch distance
        const touchDistance = clientY - touchStartPosition.y
        // set min touch threshold
        const minTouchThreshold = 200

        // if touch distance is greater or equal min threshold
        if (touchDistance >= minTouchThreshold) {
          dispatch(playerAudioSetClosed())
        }
      }}
    >
      {/* current track */}
      <div css={styles(theme).playlist.current}>
        {currentTrack && <TrackViewAudioPlaylist />}
      </div>
      {/* playlist */}
      <div css={styles(theme).playlist.tracks}>
        <TrackListAudioPlaylist />
      </div>
    </div>
  )
}

PlayerAudioPlaylist.propTypes = {}

import { FC, ButtonHTMLAttributes, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface NavigationScrollableProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  options: {
    id: string
    label: string
    enabled: boolean
    onClick: MouseEventHandler<HTMLButtonElement>
  }[]
}

export const NavigationScrollable: FC<NavigationScrollableProps> = ({ options }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).nav.main}>
      {options.map(({ id, label, enabled, onClick }) => (
        <div css={styles(theme).nav.option.main} key={`navigation-scrollable-${id}`}>
          <button
            css={[
              styles(theme).nav.option.button.shared,
              enabled
                ? styles(theme).nav.option.button.enabled
                : styles(theme).nav.option.button.disabled,
            ]}
            type="button"
            onClick={(e) => onClick(e)}
          >
            {label}
          </button>
        </div>
      ))}
    </div>
  )
}

import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  error: {
    shared: css`
      display: flex;
      justify-content: center;

      min-width: 0;
      transition: height 0.2s;
    `,
    visible: css`
      height: 24px;
    `,
    hidden: css`
      height: 0px;
    `,

    text: {
      shared: css`
        font-size: ${typography.p};
        color: ${theme.colors.negative.normal};
        text-align: center;

        transition: opacity 0.4s;
      `,
      visible: css`
        opacity: 1;
      `,
      hidden: css`
        opacity: 0;
      `,
    },
  },
})

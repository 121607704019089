import { css, Theme } from '@emotion/react'
import { helpers, typography, z } from '@/styles'

export const styles = (theme: Theme) => ({
  badge: {
    main: css`
      position: relative;

      display: flex;
      align-items: center;

      height: 32px;

      border-radius: 20px;
      padding: 0px 32px;
      background-color: ${theme.colors.accent.normal};
    `,
    close: {
      main: css`
        z-index: ${z.features.user.card.selectedGenre.close};

        position: absolute;
        right: 0;
        top: -30%;
        display: flex;

        width: 24px;
        height: 24px;

        background-color: ${theme.colors.primary.normal};
        border-radius: 50%;
      `,
      button: css`
        ${helpers.resetDefaults};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 24px;
        height: 24px;

        cursor: pointer;
      `,
      icon: css`
        width: 18px;
        height: 18px;

        & > path {
          fill: ${theme.colors.accent.normal};
        }
      `,
    },
    name: css`
      font-size: ${typography.p};
      font-weight: bold;
      color: ${theme.colors.primary.normal};
    `,
  },
})

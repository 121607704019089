import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: css`
      display: flex;
      flex-direction: column;

      // else use fixed height
      height: 70px;

      cursor: pointer;
    `,

    data: {
      main: css`
        display: flex;
      `,
      avatar: {
        main: css`
          display: flex;
          align-items: center;
          justify-content: center;

          flex-shrink: 0;

          width: 30px;
          height: 30px;

          background-color: ${theme.colors.primary.darker};
          border-radius: 50%;
          overflow: hidden;

          margin-right: 12px;
        `,
        image: css`
          ${helpers.image};
        `,
        icon: css`
          width: 14px;
          height: 14px;

          & > path {
            fill: ${theme.colors.secondary.normal};
          }
        `,
      },
      content: {
        main: css`
          display: flex;
          flex-direction: column;
          justify-content: center;

          min-width: 0;
          margin-bottom: 8px;
        `,
        name: css`
          ${helpers.resetDefaults};

          display: flex;
          min-width: 0;

          max-width: 240px;
          margin-bottom: 2px;

          & > span {
            ${helpers.textOverflowEllipsis};
            cursor: pointer;

            font-size: ${typography.sp};
            font-weight: bold;
            color: ${theme.colors.secondary.normal};

            transition: color 0.2s;
          }

          &:hover {
            & > span {
              color: ${theme.colors.accent.normal};
            }
          }
        `,
        complaint: css`
          // else use ellipsis
          ${helpers.textOverflowEllipsis};

          font-size: ${typography.sp};
          color: ${theme.colors.secondary.normal};
        `,
      },
    },

    type: {
      main: css`
        display: flex;
        align-items: center;

        margin-left: 42px;
      `,
      text: css`
        display: flex;
        align-items: center;
        text-transform: uppercase;

        color: ${theme.colors.negative.normal};
        font-size: ${typography.c};
        font-weight: bold;
      `,
    },
  },
})

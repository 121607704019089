import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  PostCard,
  authSelectProfile,
  postSelectPostDeleteLoading,
  postSelectPostDeleteModalPost,
  postDelete,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type PostDeleteFormProps = {}

export const PostDeleteForm: FC<PostDeleteFormProps> = () => {
  const dispatch = useAppDispatch()
  const postDeleteLoading = useAppSelector(postSelectPostDeleteLoading)
  const profile = useAppSelector(authSelectProfile)
  const post = useAppSelector(postSelectPostDeleteModalPost)

  const submitDisabled = postDeleteLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(postDelete({ id: post?.id || '' }))
        }}
      >
        <div css={styles().form.content.track}>
          <PostCard
            post={post || {}}
            currentUserId={profile?.id || ''}
            onClick={() => () => {}}
            onAuthorNameClick={() => () => {}}
            onAuthorUsernameClick={() => () => {}}
          />
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="delete post"
            disabled={submitDisabled}
            appearance="primary"
          >
            {postDeleteLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Delete'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

PostDeleteForm.propTypes = {}

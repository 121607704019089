import { FC } from 'react'
import { useTheme } from '@emotion/react'
import { EntityConnectStatus } from '@ryddm-inc/ryddm-apiclient'

// styles
import { styles } from './styles'

type StatusProps = {
  status: EntityConnectStatus | undefined
}

export const Status: FC<StatusProps> = ({ status }) => {
  const theme = useTheme()
  return (
    <div css={styles(theme).status.main}>
      <div
        css={[
          styles(theme).status.name.shared,
          status === EntityConnectStatus.Pending && styles(theme).status.name.pending,
          status === EntityConnectStatus.Restricted &&
            styles(theme).status.name.restricted,
          status === EntityConnectStatus.Enabled && styles(theme).status.name.enabled,
          status === EntityConnectStatus.Complete && styles(theme).status.name.complete,
        ]}
      >
        {status || `---`}
      </div>
    </div>
  )
}

Status.propTypes = {}

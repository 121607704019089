import { css, Theme } from '@emotion/react'
import { typography, mq, helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  layout: {
    main: css`
      ${helpers.app.padding.content};
      margin-bottom: 60px;

      ${mq.mobile(css`
        margin-bottom: 30px;
      `)}
    `,

    header: {
      main: css`
        display: flex;
        justify-content: space-between;

        margin-bottom: 24px;

        ${mq.mobile(css`
          padding-right: 0px;
        `)};
      `,
      title: {
        main: css`
          display: flex;
          align-items: center;
        `,

        text: css`
          font-size: ${typography.h4};
          font-weight: bold;
          color: ${theme.colors.secondary.light};
        `,

        icon: css`
          display: flex;
          margin-right: 8px;

          & > svg {
            width: 18px;
            height: 18px;
          }
        `,
      },
      navigation: {
        main: css`
          display: flex;

          & > button:nth-of-type(1) {
            margin-right: 28px;
          }

          ${mq.mobile(css`
            display: none;
          `)};
        `,
        button: css``,
        icon: css`
          width: 24px;
          height: 24px;
        `,
      },
    },

    content: {
      main: css`
        ${helpers.app.screen.width.content};
      `,
      container: {
        main: css`
          display: flex;
        `,
      },
    },
  },
})

import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  authOpenLoginModal,
  authOpenSignupModal,
  authSelectProfile,
  navigationAppNavigationClose,
  navigationSelectAppNavigationOpen,
  authSignout,
  playerAudioSetClosed,
} from '@/features'
// components
import { ButtonSmall, CardHeaderMobile } from '@/components'

// utils
import { getNavLinks } from './utils'
// components
import { NavLink, NavFooter } from './components'
// styles
import { styles } from './NavigationAppMobile.styles'

type NavigationAppMobileProps = {}

export const NavigationAppMobile: FC<NavigationAppMobileProps> = () => {
  const theme = useTheme()
  const { pathname } = useLocation()

  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const isNavigationOpen = useAppSelector(navigationSelectAppNavigationOpen)
  const navLinks = getNavLinks(profile)

  return (
    <div
      css={[
        styles(theme).navigation.main.shared,
        isNavigationOpen
          ? styles(theme).navigation.main.open
          : styles(theme).navigation.main.closed,
      ]}
    >
      {/* mobile navigation header */}
      <CardHeaderMobile onCloseClick={() => dispatch(navigationAppNavigationClose())} />

      {/* mobile navigation links */}
      <nav css={styles(theme).navigation.links}>
        <ul>
          {navLinks.map(({ name, path, icon }) => (
            <li key={`${name}-${path}`}>
              <button
                css={styles(theme).navigation.menu}
                onClick={() => dispatch(navigationAppNavigationClose())}
                type="button"
                aria-label={`navigate to ${name}`}
              >
                <NavLink
                  to={path}
                  name={name}
                  icon={icon}
                  onClick={() => dispatch(playerAudioSetClosed())}
                  active={pathname === path}
                />
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {/* mobile navigation auth links */}
      <div css={styles(theme).navigation.auth}>
        {profile ? (
          // if authenticated
          <ButtonSmall
            aria-label="log out"
            appearance="secondary"
            onClick={() => {
              dispatch(authSignout())
              dispatch(navigationAppNavigationClose())
            }}
          >
            Log out
          </ButtonSmall>
        ) : (
          // if unauthenticated
          <>
            <ButtonSmall
              aria-label="log in"
              appearance="secondary"
              onClick={() => {
                dispatch(authOpenLoginModal())
                dispatch(navigationAppNavigationClose())
              }}
            >
              Log in
            </ButtonSmall>
            <ButtonSmall
              aria-label="sign up"
              appearance="primary"
              onClick={() => {
                dispatch(authOpenSignupModal())
                dispatch(navigationAppNavigationClose())
              }}
            >
              Sign up
            </ButtonSmall>
          </>
        )}
      </div>

      {/* mobile navigation footer */}
      <div css={styles(theme).navigation.footer}>
        <NavFooter />
      </div>
    </div>
  )
}

NavigationAppMobile.propTypes = {}

import { css, Theme } from '@emotion/react'
import { typography, helpers, mq, kf } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: {
      shared: css`
        display: flex;
        padding: 10px 22px;

        animation: 0.4s ease 0.2s backwards ${kf.slideIn.down(50)};
      `,
      info: css`
        background-color: ${theme.colors.positive.normal};
        color: ${theme.colors.primary.normal};
      `,
      warn: css`
        background-color: ${theme.colors.accent.normal};
        color: ${theme.colors.primary.normal};
      `,
      error: css`
        background-color: ${theme.colors.negative.normal};
        color: ${theme.colors.primary.normal};
      `,
    },

    content: {
      main: css`
        align-self: center;
        margin-right: auto;
      `,
      message: css`
        font-size: ${typography.p};

        ${mq.mobile(css`
          font-size: ${typography.h6};
        `)}
      `,
    },

    close: {
      main: css`
        display: flex;
        align-items: center;

        margin-left: 10px;
      `,
      button: css`
        ${helpers.resetDefaults};

        display: flex;
        cursor: pointer;
      `,
      icon: css`
        width: 30px;
        height: 30px;

        & > path {
          fill: ${theme.colors.primary.normal};
        }
      `,
    },
  },
})

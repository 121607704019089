import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  view: {
    main: css`
      margin-bottom: 50px;
    `,
    content: {
      main: css`
        width: 400px;

        ${mq.tablet(css`
          width: 100%;
        `)}
      `,

      form: css`
        margin-bottom: 32px;
      `,
    },
  },
})

import { EntityUserRole, EntityOnboardingStage } from '@ryddm-inc/ryddm-apiclient'
import { OnboardingStep } from '../types'

export const onboardingGetCurrentStep = (
  userRole: EntityUserRole,
  currentOnboardingStage: EntityOnboardingStage,
): {
  currentOnboardingStep: OnboardingStep
} => {
  let currentOnboardingStep: OnboardingStep

  // based on user role and current onboarding stage
  // assert current onboarding step
  switch (userRole) {
    case EntityUserRole.Fan:
      switch (currentOnboardingStage) {
        case EntityOnboardingStage.SelectRole:
          currentOnboardingStep = OnboardingStep.Initial
          break
        case EntityOnboardingStage.CreateProfile:
          currentOnboardingStep = OnboardingStep.First
          break
        case EntityOnboardingStage.SelectGenres:
          currentOnboardingStep = OnboardingStep.Second
          break
        case EntityOnboardingStage.FollowArtists:
          currentOnboardingStep = OnboardingStep.Third
          break
        case EntityOnboardingStage.ConfigureNotifications:
          currentOnboardingStep = OnboardingStep.Fourth
          break
        case EntityOnboardingStage.AcceptTermsAndConditions:
          currentOnboardingStep = OnboardingStep.Fifth
          break
        default:
          return {
            currentOnboardingStep: OnboardingStep.Initial,
          }
      }
      break

    case EntityUserRole.Artist:
      switch (currentOnboardingStage) {
        case EntityOnboardingStage.SelectRole:
          currentOnboardingStep = OnboardingStep.Initial
          break
        case EntityOnboardingStage.CreateProfile:
          currentOnboardingStep = OnboardingStep.First
          break
        case EntityOnboardingStage.UploadTracks:
          currentOnboardingStep = OnboardingStep.Second
          break
        case EntityOnboardingStage.EnableMonetization:
          currentOnboardingStep = OnboardingStep.Third
          break
        case EntityOnboardingStage.ConfigureNotifications:
          currentOnboardingStep = OnboardingStep.Fourth
          break
        case EntityOnboardingStage.AcceptTermsAndConditions:
          currentOnboardingStep = OnboardingStep.Fifth
          break
        default:
          return {
            currentOnboardingStep: OnboardingStep.Initial,
          }
      }
      break

    default:
      return {
        currentOnboardingStep: OnboardingStep.Initial,
      }
  }

  return {
    currentOnboardingStep,
  }
}

export const onboardingGetNextStage = (
  userRole: EntityUserRole,
  currentOnboardingStage: EntityOnboardingStage,
  currentOnboardingStep: OnboardingStep,
): {
  nextOnboardingStage: EntityOnboardingStage
  nextOnboardingStep: OnboardingStep
} => {
  let nextOnboardingStage: EntityOnboardingStage
  let nextOnboardingStep: OnboardingStep

  // based on user role and current onboarding stage
  // assert next onboarding stage and onboarding step
  switch (userRole) {
    case EntityUserRole.Fan:
      switch (currentOnboardingStage) {
        case EntityOnboardingStage.SelectRole:
          nextOnboardingStage = EntityOnboardingStage.CreateProfile
          nextOnboardingStep = OnboardingStep.First
          break
        case EntityOnboardingStage.CreateProfile:
          nextOnboardingStage = EntityOnboardingStage.SelectGenres
          nextOnboardingStep = OnboardingStep.Second
          break
        case EntityOnboardingStage.SelectGenres:
          nextOnboardingStage = EntityOnboardingStage.FollowArtists
          nextOnboardingStep = OnboardingStep.Third
          break
        case EntityOnboardingStage.FollowArtists:
          nextOnboardingStage = EntityOnboardingStage.ConfigureNotifications
          nextOnboardingStep = OnboardingStep.Fourth
          break
        case EntityOnboardingStage.ConfigureNotifications:
          nextOnboardingStage = EntityOnboardingStage.AcceptTermsAndConditions
          nextOnboardingStep = OnboardingStep.Fifth
          break
        case EntityOnboardingStage.AcceptTermsAndConditions:
          nextOnboardingStage = EntityOnboardingStage.Finished
          nextOnboardingStep = OnboardingStep.Sixth
          break
        default:
          return {
            nextOnboardingStage: currentOnboardingStage,
            nextOnboardingStep: currentOnboardingStep,
          }
      }
      break

    case EntityUserRole.Artist:
      switch (currentOnboardingStage) {
        case EntityOnboardingStage.SelectRole:
          nextOnboardingStage = EntityOnboardingStage.CreateProfile
          nextOnboardingStep = OnboardingStep.First
          break
        case EntityOnboardingStage.CreateProfile:
          nextOnboardingStage = EntityOnboardingStage.UploadTracks
          nextOnboardingStep = OnboardingStep.Second
          break
        case EntityOnboardingStage.UploadTracks:
          nextOnboardingStage = EntityOnboardingStage.EnableMonetization
          nextOnboardingStep = OnboardingStep.Third
          break
        case EntityOnboardingStage.EnableMonetization:
          nextOnboardingStage = EntityOnboardingStage.ConfigureNotifications
          nextOnboardingStep = OnboardingStep.Fourth
          break
        case EntityOnboardingStage.ConfigureNotifications:
          nextOnboardingStage = EntityOnboardingStage.AcceptTermsAndConditions
          nextOnboardingStep = OnboardingStep.Fifth
          break
        case EntityOnboardingStage.AcceptTermsAndConditions:
          nextOnboardingStage = EntityOnboardingStage.Finished
          nextOnboardingStep = OnboardingStep.Sixth
          break
        default:
          return {
            nextOnboardingStage: currentOnboardingStage,
            nextOnboardingStep: currentOnboardingStep,
          }
      }
      break

    default:
      return {
        nextOnboardingStage: currentOnboardingStage,
        nextOnboardingStep: currentOnboardingStep,
      }
  }

  return {
    nextOnboardingStage,
    nextOnboardingStep,
  }
}

export const onboardingGetPrevStage = (
  userRole: EntityUserRole,
  currentOnboardingStage: EntityOnboardingStage,
  currentOnboardingStep: OnboardingStep,
): {
  prevOnboardingStage: EntityOnboardingStage
  prevOnboardingStep: OnboardingStep
} => {
  let prevOnboardingStage: EntityOnboardingStage
  let prevOnboardingStep: OnboardingStep

  // based on user role and current onboarding stage
  // assert prev onboarding stage and onboarding step
  switch (userRole) {
    case EntityUserRole.Fan:
      switch (currentOnboardingStage) {
        case EntityOnboardingStage.AcceptTermsAndConditions:
          prevOnboardingStage = EntityOnboardingStage.ConfigureNotifications
          prevOnboardingStep = OnboardingStep.Fourth
          break
        case EntityOnboardingStage.ConfigureNotifications:
          prevOnboardingStage = EntityOnboardingStage.FollowArtists
          prevOnboardingStep = OnboardingStep.Third
          break
        case EntityOnboardingStage.FollowArtists:
          prevOnboardingStage = EntityOnboardingStage.SelectGenres
          prevOnboardingStep = OnboardingStep.Second
          break
        case EntityOnboardingStage.SelectGenres:
          prevOnboardingStage = EntityOnboardingStage.CreateProfile
          prevOnboardingStep = OnboardingStep.First
          break
        default:
          return {
            prevOnboardingStage: currentOnboardingStage,
            prevOnboardingStep: currentOnboardingStep,
          }
      }
      break

    case EntityUserRole.Artist:
      switch (currentOnboardingStage) {
        case EntityOnboardingStage.AcceptTermsAndConditions:
          prevOnboardingStage = EntityOnboardingStage.ConfigureNotifications
          prevOnboardingStep = OnboardingStep.Fourth
          break
        case EntityOnboardingStage.ConfigureNotifications:
          prevOnboardingStage = EntityOnboardingStage.EnableMonetization
          prevOnboardingStep = OnboardingStep.Third
          break
        case EntityOnboardingStage.EnableMonetization:
          prevOnboardingStage = EntityOnboardingStage.UploadTracks
          prevOnboardingStep = OnboardingStep.Second
          break
        case EntityOnboardingStage.UploadTracks:
          prevOnboardingStage = EntityOnboardingStage.CreateProfile
          prevOnboardingStep = OnboardingStep.First
          break
        default:
          return {
            prevOnboardingStage: currentOnboardingStage,
            prevOnboardingStep: currentOnboardingStep,
          }
      }
      break

    default:
      return {
        prevOnboardingStage: currentOnboardingStage,
        prevOnboardingStep: currentOnboardingStep,
      }
  }

  return {
    prevOnboardingStage,
    prevOnboardingStep,
  }
}

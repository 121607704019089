import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  subscriptionSelectSubscriptionRenewModalOpen,
  subscriptionCloseSubscriptionRenewModal,
} from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { SubscriptionRenewCard } from './components'
// styles
import { styles } from './styles'

type SubscriptionRenewModalProps = {}

export const SubscriptionRenewModal: FC<SubscriptionRenewModalProps> = () => {
  const dispatch = useAppDispatch()
  const subscriptionRenewModalOpen = useAppSelector(
    subscriptionSelectSubscriptionRenewModalOpen,
  )

  return (
    <div css={styles().modal.main}>
      {subscriptionRenewModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(subscriptionCloseSubscriptionRenewModal())}
        >
          <div css={styles().modal.content}>
            <SubscriptionRenewCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

SubscriptionRenewModal.propTypes = {}

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityReportView } from '@ryddm-inc/ryddm-apiclient'

// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type ReportCardProps = {
  report: EntityReportView
  onClick: MouseEventHandler<HTMLDivElement>
}

export const ReportCard: FC<ReportCardProps> = ({ report, onClick }) => {
  const theme = useTheme()
  const { shortId, entityType, status, createdAt } = report

  const date = new Date(createdAt || '')
  const dateDay = date.getUTCDate().toString().padStart(2, '0')
  const dateMonth = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const dateYear = date.getUTCFullYear()
  // const dateYear = date.getUTCFullYear() % 100

  return (
    <div css={styles(theme).card.main} onClick={(e) => onClick(e)} aria-hidden="true">
      <div css={styles(theme).card.data.main}>
        <div css={styles(theme).card.data.icon}>
          <icons.AlertTriangle />
        </div>
        <div css={styles(theme).card.data.identifier}>{`#${shortId}`}</div>
        <div css={styles(theme).card.data.type}>{entityType}</div>
        <div css={styles(theme).card.data.status}>{status}</div>
        <div
          css={styles(theme).card.data.date}
        >{`${dateDay}.${dateMonth}.${dateYear}`}</div>
      </div>
      <div css={styles(theme).card.divider} />
    </div>
  )
}

ReportCard.propTypes = {}

import { FC, useEffect, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'
// features
import { AlertMessage, AlertMessageType } from '@/features'

// styles
import { styles } from './styles'

type AlertCardProps = {
  alertMessage: AlertMessage
  onTimeout: Function
  onCloseClick: MouseEventHandler<HTMLButtonElement>
}

export const AlertCard: FC<AlertCardProps> = ({
  alertMessage,
  onTimeout,
  onCloseClick,
}) => {
  const theme = useTheme()
  const { type, message } = alertMessage

  const isInfoMessage = type === AlertMessageType.Info
  const isWarnMessage = type === AlertMessageType.Warn
  const isErrorMessage = type === AlertMessageType.Error

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onTimeout()
    }, 5000)

    return () => clearTimeout(timeoutId)
  })

  return (
    <div
      css={[
        styles(theme).card.main.shared,
        isInfoMessage && styles(theme).card.main.info,
        isWarnMessage && styles(theme).card.main.warn,
        isErrorMessage && styles(theme).card.main.error,
      ]}
    >
      <div css={styles(theme).card.content.main}>
        <div css={styles(theme).card.content.message}>{message}</div>
      </div>
      <div css={styles(theme).card.close.main}>
        <button
          css={styles(theme).card.close.button}
          onClick={(e) => onCloseClick(e)}
          type="button"
          aria-label="close alert"
        >
          <icons.CrossCircle css={styles(theme).card.close.icon} />
        </button>
      </div>
    </div>
  )
}

AlertCard.propTypes = {}

import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

// components
import { SuspenseContentFallback } from '@/components'

const App = () => (
  <Suspense fallback={<SuspenseContentFallback position="full" />}>
    <Outlet />
  </Suspense>
)

export const refreshingRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <SuspenseContentFallback position="full" />,
      },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
]

import { css } from '@emotion/react'
import { helpers, getTheme, ThemeName } from '@/styles'

export const styles = () => {
  const theme = getTheme(ThemeName.Dark)

  return {
    fallback: {
      main: css`
        ${helpers.app.padding.full};

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        min-width: 100vw;
        min-height: 100vh;

        background-color: ${theme.colors.primary.normal};
      `,
    },
  }
}

import { FC, useEffect, useState, useRef, ChangeEvent } from 'react'

// config
import {
  musicGenres as musGen,
  maxUserPreferredGenresCount,
  minUserNameLength,
  maxUserNameLength,
} from '@/config'
// hooks
import { useAppSelector, useAppDispatch, useFile } from '@/hooks'
// features
import {
  authSelectProfile,
  userSelectUserProfileUpdateLoading,
  userProfileUpdate,
} from '@/features'
// utils
import { validateAvatarFile, validateName } from '@/utils'
// icons
import { icons } from '@/assets'
// components
import {
  Loader,
  InputLine,
  InputLabel,
  Badge,
  BadgeSelected,
  ButtonMedium,
  Error,
} from '@/components'

// components
import { Avatar } from './components'
// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const userProfileUpdateLoading = useAppSelector(userSelectUserProfileUpdateLoading)

  // avatar state
  const userHasAvatar = !!profile?.avatarId
  const profileAvatar = useFile(profile?.avatarId || '')
  const [avatar, setAvatar] = useState<File | null>(null)
  const [avatarPreview, setAvatarPreview] = useState(userHasAvatar ? profileAvatar : '')
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [avatarError, setAvatarError] = useState('')

  // name state
  const [name, setName] = useState(profile?.name || '')
  const [nameError, setNameError] = useState('')

  // username state
  const [username] = useState(profile?.username || '')

  // genres state
  const [preferredGenres, setPreferredGenres] = useState(profile?.preferredGenres || [])
  const [musicGenres, setMusicGenres] = useState(musGen)

  // genres search state
  const [search, setSearch] = useState('')

  const nameUnchanged = name === profile?.name
  const genresUnchanged = preferredGenres.join('') === profile?.preferredGenres?.join('')
  const avatarNotSelected = avatar === null
  const submitDisabled =
    name.length < minUserNameLength ||
    (nameUnchanged && genresUnchanged && avatarNotSelected) ||
    nameError !== '' ||
    avatarError !== '' ||
    userProfileUpdateLoading

  // TODO: add invite friend logic
  const inviteDisabled = true

  const revokePreviewURL = (url: string) => {
    URL.revokeObjectURL(url)
  }

  const onEditClick = () => {
    fileInputRef.current?.click()
    setAvatarError('')
  }

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    // if file selected
    if (files && files.length > 0) {
      const file = files[0]

      // validate file
      const error = validateAvatarFile(file)
      if (error) {
        setAvatarError(error)
        return
      }

      // if avatar preview was set
      if (avatarPreview) {
        // clean up to avoid memory leaks
        revokePreviewURL(avatarPreview)
      }

      // set file as a preview
      setAvatarPreview(URL.createObjectURL(file))

      // store file in state
      setAvatar(file)
    }
  }

  useEffect(() => () => {
    // if avatar preview was set
    if (avatarPreview) {
      // clean up to avoid memory leaks
      revokePreviewURL(avatarPreview)
    }
  })

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          setAvatar(null)
          dispatch(userProfileUpdate({ avatar, name, preferredGenres }))
        }}
      >
        {/* avatar */}
        <div css={styles().form.content.avatar.main}>
          <label css={styles().form.content.avatar.label} htmlFor="avatar">
            avatar
            <input
              name="avatar"
              type="file"
              id="avatar"
              ref={fileInputRef}
              accept="image/jpeg,image/webp,image/png"
              onChange={(e) => onFileChange(e)}
            />
          </label>
          <div css={styles().form.content.avatar.image}>
            <Avatar onEditClick={() => onEditClick()} source={avatarPreview} />
          </div>
          <div css={styles().form.content.avatar.error}>
            <Error error={avatarError} />
          </div>
        </div>

        {/* name */}
        <div css={styles().form.content.name}>
          <InputLine
            name="name"
            label="Name"
            type="text"
            placeholder="Enter your name"
            icon={icons.Edit}
            value={name}
            error={nameError.length > 0}
            errorMessage={nameError}
            min={minUserNameLength}
            max={maxUserNameLength}
            onChange={(e) => {
              setName(e.target.value)
            }}
            onBlur={() => setNameError(validateName(name))}
            onFocus={() => setNameError('')}
          />
        </div>

        {/* username */}
        <div css={styles().form.content.username}>
          <InputLine
            name="username"
            label="Username"
            type="text"
            placeholder="Enter your username"
            icon={icons.Edit}
            value={`@${username}`}
            disabled
          />
        </div>

        {/* genres */}
        <div css={styles().form.content.genres.main}>
          {/* preferred genres */}
          <div css={styles().form.content.genres.selected.main}>
            <div css={styles().form.content.genres.selected.label}>
              <InputLabel text="Preferred genres" />
            </div>
            <div css={styles().form.content.genres.selected.genres}>
              {preferredGenres.map((preferredGenre) => (
                <BadgeSelected
                  key={`profile-user-genres-${preferredGenre}`}
                  name={preferredGenre}
                  onRemoveClick={() =>
                    setPreferredGenres((state) =>
                      state.filter((genre) => genre !== preferredGenre),
                    )
                  }
                />
              ))}
            </div>
          </div>

          {/* search genres */}
          <div css={styles().form.content.genres.search.main}>
            <div css={styles().form.content.genres.search.input}>
              <InputLine
                name="search"
                label="Select genre"
                type="search"
                placeholder="Find genre"
                icon={icons.Search}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setMusicGenres(
                    musGen.filter((genre) =>
                      genre.toLowerCase().includes(e.target.value),
                    ),
                  )
                }}
              />
            </div>
            <div css={styles().form.content.genres.search.genres}>
              {musicGenres.map((genre) => (
                <Badge
                  key={`profile-genres-${genre}`}
                  name={genre}
                  disabled={
                    preferredGenres.includes(genre) ||
                    preferredGenres.length >= maxUserPreferredGenresCount
                  }
                  onClick={() => setPreferredGenres((state) => [...state, genre])}
                />
              ))}
            </div>
          </div>
        </div>

        {/* buttons */}
        <div css={styles().form.content.buttons.main}>
          <div css={styles().form.content.buttons.submit}>
            <ButtonMedium
              type="submit"
              aria-label="update profile data"
              disabled={submitDisabled}
              appearance="primary"
            >
              {userProfileUpdateLoading ? (
                <Loader width={56} height={20} appearance="secondary" />
              ) : (
                'Update'
              )}
            </ButtonMedium>
          </div>

          <div css={styles().form.content.buttons.share}>
            <ButtonMedium
              type="button"
              aria-label="invite friend"
              disabled={inviteDisabled}
              appearance="secondary"
            >
              Invite friends to RYDDM
            </ButtonMedium>
          </div>
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  subtitle: {
    main: css`
      font-size: ${typography.p};
      color: ${theme.colors.secondary.dark};
    `,
  },
})

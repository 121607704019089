import { css, Theme } from '@emotion/react'
import { helpers, z } from '@/styles'

export const styles = (theme: Theme) => ({
  menu: {
    main: css`
      position: relative;

      &:hover div:nth-of-type(2) {
        visibility: visible;
        opacity: 1;
      }
    `,
    avatar: css``,
    options: {
      main: css`
        z-index: ${z.app.header.menu};

        position: absolute;
        top: 100%;
        right: 50%;
        transform: translate(50%, 0%);

        visibility: hidden;
        opacity: 0;

        transition: opacity 0.2s;
      `,
      divider: css`
        height: 10px;
        width: 100%;

        background-color: transparent;
      `,
      list: {
        main: css`
          width: 100px;
          padding: 2px 10px;
          border-radius: 4px;
          border: 1px solid ${theme.colors.secondary.darker};

          background-color: ${theme.colors.primary.darker};
        `,
        ul: css`
          list-style: none;
        `,
        li: css``,
        button: {
          shared: css`
            ${helpers.resetDefaults};
            width: 100%;
            height: 24px;

            cursor: pointer;
            font-weight: bold;
            white-space: nowrap;
            text-align: start;

            transition: color 0.2s;
          `,

          profile: css`
            color: ${theme.colors.secondary.normal};
            &:hover {
              color: ${theme.colors.accent.normal};
            }
          `,
          logout: css`
            color: ${theme.colors.negative.normal};

            &:hover {
              color: ${theme.colors.negative.normalHover};
            }
          `,
        },
      },
    },
  },
})

import { FC } from 'react'
import { useTheme } from '@emotion/react'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useUserPublic } from '@/hooks'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TrackLockedProps = {
  track: EntityTrackView
}

export const TrackLocked: FC<TrackLockedProps> = ({ track }) => {
  const theme = useTheme()
  const { userId, name } = track

  // track author
  const { user: postAuthor, isLoading: isPostAuthorLoading } = useUserPublic(userId || '')
  const { name: authorName } = postAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'

  return (
    <div css={styles(theme).track.main}>
      <div css={styles(theme).track.cover.main}>
        <icons.Lock css={styles(theme).track.cover.icon} />
      </div>
      <div css={styles(theme).track.description.main}>
        <span css={styles(theme).track.description.name}>{name}</span>
        <span css={styles(theme).track.description.authorName}>
          {isPostAuthorLoading
            ? userLoadingPlaceholder
            : authorName || userNamePlaceholder}
        </span>
      </div>
      <div css={styles(theme).track.play}>
        <icons.PlayCircle />
      </div>
    </div>
  )
}

TrackLocked.propTypes = {}

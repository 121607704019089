import { createAsyncThunk } from '@reduxjs/toolkit'
import { EntityCommentView } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // comment
  commentSetComments,
  commentFilterComment,
  commentAddComment,
  commentUpdateComment,
  commentCloseCommentBlockModal,
  commentCloseCommentDeleteModal,
  // report
  reportSetReportEntityBlocked,
} from '@/features'

export const commentGetComments = createAsyncThunk<
  { error: string },
  {
    postId: string
    commentId?: string
    limit?: number
    offset?: number
    search?: string
  },
  { state: RootState; rejectValue: undefined }
>(
  'comment/commentGetComments',
  async ({ postId, commentId, limit, offset, search }, { dispatch }) => {
    // get api service
    const api = apiGetService()

    try {
      // if depth exists
      if (commentId) {
        // retrieve replies
        const { comments, count } = await api.commentApi.getReplies(
          commentId,
          postId,
          limit,
          offset,
          search,
        )

        // set replies
        dispatch(
          commentSetComments({
            comments: comments || [],
            count: count || 0,
            offset: offset || 0,
          }),
        )

        // return empty payload
        return {
          error: '',
        }
      }

      // retrieve comments
      const { comments, count } = await api.commentApi.getComments(
        postId,
        limit,
        offset,
        search,
      )

      // set comments
      dispatch(
        commentSetComments({
          comments: comments || [],
          count: count || 0,
          offset: offset || 0,
        }),
      )

      // return empty payload
      return {
        error: '',
      }
    } catch (err: any) {
      const { message } = await apiHandleError(err)

      // return error message in payload
      return {
        error: message,
      }
    }
  },
)

export const commentCreate = createAsyncThunk<
  { error: string },
  {
    message: string
    postId: string
    commentId?: string
  },
  { state: RootState; rejectValue: undefined }
>('comment/commentCreate', async ({ message, postId, commentId }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // create comment
    const { comment } = await api.commentApi.createComment({
      message,
      postId,
      commentId,
    })

    // add comment to state
    dispatch(commentAddComment({ comment: comment || {} }))

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully created comment!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message: errMessage } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to create comment: ${errMessage}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: errMessage,
    }
  }
})

export const commentUpdate = createAsyncThunk<
  { error: string },
  {
    comment: EntityCommentView
    message: string
  },
  { state: RootState; rejectValue: undefined }
>('comment/commentUpdate', async ({ comment, message }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // update comment
    const { comment: updatedComment } = await api.commentApi.updateComment({
      commentId: comment.id || '',
      message,
    })

    // update comment in state
    dispatch(
      commentUpdateComment({
        updatedComment: {
          ...comment,
          message: updatedComment?.message,
        },
      }),
    )

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully updated comment!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message: errMessage } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to update comment: ${errMessage}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: errMessage,
    }
  }
})

export const commentBlock = createAsyncThunk<
  { error: string },
  { id: string },
  { state: RootState; rejectValue: undefined }
>('comment/commentBlock', async ({ id }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // block comment
    await api.commentApi.blockComment({ commentId: id })

    // filter blocked comment
    dispatch(commentFilterComment({ id }))

    // set comment as blocked in the report state
    dispatch(reportSetReportEntityBlocked())

    // close comment block modal
    dispatch(commentCloseCommentBlockModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully blocked comment!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to block comment: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const commentDelete = createAsyncThunk<
  { error: string },
  { id: string },
  { state: RootState; rejectValue: undefined }
>('comment/commentDelete', async ({ id }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // delete comment
    await api.commentApi.deleteComment(id)

    // filter deleted comment
    dispatch(commentFilterComment({ id }))

    // close comment delete modal
    dispatch(commentCloseCommentDeleteModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully deleted comment!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to delete comment: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  PostCard,
  authSelectProfile,
  postSelectPostBlockLoading,
  postSelectPostBlockModalPost,
  postBlock,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type PostBlockFormProps = {}

export const PostBlockForm: FC<PostBlockFormProps> = () => {
  const dispatch = useAppDispatch()
  const postBlockLoading = useAppSelector(postSelectPostBlockLoading)
  const profile = useAppSelector(authSelectProfile)
  const post = useAppSelector(postSelectPostBlockModalPost)

  const submitDisabled = postBlockLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(postBlock({ id: post?.id || '' }))
        }}
      >
        <div css={styles().form.content.track}>
          <PostCard
            post={post || {}}
            currentUserId={profile?.id || ''}
            onClick={() => () => {}}
            onAuthorNameClick={() => () => {}}
            onAuthorUsernameClick={() => () => {}}
          />
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="block post"
            disabled={submitDisabled}
            appearance="primary"
          >
            {postBlockLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Block'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

PostBlockForm.propTypes = {}

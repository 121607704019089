import { css, Theme } from '@emotion/react'
import { mq, z, helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  navigation: {
    main: {
      shared: css`
        ${helpers.hideScrollbar};
        ${helpers.app.padding.full};

        z-index: ${z.app.navigation.mobile};
        display: none;

        position: fixed;
        padding-top: 0px;

        width: 100%;
        height: 100%;
        background-color: ${theme.colors.primary.darker};

        overflow: hidden;
        overflow-y: scroll;

        transition: left 0.2s, opacity 0.4s;

        ${mq.tablet(css`
          display: flex;
          flex-direction: column;
        `)}

        ${mq.mobile(css`
          padding-top: 0px;
        `)}
      `,

      open: css`
        left: 0;
        top: 0;

        opacity: 1;
      `,

      closed: css`
        left: 100vw;
        top: 0;

        opacity: 0;
      `,
    },

    menu: css`
      ${helpers.resetDefaults};

      display: flex;
      align-items: center;

      cursor: pointer;

      & > svg > path {
        transition: fill 0.2s;
        fill: ${theme.colors.secondary.light};
      }

      &:hover {
        & > svg > path {
          transition: fill 0.2s;
          fill: ${theme.colors.accent.normal};
        }
      }
    `,

    links: css`
      flex: 1;
      display: flex;
      justify-content: center;

      background-color: ${theme.colors.primary.darker};

      margin-bottom: 40px;

      & > ul {
        list-style-type: none;

        & > li:not(:last-of-type) {
          margin-bottom: 24px;
        }
      }
    `,
    auth: css`
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0px 10px;
      margin-bottom: 40px;

      & > button {
        max-width: 300px;
      }

      & > button:nth-of-type(1) {
        margin-bottom: 20px;
      }
    `,
    footer: css`
      display: flex;
      justify-content: center;

      padding: 0px 10px;

      & > footer {
        text-align: center;
      }
    `,
  },
})

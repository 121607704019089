import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// components
import { ButtonLarge, Loader } from '@/components'

// components
import { Feature, Title } from './components'

// styles
import { styles } from './styles'

type RoleProps = {
  name: string
  features: { id: string; title: string; description: string; icon: any }[]
  visible: boolean
  loading: boolean
  onRoleMouseEnter: MouseEventHandler<HTMLDivElement>
  onRoleMouseLeave: MouseEventHandler<HTMLDivElement>
  onNextClick: MouseEventHandler<HTMLButtonElement>

  appearance?: 'primary' | 'secondary'
}

export const Role: FC<RoleProps> = ({
  name,
  features,
  visible,
  loading,
  onRoleMouseEnter,
  onRoleMouseLeave,
  onNextClick,
  appearance = 'primary',
}) => {
  const theme = useTheme()

  return (
    <div
      css={[
        styles(theme).role.main.shared(visible),
        appearance === 'primary' && styles(theme).role.main.primary,
        appearance === 'secondary' && styles(theme).role.main.secondary,
      ]}
      onMouseEnter={(e) => onRoleMouseEnter(e)}
      onMouseLeave={(e) => onRoleMouseLeave(e)}
    >
      <div css={styles(theme).role.content.main}>
        <div css={styles(theme).role.content.title}>
          <Title text={name} appearance={appearance} />
        </div>
        <div css={styles(theme).role.content.features}>
          {features.map((feature) => (
            <Feature key={feature.id} {...feature} appearance={appearance} />
          ))}
        </div>
        <div css={styles(theme).role.content.next}>
          <ButtonLarge
            onClick={(e) => onNextClick(e)}
            aria-label={`select role ${name}`}
            disabled={loading}
            appearance={appearance === 'primary' ? 'secondary' : 'primary'}
          >
            {loading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Become a ${name}`
            )}
          </ButtonLarge>
        </div>
      </div>
    </div>
  )
}

Role.propTypes = {}

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// config
import { ExternalLinks } from '@/config'

// components
import { Link } from './components'
// styles
import { styles } from './styles'

type NavFooterProps = {}

export const NavFooter: FC<NavFooterProps> = () => {
  const theme = useTheme()

  return (
    <footer css={styles(theme).footer.main}>
      <div css={styles(theme).footer.terms}>
        <Link name="Terms of use" href={ExternalLinks.TermsOfUse} />
        <span css={styles(theme).footer.divider}>|</span>
        <Link name="Privacy Policy" href={ExternalLinks.PrivacyPolicy} />
      </div>
      <div css={styles(theme).footer.contacts}>
        <Link name="FAQs" href={ExternalLinks.FAQ} />
        <span css={styles(theme).footer.divider}>|</span>
        <Link name="Support" href={ExternalLinks.Support} />
        <span css={styles(theme).footer.divider}>|</span>
        <Link name="Contact us" href={ExternalLinks.Contacts} />
      </div>
      <div css={styles(theme).footer.copyright}>
        © 2021-{new Date().getFullYear()} Ryddm Inc. All Rights Reserved
      </div>
    </footer>
  )
}

NavFooter.propTypes = {}

import { createAsyncThunk } from '@reduxjs/toolkit'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // unlock
  unlockSetPostUnlocks,
  unlockSetTrackUnlocks,
  unlockCloseUnlockPostModal,
  unlockCloseUnlockTrackModal,
  // payment
  PaymentOperationType,
  paymentOpenPaymentModal,
} from '@/features'

export const unlockGetUnlockedPostIds = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('unlock/unlockGetUnlockedPostIds', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve unlocked post ids
    const { unlockedPostIds } = await api.unlockApi.getUnlockedPostIds()

    // set post unlocks
    dispatch(unlockSetPostUnlocks({ unlockedPostIds: unlockedPostIds || [] }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const unlockGetUnlockedTrackIds = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('unlock/unlockGetUnlockedTrackIds', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve unlocked track ids
    const { unlockedTrackIds } = await api.unlockApi.getUnlockedTrackIds()

    // set track unlocks
    dispatch(unlockSetTrackUnlocks({ unlockedTrackIds: unlockedTrackIds || [] }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const unlockCreatePostUnlock = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('unlock/unlockCreatePostUnlock', async (_, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get post from modal state
  const modalPost = getState().unlock.unlockPostModal.post
  const { id: postId } = modalPost ?? {}

  try {
    // create post unlock
    const { clientSecret } = await api.unlockApi.createPostUnlock({
      postId: postId || '',
    })

    // get current default customer payment method
    const { customerPaymentMethod } =
      await api.customerApi.getCustomerPaymentMethodDefault()

    // close post unlock modal
    dispatch(unlockCloseUnlockPostModal())

    // open payment modal
    dispatch(
      paymentOpenPaymentModal({
        clientSecret: clientSecret || '',
        defaultCustomerPaymentMethod: customerPaymentMethod,
        operationType: PaymentOperationType.PostUnlock,
        operationSubject: modalPost,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to unlock post: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const unlockCreateTrackUnlock = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('unlock/unlockCreateTrackUnlock', async (_, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get track from modal state
  const modalTrack = getState().unlock.unlockTrackModal.track
  const { id: trackId } = modalTrack ?? {}

  try {
    // create track unlock
    const { clientSecret } = await api.unlockApi.createTrackUnlock({
      trackId: trackId || '',
    })

    // get current default customer payment method
    const { customerPaymentMethod } =
      await api.customerApi.getCustomerPaymentMethodDefault()

    // close unlock track modal
    dispatch(unlockCloseUnlockTrackModal())

    // open payment modal
    dispatch(
      paymentOpenPaymentModal({
        clientSecret: clientSecret || '',
        defaultCustomerPaymentMethod: customerPaymentMethod,
        operationType: PaymentOperationType.TrackUnlock,
        operationSubject: modalTrack,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to unlock track: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

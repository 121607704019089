import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  login: {
    main: css`
      text-align: center;
    `,
    text: css``,
    button: css`
      ${helpers.resetDefaults};

      font-weight: bold;
      color: ${theme.colors.secondary.normal};

      padding: 10px 12px;
      transition: color 0.2s;

      cursor: pointer;

      &:hover {
        color: ${theme.colors.accent.normal};
      }
    `,
  },
})

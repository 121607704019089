import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  description: {
    main: css`
      text-align: center;
    `,
    title: css`
      font-size: ${typography.h4};
      font-weight: bold;
      margin-bottom: 8px;
    `,
    subtitle: css`
      font-size: ${typography.h6};
      font-weight: bold;
    `,
    accent: css`
      color: ${theme.colors.accent.normal};
    `,
  },
})

import { css, Theme } from '@emotion/react'
import { typography, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  feature: {
    main: css`
      display: flex;

      ${mq.mobile(css`
        flex-direction: column;
      `)}
    `,

    icon: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;

        flex-shrink: 0;

        width: 80px;
        height: 80px;

        margin-right: 24px;

        ${mq.mobile(css`
          align-self: center;
          margin-right: 0px;
          margin-bottom: 20px;
        `)}
      `,
      content: {
        shared: css`
          width: 58px;
          height: 58px;
        `,
        primary: css`
          & > path {
            fill: ${theme.colors.secondary.normal};
          }
        `,
        secondary: css`
          & > path {
            fill: ${theme.colors.accent.normal};
          }
        `,
      },
    },

    content: {
      main: {
        shared: css`
          ${mq.mobile(css`
            text-align: center;
          `)}
        `,
        primary: css`
          color: ${theme.colors.primary.normal};
        `,
        secondary: css`
          color: ${theme.colors.secondary.normal};
        `,
      },
      title: css`
        font-size: ${typography.h6};
        font-weight: bold;
        text-transform: uppercase;
      `,
      description: css``,
    },
  },
})

import { css, Theme } from '@emotion/react'
import { mq, helpers, kf } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: css`
      ${helpers.hideScrollbar};
      ${helpers.app.padding.full};

      display: flex;
      flex-direction: column;

      width: 740px;
      border-radius: 10px;

      overflow: hidden;
      overflow-y: scroll;

      background: linear-gradient(
        180deg,
        ${theme.colors.accent.darkHover} 0%,
        ${theme.colors.primary.normal} 100%
      );

      animation: 0.4s ease 0.2s backwards ${kf.slideIn.up(50)};

      ${mq.tablet(css`
        width: 100%;
        height: 100%;

        border-radius: 0px;
        padding-top: 0px;
      `)}

      ${mq.mobile(css`
        padding-top: 0px;
      `)}
    `,

    header: css`
      display: none;

      ${mq.tablet(css`
        display: inline-block;
      `)}
    `,

    composition: css`
      margin-bottom: 24px;
    `,

    description: css`
      margin-bottom: 42px;

      ${mq.tablet(
        css`
          margin-bottom: auto;
        `,
      )}
    `,

    buttons: css`
      margin-top: 22px;

      & > div:nth-of-type(1) {
        margin-bottom: 24px;
      }
    `,
  },
})

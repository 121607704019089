import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  EntityNotificationEvent,
  EntityNotificationEntityType,
  EntityTipView,
  EntityUnlockView,
  EntitySubscriptionView,
} from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import {
  useAppSelector,
  useUsers,
  useTip,
  useUnlock,
  useSubscription,
  useConnectPayout,
} from '@/hooks'
// features
import {
  // notification
  notificationSelectNotification,
  notificationSelectNotificationFetched,
} from '@/features'
// components
import { ContentPlaceholder, SkeletonHorizontal } from '@/components'
// icons
import { icons } from '@/assets'

// utils
import { getNotificationOthers, getNotificationTitle } from './utils'
// components
import {
  CommentControls,
  PostControls,
  SubscriptionControls,
  TipControls,
  TrackControls,
  UnlockControls,
  SourceUser,
} from './components'
// styles
import { styles } from './styles'

type NotificationViewNotificationProps = {}

export const NotificationViewNotification: FC<NotificationViewNotificationProps> = () => {
  const navigate = useNavigate()

  const notification = useAppSelector(notificationSelectNotification)
  const notificationFetched = useAppSelector(notificationSelectNotificationFetched)

  const {
    sourceUserIds,
    sourceUserIdsCount,
    entityType,
    entityId,
    parentEntityId,
    event,
  } = notification ?? {}

  // source users
  const { users: sourceUsers, isLoading: isSourceUsersLoading } = useUsers(
    sourceUserIds || [],
  )
  const userLoadingPlaceholder = 'Loading...'

  // notification types
  const isTrackNotification = entityType === EntityNotificationEntityType.Track
  const isPostNotification = entityType === EntityNotificationEntityType.Post
  const isCommentNotification = entityType === EntityNotificationEntityType.Comment
  const isTipNotification = entityType === EntityNotificationEntityType.Tip
  const isUnlockNotification = entityType === EntityNotificationEntityType.Unlock
  const isSubscriptionNotification =
    entityType === EntityNotificationEntityType.Subscription
  const isConnectPayoutNotification =
    entityType === EntityNotificationEntityType.ConnectPayout

  // notification tip
  const { tip: notificationTip, isLoading: isNotificationTipLoading } = useTip(
    isTipNotification ? entityId || '' : '',
  )

  // notification unlock
  const { unlock: notificationUnlock, isLoading: isNotificationUnlockLoading } =
    useUnlock(isUnlockNotification ? entityId || '' : '')

  // notification subscription
  const {
    subscription: notificationSubscription,
    isLoading: isNotificationSubscriptionLoading,
  } = useSubscription(isSubscriptionNotification ? entityId || '' : '')

  // notification connect payout
  const {
    connectPayout: notificationConnectPayout,
    isLoading: isNotificationConnectPayoutLoading,
  } = useConnectPayout(isConnectPayoutNotification ? entityId || '' : '')

  const isTipControlsDisabled = isNotificationTipLoading || !notificationTip
  const isUnlockControlsDisabled = isNotificationUnlockLoading || !notificationUnlock
  const isSubscriptionControlsDisabled =
    isNotificationSubscriptionLoading || !notificationSubscription

  // if notification not found
  if (notificationFetched && !notification) {
    return (
      <div css={styles().view.main}>
        <ContentPlaceholder
          icon={icons.AlertTriangle}
          height="80px"
          title="Could not find notification"
        />
      </div>
    )
  }

  const renderNotificationSourceUsers = () => {
    // if source users loading
    if (isSourceUsersLoading) {
      // return placeholder
      return <span>{userLoadingPlaceholder} </span>
    }

    // if source users loaded
    if (sourceUsers) {
      // render source users
      return sourceUsers.map(({ id: userId, username }, idx) => {
        const isLastSource = sourceUsers.length - 1 === idx
        return (
          <span key={`notification-view-notification-source-${userId}`}>
            <SourceUser
              label="source user"
              username={username || 'unknown'}
              onClick={() => navigate(`${Path.User}/${userId}`)}
            />
            {isLastSource ? ' ' : ', '}
          </span>
        )
      })
    }

    return <span />
  }

  // if notification found
  if (notificationFetched && notification) {
    return (
      <div css={styles().view.main}>
        <div css={styles().view.content.main}>
          <div css={styles().view.content.identifier}>
            {renderNotificationSourceUsers()}
            {getNotificationOthers(sourceUserIds || [], sourceUserIdsCount || 0)}{' '}
            {event &&
              getNotificationTitle(event, {
                tip: notificationTip,
                isTipLoading: isNotificationTipLoading,
                unlock: notificationUnlock,
                isUnlockLoading: isNotificationUnlockLoading,
                subscription: notificationSubscription,
                isSubscriptionLoading: isNotificationSubscriptionLoading,
                connectPayout: notificationConnectPayout,
                isConnectPayoutLoading: isNotificationConnectPayoutLoading,
              })}
          </div>
          <div css={styles().view.content.navigation}>
            {/* track controls */}
            {isTrackNotification && <TrackControls trackId={entityId || ''} />}

            {/* post controls */}
            {isPostNotification && <PostControls postId={entityId || ''} />}

            {/* comment controls */}
            {isCommentNotification && (
              <CommentControls
                commentId={entityId || ''}
                parentCommentId={parentEntityId || ''}
              />
            )}

            {/* tip controls */}
            {isTipNotification && (
              <TipControls
                event={notification.event as EntityNotificationEvent}
                tip={notificationTip as EntityTipView}
                isDisabled={isTipControlsDisabled}
              />
            )}

            {/* unlock controls */}
            {isUnlockNotification && (
              <UnlockControls
                event={notification.event as EntityNotificationEvent}
                unlock={notificationUnlock as EntityUnlockView}
                isDisabled={isUnlockControlsDisabled}
              />
            )}

            {/* subscription controls */}
            {isSubscriptionNotification && (
              <SubscriptionControls
                event={notification.event as EntityNotificationEvent}
                subscription={notificationSubscription as EntitySubscriptionView}
                isDisabled={isSubscriptionControlsDisabled}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div css={styles().view.main}>
      <SkeletonHorizontal
        sections={[
          {
            name: 'notification-view-notifications-notification',
            width: '100%',
            height: '80px',
            isStatic: false,
            isDivider: false,
          },
        ]}
      />
    </div>
  )
}

NotificationViewNotification.propTypes = {}

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { paymentSelectPaymentModalOpen, paymentClosePaymentModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { PaymentCard } from './components'
// styles
import { styles } from './styles'

type PaymentModalProps = {}

export const PaymentModal: FC<PaymentModalProps> = () => {
  const dispatch = useAppDispatch()
  const paymentModalOpen = useAppSelector(paymentSelectPaymentModalOpen)

  return (
    <div css={styles().modal.main}>
      {paymentModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(paymentClosePaymentModal())}
        >
          <div css={styles().modal.content}>
            <PaymentCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

PaymentModal.propTypes = {}

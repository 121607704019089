import { FC, memo } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authOpenJoinModal,
  // user
  ArtistCard,
  userSelectPublicArtists,
  userGetPublicArtists,
  userUtilGetArtistCardSkeletonSections,
} from '@/features'
// components
import { ContentLayoutVirtualized, SkeletonVertical } from '@/components'
// styles
import { contentLayout } from '@/styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const dispatch = useAppDispatch()
  const artists = useAppSelector(userSelectPublicArtists)
  const artist = artists[index]

  // if artist not fetched
  if (!artist) {
    return (
      <div style={style}>
        <SkeletonVertical
          containerWidth="140px"
          sections={userUtilGetArtistCardSkeletonSections('artist-list-home-public')}
        />
      </div>
    )
  }

  const openJoinModal = () => dispatch(authOpenJoinModal())

  return (
    <div style={style}>
      <ArtistCard
        artist={artist}
        artistActions={{
          isFollowed: false,
          isSubscribed: false,
          isSubscribedAtPeriodEnd: false,
          isPublic: true,

          onFollowClick: openJoinModal,
          onUnfollowClick: openJoinModal,
          onCreateSubscriptionClick: openJoinModal,
          onRenewSubscriptionClick: openJoinModal,
          onCancelSubscriptionClick: openJoinModal,
        }}
        onNameClick={openJoinModal}
        onUsernameClick={openJoinModal}
      />
    </div>
  )
})

type ArtistListHomePublicProps = {}

export const ArtistListHomePublic: FC<ArtistListHomePublicProps> = () => {
  const dispatch = useAppDispatch()
  const publicArtists = useAppSelector(userSelectPublicArtists)

  const artistBlockWidth = 140
  const artistBlockMarginRight = 20
  const itemWidth = artistBlockWidth + artistBlockMarginRight

  const artistBlockHeight = 242
  const artistBlockMarginBottom = 0
  const itemHeight = artistBlockHeight + artistBlockMarginBottom

  const defaultContentCount = 60
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 15 rows

  const isItemLoaded = (index: number) => !!publicArtists[index]

  const loadMoreItems = (): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(userGetPublicArtists())
  }

  return (
    <ContentLayoutVirtualized
      title="Popular Artists"
      overrideCss={contentLayout.home}
      itemWidth={itemWidth}
      itemsCount={defaultContentCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={defaultContentCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={defaultContentCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

ArtistListHomePublic.propTypes = {}

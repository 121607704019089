import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type DividerProps = {
  maxWidth?: string
  horizontalMargin?: { left: string; right: string }
  verticalMargin?: { top: string; bottom: string }
}

export const Divider: FC<DividerProps> = ({
  maxWidth = '',
  horizontalMargin = undefined,
  verticalMargin = undefined,
}) => {
  const theme = useTheme()

  return (
    <div
      css={[
        styles(theme).divider.main,
        maxWidth && styles(theme).divider.maxWidth(maxWidth),
        horizontalMargin && styles(theme).divider.horizontalMargin(horizontalMargin),
        verticalMargin && styles(theme).divider.verticalMargin(verticalMargin),
      ]}
    />
  )
}

Divider.propTypes = {}

import { FC, useState } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { authSelectLoading, authSignupWithEmailAndPassword } from '@/features'
// utils
import { validateEmail, validatePassword, validateRepeatPassword } from '@/utils'
// components
import { Loader, ButtonLarge } from '@/components'

// components
import { FormInput } from './components'
// styles
import { styles } from './SignupForm.styles'

type SignupFormProps = {}

export const SignupForm: FC<SignupFormProps> = () => {
  const dispatch = useAppDispatch()
  const authLoading = useAppSelector(authSelectLoading)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const [repeatPassword, setRepeatPassword] = useState('')
  const [repeatPasswordError, setRepeatPasswordError] = useState('')

  const submitDisabled =
    email.length < 4 ||
    password.length < 6 ||
    repeatPassword.length < 6 ||
    emailError !== '' ||
    passwordError !== '' ||
    repeatPasswordError !== '' ||
    authLoading

  return (
    <div css={styles().form.main}>
      <div css={styles().form.title.main}>
        <div css={styles().form.title.text}>Sign up with email and password</div>
      </div>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(authSignupWithEmailAndPassword({ email, password }))
        }}
      >
        <div css={styles().form.content.email}>
          <FormInput
            name="email"
            label="email"
            type="email"
            placeholder="Enter your email"
            value={email}
            error={emailError.length > 0}
            errorMessage={emailError}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            onBlur={() => setEmailError(validateEmail(email))}
            onFocus={() => setEmailError('')}
          />
        </div>
        <div css={styles().form.content.password}>
          <FormInput
            name="password"
            label="password"
            type="password"
            placeholder="Create a password"
            value={password}
            error={passwordError.length > 0}
            errorMessage={passwordError}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            onBlur={() => setPasswordError(validatePassword(password))}
            onFocus={() => setPasswordError('')}
          />
        </div>
        <div css={styles().form.content.repeat}>
          <FormInput
            name="repeat password"
            label="repeat password"
            type="password"
            placeholder="Repeat password"
            value={repeatPassword}
            error={repeatPasswordError.length > 0}
            errorMessage={repeatPasswordError}
            onChange={(e) => {
              setRepeatPassword(e.target.value)
            }}
            onBlur={() =>
              setRepeatPasswordError(validateRepeatPassword(password, repeatPassword))
            }
            onFocus={() => setRepeatPasswordError('')}
          />
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="sign up"
            disabled={submitDisabled}
            appearance="primary"
          >
            {authLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Sign up'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

SignupForm.propTypes = {}

/**
 * Generates RFC4122-compliant uuid v4.
 *
 * Generates uuid version 4 and returns it.
 *
 * @returns uuid version 4.
 *
 * @example
 * // Returns uuid version 4.
 * const uuid = uuid();
 */
export const uuid = () =>
  // @ts-ignore: Operator '+' cannot be applied to types 'number[]' and 'number'
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c: number) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16), // eslint-disable-line no-bitwise
  )

import { createSlice } from '@reduxjs/toolkit'
import { EntityConnectStatus } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// features
import {
  connectGetConnectStatus,
  connectGetConnectBalance,
  connectGetConnectOnboardingLink,
  connectGetConnectLoginLink,
  connectCreatePayout,
} from '@/features'

export interface ConnectState {
  connectStatus: EntityConnectStatus | undefined
  connectBalance: {
    available: number
    pending: number
  }

  connectOnboardingLink: string
  connectLoginLink: string

  connectGetConnectStatusLoading: boolean
  connectGetConnectBalanceLoading: boolean
  connectGetConnectOnboardingLinkLoading: boolean
  connectGetConnectLoginLinkLoading: boolean
  connectCreatePayoutLoading: boolean
}

const initialState: ConnectState = {
  connectStatus: undefined,
  connectBalance: {
    available: 0,
    pending: 0,
  },

  connectOnboardingLink: '',
  connectLoginLink: '',

  connectGetConnectStatusLoading: false,
  connectGetConnectBalanceLoading: false,
  connectGetConnectOnboardingLinkLoading: false,
  connectGetConnectLoginLinkLoading: false,
  connectCreatePayoutLoading: false,
}

export const connectSlice = createSlice({
  name: 'connect',
  initialState,
  reducers: {
    // reset state
    connectResetState: () => ({
      connectStatus: undefined,
      connectBalance: {
        available: 0,
        pending: 0,
      },

      connectOnboardingLink: '',
      connectLoginLink: '',

      connectGetConnectStatusLoading: false,
      connectGetConnectBalanceLoading: false,
      connectGetConnectOnboardingLinkLoading: false,
      connectGetConnectLoginLinkLoading: false,
      connectCreatePayoutLoading: false,
    }),
  },
  extraReducers: (builder) => {
    // get connect status
    builder
      .addCase(connectGetConnectStatus.pending, (state) => ({
        ...state,
        connectGetConnectStatusLoading: true,
      }))
      .addCase(connectGetConnectStatus.fulfilled, (state, { payload }) => ({
        ...state,
        connectStatus: payload.status,
        connectGetConnectStatusLoading: false,
      }))

    // get connect balance
    builder
      .addCase(connectGetConnectBalance.pending, (state) => ({
        ...state,
        connectGetConnectBalanceLoading: true,
      }))
      .addCase(connectGetConnectBalance.fulfilled, (state, { payload }) => ({
        ...state,
        connectBalance: payload.balance,
        connectGetConnectBalanceLoading: false,
      }))

    // get connect onboarding link
    builder
      .addCase(connectGetConnectOnboardingLink.pending, (state) => ({
        ...state,
        connectGetConnectOnboardingLinkLoading: true,
      }))
      .addCase(connectGetConnectOnboardingLink.fulfilled, (state, { payload }) => ({
        ...state,
        connectOnboardingLink: payload.onboardingLink,
        connectGetConnectOnboardingLinkLoading: false,
      }))

    // get connect login link
    builder
      .addCase(connectGetConnectLoginLink.pending, (state) => ({
        ...state,
        connectGetConnectLoginLinkLoading: true,
      }))
      .addCase(connectGetConnectLoginLink.fulfilled, (state, { payload }) => ({
        ...state,
        connectLoginLink: payload.loginLink,
        connectGetConnectLoginLinkLoading: false,
      }))

    // create payout
    builder
      .addCase(connectCreatePayout.pending, (state) => ({
        ...state,
        connectCreatePayoutLoading: true,
      }))
      .addCase(connectCreatePayout.fulfilled, (state) => ({
        ...state,
        connectCreatePayoutLoading: false,
      }))
  },
})

export const { connectResetState } = connectSlice.actions

export const connectSelectConnectBalance = (state: RootState) =>
  state.connect.connectBalance
export const connectSelectConnectStatus = (state: RootState) =>
  state.connect.connectStatus

export const connectSelectConnectOnboardingLink = (state: RootState) =>
  state.connect.connectOnboardingLink
export const connectSelectConnectLoginLink = (state: RootState) =>
  state.connect.connectLoginLink

export const connectSelectConnectGetConnectStatusLoading = (state: RootState) =>
  state.connect.connectGetConnectStatusLoading
export const connectSelectConnectGetConnectBalanceLoading = (state: RootState) =>
  state.connect.connectGetConnectBalanceLoading
export const connectSelectConnectGetConnectOnboardingLinkLoading = (state: RootState) =>
  state.connect.connectGetConnectOnboardingLinkLoading
export const connectSelectConnectGetConnectLoginLinkLoading = (state: RootState) =>
  state.connect.connectGetConnectLoginLinkLoading
export const connectSelectConnectCreatePayoutLoading = (state: RootState) =>
  state.connect.connectCreatePayoutLoading

export const connectReducer = connectSlice.reducer

// export default connectSlice.reducer

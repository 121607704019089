import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityReportEntityType } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  ReportCard,
  reportSelectReportGetReportsLoading,
  reportSelectReports,
  reportSelectReportsCount,
  reportSelectReportsLength,
  reportSelectReportsFetched,
  reportSelectReportsFilter,
  reportSetReportsFilter,
  reportGetReports,
} from '@/features'
// components
import {
  NavigationScrollable,
  ContentPlaceholder,
  SkeletonHorizontal,
} from '@/components'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const reports = useAppSelector(reportSelectReports)
  const report = reports[index]

  // if report not fetched
  if (!report) {
    return (
      <div style={style}>
        <SkeletonHorizontal
          sections={[
            {
              name: 'report-list-reports-report',
              width: '100%',
              height: '60px',
              isStatic: false,
              isDivider: false,
            },
          ]}
        />
      </div>
    )
  }

  const { id: reportId } = report

  return (
    <div css={styles().report.main} style={style}>
      <ReportCard
        report={report}
        onClick={() => navigate(`${Path.Reports}${Path.Report}/${reportId}`)}
      />
    </div>
  )
})

type ReportListReportsProps = {}

export const ReportListReports: FC<ReportListReportsProps> = () => {
  const dispatch = useAppDispatch()
  const reportGetReportsLoading = useAppSelector(reportSelectReportGetReportsLoading)
  const reports = useAppSelector(reportSelectReports)
  const reportsCount = useAppSelector(reportSelectReportsCount)
  const reportsLength = useAppSelector(reportSelectReportsLength)
  const reportsFetched = useAppSelector(reportSelectReportsFetched)
  const reportsFilter = useAppSelector(reportSelectReportsFilter)

  const reportCardHeight = 60
  const reportCardMarginBottom = 20
  const itemHeight = reportCardHeight + reportCardMarginBottom
  const itemsToFit = 6

  const defaultContentCount = 1000
  const defaultListWidth = '100%'
  const defaultListHeight = itemsToFit * itemHeight
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!reports[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(reportGetReports({ limit: itemsToFetch, offset: startIndex }))
  }

  // renders report list navigation
  const renderReportListNavigation = () => (
    <div css={styles().list.nav.main}>
      <NavigationScrollable
        options={[
          {
            id: '14971507-83aa-4bf6-a1af-9bfe525f6d32',
            label: 'User',
            enabled: reportsFilter === EntityReportEntityType.User,
            onClick: () => {
              // set reports filter
              dispatch(reportSetReportsFilter({ filter: EntityReportEntityType.User }))

              // refetch reports
              dispatch(reportGetReports({ limit: 30, offset: 0 }))
            },
          },
          {
            id: '9ba9f8d0-624b-4698-b024-e0a6fd752d29',
            label: 'Track',
            enabled: reportsFilter === EntityReportEntityType.Track,
            onClick: () => {
              // set reports filter
              dispatch(reportSetReportsFilter({ filter: EntityReportEntityType.Track }))

              // refetch reports
              dispatch(reportGetReports({ limit: 30, offset: 0 }))
            },
          },
          {
            id: 'd53b86eb-48e7-4abc-aa96-482944eb7a5b',
            label: 'Post',
            enabled: reportsFilter === EntityReportEntityType.Post,
            onClick: () => {
              // set reports filter
              dispatch(reportSetReportsFilter({ filter: EntityReportEntityType.Post }))

              // refetch reports
              dispatch(reportGetReports({ limit: 30, offset: 0 }))
            },
          },
          {
            id: 'df95c9e0-99c1-4da9-9baf-939f26bc3d09',
            label: 'Comment',
            enabled: reportsFilter === EntityReportEntityType.Comment,
            onClick: () => {
              // set reports filter
              dispatch(reportSetReportsFilter({ filter: EntityReportEntityType.Comment }))

              // refetch reports
              dispatch(reportGetReports({ limit: 30, offset: 0 }))
            },
          },
        ]}
      />
    </div>
  )

  // renders report list table header
  const renderReportListTableHeader = () => (
    <div css={styles().list.header.main}>
      <div css={styles().list.header.placeholder} />
      <div css={styles().list.header.identifier}>Identifier</div>
      <div css={styles().list.header.type}>Type</div>
      <div css={styles().list.header.status}>Status</div>
      <div css={styles().list.header.date}>Date</div>
    </div>
  )

  // if reports are fetched but length is 0
  if (!reportGetReportsLoading && reportsFetched && reportsLength === 0) {
    // return place holder
    return (
      // used to be content layout virtualized
      <div css={styles().list.main}>
        {renderReportListNavigation()}
        {renderReportListTableHeader()}
        <div css={styles().list.content.main}>
          <ContentPlaceholder
            icon={icons.CheckCircle}
            height={`${defaultListHeight}px`}
            title="There are no unresolved reports"
          />
        </div>
      </div>
    )
  }

  return (
    // used to be content layout virtualized
    <div css={styles().list.main}>
      {renderReportListNavigation()}
      {renderReportListTableHeader()}
      <div css={styles().list.content.main}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          itemCount={reportsCount || defaultContentCount}
          minimumBatchSize={itemsToFetch}
          threshold={itemThreshold}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className="virtualized-list"
              height={defaultListHeight}
              itemSize={itemHeight}
              itemCount={reportsCount || defaultContentCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={defaultListWidth}
              layout="vertical"
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </div>
    </div>
  )
}

ReportListReports.propTypes = {}

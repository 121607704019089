import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  EntityCustomerPaymentMethod,
  EntityUserView,
  EntityPostView,
  EntityTrackView,
} from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// features
import {
  PaymentOperationType,
  paymentCreatePayment,
  paymentCreateDefaultPayment,
} from '@/features'

export interface PaymentState {
  paymentModal: {
    clientSecret: string
    defaultCustomerPaymentMethod: EntityCustomerPaymentMethod | undefined
    operationType: PaymentOperationType | undefined
    operationSubject: EntityUserView | EntityPostView | EntityTrackView | undefined
    open: boolean
  }

  paymentCreatePaymentLoading: boolean
  paymentCreateDefaultPaymentLoading: boolean
}

const initialState: PaymentState = {
  paymentModal: {
    clientSecret: '',
    defaultCustomerPaymentMethod: undefined,
    operationType: undefined,
    operationSubject: undefined,
    open: false,
  },

  paymentCreatePaymentLoading: false,
  paymentCreateDefaultPaymentLoading: false,
}

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // reset state
    paymentResetState: () => ({
      paymentModal: {
        clientSecret: '',
        defaultCustomerPaymentMethod: undefined,
        operationType: undefined,
        operationSubject: undefined,
        open: false,
      },

      paymentCreatePaymentLoading: false,
      paymentCreateDefaultPaymentLoading: false,
    }),

    // open payment modal
    paymentOpenPaymentModal: (
      state,
      action: PayloadAction<{
        clientSecret: string
        defaultCustomerPaymentMethod: EntityCustomerPaymentMethod | undefined
        operationType: PaymentOperationType
        operationSubject: EntityUserView | EntityPostView | EntityTrackView | undefined
      }>,
    ) => ({
      ...state,
      paymentModal: {
        clientSecret: action.payload.clientSecret,
        defaultCustomerPaymentMethod: action.payload.defaultCustomerPaymentMethod,
        operationType: action.payload.operationType,
        operationSubject: action.payload.operationSubject,
        open: true,
      },
    }),

    // close payment modal
    paymentClosePaymentModal: (state) => ({
      ...state,
      paymentModal: {
        clientSecret: '',
        defaultCustomerPaymentMethod: undefined,
        operationType: undefined,
        operationSubject: undefined,
        open: false,
      },
      error: '',
    }),
  },
  extraReducers: (builder) => {
    // create payment
    builder
      .addCase(paymentCreatePayment.pending, (state) => ({
        ...state,
        paymentCreatePaymentLoading: true,
      }))
      .addCase(paymentCreatePayment.fulfilled, (state) => ({
        ...state,
        paymentCreatePaymentLoading: false,
      }))

    // create default payment (payment utilizing default payment method)
    builder
      .addCase(paymentCreateDefaultPayment.pending, (state) => ({
        ...state,
        paymentCreateDefaultPaymentLoading: true,
      }))
      .addCase(paymentCreateDefaultPayment.fulfilled, (state) => ({
        ...state,
        paymentCreateDefaultPaymentLoading: false,
      }))
  },
})

export const { paymentResetState, paymentOpenPaymentModal, paymentClosePaymentModal } =
  paymentSlice.actions

export const paymentSelectPaymentModalOpen = (state: RootState) =>
  state.payment.paymentModal.open
export const paymentSelectPaymentModalClientSecret = (state: RootState) =>
  state.payment.paymentModal.clientSecret
export const paymentSelectPaymentModalDefaultCustomerPaymentMethod = (state: RootState) =>
  state.payment.paymentModal.defaultCustomerPaymentMethod

export const paymentSelectPaymentCreatePaymentLoading = (state: RootState) =>
  state.payment.paymentCreatePaymentLoading
export const paymentSelectPaymentCreateDefaultPaymentLoading = (state: RootState) =>
  state.payment.paymentCreateDefaultPaymentLoading

export const paymentReducer = paymentSlice.reducer

// export default paymentSlice.reducer

import { css, Theme } from '@emotion/react'
import { helpers, typography, kf } from '@/styles'

export const styles = (theme: Theme) => ({
  input: {
    main: css`
      position: relative;

      display: flex;
      flex-direction: column;
    `,

    header: {
      main: {
        shared: css`
          position: relative;

          display: flex;
          align-items: center;

          width: 100%;
          height: 28px;

          border-bottom: 1px solid ${theme.colors.secondary.normal};
          transition: border 0.2s;
        `,
        open: css`
          border-bottom: 1px solid ${theme.colors.accent.normal};
        `,
      },
      option: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.p};
        font-weight: bold;
        user-select: none;

        margin-right: 28px;
        cursor: pointer;
      `,
      icon: {
        shared: css`
          position: absolute;
          right: 0;
          top: 0;

          display: flex;
          align-items: center;
          justify-content: center;

          width: 28px;
          height: 28px;

          transform: rotate(180deg);
          transition: transform 0.2s;

          & > svg {
            width: 16px;
            height: 16px;

            & > path {
              fill: ${theme.colors.secondary.normal};
            }
          }
        `,
        open: css`
          transform: rotate(0deg);

          & > svg {
            & > path {
              fill: ${theme.colors.accent.normal};
            }
          }
        `,
      },
    },

    options: {
      main: {
        shared: css`
          position: absolute;
          left: 0;
          top: 36px;

          display: none;
          width: 100%;

          background-color: ${theme.colors.primary.normal};
          padding: 6px 0px;

          animation: 0.2s ease 0s backwards ${kf.slideIn.down(10)};

          & > div:not(:last-of-type) {
            margin-bottom: 8px;
          }
        `,
        open: css`
          display: flex;
          flex-direction: column;
        `,
      },
      option: {
        shared: css`
          ${helpers.textOverflowEllipsis};

          width: 100%;
          height: 28px;

          font-size: ${typography.p};
          font-weight: bold;
          user-select: none;

          cursor: pointer;
        `,
        selected: css`
          color: ${theme.colors.accent.normal};
        `,
      },
    },
  },
})

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { reportSelectReportPostModalOpen, reportCloseReportPostModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { ReportPostCard } from './components'
// styles
import { styles } from './styles'

type ReportPostModalProps = {}

export const ReportPostModal: FC<ReportPostModalProps> = () => {
  const dispatch = useAppDispatch()
  const reportPostModalOpen = useAppSelector(reportSelectReportPostModalOpen)

  return (
    <div css={styles().modal.main}>
      {reportPostModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(reportCloseReportPostModal())}
        >
          <div css={styles().modal.content}>
            <ReportPostCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

ReportPostModal.propTypes = {}

import useSWR from 'swr'

// libs
import { apiGetService, apiHandleError } from '@/lib'

// types
import { SWRKeys, SWRFetcherData } from './types'

const getComment = async ({
  data: { commentId },
}: SWRFetcherData<{ commentId: string }>) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve comment
    const { comment } = await api.commentApi.getComment(commentId)

    // return payload
    return { comment, error: '' }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return payload
    return { comment: undefined, error: message }
  }
}

const fetcher = (args: SWRFetcherData<{ commentId: string }>) => getComment(args)

export const useComment = (commentId: string) => {
  const shouldFetch = !!commentId

  const { data, isLoading, mutate } = useSWR(
    shouldFetch ? { key: SWRKeys.UseComment, data: { commentId } } : null,
    fetcher,
    { revalidateOnFocus: false },
  )

  return {
    comment: data?.comment,
    isLoading,
    isError: data?.error,
    mutate,
  }
}

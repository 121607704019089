import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type IdentifierProps = {
  identifier: string
}

export const Identifier: FC<IdentifierProps> = ({ identifier }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).identifier.main}>
      <div css={styles(theme).identifier.id}>{`#${identifier}`}</div>
    </div>
  )
}

Identifier.propTypes = {}

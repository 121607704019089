import { useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>()

  // remember the latest callback
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // set up the interval
  useEffect(() => {
    const intervalId = setInterval(() => {
      savedCallback.current?.()
    }, delay)
    return () => clearInterval(intervalId)
  }, [delay])
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/stores'

export interface SearchState {
  term: string
}

const initialState: SearchState = {
  term: '',
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchSetTerm: (state, action: PayloadAction<string>) => ({
      ...state,
      term: action.payload,
    }),
    searchResetState: (state) => ({
      ...state,
      term: '',
    }),
  },
})

export const { searchSetTerm, searchResetState } = searchSlice.actions

export const searchSelectTerm = (state: RootState) => state.search.term

export const searchReducer = searchSlice.reducer

// export default searchSlice.reducer

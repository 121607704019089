import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// hooks
import {
  useAppSelector,
  useAppDispatch,
  useFile,
  useUser,
  useConnectReady,
} from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // player
  playerAudioSelectCurrentTrack,
  playerAudioSetClosed,
  // like
  likeCreateUserFollow,
  likeDeleteUserFollow,
  likeCreateTrackLike,
  likeDeleteTrackLike,
  likeSelectUserFollows,
  likeSelectTrackLikes,
  // subscription
  subscriptionOpenSubscriptionCancelModal,
  subscriptionOpenSubscriptionCreateModal,
  subscriptionOpenSubscriptionRenewModal,
  subscriptionSelectUserSubscriptions,
  subscriptionSelectUserSubscriptionsAtPeriodEnd,
  // tip
  tipOpenTipModal,
} from '@/features'
// icon
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TrackViewAudioPlaylistProps = {}

export const TrackViewAudioPlaylist: FC<TrackViewAudioPlaylistProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [coverLoadError, setCoverLoadError] = useState(false)

  // profile
  const profile = useAppSelector(authSelectProfile)
  const userFollows = useAppSelector(likeSelectUserFollows)
  const trackLikes = useAppSelector(likeSelectTrackLikes)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const userSubscriptionsAtPeriodEnd = useAppSelector(
    subscriptionSelectUserSubscriptionsAtPeriodEnd,
  )
  const { id: profileUserId } = profile ?? {}

  // track
  const track = useAppSelector(playerAudioSelectCurrentTrack)
  const { id: trackId, coverId, name: trackName, userId: trackAuthorUserId } = track ?? {}

  const trackHasCover = !!coverId
  const source = useFile(coverId || '')

  // track author
  const { user: trackAuthor, isLoading: isTrackAuthorLoading } = useUser(
    trackAuthorUserId || '',
  )
  const { name: authorName } = trackAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'

  // connect ready
  const { ready: connectReady } = useConnectReady(trackAuthorUserId || '')

  const likeTrackDisabled = false
  const dislikeTrackDisabled = false
  const followAuthorDisabled = false
  const unfollowAuthorDisabled = false
  const subscribeToAuthorDisabled = false
  const unsubscribeFromAuthorDisabled = false
  const tipAuthorDisabled = false

  const isTrackAuthorFollowed = userFollows[trackAuthorUserId || '']
  const isTrackLiked = trackLikes[trackId || '']

  const isCurrentUserContentAuthor = profileUserId === trackAuthorUserId
  const isCurrentUserSubscribedToContentAuthor =
    userSubscriptions[trackAuthorUserId || '']
  const isCurrentUserSubscribedToContentAuthorAtPeriodEnd =
    userSubscriptionsAtPeriodEnd[trackAuthorUserId || '']

  const isAuthorCanReceivePayments = connectReady && !isCurrentUserContentAuthor

  const subscribeAction = isCurrentUserSubscribedToContentAuthorAtPeriodEnd
    ? () =>
        // renew subscription
        dispatch(subscriptionOpenSubscriptionRenewModal({ artist: { ...trackAuthor } }))
    : () =>
        // else create new subscription
        dispatch(subscriptionOpenSubscriptionCreateModal({ artist: { ...trackAuthor } }))

  return (
    <div css={styles(theme).track.main}>
      {/* track cover */}
      <div css={styles(theme).track.cover.main}>
        {trackHasCover && !coverLoadError ? (
          <img
            css={styles(theme).track.cover.image}
            onError={() => setCoverLoadError(true)}
            loading="lazy"
            width="200px"
            height="200px"
            src={source}
            alt="track album cover"
          />
        ) : (
          <div css={styles(theme).track.cover.placeholder}>
            <icons.MusicNote />
          </div>
        )}
      </div>

      <div css={styles(theme).track.panel.main}>
        {/* track description */}
        <div css={styles(theme).track.panel.description.main}>
          <div css={styles(theme).track.panel.description.name}>{trackName}</div>
          <button
            css={styles(theme).track.panel.description.author}
            onClick={() => {
              navigate(`${Path.User}/${trackAuthorUserId}`)
              dispatch(playerAudioSetClosed())
            }}
            type="button"
            aria-label="explore track author"
          >
            {isTrackAuthorLoading
              ? userLoadingPlaceholder
              : authorName || userNamePlaceholder}
          </button>
        </div>

        {/* features */}
        <div css={styles(theme).track.panel.features.main}>
          {/* like / dislike */}
          {isTrackLiked ? (
            <div css={styles(theme).track.panel.features.dislike.main}>
              <button
                css={[
                  styles(theme).track.panel.features.button.shared,
                  styles(theme).track.panel.features.button.accent,
                ]}
                onClick={() => dispatch(likeDeleteTrackLike({ track }))}
                type="button"
                aria-label="dislike track"
                disabled={dislikeTrackDisabled}
              >
                <icons.Heart />
              </button>
            </div>
          ) : (
            <div css={styles(theme).track.panel.features.like.main}>
              <button
                css={[
                  styles(theme).track.panel.features.button.shared,
                  styles(theme).track.panel.features.button.normal,
                ]}
                onClick={() => dispatch(likeCreateTrackLike({ track }))}
                type="button"
                aria-label="like track"
                disabled={likeTrackDisabled}
              >
                <icons.Heart />
              </button>
            </div>
          )}

          {/* follow / unfollow */}
          {isTrackAuthorFollowed ? (
            <div css={styles(theme).track.panel.features.unfollow.main}>
              <button
                css={[
                  styles(theme).track.panel.features.button.shared,
                  styles(theme).track.panel.features.button.accent,
                ]}
                onClick={() =>
                  dispatch(likeDeleteUserFollow({ user: { ...trackAuthor } }))
                }
                type="button"
                aria-label="unfollow track author"
                disabled={unfollowAuthorDisabled}
              >
                <icons.UserPlus />
              </button>
            </div>
          ) : (
            <div css={styles(theme).track.panel.features.follow.main}>
              <button
                css={[
                  styles(theme).track.panel.features.button.shared,
                  styles(theme).track.panel.features.button.normal,
                ]}
                onClick={() =>
                  dispatch(likeCreateUserFollow({ user: { ...trackAuthor } }))
                }
                type="button"
                aria-label="follow track author"
                disabled={followAuthorDisabled}
              >
                <icons.UserPlus />
              </button>
            </div>
          )}

          {/* tip */}
          {isAuthorCanReceivePayments && (
            <div css={styles(theme).track.panel.features.tip.main}>
              <button
                css={[
                  styles(theme).track.panel.features.button.shared,
                  styles(theme).track.panel.features.button.normal,
                ]}
                onClick={() => dispatch(tipOpenTipModal({ artist: { ...trackAuthor } }))}
                type="button"
                aria-label="send tip to track author"
                disabled={tipAuthorDisabled}
              >
                <icons.DollarCircle />
              </button>
            </div>
          )}

          {/* subscribe / unsubscribe */}
          {isAuthorCanReceivePayments &&
            (isCurrentUserSubscribedToContentAuthor &&
            !isCurrentUserSubscribedToContentAuthorAtPeriodEnd ? (
              <div css={styles(theme).track.panel.features.unsubscribe.main}>
                <button
                  css={[
                    styles(theme).track.panel.features.button.shared,
                    styles(theme).track.panel.features.button.accent,
                  ]}
                  onClick={() =>
                    dispatch(
                      subscriptionOpenSubscriptionCancelModal({
                        artist: { ...trackAuthor },
                      }),
                    )
                  }
                  type="button"
                  aria-label="unsubscribe from track author"
                  disabled={unsubscribeFromAuthorDisabled}
                >
                  <icons.Lock />
                </button>
              </div>
            ) : (
              <div css={styles(theme).track.panel.features.subscribe.main}>
                <button
                  css={[
                    styles(theme).track.panel.features.button.shared,
                    styles(theme).track.panel.features.button.normal,
                  ]}
                  onClick={subscribeAction}
                  type="button"
                  aria-label="subscribe to track author"
                  disabled={subscribeToAuthorDisabled}
                >
                  <icons.Lock />
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

TrackViewAudioPlaylist.propTypes = {}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityUserView } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// features
import { tipCreateTip } from '@/features'

export interface TipState {
  tipModal: {
    artist: EntityUserView | undefined
    open: boolean
  }

  tipCreateTipLoading: boolean
}

const initialState: TipState = {
  tipModal: {
    artist: undefined,
    open: false,
  },

  tipCreateTipLoading: false,
}

export const tipSlice = createSlice({
  name: 'tip',
  initialState,
  reducers: {
    // reset state
    tipResetState: () => ({
      tipModal: {
        artist: undefined,
        open: false,
      },

      tipCreateTipLoading: false,
    }),

    // open tip modal
    tipOpenTipModal: (state, action: PayloadAction<{ artist: EntityUserView }>) => ({
      ...state,
      tipModal: {
        artist: action.payload.artist,
        open: true,
      },
    }),

    // close tip modal
    tipCloseTipModal: (state) => ({
      ...state,
      tipModal: {
        artist: undefined,
        open: false,
      },
      error: '',
    }),
  },
  extraReducers: (builder) => {
    // create tip
    builder
      .addCase(tipCreateTip.pending, (state) => ({
        ...state,
        tipCreateTipLoading: true,
      }))
      .addCase(tipCreateTip.fulfilled, (state) => ({
        ...state,
        tipCreateTipLoading: false,
      }))
  },
})

export const { tipResetState, tipOpenTipModal, tipCloseTipModal } = tipSlice.actions

export const tipSelectTipModalOpen = (state: RootState) => state.tip.tipModal.open
export const tipSelectTipModalArtist = (state: RootState) => state.tip.tipModal.artist

export const tipSelectTipCreateTipLoading = (state: RootState) =>
  state.tip.tipCreateTipLoading

export const tipReducer = tipSlice.reducer

// export default tipSlice.reducer

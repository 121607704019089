import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { EntityCommentView } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppDispatch, useComment } from '@/hooks'
// features
import {
  // comment
  commentAddDepth,
} from '@/features'
// icons
import { icons } from '@/assets'

// components
import { Control } from './components'

type CommentControlsProps = {
  commentId: string
  parentCommentId: string
}

export const CommentControls: FC<CommentControlsProps> = ({
  commentId,
  parentCommentId,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  // comment
  const { comment, isLoading } = useComment(commentId)

  // parent comment
  const { comment: parentComment, isLoading: parentCommentLoading } =
    useComment(parentCommentId)

  const isReply = !!parentCommentId
  const isDisabled = isLoading || !comment
  const isParentDisabled = isDisabled || parentCommentLoading || !parentComment

  // if reply
  if (isReply) {
    // return comment and reply controls
    return (
      <>
        <Control
          appearance="neutral"
          label="navigate to comment"
          icon={icons.Scope}
          text="View comment"
          disabled={isParentDisabled}
          onClick={() => {
            // add parent comment to comment depth
            dispatch(
              commentAddDepth({
                comment: parentComment as EntityCommentView,
              }),
            )

            // navigate to post page
            navigate(`${Path.Post}/${parentComment?.postId}`)
          }}
        />
        <Control
          appearance="neutral"
          label="navigate to reply"
          icon={icons.Scope}
          text="View reply"
          disabled={isDisabled}
          onClick={() => {
            // add parent comment to comment depth
            dispatch(
              commentAddDepth({
                comment: parentComment as EntityCommentView,
              }),
            )

            // add reply comment to comment depth
            dispatch(
              commentAddDepth({
                comment: comment as EntityCommentView,
              }),
            )

            // navigate to post page
            navigate(`${Path.Post}/${comment?.postId}`)
          }}
        />
      </>
    )
  }

  // return comment controls
  return (
    <Control
      appearance="neutral"
      label="navigate to comment page"
      icon={icons.Scope}
      text="View comment"
      disabled={isDisabled}
      onClick={() => {
        // add comment to comment depth
        dispatch(
          commentAddDepth({
            comment: comment as EntityCommentView,
          }),
        )

        // navigate to post page
        navigate(`${Path.Post}/${comment?.postId}`)
      }}
    />
  )
}

CommentControls.propTypes = {}

import { apiGetService } from '@/lib'

export const useFile = (fileId: string, userId?: string): string => {
  const { basePath } = apiGetService()

  // if user id provided
  if (userId) {
    return `${basePath}/file/public/file/url?fileId=${fileId}&userId=${userId}`
  }

  return `${basePath}/file/public/file/url?fileId=${fileId}`
}

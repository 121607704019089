import { css, Theme } from '@emotion/react'
import { mq, kf } from '@/styles'

export const styles = (theme: Theme) => ({
  skeleton: {
    main: css`
      display: flex;
      align-items: center;

      width: 212px;

      border: 1px solid ${theme.colors.primary.darker};
      border-radius: 50px;

      padding: 6px 20px 6px 6px;
      overflow: hidden;

      background-color: ${theme.colors.primary.normal};
      background-image: linear-gradient(
        90deg,
        #ffffff00,
        ${theme.colors.secondary.darker},
        #ffffff00
      );
      background-size: 60px 100%;
      background-repeat: no-repeat;
      background-position: left -60px top 0;
      animation: 1.4s ease infinite ${kf.load.shine(-60, 0)};

      ${mq.mobile(css`
        width: 100%;
      `)}
    `,

    avatar: css`
      flex-shrink: 0;
      display: flex;

      width: 70px;
      height: 70px;

      opacity: 0.4;
      border-radius: 50%;
      margin-right: 8px;

      background-color: ${theme.colors.primary.darker};
    `,

    description: {
      main: css`
        width: 100%;
        height: 70;
      `,

      name: css`
        width: 100%;
        height: 28px;
        opacity: 0.4;

        border-radius: 4px;
        background-color: ${theme.colors.primary.darker};

        margin-bottom: 8px;
      `,

      username: css`
        width: 100%;
        height: 24px;
        opacity: 0.4;

        border-radius: 4px;
        background-color: ${theme.colors.primary.darker};
      `,
    },
  },
})

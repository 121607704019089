import { FC, useEffect } from 'react'
import { EntityConnectStatus } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  connectSelectConnectStatus,
  connectSelectConnectBalance,
  connectSelectConnectOnboardingLink,
  connectSelectConnectCreatePayoutLoading,
  connectSelectConnectGetConnectOnboardingLinkLoading,
  connectGetConnectStatus,
  connectGetConnectBalance,
  connectGetConnectOnboardingLink,
  connectCreatePayout,
} from '@/features'
// components
import { ButtonMedium, Loader } from '@/components'

// components
import { Balance, Status, Subtitle, Title } from './components'
// styles
import { styles } from './styles'

type ConnectViewProfileProps = {}

export const ConnectViewProfile: FC<ConnectViewProfileProps> = () => {
  const dispatch = useAppDispatch()

  const connectStatus = useAppSelector(connectSelectConnectStatus)
  const { available: availableBalance, pending: pendingBalance } = useAppSelector(
    connectSelectConnectBalance,
  )
  const connectOnboardingLink = useAppSelector(connectSelectConnectOnboardingLink)
  const connectGetConnectOnboardingLinkLoading = useAppSelector(
    connectSelectConnectGetConnectOnboardingLinkLoading,
  )
  const connectCreatePayoutLoading = useAppSelector(
    connectSelectConnectCreatePayoutLoading,
  )

  const minPayoutAvailableBalance = 5000
  const hasEnoughBalance = availableBalance >= minPayoutAvailableBalance
  const isConnectReady =
    connectStatus === EntityConnectStatus.Complete ||
    connectStatus === EntityConnectStatus.Enabled
  const canPayout = hasEnoughBalance && isConnectReady
  const updateConnectDisabled = connectGetConnectOnboardingLinkLoading
  const createPayoutDisabled = !canPayout || connectCreatePayoutLoading

  useEffect(() => {
    // get connect status
    dispatch(connectGetConnectStatus())

    // get connect balance
    dispatch(connectGetConnectBalance())

    // get connect onboarding link
    dispatch(connectGetConnectOnboardingLink())
  }, [dispatch])

  const getBusinessAccountMessage = (status: EntityConnectStatus | undefined): string => {
    switch (status) {
      case EntityConnectStatus.Pending: {
        return 'Set up your business account to unlock full potential.'
      }
      case EntityConnectStatus.Restricted: {
        return 'We need a bit more info to complete your business account setup.'
      }
      case EntityConnectStatus.Enabled: {
        return 'Your business account is almost ready, but we need some extra details from you.'
      }
      case EntityConnectStatus.Complete: {
        return 'Your business account setup is complete.'
      }
      default: {
        return ''
      }
    }
  }

  const getUpdateButtonText = (status: EntityConnectStatus | undefined): string => {
    switch (status) {
      case EntityConnectStatus.Complete: {
        return 'Update'
      }
      default: {
        return 'Set up'
      }
    }
  }

  const getPayoutButtonText = (payoutAvailable: boolean): string =>
    payoutAvailable ? 'Create payout' : 'Create payout (min $50)'

  return (
    <div css={styles().card.main}>
      <div css={styles().card.content.main}>
        <div css={styles().card.content.account.main}>
          <div css={styles().card.content.account.status.main}>
            <div css={styles().card.content.account.status.title}>
              <Title text="Stripe business account" />
            </div>
            <div css={styles().card.content.account.status.name}>
              <Status status={connectStatus} />
            </div>
          </div>
          <div css={styles().card.content.account.status.subtitle}>
            <Subtitle text={getBusinessAccountMessage(connectStatus)} />
          </div>
        </div>
        <div css={styles().card.content.balance.main}>
          <div css={styles().card.content.balance.title}>
            <Title text="Balance overview" />
          </div>
          <div css={styles().card.content.balance.pending}>
            <Balance amount={pendingBalance} subtitle="Pending Balance" />
          </div>
          <div css={styles().card.content.balance.available}>
            <Balance amount={availableBalance} subtitle="Available Balance" />
          </div>
        </div>
        <div css={styles().card.content.buttons.main}>
          <div css={styles().card.content.buttons.update}>
            <ButtonMedium
              type="button"
              aria-label="update connect account"
              disabled={updateConnectDisabled}
              appearance="secondary"
              onClick={() => {
                window.location.assign(connectOnboardingLink)
              }}
            >
              {connectGetConnectOnboardingLinkLoading ? (
                <Loader width={56} height={20} appearance="secondary" />
              ) : (
                getUpdateButtonText(connectStatus)
              )}
            </ButtonMedium>
          </div>

          <div css={styles().card.content.buttons.payout}>
            <ButtonMedium
              type="button"
              aria-label="create payout"
              disabled={createPayoutDisabled}
              appearance="primary"
              onClick={() => {
                dispatch(connectCreatePayout())
              }}
            >
              {connectCreatePayoutLoading ? (
                <Loader width={56} height={20} appearance="secondary" />
              ) : (
                getPayoutButtonText(canPayout)
              )}
            </ButtonMedium>
          </div>
        </div>
      </div>
    </div>
  )
}

ConnectViewProfile.propTypes = {}

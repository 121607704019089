import { FC, useEffect } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // user
  userSelectRecommendedArtists,
  userSelectRecommendedArtistsLength,
  userSelectUserGetRecommendedArtistsLoading,
  userGetRecommendedArtists,
  // like
  likeCreateUserFollow,
  likeDeleteUserFollow,
  likeSelectUserFollows,
} from '@/features'

// components
import { Artist, ArtistSkeleton } from './components'
// styles
import { styles } from './styles'

type ArtistListOnboardingRecommendedProps = {}

export const ArtistListOnboardingRecommended: FC<
  ArtistListOnboardingRecommendedProps
> = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const userFollows = useAppSelector(likeSelectUserFollows)
  const recommendedArtists = useAppSelector(userSelectRecommendedArtists)
  const recommendedArtistsLength = useAppSelector(userSelectRecommendedArtistsLength)
  const userGetRecommendedArtistsLoading = useAppSelector(
    userSelectUserGetRecommendedArtistsLoading,
  )

  const { id: profileUserId } = profile ?? {}

  const defaultContentLength = 20
  // [0, 1,..., 19]
  const skeletons = Array.from({ length: defaultContentLength }, (_, i) => i)

  useEffect(() => {
    // if recommended artists is not fetched
    if (recommendedArtistsLength === 0) {
      // fetch artists
      dispatch(userGetRecommendedArtists({ limit: 29, offset: 0 }))
    }
  }, [dispatch, recommendedArtistsLength])

  return (
    <div css={styles().artists.main}>
      {userGetRecommendedArtistsLoading
        ? skeletons.map((n) => (
            <ArtistSkeleton key={`onboarding-rec-artist-skeleton-${n}`} />
          ))
        : recommendedArtists.map((artist) => {
            const { id: artistUserId } = artist

            const isCurrentUser = profileUserId === artistUserId
            const isUserFollowed = userFollows[artistUserId || '']

            const followArtist = () => dispatch(likeCreateUserFollow({ user: artist }))
            const unfollowArtist = () => dispatch(likeDeleteUserFollow({ user: artist }))
            const followUnfollowAction = isUserFollowed ? unfollowArtist : followArtist

            // if is current user
            if (isCurrentUser) {
              return (
                <Artist
                  key={`onboarding-rec-artist-${artistUserId}`}
                  artist={artist}
                  followed={isUserFollowed}
                />
              )
            }

            return (
              <Artist
                key={`onboarding-rec-artist-${artistUserId}`}
                artist={artist}
                followed={isUserFollowed}
                onClick={followUnfollowAction}
              />
            )
          })}
    </div>
  )
}

ArtistListOnboardingRecommended.propTypes = {}

import { css } from '@emotion/react'
import { z } from '@/styles'

export const styles = () => ({
  modal: {
    main: css`
      z-index: ${z.alert.modal};

      position: fixed;
      top: 0;
      left: 0;

      width: 100%;
    `,
    content: {
      main: css``,
      message: css``,
    },
  },
})

import useSWR from 'swr'

// libs
import { apiGetService, apiHandleError } from '@/lib'

// types
import { SWRKeys, SWRFetcherData } from './types'

const getSubscription = async ({
  data: { subscriptionId },
}: SWRFetcherData<{ subscriptionId: string }>) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve subscription
    const { subscription } = await api.subscriptionApi.getSubscription(subscriptionId)

    // return payload
    return { subscription, error: '' }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return payload
    return { subscription: undefined, error: message }
  }
}

const fetcher = (args: SWRFetcherData<{ subscriptionId: string }>) =>
  getSubscription(args)

export const useSubscription = (subscriptionId: string) => {
  const shouldFetch = !!subscriptionId

  const { data, isLoading, mutate } = useSWR(
    shouldFetch ? { key: SWRKeys.UseSubscription, data: { subscriptionId } } : null,
    fetcher,
    { revalidateOnFocus: false },
  )

  return {
    subscription: data?.subscription,
    isLoading,
    isError: data?.error,
    mutate,
  }
}

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type CategoryProps = {
  name: string
  label: string
  selected?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const Category: FC<CategoryProps> = ({
  name,
  label,
  selected = false,
  onClick,
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).category.main}>
      <button
        css={[
          styles(theme).category.button.shared,
          selected
            ? styles(theme).category.button.selected
            : styles(theme).category.button.unselected,
        ]}
        onClick={(e) => onClick(e)}
        type="button"
        aria-label={label}
      >
        {name}
      </button>
    </div>
  )
}

Category.propTypes = {}

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type AvatarProps = {
  source: string
  onEditClick: MouseEventHandler<HTMLButtonElement>
}

export const Avatar: FC<AvatarProps> = ({ source, onEditClick }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).avatar.main}>
      <div css={styles(theme).avatar.image.main}>
        {source ? (
          <img
            css={styles(theme).avatar.image.content}
            loading="lazy"
            width="120px"
            height="120px"
            src={source}
            alt="user avatar"
          />
        ) : (
          <icons.User css={styles(theme).avatar.image.icon} />
        )}
      </div>
      <div css={styles(theme).avatar.edit.main}>
        <button
          css={styles(theme).avatar.edit.button}
          onClick={(e) => onEditClick(e)}
          type="button"
          aria-label="change user avatar"
        >
          <icons.Edit css={styles(theme).avatar.edit.icon} />
        </button>
      </div>
    </div>
  )
}

Avatar.propTypes = {}

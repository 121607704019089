import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 60px;

      cursor: pointer;
    `,

    data: {
      main: css`
        display: flex;
        align-items: center;

        height: 60px;
      `,
      source: {
        main: css`
          display: flex;
          flex: 0 0 60px;

          margin-right: 20px;
        `,

        avatars: {
          shared: css`
            width: 60px;
            height: 60px;
          `,
          single: css``,
          double: css`
            position: relative;
            display: flex;

            align-items: center;

            & > div {
              position: absolute;

              width: 40px;
              height: 40px;
            }

            & > div:nth-of-type(1) {
              z-index: 2;
              left: 0px;
            }

            & > div:nth-of-type(2) {
              z-index: 1;
              right: 0px;
            }
          `,
          triple: css`
            position: relative;
            display: flex;

            align-items: center;

            & > div {
              position: absolute;

              width: 30px;
              height: 30px;
            }

            & > div:nth-of-type(1) {
              z-index: 3;
              left: 0px;
            }

            & > div:nth-of-type(2) {
              z-index: 2;
              left: 15px;
            }

            & > div:nth-of-type(3) {
              z-index: 1;
              right: 0px;
            }
          `,
        },

        icon: css`
          display: flex;
          align-items: center;
          justify-content: center;

          width: 60px;
          height: 60px;

          & > svg {
            width: 32px;
            height: 32px;

            & > path {
              fill: ${theme.colors.secondary.normal};
            }
          }
        `,
      },
      info: {
        main: css`
          display: flex;
          flex-direction: column;

          width: 100%;
          min-width: 0;
        `,
        sources: css`
          ${helpers.textOverflowEllipsis};
        `,
        titles: css`
          ${helpers.textOverflowEllipsis};
        `,
      },
    },
  },
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityCustomerPaymentMethod } from '@ryddm-inc/ryddm-apiclient'

// utils
import { mergeArraysAtIndex } from '@/utils'
// stores
import { RootState } from '@/stores'
// features
import {
  customerGetCustomerPaymentMethods,
  customerCreateCustomerPaymentMethodSetup,
  customerCreateCustomerPaymentMethod,
  customerDeleteCustomerPaymentMethod,
  customerSetCustomerPaymentMethodDefault,
} from '@/features'

export interface CustomerState {
  customerPaymentMethods: EntityCustomerPaymentMethod[]
  customerPaymentMethodsCount: number
  customerPaymentMethodsFetched: boolean

  customerPaymentMethodSelected: EntityCustomerPaymentMethod | undefined

  customerPaymentMethodCreateModal: {
    clientSecret: string
    open: boolean
  }

  customerGetCustomerPaymentMethodsLoading: boolean
  customerCreateCustomerPaymentMethodSetupLoading: boolean
  customerCreateCustomerPaymentMethodLoading: boolean
  customerDeleteCustomerPaymentMethodLoading: boolean
  customerSetCustomerPaymentMethodDefaultLoading: boolean
}

const initialState: CustomerState = {
  customerPaymentMethods: [],
  customerPaymentMethodsCount: 0,
  customerPaymentMethodsFetched: false,

  customerPaymentMethodSelected: undefined,

  customerPaymentMethodCreateModal: {
    clientSecret: '',
    open: false,
  },

  customerGetCustomerPaymentMethodsLoading: false,
  customerCreateCustomerPaymentMethodSetupLoading: false,
  customerCreateCustomerPaymentMethodLoading: false,
  customerDeleteCustomerPaymentMethodLoading: false,
  customerSetCustomerPaymentMethodDefaultLoading: false,
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // reset state
    customerResetState: () => ({
      customerPaymentMethods: [],
      customerPaymentMethodsCount: 0,
      customerPaymentMethodsFetched: false,

      customerPaymentMethodSelected: undefined,

      customerPaymentMethodCreateModal: {
        clientSecret: '',
        open: false,
      },

      customerGetCustomerPaymentMethodsLoading: false,
      customerCreateCustomerPaymentMethodSetupLoading: false,
      customerCreateCustomerPaymentMethodLoading: false,
      customerDeleteCustomerPaymentMethodLoading: false,
      customerSetCustomerPaymentMethodDefaultLoading: false,
    }),

    // select customer payment method
    customerSelectCustomerPaymentMethod: (
      state,
      action: PayloadAction<{ customerPaymentMethod: EntityCustomerPaymentMethod }>,
    ) => {
      const { customerPaymentMethod } = action.payload

      // mutate selected customer payment method
      // if provided payment method already selected - unselect it
      // else select provided payment method
      state.customerPaymentMethodSelected =
        state.customerPaymentMethodSelected?.id === customerPaymentMethod?.id
          ? undefined
          : customerPaymentMethod
    },

    // set customer payment methods
    customerSetCustomerPaymentMethods: (
      state,
      action: PayloadAction<{
        customerPaymentMethods: EntityCustomerPaymentMethod[]
        count: number
        offset: number
      }>,
    ) => {
      const { customerPaymentMethods, count, offset } = action.payload

      // mutate count
      state.customerPaymentMethodsCount = count
      // mutate payment methods array
      state.customerPaymentMethods = mergeArraysAtIndex(
        state.customerPaymentMethods,
        customerPaymentMethods,
        offset,
      )
    },

    // open customer payment method create modal
    customerOpenCustomerPaymentMethodCreateModal: (
      state,
      action: PayloadAction<{
        clientSecret: string
      }>,
    ) => ({
      ...state,
      customerPaymentMethodCreateModal: {
        clientSecret: action.payload.clientSecret,
        open: true,
      },
    }),

    // close customer payment method create modal
    customerCloseCustomerPaymentMethodCreateModal: (state) => ({
      ...state,
      customerPaymentMethodCreateModal: {
        clientSecret: '',
        open: false,
      },
    }),
  },
  extraReducers: (builder) => {
    // get customer payment methods
    builder
      .addCase(customerGetCustomerPaymentMethods.pending, (state) => ({
        ...state,
        customerGetCustomerPaymentMethodsLoading: true,
      }))
      .addCase(customerGetCustomerPaymentMethods.fulfilled, (state) => ({
        ...state,
        customerPaymentMethodsFetched: true,
        customerGetCustomerPaymentMethodsLoading: false,
      }))

    // create customer payment method setup
    builder
      .addCase(customerCreateCustomerPaymentMethodSetup.pending, (state) => ({
        ...state,
        customerCreateCustomerPaymentMethodSetupLoading: true,
      }))
      .addCase(customerCreateCustomerPaymentMethodSetup.fulfilled, (state) => ({
        ...state,
        customerCreateCustomerPaymentMethodSetupLoading: false,
      }))

    // create customer payment method
    builder
      .addCase(customerCreateCustomerPaymentMethod.pending, (state) => ({
        ...state,
        customerCreateCustomerPaymentMethodLoading: true,
      }))
      .addCase(customerCreateCustomerPaymentMethod.fulfilled, (state) => ({
        ...state,
        customerCreateCustomerPaymentMethodLoading: false,
      }))

    // delete customer payment method
    builder
      .addCase(customerDeleteCustomerPaymentMethod.pending, (state) => ({
        ...state,
        customerDeleteCustomerPaymentMethodLoading: true,
      }))
      .addCase(customerDeleteCustomerPaymentMethod.fulfilled, (state) => ({
        ...state,
        customerDeleteCustomerPaymentMethodLoading: false,
      }))

    // set customer payment method default
    builder
      .addCase(customerSetCustomerPaymentMethodDefault.pending, (state) => ({
        ...state,
        customerSetCustomerPaymentMethodDefaultLoading: true,
      }))
      .addCase(
        customerSetCustomerPaymentMethodDefault.fulfilled,
        (state, { payload }) => ({
          ...state,
          customerSetCustomerPaymentMethodDefaultLoading: false,
          error: payload.error,
        }),
      )
  },
})

export const {
  customerResetState,
  customerSelectCustomerPaymentMethod,
  customerSetCustomerPaymentMethods,
  customerOpenCustomerPaymentMethodCreateModal,
  customerCloseCustomerPaymentMethodCreateModal,
} = customerSlice.actions

export const customerSelectCustomerPaymentMethods = (state: RootState) =>
  state.customer.customerPaymentMethods
export const customerSelectCustomerPaymentMethodsLength = (state: RootState) =>
  state.customer.customerPaymentMethods.length
export const customerSelectCustomerPaymentMethodsCount = (state: RootState) =>
  state.customer.customerPaymentMethodsCount
export const customerSelectCustomerPaymentMethodsFetched = (state: RootState) =>
  state.customer.customerPaymentMethodsFetched

export const customerSelectCustomerPaymentMethodSelected = (state: RootState) =>
  state.customer.customerPaymentMethodSelected

export const customerSelectCustomerPaymentMethodCreateModalOpen = (state: RootState) =>
  state.customer.customerPaymentMethodCreateModal.open
export const customerSelectCustomerPaymentMethodCreateModalClientSecret = (
  state: RootState,
) => state.customer.customerPaymentMethodCreateModal.clientSecret

export const customerSelectCustomerGetCustomerPaymentMethodsLoading = (
  state: RootState,
) => state.customer.customerGetCustomerPaymentMethodsLoading
export const customerSelectCustomerCreateCustomerPaymentMethodSetupLoading = (
  state: RootState,
) => state.customer.customerCreateCustomerPaymentMethodSetupLoading
export const customerSelectCustomerCreateCustomerPaymentMethodLoading = (
  state: RootState,
) => state.customer.customerCreateCustomerPaymentMethodLoading
export const customerSelectCustomerDeleteCustomerPaymentMethodLoading = (
  state: RootState,
) => state.customer.customerDeleteCustomerPaymentMethodLoading
export const customerSelectCustomerSetCustomerPaymentMethodDefaultLoading = (
  state: RootState,
) => state.customer.customerSetCustomerPaymentMethodDefaultLoading

export const customerReducer = customerSlice.reducer

// export default customerSlice.reducer

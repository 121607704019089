// config
import { StorageKeys } from '@/config'

export const storageGetAuthToken = (): string =>
  localStorage.getItem(StorageKeys.AuthToken) || ''

export const storageSetAuthToken = (authToken: string) =>
  localStorage.setItem(StorageKeys.AuthToken, authToken)

export const storageGetSentPasswordResetEmailDate = (): string =>
  localStorage.getItem(StorageKeys.SentPasswordResetEmailDate) || ''

export const storageSetSentPasswordResetEmailDate = (date: string) =>
  localStorage.setItem(StorageKeys.SentPasswordResetEmailDate, date)

export const storageGetSentVerificationEmailDate = (): string =>
  localStorage.getItem(StorageKeys.SentVerificationEmailDate) || ''

export const storageSetSentVerificationEmailDate = (date: string) =>
  localStorage.setItem(StorageKeys.SentVerificationEmailDate, date)

import { FC } from 'react'

// components
import { Form } from './components'
// styles
import { styles } from './styles'

type UserViewProfileProps = {}

export const UserViewProfile: FC<UserViewProfileProps> = () => (
  <div css={styles().view.main}>
    <div css={styles().view.content.main}>
      <div css={styles().view.content.form}>
        <Form />
      </div>
    </div>
  </div>
)

UserViewProfile.propTypes = {}

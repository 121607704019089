import { FC } from 'react'

// components
import { Form } from './components'
// styles
import { styles } from './styles'

type TrackViewCreateStudioProps = {}

export const TrackViewCreateStudio: FC<TrackViewCreateStudioProps> = () => (
  <div css={styles().card.main}>
    <div css={styles().card.content.main}>
      <div css={styles().card.content.form}>
        <Form />
      </div>
    </div>
  </div>
)

TrackViewCreateStudio.propTypes = {}

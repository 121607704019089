import {
  EntityNotificationEvent,
  EntitySubscriptionView,
  EntityTipView,
  EntityUnlockView,
  EntityConnectPayoutView,
} from '@ryddm-inc/ryddm-apiclient'

// utils
import { numbersFormatter } from '@/utils'

type TitleData = {
  tip?: EntityTipView
  isTipLoading?: boolean
  unlock?: EntityUnlockView
  isUnlockLoading?: boolean
  subscription?: EntitySubscriptionView
  isSubscriptionLoading?: boolean
  connectPayout?: EntityConnectPayoutView
  isConnectPayoutLoading?: boolean
}

export const getNotificationTitle = (
  event: EntityNotificationEvent,
  {
    tip,
    isTipLoading,
    unlock,
    isUnlockLoading,
    subscription,
    isSubscriptionLoading,
    connectPayout,
    isConnectPayoutLoading,
  }: TitleData,
): string => {
  // USER

  if (event === EntityNotificationEvent.AppUserFollowCreated) {
    return 'started following you'
  }

  // TRACK

  if (event === EntityNotificationEvent.AppTrackCreated) {
    return 'released new track'
  }

  if (event === EntityNotificationEvent.AppTrackBlocked) {
    return 'Your track is blocked'
  }

  if (event === EntityNotificationEvent.AppTrackLikeCreated) {
    return 'liked your track'
  }

  // POST

  if (event === EntityNotificationEvent.AppPostCreated) {
    return 'shared new post'
  }

  if (event === EntityNotificationEvent.AppPostBlocked) {
    return 'Your post is blocked'
  }

  if (event === EntityNotificationEvent.AppPostLikeCreated) {
    return 'liked your post'
  }

  // COMMENT

  if (event === EntityNotificationEvent.AppCommentCreated) {
    return 'commented on your post'
  }

  if (event === EntityNotificationEvent.AppCommentReplyCreated) {
    return 'replied to your comment'
  }

  if (event === EntityNotificationEvent.AppCommentBlocked) {
    return 'Your comment is blocked'
  }

  if (event === EntityNotificationEvent.AppCommentLikeCreated) {
    return 'liked your comment'
  }

  // TIP

  if (event === EntityNotificationEvent.AppTipPaymentSucceeded) {
    return `Your $${
      isTipLoading ? '...' : (tip?.amount || 0) / 100
    } tip payment has succeeded`
  }

  if (event === EntityNotificationEvent.AppTipPaymentFailed) {
    return `Your $${
      isTipLoading ? '...' : (tip?.amount || 0) / 100
    } tip payment has failed`
  }

  if (event === EntityNotificationEvent.AppTipCreated) {
    return ` has sent you $${isTipLoading ? '...' : (tip?.amount || 0) / 100} tip`
  }

  // TRACK UNLOCK

  if (event === EntityNotificationEvent.AppTrackUnlockPaymentSucceeded) {
    return `Your $${
      isUnlockLoading ? '...' : (unlock?.unlockPrice || 0) / 100
    } track unlock payment has succeeded`
  }

  if (event === EntityNotificationEvent.AppTrackUnlockPaymentFailed) {
    return `Your $${
      isUnlockLoading ? '...' : (unlock?.unlockPrice || 0) / 100
    }  track unlock payment has failed`
  }

  if (event === EntityNotificationEvent.AppTrackUnlockCreated) {
    return ` has unlocked your track for $${
      isUnlockLoading ? '...' : (unlock?.unlockPrice || 0) / 100
    } `
  }

  // POST UNLOCK

  if (event === EntityNotificationEvent.AppPostUnlockPaymentSucceeded) {
    return `Your $${
      isUnlockLoading ? '...' : (unlock?.unlockPrice || 0) / 100
    }  post unlock payment has succeeded`
  }

  if (event === EntityNotificationEvent.AppPostUnlockPaymentFailed) {
    return `Your $${
      isUnlockLoading ? '...' : (unlock?.unlockPrice || 0) / 100
    }  post unlock payment has failed`
  }

  if (event === EntityNotificationEvent.AppPostUnlockCreated) {
    return ` has unlocked your post for $${
      isUnlockLoading ? '...' : (unlock?.unlockPrice || 0) / 100
    } `
  }

  // SUBSCRIPTION

  if (event === EntityNotificationEvent.AppSubscriptionPaymentSucceeded) {
    return `Your $${
      isSubscriptionLoading ? '...' : (subscription?.subscriptionPrice || 0) / 100
    } subscription payment has succeeded`
  }

  if (event === EntityNotificationEvent.AppSubscriptionPaymentFailed) {
    return `Your $${
      isSubscriptionLoading ? '...' : (subscription?.subscriptionPrice || 0) / 100
    } subscription payment has failed`
  }

  if (event === EntityNotificationEvent.AppSubscriptionCreated) {
    return ` has subscribed to you for $${
      isSubscriptionLoading ? '...' : (subscription?.subscriptionPrice || 0) / 100
    }`
  }

  // PAYOUT

  if (event === EntityNotificationEvent.AppPayoutPaid) {
    return `Your $${
      isConnectPayoutLoading ? '...' : (connectPayout?.amount || 0) / 100
    } payout has succeeded`
  }

  if (event === EntityNotificationEvent.AppPayoutFailed) {
    return `Your $${
      isConnectPayoutLoading ? '...' : (connectPayout?.amount || 0) / 100
    } payout has succeeded`
  }

  return ''
}

export const getNotificationOthers = (
  sourceUserIds: string[],
  sourceUserIdsCount: number,
): string => {
  if (sourceUserIds.length >= 3 && sourceUserIdsCount > 3) {
    return `and ${numbersFormatter(sourceUserIdsCount - sourceUserIds.length)} other(s)`
  }

  return ''
}

import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// utils
import { audioDurationFormatter } from '@/utils'
// hooks
import { useFile } from '@/hooks'
// icon
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type CurrentTrackProps = {
  track: EntityTrackView
  trackAuthorName: string
  duration: number
  progress: number
  onAuthorNameClick: MouseEventHandler<HTMLButtonElement>
}

export const CurrentTrack: FC<CurrentTrackProps> = ({
  track,
  trackAuthorName,
  progress,
  duration,
  onAuthorNameClick,
}) => {
  const theme = useTheme()
  const [coverLoadError, setCoverLoadError] = useState(false)

  // track
  const { coverId, name: trackName } = track ?? {}
  const trackHasCover = !!coverId
  const coverSrc = useFile(coverId || '')

  const elapsedTime = audioDurationFormatter(progress)
  const durationTime = audioDurationFormatter(duration)

  return (
    <div css={styles(theme).track.main}>
      {/* track cover */}
      <div css={styles(theme).track.cover.main}>
        {trackHasCover && !coverLoadError ? (
          <img
            css={styles(theme).track.cover.image}
            onError={() => setCoverLoadError(true)}
            loading="lazy"
            width="56px"
            height="56px"
            src={coverSrc}
            alt="track album cover"
          />
        ) : (
          <div css={styles(theme).track.cover.placeholder}>
            <icons.MusicNote />
          </div>
        )}
      </div>

      {/* track description */}
      <div css={styles(theme).track.description.main}>
        <div css={styles(theme).track.description.name}>{trackName}</div>
        <button
          css={styles(theme).track.description.author}
          onClick={(e) => onAuthorNameClick(e)}
          type="button"
          aria-label="explore track author"
        >
          {trackAuthorName}
        </button>
        <div css={styles(theme).track.description.duration}>
          {elapsedTime} / {durationTime}
        </div>
      </div>
    </div>
  )
}

CurrentTrack.propTypes = {}

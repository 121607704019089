import { FC, InputHTMLAttributes } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
}

export const Checkbox: FC<CheckboxProps> = ({ name, label, ...attributes }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).checkbox.main}>
      <label css={styles(theme).checkbox.label.main} htmlFor={name}>
        <input
          css={styles(theme).checkbox.input}
          id={name}
          {...attributes}
          type="checkbox"
        />
        <span
          css={[
            styles(theme).checkbox.label.mark.shared,
            attributes.checked
              ? styles(theme).checkbox.label.mark.checked
              : styles(theme).checkbox.label.mark.unchecked,
          ]}
        />
        <span css={styles(theme).checkbox.label.text}>{label}</span>
      </label>
    </div>
  )
}

Checkbox.propTypes = {}

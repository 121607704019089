import { createAsyncThunk } from '@reduxjs/toolkit'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // subscription
  subscriptionSetUserSubscriptions,
  subscriptionSetUserSubscriptionsAtPeriodEnd,
  subscriptionAddUserSubscriptionAtPeriodEnd,
  subscriptionRemoveUserSubscriptionAtPeriodEnd,
  subscriptionCloseSubscriptionCreateModal,
  subscriptionCloseSubscriptionRenewModal,
  subscriptionCloseSubscriptionCancelModal,
  // payment
  PaymentOperationType,
  paymentOpenPaymentModal,
} from '@/features'

export const subscriptionGetSubscribedUserIds = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('subscription/subscriptionGetSubscribedUserIds', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve subscribed user ids
    const { subscribedUserIds } = await api.subscriptionApi.getSubscribedUserIds()

    // set user subscriptions
    dispatch(
      subscriptionSetUserSubscriptions({ subscribedUserIds: subscribedUserIds || [] }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const subscriptionGetSubscribedAtPeriodEndUserIds = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('subscription/subscriptionGetSubscribedAtPeriodEndUserIds', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve subscribed at period end user ids
    const { subscribedAtPeriodEndUserIds } =
      await api.subscriptionApi.getSubscribedAtPeriodEndUserIds()

    // set user subscriptions
    dispatch(
      subscriptionSetUserSubscriptionsAtPeriodEnd({
        subscribedAtPeriodEndUserIds: subscribedAtPeriodEndUserIds || [],
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const subscriptionCreateSubscription = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('subscription/subscriptionCreateSubscription', async (_, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get artist from modal state
  const modalArtist = getState().subscription.subscriptionCreateModal.artist
  const { id: artistId } = modalArtist ?? {}

  try {
    // create subscription
    const { clientSecret } = await api.subscriptionApi.createSubscription({
      userId: artistId || '',
    })

    // get current default customer payment method
    const { customerPaymentMethod } =
      await api.customerApi.getCustomerPaymentMethodDefault()

    // close subscription create modal
    dispatch(subscriptionCloseSubscriptionCreateModal())

    // open payment modal
    dispatch(
      paymentOpenPaymentModal({
        clientSecret: clientSecret || '',
        defaultCustomerPaymentMethod: customerPaymentMethod,
        operationType: PaymentOperationType.Subscription,
        operationSubject: modalArtist,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to create subscription: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const subscriptionRenewSubscription = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('subscription/subscriptionRenewSubscription', async (_, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get artist from modal state
  const modalArtist = getState().subscription.subscriptionRenewModal.artist
  const { id: artistId } = modalArtist ?? {}

  try {
    // renew subscription
    await api.subscriptionApi.renewSubscription({
      userId: artistId || '',
    })

    // remove user subscription at period end
    dispatch(subscriptionRemoveUserSubscriptionAtPeriodEnd({ userId: artistId || '' }))

    // close subscription renew modal
    dispatch(subscriptionCloseSubscriptionRenewModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully renewed subscription!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to renew subscription: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const subscriptionCancelSubscription = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('subscription/subscriptionCancelSubscription', async (_, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get artist from modal state
  const modalArtist = getState().subscription.subscriptionCancelModal.artist
  const { id: artistId } = modalArtist ?? {}

  try {
    // cancel subscription
    await api.subscriptionApi.cancelSubscription({
      userId: artistId || '',
    })

    // add user subscription at period end
    dispatch(subscriptionAddUserSubscriptionAtPeriodEnd({ userId: artistId || '' }))

    // close subscription cancel modal
    dispatch(subscriptionCloseSubscriptionCancelModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully canceled subscription!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to cancel subscription: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

import { css } from '@emotion/react'

// styles
import { constants, typography, helpers, mq } from '@/styles'

export const contentLayout = {
  home: {
    main: css``,
    header: {
      main: css`
        padding-right: ${constants.app.content.desktop.padding};
      `,
      title: {
        main: css``,
        text: css`
          font-size: ${typography.h4};
        `,
        icon: css``,
      },
    },
    navigation: {
      main: css``,
      button: css``,
      icon: css``,
    },
    content: {
      main: css`
        ${helpers.app.screen.width.content};
      `,
    },
    container: {
      main: css``,
    },
  },
  feed: {
    main: css``,
    header: {
      main: css`
        padding-right: ${constants.app.content.desktop.padding};
      `,
      title: {
        main: css``,
        text: css`
          font-size: ${typography.h6};
        `,
        icon: css``,
      },
    },
    navigation: {
      main: css``,
      button: css``,
      icon: css``,
    },
    content: {
      main: css`
        ${helpers.app.screen.width.content};
      `,
    },
    container: {
      main: css``,
    },
  },
  library: {
    main: css``,
    header: {
      main: css`
        padding-right: ${constants.app.content.desktop.padding};
      `,
      title: {
        main: css``,
        text: css`
          font-size: ${typography.h6};
        `,
        icon: css``,
      },
    },
    navigation: {
      main: css``,
      button: css``,
      icon: css``,
    },
    content: {
      main: css`
        ${helpers.app.screen.width.content};
      `,
    },
    container: {
      main: css``,
    },
  },
  charts: {
    main: css``,
    header: {
      main: css`
        padding-right: ${constants.app.content.desktop.padding};
      `,
      title: {
        main: css``,
        text: css`
          font-size: ${typography.h6};
        `,
        icon: css``,
      },
    },
    navigation: {
      main: css``,
      button: css``,
      icon: css``,
    },
    content: {
      main: css`
        ${helpers.app.screen.width.content};
      `,
    },
    container: {
      main: css``,
    },
  },
  studio: {
    main: css``,
    header: {
      main: css`
        padding-right: ${constants.app.content.desktop.padding};
      `,
      title: {
        main: css``,
        text: css`
          font-size: ${typography.h6};
        `,
        icon: css``,
      },
    },
    navigation: {
      main: css``,
      button: css``,
      icon: css``,
    },
    content: {
      main: css`
        ${helpers.app.screen.width.content};
      `,
    },
    container: {
      main: css``,
    },
  },
  user: {
    main: css``,
    header: {
      main: css`
        padding-right: ${constants.app.content.desktop.padding};
      `,
      title: {
        main: css``,
        text: css`
          font-size: ${typography.h6};
        `,
        icon: css``,
      },
    },
    navigation: {
      main: css``,
      button: css``,
      icon: css``,
    },
    content: {
      main: css`
        ${helpers.app.screen.width.content};
      `,
    },
    container: {
      main: css``,
    },
  },
  post: {
    current: {
      main: css`
        overflow: hidden;
        padding: 0px;
        margin: 0px;

        ${mq.mobile(css`
          padding: 0px;
          margin: 0px;
        `)}
      `,
      header: {
        main: css`
          padding-right: 0px;
          max-width: 800px;
        `,
        title: {
          main: css``,
          text: css`
            font-size: ${typography.h6};
          `,
          icon: css``,
        },
      },
      navigation: {
        main: css``,
        button: css``,
        icon: css``,
      },
      content: {
        main: css`
          ${helpers.app.screen.width.full};
        `,
      },
      container: {
        main: css`
          max-width: 800px;
        `,
      },
    },
    create: {
      main: css`
        overflow: hidden;
        padding: 0px;
        margin-bottom: 8px;

        ${mq.mobile(css`
          padding: 0px;
          margin-bottom: 0px;
        `)}
      `,
      header: {
        main: css`
          padding-right: 0px;
        `,
        title: {
          main: css``,
          text: css`
            font-size: ${typography.h6};
          `,
          icon: css``,
        },
      },
      navigation: {
        main: css``,
        button: css``,
        icon: css``,
      },
      content: {
        main: css`
          overflow: hidden;
          width: 400px;

          ${mq.tablet(css`
            // 42px represents desktop padding left and right
            width: calc(100vw - (${constants.app.content.desktop.padding}*2));
          `)}

          ${mq.mobile(css`
            // 22px represents mobile padding left and right
            width: calc(100vw - (${constants.app.content.mobile.padding}*2));
          `)}
        `,
      },
      container: {
        main: css``,
      },
    },
  },
  onboarding: {
    main: css`
      overflow: hidden;
      padding: 0px;

      ${mq.mobile(css`
        margin-bottom: 0px;
        padding: 0px;
      `)}
    `,
    header: {
      main: css`
        padding-right: 0px;
      `,
      title: {
        main: css``,
        text: css`
          font-size: ${typography.h6};
        `,
        icon: css``,
      },
    },
    navigation: {
      main: css``,
      button: css``,
      icon: css``,
    },
    content: {
      main: css`
        // 42px represents desktop padding left
        width: calc(100vw - ${constants.app.content.desktop.padding});
        overflow: hidden;

        ${mq.mobile(css`
          // 22px represents mobile padding left and right
          width: calc(100vw - (${constants.app.content.mobile.padding}*2));
        `)}
      `,
    },
    container: {
      main: css``,
    },
  },
}

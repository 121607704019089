// updating this values will also require to update regex
// in @/utils/validateTrackName
export const minTrackNameLength = 3
export const maxTrackNameLength = 100

export const minTrackUnlockPrice = 1
export const maxTrackUnlockPrice = 9

// 8 * 1024 * 1024 === 8 << 20 === 8 MiB
export const maxTrackCoverFileSize = 8 * 1024 * 1024
// 24 * 1024 * 1024 === 24 << 20 === 24 MiB
export const maxTrackFileSize = 24 * 1024 * 1024

import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

// components
import { AppLayout, SuspenseContentFallback } from '@/components'
// utils
import { importsLazy } from '@/utils'

const { HomePage } = importsLazy(() => import('@/pages'), 'HomePage')

const App = () => (
  <AppLayout>
    <Suspense fallback={<SuspenseContentFallback position="fit" />}>
      <Outlet />
    </Suspense>
  </AppLayout>
)

export const publicRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
]

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type ModalCardSubtitleProps = {
  text: string
  accent?: {
    accentStart: number
    accentEnd: number
    onClick: MouseEventHandler<HTMLButtonElement>
  }
}

export const ModalCardSubtitle: FC<ModalCardSubtitleProps> = ({
  text,
  accent = undefined,
}) => {
  const theme = useTheme()

  // if accent provided
  if (accent) {
    const { accentStart, accentEnd, onClick } = accent
    const isLongUsername = accentEnd - accentStart > 31
    return (
      <div css={styles(theme).title.main}>
        <div css={styles(theme).title.text}>
          {text.slice(0, accentStart)}
          <button
            css={styles(theme).title.button}
            onClick={(e) => onClick(e)}
            type="button"
            aria-label="navigate to artist profile"
          >
            <span
              css={[
                styles(theme).title.accent.main,
                isLongUsername && styles(theme).title.accent.long,
              ]}
            >
              {text.slice(accentStart, accentEnd)}
            </span>
          </button>
          {text.slice(accentEnd, text.length)}
        </div>
      </div>
    )
  }

  return (
    <div css={styles(theme).title.main}>
      <div css={styles(theme).title.text}>{text}</div>
    </div>
  )
}

ModalCardSubtitle.propTypes = {}

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  commentSelectCommentBlockModalOpen,
  commentCloseCommentBlockModal,
} from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { CommentBlockCard } from './components'
// styles
import { styles } from './styles'

type CommentBlockModalProps = {}

export const CommentBlockModal: FC<CommentBlockModalProps> = () => {
  const dispatch = useAppDispatch()
  const blockCommentModalOpen = useAppSelector(commentSelectCommentBlockModalOpen)

  return (
    <div css={styles().modal.main}>
      {blockCommentModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(commentCloseCommentBlockModal())}
        >
          <div css={styles().modal.content}>
            <CommentBlockCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

CommentBlockModal.propTypes = {}

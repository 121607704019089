import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  view: {
    main: css`
      max-width: 800px;
    `,
    content: {
      main: css`
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }

        ${mq.tablet(css`
          width: 100%;
        `)}
      `,

      identifier: css`
        margin-bottom: 16px;
      `,

      navigation: css`
        display: flex;
        flex-wrap: wrap;

        & > div {
          margin-bottom: 12px;
        }
        & > div:not(:last-of-type) {
          margin-right: 16px;
        }
      `,

      controls: css`
        flex-wrap: wrap;
        display: flex;

        & > div {
          margin-bottom: 12px;
        }
        & > div:not(:last-of-type) {
          margin-right: 16px;
        }
      `,
    },
  },
})

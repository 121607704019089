import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  tracks: {
    main: css`
      & > div:not(:last-of-type) {
        margin-bottom: 24px;
      }

      ${mq.mobile(css`
        & > div:not(:last-of-type) {
          margin-bottom: 12px;
        }
      `)};
    `,
    row: css`
      width: 280px;
    `,
  },
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  EntityReportComplaintType,
  EntityReportEntityType,
} from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // report
  reportCloseReportCommentModal,
  reportCloseReportPostModal,
  reportCloseReportTrackModal,
  reportCloseReportUserModal,
  reportCloseReportResolveModal,
  reportSetReports,
  reportSetReport,
  reportSetReportComplaints,
  reportSetReportComplaint,
  reportFilterReport,
} from '@/features'

/**
 * Perform type conversion of complaint type.
 *
 * Takes complaint type as a string and converts it to valid EntityReportComplaintType.
 *
 * @param {string} type - Complaint type.
 * @returns {EntityReportComplaintType} - Complaint type as valid EntityReportComplaintType.
 *
 * @example
 * // complaintType is EntityReportComplaintType.Copyright.
 * const complaintType = convertComplaintType('');
 * // complaintType is EntityReportComplaintType.Spam.
 * const complaintType = convertComplaintType('spam');
 */
const convertComplaintType = (type: string) => {
  // convert complaint type
  let complaintType: EntityReportComplaintType
  switch (type) {
    case EntityReportComplaintType.ChildAbuse.toString():
      complaintType = EntityReportComplaintType.ChildAbuse
      break
    case EntityReportComplaintType.Copyright.toString():
      complaintType = EntityReportComplaintType.Copyright
      break
    case EntityReportComplaintType.Fake.toString():
      complaintType = EntityReportComplaintType.Fake
      break
    case EntityReportComplaintType.Pornography.toString():
      complaintType = EntityReportComplaintType.Pornography
      break
    case EntityReportComplaintType.Spam.toString():
      complaintType = EntityReportComplaintType.Spam
      break
    case EntityReportComplaintType.Violence.toString():
      complaintType = EntityReportComplaintType.Violence
      break
    default:
      complaintType = EntityReportComplaintType.Copyright
  }

  return complaintType
}

export const reportCreateCommentReportComplaint = createAsyncThunk<
  { error: string },
  { complaintType: string; complaint?: string },
  { state: RootState; rejectValue: undefined }
>('report/reportCreateCommentReportComplaint', async (inp, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get comment from modal state
  const modalComment = getState().report.reportCommentModal.comment
  const { id: commentId, userId } = modalComment ?? {}

  // convert complaint type
  const complaintType = convertComplaintType(inp.complaintType)

  try {
    // create comment report complaint
    await api.reportApi.createReportComplaint({
      entityType: EntityReportEntityType.Comment,
      entityId: commentId || '',
      entityUserId: userId || '',
      complaintType,
      complaint: inp.complaint,
    })

    // close report comment modal
    dispatch(reportCloseReportCommentModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully created complaint!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to create complaint: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const reportCreatePostReportComplaint = createAsyncThunk<
  { error: string },
  { complaintType: string; complaint?: string },
  { state: RootState; rejectValue: undefined }
>('report/reportCreatePostReportComplaint', async (inp, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get post from modal state
  const modalPost = getState().report.reportPostModal.post
  const { id: postId, userId } = modalPost ?? {}

  // convert complaint type
  const complaintType = convertComplaintType(inp.complaintType)

  try {
    // create post report complaint
    await api.reportApi.createReportComplaint({
      entityType: EntityReportEntityType.Post,
      entityId: postId || '',
      entityUserId: userId || '',
      complaintType,
      complaint: inp.complaint,
    })

    // close report post modal
    dispatch(reportCloseReportPostModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully created complaint!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to create complaint: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const reportCreateTrackReportComplaint = createAsyncThunk<
  { error: string },
  { complaintType: string; complaint?: string },
  { state: RootState; rejectValue: undefined }
>('report/reportCreateTrackReportComplaint', async (inp, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get track from modal state
  const modalTrack = getState().report.reportTrackModal.track
  const { id: trackId, userId } = modalTrack ?? {}

  // convert complaint type
  const complaintType = convertComplaintType(inp.complaintType)

  try {
    // create track report complaint
    await api.reportApi.createReportComplaint({
      entityType: EntityReportEntityType.Track,
      entityId: trackId || '',
      entityUserId: userId || '',
      complaintType,
      complaint: inp.complaint,
    })

    // close report track modal
    dispatch(reportCloseReportTrackModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully created complaint!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to create complaint: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const reportCreateUserReportComplaint = createAsyncThunk<
  { error: string },
  { complaintType: string; complaint?: string },
  { state: RootState; rejectValue: undefined }
>('report/reportCreateUserReportComplaint', async (inp, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get user from modal state
  const modalUser = getState().report.reportUserModal.user
  const { id: userId } = modalUser ?? {}

  // convert complaint type
  const complaintType = convertComplaintType(inp.complaintType)

  try {
    // create user report complaint
    await api.reportApi.createReportComplaint({
      entityType: EntityReportEntityType.User,
      entityId: userId || '',
      entityUserId: userId || '',
      complaintType,
      complaint: inp.complaint,
    })

    // close report user modal
    dispatch(reportCloseReportUserModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully created complaint!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to create complaint: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const reportResolveReport = createAsyncThunk<
  { error: string },
  { reportId: string; cb?: () => void },
  { state: RootState; rejectValue: undefined }
>('report/reportResolveReport', async ({ reportId, cb }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // resolve report
    await api.reportApi.resolveReport({ reportId })

    // close report resolve modal
    dispatch(reportCloseReportResolveModal())

    // filter resolved report
    dispatch(reportFilterReport({ id: reportId }))

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully resolved report!',
        },
      }),
    )

    // if callback provided
    if (cb) {
      cb()
    }

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to resolve report: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const reportGetReports = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('report/reportGetReports', async (inp, { getState, dispatch }) => {
  // get api service
  const api = apiGetService()

  // get reports filter
  const filter = getState().report.reportsFilter

  // define entity type
  let entityType: 'comment' | 'post' | 'track' | 'user' | undefined

  // assert entity type
  switch (filter) {
    case EntityReportEntityType.Comment:
      entityType = 'comment'
      break
    case EntityReportEntityType.Post:
      entityType = 'post'
      break
    case EntityReportEntityType.Track:
      entityType = 'track'
      break
    case EntityReportEntityType.User:
      entityType = 'user'
      break
    default:
      entityType = undefined
  }

  try {
    // retrieve reports
    const { reports, count } = await api.reportApi.getReports(
      entityType,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set reports
    dispatch(
      reportSetReports({
        reports: reports || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const reportGetReport = createAsyncThunk<
  { error: string },
  { reportId: string },
  { state: RootState; rejectValue: undefined }
>('report/reportGetReport', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve report
    const { report } = await api.reportApi.getReport(inp.reportId)

    // set report
    dispatch(
      reportSetReport({
        report: report || {},
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const reportGetReportComplaints = createAsyncThunk<
  { error: string },
  { reportId: string; limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('report/reportGetReportComplaints', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve reports
    const { reportComplaints, count } = await api.reportApi.getReportComplaints(
      inp.reportId,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set reports
    dispatch(
      reportSetReportComplaints({
        reportComplaints: reportComplaints || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const reportGetReportComplaint = createAsyncThunk<
  { error: string },
  { reportComplaintId: string },
  { state: RootState; rejectValue: undefined }
>('report/reportGetReportComplaint', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve report
    const { reportComplaint } = await api.reportApi.getReportComplaint(
      inp.reportComplaintId,
    )

    // set report
    dispatch(
      reportSetReportComplaint({
        reportComplaint: reportComplaint || {},
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

import { createAsyncThunk } from '@reduxjs/toolkit'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // tip
  tipCloseTipModal,
  // payment
  PaymentOperationType,
  paymentOpenPaymentModal,
} from '@/features'

export const tipCreateTip = createAsyncThunk<
  { error: string },
  { amount: number; message?: string; artistId?: string },
  { state: RootState; rejectValue: undefined }
>('tip/tipCreateTip', async (inp, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get artist from modal state
  const modalArtist = getState().tip.tipModal.artist

  // if artist id is not provided
  if (!inp.artistId) {
    // set artist id as modal artist id
    inp.artistId = modalArtist?.id
  }

  try {
    // create tip
    const { clientSecret } = await api.tipApi.createTip({
      // create tip accepts amount in cents
      // if inp.amount = 1 (one dollar)
      // 1 * 100 = 100 (hundred cents)
      amount: inp.amount * 100,
      artistId: inp.artistId || '',
      message: inp.message,
    })

    // get current default customer payment method
    const { customerPaymentMethod } =
      await api.customerApi.getCustomerPaymentMethodDefault()

    // close tip modal
    dispatch(tipCloseTipModal())

    // open payment modal
    dispatch(
      paymentOpenPaymentModal({
        clientSecret: clientSecret || '',
        defaultCustomerPaymentMethod: customerPaymentMethod,
        operationType: PaymentOperationType.Tip,
        operationSubject: modalArtist,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to create tip: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // user
  ArtistCard,
  userSelectRecommendedArtists,
  userSelectRecommendedArtistsCount,
  userGetRecommendedArtists,
  userUtilGetArtistCardSkeletonSections,
  // like
  likeCreateUserFollow,
  likeDeleteUserFollow,
  likeSelectUserFollows,
  // subscription
  subscriptionSelectUserSubscriptions,
  subscriptionSelectUserSubscriptionsAtPeriodEnd,
  subscriptionOpenSubscriptionCreateModal,
  subscriptionOpenSubscriptionRenewModal,
  subscriptionOpenSubscriptionCancelModal,
} from '@/features'
// components
import { ContentLayoutVirtualized, SkeletonVertical } from '@/components'
// styles
import { contentLayout } from '@/styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const userFollows = useAppSelector(likeSelectUserFollows)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const userSubscriptionsAtPeriodEnd = useAppSelector(
    subscriptionSelectUserSubscriptionsAtPeriodEnd,
  )
  const artists = useAppSelector(userSelectRecommendedArtists)
  const artist = artists[index]

  // if artist not fetched
  if (!artist) {
    return (
      <div style={style}>
        <SkeletonVertical
          containerWidth="140px"
          sections={userUtilGetArtistCardSkeletonSections('artist-list-feed-recommended')}
        />
      </div>
    )
  }

  const { id: profileUserId } = profile ?? {}
  const { id: artistUserId } = artist

  const isCurrentUser = profileUserId === artistUserId
  const navigateToArtistPage = () => navigate(`${Path.User}/${artistUserId}`)

  // if is current user
  if (isCurrentUser) {
    return (
      <div style={style}>
        <ArtistCard
          artist={artist}
          onNameClick={navigateToArtistPage}
          onUsernameClick={navigateToArtistPage}
        />
      </div>
    )
  }

  const isUserFollowed = userFollows[artistUserId || '']
  const isCurrentUserSubscribedToUser = userSubscriptions[artistUserId || '']
  const isCurrentUserSubscribedToUserAtPeriodEnd =
    userSubscriptionsAtPeriodEnd[artistUserId || '']

  const onFollowClick = () => dispatch(likeCreateUserFollow({ user: artist }))
  const onUnfollowClick = () => dispatch(likeDeleteUserFollow({ user: artist }))
  const onCreateSubscriptionClick = () =>
    dispatch(subscriptionOpenSubscriptionCreateModal({ artist }))
  const onRenewSubscriptionClick = () =>
    dispatch(subscriptionOpenSubscriptionRenewModal({ artist }))
  const onCancelSubscriptionClick = () =>
    dispatch(subscriptionOpenSubscriptionCancelModal({ artist }))

  return (
    <div style={style}>
      <ArtistCard
        artist={artist}
        artistActions={{
          isFollowed: isUserFollowed,
          isSubscribed: isCurrentUserSubscribedToUser,
          isSubscribedAtPeriodEnd: isCurrentUserSubscribedToUserAtPeriodEnd,

          onFollowClick,
          onUnfollowClick,
          onCreateSubscriptionClick,
          onRenewSubscriptionClick,
          onCancelSubscriptionClick,
        }}
        onNameClick={navigateToArtistPage}
        onUsernameClick={navigateToArtistPage}
      />
    </div>
  )
})

type ArtistListFeedRecommendedProps = {}

export const ArtistListFeedRecommended: FC<ArtistListFeedRecommendedProps> = () => {
  const dispatch = useAppDispatch()
  const recommendedArtists = useAppSelector(userSelectRecommendedArtists)
  const recommendedArtistsCount = useAppSelector(userSelectRecommendedArtistsCount)

  const artistBlockWidth = 140
  const artistBlockMarginRight = 20
  const itemWidth = artistBlockWidth + artistBlockMarginRight

  const artistBlockHeight = 242
  const artistBlockMarginBottom = 0
  const itemHeight = artistBlockHeight + artistBlockMarginBottom

  const defaultContentCount = 1000
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 15 rows

  const isItemLoaded = (index: number) => !!recommendedArtists[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(userGetRecommendedArtists({ limit: itemsToFetch, offset: startIndex }))
  }

  return (
    <ContentLayoutVirtualized
      title="Artists"
      overrideCss={contentLayout.feed}
      itemWidth={itemWidth}
      itemsCount={recommendedArtistsCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={recommendedArtistsCount || defaultContentCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={recommendedArtistsCount || defaultContentCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

ArtistListFeedRecommended.propTypes = {}

import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityUserView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile } from '@/hooks'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type ArtistProps = {
  artist: EntityUserView
  followed: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const Artist: FC<ArtistProps> = ({ artist, followed, onClick = undefined }) => {
  const theme = useTheme()
  const [avatarLoadError, setAvatarLoadError] = useState(false)
  const { name, username, avatarId } = artist

  const artistHasAvatar = !!avatarId
  const source = useFile(avatarId || '')

  return (
    <div
      css={[
        styles(theme).artist.main.shared,
        followed
          ? styles(theme).artist.main.followed
          : styles(theme).artist.main.unfollowed,
      ]}
    >
      {/* image */}
      <div css={styles(theme).artist.avatar.main}>
        {artistHasAvatar && !avatarLoadError ? (
          <img
            css={styles(theme).artist.avatar.image}
            onError={() => setAvatarLoadError(true)}
            loading="lazy"
            width="70px"
            height="70px"
            src={source}
            alt="artist avatar"
          />
        ) : (
          <icons.User css={styles(theme).artist.avatar.icon} />
        )}
      </div>

      {/* content */}
      <div css={styles(theme).artist.content.main}>
        <div css={styles(theme).artist.content.data.main}>
          <div css={styles(theme).artist.content.data.name}>{name || ''}</div>
          <span css={styles(theme).artist.content.data.username}>@{username || ''}</span>
        </div>

        <div css={styles(theme).artist.content.follow.main}>
          {onClick ? (
            <button
              css={[
                styles(theme).artist.content.follow.button.shared,
                followed
                  ? styles(theme).artist.content.follow.button.followed
                  : styles(theme).artist.content.follow.button.unfollowed,
              ]}
              onClick={(e) => onClick(e)}
              type="button"
              aria-label="follow/unfollow artist"
            >
              {followed ? 'Unfollow' : 'Follow'}
            </button>
          ) : (
            <button
              css={[
                styles(theme).artist.content.follow.button.shared,
                styles(theme).artist.content.follow.button.disabled,
              ]}
              onClick={() => {}}
              disabled
              type="button"
              aria-label="follow artist"
            >
              Follow
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

Artist.propTypes = {}

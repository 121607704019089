import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { authSignout, onboardingSelectError } from '@/features'
// icons
import { icons } from '@/assets'
// components
import { ButtonRound, Subtitle, Error } from '@/components'

// components
import { Form } from './components'
// styles
import { styles } from './styles'

type CreateProfileProps = {}

export const CreateProfile: FC<CreateProfileProps> = () => {
  const dispatch = useAppDispatch()
  const onboardingError = useAppSelector(onboardingSelectError)

  return (
    <div css={styles().stage.main}>
      <div css={styles().stage.backward.main}>
        <ButtonRound
          css={styles().stage.backward.button}
          aria-label="sign out"
          appearance="secondary"
          onClick={() => dispatch(authSignout())}
        >
          <icons.SignOut css={styles().stage.backward.icon} />
        </ButtonRound>
      </div>

      <div css={styles().stage.content.main}>
        <div css={styles().stage.content.title}>
          <Subtitle text="Personalize your profile to make it uniquely yours!" />
        </div>

        <div css={styles().stage.content.form}>
          <Form />
        </div>
        <div css={styles().stage.content.error}>
          <Error error={onboardingError} />
        </div>
      </div>
    </div>
  )
}

CreateProfile.propTypes = {}

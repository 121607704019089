import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// utils
import { stringsCutter } from '@/utils'
// features
import {
  commentSelectCommentBlockLoading,
  commentSelectCommentBlockModalComment,
  commentBlock,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type CommentBlockFormProps = {}

export const CommentBlockForm: FC<CommentBlockFormProps> = () => {
  const dispatch = useAppDispatch()
  const commentBlockLoading = useAppSelector(commentSelectCommentBlockLoading)
  const comment = useAppSelector(commentSelectCommentBlockModalComment)

  const { message } = comment ?? {}
  const maxCommentMessageDisplayLength = 88

  const submitDisabled = commentBlockLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(commentBlock({ id: comment?.id || '' }))
        }}
      >
        <div css={styles().form.content.message}>
          {stringsCutter(maxCommentMessageDisplayLength, message || '')}
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="block comment"
            disabled={submitDisabled}
            appearance="primary"
          >
            {commentBlockLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Block'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

CommentBlockForm.propTypes = {}

import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  card: {
    main: css`
      margin: 50px 0px;
    `,
    content: {
      main: css`
        width: 400px;

        ${mq.tablet(css`
          width: 100%;
        `)}
      `,

      account: {
        main: css`
          margin-bottom: 32px;
        `,
        status: {
          main: css`
            display: flex;
            margin-bottom: 10px;
            align-items: center;
          `,
          title: css`
            margin-right: 20px;
          `,
          name: css``,
          subtitle: css``,
        },
      },

      balance: {
        main: css`
          margin-bottom: 32px;
        `,
        title: css`
          margin-bottom: 10px;
        `,
        pending: css`
          margin-bottom: 10px;
        `,
        available: css``,
      },

      buttons: {
        main: css`
          display: flex;
          margin-bottom: 32px;

          ${mq.tablet(css`
            flex-direction: column;
          `)}
        `,
        update: css`
          margin-right: 20px;

          & > button {
            width: 130px;
          }

          ${mq.tablet(css`
            margin-right: 0px;
            margin-bottom: 20px;

            & > button {
              width: 100%;
            }
          `)}
        `,
        payout: css`
          width: 100%;
        `,
      },
    },
  },
})

import { css } from '@emotion/react'

export const styles = () => ({
  form: {
    main: css`
      width: 100%;
    `,
    content: {
      main: css`
        width: 100%;
      `,
      message: css`
        width: 100%;
      `,
    },
  },
})

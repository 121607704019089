import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  track: {
    main: css`
      display: flex;
      align-items: center;

      width: 100%;
    `,

    cover: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 56px;
        height: 56px;

        background-color: ${theme.colors.primary.darker};
        border-radius: 4px;
        overflow: hidden;

        margin-right: 16px;

        &: hover > div {
          opacity: 1;
        }
      `,

      image: css`
        ${helpers.image};
      `,

      placeholder: css`
        display: flex;

        & > svg {
          width: 20px;
          height: 20px;

          & > path {
            color: ${theme.colors.secondary.normal};
          }
          & > circle {
            color: ${theme.colors.secondary.normal};
          }
        }
      `,
    },

    description: {
      main: css`
        display: flex;
        flex-direction: column;

        min-width: 0;
      `,
      name: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.sp};
        font-weight: bold;
      `,
      author: css`
        ${helpers.resetDefaults};
        ${helpers.textOverflowEllipsis};

        cursor: pointer;

        font-size: ${typography.sp};
        color: ${theme.colors.secondary.normal};
        text-align: start;
        margin-bottom: 2px;

        transition: color 0.2s;
        &:hover {
          color: ${theme.colors.accent.normal};
        }
      `,
      duration: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.sp};
        color: ${theme.colors.secondary.dark};
      `,
    },
  },
})

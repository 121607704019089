export enum AlertMessageType {
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

export type AlertMessage = {
  id: string
  type: AlertMessageType
  message: string
}

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { authCloseLoginModal, authSelectLoginModalOpen } from '@/features'

// components
import { ModalLayout } from '@/components'
import { LoginCard } from './components'

// styles
import { styles } from './AuthLoginModal.styles'

type AuthLoginModalProps = {}

export const AuthLoginModal: FC<AuthLoginModalProps> = () => {
  const dispatch = useAppDispatch()
  const loginModalOpen = useAppSelector(authSelectLoginModalOpen)

  return (
    <div css={styles().modal.main}>
      {loginModalOpen && (
        <ModalLayout
          background="solid"
          onOutsideClick={() => dispatch(authCloseLoginModal())}
        >
          <LoginCard />
        </ModalLayout>
      )}
    </div>
  )
}

AuthLoginModal.propTypes = {}

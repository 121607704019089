import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type CardDescriptionProps = {
  title: string
  subtitle: string
  accentStart: number
  accentEnd: number
}

export const CardDescription: FC<CardDescriptionProps> = ({
  title,
  subtitle,
  accentStart,
  accentEnd,
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).description.main}>
      <div css={styles(theme).description.title}>{title}</div>
      <div css={styles(theme).description.subtitle}>
        {subtitle.slice(0, accentStart)}
        <span css={styles(theme).description.accent}>
          {subtitle.slice(accentStart, accentEnd)}
        </span>
        {subtitle.slice(accentEnd, subtitle.length)}
      </div>
    </div>
  )
}

CardDescription.propTypes = {}

import { FC, useState } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'
// hooks
import { useAppSelector, useFile } from '@/hooks'
// features
import { authSelectProfileAvatar } from '@/features'

// styles
import { styles } from './styles'

interface AvatarProps {}

export const Avatar: FC<AvatarProps> = () => {
  const theme = useTheme()
  const [avatarLoadError, setAvatarLoadError] = useState(false)
  const profileAvatar = useAppSelector(authSelectProfileAvatar)
  const source = useFile(profileAvatar || '')

  return (
    <div css={styles(theme).avatar.main}>
      {profileAvatar && !avatarLoadError ? (
        <img
          css={styles(theme).avatar.content}
          onError={() => setAvatarLoadError(true)}
          loading="lazy"
          width="40px"
          height="40px"
          src={source}
          alt="user avatar"
        />
      ) : (
        <icons.User css={styles(theme).avatar.icon} />
      )}
    </div>
  )
}

import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  option: {
    main: css`
      display: flex;
    `,

    data: {
      main: css`
        display: flex;
        flex-direction: column;

        margin-right: auto;
      `,
      title: {
        main: css`
          display: flex;
          align-items: center;
        `,
        icon: css`
          margin-right: 8px;

          & > svg {
            width: 26px;
            height: 26px;
          }
        `,
        name: css`
          font-size: ${typography.p};
          font-weight: bold;

          margin-right: auto;
        `,
      },

      description: css`
        font-size: ${typography.sp};
        color: ${theme.colors.secondary.dark};
      `,
    },

    checkbox: css``,
  },
})

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'
// hooks
import { useAppDispatch } from '@/hooks'
// features
import { reportCloseReportUserModal } from '@/features'
// components
import { CardHeaderMobile, ModalCardTitle, ModalCardSubtitle, Error } from '@/components'

// components
import { ReportUserForm } from './components'
// styles
import { styles } from './styles'

type ReportUserCardProps = {}

export const ReportUserCard: FC<ReportUserCardProps> = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const reportUserError = ''

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile onCloseClick={() => dispatch(reportCloseReportUserModal())} />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <ModalCardTitle text="Report" icon={icons.AlertTriangle} />
      </div>

      {/* subtitle */}
      <div css={styles(theme).card.subtitle}>
        <ModalCardSubtitle text="Kindly provide details of your concern regarding this user." />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* form */}
        <div css={styles(theme).card.content.form}>
          <ReportUserForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={reportUserError} />
        </div>
      </div>
    </div>
  )
}

ReportUserCard.propTypes = {}

import { FC, memo } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  TrackTileSelect,
  trackSelectUploadedTracks,
  trackSelectUploadedTracksCount,
  trackSelectUploadedTracksLength,
  trackSelectUploadedTracksFetched,
  trackGetUploadedTracks,
  trackUtilGetTrackTileSkeletonSections,
} from '@/features'
// components
import {
  ContentLayoutVirtualized,
  ContentPlaceholder,
  SkeletonVertical,
} from '@/components'
// icons
import { icons } from '@/assets'
// styles
import { contentLayout } from '@/styles'

// styles
import { styles } from './styles'

type RowProps = {
  postTracks: EntityTrackView[]
  addPostTrack: (track: EntityTrackView) => void
  removePostTrack: (trackId: string) => void
}

const Row: FC<ListChildComponentProps<RowProps>> = memo(({ index, style, data }) => {
  const tracks = useAppSelector(trackSelectUploadedTracks)
  const track = tracks[index]

  // if track not fetched
  if (!track) {
    return (
      <div style={style}>
        <SkeletonVertical
          containerWidth="180px"
          sections={trackUtilGetTrackTileSkeletonSections(
            'track-list-studio-post-uploaded',
          )}
        />
      </div>
    )
  }

  // parent provided props
  const { postTracks, addPostTrack, removePostTrack } = data
  const { id: trackId } = track

  const isTrackAdded = postTracks.findIndex((t) => t.id === trackId) !== -1

  return (
    <div css={styles().tracks.main} style={style}>
      <TrackTileSelect
        track={track}
        selected={isTrackAdded}
        onClick={() =>
          isTrackAdded ? removePostTrack(trackId || '') : addPostTrack(track)
        }
        onAuthorNameClick={() => {}}
      />
    </div>
  )
})

type TrackListStudioPostUploadedProps = {
  postTracks: EntityTrackView[]
  addPostTrack: (track: EntityTrackView) => void
  removePostTrack: (trackId: string) => void
}

export const TrackListStudioPostUploaded: FC<TrackListStudioPostUploadedProps> = ({
  postTracks,
  addPostTrack,
  removePostTrack,
}) => {
  const dispatch = useAppDispatch()
  const uploadedTracks = useAppSelector(trackSelectUploadedTracks)
  const uploadedTracksCount = useAppSelector(trackSelectUploadedTracksCount)
  const uploadedTracksLength = useAppSelector(trackSelectUploadedTracksLength)
  const uploadedTracksFetched = useAppSelector(trackSelectUploadedTracksFetched)

  const trackBlockWidth = 180
  const trackBlockMarginRight = 20
  const itemWidth = trackBlockWidth + trackBlockMarginRight

  const trackBlockHeight = 230
  const trackBlockMarginBottom = 0
  const itemHeight = trackBlockHeight + trackBlockMarginBottom

  const defaultItemsCount = 1000
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!uploadedTracks[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(trackGetUploadedTracks({ limit: 30, offset: startIndex }))
  }

  // if uploaded tracks are fetched but length is 0
  if (uploadedTracksFetched && uploadedTracksLength === 0) {
    // return place holder
    return (
      <ContentLayoutVirtualized
        title="Tracks"
        overrideCss={contentLayout.post.create}
        itemWidth={itemWidth}
        itemsCount={uploadedTracksCount}
      >
        <ContentPlaceholder
          icon={icons.MusicNotes}
          height="230px"
          title="Couldn't find any uploaded tracks"
        />
      </ContentLayoutVirtualized>
    )
  }

  return (
    <ContentLayoutVirtualized
      title="Tracks"
      overrideCss={contentLayout.post.create}
      itemWidth={itemWidth}
      itemsCount={uploadedTracksCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={uploadedTracksCount || defaultItemsCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={uploadedTracksCount || defaultItemsCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
            itemData={{ postTracks, addPostTrack, removePostTrack }}
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

TrackListStudioPostUploaded.propTypes = {}

import { FC, useEffect, useState, useRef, ChangeEvent } from 'react'

// config
import {
  minUserNameLength,
  maxUserNameLength,
  minUserUsernameLength,
  maxUserUsernameLength,
} from '@/config'
// hooks
import { useAppSelector, useAppDispatch, useFile } from '@/hooks'
// features
import {
  authSelectProfile,
  onboardingSelectLoading,
  onboardingSelectError,
  onboardingUpdateProfile,
  onboardingSetError,
} from '@/features'
// utils
import { validateAvatarFile, validateName, validateUsername } from '@/utils'
// components
import { Loader, ButtonLarge, InputBar } from '@/components'

// components
import { Avatar } from './components'

// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const onboardingError = useAppSelector(onboardingSelectError)
  const onboardingLoading = useAppSelector(onboardingSelectLoading)

  // avatar state
  const userHasAvatar = !!profile?.avatarId
  const profileAvatar = useFile(profile?.avatarId || '')
  const [avatar, setAvatar] = useState<File | null>(null)
  const [avatarPreview, setAvatarPreview] = useState(userHasAvatar ? profileAvatar : '')
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  // name state
  const [name, setName] = useState(profile?.name || '')
  const [nameError, setNameError] = useState('')

  // username state
  const [username, setUsername] = useState(profile?.username || '')
  const [usernameError, setUsernameError] = useState('')

  const submitDisabled =
    name.length < minUserNameLength ||
    username.length < minUserUsernameLength ||
    nameError !== '' ||
    usernameError !== '' ||
    onboardingError !== '' ||
    onboardingLoading

  const revokePreviewURL = (url: string) => {
    URL.revokeObjectURL(url)
  }

  const onEditClick = () => {
    fileInputRef.current?.click()
    dispatch(onboardingSetError(''))
  }

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    // if file selected
    if (files && files.length > 0) {
      const file = files[0]

      // validate file
      const error = validateAvatarFile(file)
      if (error) {
        dispatch(onboardingSetError(error))
        return
      }

      // if avatar preview was set
      if (avatarPreview) {
        // clean up to avoid memory leaks
        revokePreviewURL(avatarPreview)
      }

      // set file as a preview
      setAvatarPreview(URL.createObjectURL(file))

      // store file in state
      setAvatar(file)
    }
  }

  useEffect(() => () => {
    // if avatar preview was set
    if (avatarPreview) {
      // clean up to avoid memory leaks
      revokePreviewURL(avatarPreview)
    }
  })

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(onboardingUpdateProfile({ name, username, avatar }))
        }}
      >
        <div css={styles().form.content.avatar.main}>
          <label css={styles().form.content.avatar.label} htmlFor="avatar">
            avatar
            <input
              css={styles().form.content.avatar.input}
              name="avatar"
              type="file"
              id="avatar"
              ref={fileInputRef}
              accept="image/jpeg,image/webp,image/png"
              onChange={(e) => onFileChange(e)}
            />
          </label>
          <Avatar onEditClick={() => onEditClick()} source={avatarPreview} />
        </div>

        <div css={styles().form.content.name}>
          <InputBar
            name="name"
            label="name"
            type="text"
            placeholder="Enter your name"
            value={name}
            error={nameError.length > 0}
            errorMessage={nameError}
            min={minUserNameLength}
            max={maxUserNameLength}
            onChange={(e) => {
              setName(e.target.value)
            }}
            onBlur={() => setNameError(validateName(name))}
            onFocus={() => setNameError('')}
          />
        </div>

        {!profile?.username && (
          // render username input if user has no username
          <div css={styles().form.content.username}>
            <InputBar
              name="username"
              label="username"
              type="text"
              placeholder="Enter your username"
              value={username}
              error={usernameError.length > 0}
              errorMessage={usernameError}
              min={minUserUsernameLength}
              max={maxUserUsernameLength}
              onChange={(e) => {
                setUsername(e.target.value)
              }}
              onBlur={() => setUsernameError(validateUsername(username))}
              onFocus={() => {
                setUsernameError('')
                dispatch(onboardingSetError(''))
              }}
            />
          </div>
        )}

        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="create profile"
            disabled={submitDisabled}
            appearance="primary"
          >
            {onboardingLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Create'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

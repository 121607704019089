import { css } from '@emotion/react'
import { mq, helpers } from '@/styles'

export const styles = () => ({
  list: {
    main: css``,
    nav: {
      main: css`
        ${helpers.app.screen.width.full};
        display: flex;

        overflow: hidden;
        max-width: 800px;

        margin-bottom: 50px;
      `,
    },
    header: {
      main: css`
        display: flex;

        max-width: 800px;
        margin-bottom: 32px;
      `,
      placeholder: css`
        flex: 0 0 40px;
        margin-right: 10px;

        ${mq.tablet(css`
          display: none;
        `)}
      `,
      source: css`
        flex: 1 0 100px;
        margin-right: 10px;

        ${mq.mobile(css`
          flex: 1 0 80px;
        `)}
      `,
      amount: css`
        flex: 1 0 60px;
        max-width: 100px;
        margin-right: 10px;

        ${mq.mobile(css`
          flex: 1 0 50px;
          max-width: 60px;
        `)}
      `,
      type: css`
        flex: 1 0 60px;
        max-width: 100px;
        margin-right: 10px;

        ${mq.tablet(css`
          display: none;
        `)}
      `,
      status: css`
        flex: 1 0 60px;
        max-width: 100px;
        margin-right: 10px;

        ${mq.mobile(css`
          flex: 1 0 40px;
          max-width: 60px;
        `)}
      `,
      date: css`
        flex: 1 0 40px;
        max-width: 80px;

        ${mq.mobile(css`
          flex: 1 0 40px;
          max-width: 60px;
        `)}
      `,
    },
    content: {
      main: css`
        max-width: 800px;
      `,
    },
  },

  transactions: {
    main: css``,
  },
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import { EntityUserRole, EntityOnboardingStage } from '@ryddm-inc/ryddm-apiclient'

// features
import { authUpdateProfile } from '@/features'
// libs
import { apiGetService, apiHandleError, storageSetAuthToken } from '@/lib'
// config
import { RootState } from '@/stores'

// types
import { OnboardingStep, ConnectLinkType } from './types'
// utils
import {
  // onboarding
  onboardingGetCurrentStep,
  onboardingGetNextStage,
  onboardingGetPrevStage,
} from './utils'

export const onboardingSetCurrentStep = createAsyncThunk<
  { step: OnboardingStep; error: string },
  { userRole: EntityUserRole; currentOnboardingStage: EntityOnboardingStage },
  { state: RootState; rejectValue: undefined }
>('onboarding/onboardingSetCurrentStep', async (inp) => {
  // get current onboarding step utilizing provided user role and onboarding stage
  const { currentOnboardingStep } = onboardingGetCurrentStep(
    inp.userRole,
    inp.currentOnboardingStage,
  )

  return {
    step: currentOnboardingStep,
    error: '',
  }
})

export const onboardingSetNextStage = createAsyncThunk<
  { step: OnboardingStep; error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('onboarding/onboardingSetNextStage', async (_, { getState, dispatch }) => {
  // get api service
  const api = apiGetService()

  // get current user
  const { profile } = getState().auth
  // get current step
  const currentOnboardingStep = getState().onboarding.step

  const userRole = profile?.role as EntityUserRole
  const currentOnboardingStage = profile?.onboardingStage as EntityOnboardingStage

  const { nextOnboardingStage, nextOnboardingStep } = onboardingGetNextStage(
    userRole,
    currentOnboardingStage,
    currentOnboardingStep,
  )

  try {
    // update onboarding stage
    const { user } = await api.userApi.updateUserProfileOnboardingStage({
      onboardingStage: nextOnboardingStage,
    })

    // update profile
    dispatch(authUpdateProfile(user || {}))

    // return payload
    return {
      step: nextOnboardingStep,
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      step: currentOnboardingStep,
      error: message,
    }
  }
})

export const onboardingSetPrevStage = createAsyncThunk<
  { step: OnboardingStep; error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('onboarding/onboardingSetPrevStage', async (_, { getState, dispatch }) => {
  // get api service
  const api = apiGetService()

  // get current user
  const { profile } = getState().auth
  // get current step
  const currentOnboardingStep = getState().onboarding.step

  const userRole = profile?.role as EntityUserRole
  const currentOnboardingStage = profile?.onboardingStage as EntityOnboardingStage

  const { prevOnboardingStage, prevOnboardingStep } = onboardingGetPrevStage(
    userRole,
    currentOnboardingStage,
    currentOnboardingStep,
  )

  try {
    // update onboarding stage
    const { user } = await api.userApi.updateUserProfileOnboardingStage({
      onboardingStage: prevOnboardingStage,
    })

    // update profile
    dispatch(authUpdateProfile(user || {}))

    // return payload
    return {
      step: prevOnboardingStep,
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      step: currentOnboardingStep,
      error: message,
    }
  }
})

export const onboardingUpdateRole = createAsyncThunk<
  { error: string },
  { role: EntityUserRole },
  { state: RootState; rejectValue: undefined }
>('onboarding/onboardingUpdateRole', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // update user role
    const { user } = await api.userApi.updateUserProfileRole({ role: inp.role })

    // refresh token
    const { token: freshToken } = await api.authApi.refreshToken()

    // update token in local storage
    storageSetAuthToken(freshToken!)

    // update token in api service
    api.updateApiKey(freshToken!)

    // update profile
    dispatch(authUpdateProfile(user || {}))

    // update onboarding stage
    dispatch(onboardingSetNextStage())

    // return payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const onboardingUpdateProfile = createAsyncThunk<
  { error: string },
  { avatar: File | null; name: string; username: string },
  { state: RootState; rejectValue: undefined }
>('onboarding/onboardingUpdateProfile', async (inp, { getState, dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // if avatar provided
    if (inp.avatar) {
      // create form data
      const formData = new FormData()

      // append avatar
      formData.append('file', inp.avatar)

      // update user avatar
      const { user } = await api.userApi.updateUserProfileAvatar({ body: formData })

      // update profile
      dispatch(authUpdateProfile(user || {}))
    }

    // if name provided
    if (inp.name) {
      // update user name
      const { user } = await api.userApi.updateUserProfile({ name: inp.name })

      // update profile
      dispatch(authUpdateProfile(user || {}))
    }

    // get current profile
    const { profile } = getState().auth

    // if user not have username and provided username
    if (inp.username && !profile?.username) {
      // check username availability
      const unRes = await api.userApi.getUserProfileUsernameAvailable(inp.username)

      // if username is not available
      if (!unRes.usernameAvailable) {
        return {
          error: 'this username is not available',
        }
      }

      // update user username
      const { user } = await api.userApi.updateUserProfileUsername({
        username: inp.username,
      })

      // update profile
      dispatch(authUpdateProfile(user || {}))
    }

    // update onboarding stage
    dispatch(onboardingSetNextStage())

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const onboardingUpdateGenres = createAsyncThunk<
  { error: string },
  { preferredGenres: string[] },
  { state: RootState; rejectValue: undefined }
>('onboarding/onboardingUpdateGenres', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // update user preferred genres
    const { user } = await api.userApi.updateUserProfile({
      preferredGenres: inp.preferredGenres,
    })

    // update profile
    dispatch(authUpdateProfile(user || {}))

    // update onboarding stage
    dispatch(onboardingSetNextStage())

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const onboardingGetConnectLink = createAsyncThunk<
  { connectLink: string; error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('onboarding/onboardingGetConnectLink', async () => {
  // get api service
  const api = apiGetService()

  try {
    // get connect link
    const res = await api.connectApi.getConnectLink(ConnectLinkType.Onboarding)

    // return empty payload
    return {
      connectLink: res.link || '',
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      connectLink: '',
      error: message,
    }
  }
})

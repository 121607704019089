import { FC } from 'react'
import { useTheme } from '@emotion/react'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useUserPublic } from '@/hooks'
// icon
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TrackTileProductProps = {
  track: EntityTrackView
}

export const TrackTileProduct: FC<TrackTileProductProps> = ({ track }) => {
  const theme = useTheme()

  const { name: trackName, userId } = track

  // track author
  const { user: trackAuthor, isLoading: isTrackAuthorLoading } = useUserPublic(
    userId || '',
  )
  const { name: authorName } = trackAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'

  return (
    <div css={styles(theme).track.main}>
      {/* track cover */}
      <div css={styles(theme).track.cover.main}>
        <div css={styles(theme).track.cover.lock.main}>
          <div css={styles(theme).track.cover.lock.icon}>
            <icons.Lock />
          </div>
        </div>
      </div>

      {/* track description */}
      <div css={styles(theme).track.description.main}>
        <div css={styles(theme).track.description.name}>{trackName}</div>
        <div css={styles(theme).track.description.author}>
          {isTrackAuthorLoading
            ? userLoadingPlaceholder
            : authorName || userNamePlaceholder}
        </div>
      </div>
    </div>
  )
}

TrackTileProduct.propTypes = {}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { EntityCustomerPaymentMethod } from '@ryddm-inc/ryddm-apiclient'
import { Stripe, StripeElements } from '@stripe/stripe-js'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// config
import { Environment, Platform } from '@/config'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // customer
  customerSetCustomerPaymentMethods,
  customerResetState,
  customerCloseCustomerPaymentMethodCreateModal,
  customerOpenCustomerPaymentMethodCreateModal,
} from '@/features'

export const customerGetCustomerPaymentMethods = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number },
  { state: RootState; rejectValue: undefined }
>('customer/customerGetCustomerPaymentMethods', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // get payment methods
    const { customerPaymentMethods, count } =
      await api.customerApi.getCustomerPaymentMethods(inp.limit, inp.offset)

    // set payment methods
    dispatch(
      customerSetCustomerPaymentMethods({
        customerPaymentMethods: customerPaymentMethods || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const customerCreateCustomerPaymentMethodSetup = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('customer/customerCreateCustomerPaymentMethodSetup', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // create customer payment method setup
    const { clientSecret } = await api.customerApi.createCustomerPaymentMethodSetup()

    // open customer payment method create modal
    dispatch(
      customerOpenCustomerPaymentMethodCreateModal({
        clientSecret: clientSecret || '',
      }),
    )

    // return payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to setup customer payment method creation: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const customerCreateCustomerPaymentMethod = createAsyncThunk<
  { error: string },
  { stripe: Stripe | null; elements: StripeElements | null },
  { state: RootState; rejectValue: undefined }
>('customer/customerCreateCustomerPaymentMethod', async (inp, { dispatch }) => {
  // if stripe is not loaded
  if (!inp.stripe || !inp.elements) {
    // return error message in payload
    return {
      error: 'Stripe is failed to load',
    }
  }

  // define redirect url
  const redirectUrl =
    process.env.ENVIRONMENT === Environment.Production
      ? Platform.ProductionURL
      : Platform.StagingURL

  try {
    // confirm stripe setup
    const { error } = await inp.stripe.confirmSetup({
      elements: inp.elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: redirectUrl,
      },
    })

    // if error returned
    if (error) {
      // launch error alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Error,
            message: `Payment method creation failed: ${error.message}`,
          },
        }),
      )

      // return error message in payload
      return {
        error: error.message || '',
      }
    }

    // launch success alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: `Payment method creation succeeded!`,
        },
      }),
    )

    // close customer payment method create modal
    dispatch(customerCloseCustomerPaymentMethodCreateModal())

    const resetAfterMs = 3000
    setTimeout(() => {
      // reset customer state
      dispatch(customerResetState())
    }, resetAfterMs)

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Payment method creation failed: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const customerDeleteCustomerPaymentMethod = createAsyncThunk<
  { error: string },
  { customerPaymentMethod: EntityCustomerPaymentMethod },
  { state: RootState; rejectValue: undefined }
>(
  'customer/customerDeleteCustomerPaymentMethod',
  async ({ customerPaymentMethod }, { dispatch }) => {
    const { id: customerPaymentMethodId } = customerPaymentMethod

    // get api service
    const api = apiGetService()

    try {
      // delete payment method
      await api.customerApi.deleteCustomerPaymentMethod(customerPaymentMethodId || '')

      // reset customer state
      dispatch(customerResetState())

      // launch info alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Info,
            message: `Successfully deleted payment method!`,
          },
        }),
      )

      // return payload
      return {
        error: '',
      }
    } catch (err: any) {
      const { message } = await apiHandleError(err)

      // launch error alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Error,
            message: `Failed to delete payment method: ${message}`,
          },
        }),
      )

      // return error message in payload
      return {
        error: message,
      }
    }
  },
)

export const customerSetCustomerPaymentMethodDefault = createAsyncThunk<
  { error: string },
  { customerPaymentMethod: EntityCustomerPaymentMethod },
  { state: RootState; rejectValue: undefined }
>(
  'customer/customerSetCustomerPaymentMethodDefault',
  async ({ customerPaymentMethod }, { dispatch }) => {
    const { id: customerPaymentMethodId } = customerPaymentMethod

    // get api service
    const api = apiGetService()

    try {
      // set payment method as default
      await api.customerApi.setCustomerPaymentMethodDefault({
        customerPaymentMethodId: customerPaymentMethodId || '',
      })

      // reset customer state
      dispatch(customerResetState())

      // launch info alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Info,
            message: `Successfully updated default payment method!`,
          },
        }),
      )

      // return payload
      return {
        error: '',
      }
    } catch (err: any) {
      const { message } = await apiHandleError(err)

      // launch error alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Error,
            message: `Failed to update default payment method: ${message}`,
          },
        }),
      )

      // return error message in payload
      return {
        error: message,
      }
    }
  },
)

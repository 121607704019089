import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { onboardingSelectError, onboardingSetPrevStage } from '@/features'
// icons
import { icons } from '@/assets'
// components
import { ButtonRound, Error, Subtitle } from '@/components'

// components
import { Form } from './components'
// styles
import { styles } from './styles'

type SelectGenresProps = {}

export const SelectGenres: FC<SelectGenresProps> = () => {
  const dispatch = useAppDispatch()
  const onboardingError = useAppSelector(onboardingSelectError)

  return (
    <div css={styles().stage.main}>
      <div css={styles().stage.backward.button}>
        <ButtonRound
          aria-label="set previous onboarding stage"
          appearance="secondary"
          onClick={() => dispatch(onboardingSetPrevStage())}
        >
          <icons.ArrowLeft css={styles().stage.backward.icon} />
        </ButtonRound>
      </div>

      <div css={styles().stage.content.main}>
        <div css={styles().stage.content.title}>
          <Subtitle text="Pick the top three music genres you enjoy the most!" />
        </div>

        <div css={styles().stage.content.form}>
          <Form />
        </div>

        <div css={styles().stage.content.error}>
          <Error error={onboardingError} />
        </div>
      </div>
    </div>
  )
}

SelectGenres.propTypes = {}

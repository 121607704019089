import { FC, useState, ReactNode } from 'react'

// components
import { Button } from './components'
// styles
import { styles } from './styles'

type SectionsLayoutProps = {
  sections: { id: string; name: string; section: ReactNode }[]
}

export const SectionsLayout: FC<SectionsLayoutProps> = ({ sections }) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0)

  return (
    <div css={styles().sections.main}>
      <div css={styles().sections.nav.main}>
        {sections.map(({ id, name }, idx) => (
          <div css={styles().sections.nav.item} key={`section-layout-item-${id}`}>
            <Button
              type="button"
              aria-label={`select ${name}`}
              active={currentSectionIndex === idx}
              onClick={() => setCurrentSectionIndex(idx)}
            >
              {name}
            </Button>
          </div>
        ))}
      </div>
      <div css={styles().sections.content}>{sections[currentSectionIndex].section}</div>
    </div>
  )
}

SectionsLayout.propTypes = {}

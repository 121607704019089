import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  balance: {
    main: css``,
    amount: css`
      font-size: ${typography.h3};
      color: ${theme.colors.secondary.light};
    `,
    subtitle: css`
      font-size: ${typography.p};
      color: ${theme.colors.secondary.dark};
    `,
  },
})

import { ComponentType, lazy } from 'react'

/**
 * Creates new instance of api client.
 *
 * Takes api base path and jwt token, returns api service.
 *
 * @param {string} basePath - API base path.
 * @param {number} apiKey - API authentication token (JWT).
 * @returns {number} The sum of the two input numbers.
 *
 * @example
 * // Returns api service.
 * const apiService = createApiService('https://api.example.com/api/v1', 'eyJhbGciOiJI...');
 */
export function importsLazy<
  T extends ComponentType<any>,
  I extends { [K2 in K]: T },
  K extends keyof I,
>(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: lazy(() => factory().then((module) => ({ default: module[name] }))),
  })
}

import { css } from '@emotion/react'
import { helpers, mq } from '@/styles'

export const styles = () => ({
  artists: {
    main: css`
      ${helpers.hideScrollbar};

      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      max-height: 400px;
      overflow: hidden;

      width: 100%;

      overflow: hidden;
      overflow-y: scroll;

      padding: 40px 0px;

      & > div {
        margin: 0px 8px 16px 8px;
      }

      ${mq.mobile(css`
        & > div {
          margin: 0px 0px 16px 0px;
        }
      `)}
    `,
  },
})

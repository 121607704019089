import { FC, useRef, useEffect, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useUserPublic } from '@/hooks'
// icon
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TrackCardProps = {
  track: EntityTrackView
  inProgress?: boolean
  options?: {
    id: string
    label: string
    name: string
    danger?: boolean
    onClick: MouseEventHandler<HTMLButtonElement>
  }[]
  onClick: MouseEventHandler<HTMLButtonElement>
  onAuthorNameClick: MouseEventHandler<HTMLButtonElement>
}

export const TrackCard: FC<TrackCardProps> = ({
  track,
  inProgress = false,
  options = [],
  onClick,
  onAuthorNameClick,
}) => {
  const theme = useTheme()

  const optionsRef = useRef<HTMLDivElement | null>(null)

  const isMoreThanTwoOptions = (options?.length || 0) > 2

  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [isOptionsScrollStart, setIsOptionsScrollStart] = useState(false)
  const [isOptionsScrollEnd, setIsOptionsScrollEnd] = useState(false)

  const { coverId, name: trackName, userId } = track
  const trackHasCover = !!coverId
  const source = useFile(coverId || '')
  const [coverLoadError, setCoverLoadError] = useState(false)

  // track author
  const { user: trackAuthor, isLoading: isTrackAuthorLoading } = useUserPublic(
    userId || '',
  )
  const { name: authorName } = trackAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'

  const handleOptionsScrollArrow = (target: HTMLDivElement) => {
    // if more than two options provided
    if (isMoreThanTwoOptions) {
      // get node client height, scroll height, and scroll offset
      // clientHeight - height of currently visible content
      // scrollHeight - height of total scroll the element
      // scrollTop  - number of pixels scrolled
      const { clientHeight, scrollHeight, scrollTop } = target

      // last scroll position
      const lastScrollPosition = scrollHeight - clientHeight

      // if scroll start reached
      if (scrollTop <= 0) {
        setIsOptionsScrollStart(true)
        setIsOptionsScrollEnd(false)
      }

      // if scroll end reached
      if (scrollTop >= lastScrollPosition) {
        setIsOptionsScrollStart(false)
        setIsOptionsScrollEnd(true)
      }
    }
  }

  useEffect(() => {
    // if node present
    if (optionsRef.current) {
      handleOptionsScrollArrow(optionsRef.current)
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isOptionsOpen])

  return (
    <div css={styles(theme).track.main} onMouseLeave={() => setIsOptionsOpen(false)}>
      {/* track cover */}
      <div css={styles(theme).track.cover.main}>
        <div css={styles(theme).track.cover.overlay.main(!!inProgress)}>
          {inProgress ? (
            <button
              css={styles(theme).track.cover.overlay.button}
              onClick={() => {}}
              type="button"
              aria-label="currently playing"
            >
              <icons.Speaker />
            </button>
          ) : (
            <button
              css={styles(theme).track.cover.overlay.button}
              onClick={(e) => onClick(e)}
              type="button"
              aria-label="play track"
            >
              <icons.Play />
            </button>
          )}
        </div>

        {trackHasCover && !coverLoadError ? (
          <img
            css={styles(theme).track.cover.image}
            onError={() => setCoverLoadError(true)}
            loading="lazy"
            width="60px"
            height="60px"
            src={source}
            alt="track album cover"
          />
        ) : (
          <div css={styles(theme).track.cover.placeholder}>
            <icons.MusicNote />
          </div>
        )}
      </div>

      {/* track description */}
      <div css={styles(theme).track.description.main}>
        <div css={styles(theme).track.description.name}>{trackName}</div>
        <button
          css={styles(theme).track.description.author}
          onClick={(e) => onAuthorNameClick(e)}
          type="button"
          aria-label="explore track author"
        >
          {isTrackAuthorLoading
            ? userLoadingPlaceholder
            : authorName || userNamePlaceholder}
        </button>
      </div>

      {/* track menu */}
      <div css={styles(theme).track.menu.main}>
        <button
          css={styles(theme).track.menu.button}
          onClick={() => {
            setIsOptionsOpen((isOpen) => !isOpen)
          }}
          type="button"
          aria-label="toggle track options"
        >
          <icons.MenuDots css={styles(theme).track.menu.icon} />
        </button>

        {/* menu options */}
        {isOptionsOpen && (
          <div css={styles(theme).track.menu.options.main}>
            <div
              css={[
                styles(theme).track.menu.options.overlay.top.shared,
                isOptionsScrollEnd &&
                  styles(theme).track.menu.options.overlay.top.visible,
              ]}
            >
              <icons.CaretDown />
            </div>

            <div
              css={styles(theme).track.menu.options.list.main}
              ref={optionsRef}
              onScroll={(e) => {
                handleOptionsScrollArrow(e.target as HTMLDivElement)
              }}
            >
              <ul css={styles(theme).track.menu.options.list.ul}>
                {options &&
                  options.map(({ id, label, name, danger, onClick: onOptionClick }) => (
                    <li
                      css={styles(theme).track.menu.options.list.li}
                      key={`track-option-${id}`}
                    >
                      <button
                        css={[
                          styles(theme).track.menu.options.list.button.shared,
                          danger
                            ? styles(theme).track.menu.options.list.button.danger
                            : styles(theme).track.menu.options.list.button.regular,
                        ]}
                        onClick={(e) => onOptionClick(e)}
                        type="button"
                        aria-label={label}
                      >
                        {name}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>

            <div
              css={[
                styles(theme).track.menu.options.overlay.bottom.shared,
                isOptionsScrollStart &&
                  styles(theme).track.menu.options.overlay.bottom.visible,
              ]}
            >
              <icons.CaretDown />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

TrackCard.propTypes = {}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// stores
import { RootState } from '@/stores'
// features
import {
  onboardingSetCurrentStep,
  onboardingSetNextStage,
  onboardingSetPrevStage,
  onboardingUpdateRole,
  onboardingUpdateProfile,
  onboardingUpdateGenres,
  onboardingGetConnectLink,
} from '@/features'

// types
import { OnboardingStep } from './types'

export interface OnboardingState {
  step: OnboardingStep
  connectLink: string
  error: string
  loading: boolean
}

const initialState: OnboardingState = {
  step: 0,
  connectLink: '',
  error: '',
  loading: false,
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    // set error
    onboardingSetError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
    }),
  },
  extraReducers: (builder) => {
    // set current onboarding step (on refresh)
    builder
      .addCase(onboardingSetCurrentStep.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(onboardingSetCurrentStep.fulfilled, (state, { payload }) => ({
        ...state,
        step: payload.step,
        error: payload.error,
        loading: false,
      }))

    // set next onboarding stage
    builder
      .addCase(onboardingSetNextStage.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(onboardingSetNextStage.fulfilled, (state, { payload }) => ({
        ...state,
        step: payload.step,
        error: payload.error,
        loading: false,
      }))

    // set prev onboarding stage
    builder
      .addCase(onboardingSetPrevStage.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(onboardingSetPrevStage.fulfilled, (state, { payload }) => ({
        ...state,
        step: payload.step,
        error: payload.error,
        loading: false,
      }))

    // select user role
    builder
      .addCase(onboardingUpdateRole.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(onboardingUpdateRole.fulfilled, (state, { payload }) => ({
        ...state,
        error: payload.error,
        loading: false,
      }))

    // create profile
    builder
      .addCase(onboardingUpdateProfile.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(onboardingUpdateProfile.fulfilled, (state, { payload }) => ({
        ...state,
        error: payload.error,
        loading: false,
      }))

    // select genres
    builder
      .addCase(onboardingUpdateGenres.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(onboardingUpdateGenres.fulfilled, (state, { payload }) => ({
        ...state,
        error: payload.error,
        loading: false,
      }))

    // get connect link
    builder
      .addCase(onboardingGetConnectLink.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(onboardingGetConnectLink.fulfilled, (state, { payload }) => ({
        ...state,
        connectLink: payload.connectLink,
        error: payload.error,
        loading: false,
      }))
  },
})

export const { onboardingSetError } = onboardingSlice.actions

export const onboardingSelectStep = (state: RootState) => state.onboarding.step
export const onboardingSelectConnectLink = (state: RootState) =>
  state.onboarding.connectLink
export const onboardingSelectError = (state: RootState) => state.onboarding.error
export const onboardingSelectLoading = (state: RootState) => state.onboarding.loading

export const onboardingReducer = onboardingSlice.reducer

// export default onboardingSlice.reducer

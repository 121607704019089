import { css } from '@emotion/react'
import { typography } from '@/styles'

export const styles = () => ({
  form: {
    main: css``,
    title: {
      main: css`
        margin-bottom: 50px;
      `,
      text: css`
        font-size: ${typography.h6};
        font-weight: bold;

        text-align: center;
      `,
    },
    content: {
      main: css``,
      email: css`
        margin-bottom: 32px;
      `,
      password: css`
        margin-bottom: 32px;
      `,
      repeat: css`
        margin-bottom: 50px;
      `,
      terms: css`
        margin-bottom: 50px;
      `,
      submit: css``,
    },
  },
})

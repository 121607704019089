import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// icons
import { icons } from '@/assets'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  subscriptionSelectSubscriptionRenewModalArtist,
  subscriptionCloseSubscriptionRenewModal,
} from '@/features'
// components
import { CardHeaderMobile, ModalCardTitle, ModalCardSubtitle, Error } from '@/components'

// components
import { SubscriptionRenewForm } from './components'
// styles
import { styles } from './styles'

type SubscriptionRenewCardProps = {}

export const SubscriptionRenewCard: FC<SubscriptionRenewCardProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const modalArtist = useAppSelector(subscriptionSelectSubscriptionRenewModalArtist)
  const subscriptionError = ''

  const { id: artistUserId, username } = modalArtist ?? {}

  const subtitleAccentStartIndex = 54
  const subtitleAccentEndIndex = 55 + (username?.length || 0)

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile
          onCloseClick={() => dispatch(subscriptionCloseSubscriptionRenewModal())}
        />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <ModalCardTitle text="Subscribe" icon={icons.Unlock} />
      </div>

      {/* subtitle */}
      <div css={styles(theme).card.subtitle}>
        <ModalCardSubtitle
          text={`Unlock a world of exclusive content by subscribing to @${username}!`}
          accent={{
            accentStart: subtitleAccentStartIndex,
            accentEnd: subtitleAccentEndIndex,
            onClick: () => navigate(`${Path.User}/${artistUserId}`),
          }}
        />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* form */}
        <div css={styles(theme).card.content.form}>
          <SubscriptionRenewForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={subscriptionError} />
        </div>
      </div>
    </div>
  )
}

SubscriptionRenewCard.propTypes = {}

import { FC, InputHTMLAttributes, ElementType } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface InputLineProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  icon?: ElementType
  error?: boolean
  errorMessage?: string
}

export const InputLine: FC<InputLineProps> = ({
  name,
  label,
  icon: Icon = undefined,
  error = false,
  errorMessage = '',
  ...attributes
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).input.main}>
      {/* label */}
      <label css={styles(theme).input.label} htmlFor={name}>
        {label}
      </label>

      {/* input */}
      <div css={styles(theme).input.data.main}>
        <input
          css={[
            styles(theme).input.data.shared,

            attributes.value
              ? styles(theme).input.data.filled
              : styles(theme).input.data.empty,

            attributes.disabled && styles(theme).input.data.disabled,
            error && styles(theme).input.data.errored,
          ]}
          id={name}
          {...attributes}
        />
        {Icon && (
          <div
            css={[
              styles(theme).input.data.icon.shared,

              attributes.value
                ? styles(theme).input.data.icon.filled
                : styles(theme).input.data.icon.empty,

              attributes.disabled && styles(theme).input.data.icon.disabled,
              error && styles(theme).input.data.icon.errored,
            ]}
          >
            <Icon />
          </div>
        )}
      </div>

      {/* error message */}
      <div
        css={[
          styles(theme).input.error.shared,
          errorMessage
            ? styles(theme).input.error.visible
            : styles(theme).input.error.hidden,
        ]}
      >
        <span
          css={[
            styles(theme).input.error.text.shared,
            errorMessage
              ? styles(theme).input.error.text.visible
              : styles(theme).input.error.text.hidden,
          ]}
        >
          {errorMessage}
        </span>
      </div>
    </div>
  )
}

InputLine.propTypes = {}

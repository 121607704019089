import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { authCloseSignupModal, authSelectSignupModalOpen } from '@/features'

// components
import { ModalLayout } from '@/components'
import { SignupCard } from './components'

// styles
import { styles } from './AuthSignupModal.styles'

type AuthSignupModalProps = {}

export const AuthSignupModal: FC<AuthSignupModalProps> = () => {
  const dispatch = useAppDispatch()
  const signupModalOpen = useAppSelector(authSelectSignupModalOpen)

  return (
    <div css={styles().modal.main}>
      {signupModalOpen && (
        <ModalLayout
          background="solid"
          onOutsideClick={() => dispatch(authCloseSignupModal())}
        >
          <SignupCard />
        </ModalLayout>
      )}
    </div>
  )
}

AuthSignupModal.propTypes = {}

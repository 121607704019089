import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: css`
      display: flex;
      // justify-content: space-between;
    `,

    left: {
      main: css`
        display: flex;
        flex-direction: column;

        margin-right: 12px;
      `,
      avatar: {
        main: css`
          display: flex;
          align-items: center;
          justify-content: center;

          flex-shrink: 0;

          width: 30px;
          height: 30px;

          background-color: ${theme.colors.primary.darker};
          border-radius: 50%;
          overflow: hidden;

          margin-bottom: 12px;
        `,
        image: css`
          ${helpers.image};
        `,
        icon: css`
          width: 14px;
          height: 14px;

          & > path {
            fill: ${theme.colors.secondary.normal};
          }
        `,
      },
      nav: {
        main: css`
          display: flex;
        `,
        button: css`
          ${helpers.resetDefaults};
          cursor: pointer;

          display: flex;
          align-items: center;
          justify-content: center;

          width: 30px;
          height: 30px;

          & > svg {
            width: 20px;
            height: 20px;

            & > path {
              color: ${theme.colors.secondary.normal};
            }
          }
        `,
      },
    },

    right: {
      main: css`
        display: flex;
        flex-direction: column;

        min-width: 0;
      `,
      name: css`
        ${helpers.resetDefaults};

        display: flex;
        min-width: 0;

        max-width: 240px;
        margin-bottom: 2px;

        & > span {
          ${helpers.textOverflowEllipsis};
          cursor: pointer;

          font-size: ${typography.sp};
          font-weight: bold;
          color: ${theme.colors.secondary.normal};

          transition: color 0.2s;
        }

        &:hover {
          & > span {
            color: ${theme.colors.accent.normal};
          }
        }
      `,
      message: css`
        word-wrap: break-word;

        font-size: ${typography.sp};
        color: ${theme.colors.secondary.normal};
      `,
    },
  },
})

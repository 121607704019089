import { css, Theme } from '@emotion/react'
import { helpers, typography, z } from '@/styles'

export const styles = (theme: Theme) => ({
  track: {
    main: css`
      display: flex;
      align-items: center;

      width: 100%;
    `,

    cover: {
      main: css`
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 60px;
        height: 60px;

        background-color: ${theme.colors.primary.darker};
        border-radius: 4px;
        overflow: hidden;

        margin-right: 16px;

        &: hover > div {
          opacity: 1;
        }
      `,

      overlay: {
        main: (inProgress: boolean) => css`
          z-index: ${z.features.track.card.overlay};

          position: absolute;
          left: 0;
          top: 0;

          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 100%;

          transition: opacity 0.2s;

          opacity: 0;
          ${inProgress && 'opacity: 1;'}

          background-color: ${theme.colors.primary.normal}A0;
        `,

        button: css`
          ${helpers.resetDefaults};

          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 100%;

          cursor: pointer;

          & > svg {
            width: 20px;
            height: 20px;

            & path {
              fill: ${theme.colors.accent.normal};
            }
          }
        `,
      },

      image: css`
        ${helpers.image};
      `,

      placeholder: css`
        display: flex;

        & > svg {
          width: 20px;
          height: 20px;

          & > path {
            color: ${theme.colors.secondary.normal};
          }
          & > circle {
            color: ${theme.colors.secondary.normal};
          }
        }
      `,
    },

    description: {
      main: css`
        display: flex;
        flex-direction: column;

        min-width: 0;
        margin-right: auto;
      `,
      name: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.p};
        font-weight: bold;
      `,
      author: css`
        ${helpers.resetDefaults};
        ${helpers.textOverflowEllipsis};

        cursor: pointer;

        font-size: ${typography.sp};
        color: ${theme.colors.secondary.normal};
        text-align: start;

        transition: color 0.2s;
        &:hover {
          color: ${theme.colors.accent.normal};
        }
      `,
    },

    menu: {
      main: css`
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-left: 16px;
      `,

      button: css`
        ${helpers.resetDefaults};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 30px;
        height: 30px;

        cursor: pointer;

        & > svg > path {
          transition: fill 0.2s;
          fill: ${theme.colors.secondary.normal};
        }

        &:hover svg > path {
          fill: ${theme.colors.accent.normal};
        }
      `,

      icon: css`
        width: 20px;
        height: 6px;
      `,

      options: {
        main: css`
          z-index: ${z.features.track.card.menu};
          position: absolute;
          right: 100%;

          height: 60px;
          min-width: 80px;
          overflow-y: hidden;

          margin-right: 10px;

          border-radius: 4px;
          border: 1px solid ${theme.colors.secondary.darker};
        `,

        overlay: {
          top: {
            shared: css`
              position: absolute;
              top: 0;
              left: 0;

              display: flex;
              align-items: center;
              justify-content: center;

              width: 100%;
              height: 12px;

              visibility: hidden;
              opacity: 0;
              transition: visibility 0.2s, opacity 0.2s;

              background: linear-gradient(
                180deg,
                ${theme.colors.primary.normal} 0%,
                ${theme.colors.primary.normal}00 100%
              );

              & > svg {
                height: 10px;
                width: 10px;

                transform: rotate(180deg);

                & > path {
                  fill: ${theme.colors.accent.normal};
                }
              }
            `,
            visible: css`
              visibility: visible;
              opacity: 1;
            `,
          },
          bottom: {
            shared: css`
              position: absolute;
              bottom: 0;
              left: 0;

              display: flex;
              align-items: center;
              justify-content: center;

              width: 100%;
              height: 12px;

              visibility: hidden;
              opacity: 0;
              transition: visibility 0.2s, opacity 0.2s;

              background: linear-gradient(
                180deg,
                ${theme.colors.primary.normal}00 0%,
                ${theme.colors.primary.normal} 100%
              );

              & > svg {
                height: 10px;
                width: 10px;

                & > path {
                  fill: ${theme.colors.accent.normal};
                }
              }
            `,
            visible: css`
              visibility: visible;
              opacity: 1;
            `,
          },
        },

        list: {
          main: css`
            ${helpers.hideScrollbar};

            height: 60px;
            overflow-y: scroll;

            padding: 4px 10px;
            background-color: ${theme.colors.primary.darker};
          `,

          ul: css`
            list-style: none;
          `,

          li: css``,

          button: {
            shared: css`
              ${helpers.resetDefaults};

              width: 100%;
              height: 24px;

              cursor: pointer;
              font-weight: bold;
              white-space: nowrap;
              text-align: start;

              transition: color 0.2s;
            `,
            regular: css`
              color: ${theme.colors.secondary.normal};

              &:hover {
                color: ${theme.colors.accent.normal};
              }
            `,
            danger: css`
              color: ${theme.colors.negative.normal};

              &:hover {
                color: ${theme.colors.negative.normalHover};
              }
            `,
          },
        },
      },
    },
  },
})

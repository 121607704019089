import { css, Theme } from '@emotion/react'
import { helpers, kf, mq, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: css`
      ${helpers.hideScrollbar};
      ${helpers.app.padding.full};

      display: flex;
      flex-direction: column;
      align-items: center;

      width: 740px;
      border-radius: 10px;

      overflow: hidden;
      overflow-y: scroll;

      background-color: ${theme.colors.primary.darker};

      animation: 0.4s ease 0.2s backwards ${kf.slideIn.up(50)};

      ${mq.tablet(css`
        align-items: normal;

        width: 100%;
        height: 100%;

        border-radius: 0px;
        padding-top: 0px;
      `)}

      ${mq.mobile(css`
        padding-top: 0px;
      `)}
    `,

    header: css`
      display: none;

      ${mq.tablet(css`
        display: inline-block;
      `)}
    `,

    title: css`
      margin-bottom: 50px;
    `,

    content: {
      main: css`
        width: 380px;

        ${mq.tablet(css`
          width: 100%;
        `)}
      `,

      form: css`
        margin-bottom: 32px;
      `,

      error: css`
        margin-bottom: 32px;
      `,

      login: {
        main: css`
          display: flex;
          justify-content: center;

          ${mq.mobile(css`
            flex-direction: column;
          `)}
        `,
        button: css`
          ${helpers.resetDefaults};

          font-size: ${typography.p};
          font-weight: bold;
          color: ${theme.colors.secondary.normal};

          text-align: center;
          transition: color 0.2s;

          cursor: pointer;

          &:hover {
            color: ${theme.colors.accent.normal};
          }
        `,
      },

      divider: {
        bottom: css`
          margin-bottom: 32px;
        `,
      },
    },
  },
})

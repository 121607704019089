import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // report
  ReportComplaintCard,
  reportSelectReportGetReportComplaintsLoading,
  reportSelectReportComplaints,
  reportSelectReportComplaintsCount,
  reportSelectReportComplaintsLength,
  reportSelectReportComplaintsFetched,
  reportGetReportComplaints,
  reportSelectReport,
} from '@/features'
// components
import { ContentPlaceholder, SkeletonHorizontal } from '@/components'
// icons
import { icons } from '@/assets'

// components
import { Title } from './components'
// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const reportComplaints = useAppSelector(reportSelectReportComplaints)
  const reportComplaint = reportComplaints[index]

  // if report complaint not fetched
  if (!reportComplaint) {
    return (
      <div style={style}>
        <SkeletonHorizontal
          sections={[
            {
              name: 'report-list-report-complaints-complaint',
              width: '100%',
              height: '70px',
              isStatic: false,
              isDivider: false,
            },
          ]}
        />
      </div>
    )
  }

  const {
    id: reportComplaintId,
    userId: reportComplaintAuthorUserId,
    reportId,
  } = reportComplaint

  return (
    <div css={styles().comments.main} style={style}>
      <ReportComplaintCard
        reportComplaint={reportComplaint}
        onUserNameClick={() => navigate(`${Path.User}/${reportComplaintAuthorUserId}`)}
        onClick={() =>
          navigate(
            `${Path.Reports}${Path.Report}/${reportId}${Path.Complaint}/${reportComplaintId}`,
          )
        }
      />
    </div>
  )
})

type ReportComplaintListReportProps = {}

export const ReportComplaintListReport: FC<ReportComplaintListReportProps> = () => {
  const dispatch = useAppDispatch()

  const reportGetReportComplaintsLoading = useAppSelector(
    reportSelectReportGetReportComplaintsLoading,
  )
  const reportComplaints = useAppSelector(reportSelectReportComplaints)
  const reportComplaintsCount = useAppSelector(reportSelectReportComplaintsCount)
  const reportComplaintsLength = useAppSelector(reportSelectReportComplaintsLength)
  const reportComplaintsFetched = useAppSelector(reportSelectReportComplaintsFetched)

  const report = useAppSelector(reportSelectReport)
  const { id: reportId } = report ?? {}

  const reportComplaintCardHeight = 70
  const reportComplaintCardMarginBottom = 10
  const itemHeight = reportComplaintCardHeight + reportComplaintCardMarginBottom
  const itemsToFit = 6

  const defaultContentCount = 1000
  const defaultListWidth = '100%'
  const defaultListHeight = itemsToFit * itemHeight
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  // if report is not fetched
  if (!report) {
    // return skeleton
    return (
      <div css={styles().list.main}>
        {/* title */}
        <div css={styles().list.title}>
          <Title text="Complaints" />
        </div>

        {/* skeleton */}
        <div css={styles().list.content}>
          <SkeletonHorizontal
            sections={[
              {
                name: 'report-list-report-complaints-load',
                width: '100%',
                height: `${defaultListHeight}px`,
                isStatic: false,
                isDivider: false,
              },
            ]}
          />
        </div>
      </div>
    )
  }

  const isItemLoaded = (index: number) => !!reportComplaints[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(
      reportGetReportComplaints({
        reportId: reportId || '',
        limit: itemsToFetch,
        offset: startIndex,
      }),
    )
  }

  // if report complaints are fetched but length is 0
  if (
    !reportGetReportComplaintsLoading &&
    reportComplaintsFetched &&
    reportComplaintsLength === 0
  ) {
    // return placeholder
    return (
      <div css={styles().list.main}>
        {/* title */}
        <div css={styles().list.title}>
          <Title text="Complaints" />
        </div>

        {/* placeholder */}
        <div css={styles().list.content}>
          <ContentPlaceholder
            icon={icons.CheckCircle}
            height={`${defaultListHeight}px`}
            title="Could not find any complaints"
          />
        </div>
      </div>
    )
  }

  return (
    <div css={styles().list.main}>
      {/* title */}
      <div css={styles().list.title}>
        <Title text="Complaints" />
      </div>

      {/* report complaints */}
      <div css={styles().list.content}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          itemCount={reportComplaintsCount || defaultContentCount}
          minimumBatchSize={itemsToFetch}
          threshold={itemThreshold}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className="virtualized-list"
              height={defaultListHeight}
              itemSize={itemHeight}
              itemCount={reportComplaintsCount || defaultContentCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={defaultListWidth}
              layout="vertical"
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </div>
    </div>
  )
}

ReportComplaintListReport.propTypes = {}

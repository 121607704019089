import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  message: {
    main: css`
      word-break: break-all;

      color: ${theme.colors.secondary.normal};
      font-size: ${typography.h6};
    `,
  },
})

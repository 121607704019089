import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useUserPublic } from '@/hooks'
// icon
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TrackCardUploadedProps = {
  track: EntityTrackView
  deleteDisabled: boolean
  onDelete: MouseEventHandler<HTMLButtonElement>
}

export const TrackCardUploaded: FC<TrackCardUploadedProps> = ({
  track,
  deleteDisabled,
  onDelete,
}) => {
  const theme = useTheme()
  const [coverLoadError, setCoverLoadError] = useState(false)

  const { userId, coverId, name } = track

  // track author
  const { user: trackAuthor, isLoading: isTrackAuthorLoading } = useUserPublic(
    userId || '',
  )
  const { name: authorName } = trackAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'

  const trackHasCover = !!coverId
  const source = useFile(coverId || '')

  return (
    <div css={styles(theme).track.main}>
      <div css={styles(theme).track.cover.main}>
        {trackHasCover && !coverLoadError ? (
          <img
            css={styles(theme).track.cover.image}
            onError={() => setCoverLoadError(true)}
            loading="lazy"
            width="60px"
            height="60px"
            src={source}
            alt="track album cover"
          />
        ) : (
          <icons.MusicNote css={styles(theme).track.cover.icon} />
        )}
      </div>
      <div css={styles(theme).track.description.main}>
        <span css={styles(theme).track.description.name}>{name}</span>
        <span css={styles(theme).track.description.author}>
          {isTrackAuthorLoading
            ? userLoadingPlaceholder
            : authorName || userNamePlaceholder}
        </span>
      </div>
      <div css={styles(theme).track.delete.main}>
        <button
          css={[
            styles(theme).track.delete.button.shared,
            deleteDisabled
              ? styles(theme).track.delete.button.disabled
              : styles(theme).track.delete.button.enabled,
          ]}
          onClick={(e) => onDelete(e)}
          disabled={deleteDisabled}
          type="button"
          aria-label="delete track"
        >
          <icons.Trash css={styles(theme).track.delete.button.icon} />
        </button>
      </div>
    </div>
  )
}

TrackCardUploaded.propTypes = {}

import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  badge: {
    main: css`
      display: flex;
      height: 24px;
      background-color: ${theme.colors.primary.normal};
    `,

    button: {
      shared: css`
        ${helpers.resetDefaults};

        font-size: ${typography.p};
        transition: color 0.2s;
      `,

      enabled: css`
        cursor: pointer;
        color: ${theme.colors.secondary.normal};

        &:hover {
          color: ${theme.colors.accent.normal};
        }
      `,

      disabled: css`
        cursor: not-allowed;
        color: ${theme.colors.secondary.dark};

        &:hover {
          color: ${theme.colors.secondary.dark};
        }
      `,
    },
  },
})

import useSWR from 'swr'

// libs
import { apiGetService, apiHandleError } from '@/lib'

// types
import { SWRKeys, SWRFetcherData } from './types'

const getPost = async ({ data: { postId } }: SWRFetcherData<{ postId: string }>) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve post
    const { post } = await api.postApi.getPost(postId)

    // return payload
    return { post, error: '' }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return payload
    return { post: undefined, error: message }
  }
}

const fetcher = (args: SWRFetcherData<{ postId: string }>) => getPost(args)

export const usePost = (postId: string) => {
  const shouldFetch = !!postId

  const { data, isLoading, mutate } = useSWR(
    shouldFetch ? { key: SWRKeys.UsePost, data: { postId } } : null,
    fetcher,
    { revalidateOnFocus: false },
  )

  return {
    post: data?.post,
    isLoading,
    isError: data?.error,
    mutate,
  }
}

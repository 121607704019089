import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  progress: {
    main: css`
      position: relative;

      height: 100%;
      top: -6px;
    `,
    label: css`
      display: none;
    `,
    input: (progressWidthPercent: number) => css`
      ${helpers.resetDefaults};

      width: 100.2%;
      height: 16px;

      // reset default style
      appearance: none;
      -webkit-appearance: none;
      background: transparent;
      cursor: pointer;

      // track style
      &::-webkit-slider-runnable-track {
        background: linear-gradient(
          to right,
          ${theme.colors.accent.normal} 0% ${progressWidthPercent}%,
          ${theme.colors.secondary.normal} ${progressWidthPercent}%
            ${100 - progressWidthPercent}%
        );
        height: 4px;
      }
      &::-moz-range-track {
        background: linear-gradient(
          to right,
          ${theme.colors.accent.normal} 0% ${progressWidthPercent}%,
          ${theme.colors.secondary.normal} ${progressWidthPercent}%
            ${100 - progressWidthPercent}%
        );
        height: 4px;
      }

      // thumb style
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;

        // center thumb
        // (track hight in px / 2) - (thumb height in px / 2)
        margin-top: -8px;

        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: ${theme.colors.accent.normal};
        border: 2px solid ${theme.colors.primary.normal};
      }
      &::-moz-range-thumb {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: ${theme.colors.accent.normal};
        border: 2px solid ${theme.colors.primary.normal};
      }
    `,
  },
})

/*

(scale: number) => css`
      position: absolute;
      left: 0;
      top: 6px;

      background-color: red;

      height: 100%;
      width: 100%;

      transform: scaleX(${scale});
      transform-origin: left;
    `,

*/

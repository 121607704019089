import { css, Theme } from '@emotion/react'
import { helpers, fonts, typography, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  input: {
    main: css`
      display: flex;
      flex-direction: column;
    `,

    label: css`
      display: none;
    `,

    data: {
      main: css`
        position: relative;
      `,

      shared: css`
        ${helpers.resetDefaults};

        width: 100%;
        height: 40px;

        font-family: ${fonts.primary};
        font-size: ${typography.p};
        color: ${theme.colors.secondary.normal};

        border-radius: 6px;
        padding: 8px 32px;

        transition: border 0.2s;

        &:focus {
          border: 1px solid ${theme.colors.accent.normal};
        }

        &::placeholder {
          color: ${theme.colors.secondary.darkHover};
        }

        // https://stackoverflow.com/questions/20804016/editing-input-type-search-pseudo-element-button-x
        &::-webkit-search-cancel-button {
        }

        ${mq.mobile(css`
          height: 36px;
          padding: 12px 26px;
        `)}
      `,

      empty: css`
        border: 1px solid ${theme.colors.secondary.darkHover};

        & ~ button > svg > path {
          fill: ${theme.colors.secondary.darkHover};
        }
      `,

      filled: css`
        border: 1px solid ${theme.colors.secondary.normal};

        & ~ button > svg > path {
          fill: ${theme.colors.secondary.normal};
        }
      `,

      errored: css`
        border: 1px solid ${theme.colors.negative.normal};
      `,

      withIcon: css`
        padding-right: 80px;

        ${mq.mobile(css`
          padding-right: 64px;
        `)}
      `,
    },

    button: {
      shared: css`
        ${helpers.resetDefaults};

        display: flex;
        align-items: center;

        position: absolute;
        right: 32px;
        top: 50%;
        transform: translate(0%, -50%);

        height: 30px;

        ${mq.mobile(css`
          right: 26px;
        `)}
      `,
      enabled: css`
        cursor: pointer;

        & > span {
          color: ${theme.colors.secondary.normal};
        }

        &:hover {
          & > span {
            color: ${theme.colors.accent.normal};
          }
        }
      `,
      disabled: css`
        cursor: not-allowed;

        & > span {
          color: ${theme.colors.secondary.darkHover};
        }

        &:hover {
          & > span {
            color: ${theme.colors.secondary.darkHover};
          }
        }
      `,
      text: css`
        font-size: ${typography.p};

        transition: color 0.2s;
      `,
    },
  },
})

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useUserPublic } from '@/hooks'
// icon
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TrackTileLockedProps = {
  track: EntityTrackView
  onClick: MouseEventHandler<HTMLButtonElement>
  onAuthorNameClick: MouseEventHandler<HTMLButtonElement>
}

export const TrackTileLocked: FC<TrackTileLockedProps> = ({
  track,
  onClick,
  onAuthorNameClick,
}) => {
  const theme = useTheme()

  const { name: trackName, userId } = track

  // track author
  const { user: trackAuthor, isLoading: isTrackAuthorLoading } = useUserPublic(
    userId || '',
  )
  const { name: authorName } = trackAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'

  return (
    <div css={styles(theme).track.main}>
      {/* track cover */}
      <div css={styles(theme).track.cover.main}>
        <div css={styles(theme).track.cover.overlay.main}>
          <button
            css={styles(theme).track.cover.overlay.button}
            onClick={(e) => onClick(e)}
            type="button"
            aria-label="unlock track"
          >
            <icons.Unlock />
          </button>
        </div>

        <div css={styles(theme).track.cover.lock.main}>
          <div css={styles(theme).track.cover.lock.icon}>
            <icons.Lock />
          </div>
        </div>
      </div>

      {/* track description */}
      <div css={styles(theme).track.description.main}>
        <div css={styles(theme).track.description.name}>{trackName}</div>
        <button
          css={styles(theme).track.description.author}
          onClick={(e) => onAuthorNameClick(e)}
          type="button"
          aria-label="explore track author"
        >
          {isTrackAuthorLoading
            ? userLoadingPlaceholder
            : authorName || userNamePlaceholder}
        </button>
      </div>
    </div>
  )
}

TrackTileLocked.propTypes = {}

export const musicGenres = [
  'Rock',
  'Metal',
  'Alternative',
  'Hip-Hop',
  'Rap',
  'Dance',
  'EDM',
  'Classical',
  'Country',
  'Folk',
  'Jazz',
  'Indie',
  'Industrial',
  'Instrumental',
  'World',
  'Trap',
  'R&B',
  'New Age',
  'Christian',
  'Synthwave',
  'Children Music',
  'Acoustic',
  'Bass',
  'Blues',
  'Electronic',
  'Future Bass',
  'Holiday',
  'House',
  'Latin',
  'Pop',
  'Reggae',
  'Rockabilly',
]

export const maxTrackGenresCount = 2
export const maxUserPreferredGenresCount = 3

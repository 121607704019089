import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  link: {
    main: css`
      color: ${theme.colors.secondary.light};
      font-size: ${typography.h6};
      font-weight: bold;

      cursor: pointer;
      text-decoration: none;

      transition: color 0.2s;
      display: inline-block;

      &:link,
      &:visited {
      }

      &:hover,
      &:active {
        color: ${theme.colors.accent.normal};

        & > div > div > svg > path {
          fill: ${theme.colors.accent.normal};
        }
      }
    `,
    active: css`
      &:link,
      &:visited {
        color: ${theme.colors.accent.normal};
      }

      & > div > div > svg > path {
        fill: ${theme.colors.accent.normal};
      }
    `,
    body: css`
      display: flex;
    `,
    icon: css`
      display: flex;
      width: 22px;
      align-items: center;
      justify-content: center;

      margin-right: 12px;

      & > svg {
        width: 22px;
        height: 22px;
      }

      & > svg > path {
        transition: fill 0.2s;
      }
    `,
    text: css`
      margin-top: 2px;
    `,
  },
})

import { css } from '@emotion/react'
import { typography } from '@/styles'

export const styles = () => ({
  form: {
    main: css``,
    content: {
      main: css``,
      card: css`
        margin-bottom: 32px;
      `,
      checkbox: {
        main: css`
          display: flex;
          align-items: center;
          justify-content: left;

          margin-bottom: 32px;
        `,
        label: css`
          display: flex;
          font-size: ${typography.h6};
          user-select: none;

          margin-left: 16px;
        `,
      },
      submit: css``,
    },
  },
})

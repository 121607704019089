import { css, Theme } from '@emotion/react'
import { helpers, mq, z } from '@/styles'

export const styles = (theme: Theme) => ({
  player: {
    main: css`
      ${helpers.app.player.height};
      ${helpers.app.player.width};

      position: relative;
      z-index: ${z.features.audio.player};

      display: flex;
      flex-direction: column;

      background-color: ${theme.colors.primary.darker};
    `,

    progress: {
      main: css`
        height: 4%;
      `,
    },

    panel: {
      main: css`
        ${helpers.app.padding.sides};

        display: flex;

        display: flex;
        height: 94%;
      `,

      audio: {
        main: css`
          width: 33.333%;

          display: flex;
          align-items: center;
          justify-content: flex-start;

          ${mq.tablet(css`
            & > div {
              display: none;
            }
          `)}

          ${mq.mobile(css`
            display: none;
          `)}
        `,
      },

      controls: {
        main: css`
          width: 246px;
          margin: 0 auto;

          ${mq.mobile(css`
            width: 100%;
          `)}
        `,
      },

      nav: {
        main: css`
          width: 33.333%;
          margin-left: 10px;

          ${mq.tablet(css`
            margin-left: 30px;
          `)}

          ${mq.mobile(css`
            display: none;
          `)}
        `,
      },
    },
  },
})

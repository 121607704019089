import { css, Theme } from '@emotion/react'
import { helpers, typography, z } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: css`
      flex-grow: 0;
      flex-shrink: 0;

      position: relative;

      width: 180px;
      height: 282px;

      cursor: pointer;
      overflow: hidden;
    `,

    // overlay
    overlay: {
      main: (hover: boolean) => css`
        z-index: ${z.features.post.card.overlay};

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border-radius: 4px;
        border: 1px solid ${theme.colors.accent.normal};

        transition: opacity 0.2s, border 0.2s;

        opacity: ${hover ? '1' : '0'};

        background-color: ${theme.colors.primary.normal}E4;
      `,
      avatar: {
        main: css`
          display: flex;
          align-items: center;
          justify-content: center;

          width: 80px;
          height: 80px;

          background-color: ${theme.colors.primary.darker};
          border-radius: 50%;
          overflow: hidden;

          margin-bottom: 8px;
        `,
        image: css`
          ${helpers.image};
        `,
        icon: css`
          width: 30px;
          height: 30px;

          & > path {
            fill: ${theme.colors.secondary.normal};
          }
        `,
      },

      author: {
        main: css`
          display: flex;
          flex-direction: column;

          margin-bottom: 24px;
          text-align: center;
        `,
        name: css`
          ${helpers.textOverflowEllipsis};

          width: 120px;

          font-size: ${typography.sp};
          font-weight: bold;
        `,
        username: css`
          ${helpers.textOverflowEllipsis};

          width: 120px;
          font-size: ${typography.sp};
        `,
      },

      follow: css`
        & > button {
          font-size: ${typography.sp};
        }
      `,
    },

    // header
    header: {
      main: css`
        z-index: ${z.features.post.card.header};

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 60px;

        padding: 8px 8px 18px 8px;

        background: linear-gradient(
          180deg,
          ${theme.colors.primary.normal} 0%,
          ${theme.colors.primary.normal}00 75.79%
        );
      `,
      content: {
        main: css`
          display: flex;
        `,
        avatar: {
          main: css`
            display: flex;
            align-items: center;
            justify-content: center;

            flex-shrink: 0;

            height: 30px;
            width: 30px;

            background-color: ${theme.colors.primary.darker};
            border-radius: 50%;
            overflow: hidden;

            margin-right: 8px;
          `,
          image: css`
            ${helpers.image};
          `,
          icon: css`
            width: 16px;
            height: 16px;

            & > path {
              fill: ${theme.colors.secondary.normal};
            }
          `,
        },
        author: {
          main: css`
            display: flex;
            flex-direction: column;
            justify-content: center;

            min-width: 0;
            margin-right: auto;
          `,
          name: css`
            ${helpers.resetDefaults};
            ${helpers.textOverflowEllipsis};

            cursor: pointer;

            font-size: ${typography.c};
            font-weight: bold;
            color: ${theme.colors.secondary.normal};
            text-align: left;

            margin-bottom: 2px;

            transition: color 0.2s;
            &:hover {
              color: ${theme.colors.accent.normal};
            }
          `,
          username: css`
            ${helpers.resetDefaults};
            ${helpers.textOverflowEllipsis};

            cursor: pointer;

            font-size: ${typography.c};
            color: ${theme.colors.secondary.normal};
            text-align: left;

            transition: color 0.2s;
            &:hover {
              color: ${theme.colors.accent.normal};
            }
          `,
        },
        likes: {
          main: css`
            display: flex;
            align-items: center;

            margin-left: 8px;
          `,
          icon: css`
            display: flex;

            margin-right: 4px;

            & > svg {
              width: 14px;
              height: 14px;
            }

            & > svg > path {
              fill: ${theme.colors.accent.normal};
            }
          `,
          likes: css`
            font-size: ${typography.sp};
          `,
        },
      },
    },

    // media
    media: {
      main: css`
        position: relative;

        height: 150px;
        border-radius: 4px;
      `,
      image: css`
        ${helpers.image};
        border-radius: 4px 4px 0px 0px;
      `,
      video: {
        main: css`
          ${helpers.image};
          border-radius: 4px 4px 0px 0px;
        `,
        play: css`
          position: absolute;
          top: 60%;
          left: 50%;

          transform: translate(-50%, -60%);

          & > svg {
            width: 40px;
            height: 40px;
          }

          & > svg > path {
            fill: ${theme.colors.secondary.normal};
          }

          & > svg > circle {
            stroke: ${theme.colors.secondary.normal};
          }
        `,
      },
      placeholder: css`
        height: 100%;
        background-color: ${theme.colors.primary.darker};

        & > svg {
          position: absolute;
          top: 60%;
          left: 50%;

          width: 30px;
          height: 40px;

          transform: translate(-50%, -60%);
        }

        & > svg > path {
          fill: ${theme.colors.secondary.normal};
        }
      `,
    },

    // content
    content: {
      main: css`
        height: 130px;

        overflow: hidden;

        padding: 8px 8px 0px 8px;
        border-radius: 0px 0px 4px 4px;

        background-color: ${theme.colors.primary.darker};
      `,

      description: css`
        font-size: ${typography.sp};
        color: ${theme.colors.secondary.normal};
        line-height: 120%;

        word-break: break-word;
        height: 62px;
        overflow: hidden;

        margin-bottom: 8px;
      `,

      tracks: css`
        & > div:not(:last-of-type) {
          margin-bottom: 8px;
        }
      `,
    },

    // footer
    footer: css`
      z-index: ${z.features.post.card.footer};

      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 60px;

      padding: 8px 8px 18px 8px;

      background: linear-gradient(
        180deg,
        ${theme.colors.primary.normal}00 25.21%,
        ${theme.colors.primary.normal} 100%
      );
    `,
  },
})

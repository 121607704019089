//@ts-nocheck
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Ryddm API
 * Ryddm API.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration?: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = portableFetch,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = "RequiredError";
  constructor(
    public field: string,
    msg?: string,
  ) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface BlockCommentRequest
 */
export interface BlockCommentRequest {
  /**
   *
   * @type {string}
   * @memberof BlockCommentRequest
   */
  commentId: string;
}

/**
 *
 * @export
 * @interface BlockPostRequest
 */
export interface BlockPostRequest {
  /**
   *
   * @type {string}
   * @memberof BlockPostRequest
   */
  postId: string;
}

/**
 *
 * @export
 * @interface BlockTrackRequest
 */
export interface BlockTrackRequest {
  /**
   *
   * @type {string}
   * @memberof BlockTrackRequest
   */
  trackId: string;
}

/**
 *
 * @export
 * @interface BlockUserRequest
 */
export interface BlockUserRequest {
  /**
   *
   * @type {string}
   * @memberof BlockUserRequest
   */
  userId: string;
}

/**
 *
 * @export
 * @interface CancelSubscriptionRequest
 */
export interface CancelSubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof CancelSubscriptionRequest
   */
  userId: string;
}

/**
 *
 * @export
 * @interface CancelSubscriptionResponse
 */
export interface CancelSubscriptionResponse {
  /**
   *
   * @type {EntitySubscription}
   * @memberof CancelSubscriptionResponse
   */
  subscription?: EntitySubscription;
}

/**
 *
 * @export
 * @interface CreateCommentLikeRequest
 */
export interface CreateCommentLikeRequest {
  /**
   *
   * @type {string}
   * @memberof CreateCommentLikeRequest
   */
  commentId: string;
  /**
   *
   * @type {string}
   * @memberof CreateCommentLikeRequest
   */
  postId: string;
}

/**
 *
 * @export
 * @interface CreateCommentLikeResponse
 */
export interface CreateCommentLikeResponse {
  /**
   *
   * @type {EntityLike}
   * @memberof CreateCommentLikeResponse
   */
  like?: EntityLike;
}

/**
 *
 * @export
 * @interface CreateCommentRequest
 */
export interface CreateCommentRequest {
  /**
   *
   * @type {string}
   * @memberof CreateCommentRequest
   */
  commentId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCommentRequest
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof CreateCommentRequest
   */
  postId: string;
}

/**
 *
 * @export
 * @interface CreateCommentResponse
 */
export interface CreateCommentResponse {
  /**
   *
   * @type {EntityCommentView}
   * @memberof CreateCommentResponse
   */
  comment?: EntityCommentView;
}

/**
 *
 * @export
 * @interface CreateCommentStreamRequest
 */
export interface CreateCommentStreamRequest {
  /**
   *
   * @type {string}
   * @memberof CreateCommentStreamRequest
   */
  commentId: string;
  /**
   *
   * @type {string}
   * @memberof CreateCommentStreamRequest
   */
  postId: string;
}

/**
 *
 * @export
 * @interface CreateCommentStreamResponse
 */
export interface CreateCommentStreamResponse {
  /**
   *
   * @type {EntityStream}
   * @memberof CreateCommentStreamResponse
   */
  stream?: EntityStream;
}

/**
 *
 * @export
 * @interface CreateConnectPayoutResponse
 */
export interface CreateConnectPayoutResponse {
  /**
   *
   * @type {EntityConnectPayout}
   * @memberof CreateConnectPayoutResponse
   */
  connectPayout?: EntityConnectPayout;
}

/**
 *
 * @export
 * @interface CreateCustomerPaymentMethodRequest
 */
export interface CreateCustomerPaymentMethodRequest {
  /**
   *
   * @type {boolean}
   * @memberof CreateCustomerPaymentMethodRequest
   */
  isDefault?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateCustomerPaymentMethodRequest
   */
  paymentMethodId: string;
}

/**
 *
 * @export
 * @interface CreateCustomerPaymentMethodResponse
 */
export interface CreateCustomerPaymentMethodResponse {
  /**
   *
   * @type {EntityCustomerPaymentMethod}
   * @memberof CreateCustomerPaymentMethodResponse
   */
  customerPaymentMethod?: EntityCustomerPaymentMethod;
}

/**
 *
 * @export
 * @interface CreateCustomerPaymentMethodSetupResponse
 */
export interface CreateCustomerPaymentMethodSetupResponse {
  /**
   *
   * @type {string}
   * @memberof CreateCustomerPaymentMethodSetupResponse
   */
  clientSecret?: string;
  /**
   *
   * @type {EntityCustomerPaymentMethodSetup}
   * @memberof CreateCustomerPaymentMethodSetupResponse
   */
  customerPaymentMethodSetup?: EntityCustomerPaymentMethodSetup;
}

/**
 *
 * @export
 * @interface CreateFileResponse
 */
export interface CreateFileResponse {
  /**
   *
   * @type {EntityFile}
   * @memberof CreateFileResponse
   */
  file?: EntityFile;
}

/**
 *
 * @export
 * @interface CreatePostLikeRequest
 */
export interface CreatePostLikeRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePostLikeRequest
   */
  postId: string;
}

/**
 *
 * @export
 * @interface CreatePostLikeResponse
 */
export interface CreatePostLikeResponse {
  /**
   *
   * @type {EntityLike}
   * @memberof CreatePostLikeResponse
   */
  like?: EntityLike;
}

/**
 *
 * @export
 * @interface CreatePostResponse
 */
export interface CreatePostResponse {
  /**
   *
   * @type {EntityPostView}
   * @memberof CreatePostResponse
   */
  post?: EntityPostView;
}

/**
 *
 * @export
 * @interface CreatePostStreamRequest
 */
export interface CreatePostStreamRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePostStreamRequest
   */
  postId: string;
}

/**
 *
 * @export
 * @interface CreatePostStreamResponse
 */
export interface CreatePostStreamResponse {
  /**
   *
   * @type {EntityStream}
   * @memberof CreatePostStreamResponse
   */
  stream?: EntityStream;
}

/**
 *
 * @export
 * @interface CreatePostUnlockRequest
 */
export interface CreatePostUnlockRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePostUnlockRequest
   */
  postId: string;
}

/**
 *
 * @export
 * @interface CreatePostUnlockResponse
 */
export interface CreatePostUnlockResponse {
  /**
   *
   * @type {string}
   * @memberof CreatePostUnlockResponse
   */
  clientSecret?: string;
  /**
   *
   * @type {EntityUnlock}
   * @memberof CreatePostUnlockResponse
   */
  unlock?: EntityUnlock;
}

/**
 *
 * @export
 * @interface CreateReportComplaintRequest
 */
export interface CreateReportComplaintRequest {
  /**
   *
   * @type {string}
   * @memberof CreateReportComplaintRequest
   */
  complaint?: string;
  /**
   *
   * @type {EntityReportComplaintType}
   * @memberof CreateReportComplaintRequest
   */
  complaintType: EntityReportComplaintType;
  /**
   *
   * @type {string}
   * @memberof CreateReportComplaintRequest
   */
  entityId: string;
  /**
   *
   * @type {EntityReportEntityType}
   * @memberof CreateReportComplaintRequest
   */
  entityType: EntityReportEntityType;
  /**
   *
   * @type {string}
   * @memberof CreateReportComplaintRequest
   */
  entityUserId: string;
}

/**
 *
 * @export
 * @interface CreateReportComplaintResponse
 */
export interface CreateReportComplaintResponse {
  /**
   *
   * @type {EntityReportComplaintView}
   * @memberof CreateReportComplaintResponse
   */
  reportComplaint?: EntityReportComplaintView;
}

/**
 *
 * @export
 * @interface CreateSubscriptionRequest
 */
export interface CreateSubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof CreateSubscriptionRequest
   */
  userId: string;
}

/**
 *
 * @export
 * @interface CreateSubscriptionResponse
 */
export interface CreateSubscriptionResponse {
  /**
   *
   * @type {string}
   * @memberof CreateSubscriptionResponse
   */
  clientSecret?: string;
  /**
   *
   * @type {EntitySubscription}
   * @memberof CreateSubscriptionResponse
   */
  subscription?: EntitySubscription;
}

/**
 *
 * @export
 * @interface CreateTipRequest
 */
export interface CreateTipRequest {
  /**
   *
   * @type {number}
   * @memberof CreateTipRequest
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof CreateTipRequest
   */
  artistId: string;
  /**
   *
   * @type {string}
   * @memberof CreateTipRequest
   */
  message?: string;
}

/**
 *
 * @export
 * @interface CreateTipResponse
 */
export interface CreateTipResponse {
  /**
   *
   * @type {string}
   * @memberof CreateTipResponse
   */
  clientSecret?: string;
  /**
   *
   * @type {EntityTip}
   * @memberof CreateTipResponse
   */
  tip?: EntityTip;
}

/**
 *
 * @export
 * @interface CreateTrackLikeRequest
 */
export interface CreateTrackLikeRequest {
  /**
   *
   * @type {string}
   * @memberof CreateTrackLikeRequest
   */
  trackId: string;
}

/**
 *
 * @export
 * @interface CreateTrackLikeResponse
 */
export interface CreateTrackLikeResponse {
  /**
   *
   * @type {EntityLike}
   * @memberof CreateTrackLikeResponse
   */
  like?: EntityLike;
}

/**
 *
 * @export
 * @interface CreateTrackResponse
 */
export interface CreateTrackResponse {
  /**
   *
   * @type {EntityTrackView}
   * @memberof CreateTrackResponse
   */
  track?: EntityTrackView;
}

/**
 *
 * @export
 * @interface CreateTrackStreamRequest
 */
export interface CreateTrackStreamRequest {
  /**
   *
   * @type {string}
   * @memberof CreateTrackStreamRequest
   */
  trackId: string;
}

/**
 *
 * @export
 * @interface CreateTrackStreamResponse
 */
export interface CreateTrackStreamResponse {
  /**
   *
   * @type {EntityStream}
   * @memberof CreateTrackStreamResponse
   */
  stream?: EntityStream;
}

/**
 *
 * @export
 * @interface CreateTrackUnlockRequest
 */
export interface CreateTrackUnlockRequest {
  /**
   *
   * @type {string}
   * @memberof CreateTrackUnlockRequest
   */
  trackId: string;
}

/**
 *
 * @export
 * @interface CreateTrackUnlockResponse
 */
export interface CreateTrackUnlockResponse {
  /**
   *
   * @type {string}
   * @memberof CreateTrackUnlockResponse
   */
  clientSecret?: string;
  /**
   *
   * @type {EntityUnlock}
   * @memberof CreateTrackUnlockResponse
   */
  unlock?: EntityUnlock;
}

/**
 *
 * @export
 * @interface CreateUserDeleteConfirmationResponse
 */
export interface CreateUserDeleteConfirmationResponse {
  /**
   *
   * @type {EntityUserDeleteConfirmation}
   * @memberof CreateUserDeleteConfirmationResponse
   */
  userDeleteConfirmation?: EntityUserDeleteConfirmation;
}

/**
 *
 * @export
 * @interface CreateUserFollowRequest
 */
export interface CreateUserFollowRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUserFollowRequest
   */
  userId: string;
}

/**
 *
 * @export
 * @interface CreateUserFollowResponse
 */
export interface CreateUserFollowResponse {
  /**
   *
   * @type {EntityLike}
   * @memberof CreateUserFollowResponse
   */
  like?: EntityLike;
}

/**
 *
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  authId: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  name?: string;
}

/**
 *
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
  /**
   *
   * @type {EntityUser}
   * @memberof CreateUserResponse
   */
  user?: EntityUser;
}

/**
 *
 * @export
 * @interface CreateUserStreamRequest
 */
export interface CreateUserStreamRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUserStreamRequest
   */
  userId: string;
}

/**
 *
 * @export
 * @interface CreateUserStreamResponse
 */
export interface CreateUserStreamResponse {
  /**
   *
   * @type {EntityStream}
   * @memberof CreateUserStreamResponse
   */
  stream?: EntityStream;
}

/**
 *
 * @export
 * @interface EntityComment
 */
export interface EntityComment {
  /**
   *
   * @type {string}
   * @memberof EntityComment
   */
  commentId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityComment
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof EntityComment
   */
  likesCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityComment
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof EntityComment
   */
  postId?: string;
  /**
   *
   * @type {number}
   * @memberof EntityComment
   */
  repliesCount?: number;
  /**
   *
   * @type {number}
   * @memberof EntityComment
   */
  streamsCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityComment
   */
  userId?: string;
}

/**
 *
 * @export
 * @interface EntityCommentView
 */
export interface EntityCommentView {
  /**
   *
   * @type {string}
   * @memberof EntityCommentView
   */
  commentId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityCommentView
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof EntityCommentView
   */
  likesCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityCommentView
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof EntityCommentView
   */
  postId?: string;
  /**
   *
   * @type {number}
   * @memberof EntityCommentView
   */
  repliesCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityCommentView
   */
  userId?: string;
}

/**
 *
 * @export
 * @interface EntityConnect
 */
export interface EntityConnect {
  /**
   *
   * @type {string}
   * @memberof EntityConnect
   */
  connectId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityConnect
   */
  id?: string;
  /**
   *
   * @type {EntityConnectStatus}
   * @memberof EntityConnect
   */
  status?: EntityConnectStatus;
  /**
   *
   * @type {string}
   * @memberof EntityConnect
   */
  userId?: string;
}

/**
 *
 * @export
 * @interface EntityConnectPayout
 */
export interface EntityConnectPayout {
  /**
   *
   * @type {number}
   * @memberof EntityConnectPayout
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityConnectPayout
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityConnectPayout
   */
  payoutId?: string;
  /**
   *
   * @type {EntityConnectPayoutStatus}
   * @memberof EntityConnectPayout
   */
  status?: EntityConnectPayoutStatus;
  /**
   *
   * @type {string}
   * @memberof EntityConnectPayout
   */
  userId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityConnectPayoutStatus {
  Succeeded = <any>"succeeded",
  Failed = <any>"failed",
  Incomplete = <any>"incomplete",
}

/**
 *
 * @export
 * @interface EntityConnectPayoutView
 */
export interface EntityConnectPayoutView {
  /**
   *
   * @type {number}
   * @memberof EntityConnectPayoutView
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityConnectPayoutView
   */
  id?: string;
  /**
   *
   * @type {EntityConnectPayoutStatus}
   * @memberof EntityConnectPayoutView
   */
  status?: EntityConnectPayoutStatus;
  /**
   *
   * @type {string}
   * @memberof EntityConnectPayoutView
   */
  userId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityConnectStatus {
  Restricted = <any>"restricted",
  Pending = <any>"pending",
  Enabled = <any>"enabled",
  Complete = <any>"complete",
}

/**
 *
 * @export
 * @interface EntityCustomer
 */
export interface EntityCustomer {
  /**
   *
   * @type {string}
   * @memberof EntityCustomer
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityCustomer
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityCustomer
   */
  userId?: string;
}

/**
 *
 * @export
 * @interface EntityCustomerPaymentMethod
 */
export interface EntityCustomerPaymentMethod {
  /**
   *
   * @type {string}
   * @memberof EntityCustomerPaymentMethod
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof EntityCustomerPaymentMethod
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof EntityCustomerPaymentMethod
   */
  isDefault?: boolean;
  /**
   *
   * @type {string}
   * @memberof EntityCustomerPaymentMethod
   */
  last4?: string;
  /**
   *
   * @type {string}
   * @memberof EntityCustomerPaymentMethod
   */
  paymentMethodId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityCustomerPaymentMethod
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof EntityCustomerPaymentMethod
   */
  userId?: string;
}

/**
 *
 * @export
 * @interface EntityCustomerPaymentMethodSetup
 */
export interface EntityCustomerPaymentMethodSetup {
  /**
   *
   * @type {string}
   * @memberof EntityCustomerPaymentMethodSetup
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityCustomerPaymentMethodSetup
   */
  setupIntentId?: string;
  /**
   *
   * @type {EntityCustomerPaymentMethodSetupStatus}
   * @memberof EntityCustomerPaymentMethodSetup
   */
  status?: EntityCustomerPaymentMethodSetupStatus;
  /**
   *
   * @type {string}
   * @memberof EntityCustomerPaymentMethodSetup
   */
  userId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityCustomerPaymentMethodSetupStatus {
  Succeeded = <any>"succeeded",
  Failed = <any>"failed",
  Incomplete = <any>"incomplete",
}

/**
 *
 * @export
 * @interface EntityFile
 */
export interface EntityFile {
  /**
   *
   * @type {string}
   * @memberof EntityFile
   */
  bucket?: string;
  /**
   *
   * @type {string}
   * @memberof EntityFile
   */
  extension?: string;
  /**
   *
   * @type {string}
   * @memberof EntityFile
   */
  fileType?: string;
  /**
   *
   * @type {string}
   * @memberof EntityFile
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityFile
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof EntityFile
   */
  mimeType?: string;
  /**
   *
   * @type {string}
   * @memberof EntityFile
   */
  userId?: string;
}

/**
 *
 * @export
 * @interface EntityLike
 */
export interface EntityLike {
  /**
   *
   * @type {string}
   * @memberof EntityLike
   */
  entityId?: string;
  /**
   *
   * @type {EntityLikeEntityType}
   * @memberof EntityLike
   */
  entityType?: EntityLikeEntityType;
  /**
   *
   * @type {string}
   * @memberof EntityLike
   */
  id?: string;
  /**
   * represents post id for a comment
   * @type {string}
   * @memberof EntityLike
   */
  parentEntityId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityLike
   */
  userId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityLikeEntityType {
  User = <any>"user",
  Track = <any>"track",
  Post = <any>"post",
  Comment = <any>"comment",
}

/**
 *
 * @export
 * @interface EntityNotificationConfig
 */
export interface EntityNotificationConfig {
  /**
   *
   * @type {string}
   * @memberof EntityNotificationConfig
   */
  id?: string;
  /**
   *
   * @type {EntityNotificationConfigScope}
   * @memberof EntityNotificationConfig
   */
  scope?: EntityNotificationConfigScope;
  /**
   *
   * @type {string}
   * @memberof EntityNotificationConfig
   */
  userId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityNotificationConfigScope {
  Enabled = <any>"enabled",
  Custom = <any>"custom",
  Disabled = <any>"disabled",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityNotificationEntityType {
  User = <any>"user",
  Track = <any>"track",
  Post = <any>"post",
  Comment = <any>"comment",
  Tip = <any>"tip",
  Unlock = <any>"unlock",
  Subscription = <any>"subscription",
  ConnectPayout = <any>"connect_payout",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityNotificationEvent {
  AppUserFollowCreated = <any>"app_user_follow_created",
  AppTrackCreated = <any>"app_track_created",
  AppTrackBlocked = <any>"app_track_blocked",
  AppTrackLikeCreated = <any>"app_track_like_created",
  AppPostCreated = <any>"app_post_created",
  AppPostBlocked = <any>"app_post_blocked",
  AppPostLikeCreated = <any>"app_post_like_created",
  AppCommentCreated = <any>"app_comment_created",
  AppCommentReplyCreated = <any>"app_comment_reply_created",
  AppCommentBlocked = <any>"app_comment_blocked",
  AppCommentLikeCreated = <any>"app_comment_like_created",
  AppTipPaymentSucceeded = <any>"app_tip_payment_succeeded",
  AppTipPaymentFailed = <any>"app_tip_payment_failed",
  AppTipCreated = <any>"app_tip_created",
  AppTrackUnlockPaymentSucceeded = <any>"app_track_unlock_payment_succeeded",
  AppTrackUnlockPaymentFailed = <any>"app_track_unlock_payment_failed",
  AppTrackUnlockCreated = <any>"app_track_unlock_created",
  AppPostUnlockPaymentSucceeded = <any>"app_post_unlock_payment_succeeded",
  AppPostUnlockPaymentFailed = <any>"app_post_unlock_payment_failed",
  AppPostUnlockCreated = <any>"app_post_unlock_created",
  AppSubscriptionPaymentSucceeded = <any>"app_subscription_payment_succeeded",
  AppSubscriptionPaymentFailed = <any>"app_subscription_payment_failed",
  AppSubscriptionCreated = <any>"app_subscription_created",
  AppPayoutPaid = <any>"app_payout_paid",
  AppPayoutFailed = <any>"app_payout_failed",
  EmailWelcome = <any>"email_welcome",
  EmailUserBlocked = <any>"email_user_blocked",
  EmailTrackBlocked = <any>"email_track_blocked",
  EmailPostBlocked = <any>"email_post_blocked",
  EmailCommentBlocked = <any>"email_comment_blocked",
  EmailUserDeleteConfirmation = <any>"email_user_delete_confirmation",
  EmailUserDeleted = <any>"email_user_deleted",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityNotificationType {
  Application = <any>"application",
  Email = <any>"email",
  Push = <any>"push",
}

/**
 *
 * @export
 * @interface EntityNotificationView
 */
export interface EntityNotificationView {
  /**
   *
   * @type {string}
   * @memberof EntityNotificationView
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof EntityNotificationView
   */
  entityId?: string;
  /**
   *
   * @type {EntityNotificationEntityType}
   * @memberof EntityNotificationView
   */
  entityType?: EntityNotificationEntityType;
  /**
   *
   * @type {EntityNotificationEvent}
   * @memberof EntityNotificationView
   */
  event?: EntityNotificationEvent;
  /**
   *
   * @type {string}
   * @memberof EntityNotificationView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityNotificationView
   */
  parentEntityId?: string;
  /**
   *
   * @type {number}
   * @memberof EntityNotificationView
   */
  priority?: number;
  /**
   *
   * @type {boolean}
   * @memberof EntityNotificationView
   */
  read?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof EntityNotificationView
   */
  sourceUserIds?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof EntityNotificationView
   */
  sourceUserIdsCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityNotificationView
   */
  targetUserId?: string;
  /**
   *
   * @type {EntityNotificationType}
   * @memberof EntityNotificationView
   */
  type?: EntityNotificationType;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityOnboardingStage {
  SelectRole = <any>"select_role",
  CreateProfile = <any>"create_profile",
  SelectGenres = <any>"select_genres",
  FollowArtists = <any>"follow_artists",
  UploadTracks = <any>"upload_tracks",
  EnableMonetization = <any>"enable_monetization",
  ConfigureNotifications = <any>"configure_notifications",
  AcceptTermsAndConditions = <any>"accept_terms_and_conditions",
  Finished = <any>"finished",
}

/**
 *
 * @export
 * @interface EntityPost
 */
export interface EntityPost {
  /**
   *
   * @type {number}
   * @memberof EntityPost
   */
  commentsCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityPost
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof EntityPost
   */
  likesCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityPost
   */
  mediaId?: string;
  /**
   *
   * @type {EntityPostMediaType}
   * @memberof EntityPost
   */
  mediaType?: EntityPostMediaType;
  /**
   *
   * @type {string}
   * @memberof EntityPost
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof EntityPost
   */
  streamsCount?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof EntityPost
   */
  trackIds?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof EntityPost
   */
  unlockPrice?: number;
  /**
   *
   * @type {number}
   * @memberof EntityPost
   */
  unlocksCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityPost
   */
  userId?: string;
  /**
   *
   * @type {EntityPostVisibility}
   * @memberof EntityPost
   */
  visibility?: EntityPostVisibility;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityPostMediaType {
  Image = <any>"image",
  Video = <any>"video",
}

/**
 *
 * @export
 * @interface EntityPostView
 */
export interface EntityPostView {
  /**
   *
   * @type {number}
   * @memberof EntityPostView
   */
  commentsCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityPostView
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof EntityPostView
   */
  likesCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityPostView
   */
  mediaId?: string;
  /**
   *
   * @type {EntityPostMediaType}
   * @memberof EntityPostView
   */
  mediaType?: EntityPostMediaType;
  /**
   *
   * @type {string}
   * @memberof EntityPostView
   */
  message?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EntityPostView
   */
  trackIds?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof EntityPostView
   */
  unlockPrice?: number;
  /**
   *
   * @type {number}
   * @memberof EntityPostView
   */
  unlocksCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityPostView
   */
  userId?: string;
  /**
   *
   * @type {EntityPostVisibility}
   * @memberof EntityPostView
   */
  visibility?: EntityPostVisibility;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityPostVisibility {
  Public = <any>"public",
  Subscribers = <any>"subscribers",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityReportComplaintType {
  Spam = <any>"spam",
  Fake = <any>"fake",
  Violence = <any>"violence",
  Pornography = <any>"pornography",
  ChildAbuse = <any>"child_abuse",
  Copyright = <any>"copyright",
}

/**
 *
 * @export
 * @interface EntityReportComplaintView
 */
export interface EntityReportComplaintView {
  /**
   *
   * @type {string}
   * @memberof EntityReportComplaintView
   */
  complaint?: string;
  /**
   *
   * @type {EntityReportComplaintType}
   * @memberof EntityReportComplaintView
   */
  complaintType?: EntityReportComplaintType;
  /**
   *
   * @type {string}
   * @memberof EntityReportComplaintView
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof EntityReportComplaintView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityReportComplaintView
   */
  reportId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityReportComplaintView
   */
  userId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityReportEntityType {
  Post = <any>"post",
  User = <any>"user",
  Track = <any>"track",
  Comment = <any>"comment",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityReportStatus {
  Open = <any>"open",
  Resolved = <any>"resolved",
}

/**
 *
 * @export
 * @interface EntityReportView
 */
export interface EntityReportView {
  /**
   *
   * @type {string}
   * @memberof EntityReportView
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof EntityReportView
   */
  entityId?: string;
  /**
   *
   * @type {EntityReportEntityType}
   * @memberof EntityReportView
   */
  entityType?: EntityReportEntityType;
  /**
   *
   * @type {string}
   * @memberof EntityReportView
   */
  entityUserId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityReportView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityReportView
   */
  shortId?: string;
  /**
   *
   * @type {EntityReportStatus}
   * @memberof EntityReportView
   */
  status?: EntityReportStatus;
}

/**
 *
 * @export
 * @interface EntityStream
 */
export interface EntityStream {
  /**
   *
   * @type {string}
   * @memberof EntityStream
   */
  entityId?: string;
  /**
   *
   * @type {EntityStreamEntityType}
   * @memberof EntityStream
   */
  entityType?: EntityStreamEntityType;
  /**
   *
   * @type {string}
   * @memberof EntityStream
   */
  id?: string;
  /**
   * represents post id for a comment
   * @type {string}
   * @memberof EntityStream
   */
  parentEntityId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityStream
   */
  userId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityStreamEntityType {
  User = <any>"user",
  Track = <any>"track",
  Post = <any>"post",
  Comment = <any>"comment",
}

/**
 *
 * @export
 * @interface EntitySubscription
 */
export interface EntitySubscription {
  /**
   *
   * @type {string}
   * @memberof EntitySubscription
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntitySubscription
   */
  sourceUserId?: string;
  /**
   *
   * @type {EntitySubscriptionStatus}
   * @memberof EntitySubscription
   */
  status?: EntitySubscriptionStatus;
  /**
   *
   * @type {string}
   * @memberof EntitySubscription
   */
  subscriptionId?: string;
  /**
   *
   * @type {number}
   * @memberof EntitySubscription
   */
  subscriptionPrice?: number;
  /**
   *
   * @type {string}
   * @memberof EntitySubscription
   */
  targetUserId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntitySubscriptionStatus {
  Active = <any>"active",
  CanceledAtPeriodEnd = <any>"canceled_at_period_end",
  Canceled = <any>"canceled",
  Incomplete = <any>"incomplete",
  IncompleteExpired = <any>"incomplete_expired",
}

/**
 *
 * @export
 * @interface EntitySubscriptionView
 */
export interface EntitySubscriptionView {
  /**
   *
   * @type {string}
   * @memberof EntitySubscriptionView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntitySubscriptionView
   */
  sourceUserId?: string;
  /**
   *
   * @type {EntitySubscriptionStatus}
   * @memberof EntitySubscriptionView
   */
  status?: EntitySubscriptionStatus;
  /**
   *
   * @type {number}
   * @memberof EntitySubscriptionView
   */
  subscriptionPrice?: number;
  /**
   *
   * @type {string}
   * @memberof EntitySubscriptionView
   */
  targetUserId?: string;
}

/**
 *
 * @export
 * @interface EntityTip
 */
export interface EntityTip {
  /**
   *
   * @type {number}
   * @memberof EntityTip
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityTip
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityTip
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof EntityTip
   */
  paymentIntentId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityTip
   */
  sourceUserId?: string;
  /**
   *
   * @type {EntityTipStatus}
   * @memberof EntityTip
   */
  status?: EntityTipStatus;
  /**
   *
   * @type {string}
   * @memberof EntityTip
   */
  targetUserId?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityTipStatus {
  Succeeded = <any>"succeeded",
  Failed = <any>"failed",
  Incomplete = <any>"incomplete",
}

/**
 *
 * @export
 * @interface EntityTipView
 */
export interface EntityTipView {
  /**
   *
   * @type {number}
   * @memberof EntityTipView
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityTipView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityTipView
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof EntityTipView
   */
  sourceUserId?: string;
  /**
   *
   * @type {EntityTipStatus}
   * @memberof EntityTipView
   */
  status?: EntityTipStatus;
  /**
   *
   * @type {string}
   * @memberof EntityTipView
   */
  targetUserId?: string;
}

/**
 *
 * @export
 * @interface EntityTrack
 */
export interface EntityTrack {
  /**
   *
   * @type {string}
   * @memberof EntityTrack
   */
  coverId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EntityTrack
   */
  genres?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof EntityTrack
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof EntityTrack
   */
  likesCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityTrack
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof EntityTrack
   */
  streamsCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityTrack
   */
  trackId?: string;
  /**
   *
   * @type {number}
   * @memberof EntityTrack
   */
  unlockPrice?: number;
  /**
   *
   * @type {number}
   * @memberof EntityTrack
   */
  unlocksCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityTrack
   */
  userId?: string;
  /**
   *
   * @type {EntityTrackVisibility}
   * @memberof EntityTrack
   */
  visibility?: EntityTrackVisibility;
}

/**
 *
 * @export
 * @interface EntityTrackView
 */
export interface EntityTrackView {
  /**
   *
   * @type {string}
   * @memberof EntityTrackView
   */
  coverId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EntityTrackView
   */
  genres?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof EntityTrackView
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof EntityTrackView
   */
  likesCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityTrackView
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EntityTrackView
   */
  trackId?: string;
  /**
   *
   * @type {number}
   * @memberof EntityTrackView
   */
  unlockPrice?: number;
  /**
   *
   * @type {number}
   * @memberof EntityTrackView
   */
  unlocksCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityTrackView
   */
  userId?: string;
  /**
   *
   * @type {EntityTrackVisibility}
   * @memberof EntityTrackView
   */
  visibility?: EntityTrackVisibility;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityTrackVisibility {
  Public = <any>"public",
  Subscribers = <any>"subscribers",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityTransactionCurrency {
  Usd = <any>"usd",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityTransactionStatus {
  Succeeded = <any>"succeeded",
  Failed = <any>"failed",
  Incomplete = <any>"incomplete",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityTransactionType {
  Tip = <any>"tip",
  Unlock = <any>"unlock",
  Subscription = <any>"subscription",
  Payout = <any>"payout",
}

/**
 *
 * @export
 * @interface EntityTransactionView
 */
export interface EntityTransactionView {
  /**
   *
   * @type {number}
   * @memberof EntityTransactionView
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityTransactionView
   */
  createdAt?: string;
  /**
   *
   * @type {EntityTransactionCurrency}
   * @memberof EntityTransactionView
   */
  currency?: EntityTransactionCurrency;
  /**
   *
   * @type {string}
   * @memberof EntityTransactionView
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof EntityTransactionView
   */
  feeAmount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityTransactionView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityTransactionView
   */
  sourceUserId?: string;
  /**
   *
   * @type {EntityTransactionStatus}
   * @memberof EntityTransactionView
   */
  status?: EntityTransactionStatus;
  /**
   *
   * @type {string}
   * @memberof EntityTransactionView
   */
  targetUserId?: string;
  /**
   *
   * @type {EntityTransactionType}
   * @memberof EntityTransactionView
   */
  type?: EntityTransactionType;
}

/**
 *
 * @export
 * @interface EntityUnlock
 */
export interface EntityUnlock {
  /**
   *
   * @type {string}
   * @memberof EntityUnlock
   */
  entityId?: string;
  /**
   *
   * @type {EntityUnlockEntityType}
   * @memberof EntityUnlock
   */
  entityType?: EntityUnlockEntityType;
  /**
   *
   * @type {string}
   * @memberof EntityUnlock
   */
  id?: string;
  /**
   * assigned on tracks that unlocked by unlocking a post
   * @type {string}
   * @memberof EntityUnlock
   */
  parentEntityId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUnlock
   */
  paymentIntentId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUnlock
   */
  sourceUserId?: string;
  /**
   *
   * @type {EntityUnlockStatus}
   * @memberof EntityUnlock
   */
  status?: EntityUnlockStatus;
  /**
   *
   * @type {string}
   * @memberof EntityUnlock
   */
  targetUserId?: string;
  /**
   *
   * @type {number}
   * @memberof EntityUnlock
   */
  unlockPrice?: number;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityUnlockEntityType {
  Post = <any>"post",
  Track = <any>"track",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityUnlockStatus {
  Succeeded = <any>"succeeded",
  Failed = <any>"failed",
  Incomplete = <any>"incomplete",
}

/**
 *
 * @export
 * @interface EntityUnlockView
 */
export interface EntityUnlockView {
  /**
   *
   * @type {string}
   * @memberof EntityUnlockView
   */
  entityId?: string;
  /**
   *
   * @type {EntityUnlockEntityType}
   * @memberof EntityUnlockView
   */
  entityType?: EntityUnlockEntityType;
  /**
   *
   * @type {string}
   * @memberof EntityUnlockView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUnlockView
   */
  parentEntityId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUnlockView
   */
  sourceUserId?: string;
  /**
   *
   * @type {EntityUnlockStatus}
   * @memberof EntityUnlockView
   */
  status?: EntityUnlockStatus;
  /**
   *
   * @type {string}
   * @memberof EntityUnlockView
   */
  targetUserId?: string;
  /**
   *
   * @type {number}
   * @memberof EntityUnlockView
   */
  unlockPrice?: number;
}

/**
 *
 * @export
 * @interface EntityUser
 */
export interface EntityUser {
  /**
   *
   * @type {string}
   * @memberof EntityUser
   */
  authId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUser
   */
  avatarId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUser
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof EntityUser
   */
  followersCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityUser
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUser
   */
  name?: string;
  /**
   *
   * @type {EntityOnboardingStage}
   * @memberof EntityUser
   */
  onboardingStage?: EntityOnboardingStage;
  /**
   *
   * @type {Array<string>}
   * @memberof EntityUser
   */
  preferredGenres?: Array<string>;
  /**
   *
   * @type {EntityUserRole}
   * @memberof EntityUser
   */
  role?: EntityUserRole;
  /**
   *
   * @type {number}
   * @memberof EntityUser
   */
  streamsCount?: number;
  /**
   *
   * @type {number}
   * @memberof EntityUser
   */
  subscribersCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityUser
   */
  username?: string;
}

/**
 *
 * @export
 * @interface EntityUserDeleteConfirmation
 */
export interface EntityUserDeleteConfirmation {
  /**
   *
   * @type {string}
   * @memberof EntityUserDeleteConfirmation
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUserDeleteConfirmation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUserDeleteConfirmation
   */
  userId?: string;
}

/**
 *
 * @export
 * @interface EntityUserProfileView
 */
export interface EntityUserProfileView {
  /**
   *
   * @type {string}
   * @memberof EntityUserProfileView
   */
  avatarId?: string;
  /**
   *
   * @type {boolean}
   * @memberof EntityUserProfileView
   */
  blocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof EntityUserProfileView
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof EntityUserProfileView
   */
  followersCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityUserProfileView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUserProfileView
   */
  name?: string;
  /**
   *
   * @type {EntityOnboardingStage}
   * @memberof EntityUserProfileView
   */
  onboardingStage?: EntityOnboardingStage;
  /**
   *
   * @type {Array<string>}
   * @memberof EntityUserProfileView
   */
  preferredGenres?: Array<string>;
  /**
   *
   * @type {EntityUserRole}
   * @memberof EntityUserProfileView
   */
  role?: EntityUserRole;
  /**
   *
   * @type {number}
   * @memberof EntityUserProfileView
   */
  subscribersCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityUserProfileView
   */
  username?: string;
}

/**
 *
 * @export
 * @interface EntityUserPublicView
 */
export interface EntityUserPublicView {
  /**
   *
   * @type {string}
   * @memberof EntityUserPublicView
   */
  avatarId?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUserPublicView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUserPublicView
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUserPublicView
   */
  username?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EntityUserRole {
  Fan = <any>"fan",
  Artist = <any>"artist",
  Admin = <any>"admin",
  Empty = <any>"",
}

/**
 *
 * @export
 * @interface EntityUserView
 */
export interface EntityUserView {
  /**
   *
   * @type {string}
   * @memberof EntityUserView
   */
  avatarId?: string;
  /**
   *
   * @type {number}
   * @memberof EntityUserView
   */
  followersCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityUserView
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EntityUserView
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EntityUserView
   */
  preferredGenres?: Array<string>;
  /**
   *
   * @type {EntityUserRole}
   * @memberof EntityUserView
   */
  role?: EntityUserRole;
  /**
   *
   * @type {number}
   * @memberof EntityUserView
   */
  subscribersCount?: number;
  /**
   *
   * @type {string}
   * @memberof EntityUserView
   */
  username?: string;
}

/**
 *
 * @export
 * @interface ExchangeTokenRequest
 */
export interface ExchangeTokenRequest {
  /**
   *
   * @type {string}
   * @memberof ExchangeTokenRequest
   */
  authToken: string;
}

/**
 *
 * @export
 * @interface ExchangeTokenResponse
 */
export interface ExchangeTokenResponse {
  /**
   *
   * @type {string}
   * @memberof ExchangeTokenResponse
   */
  token?: string;
}

/**
 *
 * @export
 * @interface GetAppNotificationResponse
 */
export interface GetAppNotificationResponse {
  /**
   *
   * @type {EntityNotificationView}
   * @memberof GetAppNotificationResponse
   */
  notification?: EntityNotificationView;
}

/**
 *
 * @export
 * @interface GetAppNotificationsResponse
 */
export interface GetAppNotificationsResponse {
  /**
   *
   * @type {number}
   * @memberof GetAppNotificationsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityNotificationView>}
   * @memberof GetAppNotificationsResponse
   */
  notifications?: Array<EntityNotificationView>;
}

/**
 *
 * @export
 * @interface GetAppNotificationsUnreadCountResponse
 */
export interface GetAppNotificationsUnreadCountResponse {
  /**
   *
   * @type {number}
   * @memberof GetAppNotificationsUnreadCountResponse
   */
  count?: number;
}

/**
 *
 * @export
 * @interface GetArtistPostsResponse
 */
export interface GetArtistPostsResponse {
  /**
   *
   * @type {number}
   * @memberof GetArtistPostsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityPostView>}
   * @memberof GetArtistPostsResponse
   */
  posts?: Array<EntityPostView>;
}

/**
 *
 * @export
 * @interface GetArtistTracksResponse
 */
export interface GetArtistTracksResponse {
  /**
   *
   * @type {number}
   * @memberof GetArtistTracksResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetArtistTracksResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetCommentInternalResponse
 */
export interface GetCommentInternalResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetCommentInternalResponse
   */
  blocked?: boolean;
  /**
   *
   * @type {EntityComment}
   * @memberof GetCommentInternalResponse
   */
  comment?: EntityComment;
  /**
   *
   * @type {boolean}
   * @memberof GetCommentInternalResponse
   */
  exists?: boolean;
}

/**
 *
 * @export
 * @interface GetCommentResponse
 */
export interface GetCommentResponse {
  /**
   *
   * @type {EntityCommentView}
   * @memberof GetCommentResponse
   */
  comment?: EntityCommentView;
}

/**
 *
 * @export
 * @interface GetCommentsResponse
 */
export interface GetCommentsResponse {
  /**
   *
   * @type {Array<EntityCommentView>}
   * @memberof GetCommentsResponse
   */
  comments?: Array<EntityCommentView>;
  /**
   *
   * @type {number}
   * @memberof GetCommentsResponse
   */
  count?: number;
}

/**
 *
 * @export
 * @interface GetConnectBalanceResponse
 */
export interface GetConnectBalanceResponse {
  /**
   *
   * @type {number}
   * @memberof GetConnectBalanceResponse
   */
  availableBalance?: number;
  /**
   *
   * @type {number}
   * @memberof GetConnectBalanceResponse
   */
  pendingBalance?: number;
}

/**
 *
 * @export
 * @interface GetConnectByUserIdResponse
 */
export interface GetConnectByUserIdResponse {
  /**
   *
   * @type {EntityConnect}
   * @memberof GetConnectByUserIdResponse
   */
  connect?: EntityConnect;
  /**
   *
   * @type {boolean}
   * @memberof GetConnectByUserIdResponse
   */
  exists?: boolean;
}

/**
 *
 * @export
 * @interface GetConnectLinkResponse
 */
export interface GetConnectLinkResponse {
  /**
   *
   * @type {string}
   * @memberof GetConnectLinkResponse
   */
  link?: string;
}

/**
 *
 * @export
 * @interface GetConnectLoginLinkResponse
 */
export interface GetConnectLoginLinkResponse {
  /**
   *
   * @type {string}
   * @memberof GetConnectLoginLinkResponse
   */
  link?: string;
}

/**
 *
 * @export
 * @interface GetConnectPayoutResponse
 */
export interface GetConnectPayoutResponse {
  /**
   *
   * @type {EntityConnectPayoutView}
   * @memberof GetConnectPayoutResponse
   */
  connectPayout?: EntityConnectPayoutView;
}

/**
 *
 * @export
 * @interface GetConnectReadinessResponse
 */
export interface GetConnectReadinessResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetConnectReadinessResponse
   */
  ready?: boolean;
}

/**
 *
 * @export
 * @interface GetConnectStatusResponse
 */
export interface GetConnectStatusResponse {
  /**
   *
   * @type {EntityConnectStatus}
   * @memberof GetConnectStatusResponse
   */
  status?: EntityConnectStatus;
}

/**
 *
 * @export
 * @interface GetCustomerByUserIdResponse
 */
export interface GetCustomerByUserIdResponse {
  /**
   *
   * @type {EntityCustomer}
   * @memberof GetCustomerByUserIdResponse
   */
  customer?: EntityCustomer;
  /**
   *
   * @type {boolean}
   * @memberof GetCustomerByUserIdResponse
   */
  exists?: boolean;
}

/**
 *
 * @export
 * @interface GetCustomerPaymentMethodDefaultResponse
 */
export interface GetCustomerPaymentMethodDefaultResponse {
  /**
   *
   * @type {EntityCustomerPaymentMethod}
   * @memberof GetCustomerPaymentMethodDefaultResponse
   */
  customerPaymentMethod?: EntityCustomerPaymentMethod;
}

/**
 *
 * @export
 * @interface GetCustomerPaymentMethodsResponse
 */
export interface GetCustomerPaymentMethodsResponse {
  /**
   *
   * @type {number}
   * @memberof GetCustomerPaymentMethodsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityCustomerPaymentMethod>}
   * @memberof GetCustomerPaymentMethodsResponse
   */
  customerPaymentMethods?: Array<EntityCustomerPaymentMethod>;
}

/**
 *
 * @export
 * @interface GetExclusivePostsResponse
 */
export interface GetExclusivePostsResponse {
  /**
   *
   * @type {number}
   * @memberof GetExclusivePostsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityPostView>}
   * @memberof GetExclusivePostsResponse
   */
  posts?: Array<EntityPostView>;
}

/**
 *
 * @export
 * @interface GetExclusiveTracksResponse
 */
export interface GetExclusiveTracksResponse {
  /**
   *
   * @type {number}
   * @memberof GetExclusiveTracksResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetExclusiveTracksResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetFileInternalResponse
 */
export interface GetFileInternalResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetFileInternalResponse
   */
  exists?: boolean;
  /**
   *
   * @type {EntityFile}
   * @memberof GetFileInternalResponse
   */
  file?: EntityFile;
}

/**
 *
 * @export
 * @interface GetFollowedArtistsResponse
 */
export interface GetFollowedArtistsResponse {
  /**
   *
   * @type {Array<EntityUserView>}
   * @memberof GetFollowedArtistsResponse
   */
  artists?: Array<EntityUserView>;
  /**
   *
   * @type {number}
   * @memberof GetFollowedArtistsResponse
   */
  count?: number;
}

/**
 *
 * @export
 * @interface GetFollowedUserIdsInternalResponse
 */
export interface GetFollowedUserIdsInternalResponse {
  /**
   *
   * @type {number}
   * @memberof GetFollowedUserIdsInternalResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetFollowedUserIdsInternalResponse
   */
  followedUserIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetFollowedUserIdsResponse
 */
export interface GetFollowedUserIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetFollowedUserIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetFollowedUserIdsResponse
   */
  followedUserIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetFollowingUserIdsInternalResponse
 */
export interface GetFollowingUserIdsInternalResponse {
  /**
   *
   * @type {number}
   * @memberof GetFollowingUserIdsInternalResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetFollowingUserIdsInternalResponse
   */
  followingUserIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetIncomeTransactionsResponse
 */
export interface GetIncomeTransactionsResponse {
  /**
   *
   * @type {number}
   * @memberof GetIncomeTransactionsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTransactionView>}
   * @memberof GetIncomeTransactionsResponse
   */
  transactions?: Array<EntityTransactionView>;
}

/**
 *
 * @export
 * @interface GetLatestPostsResponse
 */
export interface GetLatestPostsResponse {
  /**
   *
   * @type {number}
   * @memberof GetLatestPostsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityPostView>}
   * @memberof GetLatestPostsResponse
   */
  posts?: Array<EntityPostView>;
}

/**
 *
 * @export
 * @interface GetLatestTracksResponse
 */
export interface GetLatestTracksResponse {
  /**
   *
   * @type {number}
   * @memberof GetLatestTracksResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetLatestTracksResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetLikedCommentIdsInternalResponse
 */
export interface GetLikedCommentIdsInternalResponse {
  /**
   *
   * @type {number}
   * @memberof GetLikedCommentIdsInternalResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetLikedCommentIdsInternalResponse
   */
  likedCommentIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetLikedCommentIdsResponse
 */
export interface GetLikedCommentIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetLikedCommentIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetLikedCommentIdsResponse
   */
  likedCommentIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetLikedPostIdsInternalResponse
 */
export interface GetLikedPostIdsInternalResponse {
  /**
   *
   * @type {number}
   * @memberof GetLikedPostIdsInternalResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetLikedPostIdsInternalResponse
   */
  likedPostIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetLikedPostIdsResponse
 */
export interface GetLikedPostIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetLikedPostIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetLikedPostIdsResponse
   */
  likedPostIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetLikedPostsResponse
 */
export interface GetLikedPostsResponse {
  /**
   *
   * @type {number}
   * @memberof GetLikedPostsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityPostView>}
   * @memberof GetLikedPostsResponse
   */
  posts?: Array<EntityPostView>;
}

/**
 *
 * @export
 * @interface GetLikedTrackIdsInternalResponse
 */
export interface GetLikedTrackIdsInternalResponse {
  /**
   *
   * @type {number}
   * @memberof GetLikedTrackIdsInternalResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetLikedTrackIdsInternalResponse
   */
  likedTrackIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetLikedTrackIdsResponse
 */
export interface GetLikedTrackIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetLikedTrackIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetLikedTrackIdsResponse
   */
  likedTrackIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetLikedTracksResponse
 */
export interface GetLikedTracksResponse {
  /**
   *
   * @type {number}
   * @memberof GetLikedTracksResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetLikedTracksResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetNotificationConfigResponse
 */
export interface GetNotificationConfigResponse {
  /**
   *
   * @type {EntityNotificationConfig}
   * @memberof GetNotificationConfigResponse
   */
  notificationConfig?: EntityNotificationConfig;
}

/**
 *
 * @export
 * @interface GetOutgoingTransactionsResponse
 */
export interface GetOutgoingTransactionsResponse {
  /**
   *
   * @type {number}
   * @memberof GetOutgoingTransactionsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTransactionView>}
   * @memberof GetOutgoingTransactionsResponse
   */
  transactions?: Array<EntityTransactionView>;
}

/**
 *
 * @export
 * @interface GetPopularArtistsResponse
 */
export interface GetPopularArtistsResponse {
  /**
   *
   * @type {Array<EntityUserView>}
   * @memberof GetPopularArtistsResponse
   */
  artists?: Array<EntityUserView>;
  /**
   *
   * @type {number}
   * @memberof GetPopularArtistsResponse
   */
  count?: number;
}

/**
 *
 * @export
 * @interface GetPopularPostsResponse
 */
export interface GetPopularPostsResponse {
  /**
   *
   * @type {number}
   * @memberof GetPopularPostsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityPostView>}
   * @memberof GetPopularPostsResponse
   */
  posts?: Array<EntityPostView>;
}

/**
 *
 * @export
 * @interface GetPopularTracksResponse
 */
export interface GetPopularTracksResponse {
  /**
   *
   * @type {number}
   * @memberof GetPopularTracksResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetPopularTracksResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetPostInternalResponse
 */
export interface GetPostInternalResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetPostInternalResponse
   */
  blocked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetPostInternalResponse
   */
  exists?: boolean;
  /**
   *
   * @type {EntityPost}
   * @memberof GetPostInternalResponse
   */
  post?: EntityPost;
}

/**
 *
 * @export
 * @interface GetPostResponse
 */
export interface GetPostResponse {
  /**
   *
   * @type {EntityPostView}
   * @memberof GetPostResponse
   */
  post?: EntityPostView;
}

/**
 *
 * @export
 * @interface GetPublicArtistsResponse
 */
export interface GetPublicArtistsResponse {
  /**
   *
   * @type {Array<EntityUserView>}
   * @memberof GetPublicArtistsResponse
   */
  artists?: Array<EntityUserView>;
  /**
   *
   * @type {number}
   * @memberof GetPublicArtistsResponse
   */
  count?: number;
}

/**
 *
 * @export
 * @interface GetPublicPostsResponse
 */
export interface GetPublicPostsResponse {
  /**
   *
   * @type {number}
   * @memberof GetPublicPostsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityPostView>}
   * @memberof GetPublicPostsResponse
   */
  posts?: Array<EntityPostView>;
}

/**
 *
 * @export
 * @interface GetPublicTracksByIdsResponse
 */
export interface GetPublicTracksByIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetPublicTracksByIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetPublicTracksByIdsResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetPublicTracksResponse
 */
export interface GetPublicTracksResponse {
  /**
   *
   * @type {number}
   * @memberof GetPublicTracksResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetPublicTracksResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetPublicUserResponse
 */
export interface GetPublicUserResponse {
  /**
   *
   * @type {EntityUserPublicView}
   * @memberof GetPublicUserResponse
   */
  user?: EntityUserPublicView;
}

/**
 *
 * @export
 * @interface GetRecommendedArtistsResponse
 */
export interface GetRecommendedArtistsResponse {
  /**
   *
   * @type {Array<EntityUserView>}
   * @memberof GetRecommendedArtistsResponse
   */
  artists?: Array<EntityUserView>;
  /**
   *
   * @type {number}
   * @memberof GetRecommendedArtistsResponse
   */
  count?: number;
}

/**
 *
 * @export
 * @interface GetRecommendedPostsResponse
 */
export interface GetRecommendedPostsResponse {
  /**
   *
   * @type {number}
   * @memberof GetRecommendedPostsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityPostView>}
   * @memberof GetRecommendedPostsResponse
   */
  posts?: Array<EntityPostView>;
}

/**
 *
 * @export
 * @interface GetRecommendedTracksResponse
 */
export interface GetRecommendedTracksResponse {
  /**
   *
   * @type {number}
   * @memberof GetRecommendedTracksResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetRecommendedTracksResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetRepliesResponse
 */
export interface GetRepliesResponse {
  /**
   *
   * @type {Array<EntityCommentView>}
   * @memberof GetRepliesResponse
   */
  comments?: Array<EntityCommentView>;
  /**
   *
   * @type {number}
   * @memberof GetRepliesResponse
   */
  count?: number;
}

/**
 *
 * @export
 * @interface GetReportComplaintResponse
 */
export interface GetReportComplaintResponse {
  /**
   *
   * @type {EntityReportComplaintView}
   * @memberof GetReportComplaintResponse
   */
  reportComplaint?: EntityReportComplaintView;
}

/**
 *
 * @export
 * @interface GetReportComplaintsResponse
 */
export interface GetReportComplaintsResponse {
  /**
   *
   * @type {number}
   * @memberof GetReportComplaintsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityReportComplaintView>}
   * @memberof GetReportComplaintsResponse
   */
  reportComplaints?: Array<EntityReportComplaintView>;
}

/**
 *
 * @export
 * @interface GetReportResponse
 */
export interface GetReportResponse {
  /**
   *
   * @type {EntityReportView}
   * @memberof GetReportResponse
   */
  report?: EntityReportView;
}

/**
 *
 * @export
 * @interface GetReportsResponse
 */
export interface GetReportsResponse {
  /**
   *
   * @type {number}
   * @memberof GetReportsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityReportView>}
   * @memberof GetReportsResponse
   */
  reports?: Array<EntityReportView>;
}

/**
 *
 * @export
 * @interface GetSubscribedArtistsResponse
 */
export interface GetSubscribedArtistsResponse {
  /**
   *
   * @type {Array<EntityUserView>}
   * @memberof GetSubscribedArtistsResponse
   */
  artists?: Array<EntityUserView>;
  /**
   *
   * @type {number}
   * @memberof GetSubscribedArtistsResponse
   */
  count?: number;
}

/**
 *
 * @export
 * @interface GetSubscribedAtPeriodEndUserIdsResponse
 */
export interface GetSubscribedAtPeriodEndUserIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetSubscribedAtPeriodEndUserIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSubscribedAtPeriodEndUserIdsResponse
   */
  subscribedAtPeriodEndUserIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetSubscribedUserIdsInternalResponse
 */
export interface GetSubscribedUserIdsInternalResponse {
  /**
   *
   * @type {number}
   * @memberof GetSubscribedUserIdsInternalResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSubscribedUserIdsInternalResponse
   */
  subscribedUserIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetSubscribedUserIdsResponse
 */
export interface GetSubscribedUserIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetSubscribedUserIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSubscribedUserIdsResponse
   */
  subscribedUserIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetSubscribingUserIdsInternalResponse
 */
export interface GetSubscribingUserIdsInternalResponse {
  /**
   *
   * @type {number}
   * @memberof GetSubscribingUserIdsInternalResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSubscribingUserIdsInternalResponse
   */
  subscribingUserIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetSubscriptionResponse
 */
export interface GetSubscriptionResponse {
  /**
   *
   * @type {EntitySubscriptionView}
   * @memberof GetSubscriptionResponse
   */
  subscription?: EntitySubscriptionView;
}

/**
 *
 * @export
 * @interface GetTipResponse
 */
export interface GetTipResponse {
  /**
   *
   * @type {EntityTipView}
   * @memberof GetTipResponse
   */
  tip?: EntityTipView;
}

/**
 *
 * @export
 * @interface GetTrackInternalResponse
 */
export interface GetTrackInternalResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetTrackInternalResponse
   */
  blocked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetTrackInternalResponse
   */
  exists?: boolean;
  /**
   *
   * @type {EntityTrack}
   * @memberof GetTrackInternalResponse
   */
  track?: EntityTrack;
}

/**
 *
 * @export
 * @interface GetTrackResponse
 */
export interface GetTrackResponse {
  /**
   *
   * @type {EntityTrackView}
   * @memberof GetTrackResponse
   */
  track?: EntityTrackView;
}

/**
 *
 * @export
 * @interface GetTracksAuthorValidResponse
 */
export interface GetTracksAuthorValidResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetTracksAuthorValidResponse
   */
  valid?: boolean;
}

/**
 *
 * @export
 * @interface GetTracksByIdsResponse
 */
export interface GetTracksByIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetTracksByIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetTracksByIdsResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetTrendingPostsResponse
 */
export interface GetTrendingPostsResponse {
  /**
   *
   * @type {number}
   * @memberof GetTrendingPostsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityPostView>}
   * @memberof GetTrendingPostsResponse
   */
  posts?: Array<EntityPostView>;
}

/**
 *
 * @export
 * @interface GetTrendingTracksResponse
 */
export interface GetTrendingTracksResponse {
  /**
   *
   * @type {number}
   * @memberof GetTrendingTracksResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetTrendingTracksResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetUnlockResponse
 */
export interface GetUnlockResponse {
  /**
   *
   * @type {EntityUnlockView}
   * @memberof GetUnlockResponse
   */
  unlock?: EntityUnlockView;
}

/**
 *
 * @export
 * @interface GetUnlockedPostIdsInternalResponse
 */
export interface GetUnlockedPostIdsInternalResponse {
  /**
   *
   * @type {number}
   * @memberof GetUnlockedPostIdsInternalResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetUnlockedPostIdsInternalResponse
   */
  unlockedPostIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetUnlockedPostIdsResponse
 */
export interface GetUnlockedPostIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetUnlockedPostIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetUnlockedPostIdsResponse
   */
  unlockedPostIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetUnlockedPostsResponse
 */
export interface GetUnlockedPostsResponse {
  /**
   *
   * @type {number}
   * @memberof GetUnlockedPostsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityPostView>}
   * @memberof GetUnlockedPostsResponse
   */
  posts?: Array<EntityPostView>;
}

/**
 *
 * @export
 * @interface GetUnlockedTrackIdsInternalResponse
 */
export interface GetUnlockedTrackIdsInternalResponse {
  /**
   *
   * @type {number}
   * @memberof GetUnlockedTrackIdsInternalResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetUnlockedTrackIdsInternalResponse
   */
  unlockedTrackIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetUnlockedTrackIdsResponse
 */
export interface GetUnlockedTrackIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetUnlockedTrackIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetUnlockedTrackIdsResponse
   */
  unlockedTrackIds?: Array<string>;
}

/**
 *
 * @export
 * @interface GetUnlockedTracksResponse
 */
export interface GetUnlockedTracksResponse {
  /**
   *
   * @type {number}
   * @memberof GetUnlockedTracksResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityTrackView>}
   * @memberof GetUnlockedTracksResponse
   */
  tracks?: Array<EntityTrackView>;
}

/**
 *
 * @export
 * @interface GetUserByAuthIdResponse
 */
export interface GetUserByAuthIdResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetUserByAuthIdResponse
   */
  blocked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetUserByAuthIdResponse
   */
  exists?: boolean;
  /**
   *
   * @type {EntityUser}
   * @memberof GetUserByAuthIdResponse
   */
  user?: EntityUser;
}

/**
 *
 * @export
 * @interface GetUserByUserIdResponse
 */
export interface GetUserByUserIdResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetUserByUserIdResponse
   */
  blocked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetUserByUserIdResponse
   */
  exists?: boolean;
  /**
   *
   * @type {EntityUser}
   * @memberof GetUserByUserIdResponse
   */
  user?: EntityUser;
}

/**
 *
 * @export
 * @interface GetUserPostsCountResponse
 */
export interface GetUserPostsCountResponse {
  /**
   *
   * @type {number}
   * @memberof GetUserPostsCountResponse
   */
  count?: number;
}

/**
 *
 * @export
 * @interface GetUserProfileResponse
 */
export interface GetUserProfileResponse {
  /**
   *
   * @type {EntityUserProfileView}
   * @memberof GetUserProfileResponse
   */
  user?: EntityUserProfileView;
}

/**
 *
 * @export
 * @interface GetUserProfileUsernameAvailableResponse
 */
export interface GetUserProfileUsernameAvailableResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetUserProfileUsernameAvailableResponse
   */
  usernameAvailable?: boolean;
}

/**
 *
 * @export
 * @interface GetUserResponse
 */
export interface GetUserResponse {
  /**
   *
   * @type {EntityUserView}
   * @memberof GetUserResponse
   */
  user?: EntityUserView;
}

/**
 *
 * @export
 * @interface GetUserSubscriptionPriceResponse
 */
export interface GetUserSubscriptionPriceResponse {
  /**
   *
   * @type {number}
   * @memberof GetUserSubscriptionPriceResponse
   */
  subscriptionPrice?: number;
}

/**
 *
 * @export
 * @interface GetUserTracksCountResponse
 */
export interface GetUserTracksCountResponse {
  /**
   *
   * @type {number}
   * @memberof GetUserTracksCountResponse
   */
  count?: number;
}

/**
 *
 * @export
 * @interface GetUsersByIdsResponse
 */
export interface GetUsersByIdsResponse {
  /**
   *
   * @type {number}
   * @memberof GetUsersByIdsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<EntityUserView>}
   * @memberof GetUsersByIdsResponse
   */
  users?: Array<EntityUserView>;
}

/**
 *
 * @export
 * @interface ReadAppNotificationRequest
 */
export interface ReadAppNotificationRequest {
  /**
   *
   * @type {string}
   * @memberof ReadAppNotificationRequest
   */
  notificationId: string;
}

/**
 *
 * @export
 * @interface RefreshTokenResponse
 */
export interface RefreshTokenResponse {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenResponse
   */
  token?: string;
}

/**
 *
 * @export
 * @interface RenewSubscriptionRequest
 */
export interface RenewSubscriptionRequest {
  /**
   *
   * @type {string}
   * @memberof RenewSubscriptionRequest
   */
  userId: string;
}

/**
 *
 * @export
 * @interface RenewSubscriptionResponse
 */
export interface RenewSubscriptionResponse {
  /**
   *
   * @type {EntitySubscription}
   * @memberof RenewSubscriptionResponse
   */
  subscription?: EntitySubscription;
}

/**
 *
 * @export
 * @interface ResolveReportRequest
 */
export interface ResolveReportRequest {
  /**
   *
   * @type {string}
   * @memberof ResolveReportRequest
   */
  reportId: string;
}

/**
 *
 * @export
 * @interface ResolveReportResponse
 */
export interface ResolveReportResponse {
  /**
   *
   * @type {EntityReportView}
   * @memberof ResolveReportResponse
   */
  report?: EntityReportView;
}

/**
 *
 * @export
 * @interface Response
 */
export interface Response {
  /**
   *
   * @type {string}
   * @memberof Response
   */
  error?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ServiceConnectLinkType {
  Onboarding = <any>"account_onboarding",
  Update = <any>"account_update",
}

/**
 *
 * @export
 * @interface SetCustomerPaymentMethodDefaultRequest
 */
export interface SetCustomerPaymentMethodDefaultRequest {
  /**
   *
   * @type {string}
   * @memberof SetCustomerPaymentMethodDefaultRequest
   */
  customerPaymentMethodId: string;
}

/**
 *
 * @export
 * @interface SetCustomerPaymentMethodDefaultResponse
 */
export interface SetCustomerPaymentMethodDefaultResponse {
  /**
   *
   * @type {EntityCustomerPaymentMethod}
   * @memberof SetCustomerPaymentMethodDefaultResponse
   */
  customerPaymentMethod?: EntityCustomerPaymentMethod;
}

/**
 *
 * @export
 * @interface UpdateCommentRequest
 */
export interface UpdateCommentRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateCommentRequest
   */
  commentId: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentRequest
   */
  message: string;
}

/**
 *
 * @export
 * @interface UpdateCommentResponse
 */
export interface UpdateCommentResponse {
  /**
   *
   * @type {EntityCommentView}
   * @memberof UpdateCommentResponse
   */
  comment?: EntityCommentView;
}

/**
 *
 * @export
 * @interface UpdateNotificationConfigRequest
 */
export interface UpdateNotificationConfigRequest {
  /**
   *
   * @type {EntityNotificationConfigScope}
   * @memberof UpdateNotificationConfigRequest
   */
  scope: EntityNotificationConfigScope;
}

/**
 *
 * @export
 * @interface UpdateNotificationConfigResponse
 */
export interface UpdateNotificationConfigResponse {
  /**
   *
   * @type {EntityNotificationConfig}
   * @memberof UpdateNotificationConfigResponse
   */
  notificationConfig?: EntityNotificationConfig;
}

/**
 *
 * @export
 * @interface UpdatePostResponse
 */
export interface UpdatePostResponse {
  /**
   *
   * @type {EntityPostView}
   * @memberof UpdatePostResponse
   */
  post?: EntityPostView;
}

/**
 *
 * @export
 * @interface UpdateTrackResponse
 */
export interface UpdateTrackResponse {
  /**
   *
   * @type {EntityTrackView}
   * @memberof UpdateTrackResponse
   */
  track?: EntityTrackView;
}

/**
 *
 * @export
 * @interface UpdateUserProfileAvatarResponse
 */
export interface UpdateUserProfileAvatarResponse {
  /**
   *
   * @type {EntityUserProfileView}
   * @memberof UpdateUserProfileAvatarResponse
   */
  user?: EntityUserProfileView;
}

/**
 *
 * @export
 * @interface UpdateUserProfileOnboardingStageRequest
 */
export interface UpdateUserProfileOnboardingStageRequest {
  /**
   *
   * @type {EntityOnboardingStage}
   * @memberof UpdateUserProfileOnboardingStageRequest
   */
  onboardingStage: EntityOnboardingStage;
}

/**
 *
 * @export
 * @interface UpdateUserProfileOnboardingStageResponse
 */
export interface UpdateUserProfileOnboardingStageResponse {
  /**
   *
   * @type {EntityUserProfileView}
   * @memberof UpdateUserProfileOnboardingStageResponse
   */
  user?: EntityUserProfileView;
}

/**
 *
 * @export
 * @interface UpdateUserProfileRequest
 */
export interface UpdateUserProfileRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileRequest
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateUserProfileRequest
   */
  preferredGenres?: Array<string>;
}

/**
 *
 * @export
 * @interface UpdateUserProfileResponse
 */
export interface UpdateUserProfileResponse {
  /**
   *
   * @type {EntityUserProfileView}
   * @memberof UpdateUserProfileResponse
   */
  user?: EntityUserProfileView;
}

/**
 *
 * @export
 * @interface UpdateUserProfileRoleRequest
 */
export interface UpdateUserProfileRoleRequest {
  /**
   *
   * @type {EntityUserRole}
   * @memberof UpdateUserProfileRoleRequest
   */
  role: EntityUserRole;
}

/**
 *
 * @export
 * @interface UpdateUserProfileRoleResponse
 */
export interface UpdateUserProfileRoleResponse {
  /**
   *
   * @type {EntityUserProfileView}
   * @memberof UpdateUserProfileRoleResponse
   */
  user?: EntityUserProfileView;
}

/**
 *
 * @export
 * @interface UpdateUserProfileUsernameRequest
 */
export interface UpdateUserProfileUsernameRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileUsernameRequest
   */
  username: string;
}

/**
 *
 * @export
 * @interface UpdateUserProfileUsernameResponse
 */
export interface UpdateUserProfileUsernameResponse {
  /**
   *
   * @type {EntityUserProfileView}
   * @memberof UpdateUserProfileUsernameResponse
   */
  user?: EntityUserProfileView;
}

/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Exchanges auth provider token with auth service token.
     * @summary Exchanges token.
     * @param {ExchangeTokenRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exchangeToken(fields: ExchangeTokenRequest, options: any = {}): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling exchangeToken.",
        );
      }
      const localVarPath = `/auth/token/exchange`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"ExchangeTokenRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Signs new auth service token with extended period of validity.
     * @summary Refreshes token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(options: any = {}): FetchArgs {
      const localVarPath = `/auth/token/refresh`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Exchanges auth provider token with auth service token.
     * @summary Exchanges token.
     * @param {ExchangeTokenRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exchangeToken(
      fields: ExchangeTokenRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ExchangeTokenResponse> {
      const localVarFetchArgs = AuthApiFetchParamCreator(
        configuration,
      ).exchangeToken(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Signs new auth service token with extended period of validity.
     * @summary Refreshes token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<RefreshTokenResponse> {
      const localVarFetchArgs =
        AuthApiFetchParamCreator(configuration).refreshToken(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Exchanges auth provider token with auth service token.
     * @summary Exchanges token.
     * @param {ExchangeTokenRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exchangeToken(fields: ExchangeTokenRequest, options?: any) {
      return AuthApiFp(configuration).exchangeToken(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Signs new auth service token with extended period of validity.
     * @summary Refreshes token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(options?: any) {
      return AuthApiFp(configuration).refreshToken(options)(fetch, basePath);
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   * Exchanges auth provider token with auth service token.
   * @summary Exchanges token.
   * @param {ExchangeTokenRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public exchangeToken(fields: ExchangeTokenRequest, options?: any) {
    return AuthApiFp(this.configuration).exchangeToken(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Signs new auth service token with extended period of validity.
   * @summary Refreshes token.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public refreshToken(options?: any) {
    return AuthApiFp(this.configuration).refreshToken(options)(
      this.fetch,
      this.basePath,
    );
  }
}

/**
 * CommentApi - fetch parameter creator
 * @export
 */
export const CommentApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Blocks comment record.
     * @summary Blocks comment.
     * @param {BlockCommentRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockComment(fields: BlockCommentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling blockComment.",
        );
      }
      const localVarPath = `/comment/admin/comment/block`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"BlockCommentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates comment.
     * @summary Creates comment.
     * @param {CreateCommentRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComment(fields: CreateCommentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createComment.",
        );
      }
      const localVarPath = `/comment`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateCommentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes comment.
     * @summary Deletes comment.
     * @param {string} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteComment(commentId: string, options: any = {}): FetchArgs {
      // verify required parameter 'commentId' is not null or undefined
      if (commentId === null || commentId === undefined) {
        throw new RequiredError(
          "commentId",
          "Required parameter commentId was null or undefined when calling deleteComment.",
        );
      }
      const localVarPath = `/comment`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: "DELETE" },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (commentId !== undefined) {
        localVarQueryParameter["commentId"] = commentId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves comment record by comment id.
     * @summary Retrieves comment.
     * @param {string} id comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getComment(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getComment.",
        );
      }
      const localVarPath = `/comment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves comment record by comment id.
     * @summary Retrieves comment.
     * @param {string} id comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentInternal(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getCommentInternal.",
        );
      }
      const localVarPath = `/comment/internal/comment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves comments.
     * @summary Retrieves comments.
     * @param {string} postId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getComments(
      postId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'postId' is not null or undefined
      if (postId === null || postId === undefined) {
        throw new RequiredError(
          "postId",
          "Required parameter postId was null or undefined when calling getComments.",
        );
      }
      const localVarPath = `/comment`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (postId !== undefined) {
        localVarQueryParameter["postId"] = postId;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves comment replies.
     * @summary Retrieves comment replies.
     * @param {string} commentId
     * @param {string} postId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplies(
      commentId: string,
      postId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'commentId' is not null or undefined
      if (commentId === null || commentId === undefined) {
        throw new RequiredError(
          "commentId",
          "Required parameter commentId was null or undefined when calling getReplies.",
        );
      }
      // verify required parameter 'postId' is not null or undefined
      if (postId === null || postId === undefined) {
        throw new RequiredError(
          "postId",
          "Required parameter postId was null or undefined when calling getReplies.",
        );
      }
      const localVarPath = `/comment/replies`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (commentId !== undefined) {
        localVarQueryParameter["commentId"] = commentId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (postId !== undefined) {
        localVarQueryParameter["postId"] = postId;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates comment.
     * @summary Updates comment.
     * @param {UpdateCommentRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateComment(fields: UpdateCommentRequest, options: any = {}): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling updateComment.",
        );
      }
      const localVarPath = `/comment`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"UpdateCommentRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Blocks comment record.
     * @summary Blocks comment.
     * @param {BlockCommentRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockComment(
      fields: BlockCommentRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CommentApiFetchParamCreator(
        configuration,
      ).blockComment(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates comment.
     * @summary Creates comment.
     * @param {CreateCommentRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComment(
      fields: CreateCommentRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateCommentResponse> {
      const localVarFetchArgs = CommentApiFetchParamCreator(
        configuration,
      ).createComment(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes comment.
     * @summary Deletes comment.
     * @param {string} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteComment(
      commentId: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CommentApiFetchParamCreator(
        configuration,
      ).deleteComment(commentId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves comment record by comment id.
     * @summary Retrieves comment.
     * @param {string} id comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getComment(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetCommentResponse> {
      const localVarFetchArgs = CommentApiFetchParamCreator(
        configuration,
      ).getComment(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves comment record by comment id.
     * @summary Retrieves comment.
     * @param {string} id comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentInternal(
      id: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetCommentInternalResponse> {
      const localVarFetchArgs = CommentApiFetchParamCreator(
        configuration,
      ).getCommentInternal(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves comments.
     * @summary Retrieves comments.
     * @param {string} postId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getComments(
      postId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetCommentsResponse> {
      const localVarFetchArgs = CommentApiFetchParamCreator(
        configuration,
      ).getComments(postId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves comment replies.
     * @summary Retrieves comment replies.
     * @param {string} commentId
     * @param {string} postId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplies(
      commentId: string,
      postId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetRepliesResponse> {
      const localVarFetchArgs = CommentApiFetchParamCreator(
        configuration,
      ).getReplies(commentId, postId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates comment.
     * @summary Updates comment.
     * @param {UpdateCommentRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateComment(
      fields: UpdateCommentRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<UpdateCommentResponse> {
      const localVarFetchArgs = CommentApiFetchParamCreator(
        configuration,
      ).updateComment(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Blocks comment record.
     * @summary Blocks comment.
     * @param {BlockCommentRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockComment(fields: BlockCommentRequest, options?: any) {
      return CommentApiFp(configuration).blockComment(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates comment.
     * @summary Creates comment.
     * @param {CreateCommentRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComment(fields: CreateCommentRequest, options?: any) {
      return CommentApiFp(configuration).createComment(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Deletes comment.
     * @summary Deletes comment.
     * @param {string} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteComment(commentId: string, options?: any) {
      return CommentApiFp(configuration).deleteComment(commentId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves comment record by comment id.
     * @summary Retrieves comment.
     * @param {string} id comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getComment(id: string, options?: any) {
      return CommentApiFp(configuration).getComment(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves comment record by comment id.
     * @summary Retrieves comment.
     * @param {string} id comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentInternal(id: string, options?: any) {
      return CommentApiFp(configuration).getCommentInternal(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves comments.
     * @summary Retrieves comments.
     * @param {string} postId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getComments(
      postId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return CommentApiFp(configuration).getComments(
        postId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves comment replies.
     * @summary Retrieves comment replies.
     * @param {string} commentId
     * @param {string} postId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplies(
      commentId: string,
      postId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return CommentApiFp(configuration).getReplies(
        commentId,
        postId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Updates comment.
     * @summary Updates comment.
     * @param {UpdateCommentRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateComment(fields: UpdateCommentRequest, options?: any) {
      return CommentApiFp(configuration).updateComment(fields, options)(
        fetch,
        basePath,
      );
    },
  };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
  /**
   * Blocks comment record.
   * @summary Blocks comment.
   * @param {BlockCommentRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public blockComment(fields: BlockCommentRequest, options?: any) {
    return CommentApiFp(this.configuration).blockComment(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates comment.
   * @summary Creates comment.
   * @param {CreateCommentRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public createComment(fields: CreateCommentRequest, options?: any) {
    return CommentApiFp(this.configuration).createComment(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Deletes comment.
   * @summary Deletes comment.
   * @param {string} commentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public deleteComment(commentId: string, options?: any) {
    return CommentApiFp(this.configuration).deleteComment(commentId, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves comment record by comment id.
   * @summary Retrieves comment.
   * @param {string} id comment id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public getComment(id: string, options?: any) {
    return CommentApiFp(this.configuration).getComment(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves comment record by comment id.
   * @summary Retrieves comment.
   * @param {string} id comment id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public getCommentInternal(id: string, options?: any) {
    return CommentApiFp(this.configuration).getCommentInternal(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves comments.
   * @summary Retrieves comments.
   * @param {string} postId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public getComments(
    postId: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return CommentApiFp(this.configuration).getComments(
      postId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves comment replies.
   * @summary Retrieves comment replies.
   * @param {string} commentId
   * @param {string} postId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public getReplies(
    commentId: string,
    postId: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return CommentApiFp(this.configuration).getReplies(
      commentId,
      postId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Updates comment.
   * @summary Updates comment.
   * @param {UpdateCommentRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public updateComment(fields: UpdateCommentRequest, options?: any) {
    return CommentApiFp(this.configuration).updateComment(fields, options)(
      this.fetch,
      this.basePath,
    );
  }
}

/**
 * ConnectApi - fetch parameter creator
 * @export
 */
export const ConnectApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates connect payout.
     * @summary Creates connect payout.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createConnectPayout(options: any = {}): FetchArgs {
      const localVarPath = `/connect/artist/connect/payout`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves connect account balance.
     * @summary Retrieves connect balance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectBalance(options: any = {}): FetchArgs {
      const localVarPath = `/connect/artist/connect/balance`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves connect account by user id.
     * @summary Retrieves connect by user id.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectByUserId(userId: string, options: any = {}): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getConnectByUserId.",
        );
      }
      const localVarPath = `/connect/internal/connect/user-id`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves connect account link.
     * @summary Retrieves connect link.
     * @param {'account_onboarding' | 'account_update'} connectLinkType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectLink(
      connectLinkType: "account_onboarding" | "account_update",
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'connectLinkType' is not null or undefined
      if (connectLinkType === null || connectLinkType === undefined) {
        throw new RequiredError(
          "connectLinkType",
          "Required parameter connectLinkType was null or undefined when calling getConnectLink.",
        );
      }
      const localVarPath = `/connect/artist/connect/link`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (connectLinkType !== undefined) {
        localVarQueryParameter["connectLinkType"] = connectLinkType;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves connect account login link.
     * @summary Retrieves connect login link.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectLoginLink(options: any = {}): FetchArgs {
      const localVarPath = `/connect/artist/connect/login-link`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves connect payout record by id.
     * @summary Retrieves connect payout.
     * @param {string} id connect payout id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectPayout(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getConnectPayout.",
        );
      }
      const localVarPath = `/connect/artist/connect/payout/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves connect readiness.
     * @summary Retrieves connect readiness.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectReadiness(userId: string, options: any = {}): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getConnectReadiness.",
        );
      }
      const localVarPath = `/connect/readiness`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves connect account status.
     * @summary Retrieves connect status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectStatus(options: any = {}): FetchArgs {
      const localVarPath = `/connect/artist/connect/status`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ConnectApi - functional programming interface
 * @export
 */
export const ConnectApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Creates connect payout.
     * @summary Creates connect payout.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createConnectPayout(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateConnectPayoutResponse> {
      const localVarFetchArgs =
        ConnectApiFetchParamCreator(configuration).createConnectPayout(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves connect account balance.
     * @summary Retrieves connect balance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectBalance(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetConnectBalanceResponse> {
      const localVarFetchArgs =
        ConnectApiFetchParamCreator(configuration).getConnectBalance(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves connect account by user id.
     * @summary Retrieves connect by user id.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectByUserId(
      userId: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetConnectByUserIdResponse> {
      const localVarFetchArgs = ConnectApiFetchParamCreator(
        configuration,
      ).getConnectByUserId(userId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves connect account link.
     * @summary Retrieves connect link.
     * @param {'account_onboarding' | 'account_update'} connectLinkType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectLink(
      connectLinkType: "account_onboarding" | "account_update",
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetConnectLinkResponse> {
      const localVarFetchArgs = ConnectApiFetchParamCreator(
        configuration,
      ).getConnectLink(connectLinkType, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves connect account login link.
     * @summary Retrieves connect login link.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectLoginLink(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetConnectLoginLinkResponse> {
      const localVarFetchArgs =
        ConnectApiFetchParamCreator(configuration).getConnectLoginLink(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves connect payout record by id.
     * @summary Retrieves connect payout.
     * @param {string} id connect payout id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectPayout(
      id: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetConnectPayoutResponse> {
      const localVarFetchArgs = ConnectApiFetchParamCreator(
        configuration,
      ).getConnectPayout(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves connect readiness.
     * @summary Retrieves connect readiness.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectReadiness(
      userId: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetConnectReadinessResponse> {
      const localVarFetchArgs = ConnectApiFetchParamCreator(
        configuration,
      ).getConnectReadiness(userId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves connect account status.
     * @summary Retrieves connect status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectStatus(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetConnectStatusResponse> {
      const localVarFetchArgs =
        ConnectApiFetchParamCreator(configuration).getConnectStatus(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ConnectApi - factory interface
 * @export
 */
export const ConnectApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Creates connect payout.
     * @summary Creates connect payout.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createConnectPayout(options?: any) {
      return ConnectApiFp(configuration).createConnectPayout(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves connect account balance.
     * @summary Retrieves connect balance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectBalance(options?: any) {
      return ConnectApiFp(configuration).getConnectBalance(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves connect account by user id.
     * @summary Retrieves connect by user id.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectByUserId(userId: string, options?: any) {
      return ConnectApiFp(configuration).getConnectByUserId(userId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves connect account link.
     * @summary Retrieves connect link.
     * @param {'account_onboarding' | 'account_update'} connectLinkType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectLink(
      connectLinkType: "account_onboarding" | "account_update",
      options?: any,
    ) {
      return ConnectApiFp(configuration).getConnectLink(
        connectLinkType,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves connect account login link.
     * @summary Retrieves connect login link.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectLoginLink(options?: any) {
      return ConnectApiFp(configuration).getConnectLoginLink(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves connect payout record by id.
     * @summary Retrieves connect payout.
     * @param {string} id connect payout id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectPayout(id: string, options?: any) {
      return ConnectApiFp(configuration).getConnectPayout(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves connect readiness.
     * @summary Retrieves connect readiness.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectReadiness(userId: string, options?: any) {
      return ConnectApiFp(configuration).getConnectReadiness(userId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves connect account status.
     * @summary Retrieves connect status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConnectStatus(options?: any) {
      return ConnectApiFp(configuration).getConnectStatus(options)(
        fetch,
        basePath,
      );
    },
  };
};

/**
 * ConnectApi - object-oriented interface
 * @export
 * @class ConnectApi
 * @extends {BaseAPI}
 */
export class ConnectApi extends BaseAPI {
  /**
   * Creates connect payout.
   * @summary Creates connect payout.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectApi
   */
  public createConnectPayout(options?: any) {
    return ConnectApiFp(this.configuration).createConnectPayout(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves connect account balance.
   * @summary Retrieves connect balance.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectApi
   */
  public getConnectBalance(options?: any) {
    return ConnectApiFp(this.configuration).getConnectBalance(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves connect account by user id.
   * @summary Retrieves connect by user id.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectApi
   */
  public getConnectByUserId(userId: string, options?: any) {
    return ConnectApiFp(this.configuration).getConnectByUserId(userId, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves connect account link.
   * @summary Retrieves connect link.
   * @param {'account_onboarding' | 'account_update'} connectLinkType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectApi
   */
  public getConnectLink(
    connectLinkType: "account_onboarding" | "account_update",
    options?: any,
  ) {
    return ConnectApiFp(this.configuration).getConnectLink(
      connectLinkType,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves connect account login link.
   * @summary Retrieves connect login link.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectApi
   */
  public getConnectLoginLink(options?: any) {
    return ConnectApiFp(this.configuration).getConnectLoginLink(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves connect payout record by id.
   * @summary Retrieves connect payout.
   * @param {string} id connect payout id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectApi
   */
  public getConnectPayout(id: string, options?: any) {
    return ConnectApiFp(this.configuration).getConnectPayout(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves connect readiness.
   * @summary Retrieves connect readiness.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectApi
   */
  public getConnectReadiness(userId: string, options?: any) {
    return ConnectApiFp(this.configuration).getConnectReadiness(
      userId,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves connect account status.
   * @summary Retrieves connect status.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConnectApi
   */
  public getConnectStatus(options?: any) {
    return ConnectApiFp(this.configuration).getConnectStatus(options)(
      this.fetch,
      this.basePath,
    );
  }
}

/**
 * CustomerApi - fetch parameter creator
 * @export
 */
export const CustomerApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates customer payment method.
     * @summary Creates customer payment method.
     * @param {CreateCustomerPaymentMethodRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomerPaymentMethod(
      fields: CreateCustomerPaymentMethodRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createCustomerPaymentMethod.",
        );
      }
      const localVarPath = `/customer/pm`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateCustomerPaymentMethodRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates customer payment method setup.
     * @summary Creates customer payment method setup.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomerPaymentMethodSetup(options: any = {}): FetchArgs {
      const localVarPath = `/customer/pm/setup`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes customer customer payment method.
     * @summary Deletes customer customer payment method.
     * @param {string} customerPaymentMethodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCustomerPaymentMethod(
      customerPaymentMethodId: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'customerPaymentMethodId' is not null or undefined
      if (
        customerPaymentMethodId === null ||
        customerPaymentMethodId === undefined
      ) {
        throw new RequiredError(
          "customerPaymentMethodId",
          "Required parameter customerPaymentMethodId was null or undefined when calling deleteCustomerPaymentMethod.",
        );
      }
      const localVarPath = `/customer/pm`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: "DELETE" },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (customerPaymentMethodId !== undefined) {
        localVarQueryParameter["customerPaymentMethodId"] =
          customerPaymentMethodId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves customer by user id.
     * @summary Retrieves customer by user id.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerByUserId(userId: string, options: any = {}): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getCustomerByUserId.",
        );
      }
      const localVarPath = `/customer/internal/customer/user-id`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves customer default payment method.
     * @summary Retrieves customer default payment method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerPaymentMethodDefault(options: any = {}): FetchArgs {
      const localVarPath = `/customer/pm/default`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves customer payment methods.
     * @summary Retrieves customer payment methods.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerPaymentMethods(
      limit?: number,
      offset?: number,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/customer/pm`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sets customer payment method as default.
     * @summary Sets customer payment method as default.
     * @param {SetCustomerPaymentMethodDefaultRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setCustomerPaymentMethodDefault(
      fields: SetCustomerPaymentMethodDefaultRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling setCustomerPaymentMethodDefault.",
        );
      }
      const localVarPath = `/customer/pm/default`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"SetCustomerPaymentMethodDefaultRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Creates customer payment method.
     * @summary Creates customer payment method.
     * @param {CreateCustomerPaymentMethodRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomerPaymentMethod(
      fields: CreateCustomerPaymentMethodRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateCustomerPaymentMethodResponse> {
      const localVarFetchArgs = CustomerApiFetchParamCreator(
        configuration,
      ).createCustomerPaymentMethod(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates customer payment method setup.
     * @summary Creates customer payment method setup.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomerPaymentMethodSetup(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateCustomerPaymentMethodSetupResponse> {
      const localVarFetchArgs =
        CustomerApiFetchParamCreator(
          configuration,
        ).createCustomerPaymentMethodSetup(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes customer customer payment method.
     * @summary Deletes customer customer payment method.
     * @param {string} customerPaymentMethodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCustomerPaymentMethod(
      customerPaymentMethodId: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = CustomerApiFetchParamCreator(
        configuration,
      ).deleteCustomerPaymentMethod(customerPaymentMethodId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves customer by user id.
     * @summary Retrieves customer by user id.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerByUserId(
      userId: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetCustomerByUserIdResponse> {
      const localVarFetchArgs = CustomerApiFetchParamCreator(
        configuration,
      ).getCustomerByUserId(userId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves customer default payment method.
     * @summary Retrieves customer default payment method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerPaymentMethodDefault(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetCustomerPaymentMethodDefaultResponse> {
      const localVarFetchArgs =
        CustomerApiFetchParamCreator(
          configuration,
        ).getCustomerPaymentMethodDefault(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves customer payment methods.
     * @summary Retrieves customer payment methods.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerPaymentMethods(
      limit?: number,
      offset?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetCustomerPaymentMethodsResponse> {
      const localVarFetchArgs = CustomerApiFetchParamCreator(
        configuration,
      ).getCustomerPaymentMethods(limit, offset, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Sets customer payment method as default.
     * @summary Sets customer payment method as default.
     * @param {SetCustomerPaymentMethodDefaultRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setCustomerPaymentMethodDefault(
      fields: SetCustomerPaymentMethodDefaultRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<SetCustomerPaymentMethodDefaultResponse> {
      const localVarFetchArgs = CustomerApiFetchParamCreator(
        configuration,
      ).setCustomerPaymentMethodDefault(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Creates customer payment method.
     * @summary Creates customer payment method.
     * @param {CreateCustomerPaymentMethodRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomerPaymentMethod(
      fields: CreateCustomerPaymentMethodRequest,
      options?: any,
    ) {
      return CustomerApiFp(configuration).createCustomerPaymentMethod(
        fields,
        options,
      )(fetch, basePath);
    },
    /**
     * Creates customer payment method setup.
     * @summary Creates customer payment method setup.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomerPaymentMethodSetup(options?: any) {
      return CustomerApiFp(configuration).createCustomerPaymentMethodSetup(
        options,
      )(fetch, basePath);
    },
    /**
     * Deletes customer customer payment method.
     * @summary Deletes customer customer payment method.
     * @param {string} customerPaymentMethodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCustomerPaymentMethod(
      customerPaymentMethodId: string,
      options?: any,
    ) {
      return CustomerApiFp(configuration).deleteCustomerPaymentMethod(
        customerPaymentMethodId,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves customer by user id.
     * @summary Retrieves customer by user id.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerByUserId(userId: string, options?: any) {
      return CustomerApiFp(configuration).getCustomerByUserId(userId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves customer default payment method.
     * @summary Retrieves customer default payment method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerPaymentMethodDefault(options?: any) {
      return CustomerApiFp(configuration).getCustomerPaymentMethodDefault(
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves customer payment methods.
     * @summary Retrieves customer payment methods.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerPaymentMethods(limit?: number, offset?: number, options?: any) {
      return CustomerApiFp(configuration).getCustomerPaymentMethods(
        limit,
        offset,
        options,
      )(fetch, basePath);
    },
    /**
     * Sets customer payment method as default.
     * @summary Sets customer payment method as default.
     * @param {SetCustomerPaymentMethodDefaultRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setCustomerPaymentMethodDefault(
      fields: SetCustomerPaymentMethodDefaultRequest,
      options?: any,
    ) {
      return CustomerApiFp(configuration).setCustomerPaymentMethodDefault(
        fields,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
  /**
   * Creates customer payment method.
   * @summary Creates customer payment method.
   * @param {CreateCustomerPaymentMethodRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public createCustomerPaymentMethod(
    fields: CreateCustomerPaymentMethodRequest,
    options?: any,
  ) {
    return CustomerApiFp(this.configuration).createCustomerPaymentMethod(
      fields,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Creates customer payment method setup.
   * @summary Creates customer payment method setup.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public createCustomerPaymentMethodSetup(options?: any) {
    return CustomerApiFp(this.configuration).createCustomerPaymentMethodSetup(
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Deletes customer customer payment method.
   * @summary Deletes customer customer payment method.
   * @param {string} customerPaymentMethodId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public deleteCustomerPaymentMethod(
    customerPaymentMethodId: string,
    options?: any,
  ) {
    return CustomerApiFp(this.configuration).deleteCustomerPaymentMethod(
      customerPaymentMethodId,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves customer by user id.
   * @summary Retrieves customer by user id.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public getCustomerByUserId(userId: string, options?: any) {
    return CustomerApiFp(this.configuration).getCustomerByUserId(
      userId,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves customer default payment method.
   * @summary Retrieves customer default payment method.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public getCustomerPaymentMethodDefault(options?: any) {
    return CustomerApiFp(this.configuration).getCustomerPaymentMethodDefault(
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves customer payment methods.
   * @summary Retrieves customer payment methods.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public getCustomerPaymentMethods(
    limit?: number,
    offset?: number,
    options?: any,
  ) {
    return CustomerApiFp(this.configuration).getCustomerPaymentMethods(
      limit,
      offset,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Sets customer payment method as default.
   * @summary Sets customer payment method as default.
   * @param {SetCustomerPaymentMethodDefaultRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public setCustomerPaymentMethodDefault(
    fields: SetCustomerPaymentMethodDefaultRequest,
    options?: any,
  ) {
    return CustomerApiFp(this.configuration).setCustomerPaymentMethodDefault(
      fields,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * FileApi - fetch parameter creator
 * @export
 */
export const FileApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates new file.
     * @summary Creates file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFile(options: any = {}): FetchArgs {
      const localVarPath = `/file/internal/file`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves file record.
     * @summary Retrieves file.
     * @param {string} id file id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileInternal(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getFileInternal.",
        );
      }
      const localVarPath = `/file/internal/file/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves file url and redirects to it.
     * @summary Retrieves file url.
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileURL(fileId: string, options: any = {}): FetchArgs {
      // verify required parameter 'fileId' is not null or undefined
      if (fileId === null || fileId === undefined) {
        throw new RequiredError(
          "fileId",
          "Required parameter fileId was null or undefined when calling getFileURL.",
        );
      }
      const localVarPath = `/file/public/file/url`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fileId !== undefined) {
        localVarQueryParameter["fileId"] = fileId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Creates new file.
     * @summary Creates file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFile(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateFileResponse> {
      const localVarFetchArgs =
        FileApiFetchParamCreator(configuration).createFile(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves file record.
     * @summary Retrieves file.
     * @param {string} id file id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileInternal(
      id: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetFileInternalResponse> {
      const localVarFetchArgs = FileApiFetchParamCreator(
        configuration,
      ).getFileInternal(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves file url and redirects to it.
     * @summary Retrieves file url.
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileURL(
      fileId: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = FileApiFetchParamCreator(
        configuration,
      ).getFileURL(fileId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Creates new file.
     * @summary Creates file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFile(options?: any) {
      return FileApiFp(configuration).createFile(options)(fetch, basePath);
    },
    /**
     * Retrieves file record.
     * @summary Retrieves file.
     * @param {string} id file id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileInternal(id: string, options?: any) {
      return FileApiFp(configuration).getFileInternal(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves file url and redirects to it.
     * @summary Retrieves file url.
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileURL(fileId: string, options?: any) {
      return FileApiFp(configuration).getFileURL(fileId, options)(
        fetch,
        basePath,
      );
    },
  };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
  /**
   * Creates new file.
   * @summary Creates file.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public createFile(options?: any) {
    return FileApiFp(this.configuration).createFile(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves file record.
   * @summary Retrieves file.
   * @param {string} id file id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public getFileInternal(id: string, options?: any) {
    return FileApiFp(this.configuration).getFileInternal(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves file url and redirects to it.
   * @summary Retrieves file url.
   * @param {string} fileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public getFileURL(fileId: string, options?: any) {
    return FileApiFp(this.configuration).getFileURL(fileId, options)(
      this.fetch,
      this.basePath,
    );
  }
}

/**
 * LikeApi - fetch parameter creator
 * @export
 */
export const LikeApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates comment like.
     * @summary Creates comment like.
     * @param {CreateCommentLikeRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentLike(
      fields: CreateCommentLikeRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createCommentLike.",
        );
      }
      const localVarPath = `/like/comment`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateCommentLikeRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates post like.
     * @summary Creates post like.
     * @param {CreatePostLikeRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPostLike(
      fields: CreatePostLikeRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createPostLike.",
        );
      }
      const localVarPath = `/like/post`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreatePostLikeRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates track like.
     * @summary Creates track like.
     * @param {CreateTrackLikeRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrackLike(
      fields: CreateTrackLikeRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createTrackLike.",
        );
      }
      const localVarPath = `/like/track`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateTrackLikeRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates user follow.
     * @summary Creates user follow.
     * @param {CreateUserFollowRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserFollow(
      fields: CreateUserFollowRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createUserFollow.",
        );
      }
      const localVarPath = `/like/user`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateUserFollowRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes comment like.
     * @summary Deletes comment like.
     * @param {string} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommentLike(commentId: string, options: any = {}): FetchArgs {
      // verify required parameter 'commentId' is not null or undefined
      if (commentId === null || commentId === undefined) {
        throw new RequiredError(
          "commentId",
          "Required parameter commentId was null or undefined when calling deleteCommentLike.",
        );
      }
      const localVarPath = `/like/comment`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: "DELETE" },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (commentId !== undefined) {
        localVarQueryParameter["commentId"] = commentId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes post like.
     * @summary Deletes post like.
     * @param {string} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePostLike(postId: string, options: any = {}): FetchArgs {
      // verify required parameter 'postId' is not null or undefined
      if (postId === null || postId === undefined) {
        throw new RequiredError(
          "postId",
          "Required parameter postId was null or undefined when calling deletePostLike.",
        );
      }
      const localVarPath = `/like/post`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: "DELETE" },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (postId !== undefined) {
        localVarQueryParameter["postId"] = postId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes track like.
     * @summary Deletes track like.
     * @param {string} trackId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTrackLike(trackId: string, options: any = {}): FetchArgs {
      // verify required parameter 'trackId' is not null or undefined
      if (trackId === null || trackId === undefined) {
        throw new RequiredError(
          "trackId",
          "Required parameter trackId was null or undefined when calling deleteTrackLike.",
        );
      }
      const localVarPath = `/like/track`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: "DELETE" },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (trackId !== undefined) {
        localVarQueryParameter["trackId"] = trackId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes user follow.
     * @summary Deletes user follow.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserFollow(userId: string, options: any = {}): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling deleteUserFollow.",
        );
      }
      const localVarPath = `/like/user`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: "DELETE" },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves ids of users that source user followed.
     * @summary Retrieves ids of users that source user followed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowedUserIds(options: any = {}): FetchArgs {
      const localVarPath = `/like/user/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves ids of users that source user followed.
     * @summary Retrieves ids of users that source user followed.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowedUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getFollowedUserIdsInternal.",
        );
      }
      const localVarPath = `/like/internal/like/user/followed-ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (random !== undefined) {
        localVarQueryParameter["random"] = random;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves ids of users that following source user.
     * @summary Retrieves ids of users that following source user.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowingUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getFollowingUserIdsInternal.",
        );
      }
      const localVarPath = `/like/internal/like/user/following-ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (random !== undefined) {
        localVarQueryParameter["random"] = random;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves liked comment ids.
     * @summary Retrieves liked comment ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedCommentIds(options: any = {}): FetchArgs {
      const localVarPath = `/like/comment/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves liked comment ids.
     * @summary Retrieves liked comment ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedCommentIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getLikedCommentIdsInternal.",
        );
      }
      const localVarPath = `/like/internal/like/comment/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (random !== undefined) {
        localVarQueryParameter["random"] = random;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves liked post ids.
     * @summary Retrieves liked post ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedPostIds(options: any = {}): FetchArgs {
      const localVarPath = `/like/post/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves liked post ids.
     * @summary Retrieves liked post ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedPostIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getLikedPostIdsInternal.",
        );
      }
      const localVarPath = `/like/internal/like/post/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (random !== undefined) {
        localVarQueryParameter["random"] = random;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves liked track ids.
     * @summary Retrieves liked track ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedTrackIds(options: any = {}): FetchArgs {
      const localVarPath = `/like/track/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves liked track ids.
     * @summary Retrieves liked track ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedTrackIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getLikedTrackIdsInternal.",
        );
      }
      const localVarPath = `/like/internal/like/track/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (random !== undefined) {
        localVarQueryParameter["random"] = random;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LikeApi - functional programming interface
 * @export
 */
export const LikeApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Creates comment like.
     * @summary Creates comment like.
     * @param {CreateCommentLikeRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentLike(
      fields: CreateCommentLikeRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateCommentLikeResponse> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).createCommentLike(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates post like.
     * @summary Creates post like.
     * @param {CreatePostLikeRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPostLike(
      fields: CreatePostLikeRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreatePostLikeResponse> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).createPostLike(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates track like.
     * @summary Creates track like.
     * @param {CreateTrackLikeRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrackLike(
      fields: CreateTrackLikeRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateTrackLikeResponse> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).createTrackLike(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates user follow.
     * @summary Creates user follow.
     * @param {CreateUserFollowRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserFollow(
      fields: CreateUserFollowRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateUserFollowResponse> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).createUserFollow(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes comment like.
     * @summary Deletes comment like.
     * @param {string} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommentLike(
      commentId: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).deleteCommentLike(commentId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes post like.
     * @summary Deletes post like.
     * @param {string} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePostLike(
      postId: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).deletePostLike(postId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes track like.
     * @summary Deletes track like.
     * @param {string} trackId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTrackLike(
      trackId: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).deleteTrackLike(trackId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes user follow.
     * @summary Deletes user follow.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserFollow(
      userId: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).deleteUserFollow(userId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves ids of users that source user followed.
     * @summary Retrieves ids of users that source user followed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowedUserIds(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetFollowedUserIdsResponse> {
      const localVarFetchArgs =
        LikeApiFetchParamCreator(configuration).getFollowedUserIds(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves ids of users that source user followed.
     * @summary Retrieves ids of users that source user followed.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowedUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetFollowedUserIdsInternalResponse> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).getFollowedUserIdsInternal(userId, limit, offset, random, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves ids of users that following source user.
     * @summary Retrieves ids of users that following source user.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowingUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetFollowingUserIdsInternalResponse> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).getFollowingUserIdsInternal(userId, limit, offset, random, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves liked comment ids.
     * @summary Retrieves liked comment ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedCommentIds(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetLikedCommentIdsResponse> {
      const localVarFetchArgs =
        LikeApiFetchParamCreator(configuration).getLikedCommentIds(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves liked comment ids.
     * @summary Retrieves liked comment ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedCommentIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetLikedCommentIdsInternalResponse> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).getLikedCommentIdsInternal(userId, limit, offset, random, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves liked post ids.
     * @summary Retrieves liked post ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedPostIds(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetLikedPostIdsResponse> {
      const localVarFetchArgs =
        LikeApiFetchParamCreator(configuration).getLikedPostIds(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves liked post ids.
     * @summary Retrieves liked post ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedPostIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetLikedPostIdsInternalResponse> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).getLikedPostIdsInternal(userId, limit, offset, random, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves liked track ids.
     * @summary Retrieves liked track ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedTrackIds(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetLikedTrackIdsResponse> {
      const localVarFetchArgs =
        LikeApiFetchParamCreator(configuration).getLikedTrackIds(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves liked track ids.
     * @summary Retrieves liked track ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedTrackIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetLikedTrackIdsInternalResponse> {
      const localVarFetchArgs = LikeApiFetchParamCreator(
        configuration,
      ).getLikedTrackIdsInternal(userId, limit, offset, random, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * LikeApi - factory interface
 * @export
 */
export const LikeApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Creates comment like.
     * @summary Creates comment like.
     * @param {CreateCommentLikeRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentLike(fields: CreateCommentLikeRequest, options?: any) {
      return LikeApiFp(configuration).createCommentLike(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates post like.
     * @summary Creates post like.
     * @param {CreatePostLikeRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPostLike(fields: CreatePostLikeRequest, options?: any) {
      return LikeApiFp(configuration).createPostLike(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates track like.
     * @summary Creates track like.
     * @param {CreateTrackLikeRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrackLike(fields: CreateTrackLikeRequest, options?: any) {
      return LikeApiFp(configuration).createTrackLike(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates user follow.
     * @summary Creates user follow.
     * @param {CreateUserFollowRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserFollow(fields: CreateUserFollowRequest, options?: any) {
      return LikeApiFp(configuration).createUserFollow(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Deletes comment like.
     * @summary Deletes comment like.
     * @param {string} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommentLike(commentId: string, options?: any) {
      return LikeApiFp(configuration).deleteCommentLike(commentId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Deletes post like.
     * @summary Deletes post like.
     * @param {string} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePostLike(postId: string, options?: any) {
      return LikeApiFp(configuration).deletePostLike(postId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Deletes track like.
     * @summary Deletes track like.
     * @param {string} trackId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTrackLike(trackId: string, options?: any) {
      return LikeApiFp(configuration).deleteTrackLike(trackId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Deletes user follow.
     * @summary Deletes user follow.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserFollow(userId: string, options?: any) {
      return LikeApiFp(configuration).deleteUserFollow(userId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves ids of users that source user followed.
     * @summary Retrieves ids of users that source user followed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowedUserIds(options?: any) {
      return LikeApiFp(configuration).getFollowedUserIds(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves ids of users that source user followed.
     * @summary Retrieves ids of users that source user followed.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowedUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ) {
      return LikeApiFp(configuration).getFollowedUserIdsInternal(
        userId,
        limit,
        offset,
        random,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves ids of users that following source user.
     * @summary Retrieves ids of users that following source user.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowingUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ) {
      return LikeApiFp(configuration).getFollowingUserIdsInternal(
        userId,
        limit,
        offset,
        random,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves liked comment ids.
     * @summary Retrieves liked comment ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedCommentIds(options?: any) {
      return LikeApiFp(configuration).getLikedCommentIds(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves liked comment ids.
     * @summary Retrieves liked comment ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedCommentIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ) {
      return LikeApiFp(configuration).getLikedCommentIdsInternal(
        userId,
        limit,
        offset,
        random,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves liked post ids.
     * @summary Retrieves liked post ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedPostIds(options?: any) {
      return LikeApiFp(configuration).getLikedPostIds(options)(fetch, basePath);
    },
    /**
     * Retrieves liked post ids.
     * @summary Retrieves liked post ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedPostIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ) {
      return LikeApiFp(configuration).getLikedPostIdsInternal(
        userId,
        limit,
        offset,
        random,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves liked track ids.
     * @summary Retrieves liked track ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedTrackIds(options?: any) {
      return LikeApiFp(configuration).getLikedTrackIds(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves liked track ids.
     * @summary Retrieves liked track ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedTrackIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ) {
      return LikeApiFp(configuration).getLikedTrackIdsInternal(
        userId,
        limit,
        offset,
        random,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * LikeApi - object-oriented interface
 * @export
 * @class LikeApi
 * @extends {BaseAPI}
 */
export class LikeApi extends BaseAPI {
  /**
   * Creates comment like.
   * @summary Creates comment like.
   * @param {CreateCommentLikeRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public createCommentLike(fields: CreateCommentLikeRequest, options?: any) {
    return LikeApiFp(this.configuration).createCommentLike(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates post like.
   * @summary Creates post like.
   * @param {CreatePostLikeRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public createPostLike(fields: CreatePostLikeRequest, options?: any) {
    return LikeApiFp(this.configuration).createPostLike(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates track like.
   * @summary Creates track like.
   * @param {CreateTrackLikeRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public createTrackLike(fields: CreateTrackLikeRequest, options?: any) {
    return LikeApiFp(this.configuration).createTrackLike(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates user follow.
   * @summary Creates user follow.
   * @param {CreateUserFollowRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public createUserFollow(fields: CreateUserFollowRequest, options?: any) {
    return LikeApiFp(this.configuration).createUserFollow(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Deletes comment like.
   * @summary Deletes comment like.
   * @param {string} commentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public deleteCommentLike(commentId: string, options?: any) {
    return LikeApiFp(this.configuration).deleteCommentLike(commentId, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Deletes post like.
   * @summary Deletes post like.
   * @param {string} postId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public deletePostLike(postId: string, options?: any) {
    return LikeApiFp(this.configuration).deletePostLike(postId, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Deletes track like.
   * @summary Deletes track like.
   * @param {string} trackId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public deleteTrackLike(trackId: string, options?: any) {
    return LikeApiFp(this.configuration).deleteTrackLike(trackId, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Deletes user follow.
   * @summary Deletes user follow.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public deleteUserFollow(userId: string, options?: any) {
    return LikeApiFp(this.configuration).deleteUserFollow(userId, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves ids of users that source user followed.
   * @summary Retrieves ids of users that source user followed.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public getFollowedUserIds(options?: any) {
    return LikeApiFp(this.configuration).getFollowedUserIds(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves ids of users that source user followed.
   * @summary Retrieves ids of users that source user followed.
   * @param {string} userId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {boolean} [random]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public getFollowedUserIdsInternal(
    userId: string,
    limit?: number,
    offset?: number,
    random?: boolean,
    options?: any,
  ) {
    return LikeApiFp(this.configuration).getFollowedUserIdsInternal(
      userId,
      limit,
      offset,
      random,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves ids of users that following source user.
   * @summary Retrieves ids of users that following source user.
   * @param {string} userId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {boolean} [random]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public getFollowingUserIdsInternal(
    userId: string,
    limit?: number,
    offset?: number,
    random?: boolean,
    options?: any,
  ) {
    return LikeApiFp(this.configuration).getFollowingUserIdsInternal(
      userId,
      limit,
      offset,
      random,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves liked comment ids.
   * @summary Retrieves liked comment ids.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public getLikedCommentIds(options?: any) {
    return LikeApiFp(this.configuration).getLikedCommentIds(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves liked comment ids.
   * @summary Retrieves liked comment ids.
   * @param {string} userId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {boolean} [random]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public getLikedCommentIdsInternal(
    userId: string,
    limit?: number,
    offset?: number,
    random?: boolean,
    options?: any,
  ) {
    return LikeApiFp(this.configuration).getLikedCommentIdsInternal(
      userId,
      limit,
      offset,
      random,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves liked post ids.
   * @summary Retrieves liked post ids.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public getLikedPostIds(options?: any) {
    return LikeApiFp(this.configuration).getLikedPostIds(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves liked post ids.
   * @summary Retrieves liked post ids.
   * @param {string} userId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {boolean} [random]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public getLikedPostIdsInternal(
    userId: string,
    limit?: number,
    offset?: number,
    random?: boolean,
    options?: any,
  ) {
    return LikeApiFp(this.configuration).getLikedPostIdsInternal(
      userId,
      limit,
      offset,
      random,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves liked track ids.
   * @summary Retrieves liked track ids.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public getLikedTrackIds(options?: any) {
    return LikeApiFp(this.configuration).getLikedTrackIds(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves liked track ids.
   * @summary Retrieves liked track ids.
   * @param {string} userId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {boolean} [random]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LikeApi
   */
  public getLikedTrackIdsInternal(
    userId: string,
    limit?: number,
    offset?: number,
    random?: boolean,
    options?: any,
  ) {
    return LikeApiFp(this.configuration).getLikedTrackIdsInternal(
      userId,
      limit,
      offset,
      random,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * NotificationApi - fetch parameter creator
 * @export
 */
export const NotificationApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Retrieves notification record by notification id.
     * @summary Retrieves notification.
     * @param {string} id notification id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppNotification(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getAppNotification.",
        );
      }
      const localVarPath = `/notification/application/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves application notifications.
     * @summary Retrieves application notifications.
     * @param {'user' | 'track' | 'post' | 'comment' | 'tip' | 'unlock' | 'subscription' | 'connect_payout'} [entityType]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppNotifications(
      entityType?:
        | "user"
        | "track"
        | "post"
        | "comment"
        | "tip"
        | "unlock"
        | "subscription"
        | "connect_payout",
      limit?: number,
      offset?: number,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/notification/application`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (entityType !== undefined) {
        localVarQueryParameter["entityType"] = entityType;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get unread application notifications count.
     * @summary Get unread application notifications count.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppNotificationsUnreadCount(options: any = {}): FetchArgs {
      const localVarPath = `/notification/application/count`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets notification config.
     * @summary Gets notification config.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationConfig(options: any = {}): FetchArgs {
      const localVarPath = `/notification/config`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read notification.
     * @summary Read notification.
     * @param {ReadAppNotificationRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAppNotification(
      fields: ReadAppNotificationRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling readAppNotification.",
        );
      }
      const localVarPath = `/notification/application/read`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"ReadAppNotificationRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read all notifications.
     * @summary Read all notifications.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAppNotifications(options: any = {}): FetchArgs {
      const localVarPath = `/notification/application/read-all`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates notification config.
     * @summary Updates notification config.
     * @param {UpdateNotificationConfigRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationConfig(
      fields: UpdateNotificationConfigRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling updateNotificationConfig.",
        );
      }
      const localVarPath = `/notification/config`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"UpdateNotificationConfigRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Retrieves notification record by notification id.
     * @summary Retrieves notification.
     * @param {string} id notification id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppNotification(
      id: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetAppNotificationResponse> {
      const localVarFetchArgs = NotificationApiFetchParamCreator(
        configuration,
      ).getAppNotification(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves application notifications.
     * @summary Retrieves application notifications.
     * @param {'user' | 'track' | 'post' | 'comment' | 'tip' | 'unlock' | 'subscription' | 'connect_payout'} [entityType]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppNotifications(
      entityType?:
        | "user"
        | "track"
        | "post"
        | "comment"
        | "tip"
        | "unlock"
        | "subscription"
        | "connect_payout",
      limit?: number,
      offset?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetAppNotificationsResponse> {
      const localVarFetchArgs = NotificationApiFetchParamCreator(
        configuration,
      ).getAppNotifications(entityType, limit, offset, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get unread application notifications count.
     * @summary Get unread application notifications count.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppNotificationsUnreadCount(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetAppNotificationsUnreadCountResponse> {
      const localVarFetchArgs =
        NotificationApiFetchParamCreator(
          configuration,
        ).getAppNotificationsUnreadCount(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Gets notification config.
     * @summary Gets notification config.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationConfig(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetNotificationConfigResponse> {
      const localVarFetchArgs =
        NotificationApiFetchParamCreator(configuration).getNotificationConfig(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Read notification.
     * @summary Read notification.
     * @param {ReadAppNotificationRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAppNotification(
      fields: ReadAppNotificationRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = NotificationApiFetchParamCreator(
        configuration,
      ).readAppNotification(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Read all notifications.
     * @summary Read all notifications.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAppNotifications(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs =
        NotificationApiFetchParamCreator(configuration).readAppNotifications(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates notification config.
     * @summary Updates notification config.
     * @param {UpdateNotificationConfigRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationConfig(
      fields: UpdateNotificationConfigRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<UpdateNotificationConfigResponse> {
      const localVarFetchArgs = NotificationApiFetchParamCreator(
        configuration,
      ).updateNotificationConfig(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Retrieves notification record by notification id.
     * @summary Retrieves notification.
     * @param {string} id notification id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppNotification(id: string, options?: any) {
      return NotificationApiFp(configuration).getAppNotification(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves application notifications.
     * @summary Retrieves application notifications.
     * @param {'user' | 'track' | 'post' | 'comment' | 'tip' | 'unlock' | 'subscription' | 'connect_payout'} [entityType]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppNotifications(
      entityType?:
        | "user"
        | "track"
        | "post"
        | "comment"
        | "tip"
        | "unlock"
        | "subscription"
        | "connect_payout",
      limit?: number,
      offset?: number,
      options?: any,
    ) {
      return NotificationApiFp(configuration).getAppNotifications(
        entityType,
        limit,
        offset,
        options,
      )(fetch, basePath);
    },
    /**
     * Get unread application notifications count.
     * @summary Get unread application notifications count.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppNotificationsUnreadCount(options?: any) {
      return NotificationApiFp(configuration).getAppNotificationsUnreadCount(
        options,
      )(fetch, basePath);
    },
    /**
     * Gets notification config.
     * @summary Gets notification config.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationConfig(options?: any) {
      return NotificationApiFp(configuration).getNotificationConfig(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Read notification.
     * @summary Read notification.
     * @param {ReadAppNotificationRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAppNotification(fields: ReadAppNotificationRequest, options?: any) {
      return NotificationApiFp(configuration).readAppNotification(
        fields,
        options,
      )(fetch, basePath);
    },
    /**
     * Read all notifications.
     * @summary Read all notifications.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAppNotifications(options?: any) {
      return NotificationApiFp(configuration).readAppNotifications(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Updates notification config.
     * @summary Updates notification config.
     * @param {UpdateNotificationConfigRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationConfig(
      fields: UpdateNotificationConfigRequest,
      options?: any,
    ) {
      return NotificationApiFp(configuration).updateNotificationConfig(
        fields,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
  /**
   * Retrieves notification record by notification id.
   * @summary Retrieves notification.
   * @param {string} id notification id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getAppNotification(id: string, options?: any) {
    return NotificationApiFp(this.configuration).getAppNotification(
      id,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves application notifications.
   * @summary Retrieves application notifications.
   * @param {'user' | 'track' | 'post' | 'comment' | 'tip' | 'unlock' | 'subscription' | 'connect_payout'} [entityType]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getAppNotifications(
    entityType?:
      | "user"
      | "track"
      | "post"
      | "comment"
      | "tip"
      | "unlock"
      | "subscription"
      | "connect_payout",
    limit?: number,
    offset?: number,
    options?: any,
  ) {
    return NotificationApiFp(this.configuration).getAppNotifications(
      entityType,
      limit,
      offset,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get unread application notifications count.
   * @summary Get unread application notifications count.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getAppNotificationsUnreadCount(options?: any) {
    return NotificationApiFp(this.configuration).getAppNotificationsUnreadCount(
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Gets notification config.
   * @summary Gets notification config.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getNotificationConfig(options?: any) {
    return NotificationApiFp(this.configuration).getNotificationConfig(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Read notification.
   * @summary Read notification.
   * @param {ReadAppNotificationRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public readAppNotification(
    fields: ReadAppNotificationRequest,
    options?: any,
  ) {
    return NotificationApiFp(this.configuration).readAppNotification(
      fields,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Read all notifications.
   * @summary Read all notifications.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public readAppNotifications(options?: any) {
    return NotificationApiFp(this.configuration).readAppNotifications(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Updates notification config.
   * @summary Updates notification config.
   * @param {UpdateNotificationConfigRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public updateNotificationConfig(
    fields: UpdateNotificationConfigRequest,
    options?: any,
  ) {
    return NotificationApiFp(this.configuration).updateNotificationConfig(
      fields,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * PostApi - fetch parameter creator
 * @export
 */
export const PostApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Blocks post record.
     * @summary Blocks post.
     * @param {BlockPostRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockPost(fields: BlockPostRequest, options: any = {}): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling blockPost.",
        );
      }
      const localVarPath = `/post/admin/post/block`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"BlockPostRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates new post.
     * @summary Creates post.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPost(options: any = {}): FetchArgs {
      const localVarPath = `/post/artist/post`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes post.
     * @summary Deletes post.
     * @param {string} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePost(postId: string, options: any = {}): FetchArgs {
      // verify required parameter 'postId' is not null or undefined
      if (postId === null || postId === undefined) {
        throw new RequiredError(
          "postId",
          "Required parameter postId was null or undefined when calling deletePost.",
        );
      }
      const localVarPath = `/post/artist/post`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: "DELETE" },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (postId !== undefined) {
        localVarQueryParameter["postId"] = postId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves artist posts.
     * @summary Retrieves artist posts.
     * @param {string} artistId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArtistPosts(
      artistId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'artistId' is not null or undefined
      if (artistId === null || artistId === undefined) {
        throw new RequiredError(
          "artistId",
          "Required parameter artistId was null or undefined when calling getArtistPosts.",
        );
      }
      const localVarPath = `/post/artist`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (artistId !== undefined) {
        localVarQueryParameter["artistId"] = artistId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves exclusive posts.
     * @summary Retrieves exclusive posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExclusivePosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/post/exclusive`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (artistId !== undefined) {
        localVarQueryParameter["artistId"] = artistId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves latest posts.
     * @summary Retrieves latest posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestPosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/post/latest`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (artistId !== undefined) {
        localVarQueryParameter["artistId"] = artistId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves liked posts.
     * @summary Retrieves liked posts.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedPosts(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/post/liked`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves popular posts.
     * @summary Retrieves popular posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularPosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/post/popular`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (artistId !== undefined) {
        localVarQueryParameter["artistId"] = artistId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves post record by post id.
     * @summary Retrieves post.
     * @param {string} id post id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPost(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getPost.",
        );
      }
      const localVarPath = `/post/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves post by post id.
     * @summary Retrieves post by post id.
     * @param {string} id post id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPostInternal(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getPostInternal.",
        );
      }
      const localVarPath = `/post/internal/post/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves public posts.
     * @summary Retrieves public posts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicPosts(options: any = {}): FetchArgs {
      const localVarPath = `/post/public/post`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves recommended posts.
     * @summary Retrieves recommended posts.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPosts(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/post/recommended`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves trending posts.
     * @summary Retrieves trending posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrendingPosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/post/trending`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (artistId !== undefined) {
        localVarQueryParameter["artistId"] = artistId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves unlocked posts.
     * @summary Retrieves unlocked posts.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedPosts(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/post/unlocked`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get user posts count.
     * @summary Get user posts count.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPostsCount(options: any = {}): FetchArgs {
      const localVarPath = `/post/artist/post/count`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates post record.
     * @summary Updates post.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePost(options: any = {}): FetchArgs {
      const localVarPath = `/post/artist/post`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PostApi - functional programming interface
 * @export
 */
export const PostApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Blocks post record.
     * @summary Blocks post.
     * @param {BlockPostRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockPost(
      fields: BlockPostRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).blockPost(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates new post.
     * @summary Creates post.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPost(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreatePostResponse> {
      const localVarFetchArgs =
        PostApiFetchParamCreator(configuration).createPost(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes post.
     * @summary Deletes post.
     * @param {string} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePost(
      postId: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).deletePost(postId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves artist posts.
     * @summary Retrieves artist posts.
     * @param {string} artistId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArtistPosts(
      artistId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetArtistPostsResponse> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).getArtistPosts(artistId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves exclusive posts.
     * @summary Retrieves exclusive posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExclusivePosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetExclusivePostsResponse> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).getExclusivePosts(artistId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves latest posts.
     * @summary Retrieves latest posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestPosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetLatestPostsResponse> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).getLatestPosts(artistId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves liked posts.
     * @summary Retrieves liked posts.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedPosts(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetLikedPostsResponse> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).getLikedPosts(limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves popular posts.
     * @summary Retrieves popular posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularPosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetPopularPostsResponse> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).getPopularPosts(artistId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves post record by post id.
     * @summary Retrieves post.
     * @param {string} id post id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPost(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetPostResponse> {
      const localVarFetchArgs = PostApiFetchParamCreator(configuration).getPost(
        id,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves post by post id.
     * @summary Retrieves post by post id.
     * @param {string} id post id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPostInternal(
      id: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetPostInternalResponse> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).getPostInternal(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves public posts.
     * @summary Retrieves public posts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicPosts(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetPublicPostsResponse> {
      const localVarFetchArgs =
        PostApiFetchParamCreator(configuration).getPublicPosts(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves recommended posts.
     * @summary Retrieves recommended posts.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPosts(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetRecommendedPostsResponse> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).getRecommendedPosts(limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves trending posts.
     * @summary Retrieves trending posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrendingPosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetTrendingPostsResponse> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).getTrendingPosts(artistId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves unlocked posts.
     * @summary Retrieves unlocked posts.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedPosts(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUnlockedPostsResponse> {
      const localVarFetchArgs = PostApiFetchParamCreator(
        configuration,
      ).getUnlockedPosts(limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get user posts count.
     * @summary Get user posts count.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPostsCount(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUserPostsCountResponse> {
      const localVarFetchArgs =
        PostApiFetchParamCreator(configuration).getUserPostsCount(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates post record.
     * @summary Updates post.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePost(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<UpdatePostResponse> {
      const localVarFetchArgs =
        PostApiFetchParamCreator(configuration).updatePost(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * PostApi - factory interface
 * @export
 */
export const PostApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Blocks post record.
     * @summary Blocks post.
     * @param {BlockPostRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockPost(fields: BlockPostRequest, options?: any) {
      return PostApiFp(configuration).blockPost(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates new post.
     * @summary Creates post.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPost(options?: any) {
      return PostApiFp(configuration).createPost(options)(fetch, basePath);
    },
    /**
     * Deletes post.
     * @summary Deletes post.
     * @param {string} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePost(postId: string, options?: any) {
      return PostApiFp(configuration).deletePost(postId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves artist posts.
     * @summary Retrieves artist posts.
     * @param {string} artistId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArtistPosts(
      artistId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return PostApiFp(configuration).getArtistPosts(
        artistId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves exclusive posts.
     * @summary Retrieves exclusive posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExclusivePosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return PostApiFp(configuration).getExclusivePosts(
        artistId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves latest posts.
     * @summary Retrieves latest posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestPosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return PostApiFp(configuration).getLatestPosts(
        artistId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves liked posts.
     * @summary Retrieves liked posts.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedPosts(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return PostApiFp(configuration).getLikedPosts(
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves popular posts.
     * @summary Retrieves popular posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularPosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return PostApiFp(configuration).getPopularPosts(
        artistId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves post record by post id.
     * @summary Retrieves post.
     * @param {string} id post id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPost(id: string, options?: any) {
      return PostApiFp(configuration).getPost(id, options)(fetch, basePath);
    },
    /**
     * Retrieves post by post id.
     * @summary Retrieves post by post id.
     * @param {string} id post id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPostInternal(id: string, options?: any) {
      return PostApiFp(configuration).getPostInternal(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves public posts.
     * @summary Retrieves public posts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicPosts(options?: any) {
      return PostApiFp(configuration).getPublicPosts(options)(fetch, basePath);
    },
    /**
     * Retrieves recommended posts.
     * @summary Retrieves recommended posts.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPosts(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return PostApiFp(configuration).getRecommendedPosts(
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves trending posts.
     * @summary Retrieves trending posts.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrendingPosts(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return PostApiFp(configuration).getTrendingPosts(
        artistId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves unlocked posts.
     * @summary Retrieves unlocked posts.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedPosts(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return PostApiFp(configuration).getUnlockedPosts(
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Get user posts count.
     * @summary Get user posts count.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPostsCount(options?: any) {
      return PostApiFp(configuration).getUserPostsCount(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Updates post record.
     * @summary Updates post.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePost(options?: any) {
      return PostApiFp(configuration).updatePost(options)(fetch, basePath);
    },
  };
};

/**
 * PostApi - object-oriented interface
 * @export
 * @class PostApi
 * @extends {BaseAPI}
 */
export class PostApi extends BaseAPI {
  /**
   * Blocks post record.
   * @summary Blocks post.
   * @param {BlockPostRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public blockPost(fields: BlockPostRequest, options?: any) {
    return PostApiFp(this.configuration).blockPost(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates new post.
   * @summary Creates post.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public createPost(options?: any) {
    return PostApiFp(this.configuration).createPost(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Deletes post.
   * @summary Deletes post.
   * @param {string} postId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public deletePost(postId: string, options?: any) {
    return PostApiFp(this.configuration).deletePost(postId, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves artist posts.
   * @summary Retrieves artist posts.
   * @param {string} artistId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getArtistPosts(
    artistId: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return PostApiFp(this.configuration).getArtistPosts(
      artistId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves exclusive posts.
   * @summary Retrieves exclusive posts.
   * @param {string} [artistId]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getExclusivePosts(
    artistId?: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return PostApiFp(this.configuration).getExclusivePosts(
      artistId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves latest posts.
   * @summary Retrieves latest posts.
   * @param {string} [artistId]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getLatestPosts(
    artistId?: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return PostApiFp(this.configuration).getLatestPosts(
      artistId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves liked posts.
   * @summary Retrieves liked posts.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getLikedPosts(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return PostApiFp(this.configuration).getLikedPosts(
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves popular posts.
   * @summary Retrieves popular posts.
   * @param {string} [artistId]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getPopularPosts(
    artistId?: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return PostApiFp(this.configuration).getPopularPosts(
      artistId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves post record by post id.
   * @summary Retrieves post.
   * @param {string} id post id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getPost(id: string, options?: any) {
    return PostApiFp(this.configuration).getPost(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves post by post id.
   * @summary Retrieves post by post id.
   * @param {string} id post id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getPostInternal(id: string, options?: any) {
    return PostApiFp(this.configuration).getPostInternal(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves public posts.
   * @summary Retrieves public posts.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getPublicPosts(options?: any) {
    return PostApiFp(this.configuration).getPublicPosts(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves recommended posts.
   * @summary Retrieves recommended posts.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getRecommendedPosts(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return PostApiFp(this.configuration).getRecommendedPosts(
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves trending posts.
   * @summary Retrieves trending posts.
   * @param {string} [artistId]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getTrendingPosts(
    artistId?: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return PostApiFp(this.configuration).getTrendingPosts(
      artistId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves unlocked posts.
   * @summary Retrieves unlocked posts.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getUnlockedPosts(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return PostApiFp(this.configuration).getUnlockedPosts(
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get user posts count.
   * @summary Get user posts count.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public getUserPostsCount(options?: any) {
    return PostApiFp(this.configuration).getUserPostsCount(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Updates post record.
   * @summary Updates post.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostApi
   */
  public updatePost(options?: any) {
    return PostApiFp(this.configuration).updatePost(options)(
      this.fetch,
      this.basePath,
    );
  }
}

/**
 * ReportApi - fetch parameter creator
 * @export
 */
export const ReportApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates report complaint.
     * @summary Creates report complaint.
     * @param {CreateReportComplaintRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReportComplaint(
      fields: CreateReportComplaintRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createReportComplaint.",
        );
      }
      const localVarPath = `/report/complaint`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateReportComplaintRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves report.
     * @summary Retrieves report.
     * @param {string} id report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getReport.",
        );
      }
      const localVarPath = `/report/admin/report/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves report complaint.
     * @summary Retrieves report complaint.
     * @param {string} id report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportComplaint(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getReportComplaint.",
        );
      }
      const localVarPath = `/report/admin/report/complaint/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves report complaints.
     * @summary Retrieves report complaints.
     * @param {string} reportId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportComplaints(
      reportId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'reportId' is not null or undefined
      if (reportId === null || reportId === undefined) {
        throw new RequiredError(
          "reportId",
          "Required parameter reportId was null or undefined when calling getReportComplaints.",
        );
      }
      const localVarPath = `/report/admin/report/complaint`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (reportId !== undefined) {
        localVarQueryParameter["reportId"] = reportId;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves reports.
     * @summary Retrieves reports.
     * @param {'post' | 'user' | 'track' | 'comment'} [entityType]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReports(
      entityType?: "post" | "user" | "track" | "comment",
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/report/admin/report`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (entityType !== undefined) {
        localVarQueryParameter["entityType"] = entityType;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Resolves report.
     * @summary Resolves report.
     * @param {ResolveReportRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveReport(fields: ResolveReportRequest, options: any = {}): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling resolveReport.",
        );
      }
      const localVarPath = `/report/admin/report/resolve`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"ResolveReportRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Creates report complaint.
     * @summary Creates report complaint.
     * @param {CreateReportComplaintRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReportComplaint(
      fields: CreateReportComplaintRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateReportComplaintResponse> {
      const localVarFetchArgs = ReportApiFetchParamCreator(
        configuration,
      ).createReportComplaint(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves report.
     * @summary Retrieves report.
     * @param {string} id report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetReportResponse> {
      const localVarFetchArgs = ReportApiFetchParamCreator(
        configuration,
      ).getReport(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves report complaint.
     * @summary Retrieves report complaint.
     * @param {string} id report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportComplaint(
      id: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetReportComplaintResponse> {
      const localVarFetchArgs = ReportApiFetchParamCreator(
        configuration,
      ).getReportComplaint(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves report complaints.
     * @summary Retrieves report complaints.
     * @param {string} reportId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportComplaints(
      reportId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetReportComplaintsResponse> {
      const localVarFetchArgs = ReportApiFetchParamCreator(
        configuration,
      ).getReportComplaints(reportId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves reports.
     * @summary Retrieves reports.
     * @param {'post' | 'user' | 'track' | 'comment'} [entityType]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReports(
      entityType?: "post" | "user" | "track" | "comment",
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetReportsResponse> {
      const localVarFetchArgs = ReportApiFetchParamCreator(
        configuration,
      ).getReports(entityType, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Resolves report.
     * @summary Resolves report.
     * @param {ResolveReportRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveReport(
      fields: ResolveReportRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ResolveReportResponse> {
      const localVarFetchArgs = ReportApiFetchParamCreator(
        configuration,
      ).resolveReport(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Creates report complaint.
     * @summary Creates report complaint.
     * @param {CreateReportComplaintRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReportComplaint(fields: CreateReportComplaintRequest, options?: any) {
      return ReportApiFp(configuration).createReportComplaint(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves report.
     * @summary Retrieves report.
     * @param {string} id report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport(id: string, options?: any) {
      return ReportApiFp(configuration).getReport(id, options)(fetch, basePath);
    },
    /**
     * Retrieves report complaint.
     * @summary Retrieves report complaint.
     * @param {string} id report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportComplaint(id: string, options?: any) {
      return ReportApiFp(configuration).getReportComplaint(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves report complaints.
     * @summary Retrieves report complaints.
     * @param {string} reportId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportComplaints(
      reportId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return ReportApiFp(configuration).getReportComplaints(
        reportId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves reports.
     * @summary Retrieves reports.
     * @param {'post' | 'user' | 'track' | 'comment'} [entityType]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReports(
      entityType?: "post" | "user" | "track" | "comment",
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return ReportApiFp(configuration).getReports(
        entityType,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Resolves report.
     * @summary Resolves report.
     * @param {ResolveReportRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveReport(fields: ResolveReportRequest, options?: any) {
      return ReportApiFp(configuration).resolveReport(fields, options)(
        fetch,
        basePath,
      );
    },
  };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
  /**
   * Creates report complaint.
   * @summary Creates report complaint.
   * @param {CreateReportComplaintRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public createReportComplaint(
    fields: CreateReportComplaintRequest,
    options?: any,
  ) {
    return ReportApiFp(this.configuration).createReportComplaint(
      fields,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves report.
   * @summary Retrieves report.
   * @param {string} id report id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getReport(id: string, options?: any) {
    return ReportApiFp(this.configuration).getReport(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves report complaint.
   * @summary Retrieves report complaint.
   * @param {string} id report id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getReportComplaint(id: string, options?: any) {
    return ReportApiFp(this.configuration).getReportComplaint(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves report complaints.
   * @summary Retrieves report complaints.
   * @param {string} reportId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getReportComplaints(
    reportId: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return ReportApiFp(this.configuration).getReportComplaints(
      reportId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves reports.
   * @summary Retrieves reports.
   * @param {'post' | 'user' | 'track' | 'comment'} [entityType]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getReports(
    entityType?: "post" | "user" | "track" | "comment",
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return ReportApiFp(this.configuration).getReports(
      entityType,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Resolves report.
   * @summary Resolves report.
   * @param {ResolveReportRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public resolveReport(fields: ResolveReportRequest, options?: any) {
    return ReportApiFp(this.configuration).resolveReport(fields, options)(
      this.fetch,
      this.basePath,
    );
  }
}

/**
 * StreamApi - fetch parameter creator
 * @export
 */
export const StreamApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates comment stream.
     * @summary Creates comment stream.
     * @param {CreateCommentStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentStream(
      fields: CreateCommentStreamRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createCommentStream.",
        );
      }
      const localVarPath = `/stream/comment`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateCommentStreamRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates post stream.
     * @summary Creates post stream.
     * @param {CreatePostStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPostStream(
      fields: CreatePostStreamRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createPostStream.",
        );
      }
      const localVarPath = `/stream/post`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreatePostStreamRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates track stream.
     * @summary Creates track stream.
     * @param {CreateTrackStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrackStream(
      fields: CreateTrackStreamRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createTrackStream.",
        );
      }
      const localVarPath = `/stream/track`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateTrackStreamRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates user stream.
     * @summary Creates user stream.
     * @param {CreateUserStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserStream(
      fields: CreateUserStreamRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createUserStream.",
        );
      }
      const localVarPath = `/stream/user`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateUserStreamRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StreamApi - functional programming interface
 * @export
 */
export const StreamApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Creates comment stream.
     * @summary Creates comment stream.
     * @param {CreateCommentStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentStream(
      fields: CreateCommentStreamRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateCommentStreamResponse> {
      const localVarFetchArgs = StreamApiFetchParamCreator(
        configuration,
      ).createCommentStream(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates post stream.
     * @summary Creates post stream.
     * @param {CreatePostStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPostStream(
      fields: CreatePostStreamRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreatePostStreamResponse> {
      const localVarFetchArgs = StreamApiFetchParamCreator(
        configuration,
      ).createPostStream(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates track stream.
     * @summary Creates track stream.
     * @param {CreateTrackStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrackStream(
      fields: CreateTrackStreamRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateTrackStreamResponse> {
      const localVarFetchArgs = StreamApiFetchParamCreator(
        configuration,
      ).createTrackStream(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates user stream.
     * @summary Creates user stream.
     * @param {CreateUserStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserStream(
      fields: CreateUserStreamRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateUserStreamResponse> {
      const localVarFetchArgs = StreamApiFetchParamCreator(
        configuration,
      ).createUserStream(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * StreamApi - factory interface
 * @export
 */
export const StreamApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Creates comment stream.
     * @summary Creates comment stream.
     * @param {CreateCommentStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentStream(fields: CreateCommentStreamRequest, options?: any) {
      return StreamApiFp(configuration).createCommentStream(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates post stream.
     * @summary Creates post stream.
     * @param {CreatePostStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPostStream(fields: CreatePostStreamRequest, options?: any) {
      return StreamApiFp(configuration).createPostStream(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates track stream.
     * @summary Creates track stream.
     * @param {CreateTrackStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrackStream(fields: CreateTrackStreamRequest, options?: any) {
      return StreamApiFp(configuration).createTrackStream(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates user stream.
     * @summary Creates user stream.
     * @param {CreateUserStreamRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserStream(fields: CreateUserStreamRequest, options?: any) {
      return StreamApiFp(configuration).createUserStream(fields, options)(
        fetch,
        basePath,
      );
    },
  };
};

/**
 * StreamApi - object-oriented interface
 * @export
 * @class StreamApi
 * @extends {BaseAPI}
 */
export class StreamApi extends BaseAPI {
  /**
   * Creates comment stream.
   * @summary Creates comment stream.
   * @param {CreateCommentStreamRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StreamApi
   */
  public createCommentStream(
    fields: CreateCommentStreamRequest,
    options?: any,
  ) {
    return StreamApiFp(this.configuration).createCommentStream(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates post stream.
   * @summary Creates post stream.
   * @param {CreatePostStreamRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StreamApi
   */
  public createPostStream(fields: CreatePostStreamRequest, options?: any) {
    return StreamApiFp(this.configuration).createPostStream(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates track stream.
   * @summary Creates track stream.
   * @param {CreateTrackStreamRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StreamApi
   */
  public createTrackStream(fields: CreateTrackStreamRequest, options?: any) {
    return StreamApiFp(this.configuration).createTrackStream(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates user stream.
   * @summary Creates user stream.
   * @param {CreateUserStreamRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StreamApi
   */
  public createUserStream(fields: CreateUserStreamRequest, options?: any) {
    return StreamApiFp(this.configuration).createUserStream(fields, options)(
      this.fetch,
      this.basePath,
    );
  }
}

/**
 * SubscriptionApi - fetch parameter creator
 * @export
 */
export const SubscriptionApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Cancels subscription.
     * @summary Cancels subscription.
     * @param {CancelSubscriptionRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscription(
      fields: CancelSubscriptionRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling cancelSubscription.",
        );
      }
      const localVarPath = `/subscription/cancel`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CancelSubscriptionRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates new subscription.
     * @summary Creates subscription.
     * @param {CreateSubscriptionRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscription(
      fields: CreateSubscriptionRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createSubscription.",
        );
      }
      const localVarPath = `/subscription`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateSubscriptionRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves ids of users that source user subscribed to and that ends soon.
     * @summary Retrieves ids of users that source user subscribed to and that ends soon.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedAtPeriodEndUserIds(options: any = {}): FetchArgs {
      const localVarPath = `/subscription/subscribed-at-period-end-ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves ids of users that source user subscribed to.
     * @summary Retrieves ids of users that source user subscribed to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedUserIds(options: any = {}): FetchArgs {
      const localVarPath = `/subscription/subscribed-ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves ids of users that source user subscribed to.
     * @summary Retrieves ids of users that source user subscribed to.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getSubscribedUserIdsInternal.",
        );
      }
      const localVarPath = `/subscription/internal/subscription/subscribed-ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (random !== undefined) {
        localVarQueryParameter["random"] = random;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves ids of users that subscribing to source user.
     * @summary Retrieves ids of users that subscribing to source user.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribingUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getSubscribingUserIdsInternal.",
        );
      }
      const localVarPath = `/subscription/internal/subscription/subscribing-ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (random !== undefined) {
        localVarQueryParameter["random"] = random;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves subscription record by subscription id.
     * @summary Retrieves subscription.
     * @param {string} id subscription id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscription(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getSubscription.",
        );
      }
      const localVarPath = `/subscription/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves user subscription price.
     * @summary Retrieves user subscription price.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSubscriptionPrice(userId: string, options: any = {}): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getUserSubscriptionPrice.",
        );
      }
      const localVarPath = `/subscription/user/price`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Renews subscription.
     * @summary Renews subscription.
     * @param {RenewSubscriptionRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renewSubscription(
      fields: RenewSubscriptionRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling renewSubscription.",
        );
      }
      const localVarPath = `/subscription/renew`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"RenewSubscriptionRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Cancels subscription.
     * @summary Cancels subscription.
     * @param {CancelSubscriptionRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscription(
      fields: CancelSubscriptionRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CancelSubscriptionResponse> {
      const localVarFetchArgs = SubscriptionApiFetchParamCreator(
        configuration,
      ).cancelSubscription(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates new subscription.
     * @summary Creates subscription.
     * @param {CreateSubscriptionRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscription(
      fields: CreateSubscriptionRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateSubscriptionResponse> {
      const localVarFetchArgs = SubscriptionApiFetchParamCreator(
        configuration,
      ).createSubscription(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves ids of users that source user subscribed to and that ends soon.
     * @summary Retrieves ids of users that source user subscribed to and that ends soon.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedAtPeriodEndUserIds(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetSubscribedAtPeriodEndUserIdsResponse> {
      const localVarFetchArgs =
        SubscriptionApiFetchParamCreator(
          configuration,
        ).getSubscribedAtPeriodEndUserIds(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves ids of users that source user subscribed to.
     * @summary Retrieves ids of users that source user subscribed to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedUserIds(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetSubscribedUserIdsResponse> {
      const localVarFetchArgs =
        SubscriptionApiFetchParamCreator(configuration).getSubscribedUserIds(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves ids of users that source user subscribed to.
     * @summary Retrieves ids of users that source user subscribed to.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetSubscribedUserIdsInternalResponse> {
      const localVarFetchArgs = SubscriptionApiFetchParamCreator(
        configuration,
      ).getSubscribedUserIdsInternal(userId, limit, offset, random, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves ids of users that subscribing to source user.
     * @summary Retrieves ids of users that subscribing to source user.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribingUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetSubscribingUserIdsInternalResponse> {
      const localVarFetchArgs = SubscriptionApiFetchParamCreator(
        configuration,
      ).getSubscribingUserIdsInternal(userId, limit, offset, random, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves subscription record by subscription id.
     * @summary Retrieves subscription.
     * @param {string} id subscription id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscription(
      id: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetSubscriptionResponse> {
      const localVarFetchArgs = SubscriptionApiFetchParamCreator(
        configuration,
      ).getSubscription(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves user subscription price.
     * @summary Retrieves user subscription price.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSubscriptionPrice(
      userId: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUserSubscriptionPriceResponse> {
      const localVarFetchArgs = SubscriptionApiFetchParamCreator(
        configuration,
      ).getUserSubscriptionPrice(userId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Renews subscription.
     * @summary Renews subscription.
     * @param {RenewSubscriptionRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renewSubscription(
      fields: RenewSubscriptionRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<RenewSubscriptionResponse> {
      const localVarFetchArgs = SubscriptionApiFetchParamCreator(
        configuration,
      ).renewSubscription(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Cancels subscription.
     * @summary Cancels subscription.
     * @param {CancelSubscriptionRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscription(fields: CancelSubscriptionRequest, options?: any) {
      return SubscriptionApiFp(configuration).cancelSubscription(
        fields,
        options,
      )(fetch, basePath);
    },
    /**
     * Creates new subscription.
     * @summary Creates subscription.
     * @param {CreateSubscriptionRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscription(fields: CreateSubscriptionRequest, options?: any) {
      return SubscriptionApiFp(configuration).createSubscription(
        fields,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves ids of users that source user subscribed to and that ends soon.
     * @summary Retrieves ids of users that source user subscribed to and that ends soon.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedAtPeriodEndUserIds(options?: any) {
      return SubscriptionApiFp(configuration).getSubscribedAtPeriodEndUserIds(
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves ids of users that source user subscribed to.
     * @summary Retrieves ids of users that source user subscribed to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedUserIds(options?: any) {
      return SubscriptionApiFp(configuration).getSubscribedUserIds(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves ids of users that source user subscribed to.
     * @summary Retrieves ids of users that source user subscribed to.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ) {
      return SubscriptionApiFp(configuration).getSubscribedUserIdsInternal(
        userId,
        limit,
        offset,
        random,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves ids of users that subscribing to source user.
     * @summary Retrieves ids of users that subscribing to source user.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {boolean} [random]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribingUserIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      random?: boolean,
      options?: any,
    ) {
      return SubscriptionApiFp(configuration).getSubscribingUserIdsInternal(
        userId,
        limit,
        offset,
        random,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves subscription record by subscription id.
     * @summary Retrieves subscription.
     * @param {string} id subscription id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscription(id: string, options?: any) {
      return SubscriptionApiFp(configuration).getSubscription(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves user subscription price.
     * @summary Retrieves user subscription price.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSubscriptionPrice(userId: string, options?: any) {
      return SubscriptionApiFp(configuration).getUserSubscriptionPrice(
        userId,
        options,
      )(fetch, basePath);
    },
    /**
     * Renews subscription.
     * @summary Renews subscription.
     * @param {RenewSubscriptionRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renewSubscription(fields: RenewSubscriptionRequest, options?: any) {
      return SubscriptionApiFp(configuration).renewSubscription(
        fields,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
  /**
   * Cancels subscription.
   * @summary Cancels subscription.
   * @param {CancelSubscriptionRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public cancelSubscription(fields: CancelSubscriptionRequest, options?: any) {
    return SubscriptionApiFp(this.configuration).cancelSubscription(
      fields,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Creates new subscription.
   * @summary Creates subscription.
   * @param {CreateSubscriptionRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public createSubscription(fields: CreateSubscriptionRequest, options?: any) {
    return SubscriptionApiFp(this.configuration).createSubscription(
      fields,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves ids of users that source user subscribed to and that ends soon.
   * @summary Retrieves ids of users that source user subscribed to and that ends soon.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public getSubscribedAtPeriodEndUserIds(options?: any) {
    return SubscriptionApiFp(
      this.configuration,
    ).getSubscribedAtPeriodEndUserIds(options)(this.fetch, this.basePath);
  }

  /**
   * Retrieves ids of users that source user subscribed to.
   * @summary Retrieves ids of users that source user subscribed to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public getSubscribedUserIds(options?: any) {
    return SubscriptionApiFp(this.configuration).getSubscribedUserIds(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves ids of users that source user subscribed to.
   * @summary Retrieves ids of users that source user subscribed to.
   * @param {string} userId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {boolean} [random]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public getSubscribedUserIdsInternal(
    userId: string,
    limit?: number,
    offset?: number,
    random?: boolean,
    options?: any,
  ) {
    return SubscriptionApiFp(this.configuration).getSubscribedUserIdsInternal(
      userId,
      limit,
      offset,
      random,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves ids of users that subscribing to source user.
   * @summary Retrieves ids of users that subscribing to source user.
   * @param {string} userId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {boolean} [random]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public getSubscribingUserIdsInternal(
    userId: string,
    limit?: number,
    offset?: number,
    random?: boolean,
    options?: any,
  ) {
    return SubscriptionApiFp(this.configuration).getSubscribingUserIdsInternal(
      userId,
      limit,
      offset,
      random,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves subscription record by subscription id.
   * @summary Retrieves subscription.
   * @param {string} id subscription id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public getSubscription(id: string, options?: any) {
    return SubscriptionApiFp(this.configuration).getSubscription(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves user subscription price.
   * @summary Retrieves user subscription price.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public getUserSubscriptionPrice(userId: string, options?: any) {
    return SubscriptionApiFp(this.configuration).getUserSubscriptionPrice(
      userId,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Renews subscription.
   * @summary Renews subscription.
   * @param {RenewSubscriptionRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public renewSubscription(fields: RenewSubscriptionRequest, options?: any) {
    return SubscriptionApiFp(this.configuration).renewSubscription(
      fields,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * TipApi - fetch parameter creator
 * @export
 */
export const TipApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates new tip.
     * @summary Creates tip.
     * @param {CreateTipRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTip(fields: CreateTipRequest, options: any = {}): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createTip.",
        );
      }
      const localVarPath = `/tip`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateTipRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves tip record by tip id.
     * @summary Retrieves tip.
     * @param {string} id tip id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTip(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getTip.",
        );
      }
      const localVarPath = `/tip/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TipApi - functional programming interface
 * @export
 */
export const TipApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Creates new tip.
     * @summary Creates tip.
     * @param {CreateTipRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTip(
      fields: CreateTipRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateTipResponse> {
      const localVarFetchArgs = TipApiFetchParamCreator(
        configuration,
      ).createTip(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves tip record by tip id.
     * @summary Retrieves tip.
     * @param {string} id tip id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTip(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetTipResponse> {
      const localVarFetchArgs = TipApiFetchParamCreator(configuration).getTip(
        id,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * TipApi - factory interface
 * @export
 */
export const TipApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Creates new tip.
     * @summary Creates tip.
     * @param {CreateTipRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTip(fields: CreateTipRequest, options?: any) {
      return TipApiFp(configuration).createTip(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves tip record by tip id.
     * @summary Retrieves tip.
     * @param {string} id tip id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTip(id: string, options?: any) {
      return TipApiFp(configuration).getTip(id, options)(fetch, basePath);
    },
  };
};

/**
 * TipApi - object-oriented interface
 * @export
 * @class TipApi
 * @extends {BaseAPI}
 */
export class TipApi extends BaseAPI {
  /**
   * Creates new tip.
   * @summary Creates tip.
   * @param {CreateTipRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TipApi
   */
  public createTip(fields: CreateTipRequest, options?: any) {
    return TipApiFp(this.configuration).createTip(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves tip record by tip id.
   * @summary Retrieves tip.
   * @param {string} id tip id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TipApi
   */
  public getTip(id: string, options?: any) {
    return TipApiFp(this.configuration).getTip(id, options)(
      this.fetch,
      this.basePath,
    );
  }
}

/**
 * TrackApi - fetch parameter creator
 * @export
 */
export const TrackApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Blocks track record.
     * @summary Blocks track.
     * @param {BlockTrackRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockTrack(fields: BlockTrackRequest, options: any = {}): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling blockTrack.",
        );
      }
      const localVarPath = `/track/admin/track/block`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"BlockTrackRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates new track.
     * @summary Creates track.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrack(options: any = {}): FetchArgs {
      const localVarPath = `/track/artist/track`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes track.
     * @summary Deletes track.
     * @param {string} trackId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTrack(trackId: string, options: any = {}): FetchArgs {
      // verify required parameter 'trackId' is not null or undefined
      if (trackId === null || trackId === undefined) {
        throw new RequiredError(
          "trackId",
          "Required parameter trackId was null or undefined when calling deleteTrack.",
        );
      }
      const localVarPath = `/track/artist/track`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: "DELETE" },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (trackId !== undefined) {
        localVarQueryParameter["trackId"] = trackId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves artist tracks.
     * @summary Retrieves artist tracks.
     * @param {string} artistId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArtistTracks(
      artistId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'artistId' is not null or undefined
      if (artistId === null || artistId === undefined) {
        throw new RequiredError(
          "artistId",
          "Required parameter artistId was null or undefined when calling getArtistTracks.",
        );
      }
      const localVarPath = `/track/artist`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (artistId !== undefined) {
        localVarQueryParameter["artistId"] = artistId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves exclusive tracks.
     * @summary Retrieves exclusive tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExclusiveTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/track/exclusive`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (artistId !== undefined) {
        localVarQueryParameter["artistId"] = artistId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves latest tracks.
     * @summary Retrieves latest tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/track/latest`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (artistId !== undefined) {
        localVarQueryParameter["artistId"] = artistId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves liked tracks.
     * @summary Retrieves liked tracks.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedTracks(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/track/liked`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves popular tracks.
     * @summary Retrieves popular tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/track/popular`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (artistId !== undefined) {
        localVarQueryParameter["artistId"] = artistId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves public tracks.
     * @summary Retrieves public tracks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicTracks(options: any = {}): FetchArgs {
      const localVarPath = `/track/public/track`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves public tracks by given ids.
     * @summary Retrieves public tracks by given ids.
     * @param {string} [trackIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicTracksByIds(trackIds?: string, options: any = {}): FetchArgs {
      const localVarPath = `/track/public/track/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (trackIds !== undefined) {
        localVarQueryParameter["trackIds"] = trackIds;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves recommended tracks.
     * @summary Retrieves recommended tracks.
     * @param {string} [genres]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedTracks(
      genres?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/track/recommended`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (genres !== undefined) {
        localVarQueryParameter["genres"] = genres;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves track record by track id.
     * @summary Retrieves track.
     * @param {string} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrack(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getTrack.",
        );
      }
      const localVarPath = `/track/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves track record by track id.
     * @summary Retrieves track.
     * @param {string} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrackInternal(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getTrackInternal.",
        );
      }
      const localVarPath = `/track/internal/track/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves tracks author valid state.
     * @summary Retrieves tracks author valid state.
     * @param {string} trackIds
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTracksAuthorValid(
      trackIds: string,
      userId: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'trackIds' is not null or undefined
      if (trackIds === null || trackIds === undefined) {
        throw new RequiredError(
          "trackIds",
          "Required parameter trackIds was null or undefined when calling getTracksAuthorValid.",
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getTracksAuthorValid.",
        );
      }
      const localVarPath = `/track/internal/track/author-valid`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (trackIds !== undefined) {
        localVarQueryParameter["trackIds"] = trackIds;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves tracks by ids.
     * @summary Retrieves tracks by ids.
     * @param {string} [trackIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTracksByIds(trackIds?: string, options: any = {}): FetchArgs {
      const localVarPath = `/track/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (trackIds !== undefined) {
        localVarQueryParameter["trackIds"] = trackIds;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves trending tracks.
     * @summary Retrieves trending tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrendingTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/track/trending`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (artistId !== undefined) {
        localVarQueryParameter["artistId"] = artistId;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves unlocked tracks.
     * @summary Retrieves unlocked tracks.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedTracks(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/track/unlocked`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get user tracks count.
     * @summary Get user tracks count.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserTracksCount(options: any = {}): FetchArgs {
      const localVarPath = `/track/artist/track/count`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates track record.
     * @summary Updates track.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrack(options: any = {}): FetchArgs {
      const localVarPath = `/track/artist/track`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TrackApi - functional programming interface
 * @export
 */
export const TrackApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Blocks track record.
     * @summary Blocks track.
     * @param {BlockTrackRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockTrack(
      fields: BlockTrackRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).blockTrack(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates new track.
     * @summary Creates track.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrack(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateTrackResponse> {
      const localVarFetchArgs =
        TrackApiFetchParamCreator(configuration).createTrack(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes track.
     * @summary Deletes track.
     * @param {string} trackId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTrack(
      trackId: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).deleteTrack(trackId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves artist tracks.
     * @summary Retrieves artist tracks.
     * @param {string} artistId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArtistTracks(
      artistId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetArtistTracksResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getArtistTracks(artistId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves exclusive tracks.
     * @summary Retrieves exclusive tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExclusiveTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetExclusiveTracksResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getExclusiveTracks(artistId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves latest tracks.
     * @summary Retrieves latest tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetLatestTracksResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getLatestTracks(artistId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves liked tracks.
     * @summary Retrieves liked tracks.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedTracks(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetLikedTracksResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getLikedTracks(limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves popular tracks.
     * @summary Retrieves popular tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetPopularTracksResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getPopularTracks(artistId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves public tracks.
     * @summary Retrieves public tracks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicTracks(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetPublicTracksResponse> {
      const localVarFetchArgs =
        TrackApiFetchParamCreator(configuration).getPublicTracks(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves public tracks by given ids.
     * @summary Retrieves public tracks by given ids.
     * @param {string} [trackIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicTracksByIds(
      trackIds?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetPublicTracksByIdsResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getPublicTracksByIds(trackIds, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves recommended tracks.
     * @summary Retrieves recommended tracks.
     * @param {string} [genres]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedTracks(
      genres?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetRecommendedTracksResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getRecommendedTracks(genres, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves track record by track id.
     * @summary Retrieves track.
     * @param {string} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrack(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetTrackResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getTrack(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves track record by track id.
     * @summary Retrieves track.
     * @param {string} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrackInternal(
      id: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetTrackInternalResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getTrackInternal(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves tracks author valid state.
     * @summary Retrieves tracks author valid state.
     * @param {string} trackIds
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTracksAuthorValid(
      trackIds: string,
      userId: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetTracksAuthorValidResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getTracksAuthorValid(trackIds, userId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves tracks by ids.
     * @summary Retrieves tracks by ids.
     * @param {string} [trackIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTracksByIds(
      trackIds?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetTracksByIdsResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getTracksByIds(trackIds, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves trending tracks.
     * @summary Retrieves trending tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrendingTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetTrendingTracksResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getTrendingTracks(artistId, limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves unlocked tracks.
     * @summary Retrieves unlocked tracks.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedTracks(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUnlockedTracksResponse> {
      const localVarFetchArgs = TrackApiFetchParamCreator(
        configuration,
      ).getUnlockedTracks(limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get user tracks count.
     * @summary Get user tracks count.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserTracksCount(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUserTracksCountResponse> {
      const localVarFetchArgs =
        TrackApiFetchParamCreator(configuration).getUserTracksCount(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates track record.
     * @summary Updates track.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrack(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<UpdateTrackResponse> {
      const localVarFetchArgs =
        TrackApiFetchParamCreator(configuration).updateTrack(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * TrackApi - factory interface
 * @export
 */
export const TrackApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Blocks track record.
     * @summary Blocks track.
     * @param {BlockTrackRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockTrack(fields: BlockTrackRequest, options?: any) {
      return TrackApiFp(configuration).blockTrack(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates new track.
     * @summary Creates track.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrack(options?: any) {
      return TrackApiFp(configuration).createTrack(options)(fetch, basePath);
    },
    /**
     * Deletes track.
     * @summary Deletes track.
     * @param {string} trackId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTrack(trackId: string, options?: any) {
      return TrackApiFp(configuration).deleteTrack(trackId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves artist tracks.
     * @summary Retrieves artist tracks.
     * @param {string} artistId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArtistTracks(
      artistId: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return TrackApiFp(configuration).getArtistTracks(
        artistId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves exclusive tracks.
     * @summary Retrieves exclusive tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExclusiveTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return TrackApiFp(configuration).getExclusiveTracks(
        artistId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves latest tracks.
     * @summary Retrieves latest tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return TrackApiFp(configuration).getLatestTracks(
        artistId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves liked tracks.
     * @summary Retrieves liked tracks.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLikedTracks(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return TrackApiFp(configuration).getLikedTracks(
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves popular tracks.
     * @summary Retrieves popular tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return TrackApiFp(configuration).getPopularTracks(
        artistId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves public tracks.
     * @summary Retrieves public tracks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicTracks(options?: any) {
      return TrackApiFp(configuration).getPublicTracks(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves public tracks by given ids.
     * @summary Retrieves public tracks by given ids.
     * @param {string} [trackIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicTracksByIds(trackIds?: string, options?: any) {
      return TrackApiFp(configuration).getPublicTracksByIds(trackIds, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves recommended tracks.
     * @summary Retrieves recommended tracks.
     * @param {string} [genres]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedTracks(
      genres?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return TrackApiFp(configuration).getRecommendedTracks(
        genres,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves track record by track id.
     * @summary Retrieves track.
     * @param {string} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrack(id: string, options?: any) {
      return TrackApiFp(configuration).getTrack(id, options)(fetch, basePath);
    },
    /**
     * Retrieves track record by track id.
     * @summary Retrieves track.
     * @param {string} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrackInternal(id: string, options?: any) {
      return TrackApiFp(configuration).getTrackInternal(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves tracks author valid state.
     * @summary Retrieves tracks author valid state.
     * @param {string} trackIds
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTracksAuthorValid(trackIds: string, userId: string, options?: any) {
      return TrackApiFp(configuration).getTracksAuthorValid(
        trackIds,
        userId,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves tracks by ids.
     * @summary Retrieves tracks by ids.
     * @param {string} [trackIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTracksByIds(trackIds?: string, options?: any) {
      return TrackApiFp(configuration).getTracksByIds(trackIds, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves trending tracks.
     * @summary Retrieves trending tracks.
     * @param {string} [artistId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrendingTracks(
      artistId?: string,
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return TrackApiFp(configuration).getTrendingTracks(
        artistId,
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves unlocked tracks.
     * @summary Retrieves unlocked tracks.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedTracks(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return TrackApiFp(configuration).getUnlockedTracks(
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Get user tracks count.
     * @summary Get user tracks count.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserTracksCount(options?: any) {
      return TrackApiFp(configuration).getUserTracksCount(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Updates track record.
     * @summary Updates track.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrack(options?: any) {
      return TrackApiFp(configuration).updateTrack(options)(fetch, basePath);
    },
  };
};

/**
 * TrackApi - object-oriented interface
 * @export
 * @class TrackApi
 * @extends {BaseAPI}
 */
export class TrackApi extends BaseAPI {
  /**
   * Blocks track record.
   * @summary Blocks track.
   * @param {BlockTrackRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public blockTrack(fields: BlockTrackRequest, options?: any) {
    return TrackApiFp(this.configuration).blockTrack(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates new track.
   * @summary Creates track.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public createTrack(options?: any) {
    return TrackApiFp(this.configuration).createTrack(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Deletes track.
   * @summary Deletes track.
   * @param {string} trackId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public deleteTrack(trackId: string, options?: any) {
    return TrackApiFp(this.configuration).deleteTrack(trackId, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves artist tracks.
   * @summary Retrieves artist tracks.
   * @param {string} artistId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getArtistTracks(
    artistId: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return TrackApiFp(this.configuration).getArtistTracks(
      artistId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves exclusive tracks.
   * @summary Retrieves exclusive tracks.
   * @param {string} [artistId]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getExclusiveTracks(
    artistId?: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return TrackApiFp(this.configuration).getExclusiveTracks(
      artistId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves latest tracks.
   * @summary Retrieves latest tracks.
   * @param {string} [artistId]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getLatestTracks(
    artistId?: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return TrackApiFp(this.configuration).getLatestTracks(
      artistId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves liked tracks.
   * @summary Retrieves liked tracks.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getLikedTracks(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return TrackApiFp(this.configuration).getLikedTracks(
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves popular tracks.
   * @summary Retrieves popular tracks.
   * @param {string} [artistId]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getPopularTracks(
    artistId?: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return TrackApiFp(this.configuration).getPopularTracks(
      artistId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves public tracks.
   * @summary Retrieves public tracks.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getPublicTracks(options?: any) {
    return TrackApiFp(this.configuration).getPublicTracks(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves public tracks by given ids.
   * @summary Retrieves public tracks by given ids.
   * @param {string} [trackIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getPublicTracksByIds(trackIds?: string, options?: any) {
    return TrackApiFp(this.configuration).getPublicTracksByIds(
      trackIds,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves recommended tracks.
   * @summary Retrieves recommended tracks.
   * @param {string} [genres]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getRecommendedTracks(
    genres?: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return TrackApiFp(this.configuration).getRecommendedTracks(
      genres,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves track record by track id.
   * @summary Retrieves track.
   * @param {string} id track id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getTrack(id: string, options?: any) {
    return TrackApiFp(this.configuration).getTrack(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves track record by track id.
   * @summary Retrieves track.
   * @param {string} id track id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getTrackInternal(id: string, options?: any) {
    return TrackApiFp(this.configuration).getTrackInternal(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves tracks author valid state.
   * @summary Retrieves tracks author valid state.
   * @param {string} trackIds
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getTracksAuthorValid(trackIds: string, userId: string, options?: any) {
    return TrackApiFp(this.configuration).getTracksAuthorValid(
      trackIds,
      userId,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves tracks by ids.
   * @summary Retrieves tracks by ids.
   * @param {string} [trackIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getTracksByIds(trackIds?: string, options?: any) {
    return TrackApiFp(this.configuration).getTracksByIds(trackIds, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves trending tracks.
   * @summary Retrieves trending tracks.
   * @param {string} [artistId]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getTrendingTracks(
    artistId?: string,
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return TrackApiFp(this.configuration).getTrendingTracks(
      artistId,
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves unlocked tracks.
   * @summary Retrieves unlocked tracks.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getUnlockedTracks(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return TrackApiFp(this.configuration).getUnlockedTracks(
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get user tracks count.
   * @summary Get user tracks count.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public getUserTracksCount(options?: any) {
    return TrackApiFp(this.configuration).getUserTracksCount(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Updates track record.
   * @summary Updates track.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public updateTrack(options?: any) {
    return TrackApiFp(this.configuration).updateTrack(options)(
      this.fetch,
      this.basePath,
    );
  }
}

/**
 * TransactionApi - fetch parameter creator
 * @export
 */
export const TransactionApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Retrieves income transactions.
     * @summary Retrieves income transactions.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {'tip' | 'unlock' | 'subscription' | 'payout'} [transactionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIncomeTransactions(
      limit?: number,
      offset?: number,
      transactionType?: "tip" | "unlock" | "subscription" | "payout",
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/transaction/artist/transaction/income`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (transactionType !== undefined) {
        localVarQueryParameter["transactionType"] = transactionType;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves outgoing transactions.
     * @summary Retrieves outgoing transactions.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {'tip' | 'unlock' | 'subscription' | 'payout'} [transactionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOutgoingTransactions(
      limit?: number,
      offset?: number,
      transactionType?: "tip" | "unlock" | "subscription" | "payout",
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/transaction/outgoing`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (transactionType !== undefined) {
        localVarQueryParameter["transactionType"] = transactionType;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Retrieves income transactions.
     * @summary Retrieves income transactions.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {'tip' | 'unlock' | 'subscription' | 'payout'} [transactionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIncomeTransactions(
      limit?: number,
      offset?: number,
      transactionType?: "tip" | "unlock" | "subscription" | "payout",
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetIncomeTransactionsResponse> {
      const localVarFetchArgs = TransactionApiFetchParamCreator(
        configuration,
      ).getIncomeTransactions(limit, offset, transactionType, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves outgoing transactions.
     * @summary Retrieves outgoing transactions.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {'tip' | 'unlock' | 'subscription' | 'payout'} [transactionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOutgoingTransactions(
      limit?: number,
      offset?: number,
      transactionType?: "tip" | "unlock" | "subscription" | "payout",
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetOutgoingTransactionsResponse> {
      const localVarFetchArgs = TransactionApiFetchParamCreator(
        configuration,
      ).getOutgoingTransactions(limit, offset, transactionType, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Retrieves income transactions.
     * @summary Retrieves income transactions.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {'tip' | 'unlock' | 'subscription' | 'payout'} [transactionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIncomeTransactions(
      limit?: number,
      offset?: number,
      transactionType?: "tip" | "unlock" | "subscription" | "payout",
      options?: any,
    ) {
      return TransactionApiFp(configuration).getIncomeTransactions(
        limit,
        offset,
        transactionType,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves outgoing transactions.
     * @summary Retrieves outgoing transactions.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {'tip' | 'unlock' | 'subscription' | 'payout'} [transactionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOutgoingTransactions(
      limit?: number,
      offset?: number,
      transactionType?: "tip" | "unlock" | "subscription" | "payout",
      options?: any,
    ) {
      return TransactionApiFp(configuration).getOutgoingTransactions(
        limit,
        offset,
        transactionType,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
  /**
   * Retrieves income transactions.
   * @summary Retrieves income transactions.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {'tip' | 'unlock' | 'subscription' | 'payout'} [transactionType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionApi
   */
  public getIncomeTransactions(
    limit?: number,
    offset?: number,
    transactionType?: "tip" | "unlock" | "subscription" | "payout",
    options?: any,
  ) {
    return TransactionApiFp(this.configuration).getIncomeTransactions(
      limit,
      offset,
      transactionType,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves outgoing transactions.
   * @summary Retrieves outgoing transactions.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {'tip' | 'unlock' | 'subscription' | 'payout'} [transactionType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionApi
   */
  public getOutgoingTransactions(
    limit?: number,
    offset?: number,
    transactionType?: "tip" | "unlock" | "subscription" | "payout",
    options?: any,
  ) {
    return TransactionApiFp(this.configuration).getOutgoingTransactions(
      limit,
      offset,
      transactionType,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * UnlockApi - fetch parameter creator
 * @export
 */
export const UnlockApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates new post unlock.
     * @summary Creates post unlock.
     * @param {CreatePostUnlockRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPostUnlock(
      fields: CreatePostUnlockRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createPostUnlock.",
        );
      }
      const localVarPath = `/unlock/post`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreatePostUnlockRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates new track unlock.
     * @summary Creates track unlock.
     * @param {CreateTrackUnlockRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrackUnlock(
      fields: CreateTrackUnlockRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createTrackUnlock.",
        );
      }
      const localVarPath = `/unlock/track`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateTrackUnlockRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves unlock record by unlock id.
     * @summary Retrieves unlock.
     * @param {string} id unlock id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlock(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getUnlock.",
        );
      }
      const localVarPath = `/unlock/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves unlocked post ids.
     * @summary Retrieves unlocked post ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedPostIds(options: any = {}): FetchArgs {
      const localVarPath = `/unlock/post/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves unlocked post ids.
     * @summary Retrieves unlocked post ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedPostIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getUnlockedPostIdsInternal.",
        );
      }
      const localVarPath = `/unlock/internal/unlock/post/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves unlocked track ids.
     * @summary Retrieves unlocked track ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedTrackIds(options: any = {}): FetchArgs {
      const localVarPath = `/unlock/track/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves unlocked track ids.
     * @summary Retrieves unlocked track ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedTrackIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getUnlockedTrackIdsInternal.",
        );
      }
      const localVarPath = `/unlock/internal/unlock/track/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UnlockApi - functional programming interface
 * @export
 */
export const UnlockApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Creates new post unlock.
     * @summary Creates post unlock.
     * @param {CreatePostUnlockRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPostUnlock(
      fields: CreatePostUnlockRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreatePostUnlockResponse> {
      const localVarFetchArgs = UnlockApiFetchParamCreator(
        configuration,
      ).createPostUnlock(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates new track unlock.
     * @summary Creates track unlock.
     * @param {CreateTrackUnlockRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrackUnlock(
      fields: CreateTrackUnlockRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateTrackUnlockResponse> {
      const localVarFetchArgs = UnlockApiFetchParamCreator(
        configuration,
      ).createTrackUnlock(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves unlock record by unlock id.
     * @summary Retrieves unlock.
     * @param {string} id unlock id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlock(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetUnlockResponse> {
      const localVarFetchArgs = UnlockApiFetchParamCreator(
        configuration,
      ).getUnlock(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves unlocked post ids.
     * @summary Retrieves unlocked post ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedPostIds(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUnlockedPostIdsResponse> {
      const localVarFetchArgs =
        UnlockApiFetchParamCreator(configuration).getUnlockedPostIds(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves unlocked post ids.
     * @summary Retrieves unlocked post ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedPostIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUnlockedPostIdsInternalResponse> {
      const localVarFetchArgs = UnlockApiFetchParamCreator(
        configuration,
      ).getUnlockedPostIdsInternal(userId, limit, offset, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves unlocked track ids.
     * @summary Retrieves unlocked track ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedTrackIds(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUnlockedTrackIdsResponse> {
      const localVarFetchArgs =
        UnlockApiFetchParamCreator(configuration).getUnlockedTrackIds(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves unlocked track ids.
     * @summary Retrieves unlocked track ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedTrackIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUnlockedTrackIdsInternalResponse> {
      const localVarFetchArgs = UnlockApiFetchParamCreator(
        configuration,
      ).getUnlockedTrackIdsInternal(userId, limit, offset, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * UnlockApi - factory interface
 * @export
 */
export const UnlockApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Creates new post unlock.
     * @summary Creates post unlock.
     * @param {CreatePostUnlockRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPostUnlock(fields: CreatePostUnlockRequest, options?: any) {
      return UnlockApiFp(configuration).createPostUnlock(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates new track unlock.
     * @summary Creates track unlock.
     * @param {CreateTrackUnlockRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrackUnlock(fields: CreateTrackUnlockRequest, options?: any) {
      return UnlockApiFp(configuration).createTrackUnlock(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves unlock record by unlock id.
     * @summary Retrieves unlock.
     * @param {string} id unlock id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlock(id: string, options?: any) {
      return UnlockApiFp(configuration).getUnlock(id, options)(fetch, basePath);
    },
    /**
     * Retrieves unlocked post ids.
     * @summary Retrieves unlocked post ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedPostIds(options?: any) {
      return UnlockApiFp(configuration).getUnlockedPostIds(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves unlocked post ids.
     * @summary Retrieves unlocked post ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedPostIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      options?: any,
    ) {
      return UnlockApiFp(configuration).getUnlockedPostIdsInternal(
        userId,
        limit,
        offset,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves unlocked track ids.
     * @summary Retrieves unlocked track ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedTrackIds(options?: any) {
      return UnlockApiFp(configuration).getUnlockedTrackIds(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves unlocked track ids.
     * @summary Retrieves unlocked track ids.
     * @param {string} userId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnlockedTrackIdsInternal(
      userId: string,
      limit?: number,
      offset?: number,
      options?: any,
    ) {
      return UnlockApiFp(configuration).getUnlockedTrackIdsInternal(
        userId,
        limit,
        offset,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * UnlockApi - object-oriented interface
 * @export
 * @class UnlockApi
 * @extends {BaseAPI}
 */
export class UnlockApi extends BaseAPI {
  /**
   * Creates new post unlock.
   * @summary Creates post unlock.
   * @param {CreatePostUnlockRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UnlockApi
   */
  public createPostUnlock(fields: CreatePostUnlockRequest, options?: any) {
    return UnlockApiFp(this.configuration).createPostUnlock(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates new track unlock.
   * @summary Creates track unlock.
   * @param {CreateTrackUnlockRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UnlockApi
   */
  public createTrackUnlock(fields: CreateTrackUnlockRequest, options?: any) {
    return UnlockApiFp(this.configuration).createTrackUnlock(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves unlock record by unlock id.
   * @summary Retrieves unlock.
   * @param {string} id unlock id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UnlockApi
   */
  public getUnlock(id: string, options?: any) {
    return UnlockApiFp(this.configuration).getUnlock(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves unlocked post ids.
   * @summary Retrieves unlocked post ids.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UnlockApi
   */
  public getUnlockedPostIds(options?: any) {
    return UnlockApiFp(this.configuration).getUnlockedPostIds(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves unlocked post ids.
   * @summary Retrieves unlocked post ids.
   * @param {string} userId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UnlockApi
   */
  public getUnlockedPostIdsInternal(
    userId: string,
    limit?: number,
    offset?: number,
    options?: any,
  ) {
    return UnlockApiFp(this.configuration).getUnlockedPostIdsInternal(
      userId,
      limit,
      offset,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves unlocked track ids.
   * @summary Retrieves unlocked track ids.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UnlockApi
   */
  public getUnlockedTrackIds(options?: any) {
    return UnlockApiFp(this.configuration).getUnlockedTrackIds(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves unlocked track ids.
   * @summary Retrieves unlocked track ids.
   * @param {string} userId
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UnlockApi
   */
  public getUnlockedTrackIdsInternal(
    userId: string,
    limit?: number,
    offset?: number,
    options?: any,
  ) {
    return UnlockApiFp(this.configuration).getUnlockedTrackIdsInternal(
      userId,
      limit,
      offset,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Blocks user record.
     * @summary Blocks user.
     * @param {BlockUserRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockUser(fields: BlockUserRequest, options: any = {}): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling blockUser.",
        );
      }
      const localVarPath = `/user/admin/user/block`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"BlockUserRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates new user.
     * @summary Creates user.
     * @param {CreateUserRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(fields: CreateUserRequest, options: any = {}): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling createUser.",
        );
      }
      const localVarPath = `/user/internal/user`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"CreateUserRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates new user delete confirmation.
     * @summary Creates user delete confirmation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserDeleteConfirmation(options: any = {}): FetchArgs {
      const localVarPath = `/user/delete-confirmation`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "POST" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes user profile.
     * @summary Deletes user profile.
     * @param {string} userDeleteConfirmationCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserProfile(
      userDeleteConfirmationCode: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'userDeleteConfirmationCode' is not null or undefined
      if (
        userDeleteConfirmationCode === null ||
        userDeleteConfirmationCode === undefined
      ) {
        throw new RequiredError(
          "userDeleteConfirmationCode",
          "Required parameter userDeleteConfirmationCode was null or undefined when calling deleteUserProfile.",
        );
      }
      const localVarPath = `/user/profile`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: "DELETE" },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (userDeleteConfirmationCode !== undefined) {
        localVarQueryParameter["userDeleteConfirmationCode"] =
          userDeleteConfirmationCode;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves followed artists.
     * @summary Retrieves followed artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowedArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/user/followed`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves popular artists.
     * @summary Retrieves popular artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/user/popular`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves public artists.
     * @summary Retrieves public artists.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicArtists(options: any = {}): FetchArgs {
      const localVarPath = `/user/public/user/artists`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves public user record by user id.
     * @summary Retrieves public user.
     * @param {string} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicUser(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getPublicUser.",
        );
      }
      const localVarPath = `/user/public/user/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves recommended artists.
     * @summary Retrieves recommended artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/user/recommended`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves subscribed artists.
     * @summary Retrieves subscribed artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/user/subscribed`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (searchTerm !== undefined) {
        localVarQueryParameter["searchTerm"] = searchTerm;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves user record by user id.
     * @summary Retrieves user.
     * @param {string} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getUser.",
        );
      }
      const localVarPath = `/user/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves user record by auth provider id.
     * @summary Retrieves user by auth id.
     * @param {string} authId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserByAuthId(authId: string, options: any = {}): FetchArgs {
      // verify required parameter 'authId' is not null or undefined
      if (authId === null || authId === undefined) {
        throw new RequiredError(
          "authId",
          "Required parameter authId was null or undefined when calling getUserByAuthId.",
        );
      }
      const localVarPath = `/user/internal/user/auth-id`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (authId !== undefined) {
        localVarQueryParameter["authId"] = authId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves user record by user id.
     * @summary Retrieves user by user id.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserByUserId(userId: string, options: any = {}): FetchArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getUserByUserId.",
        );
      }
      const localVarPath = `/user/internal/user/user-id`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets user profile.
     * @summary Gets user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(options: any = {}): FetchArgs {
      const localVarPath = `/user/profile`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves username availability status.
     * @summary Retrieves username availability.
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfileUsernameAvailable(
      username: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'username' is not null or undefined
      if (username === null || username === undefined) {
        throw new RequiredError(
          "username",
          "Required parameter username was null or undefined when calling getUserProfileUsernameAvailable.",
        );
      }
      const localVarPath = `/user/profile/username/available`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (username !== undefined) {
        localVarQueryParameter["username"] = username;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves users by ids.
     * @summary Retrieves users by ids.
     * @param {string} [userIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersByIds(userIds?: string, options: any = {}): FetchArgs {
      const localVarPath = `/user/ids`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "GET" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (userIds !== undefined) {
        localVarQueryParameter["userIds"] = userIds;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates user profile.
     * @summary Updates user profile.
     * @param {UpdateUserProfileRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile(
      fields: UpdateUserProfileRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling updateUserProfile.",
        );
      }
      const localVarPath = `/user/profile`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"UpdateUserProfileRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates user profile avatar.
     * @summary Updates user profile avatar.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileAvatar(options: any = {}): FetchArgs {
      const localVarPath = `/user/profile/avatar`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates user profile onboarding stage.
     * @summary Updates user profile onboarding stage.
     * @param {UpdateUserProfileOnboardingStageRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileOnboardingStage(
      fields: UpdateUserProfileOnboardingStageRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling updateUserProfileOnboardingStage.",
        );
      }
      const localVarPath = `/user/profile/onboarding`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"UpdateUserProfileOnboardingStageRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates user profile role.
     * @summary Updates user profile role.
     * @param {UpdateUserProfileRoleRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileRole(
      fields: UpdateUserProfileRoleRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling updateUserProfileRole.",
        );
      }
      const localVarPath = `/user/profile/role`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"UpdateUserProfileRoleRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates user profile username.
     * @summary Updates user profile username.
     * @param {UpdateUserProfileUsernameRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileUsername(
      fields: UpdateUserProfileUsernameRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'fields' is not null or undefined
      if (fields === null || fields === undefined) {
        throw new RequiredError(
          "fields",
          "Required parameter fields was null or undefined when calling updateUserProfileUsername.",
        );
      }
      const localVarPath = `/user/profile/username/update`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: "PUT" }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("Authorization")
            : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>"UpdateUserProfileUsernameRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(fields || {})
        : fields || "";

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Blocks user record.
     * @summary Blocks user.
     * @param {BlockUserRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockUser(
      fields: BlockUserRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).blockUser(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates new user.
     * @summary Creates user.
     * @param {CreateUserRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(
      fields: CreateUserRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CreateUserResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).createUser(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Creates new user delete confirmation.
     * @summary Creates user delete confirmation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserDeleteConfirmation(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<CreateUserDeleteConfirmationResponse> {
      const localVarFetchArgs =
        UserApiFetchParamCreator(configuration).createUserDeleteConfirmation(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes user profile.
     * @summary Deletes user profile.
     * @param {string} userDeleteConfirmationCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserProfile(
      userDeleteConfirmationCode: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).deleteUserProfile(userDeleteConfirmationCode, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves followed artists.
     * @summary Retrieves followed artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowedArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetFollowedArtistsResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).getFollowedArtists(limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves popular artists.
     * @summary Retrieves popular artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetPopularArtistsResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).getPopularArtists(limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves public artists.
     * @summary Retrieves public artists.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicArtists(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetPublicArtistsResponse> {
      const localVarFetchArgs =
        UserApiFetchParamCreator(configuration).getPublicArtists(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves public user record by user id.
     * @summary Retrieves public user.
     * @param {string} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicUser(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetPublicUserResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).getPublicUser(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves recommended artists.
     * @summary Retrieves recommended artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetRecommendedArtistsResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).getRecommendedArtists(limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves subscribed artists.
     * @summary Retrieves subscribed artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetSubscribedArtistsResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).getSubscribedArtists(limit, offset, searchTerm, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves user record by user id.
     * @summary Retrieves user.
     * @param {string} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetUserResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUser(
        id,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves user record by auth provider id.
     * @summary Retrieves user by auth id.
     * @param {string} authId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserByAuthId(
      authId: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUserByAuthIdResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).getUserByAuthId(authId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves user record by user id.
     * @summary Retrieves user by user id.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserByUserId(
      userId: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUserByUserIdResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).getUserByUserId(userId, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Gets user profile.
     * @summary Gets user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUserProfileResponse> {
      const localVarFetchArgs =
        UserApiFetchParamCreator(configuration).getUserProfile(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves username availability status.
     * @summary Retrieves username availability.
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfileUsernameAvailable(
      username: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<GetUserProfileUsernameAvailableResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).getUserProfileUsernameAvailable(username, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Retrieves users by ids.
     * @summary Retrieves users by ids.
     * @param {string} [userIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersByIds(
      userIds?: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<GetUsersByIdsResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).getUsersByIds(userIds, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates user profile.
     * @summary Updates user profile.
     * @param {UpdateUserProfileRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile(
      fields: UpdateUserProfileRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<UpdateUserProfileResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).updateUserProfile(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates user profile avatar.
     * @summary Updates user profile avatar.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileAvatar(
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<UpdateUserProfileAvatarResponse> {
      const localVarFetchArgs =
        UserApiFetchParamCreator(configuration).updateUserProfileAvatar(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates user profile onboarding stage.
     * @summary Updates user profile onboarding stage.
     * @param {UpdateUserProfileOnboardingStageRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileOnboardingStage(
      fields: UpdateUserProfileOnboardingStageRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<UpdateUserProfileOnboardingStageResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).updateUserProfileOnboardingStage(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates user profile role.
     * @summary Updates user profile role.
     * @param {UpdateUserProfileRoleRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileRole(
      fields: UpdateUserProfileRoleRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<UpdateUserProfileRoleResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).updateUserProfileRole(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates user profile username.
     * @summary Updates user profile username.
     * @param {UpdateUserProfileUsernameRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileUsername(
      fields: UpdateUserProfileUsernameRequest,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<UpdateUserProfileUsernameResponse> {
      const localVarFetchArgs = UserApiFetchParamCreator(
        configuration,
      ).updateUserProfileUsername(fields, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Blocks user record.
     * @summary Blocks user.
     * @param {BlockUserRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockUser(fields: BlockUserRequest, options?: any) {
      return UserApiFp(configuration).blockUser(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates new user.
     * @summary Creates user.
     * @param {CreateUserRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(fields: CreateUserRequest, options?: any) {
      return UserApiFp(configuration).createUser(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Creates new user delete confirmation.
     * @summary Creates user delete confirmation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserDeleteConfirmation(options?: any) {
      return UserApiFp(configuration).createUserDeleteConfirmation(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Deletes user profile.
     * @summary Deletes user profile.
     * @param {string} userDeleteConfirmationCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserProfile(userDeleteConfirmationCode: string, options?: any) {
      return UserApiFp(configuration).deleteUserProfile(
        userDeleteConfirmationCode,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves followed artists.
     * @summary Retrieves followed artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFollowedArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return UserApiFp(configuration).getFollowedArtists(
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves popular artists.
     * @summary Retrieves popular artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopularArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return UserApiFp(configuration).getPopularArtists(
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves public artists.
     * @summary Retrieves public artists.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicArtists(options?: any) {
      return UserApiFp(configuration).getPublicArtists(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves public user record by user id.
     * @summary Retrieves public user.
     * @param {string} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicUser(id: string, options?: any) {
      return UserApiFp(configuration).getPublicUser(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves recommended artists.
     * @summary Retrieves recommended artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return UserApiFp(configuration).getRecommendedArtists(
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves subscribed artists.
     * @summary Retrieves subscribed artists.
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [searchTerm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscribedArtists(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      options?: any,
    ) {
      return UserApiFp(configuration).getSubscribedArtists(
        limit,
        offset,
        searchTerm,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves user record by user id.
     * @summary Retrieves user.
     * @param {string} id user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: string, options?: any) {
      return UserApiFp(configuration).getUser(id, options)(fetch, basePath);
    },
    /**
     * Retrieves user record by auth provider id.
     * @summary Retrieves user by auth id.
     * @param {string} authId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserByAuthId(authId: string, options?: any) {
      return UserApiFp(configuration).getUserByAuthId(authId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Retrieves user record by user id.
     * @summary Retrieves user by user id.
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserByUserId(userId: string, options?: any) {
      return UserApiFp(configuration).getUserByUserId(userId, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Gets user profile.
     * @summary Gets user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(options?: any) {
      return UserApiFp(configuration).getUserProfile(options)(fetch, basePath);
    },
    /**
     * Retrieves username availability status.
     * @summary Retrieves username availability.
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfileUsernameAvailable(username: string, options?: any) {
      return UserApiFp(configuration).getUserProfileUsernameAvailable(
        username,
        options,
      )(fetch, basePath);
    },
    /**
     * Retrieves users by ids.
     * @summary Retrieves users by ids.
     * @param {string} [userIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersByIds(userIds?: string, options?: any) {
      return UserApiFp(configuration).getUsersByIds(userIds, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Updates user profile.
     * @summary Updates user profile.
     * @param {UpdateUserProfileRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile(fields: UpdateUserProfileRequest, options?: any) {
      return UserApiFp(configuration).updateUserProfile(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Updates user profile avatar.
     * @summary Updates user profile avatar.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileAvatar(options?: any) {
      return UserApiFp(configuration).updateUserProfileAvatar(options)(
        fetch,
        basePath,
      );
    },
    /**
     * Updates user profile onboarding stage.
     * @summary Updates user profile onboarding stage.
     * @param {UpdateUserProfileOnboardingStageRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileOnboardingStage(
      fields: UpdateUserProfileOnboardingStageRequest,
      options?: any,
    ) {
      return UserApiFp(configuration).updateUserProfileOnboardingStage(
        fields,
        options,
      )(fetch, basePath);
    },
    /**
     * Updates user profile role.
     * @summary Updates user profile role.
     * @param {UpdateUserProfileRoleRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileRole(fields: UpdateUserProfileRoleRequest, options?: any) {
      return UserApiFp(configuration).updateUserProfileRole(fields, options)(
        fetch,
        basePath,
      );
    },
    /**
     * Updates user profile username.
     * @summary Updates user profile username.
     * @param {UpdateUserProfileUsernameRequest} fields data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfileUsername(
      fields: UpdateUserProfileUsernameRequest,
      options?: any,
    ) {
      return UserApiFp(configuration).updateUserProfileUsername(
        fields,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   * Blocks user record.
   * @summary Blocks user.
   * @param {BlockUserRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public blockUser(fields: BlockUserRequest, options?: any) {
    return UserApiFp(this.configuration).blockUser(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates new user.
   * @summary Creates user.
   * @param {CreateUserRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public createUser(fields: CreateUserRequest, options?: any) {
    return UserApiFp(this.configuration).createUser(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Creates new user delete confirmation.
   * @summary Creates user delete confirmation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public createUserDeleteConfirmation(options?: any) {
    return UserApiFp(this.configuration).createUserDeleteConfirmation(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Deletes user profile.
   * @summary Deletes user profile.
   * @param {string} userDeleteConfirmationCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public deleteUserProfile(userDeleteConfirmationCode: string, options?: any) {
    return UserApiFp(this.configuration).deleteUserProfile(
      userDeleteConfirmationCode,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves followed artists.
   * @summary Retrieves followed artists.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getFollowedArtists(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return UserApiFp(this.configuration).getFollowedArtists(
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves popular artists.
   * @summary Retrieves popular artists.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getPopularArtists(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return UserApiFp(this.configuration).getPopularArtists(
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves public artists.
   * @summary Retrieves public artists.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getPublicArtists(options?: any) {
    return UserApiFp(this.configuration).getPublicArtists(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves public user record by user id.
   * @summary Retrieves public user.
   * @param {string} id user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getPublicUser(id: string, options?: any) {
    return UserApiFp(this.configuration).getPublicUser(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves recommended artists.
   * @summary Retrieves recommended artists.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getRecommendedArtists(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return UserApiFp(this.configuration).getRecommendedArtists(
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves subscribed artists.
   * @summary Retrieves subscribed artists.
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [searchTerm]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getSubscribedArtists(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    options?: any,
  ) {
    return UserApiFp(this.configuration).getSubscribedArtists(
      limit,
      offset,
      searchTerm,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves user record by user id.
   * @summary Retrieves user.
   * @param {string} id user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUser(id: string, options?: any) {
    return UserApiFp(this.configuration).getUser(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves user record by auth provider id.
   * @summary Retrieves user by auth id.
   * @param {string} authId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserByAuthId(authId: string, options?: any) {
    return UserApiFp(this.configuration).getUserByAuthId(authId, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves user record by user id.
   * @summary Retrieves user by user id.
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserByUserId(userId: string, options?: any) {
    return UserApiFp(this.configuration).getUserByUserId(userId, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Gets user profile.
   * @summary Gets user profile.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserProfile(options?: any) {
    return UserApiFp(this.configuration).getUserProfile(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Retrieves username availability status.
   * @summary Retrieves username availability.
   * @param {string} username
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserProfileUsernameAvailable(username: string, options?: any) {
    return UserApiFp(this.configuration).getUserProfileUsernameAvailable(
      username,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Retrieves users by ids.
   * @summary Retrieves users by ids.
   * @param {string} [userIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUsersByIds(userIds?: string, options?: any) {
    return UserApiFp(this.configuration).getUsersByIds(userIds, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Updates user profile.
   * @summary Updates user profile.
   * @param {UpdateUserProfileRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUserProfile(fields: UpdateUserProfileRequest, options?: any) {
    return UserApiFp(this.configuration).updateUserProfile(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Updates user profile avatar.
   * @summary Updates user profile avatar.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUserProfileAvatar(options?: any) {
    return UserApiFp(this.configuration).updateUserProfileAvatar(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Updates user profile onboarding stage.
   * @summary Updates user profile onboarding stage.
   * @param {UpdateUserProfileOnboardingStageRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUserProfileOnboardingStage(
    fields: UpdateUserProfileOnboardingStageRequest,
    options?: any,
  ) {
    return UserApiFp(this.configuration).updateUserProfileOnboardingStage(
      fields,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Updates user profile role.
   * @summary Updates user profile role.
   * @param {UpdateUserProfileRoleRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUserProfileRole(
    fields: UpdateUserProfileRoleRequest,
    options?: any,
  ) {
    return UserApiFp(this.configuration).updateUserProfileRole(fields, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   * Updates user profile username.
   * @summary Updates user profile username.
   * @param {UpdateUserProfileUsernameRequest} fields data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUserProfileUsername(
    fields: UpdateUserProfileUsernameRequest,
    options?: any,
  ) {
    return UserApiFp(this.configuration).updateUserProfileUsername(
      fields,
      options,
    )(this.fetch, this.basePath);
  }
}

import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import {
  EntityPostView,
  EntityPostMediaType,
  EntityPostVisibility,
} from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useTracks } from '@/hooks'
// icons
import { icons } from '@/assets'
// utils
import { numbersFormatter, stringsCutter } from '@/utils'

// components
import { Track } from './components'
// styles
import { styles } from './styles'

type PostCardUploadedProps = {
  post: EntityPostView
  editDisabled?: boolean
  deleteDisabled?: boolean
  onClick: MouseEventHandler<HTMLDivElement>
  onEditClick?: MouseEventHandler<HTMLButtonElement>
  onDeleteClick?: MouseEventHandler<HTMLButtonElement>
}

export const PostCardUploaded: FC<PostCardUploadedProps> = ({
  post,
  editDisabled = false,
  deleteDisabled = false,
  onClick,
  onEditClick = undefined,
  onDeleteClick = undefined,
}) => {
  const theme = useTheme()
  const [mediaLoadError, setMediaLoadError] = useState(false)

  const {
    id: postId,
    trackIds,
    message,
    mediaType,
    mediaId,
    visibility,
    likesCount,
    unlocksCount,
    commentsCount,
  } = post

  // post tracks
  const { tracks: postTracks, isLoading: isPostTracksLoading } = useTracks(trackIds || [])
  const tracksLoadingPlaceholder = 'Loading...'

  const postHasMedia = !!mediaId
  const postMedia = useFile(mediaId || '')

  const maxPostDescriptionLength = 84

  return (
    <div css={styles(theme).card.main}>
      {/* header */}
      <div
        css={styles(theme).card.header.main}
        onClick={(e) => onClick(e)}
        aria-hidden="true"
      >
        <div css={styles(theme).card.header.content.main}>
          <div css={styles(theme).card.header.content.likes.main}>
            <div css={styles(theme).card.header.content.likes.icon}>
              <icons.Heart />
            </div>
            <div css={styles(theme).card.header.content.likes.count}>
              {numbersFormatter(likesCount || 0)}
            </div>
          </div>
          <div css={styles(theme).card.header.content.comments.main}>
            <div css={styles(theme).card.header.content.comments.icon}>
              <icons.Comment />
            </div>
            <div css={styles(theme).card.header.content.comments.count}>
              {numbersFormatter(commentsCount || 0)}
            </div>
          </div>
          {visibility === EntityPostVisibility.Subscribers && (
            <div css={styles(theme).card.header.content.unlocks.main}>
              <div css={styles(theme).card.header.content.unlocks.icon}>
                <icons.Lock />
              </div>
              <div css={styles(theme).card.header.content.unlocks.count}>
                {numbersFormatter(unlocksCount || 0)}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* media */}
      <div
        css={styles(theme).card.media.main}
        onClick={(e) => onClick(e)}
        aria-hidden="true"
      >
        {postHasMedia && !mediaLoadError ? (
          <>
            {mediaType && mediaType === EntityPostMediaType.Image && (
              <img
                css={styles(theme).card.media.image}
                onError={() => setMediaLoadError(true)}
                loading="lazy"
                width="180px"
                height="150px"
                src={postMedia}
                alt="post media"
              />
            )}
            {mediaType && mediaType === EntityPostMediaType.Video && (
              <>
                <video
                  css={styles(theme).card.media.video.main}
                  onError={() => setMediaLoadError(true)}
                  width="180"
                  muted
                  preload="metadata"
                  // autoPlay
                  // loop
                >
                  <source src={postMedia} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div css={styles(theme).card.media.video.play}>
                  <icons.PlayCircle />
                </div>
              </>
            )}
          </>
        ) : (
          <div css={styles(theme).card.media.placeholder}>
            <icons.Ryddm />
          </div>
        )}
      </div>

      {/* content */}
      <div
        css={styles(theme).card.content.main}
        onClick={(e) => onClick(e)}
        aria-hidden="true"
      >
        <div css={styles(theme).card.content.description}>
          {stringsCutter(maxPostDescriptionLength, message || '')}
        </div>
        <div css={styles(theme).card.content.tracks}>
          {isPostTracksLoading
            ? tracksLoadingPlaceholder
            : postTracks &&
              postTracks.map((track) => (
                <Track key={`uploaded-post-${postId}-track-${track.id}`} track={track} />
              ))}
        </div>
      </div>

      {/* footer */}
      <div
        css={styles(theme).card.footer.main}
        onClick={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <div css={styles(theme).card.footer.buttons.main}>
          {onEditClick && (
            <div css={styles(theme).card.footer.buttons.edit.main}>
              <button
                css={[
                  styles(theme).card.footer.buttons.edit.button.shared,
                  editDisabled
                    ? styles(theme).card.footer.buttons.edit.button.disabled
                    : styles(theme).card.footer.buttons.edit.button.enabled,
                ]}
                onClick={(e) => onEditClick(e)}
                disabled={editDisabled}
                type="button"
                aria-label="edit post"
              >
                <icons.Edit css={styles(theme).card.footer.buttons.edit.icon} />
              </button>
            </div>
          )}

          {onDeleteClick && (
            <div css={styles(theme).card.footer.buttons.delete.main}>
              <button
                css={[
                  styles(theme).card.footer.buttons.delete.button.shared,
                  deleteDisabled
                    ? styles(theme).card.footer.buttons.delete.button.disabled
                    : styles(theme).card.footer.buttons.delete.button.enabled,
                ]}
                onClick={(e) => onDeleteClick(e)}
                disabled={deleteDisabled}
                type="button"
                aria-label="delete post"
              >
                <icons.Trash css={styles(theme).card.footer.buttons.delete.icon} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

PostCardUploaded.propTypes = {}

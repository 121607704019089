import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { unlockSelectUnlockTrackModalOpen, unlockCloseUnlockTrackModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { UnlockTrackCard } from './components'
// styles
import { styles } from './styles'

type UnlockTrackModalProps = {}

export const UnlockTrackModal: FC<UnlockTrackModalProps> = () => {
  const dispatch = useAppDispatch()
  const unlockTrackModalOpen = useAppSelector(unlockSelectUnlockTrackModalOpen)

  return (
    <div css={styles().modal.main}>
      {unlockTrackModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(unlockCloseUnlockTrackModal())}
        >
          <div css={styles().modal.content}>
            <UnlockTrackCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

UnlockTrackModal.propTypes = {}

import { css } from '@emotion/react'

export const styles = () => ({
  form: {
    main: css``,
    content: {
      main: css``,
      type: css`
        margin-bottom: 24px;
      `,
      complaint: css`
        margin-bottom: 24px;
      `,
      submit: css``,
    },
  },
})

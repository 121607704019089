import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EntityUserProfileView } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// features
import {
  authAuthenticateUser,
  authSignupWithEmailAndPassword,
  authLoginWithEmailAndPassword,
  authLoginWithGoogle,
  authLoginWithFacebook,
  authRefresh,
  authSendPasswordResetEmail,
  authSendVerificationEmail,
  authCheckVerificationStatus,
  authSignout,
  authUnlockPlatform,
} from '@/features'

export interface AuthState {
  profile: EntityUserProfileView | null
  emailVerified: boolean
  refreshing: boolean

  joinModal: {
    open: boolean
  }
  loginModal: {
    open: boolean
  }
  resetModal: {
    open: boolean
  }
  signupModal: {
    open: boolean
  }

  platformLocked: boolean

  error: string
  loading: boolean
}

const initialState: AuthState = {
  profile: null,
  emailVerified: false,
  refreshing: true,

  joinModal: {
    open: false,
  },
  loginModal: {
    open: false,
  },
  resetModal: {
    open: false,
  },
  signupModal: {
    open: false,
  },

  platformLocked: true,

  error: '',
  loading: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // update profile
    authUpdateProfile: (state, action: PayloadAction<EntityUserProfileView>) => ({
      ...state,
      profile: action.payload,
    }),

    // open join modal
    authOpenJoinModal: (state) => ({
      ...state,
      joinModal: {
        open: true,
      },
    }),

    // close join modal
    authCloseJoinModal: (state) => ({
      ...state,
      joinModal: {
        open: false,
      },
    }),

    // open login modal
    authOpenLoginModal: (state) => ({
      ...state,
      error: '',
      loginModal: {
        open: true,
      },
    }),

    // close login modal
    authCloseLoginModal: (state) => ({
      ...state,
      loginModal: {
        open: false,
      },
    }),

    // open reset modal
    authOpenResetModal: (state) => ({
      ...state,
      error: '',
      resetModal: {
        open: true,
      },
    }),

    // close reset modal
    authCloseResetModal: (state) => ({
      ...state,
      resetModal: {
        open: false,
      },
    }),

    // open signup modal
    authOpenSignupModal: (state) => ({
      ...state,
      error: '',
      signupModal: {
        open: true,
      },
    }),

    // close signup modal
    authCloseSignupModal: (state) => ({
      ...state,
      signupModal: {
        open: false,
      },
    }),

    // unlock platform
    authSetPlatformUnlocked: (state) => ({
      ...state,
      platformLocked: false,
    }),
  },
  extraReducers: (builder) => {
    // authenticate user
    builder
      .addCase(authAuthenticateUser.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(authAuthenticateUser.fulfilled, (state, { payload }) => ({
        ...state,
        profile: payload.profile,
        emailVerified: payload.emailVerified,
        error: payload.error,
        loading: false,
      }))

    // refresh
    builder
      .addCase(authRefresh.pending, (state) => ({
        ...state,
        loading: true,
        refreshing: true,
      }))
      .addCase(authRefresh.fulfilled, (state, { payload }) => ({
        ...state,
        profile: payload.profile,
        emailVerified: payload.emailVerified,
        error: payload.error,
        loading: false,
        refreshing: false,
      }))

    // sign up with email and password
    builder
      .addCase(authSignupWithEmailAndPassword.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(authSignupWithEmailAndPassword.fulfilled, (state, { payload }) => ({
        ...state,
        error: payload.error,
        loading: false,
      }))

    // log in with email and password
    builder
      .addCase(authLoginWithEmailAndPassword.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(authLoginWithEmailAndPassword.fulfilled, (state, { payload }) => ({
        ...state,
        error: payload.error,
        loading: false,
      }))

    // log in with google
    builder
      .addCase(authLoginWithGoogle.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(authLoginWithGoogle.fulfilled, (state, { payload }) => ({
        ...state,
        error: payload.error,
        loading: false,
      }))

    // log in with facebook
    builder
      .addCase(authLoginWithFacebook.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(authLoginWithFacebook.fulfilled, (state, { payload }) => ({
        ...state,
        error: payload.error,
        loading: false,
      }))

    // reset password
    builder
      .addCase(authSendPasswordResetEmail.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(authSendPasswordResetEmail.fulfilled, (state, { payload }) => ({
        ...state,
        error: payload.error,
        loading: false,
      }))

    // verify email
    builder
      .addCase(authSendVerificationEmail.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(authSendVerificationEmail.fulfilled, (state, { payload }) => ({
        ...state,
        error: payload.error,
        loading: false,
      }))

    // sign out
    builder
      .addCase(authSignout.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(authSignout.fulfilled, (state) => ({
        ...state,
        ...initialState,
        loading: false,
        refreshing: false,
      }))

    // check email verification
    builder
      .addCase(authCheckVerificationStatus.pending, (state) => ({
        ...state,
      }))
      .addCase(authCheckVerificationStatus.fulfilled, (state, { payload }) => ({
        ...state,
        emailVerified: payload.emailVerified,
      }))

    // unlock platform
    builder
      .addCase(authUnlockPlatform.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(authUnlockPlatform.fulfilled, (state, { payload }) => ({
        ...state,
        error: payload.error,
        loading: false,
      }))
  },
})

export const {
  authUpdateProfile,

  authOpenJoinModal,
  authCloseJoinModal,

  authOpenLoginModal,
  authCloseLoginModal,

  authOpenResetModal,
  authCloseResetModal,

  authOpenSignupModal,
  authCloseSignupModal,

  authSetPlatformUnlocked,
} = authSlice.actions

export const authSelectProfile = (state: RootState) => state.auth.profile
export const authSelectProfileAvatar = (state: RootState) => state.auth.profile?.avatarId
export const authSelectEmailVerified = (state: RootState) => state.auth.emailVerified
export const authSelectRefreshing = (state: RootState) => state.auth.refreshing

export const authSelectPlatformLocked = (state: RootState) => state.auth.platformLocked

export const authSelectJoinModalOpen = (state: RootState) => state.auth.joinModal.open
export const authSelectLoginModalOpen = (state: RootState) => state.auth.loginModal.open
export const authSelectResetModalOpen = (state: RootState) => state.auth.resetModal.open
export const authSelectSignupModalOpen = (state: RootState) => state.auth.signupModal.open

export const authSelectLoading = (state: RootState) => state.auth.loading
export const authSelectError = (state: RootState) => state.auth.error

export const authReducer = authSlice.reducer

// export default authSlice.reducer

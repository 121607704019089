import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type SubtitleProps = {
  text: string
}

export const Subtitle: FC<SubtitleProps> = ({ text }) => {
  const theme = useTheme()
  return <div css={styles(theme).subtitle.main}>{text}</div>
}

Subtitle.propTypes = {}

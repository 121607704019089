import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { tipSelectTipModalOpen, tipCloseTipModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { TipCard } from './components'
// styles
import { styles } from './styles'

type TipModalProps = {}

export const TipModal: FC<TipModalProps> = () => {
  const dispatch = useAppDispatch()
  const tipModalOpen = useAppSelector(tipSelectTipModalOpen)

  return (
    <div css={styles().modal.main}>
      {tipModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(tipCloseTipModal())}
        >
          <div css={styles().modal.content}>
            <TipCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

TipModal.propTypes = {}

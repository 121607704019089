import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type TitleProps = {
  text: string
  appearance?: 'primary' | 'secondary'
}

export const Title: FC<TitleProps> = ({ text, appearance = 'primary' }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).title.main}>
      <h1
        css={[
          styles(theme).title.text.shared,
          appearance === 'primary' && styles(theme).title.text.primary,
          appearance === 'secondary' && styles(theme).title.text.secondary,
        ]}
      >
        {text}
      </h1>
    </div>
  )
}

Title.propTypes = {}

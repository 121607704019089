import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  source: {
    main: css`
      ${helpers.resetDefaults};

      cursor: pointer;
      word-break: break-all;

      color: ${theme.colors.secondary.normal};
      font-size: ${typography.h6};
      font-weight: bold;
      transition: color 0.2s;

      &:hover {
        color: ${theme.colors.accent.normal};
      }
    `,
  },
})

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type SourceUserProps = {
  label: string
  username: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const SourceUser: FC<SourceUserProps> = ({ label, username, onClick }) => {
  const theme = useTheme()

  return (
    <button
      css={styles(theme).source.main}
      onClick={(e) => onClick(e)}
      type="button"
      aria-label={label}
    >
      @{username}
    </button>
  )
}

SourceUser.propTypes = {}

import { css } from '@emotion/react'
import { helpers, mq } from '@/styles'

export const styles = () => ({
  list: {
    main: css``,
    nav: {
      main: css`
        ${helpers.app.screen.width.full};
        display: flex;

        overflow: hidden;
        max-width: 800px;

        margin-bottom: 50px;
      `,
    },
    content: {
      main: css`
        max-width: 800px;

        margin-bottom: 24px;
      `,
    },
    actions: {
      main: css`
        display: flex;
        justify-content: flex-end;

        max-width: 800px;

        & > button {
          width: 140px;
          height: 32px;
        }

        ${mq.mobile(css`
          justify-content: flex-start;

          & > button {
            width: 100%;
          }
        `)}
      `,
    },
  },

  notification: {
    main: css``,
  },
})

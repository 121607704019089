import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  EntityNotificationEvent,
  EntityTipView,
  EntityTipStatus,
} from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // notification
  notificationSelectNotificationTipRetried,
  // tip
  tipCreateTip,
} from '@/features'
// icons
import { icons } from '@/assets'

// components
import { Control, TipMessage } from './components'

type TipControlsProps = {
  event: EntityNotificationEvent
  tip: EntityTipView
  isDisabled: boolean
}

export const TipControls: FC<TipControlsProps> = ({ event, tip, isDisabled }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const notificationTipRetried = useAppSelector(notificationSelectNotificationTipRetried)

  // if notification event is tip created
  if (event === EntityNotificationEvent.AppTipCreated) {
    // if tip message present
    if (tip?.message) {
      return <TipMessage message={tip?.message} />
    }

    return <div />
  }

  // if notification event is tip payment failed
  if (event === EntityNotificationEvent.AppTipPaymentFailed) {
    const isTipRetryDisabled =
      isDisabled || tip?.status !== EntityTipStatus.Failed || notificationTipRetried

    return (
      <>
        <Control
          appearance="neutral"
          label="navigate to target user page"
          icon={icons.UserCircle}
          text="View user"
          disabled={isDisabled}
          onClick={() => navigate(`${Path.User}/${tip?.targetUserId}`)}
        />
        <Control
          appearance="neutral"
          label="retry tip"
          icon={icons.DollarCircle}
          text="Retry"
          disabled={isTipRetryDisabled}
          onClick={() => {
            dispatch(
              tipCreateTip({
                amount: (tip?.amount || 0) / 100,
                message: tip?.message,
                artistId: tip?.targetUserId || '',
              }),
            )
          }}
        />
      </>
    )
  }

  // if notification event is tip payment succeeded
  if (event === EntityNotificationEvent.AppTipPaymentSucceeded) {
    return (
      <Control
        appearance="neutral"
        label="navigate to target user page"
        icon={icons.UserCircle}
        text="View user"
        disabled={isDisabled}
        onClick={() => navigate(`${Path.User}/${tip?.targetUserId}`)}
      />
    )
  }

  return <div />
}

TipControls.propTypes = {}

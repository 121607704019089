import { FC } from 'react'

// components
import { SkeletonHorizontal } from '@/components'

// styles
import { styles } from './styles'

type TrackCardBlockSkeletonProps = {}

export const TrackCardBlockSkeleton: FC<TrackCardBlockSkeletonProps> = () => (
  <div css={styles().skeleton.main}>
    <SkeletonHorizontal
      sections={[
        {
          name: 'track-card-block-skeleton-cover-1',
          width: '60px',
          height: '60px',
          isStatic: true,
          isDivider: false,
        },
        {
          name: 'track-card-block-skeleton-divider-1',
          width: '16px',
          height: '60px',
          isStatic: true,
          isDivider: true,
        },
        {
          name: 'track-card-block-skeleton-description-1',
          width: '100%',
          height: '60px',
          isStatic: false,
          isDivider: false,
        },
      ]}
    />
    <SkeletonHorizontal
      sections={[
        {
          name: 'track-card-block-skeleton-cover-2',
          width: '60px',
          height: '60px',
          isStatic: true,
          isDivider: false,
        },
        {
          name: 'track-card-block-skeleton-divider-2',
          width: '16px',
          height: '60px',
          isStatic: true,
          isDivider: true,
        },
        {
          name: 'track-card-block-skeleton-description-2',
          width: '100%',
          height: '60px',
          isStatic: false,
          isDivider: false,
        },
      ]}
    />
    <SkeletonHorizontal
      sections={[
        {
          name: 'track-card-block-skeleton-cover-3',
          width: '60px',
          height: '60px',
          isStatic: true,
          isDivider: false,
        },
        {
          name: 'track-card-block-skeleton-divider-3',
          width: '16px',
          height: '60px',
          isStatic: true,
          isDivider: true,
        },
        {
          name: 'track-card-block-skeleton-description-3',
          width: '100%',
          height: '60px',
          isStatic: false,
          isDivider: false,
        },
      ]}
    />
  </div>
)

TrackCardBlockSkeleton.propTypes = {}

import { FC, useState } from 'react'

// hooks
import { useAppSelector, useAppDispatch, useInterval } from '@/hooks'
// features
import { authSelectLoading, authSendPasswordResetEmail } from '@/features'
// libs
import { dateGetDifferenceSec, storageGetSentPasswordResetEmailDate } from '@/lib'
// utils
import { validateEmail } from '@/utils'
// components
import { Loader, ButtonLarge } from '@/components'

// components
import { FormInput } from './components'
// styles
import { styles } from './ResetForm.styles'

type ResetFormProps = {}

export const ResetForm: FC<ResetFormProps> = () => {
  const dispatch = useAppDispatch()
  const authLoading = useAppSelector(authSelectLoading)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const lastResetSec = dateGetDifferenceSec(
    parseInt(storageGetSentPasswordResetEmailDate() || '0', 10),
  )
  const delaySec = 30
  const initialSendDelay = lastResetSec > delaySec ? 0 : delaySec - lastResetSec
  const [sendDelay, setSendDelay] = useState(initialSendDelay)

  useInterval(() => {
    if (sendDelay > 0) {
      setSendDelay(sendDelay - 1)
    }
  }, 1000)

  const submitDisabled =
    email.length < 4 || emailError !== '' || sendDelay > 0 || authLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          setSendDelay(delaySec)
          dispatch(authSendPasswordResetEmail({ email }))
        }}
      >
        <div css={styles().form.content.email}>
          <FormInput
            name="email"
            label="email"
            type="email"
            placeholder="Enter your email"
            value={email}
            error={emailError.length > 0}
            errorMessage={emailError}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            onBlur={() => setEmailError(validateEmail(email))}
            onFocus={() => setEmailError('')}
          />
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="Send password reset"
            disabled={submitDisabled}
            appearance="primary"
          >
            {((loading: boolean, delay: number) => {
              // if loading render the loader
              if (loading) {
                return <Loader width={50} height={20} appearance="primary" />
              }

              // if send delayed render the delay count
              if (delay > 0) {
                return `${delay}`
              }

              return `Send`
            })(authLoading, sendDelay)}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

ResetForm.propTypes = {}

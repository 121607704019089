import { css, Theme } from '@emotion/react'
import { helpers, typography, z } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: css`
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      width: 180px;
      height: 282px;

      background-color: ${theme.colors.primary.darker};

      cursor: pointer;
      overflow: hidden;

      transition: border 0.2s;

      &: hover > div {
        opacity: 1;
      }
    `,

    lock: {
      main: css`
        display: flex;
      `,
      icon: css`
        width: 30px;
        height: 30px;
      `,
    },

    // overlay
    overlay: {
      main: css`
        z-index: ${z.features.post.card.overlay};

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border-radius: 4px;
        background-color: ${theme.colors.primary.normal}E4;

        transition: opacity 0.2s, border 0.2s;
        opacity: 0;
      `,

      button: css`
        ${helpers.resetDefaults};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        cursor: pointer;

        & > svg {
          width: 30px;
          height: 30px;

          & path {
            fill: ${theme.colors.accent.normal};
          }
        }
      `,
    },

    // header
    header: {
      main: css`
        z-index: ${z.features.post.card.header};

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 60px;

        padding: 8px 8px 18px 8px;

        background: linear-gradient(
          180deg,
          ${theme.colors.primary.normal} 0%,
          ${theme.colors.primary.normal}00 75.79%
        );
      `,
      content: {
        main: css`
          display: flex;
        `,
        avatar: {
          main: css`
            display: flex;
            align-items: center;
            justify-content: center;

            flex-shrink: 0;

            height: 30px;
            width: 30px;

            background-color: ${theme.colors.primary.darker};
            border-radius: 50%;
            overflow: hidden;

            margin-right: 8px;
          `,
          image: css`
            ${helpers.image};
          `,
          icon: css`
            width: 16px;
            height: 16px;

            & > path {
              fill: ${theme.colors.secondary.normal};
            }
          `,
        },
        author: {
          main: css`
            display: flex;
            flex-direction: column;
            justify-content: center;

            min-width: 0;
            margin-right: auto;
          `,
          name: css`
            ${helpers.resetDefaults};
            ${helpers.textOverflowEllipsis};

            cursor: pointer;

            font-size: ${typography.c};
            font-weight: bold;
            color: ${theme.colors.secondary.normal};
            text-align: left;

            margin-bottom: 2px;

            transition: color 0.2s;
            &:hover {
              color: ${theme.colors.accent.normal};
            }
          `,
          username: css`
            ${helpers.resetDefaults};
            ${helpers.textOverflowEllipsis};

            cursor: pointer;

            font-size: ${typography.c};
            color: ${theme.colors.secondary.normal};
            text-align: left;

            transition: color 0.2s;
            &:hover {
              color: ${theme.colors.accent.normal};
            }
          `,
        },
        likes: {
          main: css`
            display: flex;
            align-items: center;

            margin-left: 8px;
          `,
          icon: css`
            display: flex;

            margin-right: 4px;

            & > svg {
              width: 14px;
              height: 14px;
            }

            & > svg > path {
              fill: ${theme.colors.accent.normal};
            }
          `,
          likes: css`
            font-size: ${typography.sp};
          `,
        },
      },
    },

    // footer
    footer: css`
      z-index: ${z.features.post.card.footer};

      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 60px;

      padding: 8px 8px 18px 8px;

      background: linear-gradient(
        180deg,
        ${theme.colors.primary.normal}00 25.21%,
        ${theme.colors.primary.normal} 100%
      );
    `,
  },
})

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type MediaFilePreviewProps = {
  source: string
  width: string
  height: string
  type: 'image' | 'video' | ''
  alt?: string
  formats?: string[]
  maxFileSize?: number
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const MediaFilePreview: FC<MediaFilePreviewProps> = ({
  source,
  width,
  height,
  type,
  formats = undefined,
  maxFileSize = undefined,
  alt = '',
  onClick,
}) => {
  const theme = useTheme()

  const renderPreview = () => {
    // if source provided and media type is image
    if (source && type === 'image') {
      return (
        <img
          css={styles(theme).preview.image.overlay.content}
          loading="lazy"
          src={source}
          alt={alt}
          width={width}
          height={height}
        />
      )
    }

    // if source provided and media type is video
    if (source && type === 'video') {
      return (
        <video
          css={styles(theme).preview.image.overlay.content}
          width={width}
          height={height}
          src={source}
          muted
          preload="metadata"
          // autoPlay
          // loop
          // controls
        >
          <source src={source} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }

    // placeholder
    return (
      <div css={styles(theme).preview.image.overlay.main}>
        <icons.ImageDownload css={styles(theme).preview.image.overlay.icon} />
        <div css={styles(theme).preview.image.overlay.text.main}>
          <div css={styles(theme).preview.image.overlay.text.formats}>
            {formats && formats.join(', ')}
          </div>
          <div css={styles(theme).preview.image.overlay.text.size}>
            {maxFileSize && `up to ${maxFileSize / 1024 / 1024}MiB`}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div css={styles(theme).preview.main(width, height)}>
      <button
        css={styles(theme).preview.button}
        onClick={(e) => onClick(e)}
        type="button"
        aria-label="change file"
      >
        <div css={styles(theme).preview.image.main(width, height)}>{renderPreview()}</div>
      </button>
    </div>
  )
}

MediaFilePreview.propTypes = {}

import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

// components
import { MainLayout, SuspenseContentFallback } from '@/components'
// utils
import { importsLazy } from '@/utils'

const { Onboarding } = importsLazy(() => import('@/features'), 'Onboarding')

const App = () => (
  <MainLayout>
    <Suspense fallback={<SuspenseContentFallback position="full" />}>
      <Outlet />
    </Suspense>
  </MainLayout>
)

export const onboardingRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Onboarding />,
      },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
]

import { css, Theme } from '@emotion/react'
import { typography, helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  title: {
    main: css`
      display: flex;
      justify-content: center;
    `,
    text: css`
      max-width: 0;
      font-size: ${typography.h6};
      text-align: center;
      color: ${theme.colors.secondary.dark};

      max-width: 500px;
    `,
    button: css`
      ${helpers.resetDefaults};

      font-weight: bold;
      text-align: start;
      white-space: nowrap;
      cursor: pointer;
    `,
    accent: {
      main: css`
        font-weight: bold;
        font-size: ${typography.h6};
        text-align: center;
        color: ${theme.colors.accent.normal};
      `,
      long: css`
        ${helpers.textOverflowEllipsis};
      `,
    },
  },
})

import { css, Theme } from '@emotion/react'

export const styles = (theme: Theme) => ({
  card: {
    main: css`
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      width: 180px;
      height: 282px;

      background-color: ${theme.colors.primary.normal};

      overflow: hidden;
      transition: border 0.2s;

      &: hover > div {
        opacity: 1;
      }
    `,

    lock: {
      main: css`
        display: flex;
      `,
      icon: css`
        width: 30px;
        height: 30px;
      `,
    },

    description: css``,
  },
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityPostView } from '@ryddm-inc/ryddm-apiclient'

// utils
import { mergeArraysAtIndex } from '@/utils'
// stores
import { RootState } from '@/stores'
// features
import {
  postGetPublicPosts,
  postGetPopularPosts,
  postGetRecommendedPosts,
  postGetLikedPosts,
  postGetUnlockedPosts,
  postGetUploadedPosts,
  postGetArtistPopularPosts,
  postGetArtistExclusivePosts,
  postGetArtistLatestPosts,
  postGetPost,
  postCreate,
  postUpdate,
  postBlock,
  postDelete,
} from '@/features'

export interface PostState {
  publicPosts: EntityPostView[]
  publicPostsCount: number
  publicPostsFetched: boolean

  popularPosts: EntityPostView[]
  popularPostsCount: number
  popularPostsFetched: boolean

  recommendedPosts: EntityPostView[]
  recommendedPostsCount: number
  recommendedPostsFetched: boolean

  likedPosts: EntityPostView[]
  likedPostsCount: number
  likedPostsFetched: boolean

  unlockedPosts: EntityPostView[]
  unlockedPostsCount: number
  unlockedPostsFetched: boolean

  uploadedPosts: EntityPostView[]
  uploadedPostsCount: number
  uploadedPostsFetched: boolean

  artistPopularPosts: EntityPostView[]
  artistPopularPostsCount: number
  artistPopularPostsFetched: boolean

  artistExclusivePosts: EntityPostView[]
  artistExclusivePostsCount: number
  artistExclusivePostsFetched: boolean

  artistLatestPosts: EntityPostView[]
  artistLatestPostsCount: number
  artistLatestPostsFetched: boolean

  post: EntityPostView | undefined
  postFetched: boolean

  postBlockModal: {
    post: EntityPostView | undefined
    open: boolean
  }

  postDeleteModal: {
    post: EntityPostView | undefined
    open: boolean
  }

  postGetPublicPostsLoading: boolean
  postGetPopularPostsLoading: boolean
  postGetRecommendedPostsLoading: boolean
  postGetLikedPostsLoading: boolean
  postGetUnlockedPostsLoading: boolean
  postGetUploadedPostsLoading: boolean
  postGetArtistPopularPostsLoading: boolean
  postGetArtistExclusivePostsLoading: boolean
  postGetArtistLatestPostsLoading: boolean
  postGetPostLoading: boolean
  postCreateLoading: boolean
  postUpdateLoading: boolean
  postBlockLoading: boolean
  postDeleteLoading: boolean
}

const initialState: PostState = {
  publicPosts: [],
  publicPostsCount: 0,
  publicPostsFetched: false,

  popularPosts: [],
  popularPostsCount: 0,
  popularPostsFetched: false,

  recommendedPosts: [],
  recommendedPostsCount: 0,
  recommendedPostsFetched: false,

  likedPosts: [],
  likedPostsCount: 0,
  likedPostsFetched: false,

  unlockedPosts: [],
  unlockedPostsCount: 0,
  unlockedPostsFetched: false,

  uploadedPosts: [],
  uploadedPostsCount: 0,
  uploadedPostsFetched: false,

  artistPopularPosts: [],
  artistPopularPostsCount: 0,
  artistPopularPostsFetched: false,

  artistExclusivePosts: [],
  artistExclusivePostsCount: 0,
  artistExclusivePostsFetched: false,

  artistLatestPosts: [],
  artistLatestPostsCount: 0,
  artistLatestPostsFetched: false,

  post: undefined,
  postFetched: false,

  postBlockModal: {
    post: undefined,
    open: false,
  },

  postDeleteModal: {
    post: undefined,
    open: false,
  },

  postGetPublicPostsLoading: false,
  postGetPopularPostsLoading: false,
  postGetRecommendedPostsLoading: false,
  postGetLikedPostsLoading: false,
  postGetUnlockedPostsLoading: false,
  postGetUploadedPostsLoading: false,
  postGetArtistPopularPostsLoading: false,
  postGetArtistExclusivePostsLoading: false,
  postGetArtistLatestPostsLoading: false,
  postGetPostLoading: false,
  postCreateLoading: false,
  postUpdateLoading: false,
  postBlockLoading: false,
  postDeleteLoading: false,
}

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    // reset state
    postResetState: () => ({
      publicPosts: [],
      publicPostsCount: 0,
      publicPostsFetched: false,

      popularPosts: [],
      popularPostsCount: 0,
      popularPostsFetched: false,

      recommendedPosts: [],
      recommendedPostsCount: 0,
      recommendedPostsFetched: false,

      likedPosts: [],
      likedPostsCount: 0,
      likedPostsFetched: false,

      unlockedPosts: [],
      unlockedPostsCount: 0,
      unlockedPostsFetched: false,

      profilePosts: [],
      profilePostsCount: 0,
      profilePostsFetched: false,

      uploadedPosts: [],
      uploadedPostsCount: 0,
      uploadedPostsFetched: false,

      artistPopularPosts: [],
      artistPopularPostsCount: 0,
      artistPopularPostsFetched: false,

      artistExclusivePosts: [],
      artistExclusivePostsCount: 0,
      artistExclusivePostsFetched: false,

      artistLatestPosts: [],
      artistLatestPostsCount: 0,
      artistLatestPostsFetched: false,

      post: undefined,
      postFetched: false,

      postBlockModal: {
        post: undefined,
        open: false,
      },

      postDeleteModal: {
        post: undefined,
        open: false,
      },

      postGetPublicPostsLoading: false,
      postGetPopularPostsLoading: false,
      postGetRecommendedPostsLoading: false,
      postGetLikedPostsLoading: false,
      postGetUnlockedPostsLoading: false,
      postGetUploadedPostsLoading: false,
      postGetArtistPopularPostsLoading: false,
      postGetArtistExclusivePostsLoading: false,
      postGetArtistLatestPostsLoading: false,
      postGetPostLoading: false,
      postCreateLoading: false,
      postUpdateLoading: false,
      postBlockLoading: false,
      postDeleteLoading: false,
    }),

    // set public posts
    postSetPublicPosts: (
      state,
      action: PayloadAction<{
        posts: EntityPostView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { posts, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.publicPostsCount = count

        // mutate public posts array
        state.publicPosts = mergeArraysAtIndex(state.publicPosts, posts, offset)
      } else {
        // this block will be used for search
        // during search array of posts will have to be recrated
        // in accordance to the new count
      }
    },

    // set popular posts
    postSetPopularPosts: (
      state,
      action: PayloadAction<{
        posts: EntityPostView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { posts, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.popularPostsCount = count

        // mutate popular posts array
        state.popularPosts = mergeArraysAtIndex(state.popularPosts, posts, offset)
      } else {
        // this block will be used for search
        // during search array of posts will have to be recrated
        // in accordance to the new count
      }
    },

    // set recommended posts
    postSetRecommendedPosts: (
      state,
      action: PayloadAction<{
        posts: EntityPostView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { posts, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.recommendedPostsCount = count

        // mutate recommended posts array
        state.recommendedPosts = mergeArraysAtIndex(state.recommendedPosts, posts, offset)
      } else {
        // this block will be used for search
        // during search array of posts will have to be recrated
        // in accordance to the new count
      }
    },

    // set liked posts
    postSetLikedPosts: (
      state,
      action: PayloadAction<{
        posts: EntityPostView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { posts, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.likedPostsCount = count

        // mutate profile posts array
        state.likedPosts = mergeArraysAtIndex(state.likedPosts, posts, offset)
      } else {
        // this block will be used for search
        // during search array of posts will have to be recrated
        // in accordance to the new count
      }
    },

    // set unlocked posts
    postSetUnlockedPosts: (
      state,
      action: PayloadAction<{
        posts: EntityPostView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { posts, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.unlockedPostsCount = count

        // mutate profile posts array
        state.unlockedPosts = mergeArraysAtIndex(state.unlockedPosts, posts, offset)
      } else {
        // this block will be used for search
        // during search array of posts will have to be recrated
        // in accordance to the new count
      }
    },

    // set uploaded posts
    postSetUploadedPosts: (
      state,
      action: PayloadAction<{
        posts: EntityPostView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { posts, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.uploadedPostsCount = count

        // mutate uploaded posts array
        state.uploadedPosts = mergeArraysAtIndex(state.uploadedPosts, posts, offset)
      } else {
        // this block will be used for search
        // during search array of posts will have to be recrated
        // in accordance to the new count
      }
    },

    // set artist popular posts
    postSetArtistPopularPosts: (
      state,
      action: PayloadAction<{
        posts: EntityPostView[]
        count: number
        offset: number
      }>,
    ) => {
      const { posts, count, offset } = action.payload

      // mutate count
      state.artistPopularPostsCount = count

      // mutate artist popular posts array
      state.artistPopularPosts = mergeArraysAtIndex(
        state.artistPopularPosts,
        posts,
        offset,
      )
    },

    // set artist exclusive posts
    postSetArtistExclusivePosts: (
      state,
      action: PayloadAction<{
        posts: EntityPostView[]
        count: number
        offset: number
      }>,
    ) => {
      const { posts, count, offset } = action.payload

      // mutate count
      state.artistExclusivePostsCount = count

      // mutate artist exclusive posts array
      state.artistExclusivePosts = mergeArraysAtIndex(
        state.artistExclusivePosts,
        posts,
        offset,
      )
    },

    // set artist latest posts
    postSetArtistLatestPosts: (
      state,
      action: PayloadAction<{
        posts: EntityPostView[]
        count: number
        offset: number
      }>,
    ) => {
      const { posts, count, offset } = action.payload

      // mutate count
      state.artistLatestPostsCount = count

      // mutate artist latest posts array
      state.artistLatestPosts = mergeArraysAtIndex(state.artistLatestPosts, posts, offset)
    },

    // reset artist posts
    postResetArtistPosts: (state) => ({
      ...state,

      artistPopularPosts: [],
      artistPopularPostsCount: 0,
      artistPopularPostsFetched: false,

      artistExclusivePosts: [],
      artistExclusivePostsCount: 0,
      artistExclusivePostsFetched: false,

      artistLatestPosts: [],
      artistLatestPostsCount: 0,
      artistLatestPostsFetched: false,
    }),

    // set post
    postSetPost: (
      state,
      action: PayloadAction<{
        post: EntityPostView
      }>,
    ) => {
      const { post } = action.payload

      // update post state
      state.post = post
    },

    // reset post
    postResetPost: (state) => ({
      ...state,

      post: undefined,
      postFetched: false,
    }),

    // append liked post
    postAppendLikedPost: (state, action: PayloadAction<{ post: EntityPostView }>) => {
      const { post } = action.payload

      // if liked posts are fetched
      if (state.likedPostsFetched) {
        // append post to liked posts
        state.likedPosts.unshift(post)
        state.likedPostsCount += 1
      }
    },

    // filter liked posts
    postFilterLikedPost: (state, action: PayloadAction<{ post: EntityPostView }>) => {
      const { post } = action.payload

      // if liked posts are fetched
      if (state.likedPostsFetched) {
        // filter post from liked posts
        state.likedPosts = state.likedPosts.filter((p) => p.id !== post.id)
        state.likedPostsCount -= 1
      }
    },

    // append unlocked post
    postAppendUnlockedPost: (state, action: PayloadAction<{ post: EntityPostView }>) => {
      const { post } = action.payload

      // if unlocked posts are fetched
      if (state.unlockedPostsFetched) {
        // append post to unlocked posts
        state.unlockedPosts.unshift(post)
        state.unlockedPostsCount += 1
      }
    },

    // filter unlocked post
    postFilterUnlockedPost: (state, action: PayloadAction<{ post: EntityPostView }>) => {
      const { post } = action.payload

      // if unlocked posts are fetched
      if (state.unlockedPostsFetched) {
        // filter post from unlocked posts
        state.unlockedPosts = state.unlockedPosts.filter((p) => p.id !== post.id)
        state.unlockedPostsCount -= 1
      }
    },

    // filter post
    postFilterPost: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload

      // check if post exists in public posts
      const pubIndex = state.publicPosts.findIndex((p) => p.id === id)
      if (pubIndex !== -1) {
        // filter post
        state.publicPosts = state.publicPosts.filter((p) => p.id !== id)
        state.publicPostsCount -= 1
      }

      // check if post exists in popular posts
      const popIndex = state.popularPosts.findIndex((p) => p.id === id)
      if (popIndex !== -1) {
        // filter post
        state.popularPosts = state.popularPosts.filter((p) => p.id !== id)
        state.popularPostsCount -= 1
      }

      // check if post exists in recommended posts
      const recIndex = state.recommendedPosts.findIndex((p) => p.id === id)
      if (recIndex !== -1) {
        // filter post
        state.recommendedPosts = state.recommendedPosts.filter((p) => p.id !== id)
        state.recommendedPostsCount -= 1
      }

      // check if post exists in liked posts
      const lkIndex = state.likedPosts.findIndex((p) => p.id === id)
      if (lkIndex !== -1) {
        // filter post
        state.likedPosts = state.likedPosts.filter((p) => p.id !== id)
        state.likedPostsCount -= 1
      }

      // check if post exists in unlocked posts
      const unlIndex = state.unlockedPosts.findIndex((p) => p.id === id)
      if (unlIndex !== -1) {
        // filter post
        state.unlockedPosts = state.unlockedPosts.filter((p) => p.id !== id)
        state.unlockedPostsCount -= 1
      }

      // check if post exists in uploaded posts
      const uplIndex = state.uploadedPosts.findIndex((p) => p.id === id)
      if (uplIndex !== -1) {
        // filter post
        state.uploadedPosts = state.uploadedPosts.filter((p) => p.id !== id)
        state.uploadedPostsCount -= 1
      }

      // check if post exists in artist popular posts
      const aPopIndex = state.artistPopularPosts.findIndex((p) => p.id === id)
      if (aPopIndex !== -1) {
        // filter post
        state.artistPopularPosts = state.artistPopularPosts.filter((p) => p.id !== id)
        state.artistPopularPostsCount -= 1
      }

      // check if post exists in artist exclusive posts
      const aExIndex = state.artistExclusivePosts.findIndex((p) => p.id === id)
      if (aExIndex !== -1) {
        // filter post
        state.artistExclusivePosts = state.artistExclusivePosts.filter((p) => p.id !== id)
        state.artistExclusivePostsCount -= 1
      }

      // check if post exists in artist latest posts
      const aLatIndex = state.artistLatestPosts.findIndex((p) => p.id === id)
      if (aLatIndex !== -1) {
        // filter post
        state.artistLatestPosts = state.artistLatestPosts.filter((p) => p.id !== id)
        state.artistLatestPostsCount -= 1
      }
    },

    // filter user posts
    postFilterUserPosts: (state, action: PayloadAction<{ userId: string }>) => {
      const { userId } = action.payload

      // filter user posts from public posts
      const filteredPublicPosts = state.publicPosts.filter((p) => p.userId !== userId)
      const filteredPublicPostsCount =
        state.publicPosts.length - filteredPublicPosts.length

      // if filtered posts
      if (filteredPublicPostsCount > 0) {
        // update posts
        state.publicPosts = filteredPublicPosts
        state.publicPostsCount -= filteredPublicPostsCount
      }

      // filter user posts from popular posts
      const filteredPopularPosts = state.popularPosts.filter((p) => p.userId !== userId)
      const filteredPopularPostsCount =
        state.popularPosts.length - filteredPopularPosts.length

      // if filtered posts
      if (filteredPopularPostsCount > 0) {
        // update posts
        state.popularPosts = filteredPopularPosts
        state.popularPostsCount -= filteredPopularPostsCount
      }

      // filter user posts from recommended posts
      const filteredRecommendedPosts = state.recommendedPosts.filter(
        (p) => p.userId !== userId,
      )
      const filteredRecommendedPostsCount =
        state.recommendedPosts.length - filteredRecommendedPosts.length

      // if filtered posts
      if (filteredRecommendedPostsCount > 0) {
        // update posts
        state.recommendedPosts = filteredRecommendedPosts
        state.recommendedPostsCount -= filteredRecommendedPostsCount
      }

      // filter user posts from liked posts
      const filteredLikedPosts = state.likedPosts.filter((p) => p.userId !== userId)
      const filteredLikedPostsCount = state.likedPosts.length - filteredLikedPosts.length

      // if filtered posts
      if (filteredLikedPostsCount > 0) {
        // update posts
        state.likedPosts = filteredLikedPosts
        state.likedPostsCount -= filteredLikedPostsCount
      }

      // filter user posts from unlocked posts
      const filteredUnlockedPosts = state.unlockedPosts.filter((p) => p.userId !== userId)
      const filteredUnlockedPostsCount =
        state.unlockedPosts.length - filteredUnlockedPosts.length

      // if filtered posts
      if (filteredUnlockedPostsCount > 0) {
        // update posts
        state.unlockedPosts = filteredUnlockedPosts
        state.unlockedPostsCount -= filteredUnlockedPostsCount
      }

      // filter user posts from uploaded posts
      const filteredUploadedPosts = state.uploadedPosts.filter((p) => p.userId !== userId)
      const filteredUploadedPostsCount =
        state.uploadedPosts.length - filteredUploadedPosts.length

      // if filtered posts
      if (filteredUploadedPostsCount > 0) {
        // update posts
        state.uploadedPosts = filteredUploadedPosts
        state.uploadedPostsCount -= filteredUploadedPostsCount
      }

      // filter user posts from artist popular posts
      const filteredArtistPopularPosts = state.artistPopularPosts.filter(
        (p) => p.userId !== userId,
      )
      const filteredArtistPopularPostsCount =
        state.artistPopularPosts.length - filteredArtistPopularPosts.length

      // if filtered posts
      if (filteredArtistPopularPostsCount > 0) {
        // update posts
        state.artistPopularPosts = filteredArtistPopularPosts
        state.artistPopularPostsCount -= filteredArtistPopularPostsCount
      }

      // filter user posts from artist exclusive posts
      const filteredArtistExclusivePosts = state.artistExclusivePosts.filter(
        (p) => p.userId !== userId,
      )
      const filteredArtistExclusivePostsCount =
        state.artistExclusivePosts.length - filteredArtistExclusivePosts.length

      // if filtered posts
      if (filteredArtistExclusivePostsCount > 0) {
        // update posts
        state.artistExclusivePosts = filteredArtistExclusivePosts
        state.artistExclusivePostsCount -= filteredArtistExclusivePostsCount
      }

      // filter user posts from artist latest posts
      const filteredArtistLatestPosts = state.artistLatestPosts.filter(
        (p) => p.userId !== userId,
      )
      const filteredArtistLatestPostsCount =
        state.artistLatestPosts.length - filteredArtistLatestPosts.length

      // if filtered posts
      if (filteredArtistLatestPostsCount > 0) {
        // update posts
        state.artistLatestPosts = filteredArtistLatestPosts
        state.artistLatestPostsCount -= filteredArtistLatestPostsCount
      }
    },

    // update post
    postUpdatePost: (state, action: PayloadAction<{ updatedPost: EntityPostView }>) => {
      const { updatedPost } = action.payload

      // update post in public posts
      const pubIndex = state.publicPosts.findIndex((p) => p.id === updatedPost.id)
      if (pubIndex !== -1) {
        const currentPost = state.publicPosts[pubIndex]
        state.publicPosts[pubIndex] = { ...currentPost, ...updatedPost }
      }

      // update post in popular posts
      const popIndex = state.popularPosts.findIndex((p) => p.id === updatedPost.id)
      if (popIndex !== -1) {
        const currentPost = state.popularPosts[popIndex]
        state.popularPosts[popIndex] = { ...currentPost, ...updatedPost }
      }

      // update post in recommended posts
      const recIndex = state.recommendedPosts.findIndex((p) => p.id === updatedPost.id)
      if (recIndex !== -1) {
        const currentPost = state.recommendedPosts[recIndex]
        state.recommendedPosts[recIndex] = { ...currentPost, ...updatedPost }
      }

      // update post in liked posts
      const lkIndex = state.likedPosts.findIndex((p) => p.id === updatedPost.id)
      if (lkIndex !== -1) {
        const currentPost = state.likedPosts[lkIndex]
        state.likedPosts[lkIndex] = { ...currentPost, ...updatedPost }
      }

      // update post in unlocked posts
      const unlIndex = state.unlockedPosts.findIndex((p) => p.id === updatedPost.id)
      if (unlIndex !== -1) {
        const currentPost = state.unlockedPosts[unlIndex]
        state.unlockedPosts[unlIndex] = { ...currentPost, ...updatedPost }
      }

      // update post in uploaded posts
      const uplIndex = state.uploadedPosts.findIndex((p) => p.id === updatedPost.id)
      if (uplIndex !== -1) {
        const currentPost = state.uploadedPosts[uplIndex]
        state.uploadedPosts[uplIndex] = { ...currentPost, ...updatedPost }
      }

      // update post in artist popular posts
      const aPopIndex = state.artistPopularPosts.findIndex((p) => p.id === updatedPost.id)
      if (aPopIndex !== -1) {
        const currentPost = state.artistPopularPosts[aPopIndex]
        state.artistPopularPosts[aPopIndex] = { ...currentPost, ...updatedPost }
      }

      // update post in artist exclusive posts
      const aExIndex = state.artistExclusivePosts.findIndex(
        (p) => p.id === updatedPost.id,
      )
      if (aExIndex !== -1) {
        const currentPost = state.artistExclusivePosts[aExIndex]
        state.artistExclusivePosts[aExIndex] = { ...currentPost, ...updatedPost }
      }

      // update post in artist latest posts
      const aLatIndex = state.artistLatestPosts.findIndex((p) => p.id === updatedPost.id)
      if (aLatIndex !== -1) {
        const currentPost = state.artistLatestPosts[aLatIndex]
        state.artistLatestPosts[aLatIndex] = { ...currentPost, ...updatedPost }
      }

      // if post fetched and exists
      if (state.postFetched && state.post) {
        // if post id equal to updated user id
        if (state.post.id === updatedPost.id) {
          // update post
          state.post = updatedPost
        }
      }
    },

    // open post block modal
    postOpenPostBlockModal: (state, action: PayloadAction<{ post: EntityPostView }>) => ({
      ...state,
      postBlockModal: {
        post: action.payload.post,
        open: true,
      },
    }),

    // close post block modal
    postClosePostBlockModal: (state) => ({
      ...state,
      postBlockModal: {
        post: undefined,
        open: false,
      },
      error: '',
    }),

    // open post delete modal
    postOpenPostDeleteModal: (
      state,
      action: PayloadAction<{ post: EntityPostView }>,
    ) => ({
      ...state,
      postDeleteModal: {
        post: action.payload.post,
        open: true,
      },
    }),

    // close post delete modal
    postClosePostDeleteModal: (state) => ({
      ...state,
      postDeleteModal: {
        post: undefined,
        open: false,
      },
      error: '',
    }),
  },
  extraReducers: (builder) => {
    // get public posts
    builder
      .addCase(postGetPublicPosts.pending, (state) => ({
        ...state,
        postGetPublicPostsLoading: true,
      }))
      .addCase(postGetPublicPosts.fulfilled, (state) => ({
        ...state,
        publicPostsFetched: true,
        postGetPublicPostsLoading: false,
      }))

    // get popular posts
    builder
      .addCase(postGetPopularPosts.pending, (state) => ({
        ...state,
        postGetPopularPostsLoading: true,
      }))
      .addCase(postGetPopularPosts.fulfilled, (state) => ({
        ...state,
        popularPostsFetched: true,
        postGetPopularPostsLoading: false,
      }))

    // get recommended posts
    builder
      .addCase(postGetRecommendedPosts.pending, (state) => ({
        ...state,
        postGetRecommendedPostsLoading: true,
      }))
      .addCase(postGetRecommendedPosts.fulfilled, (state) => ({
        ...state,
        recommendedPostsFetched: true,
        postGetRecommendedPostsLoading: false,
      }))

    // get liked posts
    builder
      .addCase(postGetLikedPosts.pending, (state) => ({
        ...state,
        postGetLikedPostsLoading: true,
      }))
      .addCase(postGetLikedPosts.fulfilled, (state) => ({
        ...state,
        likedPostsFetched: true,
        postGetLikedPostsLoading: false,
      }))

    // get unlocked posts
    builder
      .addCase(postGetUnlockedPosts.pending, (state) => ({
        ...state,
        postGetUnlockedPostsLoading: true,
      }))
      .addCase(postGetUnlockedPosts.fulfilled, (state) => ({
        ...state,
        unlockedPostsFetched: true,
        postGetUnlockedPostsLoading: false,
      }))

    // get uploaded posts
    builder
      .addCase(postGetUploadedPosts.pending, (state) => ({
        ...state,
        postGetUploadedPostsLoading: true,
      }))
      .addCase(postGetUploadedPosts.fulfilled, (state) => ({
        ...state,
        uploadedPostsFetched: true,
        postGetUploadedPostsLoading: false,
      }))

    // get artist popular posts
    builder
      .addCase(postGetArtistPopularPosts.pending, (state) => ({
        ...state,
        postGetArtistPopularPostsLoading: true,
      }))
      .addCase(postGetArtistPopularPosts.fulfilled, (state) => ({
        ...state,
        artistPopularPostsFetched: true,
        postGetArtistPopularPostsLoading: false,
      }))

    // get artist exclusive posts
    builder
      .addCase(postGetArtistExclusivePosts.pending, (state) => ({
        ...state,
        postGetArtistExclusivePostsLoading: true,
      }))
      .addCase(postGetArtistExclusivePosts.fulfilled, (state) => ({
        ...state,
        artistExclusivePostsFetched: true,
        postGetArtistExclusivePostsLoading: false,
      }))

    // get artist latest posts
    builder
      .addCase(postGetArtistLatestPosts.pending, (state) => ({
        ...state,
        postGetArtistLatestPostsLoading: true,
      }))
      .addCase(postGetArtistLatestPosts.fulfilled, (state) => ({
        ...state,
        artistLatestPostsFetched: true,
        postGetArtistLatestPostsLoading: false,
      }))

    // get post
    builder
      .addCase(postGetPost.pending, (state) => ({
        ...state,
        postGetPostLoading: true,
      }))
      .addCase(postGetPost.fulfilled, (state) => ({
        ...state,
        postFetched: true,
        postGetPostLoading: false,
      }))

    // create post
    builder
      .addCase(postCreate.pending, (state) => ({
        ...state,
        postCreateLoading: true,
      }))
      .addCase(postCreate.fulfilled, (state) => ({
        ...state,
        postCreateLoading: false,
      }))

    // update post
    builder
      .addCase(postUpdate.pending, (state) => ({
        ...state,
        postUpdateLoading: true,
      }))
      .addCase(postUpdate.fulfilled, (state) => ({
        ...state,
        postUpdateLoading: false,
      }))

    // block post
    builder
      .addCase(postBlock.pending, (state) => ({
        ...state,
        postBlockLoading: true,
      }))
      .addCase(postBlock.fulfilled, (state) => ({
        ...state,
        postBlockLoading: false,
      }))

    // delete post
    builder
      .addCase(postDelete.pending, (state) => ({
        ...state,
        postDeleteLoading: true,
      }))
      .addCase(postDelete.fulfilled, (state) => ({
        ...state,
        postDeleteLoading: false,
      }))
  },
})

export const {
  postResetState,
  postSetPublicPosts,
  postSetPopularPosts,
  postSetRecommendedPosts,
  postSetLikedPosts,
  postSetUnlockedPosts,
  postSetUploadedPosts,
  postSetArtistPopularPosts,
  postSetArtistExclusivePosts,
  postSetArtistLatestPosts,
  postResetArtistPosts,
  postSetPost,
  postResetPost,
  postAppendLikedPost,
  postFilterLikedPost,
  postAppendUnlockedPost,
  postFilterUnlockedPost,
  postFilterPost,
  postFilterUserPosts,
  postUpdatePost,
  postOpenPostBlockModal,
  postClosePostBlockModal,
  postOpenPostDeleteModal,
  postClosePostDeleteModal,
} = postSlice.actions

export const postSelectPublicPosts = (state: RootState) => state.post.publicPosts
export const postSelectPublicPostsLength = (state: RootState) =>
  state.post.publicPosts.length
export const postSelectPublicPostsCount = (state: RootState) =>
  state.post.publicPostsCount
export const postSelectPublicPostsFetched = (state: RootState) =>
  state.post.publicPostsFetched

export const postSelectPopularPosts = (state: RootState) => state.post.popularPosts
export const postSelectPopularPostsLength = (state: RootState) =>
  state.post.popularPosts.length
export const postSelectPopularPostsCount = (state: RootState) =>
  state.post.popularPostsCount
export const postSelectPopularPostsFetched = (state: RootState) =>
  state.post.popularPostsFetched

export const postSelectRecommendedPosts = (state: RootState) =>
  state.post.recommendedPosts
export const postSelectRecommendedPostsLength = (state: RootState) =>
  state.post.recommendedPosts.length
export const postSelectRecommendedPostsCount = (state: RootState) =>
  state.post.recommendedPostsCount
export const postSelectRecommendedPostsFetched = (state: RootState) =>
  state.post.recommendedPostsFetched

export const postSelectLikedPosts = (state: RootState) => state.post.likedPosts
export const postSelectLikedPostsLength = (state: RootState) =>
  state.post.likedPosts.length
export const postSelectLikedPostsCount = (state: RootState) => state.post.likedPostsCount
export const postSelectLikedPostsFetched = (state: RootState) =>
  state.post.likedPostsFetched

export const postSelectUnlockedPosts = (state: RootState) => state.post.unlockedPosts
export const postSelectUnlockedPostsLength = (state: RootState) =>
  state.post.unlockedPosts.length
export const postSelectUnlockedPostsCount = (state: RootState) =>
  state.post.unlockedPostsCount
export const postSelectUnlockedPostsFetched = (state: RootState) =>
  state.post.unlockedPostsFetched

export const postSelectUploadedPosts = (state: RootState) => state.post.uploadedPosts
export const postSelectUploadedPostsLength = (state: RootState) =>
  state.post.uploadedPosts.length
export const postSelectUploadedPostsCount = (state: RootState) =>
  state.post.uploadedPostsCount
export const postSelectUploadedPostsFetched = (state: RootState) =>
  state.post.uploadedPostsFetched

export const postSelectArtistPopularPosts = (state: RootState) =>
  state.post.artistPopularPosts
export const postSelectArtistPopularPostsLength = (state: RootState) =>
  state.post.artistPopularPosts.length
export const postSelectArtistPopularPostsCount = (state: RootState) =>
  state.post.artistPopularPostsCount
export const postSelectArtistPopularPostsFetched = (state: RootState) =>
  state.post.artistPopularPostsFetched

export const postSelectArtistExclusivePosts = (state: RootState) =>
  state.post.artistExclusivePosts
export const postSelectArtistExclusivePostsLength = (state: RootState) =>
  state.post.artistExclusivePosts.length
export const postSelectArtistExclusivePostsCount = (state: RootState) =>
  state.post.artistExclusivePostsCount
export const postSelectArtistExclusivePostsFetched = (state: RootState) =>
  state.post.artistExclusivePostsFetched

export const postSelectArtistLatestPosts = (state: RootState) =>
  state.post.artistLatestPosts
export const postSelectArtistLatestPostsLength = (state: RootState) =>
  state.post.artistLatestPosts.length
export const postSelectArtistLatestPostsCount = (state: RootState) =>
  state.post.artistLatestPostsCount
export const postSelectArtistLatestPostsFetched = (state: RootState) =>
  state.post.artistLatestPostsFetched

export const postSelectPost = (state: RootState) => state.post.post
export const postSelectPostFetched = (state: RootState) => state.post.postFetched

export const postSelectPostBlockModalOpen = (state: RootState) =>
  state.post.postBlockModal.open
export const postSelectPostBlockModalPost = (state: RootState) =>
  state.post.postBlockModal.post

export const postSelectPostDeleteModalOpen = (state: RootState) =>
  state.post.postDeleteModal.open
export const postSelectPostDeleteModalPost = (state: RootState) =>
  state.post.postDeleteModal.post

export const postSelectPostGetPublicPostsLoading = (state: RootState) =>
  state.post.postGetPublicPostsLoading
export const postSelectPostGetPopularPostsLoading = (state: RootState) =>
  state.post.postGetPopularPostsLoading
export const postSelectPostGetRecommendedPostsLoading = (state: RootState) =>
  state.post.postGetRecommendedPostsLoading
export const postSelectPostGetLikedPostsLoading = (state: RootState) =>
  state.post.postGetLikedPostsLoading
export const postSelectPostGetUnlockedPostsLoading = (state: RootState) =>
  state.post.postGetUnlockedPostsLoading
export const postSelectPostGetUploadedPostsLoading = (state: RootState) =>
  state.post.postGetUploadedPostsLoading
export const postSelectPostGetArtistPopularPostsLoading = (state: RootState) =>
  state.post.postGetArtistPopularPostsLoading
export const postSelectPostGetArtistExclusivePostsLoading = (state: RootState) =>
  state.post.postGetArtistExclusivePostsLoading
export const postSelectPostGetArtistLatestPostsLoading = (state: RootState) =>
  state.post.postGetArtistLatestPostsLoading
export const postSelectPostGetPostLoading = (state: RootState) =>
  state.post.postGetPostLoading
export const postSelectPostCreateLoading = (state: RootState) =>
  state.post.postCreateLoading
export const postSelectPostUpdateLoading = (state: RootState) =>
  state.post.postUpdateLoading
export const postSelectPostBlockLoading = (state: RootState) =>
  state.post.postBlockLoading
export const postSelectPostDeleteLoading = (state: RootState) =>
  state.post.postDeleteLoading

export const postReducer = postSlice.reducer

// export default postSlice.reducer

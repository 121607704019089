import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch, useSubscriptionPrice } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // user
  ArtistCardProduct,
  // subscription
  subscriptionSelectSubscriptionRenewSubscriptionLoading,
  subscriptionSelectSubscriptionRenewModalArtist,
  subscriptionRenewSubscription,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type SubscriptionRenewFormProps = {}

export const SubscriptionRenewForm: FC<SubscriptionRenewFormProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const subscriptionRenewLoading = useAppSelector(
    subscriptionSelectSubscriptionRenewSubscriptionLoading,
  )
  const profile = useAppSelector(authSelectProfile)
  const modalArtist = useAppSelector(subscriptionSelectSubscriptionRenewModalArtist)

  const { id: profileUserId } = profile ?? {}
  const { id: artistUserId } = modalArtist ?? {}

  const isCurrentUser = profileUserId === artistUserId
  const submitDisabled = isCurrentUser || subscriptionRenewLoading

  // subscription price
  const {
    subscriptionPrice: artistSubscriptionPrice,
    isLoading: isArtistSubscriptionPriceLoading,
  } = useSubscriptionPrice(artistUserId || '')
  const artistSubscriptionPriceLoadingPlaceholder = 'Loading...'

  return (
    <div css={styles(theme).form.main}>
      <form
        css={styles(theme).form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(subscriptionRenewSubscription())
        }}
      >
        {/* user product */}
        <div css={styles(theme).form.content.product}>
          <ArtistCardProduct
            artist={modalArtist || {}}
            onNameClick={() => navigate(`${Path.User}/${artistUserId}`)}
            onUsernameClick={() => navigate(`${Path.User}/${artistUserId}`)}
          />
        </div>

        {/* description */}
        <div css={styles(theme).form.content.description.main}>
          <div css={styles(theme).form.content.description.text}>
            When you renew your subscription, you will be charged at the end of the
            current subscription period.
          </div>
        </div>

        {/* submit */}
        <div css={styles(theme).form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="subscribe"
            disabled={submitDisabled}
            appearance="primary"
          >
            {subscriptionRenewLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Renew subscription for $${
                isArtistSubscriptionPriceLoading
                  ? artistSubscriptionPriceLoadingPlaceholder
                  : artistSubscriptionPrice && artistSubscriptionPrice / 100
              }`
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

SubscriptionRenewForm.propTypes = {}

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type TipMessageProps = {
  message: string
}

export const TipMessage: FC<TipMessageProps> = ({ message }) => {
  const theme = useTheme()

  return <p css={styles(theme).message.main}>&quot;{message}&quot;</p>
}

TipMessage.propTypes = {}

import {
  EntityUser,
  EntityUserRole,
  EntityOnboardingStage,
} from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'

// icons
import { icons } from '../assets'

const publicNavLinks = [
  {
    name: 'Home',
    path: Path.Home,
    icon: icons.HomeIcon,
  },
]

const fanNavLinks = [
  {
    name: 'Home',
    path: Path.Feed,
    icon: icons.HomeIcon,
  },
  {
    name: 'Library',
    path: Path.Library,
    icon: icons.LibraryIcon,
  },
  {
    name: 'Charts',
    path: Path.Charts,
    icon: icons.ChartsIcon,
  },
  {
    name: 'Profile',
    path: Path.Profile,
    icon: icons.ProfileIcon,
  },
]

const artistNavLinks = [
  {
    name: 'Home',
    path: Path.Feed,
    icon: icons.HomeIcon,
  },
  {
    name: 'Library',
    path: Path.Library,
    icon: icons.LibraryIcon,
  },
  {
    name: 'Charts',
    path: Path.Charts,
    icon: icons.ChartsIcon,
  },
  {
    name: 'Studio',
    path: Path.Studio,
    icon: icons.StudioIcon,
  },
  {
    name: 'Profile',
    path: Path.Profile,
    icon: icons.ProfileIcon,
  },
]

const adminNavLinks = [
  {
    name: 'Home',
    path: Path.Feed,
    icon: icons.HomeIcon,
  },
  {
    name: 'Library',
    path: Path.Library,
    icon: icons.LibraryIcon,
  },
  {
    name: 'Charts',
    path: Path.Charts,
    icon: icons.ChartsIcon,
  },
  {
    name: 'Reports',
    path: Path.Reports,
    icon: icons.ReportsIcon,
  },
  {
    name: 'Profile',
    path: Path.Profile,
    icon: icons.ProfileIcon,
  },
]

export const getNavLinks = (user: EntityUser | null) => {
  // if fan
  if (
    user &&
    user.role === EntityUserRole.Fan &&
    user.onboardingStage === EntityOnboardingStage.Finished
  ) {
    return fanNavLinks
  }

  // if artist
  if (
    user &&
    user.role === EntityUserRole.Artist &&
    user.onboardingStage === EntityOnboardingStage.Finished
  ) {
    return artistNavLinks
  }

  // if admin
  if (
    user &&
    user.role === EntityUserRole.Admin &&
    user.onboardingStage === EntityOnboardingStage.Finished
  ) {
    return adminNavLinks
  }

  return publicNavLinks
}

import {
  Configuration,
  AuthApi,
  CommentApi,
  ConnectApi,
  CustomerApi,
  FileApi,
  LikeApi,
  NotificationApi,
  PostApi,
  ReportApi,
  StreamApi,
  SubscriptionApi,
  TipApi,
  TrackApi,
  TransactionApi,
  UnlockApi,
  UserApi,
} from '@ryddm-inc/ryddm-apiclient'

// config
import { API, Environment } from '@/config'

// libs
import { storageGetAuthToken } from '@/lib'

/**
 * Creates new instance of api client.
 *
 * Takes api base path and jwt token, returns api service.
 *
 * @param {string} basePath - API base path.
 * @param {number} apiKey - API authentication token (JWT).
 * @returns {number} The sum of the two input numbers.
 *
 * @example
 * // Returns api service.
 * const apiService = createApiService('https://api.example.com/api/v1', 'eyJhbGciOiJI...');
 */
const apiCreateService = (basePath: string, apiKey: string) => ({
  basePath,
  authApi: new AuthApi(new Configuration({ apiKey, basePath })),
  commentApi: new CommentApi(new Configuration({ apiKey, basePath })),
  connectApi: new ConnectApi(new Configuration({ apiKey, basePath })),
  customerApi: new CustomerApi(new Configuration({ apiKey, basePath })),
  fileApi: new FileApi(new Configuration({ apiKey, basePath })),
  likeApi: new LikeApi(new Configuration({ apiKey, basePath })),
  notificationApi: new NotificationApi(new Configuration({ apiKey, basePath })),
  postApi: new PostApi(new Configuration({ apiKey, basePath })),
  reportApi: new ReportApi(new Configuration({ apiKey, basePath })),
  streamApi: new StreamApi(new Configuration({ apiKey, basePath })),
  subscriptionApi: new SubscriptionApi(new Configuration({ apiKey, basePath })),
  tipApi: new TipApi(new Configuration({ apiKey, basePath })),
  trackApi: new TrackApi(new Configuration({ apiKey, basePath })),
  transactionApi: new TransactionApi(new Configuration({ apiKey, basePath })),
  unlockApi: new UnlockApi(new Configuration({ apiKey, basePath })),
  userApi: new UserApi(new Configuration({ apiKey, basePath })),
})

// cachedApiService - represents cached api service.
let cachedApiService: ReturnType<typeof apiCreateService>

// apiGetService - creates and caches api service, implements apiKey update functionality.
export const apiGetService = () => {
  // get api url based on environment
  const basePath =
    process.env.ENVIRONMENT === Environment.Production
      ? API.ProductionURL
      : API.StagingURL

  // if no api service in cache
  if (!cachedApiService) {
    const apiKey = storageGetAuthToken()

    // create api service and cache it
    cachedApiService = apiCreateService(basePath, apiKey ? `Bearer ${apiKey}` : '')
  }

  // updateApiKey - recreates api service with new authentication token and updates cache.
  const updateApiKey = (apiKey: string) => {
    cachedApiService = apiCreateService(basePath, `Bearer ${apiKey}`)
    // return api service and update function.
    return {
      ...cachedApiService,
      updateApiKey,
    }
  }

  // return api service and update function.
  return {
    ...cachedApiService,
    updateApiKey,
  }
}

// ApiError - represents apiHandleError result data.
export type ApiError = {
  code: number
  message: string
}

/**
 * Handles api errors.
 *
 * Takes an error and returns error message.
 *
 * @param {any} err - Error.
 * @param {string} context - Context will be appended to the error. (optional)
 * @returns {ApiError} Error.
 *
 * @example
 * // Returns api error
 * const error = apiHandleError(err, 'Error occurred during authentication');
 */
export const apiHandleError = async (err: any, context?: string): Promise<ApiError> => {
  // if error of type response
  if (err instanceof Response) {
    // parse error
    const data = await err.json()

    const errorCode = err.status
    const errorMessage = context ? `${context}: ${data.error}` : data.error

    // if error severity is high
    if (errorCode >= 500) {
      console.error(err) // eslint-disable-line no-console
    }
    console.debug(err) // eslint-disable-line no-console

    return {
      code: errorCode,
      message: errorMessage,
    }
  }

  // if error is unknown
  const errorMessage = context ? `${context}: ${err.message}` : err.message
  console.error(err) // eslint-disable-line no-console

  return {
    code: 0,
    message: errorMessage,
  }
}

import { FC, memo } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityTransactionType } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  TransactionCard,
  transactionSelectTransactionGetIncomeTransactionsLoading,
  transactionSelectIncomeTransactions,
  transactionSelectIncomeTransactionsCount,
  transactionSelectIncomeTransactionsLength,
  transactionSelectIncomeTransactionsFetched,
  transactionSelectIncomeTransactionsFilter,
  transactionSetIncomeTransactionsFilter,
  transactionGetIncomeTransactions,
} from '@/features'
// components
import {
  NavigationScrollable,
  ContentPlaceholder,
  SkeletonHorizontal,
} from '@/components'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const transactions = useAppSelector(transactionSelectIncomeTransactions)
  const transaction = transactions[index]

  // if transaction not fetched
  if (!transaction) {
    return (
      <div style={style}>
        <SkeletonHorizontal
          sections={[
            {
              name: 'transaction-list-transaction-income',
              width: '100%',
              height: '60px',
              isStatic: false,
              isDivider: false,
            },
          ]}
        />
      </div>
    )
  }

  return (
    <div css={styles().transactions.main} style={style}>
      <TransactionCard direction="income" transaction={transaction} onClick={() => {}} />
    </div>
  )
})

type TransactionListProfileIncomeProps = {}

export const TransactionListProfileIncome: FC<TransactionListProfileIncomeProps> = () => {
  const dispatch = useAppDispatch()
  const transactionIncomeLoading = useAppSelector(
    transactionSelectTransactionGetIncomeTransactionsLoading,
  )
  const incomeTransactions = useAppSelector(transactionSelectIncomeTransactions)
  const incomeTransactionsCount = useAppSelector(transactionSelectIncomeTransactionsCount)
  const incomeTransactionsLength = useAppSelector(
    transactionSelectIncomeTransactionsLength,
  )
  const incomeTransactionsFetched = useAppSelector(
    transactionSelectIncomeTransactionsFetched,
  )
  const incomeTransactionsFilter = useAppSelector(
    transactionSelectIncomeTransactionsFilter,
  )

  const transactionCardHeight = 60
  const transactionCardMarginBottom = 20
  const itemHeight = transactionCardHeight + transactionCardMarginBottom

  const defaultContentCount = 1000
  const defaultListWidth = '100%'
  const defaultListHeight = 400
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!incomeTransactions[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(
      transactionGetIncomeTransactions({ limit: itemsToFetch, offset: startIndex }),
    )
  }

  // renders transaction list navigation
  const renderTransactionListNavigation = () => (
    <div css={styles().list.nav.main}>
      <NavigationScrollable
        options={[
          {
            id: '8117e0a6-74dd-4057-826e-7686c0bacaea',
            label: 'Subscription',
            enabled: incomeTransactionsFilter === EntityTransactionType.Subscription,
            onClick: () => {
              // set transaction filter
              dispatch(
                transactionSetIncomeTransactionsFilter({
                  filter: EntityTransactionType.Subscription,
                }),
              )

              // refetch transactions
              dispatch(transactionGetIncomeTransactions({ limit: 30, offset: 0 }))
            },
          },
          {
            id: '5627276c-1ef7-458d-a969-055ffeba8b31',
            label: 'Unlock',
            enabled: incomeTransactionsFilter === EntityTransactionType.Unlock,
            onClick: () => {
              // set transaction filter
              dispatch(
                transactionSetIncomeTransactionsFilter({
                  filter: EntityTransactionType.Unlock,
                }),
              )

              // refetch transactions
              dispatch(transactionGetIncomeTransactions({ limit: 30, offset: 0 }))
            },
          },
          {
            id: '7785a9b4-9e85-4ee5-b415-b317c4781a4f',
            label: 'Tip',
            enabled: incomeTransactionsFilter === EntityTransactionType.Tip,
            onClick: () => {
              // set transaction filter
              dispatch(
                transactionSetIncomeTransactionsFilter({
                  filter: EntityTransactionType.Tip,
                }),
              )

              // refetch transactions
              dispatch(transactionGetIncomeTransactions({ limit: 30, offset: 0 }))
            },
          },
        ]}
      />
    </div>
  )

  // renders transaction list table header
  const renderTransactionListTableHeader = () => (
    <div css={styles().list.header.main}>
      <div css={styles().list.header.placeholder} />
      <div css={styles().list.header.source}>Source</div>
      <div css={styles().list.header.amount}>Amount</div>
      <div css={styles().list.header.type}>Type</div>
      <div css={styles().list.header.status}>Status</div>
      <div css={styles().list.header.date}>Date</div>
    </div>
  )

  // if income transactions are fetched but length is 0
  if (
    !transactionIncomeLoading &&
    incomeTransactionsFetched &&
    incomeTransactionsLength === 0
  ) {
    // return place holder
    return (
      // used to be content layout virtualized
      <div css={styles().list.main}>
        {renderTransactionListNavigation()}
        {renderTransactionListTableHeader()}
        <div css={styles().list.content.main}>
          <ContentPlaceholder
            icon={icons.CreditCardIncome}
            height="400px"
            title="You don't have any income transactions yet"
          />
        </div>
      </div>
    )
  }

  return (
    // used to be content layout virtualized
    <div css={styles().list.main}>
      {renderTransactionListNavigation()}
      {renderTransactionListTableHeader()}
      <div css={styles().list.content.main}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          itemCount={incomeTransactionsCount || defaultContentCount}
          minimumBatchSize={itemsToFetch}
          threshold={itemThreshold}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className="virtualized-list"
              height={defaultListHeight}
              itemSize={itemHeight}
              itemCount={incomeTransactionsCount || defaultContentCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={defaultListWidth}
              layout="vertical"
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </div>
    </div>
  )
}

TransactionListProfileIncome.propTypes = {}

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// components
import { CardHeaderMobile, ImageComposition } from '@/components'

// components
import { CardDescription, CardLogin, CardSignup } from './components'
// styles
import { styles } from './styles'

type ModalCardProps = {
  onCloseClick: MouseEventHandler<HTMLButtonElement>
  onSignupClick: MouseEventHandler<HTMLButtonElement>
  onLoginClick: MouseEventHandler<HTMLButtonElement>
}

export const ModalCard: FC<ModalCardProps> = ({
  onCloseClick,
  onSignupClick,
  onLoginClick,
}) => {
  const theme = useTheme()

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile onCloseClick={(e) => onCloseClick(e)} />
      </div>

      {/* composition */}
      <div css={styles(theme).card.composition}>
        <ImageComposition />
      </div>

      {/* description */}
      <div css={styles(theme).card.description}>
        <CardDescription
          title="Unlock the world of music"
          subtitle="Create your free RYDDM account"
          accentStart={17}
          accentEnd={22}
        />
      </div>

      {/* buttons */}
      <div css={styles(theme).card.buttons}>
        <CardSignup onSignupClick={(e) => onSignupClick(e)} />

        <CardLogin
          description="Already have account?"
          onLoginClick={(e) => onLoginClick(e)}
        />
      </div>
    </div>
  )
}

ModalCard.propTypes = {}

import { css, Theme } from '@emotion/react'
import { kf } from '@/styles'

export const styles = (theme: Theme) => ({
  loader: {
    main: css`
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    bars: {
      shared: (width: number, height: number) => {
        const baseWidth = width / 7

        return css`
          position: relative;
          width: ${width}px;
          height: ${height}px;

          animation: 1s linear infinite alternate ${kf.load.bars(width, height)};

          background-repeat: no-repeat;
          background-position: 0px center, ${baseWidth * 1}px center,
            ${baseWidth * 2}px center, ${baseWidth * 3}px center,
            ${baseWidth * 4}px center, ${baseWidth * 5}px center,
            ${baseWidth * 6}px center;
        `
      },
      primary: (width: number, height: number) => css`
        background-image: linear-gradient(
            ${theme.colors.primary.normal} ${height}px,
            transparent 0
          ),
          linear-gradient(${theme.colors.primary.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.primary.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.primary.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.primary.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.primary.normal} ${height}px, transparent 0);
      `,
      secondary: (width: number, height: number) => css`
        background-image: linear-gradient(
            ${theme.colors.secondary.normal} ${height}px,
            transparent 0
          ),
          linear-gradient(${theme.colors.secondary.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.secondary.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.secondary.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.secondary.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.secondary.normal} ${height}px, transparent 0);
      `,
      accent: (width: number, height: number) => css`
        background-image: linear-gradient(
            ${theme.colors.accent.normal} ${height}px,
            transparent 0
          ),
          linear-gradient(${theme.colors.accent.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.accent.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.accent.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.accent.normal} ${height}px, transparent 0),
          linear-gradient(${theme.colors.accent.normal} ${height}px, transparent 0);
      `,
    },
  },
})

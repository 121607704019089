import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  preview: {
    main: (width: string, height: string) => css`
      position: relative;
      width: ${width};
      height: ${height};

      border-radius: 4px;
      overflow: hidden;
    `,

    button: css`
      ${helpers.resetDefaults};

      width: 100%;
      height: 100%;

      cursor: pointer;
    `,

    image: {
      main: (width: string, height: string) => css`
        display: flex;
        align-items: center;
        justify-content: center;

        width: ${width};
        height: ${height};

        background-color: ${theme.colors.secondary.darker};

        overflow: hidden;
      `,
      overlay: {
        main: css``,
        content: css`
          ${helpers.image};

          border-radius: 4px;
        `,
        icon: css`
          width: 40px;
          height: 40px;

          margin-bottom: 4px;

          & > path {
            fill: ${theme.colors.secondary.normal};
          }
        `,
        text: {
          main: css`
            font-size: ${typography.sp};
            color: ${theme.colors.secondary.normal};
          `,
          formats: css`
            margin-bottom: 2px;
          `,
          size: css``,
        },
      },
    },

    helpers: {
      round: css`
        border-radius: 50%;
      `,
    },
  },
})

import { FC, useState } from 'react'
import { useTheme } from '@emotion/react'
import { EntityUserRole } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch, useFile, useConnectReady } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // user
  userSelectUser,
  userSelectUserFetched,
  userOpenUserBlockModal,
  userUtilRenderArtistActions,
  // like
  likeCreateUserFollow,
  likeDeleteUserFollow,
  likeSelectUserFollows,
  // subscription
  subscriptionOpenSubscriptionCancelModal,
  subscriptionOpenSubscriptionCreateModal,
  subscriptionOpenSubscriptionRenewModal,
  subscriptionSelectUserSubscriptions,
  subscriptionSelectUserSubscriptionsAtPeriodEnd,
  // tip
  tipOpenTipModal,
  // report
  reportOpenReportUserModal,
} from '@/features'
// components
import { ButtonMedium, ContentPlaceholder, SkeletonVertical } from '@/components'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type UserViewUserProps = {}

export const UserViewUser: FC<UserViewUserProps> = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const profile = useAppSelector(authSelectProfile)
  const userFollows = useAppSelector(likeSelectUserFollows)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const userSubscriptionsAtPeriodEnd = useAppSelector(
    subscriptionSelectUserSubscriptionsAtPeriodEnd,
  )
  const user = useAppSelector(userSelectUser)
  const userFetched = useAppSelector(userSelectUserFetched)

  const { id: profileUserId, role: profileUserRole } = profile ?? {}

  const { id: userId, name, username, avatarId, preferredGenres, role } = user ?? {}

  const userHasAvatar = !!avatarId
  const source = useFile(avatarId || '')
  const [avatarLoadError, setAvatarLoadError] = useState(false)

  // connect ready
  const { ready: isUserCanReceivePayments } = useConnectReady(userId || '')

  const followDisabled = false
  const unfollowDisabled = false
  const subscribeDisabled = false
  const unsubscribeDisabled = false
  const tipDisabled = false
  const blockDisabled = false
  const reportDisabled = false

  const isUserArtist = role === EntityUserRole.Artist
  const isCurrentUserAdmin = profileUserRole === EntityUserRole.Admin

  const isUserFollowed = userFollows[userId || '']

  const isCurrentUserProfile = profileUserId === userId
  const isCurrentUserSubscribedToUser = userSubscriptions[userId || '']
  const isCurrentUserSubscribedToUserAtPeriodEnd =
    userSubscriptionsAtPeriodEnd[userId || '']

  // if user not found
  if (userFetched && !user) {
    return (
      <div css={styles(theme).view.main}>
        <ContentPlaceholder
          icon={icons.AlertTriangle}
          height="180px"
          title="Could not find user"
        />
      </div>
    )
  }

  const renderArtistActionsInput = {
    isCurrentUserFollowingArtist: isUserFollowed,
    isCurrentUserSubscribedToArtist: isCurrentUserSubscribedToUser,
    isCurrentUserSubscribedToArtistAtPeriodEnd: isCurrentUserSubscribedToUserAtPeriodEnd,
    isArtistCanReceivePayments: !!isUserCanReceivePayments,

    createUserFollowNode: (
      <div css={styles(theme).view.user.buttons.follow.main}>
        <ButtonMedium
          type="button"
          aria-label="follow artist"
          disabled={followDisabled}
          appearance="secondary"
          onClick={() => dispatch(likeCreateUserFollow({ user: { ...user } }))}
        >
          <div css={styles(theme).view.user.buttons.follow.content.main}>
            <div css={styles(theme).view.user.buttons.follow.content.icon}>
              <icons.PlusCircle />
            </div>
            <div css={styles(theme).view.user.buttons.follow.content.text}>Follow</div>
          </div>
        </ButtonMedium>
      </div>
    ),
    deleteUserFollowNode: (
      <div css={styles(theme).view.user.buttons.unfollow.main}>
        <ButtonMedium
          type="button"
          aria-label="unfollow artist"
          disabled={unfollowDisabled}
          appearance="secondary"
          onClick={() => dispatch(likeDeleteUserFollow({ user: { ...user } }))}
        >
          <div css={styles(theme).view.user.buttons.unfollow.content.main}>
            <div css={styles(theme).view.user.buttons.unfollow.content.icon}>
              <icons.CrossCircle />
            </div>
            <div css={styles(theme).view.user.buttons.unfollow.content.text}>
              Unfollow
            </div>
          </div>
        </ButtonMedium>
      </div>
    ),
    createSubscriptionNode: (
      <div css={styles(theme).view.user.buttons.subscribe.main}>
        <ButtonMedium
          type="button"
          aria-label="subscribe to artist"
          disabled={subscribeDisabled}
          appearance="primary"
          onClick={() =>
            dispatch(subscriptionOpenSubscriptionCreateModal({ artist: { ...user } }))
          }
        >
          <div css={styles(theme).view.user.buttons.subscribe.content.main}>
            <div css={styles(theme).view.user.buttons.subscribe.content.icon}>
              <icons.Lock />
            </div>
            <div css={styles(theme).view.user.buttons.subscribe.content.text}>
              Subscribe
            </div>
          </div>
        </ButtonMedium>
      </div>
    ),
    renewSubscriptionNode: (
      <div css={styles(theme).view.user.buttons.subscribe.main}>
        <ButtonMedium
          type="button"
          aria-label="subscribe to artist"
          disabled={subscribeDisabled}
          appearance="primary"
          onClick={() =>
            dispatch(subscriptionOpenSubscriptionRenewModal({ artist: { ...user } }))
          }
        >
          <div css={styles(theme).view.user.buttons.subscribe.content.main}>
            <div css={styles(theme).view.user.buttons.subscribe.content.icon}>
              <icons.Lock />
            </div>
            <div css={styles(theme).view.user.buttons.subscribe.content.text}>
              Subscribe
            </div>
          </div>
        </ButtonMedium>
      </div>
    ),
    cancelSubscriptionNode: (
      <div css={styles(theme).view.user.buttons.subscribe.main}>
        <ButtonMedium
          type="button"
          aria-label="unsubscribe from artist"
          disabled={unsubscribeDisabled}
          appearance="secondary"
          onClick={() =>
            dispatch(subscriptionOpenSubscriptionCancelModal({ artist: { ...user } }))
          }
        >
          <div css={styles(theme).view.user.buttons.subscribe.content.main}>
            <div css={styles(theme).view.user.buttons.subscribe.content.icon}>
              <icons.Lock />
            </div>
            <div css={styles(theme).view.user.buttons.subscribe.content.text}>
              Unsubscribe
            </div>
          </div>
        </ButtonMedium>
      </div>
    ),
    defaultNode: <div />,
  }

  // if user found and user is artist
  if (userFetched && user && isUserArtist) {
    return (
      <div css={styles(theme).view.main}>
        <div css={styles(theme).view.user.main}>
          {/* profile */}
          <div css={styles(theme).view.user.profile.main}>
            <div css={styles(theme).view.user.profile.avatar.main}>
              {userHasAvatar && !avatarLoadError ? (
                <img
                  css={styles(theme).view.user.profile.avatar.image}
                  onError={() => setAvatarLoadError(true)}
                  loading="lazy"
                  width="100px"
                  height="100px"
                  src={source}
                  alt="user avatar"
                />
              ) : (
                <icons.User css={styles(theme).view.user.profile.avatar.icon} />
              )}
            </div>
            <div css={styles(theme).view.user.profile.content.main}>
              <div css={styles(theme).view.user.profile.content.data.main}>
                <div css={styles(theme).view.user.profile.content.data.name}>{name}</div>
                <div css={styles(theme).view.user.profile.content.data.username}>
                  @{username}
                </div>
              </div>
              <div css={styles(theme).view.user.profile.content.genres}>
                {preferredGenres?.map((genre, i) =>
                  preferredGenres.length - 1 === i ? `${genre}` : `${genre}, `,
                )}
              </div>
            </div>
          </div>

          {/* buttons */}
          <div css={styles(theme).view.user.buttons.main}>
            {!isCurrentUserProfile &&
              userUtilRenderArtistActions(renderArtistActionsInput)}

            {!isCurrentUserProfile && isUserCanReceivePayments && (
              <div css={styles(theme).view.user.buttons.tip.main}>
                <ButtonMedium
                  type="button"
                  aria-label="send tip to artist"
                  disabled={tipDisabled}
                  appearance="primary"
                  onClick={() => dispatch(tipOpenTipModal({ artist: user }))}
                >
                  <div css={styles(theme).view.user.buttons.tip.content.main}>
                    <div css={styles(theme).view.user.buttons.tip.content.icon}>
                      <icons.DollarCircle />
                    </div>
                    <div css={styles(theme).view.user.buttons.tip.content.text}>Tip</div>
                  </div>
                </ButtonMedium>
              </div>
            )}

            {!isCurrentUserProfile &&
              (isCurrentUserAdmin ? (
                <div css={styles(theme).view.user.buttons.block.main}>
                  <ButtonMedium
                    type="button"
                    aria-label="block user"
                    disabled={blockDisabled}
                    appearance="secondary"
                    onClick={() => dispatch(userOpenUserBlockModal({ user }))}
                  >
                    <div css={styles(theme).view.user.buttons.block.content.main}>
                      <div css={styles(theme).view.user.buttons.block.content.icon}>
                        <icons.AlertTriangle />
                      </div>
                      <div css={styles(theme).view.user.buttons.block.content.text}>
                        Block
                      </div>
                    </div>
                  </ButtonMedium>
                </div>
              ) : (
                <div css={styles(theme).view.user.buttons.report.main}>
                  <ButtonMedium
                    type="button"
                    aria-label="report user"
                    disabled={reportDisabled}
                    appearance="secondary"
                    onClick={() => dispatch(reportOpenReportUserModal({ user }))}
                  >
                    <div css={styles(theme).view.user.buttons.report.content.main}>
                      <div css={styles(theme).view.user.buttons.report.content.icon}>
                        <icons.AlertTriangle />
                      </div>
                      <div css={styles(theme).view.user.buttons.report.content.text}>
                        Report
                      </div>
                    </div>
                  </ButtonMedium>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }

  // if user found but user is not artist
  if (userFetched && user) {
    return (
      <div css={styles(theme).view.main}>
        <div css={styles(theme).view.user.main}>
          {/* profile */}
          <div css={styles(theme).view.user.profile.main}>
            <div css={styles(theme).view.user.profile.avatar.main}>
              {userHasAvatar && !avatarLoadError ? (
                <img
                  css={styles(theme).view.user.profile.avatar.image}
                  onError={() => setAvatarLoadError(true)}
                  loading="lazy"
                  width="100px"
                  height="100px"
                  src={source}
                  alt="user avatar"
                />
              ) : (
                <icons.User css={styles(theme).view.user.profile.avatar.icon} />
              )}
            </div>
            <div css={styles(theme).view.user.profile.content.main}>
              <div css={styles(theme).view.user.profile.content.data.main}>
                <div css={styles(theme).view.user.profile.content.data.name}>{name}</div>
                <div css={styles(theme).view.user.profile.content.data.username}>
                  @{username}
                </div>
              </div>
              <div css={styles(theme).view.user.profile.content.genres}>
                {preferredGenres?.map((genre, i) =>
                  preferredGenres.length - 1 === i ? `${genre}` : `${genre}, `,
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // default is progress skeleton
  return (
    <div css={styles(theme).view.main}>
      <SkeletonVertical
        containerWidth="100%"
        sections={[
          {
            name: `user-view-user-profile`,
            width: '100%',
            height: '100px',
            isStatic: true,
            isDivider: false,
          },
          {
            name: `user-view-user-divider`,
            width: '100%',
            height: '24px',
            isStatic: true,
            isDivider: true,
          },
          {
            name: `user-view-user-buttons`,
            width: '100%',
            height: '56px',
            isStatic: false,
            isDivider: false,
          },
        ]}
      />
    </div>
  )
}

UserViewUser.propTypes = {}

import { css, Theme } from '@emotion/react'
import { typography, mq, helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  view: {
    main: css`
      ${helpers.app.screen.width.full};

      overflow: hidden;
      max-width: 800px;
    `,

    media: {
      main: css`
        height: 400px;
        width: 100%;

        margin-bottom: 16px;
      `,
      image: css`
        ${helpers.image};

        border-radius: 4px;
      `,
      video: css`
        ${helpers.image};

        border-radius: 4px;
      `,
      placeholder: css`
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        background-color: ${theme.colors.primary.darker};
        border-radius: 4px;

        & > svg {
          width: 80px;
          height: 80px;
        }
      `,
    },

    message: {
      main: css`
        width: 100%;

        margin-bottom: 16px;
      `,
      text: css`
        font-size: ${typography.p};
        font-weight: bold;

        // overflow-wrap: break-word;
        word-wrap: break-word;
      `,
    },

    footer: {
      main: css`
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        width: 100%;

        ${mq.tablet(css`
          flex-direction: column;
          align-items: stretch;
          flex-wrap: nowrap;
        `)}
      `,
      author: {
        main: css`
          display: flex;

          min-width: 0;
          max-width: 240px;

          margin-right: 12px;

          ${mq.tablet(css`
            max-width: 100%;
            margin-right: 0px;
            margin-bottom: 16px;
          `)}
        `,
        avatar: {
          main: css`
            display: flex;
            align-items: center;
            justify-content: center;

            flex-shrink: 0;

            width: 50px;
            height: 50px;

            background-color: ${theme.colors.primary.darker};
            border-radius: 50%;
            overflow: hidden;

            margin-right: 12px;
          `,
          image: css`
            ${helpers.image};
          `,
          icon: css`
            width: 20px;
            height: 20px;

            & > path {
              fill: ${theme.colors.secondary.normal};
            }
          `,
        },
        info: {
          main: css`
            display: flex;
            flex-direction: column;
            justify-content: center;

            min-width: 0;
          `,
          name: css`
            ${helpers.resetDefaults};
            ${helpers.textOverflowEllipsis};

            cursor: pointer;

            font-size: ${typography.p};
            font-weight: bold;
            color: ${theme.colors.secondary.normal};
            text-align: left;

            transition: color 0.2s;
            &:hover {
              color: ${theme.colors.accent.normal};
            }
          `,
          username: css`
            ${helpers.resetDefaults};
            ${helpers.textOverflowEllipsis};

            cursor: pointer;

            font-size: ${typography.sp};
            color: ${theme.colors.secondary.normal};
            text-align: left;

            transition: color 0.2s;
            &:hover {
              color: ${theme.colors.accent.normal};
            }
          `,
        },
      },
      buttons: {
        main: css`
          display: flex;
          align-items: center;

          margin-right: 12px;

          & > div:not(:last-of-type) {
            margin-right: 12px;
          }

          & > div > button {
            height: 34px;
          }

          ${mq.tablet(css`
            margin-right: 0px;
            margin-bottom: 16px;
          `)}

          ${mq.mobile(css`
            & > div:not(:last-of-type) {
              margin-right: 0px;
              margin-bottom: 12px;
            }

            & > div {
              width: 100%;
            }

            flex-direction: column;
          `)}
        `,

        follow: css``,
        unfollow: css``,
        subscribe: css``,
        unsubscribe: css``,
        tip: css``,
        block: css``,
        report: css``,
      },
      info: {
        main: css`
          display: flex;

          margin-left: auto;

          ${mq.tablet(css`
            margin-left: 0px;
          `)}
        `,
        comments: {
          main: css`
            display: flex;
            align-items: center;

            margin-right: 8px;

            ${mq.tablet(css`
              margin-right: auto;
            `)}
          `,
          buttons: css`
            display: flex;
            align-items: center;
          `,
          button: {
            shared: css`
              ${helpers.resetDefaults};
              // cursor: pointer;

              display: flex;
              align-items: center;
              justify-content: center;

              width: 30px;
              height: 30px;
            `,
            commented: css`
              & > svg {
                width: 20px;
                height: 17px;
                & > path {
                  fill: ${theme.colors.accent.normal};
                }
              }
            `,
            uncommented: css`
              & > svg {
                width: 20px;
                height: 17px;
                & > path {
                  fill: ${theme.colors.secondary.normal};
                }
              }
            `,
          },
          count: css`
            color: ${theme.colors.secondary.normal};
          `,
        },
        likes: {
          main: css`
            display: flex;
            align-items: center;
          `,
          buttons: css`
            display: flex;
            align-items: center;
          `,
          button: {
            shared: css`
              ${helpers.resetDefaults};
              cursor: pointer;

              display: flex;
              align-items: center;
              justify-content: center;

              width: 30px;
              height: 30px;

              & > svg {
                width: 20px;
                height: 18px;
              }
            `,
            like: css`
              & > svg {
                & > path {
                  fill: ${theme.colors.secondary.normal};
                }
              }
            `,
            dislike: css`
              & > svg {
                & > path {
                  fill: ${theme.colors.accent.normal};
                }
              }
            `,
          },
          count: css`
            color: ${theme.colors.secondary.normal};
          `,
        },
      },
    },
  },
})

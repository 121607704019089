import { createAsyncThunk } from '@reduxjs/toolkit'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // auth
  authUpdateProfile,
  // comment
  commentFilterUserComments,
  // player
  playerAudioFilterPlaylistUserTracks,
  // post
  postFilterUserPosts,
  // report
  reportSetReportUserBlocked,
  // track
  trackFilterUserTracks,
  // user
  userSetPublicArtists,
  userSetPopularArtists,
  userSetRecommendedArtists,
  userSetFollowedArtists,
  userSetSubscribedArtists,
  userSetUser,
  userResetUser,
  userFilterUser,
  userCloseUserBlockModal,
} from '@/features'

export const userGetPublicArtists = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('user/userGetPublicArtists', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve public artists
    const { artists, count } = await api.userApi.getPublicArtists()

    // set public artists
    dispatch(
      userSetPublicArtists({
        artists: artists || [],
        count: count || 0,
        offset: 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const userGetPopularArtists = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('user/userGetPopularArtists', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve recommended artists
    const { artists, count } = await api.userApi.getPopularArtists(
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set recommended artists
    dispatch(
      userSetPopularArtists({
        artists: artists || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const userGetRecommendedArtists = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('user/userGetRecommendedArtists', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve recommended artists
    const { artists, count } = await api.userApi.getRecommendedArtists(
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set recommended artists
    dispatch(
      userSetRecommendedArtists({
        artists: artists || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const userGetFollowedArtists = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('user/userGetFollowedArtists', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve recommended artists
    const { artists, count } = await api.userApi.getFollowedArtists(
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set recommended artists
    dispatch(
      userSetFollowedArtists({
        artists: artists || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const userGetSubscribedArtists = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('user/userGetSubscribedArtists', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve recommended artists
    const { artists, count } = await api.userApi.getSubscribedArtists(
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set recommended artists
    dispatch(
      userSetSubscribedArtists({
        artists: artists || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const userGetUser = createAsyncThunk<
  { error: string },
  { userId: string },
  { state: RootState; rejectValue: undefined }
>('user/userGetUser', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve user
    const { user } = await api.userApi.getUser(inp.userId)

    // set user
    dispatch(
      userSetUser({
        user: user || {},
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const userBlock = createAsyncThunk<
  { error: string },
  { id: string },
  { state: RootState; rejectValue: undefined }
>('user/userBlock', async ({ id }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // block user
    await api.userApi.blockUser({ userId: id })

    // reset user page state
    dispatch(userResetUser())

    // filter blocked user from users
    dispatch(userFilterUser({ id }))

    // filter blocked user tracks from tracks
    dispatch(trackFilterUserTracks({ userId: id }))

    // filter blocked user tracks from audio player
    dispatch(playerAudioFilterPlaylistUserTracks({ userId: id }))

    // filter blocked user posts from posts
    dispatch(postFilterUserPosts({ userId: id }))

    // filter blocked user comments from comments
    dispatch(commentFilterUserComments({ userId: id }))

    // set user as blocked in the report state
    dispatch(reportSetReportUserBlocked())

    // close user block modal
    dispatch(userCloseUserBlockModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully blocked user!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to block user: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const userProfileUpdate = createAsyncThunk<
  { error: string },
  { avatar: File | null; name: string; preferredGenres: string[] },
  { state: RootState; rejectValue: undefined }
>('user/userProfileUpdate', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // if avatar provided
    if (inp.avatar) {
      // create form data
      const formData = new FormData()

      // append avatar
      formData.append('file', inp.avatar)

      // update user avatar
      const { user } = await api.userApi.updateUserProfileAvatar({ body: formData })

      // update profile
      dispatch(authUpdateProfile(user || {}))
    }

    // if name or preferred genres provided
    if (inp.name || inp.preferredGenres) {
      // update user name and preferred genres
      const { user } = await api.userApi.updateUserProfile({
        name: inp.name,
        preferredGenres: inp.preferredGenres,
      })

      // update profile
      dispatch(authUpdateProfile(user || {}))
    }

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully updated user profile!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to update user profile: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const userProfileDelete = createAsyncThunk<
  { error: string },
  { avatar: File | null; name: string; preferredGenres: string[] },
  { state: RootState; rejectValue: undefined }
>('user/userProfileDelete', async (inp, { dispatch }) => {
  // get api service
  // const api = apiGetService()

  try {
    // TODO: implement
    // are you sure you want to delete your profile?
    // on confirm click
    // 1. send email with termination code
    // 2. update modal to render input field
    // on delete click
    // 1. delete user profile
    // 2. log user out

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully deleted user profile!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to delete user profile: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

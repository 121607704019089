import useSWR from 'swr'

// libs
import { apiGetService, apiHandleError } from '@/lib'

// types
import { SWRKeys, SWRFetcherData } from './types'

const getTip = async ({ data: { tipId } }: SWRFetcherData<{ tipId: string }>) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve tip
    const { tip } = await api.tipApi.getTip(tipId)

    // return payload
    return { tip, error: '' }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return payload
    return { tip: undefined, error: message }
  }
}

const fetcher = (args: SWRFetcherData<{ tipId: string }>) => getTip(args)

export const useTip = (tipId: string) => {
  const shouldFetch = !!tipId

  const { data, isLoading, mutate } = useSWR(
    shouldFetch ? { key: SWRKeys.UseTip, data: { tipId } } : null,
    fetcher,
    { revalidateOnFocus: false },
  )

  return {
    tip: data?.tip,
    isLoading,
    isError: data?.error,
    mutate,
  }
}

import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  form: {
    main: css``,
    content: {
      main: css``,
      product: css`
        display: flex;
        justify-content: center;

        margin-bottom: 24px;
      `,
      description: {
        main: css`
          font-size: ${typography.h6};
          text-align: center;
          color: ${theme.colors.secondary.dark};

          margin-bottom: 24px;
        `,
        text: css``,
        accent: css`
          font-weight: bold;
          color: ${theme.colors.accent.normal};
        `,
      },
      submit: css``,
    },
  },
})

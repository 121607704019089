import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// hooks
import { useAppDispatch } from '@/hooks'
// features
import { authSignout } from '@/features'

// components
import { Avatar } from './components'
// styles
import { styles } from './styles'

interface UserMenuProps {}

export const UserMenu: FC<UserMenuProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  return (
    <div css={styles(theme).menu.main}>
      <div css={styles(theme).menu.avatar}>
        <Avatar />
      </div>
      <div css={styles(theme).menu.options.main}>
        <div css={styles(theme).menu.options.divider} />
        <div css={styles(theme).menu.options.list.main}>
          <ul css={styles(theme).menu.options.list.ul}>
            <li css={styles(theme).menu.options.list.li}>
              <button
                css={[
                  styles(theme).menu.options.list.button.shared,
                  styles(theme).menu.options.list.button.profile,
                ]}
                onClick={() => navigate(Path.Profile)}
                type="button"
                aria-label="navigate to profile page"
              >
                Profile
              </button>
            </li>
            <li css={styles(theme).menu.options.list.li}>
              <button
                css={[
                  styles(theme).menu.options.list.button.shared,
                  styles(theme).menu.options.list.button.logout,
                ]}
                onClick={() => dispatch(authSignout())}
                type="button"
                aria-label="log out"
              >
                Log out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { postSelectPostBlockModalOpen, postClosePostBlockModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { PostBlockCard } from './components'
// styles
import { styles } from './styles'

type PostBlockModalProps = {}

export const PostBlockModal: FC<PostBlockModalProps> = () => {
  const dispatch = useAppDispatch()
  const postBlockModalOpen = useAppSelector(postSelectPostBlockModalOpen)

  return (
    <div css={styles().modal.main}>
      {postBlockModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(postClosePostBlockModal())}
        >
          <div css={styles().modal.content}>
            <PostBlockCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

PostBlockModal.propTypes = {}

// updating this values will also require to update regex
// in @/utils/validateUsername
export const minUserUsernameLength = 3
export const maxUserUsernameLength = 16

// updating this values will also require to update regex
// in @/utils/validateName
export const minUserNameLength = 3
export const maxUserNameLength = 100

export const minUserPasswordLength = 8

// 32 * 1024 * 1024 === 32 << 20 === 32 MiB
export const maxUserAvatarFileSize = 32 * 1024 * 1024

import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityPostView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useUserPublic } from '@/hooks'
// icons
import { icons } from '@/assets'
// utils
import { numbersFormatter } from '@/utils'

// styles
import { styles } from './styles'

type PostCardLockedProps = {
  post: EntityPostView
  onClick: MouseEventHandler<HTMLButtonElement>
  onAuthorNameClick: MouseEventHandler<HTMLButtonElement>
  onAuthorUsernameClick: MouseEventHandler<HTMLButtonElement>
}

export const PostCardLocked: FC<PostCardLockedProps> = ({
  post,
  onClick,
  onAuthorNameClick,
  onAuthorUsernameClick,
}) => {
  const theme = useTheme()
  const [headerAvatarError, setHeaderAvatarError] = useState(false)

  const { userId, likesCount } = post

  // post author
  const { user: postAuthor, isLoading: isPostAuthorLoading } = useUserPublic(userId || '')
  const {
    name: authorName,
    username: authorUsername,
    avatarId: authorAvatarId,
  } = postAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'
  const userUsernamePlaceholder = 'unknown'

  const authorHasAvatar = !!authorAvatarId
  const avatarSource = useFile(authorAvatarId || '')

  return (
    <div css={styles(theme).card.main}>
      {/* lock */}
      <div css={styles(theme).card.lock.main}>
        <icons.Lock css={styles(theme).card.lock.icon} />
      </div>

      {/* overlay */}
      <div css={styles(theme).card.overlay.main}>
        <button
          css={styles(theme).card.overlay.button}
          onClick={(e) => onClick(e)}
          type="button"
          aria-label="unlock track"
        >
          <icons.Unlock />
        </button>
      </div>

      {/* header */}
      <div css={styles(theme).card.header.main}>
        <div css={styles(theme).card.header.content.main}>
          <div css={styles(theme).card.header.content.avatar.main}>
            {authorHasAvatar && !headerAvatarError ? (
              <img
                css={styles(theme).card.header.content.avatar.image}
                onError={() => setHeaderAvatarError(true)}
                loading="lazy"
                width="30px"
                height="30px"
                src={avatarSource}
                alt="post author avatar"
              />
            ) : (
              <icons.User css={styles(theme).card.header.content.avatar.icon} />
            )}
          </div>
          <div css={styles(theme).card.header.content.author.main}>
            <button
              css={styles(theme).card.header.content.author.name}
              onClick={(e) => onAuthorNameClick(e)}
              type="button"
              aria-label="explore track author"
            >
              {isPostAuthorLoading
                ? userLoadingPlaceholder
                : authorName || userNamePlaceholder}
            </button>
            <button
              css={styles(theme).card.header.content.author.username}
              onClick={(e) => onAuthorUsernameClick(e)}
              type="button"
              aria-label="explore track author"
            >
              {isPostAuthorLoading
                ? userLoadingPlaceholder
                : `@${authorUsername || userUsernamePlaceholder}`}
            </button>
          </div>
          <div css={styles(theme).card.header.content.likes.main}>
            <div css={styles(theme).card.header.content.likes.icon}>
              <icons.HeartFilled />
            </div>
            <div css={styles(theme).card.header.content.likes.likes}>
              {numbersFormatter(likesCount || 0)}
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div css={styles(theme).card.footer} aria-hidden="true" />
    </div>
  )
}

PostCardLocked.propTypes = {}

import { css } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = () => ({
  onboarding: {
    main: css`
      display: flex;

      width: 100%;
      height: 100%;

      position: fixed;
      left: 0;
      right: 0;
    `,

    stage: css`
      ${helpers.hideScrollbar};
      ${helpers.app.padding.full};

      display: flex;
      flex-direction: column;

      width: 100%;
      height: 100%;

      overflow: hidden;
      overflow-y: scroll;

      & > div:nth-of-type(2) {
        width: 100%;

        max-width: 760px;
        margin: 0 auto;
      }
    `,

    role: css`
      width: 100%;
      height: 100%;
    `,
  },
})

import { FC, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

// hooks
import { useAppSelector } from '@/hooks'
// config
import { Path } from '@/config'
// features
import {
  NavigationApp,
  NavigationAppMobile,
  NavigationLibrary,
  PlayerAudio,
  PlayerAudioPlaylist,
  authSelectProfile,
} from '@/features'
// components
import { AppHeader } from '@/components'

// styles
import { styles } from './styles'

type AppLayoutProps = {
  children: ReactNode
}

export const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const { pathname } = useLocation()
  const profile = useAppSelector(authSelectProfile)

  const isLibraryPage = pathname === Path.Library
  const isUserLoggedIn = !!profile

  return (
    <div css={styles().layout.main}>
      {/* navigation */}
      <div css={styles().layout.navigation}>
        <NavigationApp />
        <NavigationAppMobile />
      </div>

      {/* header */}
      <div css={styles().layout.content.main}>
        {/* app header */}
        <div css={styles().layout.content.header.app}>
          <AppHeader />
        </div>

        {/* library navigation header */}
        {isLibraryPage && (
          <div css={styles().layout.content.header.library}>
            <NavigationLibrary />
          </div>
        )}

        {/* content panel */}
        <div css={styles().layout.content.panel.main}>
          <main css={styles().layout.content.panel.view}>{children}</main>
        </div>

        {/* audio player */}
        {isUserLoggedIn && (
          <div css={styles().layout.content.player}>
            <PlayerAudio />
          </div>
        )}

        {/* audio playlist */}
        {isUserLoggedIn && (
          <div css={styles().layout.content.playlist}>
            <PlayerAudioPlaylist />
          </div>
        )}
      </div>
    </div>
  )
}

AppLayout.propTypes = {}

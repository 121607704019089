import { FC, InputHTMLAttributes } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface ProgressProps extends InputHTMLAttributes<HTMLInputElement> {
  duration: number
  progress: number
  buffered: number
}

export const Progress: FC<ProgressProps> = ({
  duration,
  progress,
  buffered,
  ...attributes
}) => {
  const theme = useTheme()

  const progressWidth = Number.isNaN(progress / duration) ? 0 : progress / duration
  const progressWidthPercent = (progressWidth + 0.0018) * 100
  // const progressWidthPercent = Math.round(progressWidth * 100)
  // const bufferedWidth = Number.isNaN(buffered / duration) ? 0 : buffered / duration
  // const bufferedWidthPercent = Math.round(bufferedWidth * 100)

  const label = 'Audio Player Progress'
  const name = 'audio-player-progress'

  return (
    <div css={styles(theme).progress.main}>
      <label css={styles(theme).progress.label} htmlFor={name}>
        {label}
      </label>
      <input
        css={styles(theme).progress.input(progressWidthPercent)}
        id={name}
        name={name}
        type="range"
        min={0}
        max={duration}
        value={progress}
        {...attributes}
      />
    </div>
  )
}

Progress.propTypes = {}

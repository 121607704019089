import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TrackPreviewProps = {
  fileName: string
  label: string
  description: string
  error: boolean
  errorMessage: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const TrackPreview: FC<TrackPreviewProps> = ({
  fileName,
  label,
  description,
  error,
  errorMessage,
  onClick,
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).preview.main}>
      {/* preview */}
      <button
        css={[
          styles(theme).preview.button.shared,

          fileName
            ? styles(theme).preview.button.filled
            : styles(theme).preview.button.empty,

          error && styles(theme).preview.button.errored,
        ]}
        onClick={(e) => onClick(e)}
        type="button"
        aria-label={label}
      >
        <div css={styles(theme).preview.button.content.main}>
          <div css={styles(theme).preview.button.content.name}>
            {fileName || description}
          </div>
          <div
            css={[
              styles(theme).preview.button.content.icon.shared,

              fileName
                ? styles(theme).preview.button.content.icon.empty
                : styles(theme).preview.button.content.icon.filled,

              error && styles(theme).preview.button.errored,
            ]}
          >
            <icons.Upload />
          </div>
        </div>
      </button>

      {/* error message */}
      <div
        css={[
          styles(theme).preview.error.shared,
          errorMessage
            ? styles(theme).preview.error.visible
            : styles(theme).preview.error.hidden,
        ]}
      >
        <span
          css={[
            styles(theme).preview.error.text.shared,
            errorMessage
              ? styles(theme).preview.error.text.visible
              : styles(theme).preview.error.text.hidden,
          ]}
        >
          {errorMessage}
        </span>
      </div>
    </div>
  )
}

TrackPreview.propTypes = {}

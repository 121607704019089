import { css, Theme } from '@emotion/react'
import { mq } from '@/styles'

export const styles = (theme: Theme) => ({
  form: {
    main: css``,
    genres: {
      main: css`
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 50px;
      `,

      rows: {
        main: css`
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          margin-bottom: 32px;

          max-width: 572px;

          & > div {
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 16px;
          }
        `,
      },

      selected: {
        main: css`
          color: ${theme.colors.secondary.normal};
          text-align: center;
        `,
        num: css`
          font-weight: bold;
        `,
      },
    },

    content: {
      main: css`
        display: flex;
        justify-content: center;
      `,
      submit: css`
        ${mq.mobile(css`
          width: 100%;
        `)}

        & > button {
          width: 380px;

          ${mq.mobile(css`
            width: 100%;
          `)}
        }
      `,
    },
  },
})

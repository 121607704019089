import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type CoverProps = {
  source: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const Cover: FC<CoverProps> = ({ source, onClick }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).cover.main}>
      <button
        css={styles(theme).cover.button}
        onClick={(e) => onClick(e)}
        type="button"
        aria-label="change track cover"
      >
        <div css={styles(theme).cover.image.main}>
          {source ? (
            <img
              css={styles(theme).cover.image.content}
              loading="lazy"
              width="136px"
              height="136px"
              src={source}
              alt="track album cover"
            />
          ) : (
            <icons.ImageDownload css={styles(theme).cover.image.icon} />
          )}
        </div>
      </button>
    </div>
  )
}

Cover.propTypes = {}

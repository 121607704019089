import { Suspense } from 'react'
import { Navigate, Outlet, Routes, Route } from 'react-router-dom'

// config
import { Path, Params } from '@/config'
// components
import { AppLayout, SuspenseContentFallback } from '@/components'
// utils
import { importsLazy } from '@/utils'
// pages
const { ChartsPage } = importsLazy(() => import('@/pages'), 'ChartsPage')
const { FeedPage } = importsLazy(() => import('@/pages'), 'FeedPage')
const { LibraryPage } = importsLazy(() => import('@/pages'), 'LibraryPage')
const { NotificationsPage } = importsLazy(() => import('@/pages'), 'NotificationsPage')
const { NotificationsNotificationPage } = importsLazy(
  () => import('@/pages'),
  'NotificationsNotificationPage',
)
const { PostPage } = importsLazy(() => import('@/pages'), 'PostPage')
const { ProfilePage } = importsLazy(() => import('@/pages'), 'ProfilePage')
const { UserPage } = importsLazy(() => import('@/pages'), 'UserPage')

const App = () => (
  <AppLayout>
    <Suspense fallback={<SuspenseContentFallback position="fit" />}>
      <Outlet />
    </Suspense>
  </AppLayout>
)

export const fanRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: Path.Library, element: <LibraryPage /> },
      { path: Path.Charts, element: <ChartsPage /> },
      { path: Path.Profile, element: <ProfilePage /> },
      {
        path: `${Path.Notifications}/*`,
        element: (
          <Routes>
            <Route path="" element={<NotificationsPage />} />
            <Route
              path={`${Path.Notification}/*`}
              element={
                <Routes>
                  <Route
                    path={`${Params.NotificationID}`}
                    element={<NotificationsNotificationPage />}
                  />
                  <Route path="*" element={<Navigate to="." />} />
                </Routes>
              }
            />
            <Route path="*" element={<Navigate to="." />} />
          </Routes>
        ),
      },
      {
        path: `${Path.User}/*`,
        element: (
          <Routes>
            <Route path={`${Params.UserID}`} element={<UserPage />} />
            <Route path="*" element={<Navigate to="." />} />
          </Routes>
        ),
      },
      {
        path: `${Path.Post}/*`,
        element: (
          <Routes>
            <Route path={`${Params.PostID}`} element={<PostPage />} />
            <Route path="*" element={<Navigate to="." />} />
          </Routes>
        ),
      },
      { path: Path.Feed, element: <FeedPage /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
]

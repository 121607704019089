import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type BadgeProps = {
  name: string
  disabled: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const Badge: FC<BadgeProps> = ({ name, disabled, onClick }) => {
  const theme = useTheme()
  return (
    <div css={styles(theme).badge.main}>
      <button
        css={[
          styles(theme).badge.button.shared,
          disabled
            ? styles(theme).badge.button.disabled
            : styles(theme).badge.button.enabled,
        ]}
        onClick={(e) => onClick(e)}
        disabled={disabled}
        type="button"
        aria-label="log out"
      >
        {name}
      </button>
    </div>
  )
}

Badge.propTypes = {}

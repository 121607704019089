import { FC, InputHTMLAttributes } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  icon?: any
}

export const SearchInput: FC<SearchInputProps> = ({
  name,
  label,
  icon: Icon = undefined,
  ...attributes
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).input.main}>
      <label css={styles(theme).input.label} htmlFor={name}>
        {label}
      </label>
      <input css={styles(theme).input.data} id={name} {...attributes} />
      {Icon && <Icon css={styles(theme).input.icon} />}
    </div>
  )
}

import { FC, useEffect } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  CustomerPaymentMethodListProfile,
  customerSelectCustomerPaymentMethodSelected,
  customerSelectCustomerDeleteCustomerPaymentMethodLoading,
  customerSelectCustomerCreateCustomerPaymentMethodSetupLoading,
  customerSelectCustomerSetCustomerPaymentMethodDefaultLoading,
  customerGetCustomerPaymentMethods,
  customerDeleteCustomerPaymentMethod,
  customerCreateCustomerPaymentMethodSetup,
  customerSetCustomerPaymentMethodDefault,
} from '@/features'
// components
import { ButtonMedium, Loader } from '@/components'

// components
import { Title } from './components'
// styles
import { styles } from './styles'

type CustomerViewProfileProps = {}

export const CustomerViewProfile: FC<CustomerViewProfileProps> = () => {
  const dispatch = useAppDispatch()

  const customerPaymentMethodSelected = useAppSelector(
    customerSelectCustomerPaymentMethodSelected,
  )
  const customerDeleteCustomerPaymentMethodLoading = useAppSelector(
    customerSelectCustomerDeleteCustomerPaymentMethodLoading,
  )
  const customerCreateCustomerPaymentMethodSetupLoading = useAppSelector(
    customerSelectCustomerCreateCustomerPaymentMethodSetupLoading,
  )
  const customerSetCustomerPaymentMethodDefaultLoading = useAppSelector(
    customerSelectCustomerSetCustomerPaymentMethodDefaultLoading,
  )

  const isPaymentMethodSelected = customerPaymentMethodSelected !== undefined
  const isSelectedPaymentMethodDefault = customerPaymentMethodSelected?.isDefault === true

  const deletePaymentMethodDisabled =
    !isPaymentMethodSelected || customerDeleteCustomerPaymentMethodLoading
  const createPaymentMethodDisabled = customerCreateCustomerPaymentMethodSetupLoading
  const setDefaultPaymentMethodDisabled =
    !isPaymentMethodSelected ||
    isSelectedPaymentMethodDefault ||
    customerSetCustomerPaymentMethodDefaultLoading

  useEffect(() => {
    // get payment methods
    dispatch(customerGetCustomerPaymentMethods({ offset: 0, limit: 30 }))
  }, [dispatch])

  return (
    <div css={styles().card.main}>
      <div css={styles().card.content.main}>
        <div css={styles().card.content.title}>
          <Title text="Payment methods" />
        </div>
        <div css={styles().card.content.methods.main}>
          <CustomerPaymentMethodListProfile />
        </div>
        <div css={styles().card.content.buttons.main}>
          {isPaymentMethodSelected ? (
            <div css={styles().card.content.buttons.delete}>
              <ButtonMedium
                type="button"
                aria-label="delete payment method"
                disabled={deletePaymentMethodDisabled}
                appearance="secondary"
                onClick={() => {
                  dispatch(
                    customerDeleteCustomerPaymentMethod({
                      customerPaymentMethod: customerPaymentMethodSelected || {},
                    }),
                  )
                }}
              >
                {customerDeleteCustomerPaymentMethodLoading ? (
                  <Loader width={56} height={20} appearance="secondary" />
                ) : (
                  'Delete'
                )}
              </ButtonMedium>
            </div>
          ) : (
            <div css={styles().card.content.buttons.create}>
              <ButtonMedium
                type="button"
                aria-label="create payment method"
                disabled={createPaymentMethodDisabled}
                appearance="primary"
                onClick={() => dispatch(customerCreateCustomerPaymentMethodSetup())}
              >
                {customerCreateCustomerPaymentMethodSetupLoading ? (
                  <Loader width={56} height={20} appearance="secondary" />
                ) : (
                  'Create'
                )}
              </ButtonMedium>
            </div>
          )}

          <div css={styles().card.content.buttons.set}>
            <ButtonMedium
              type="button"
              aria-label="set payment method as default"
              disabled={setDefaultPaymentMethodDisabled}
              appearance="primary"
              onClick={() => {
                dispatch(
                  customerSetCustomerPaymentMethodDefault({
                    customerPaymentMethod: customerPaymentMethodSelected || {},
                  }),
                )
              }}
            >
              {customerSetCustomerPaymentMethodDefaultLoading ? (
                <Loader width={56} height={20} appearance="secondary" />
              ) : (
                'Set as Default'
              )}
            </ButtonMedium>
          </div>
        </div>
      </div>
    </div>
  )
}

CustomerViewProfile.propTypes = {}

import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import {
  EntityPostView,
  EntityPostMediaType,
  EntityTrackVisibility,
} from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useFile, useUserPublic, useTracks } from '@/hooks'
// features
import { subscriptionSelectUserSubscriptions, unlockSelectTrackUnlocks } from '@/features'
// icons
import { icons } from '@/assets'
// utils
import { numbersFormatter, stringsCutter } from '@/utils'

// components
import { Track, TrackLocked } from './components'
// styles
import { styles } from './styles'

type PostCardProps = {
  post: EntityPostView
  currentUserId: string
  onClick: MouseEventHandler<HTMLDivElement>
  onAuthorNameClick: MouseEventHandler<HTMLButtonElement>
  onAuthorUsernameClick: MouseEventHandler<HTMLButtonElement>
}

export const PostCard: FC<PostCardProps> = ({
  post,
  currentUserId,
  onClick,
  onAuthorNameClick,
  onAuthorUsernameClick,
}) => {
  const theme = useTheme()
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const trackUnlocks = useAppSelector(unlockSelectTrackUnlocks)

  const [mediaLoadError, setMediaLoadError] = useState(false)
  const [headerAvatarError, setHeaderAvatarError] = useState(false)

  const { id: postId, userId, trackIds, message, mediaType, mediaId, likesCount } = post

  // post author
  const { user: postAuthor, isLoading: isPostAuthorLoading } = useUserPublic(userId || '')
  const {
    name: authorName,
    username: authorUsername,
    avatarId: authorAvatarId,
  } = postAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'
  const userUsernamePlaceholder = 'unknown'

  // post tracks
  const { tracks: postTracks, isLoading: isPostTracksLoading } = useTracks(trackIds || [])
  const tracksLoadingPlaceholder = 'Loading...'

  const authorHasAvatar = !!authorAvatarId
  const avatarSource = useFile(authorAvatarId || '')

  const postHasMedia = !!mediaId
  const postMedia = useFile(mediaId || '')

  const maxPostDescriptionLength = 84

  return (
    <div css={styles(theme).card.main}>
      {/* header */}
      <div css={styles(theme).card.header.main}>
        <div css={styles(theme).card.header.content.main}>
          <div css={styles(theme).card.header.content.avatar.main}>
            {authorHasAvatar && !headerAvatarError ? (
              <img
                css={styles(theme).card.header.content.avatar.image}
                onError={() => setHeaderAvatarError(true)}
                loading="lazy"
                width="30px"
                height="30px"
                src={avatarSource}
                alt="post author avatar"
              />
            ) : (
              <icons.User css={styles(theme).card.header.content.avatar.icon} />
            )}
          </div>
          <div css={styles(theme).card.header.content.author.main}>
            <button
              css={styles(theme).card.header.content.author.name}
              onClick={(e) => onAuthorNameClick(e)}
              type="button"
              aria-label="explore track author"
            >
              {isPostAuthorLoading
                ? userLoadingPlaceholder
                : authorName || userNamePlaceholder}
            </button>
            <button
              css={styles(theme).card.header.content.author.username}
              onClick={(e) => onAuthorUsernameClick(e)}
              type="button"
              aria-label="explore track author"
            >
              {isPostAuthorLoading
                ? userLoadingPlaceholder
                : `@${authorUsername || userUsernamePlaceholder}`}
            </button>
          </div>
          <div css={styles(theme).card.header.content.likes.main}>
            <div css={styles(theme).card.header.content.likes.icon}>
              <icons.HeartFilled />
            </div>
            <div css={styles(theme).card.header.content.likes.likes}>
              {numbersFormatter(likesCount || 0)}
            </div>
          </div>
        </div>
      </div>

      {/* media */}
      <div
        css={styles(theme).card.media.main}
        onClick={(e) => onClick(e)}
        aria-hidden="true"
      >
        {postHasMedia && !mediaLoadError ? (
          <>
            {mediaType && mediaType === EntityPostMediaType.Image && (
              <img
                css={styles(theme).card.media.image}
                onError={() => setMediaLoadError(true)}
                loading="lazy"
                width="180px"
                height="150px"
                src={postMedia}
                alt="post media"
              />
            )}
            {mediaType && mediaType === EntityPostMediaType.Video && (
              <>
                <video
                  css={styles(theme).card.media.video.main}
                  onError={() => setMediaLoadError(true)}
                  width="180"
                  muted
                  preload="metadata"
                  // autoPlay
                  // loop
                >
                  <source src={postMedia} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div css={styles(theme).card.media.video.play}>
                  <icons.PlayCircle />
                </div>
              </>
            )}
          </>
        ) : (
          <div css={styles(theme).card.media.placeholder}>
            <icons.Ryddm />
          </div>
        )}
      </div>

      {/* content */}
      <div
        css={styles(theme).card.content.main}
        onClick={(e) => onClick(e)}
        aria-hidden="true"
      >
        <div css={styles(theme).card.content.description}>
          {stringsCutter(maxPostDescriptionLength, message || '')}
        </div>
        <div css={styles(theme).card.content.tracks}>
          {isPostTracksLoading
            ? tracksLoadingPlaceholder
            : postTracks &&
              postTracks.map((track) => {
                const { id: trackId, userId: trackAuthorUserId, visibility } = track

                const isContentLocked = visibility === EntityTrackVisibility.Subscribers
                const isCurrentUserContentAuthor = currentUserId === trackAuthorUserId
                const isCurrentUserUnlockedContent = trackUnlocks[trackId || '']
                const isCurrentUserSubscribedToContentAuthor =
                  userSubscriptions[trackAuthorUserId || '']

                const renderLocked =
                  isContentLocked &&
                  !isCurrentUserContentAuthor &&
                  !isCurrentUserUnlockedContent &&
                  !isCurrentUserSubscribedToContentAuthor

                if (renderLocked) {
                  return (
                    <TrackLocked key={`post-${postId}-track-${track.id}`} track={track} />
                  )
                }

                return <Track key={`post-${postId}-track-${track.id}`} track={track} />
              })}
        </div>
      </div>

      {/* footer */}
      <div
        css={styles(theme).card.footer}
        onClick={(e) => onClick(e)}
        aria-hidden="true"
      />
    </div>
  )
}

PostCard.propTypes = {}

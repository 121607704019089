import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  TrackTileProduct,
  authSelectProfile,
  unlockSelectUnlockCreateTrackUnlockLoading,
  unlockSelectUnlockTrackModalTrack,
  unlockCreateTrackUnlock,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type UnlockTrackFormProps = {}

export const UnlockTrackForm: FC<UnlockTrackFormProps> = () => {
  const dispatch = useAppDispatch()
  const unlockCreateTrackUnlockLoading = useAppSelector(
    unlockSelectUnlockCreateTrackUnlockLoading,
  )
  const profile = useAppSelector(authSelectProfile)
  const modalTrack = useAppSelector(unlockSelectUnlockTrackModalTrack)

  const { id: profileUserId } = profile ?? {}
  const { unlockPrice, userId: artistUserId } = modalTrack ?? {}

  const isCurrentUserContentUnlock = profileUserId === artistUserId

  const submitDisabled = isCurrentUserContentUnlock || unlockCreateTrackUnlockLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(unlockCreateTrackUnlock())
        }}
      >
        <div css={styles().form.content.product}>
          <TrackTileProduct track={modalTrack || {}} />
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="unlock track"
            disabled={submitDisabled}
            appearance="primary"
          >
            {unlockCreateTrackUnlockLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Unlock for $${unlockPrice && unlockPrice / 100}`
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

UnlockTrackForm.propTypes = {}

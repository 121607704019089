import { FC, InputHTMLAttributes } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface InputAmountProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
}

export const InputAmount: FC<InputAmountProps> = ({ name, label, ...attributes }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).input.main}>
      {/* label */}
      <label css={styles(theme).input.label} htmlFor={name}>
        {label}
      </label>

      {/* range */}
      <div css={styles(theme).input.range.main}>
        <div
          css={
            attributes.value === attributes.min
              ? styles(theme).input.range.min.active
              : styles(theme).input.range.min.inactive
          }
        >
          ${attributes.min}
        </div>
        <div
          css={
            attributes.value === attributes.max
              ? styles(theme).input.range.min.active
              : styles(theme).input.range.min.inactive
          }
        >
          ${attributes.max}
        </div>
      </div>

      {/* input */}
      <div css={styles(theme).input.data.main}>
        <input css={styles(theme).input.data.input} id={name} {...attributes} />
      </div>
    </div>
  )
}

InputAmount.propTypes = {}

import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/stores'
import { ThemeName } from '@/styles'

export interface ThemeState {
  currentTheme: ThemeName
}

const initialState: ThemeState = {
  currentTheme: ThemeName.Dark,
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    themeToggle: (state) => ({
      ...state,
      currentTheme:
        state.currentTheme === ThemeName.Dark ? ThemeName.Light : ThemeName.Dark,
    }),
    themeSetDark: (state) => ({
      ...state,
      currentTheme: ThemeName.Dark,
    }),
    themeSetLight: (state) => ({
      ...state,
      currentTheme: ThemeName.Light,
    }),
  },
})

export const { themeToggle, themeSetDark, themeSetLight } = themeSlice.actions

export const themeSelectCurrentTheme = (state: RootState) => state.theme.currentTheme

export const themeReducer = themeSlice.reducer

// export default themeSlice.reducer

import { css, Theme } from '@emotion/react'
import { typography, helpers, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  view: {
    main: css`
      ${helpers.app.padding.sides};

      margin-bottom: 50px;
    `,

    user: {
      main: css`
        ${helpers.app.screen.width.full};
      `,

      profile: {
        main: css`
          display: flex;

          margin-bottom: 24px;

          ${mq.mobile(css`
            flex-direction: column;
          `)}
        `,
        avatar: {
          main: css`
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            flex-shrink: 0;

            width: 100px;
            height: 100px;

            background-color: ${theme.colors.primary.darker};
            border-radius: 50%;
            overflow: hidden;

            margin-right: 16px;

            ${mq.mobile(css`
              margin-right: 0px;
              margin-bottom: 16px;
            `)}
          `,
          image: css`
            ${helpers.image};
          `,
          icon: css`
            width: 36px;
            height: 36px;

            & > path {
              fill: ${theme.colors.secondary.normal};
            }
          `,
        },
        content: {
          main: css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            min-width: 0;
          `,
          data: {
            main: css`
              display: flex;
              flex-direction: column;

              min-width: 0;

              ${mq.mobile(css`
                text-align: center;
                margin-bottom: 10px;
              `)}
            `,
            name: css`
              ${helpers.textOverflowEllipsis};

              font-size: ${typography.h6};
              font-weight: bold;
            `,
            username: css`
              ${helpers.textOverflowEllipsis};

              font-size: ${typography.p};
            `,
          },
          stats: css`
            display: flex;
            text-align: center;

            & > div:not(:last-of-type) {
              margin-right: 16px;
            }

            ${mq.mobile(css`
              justify-content: center;
            `)}
          `,
        },
      },

      buttons: {
        main: css`
          display: flex;
          flex-wrap: wrap;

          & > div:nth-of-type(1) {
            margin-right: 20px;
            margin-bottom: 12px;
          }

          ${mq.tablet(css`
            flex-wrap: nowrap;
          `)}

          ${mq.mobile(css`
            flex-direction: column;
            flex-wrap: nowrap;

            & > div:nth-of-type(1) {
              margin-right: 0px;
              margin-bottom: 12px;
            }
          `)}
        `,
        create: {
          main: css`
            width: 206px;

            ${mq.tablet(css`
              width: 50%;
            `)}

            ${mq.mobile(css`
              width: 100%;
            `)}
          `,
          content: {
            main: css`
              display: flex;
              align-items: center;
            `,
            icon: css`
              flex-shrink: 0;

              width: 22px;
              height: 22px;
              margin-right: 12px;

              & > svg {
                & > path {
                  fill: ${theme.colors.secondary.normal};
                }
              }
            `,
            text: css`
              ${mq.mobile(css`
                ${helpers.textOverflowEllipsis};
              `)}
            `,
          },
        },
        upload: {
          main: css`
            width: 206px;

            ${mq.tablet(css`
              width: 50%;
            `)}

            ${mq.mobile(css`
              width: 100%;
            `)}
          `,
          content: {
            main: css`
              display: flex;
              align-items: center;
            `,
            icon: css`
              flex-shrink: 0;

              width: 22px;
              height: 22px;
              margin-right: 12px;

              & > svg {
                & > path {
                  fill: ${theme.colors.secondary.normal};
                }
              }
            `,
            text: css`
              ${mq.mobile(css`
                ${helpers.textOverflowEllipsis};
              `)}
            `,
          },
        },
      },
    },
  },
})

import { css, Theme } from '@emotion/react'
import { helpers, fonts, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  button: {
    main: css`
      ${helpers.resetDefaults};

      display: flex;
      align-items: center;

      font-family: ${fonts.primary};
      font-size: ${typography.h6};
      font-weight: bold;
      white-space: nowrap;

      cursor: pointer;
      overflow: hidden;

      transition: color 0.2s;
      min-width: 0;

      height: 44px;
      width: 100%;
    `,
    active: css`
      color: ${theme.colors.accent.normal};
      border-bottom: 2px solid ${theme.colors.accent.normal};

      &:hover {
        color: ${theme.colors.accent.normal};
      }
    `,
    inactive: css`
      color: ${theme.colors.secondary.dark};
      border-bottom: 2px solid transparent;

      &:hover {
        color: ${theme.colors.accent.normal};
      }
    `,

    content: {
      main: css`
        display: flex;
        justify-content: center;

        width: 100%;
        min-width: 0;
      `,
      children: css`
        ${helpers.textOverflowEllipsis};
      `,
    },
  },
})

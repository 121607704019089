/**
 * Calculates difference in seconds between provided dates.
 *
 * Takes past and current data as params, returns difference in seconds.
 *
 * @param {number} pastDate - Date in milliseconds elapsed since the epoch.
 * @param {number} currentDate - Date in milliseconds elapsed since the epoch (default is current date).
 * @returns {number} - Difference between dates in seconds.
 *
 * @example
 * // Returns difference between two dates in seconds.
 * const lastPasswordResetSec = dateGetDifferenceSec(lastIssuedDate);
 */
export const dateGetDifferenceSec = (pastDate: number, currentDate = Date.now()) =>
  Math.floor((currentDate - pastDate) / 1000)

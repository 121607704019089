import { FC, useState } from 'react'

// config
import { maxCommentMessageLength, minCommentMessageLength } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  commentSelectCommentCreateLoading,
  commentSelectCommentsDepth,
  commentCreate,
  postSelectPost,
} from '@/features'
// utils
import { validateCommentMessage } from '@/utils'

// components
import { FormInput } from './components'
// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const dispatch = useAppDispatch()

  const commentsDepth = useAppSelector(commentSelectCommentsDepth)
  const commentDepthCurrent = commentsDepth[0] || undefined
  const { id: commentId } = commentDepthCurrent ?? {}

  const post = useAppSelector(postSelectPost)
  const { id: postId } = post ?? {}

  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('')

  const commentCreateLoading = useAppSelector(commentSelectCommentCreateLoading)
  const submitDisabled =
    message.length < minCommentMessageLength ||
    messageError !== '' ||
    commentCreateLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(commentCreate({ postId: postId || '', message, commentId }))
          setMessage('')
          setMessageError('')
        }}
      >
        <div css={styles().form.content.message}>
          <FormInput
            name="comment"
            label="comment"
            type="text"
            placeholder="Enter your comment"
            maxLength={maxCommentMessageLength}
            value={message}
            error={messageError.length > 0}
            // errorMessage={messageError}
            sendDisabled={submitDisabled}
            onChange={(e) => {
              setMessage(e.target.value)
            }}
            onBlur={() => {
              if (message !== '') {
                setMessageError(validateCommentMessage(message))
              }
            }}
            onFocus={() => setMessageError('')}
          />
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

import { FC, useState } from 'react'

// config
import { minTipAmount, maxTipAmount, maxTipMessageLength } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  authSelectProfile,
  tipSelectTipCreateTipLoading,
  tipSelectTipModalArtist,
  tipCreateTip,
} from '@/features'
// utils
import { validateTipMessage } from '@/utils'
// components
import { Loader, ButtonLarge, InputAmount, InputArea } from '@/components'

// styles
import { styles } from './styles'

type TipFormProps = {}

export const TipForm: FC<TipFormProps> = () => {
  const dispatch = useAppDispatch()
  const tipCreateLoading = useAppSelector(tipSelectTipCreateTipLoading)
  const profile = useAppSelector(authSelectProfile)
  const modalArtist = useAppSelector(tipSelectTipModalArtist)

  const [tipAmount, setTipAmount] = useState(Math.ceil(maxTipAmount / 2))
  const [tipMessage, setTipMessage] = useState('')
  const [tipMessageError, setTipMessageError] = useState('')

  const { id: profileUserId } = profile ?? {}
  const { id: artistUserId } = modalArtist ?? {}

  const isCurrentUserTip = profileUserId === artistUserId

  const submitDisabled =
    tipMessageError !== '' ||
    tipAmount < minTipAmount ||
    tipAmount > maxTipAmount ||
    tipMessage.length > maxTipMessageLength ||
    isCurrentUserTip ||
    tipCreateLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(
            tipCreateTip({
              amount: tipAmount,
              message: tipMessage,
            }),
          )
        }}
      >
        <div css={styles().form.content.amount}>
          <InputAmount
            name="tip-amount"
            label="tip amount"
            type="range"
            id="tip-amount"
            value={tipAmount}
            min={minTipAmount}
            max={maxTipAmount}
            onChange={(e) => setTipAmount(parseInt(e.target.value, 10))}
          />
        </div>
        <div css={styles().form.content.message}>
          <InputArea
            name="tip-message"
            label="tip message"
            id="tip-message"
            placeholder="Connect with the artist by sending a message"
            value={tipMessage}
            maxLength={maxTipMessageLength}
            error={tipMessageError.length > 0}
            errorMessage={tipMessageError}
            rows={6}
            onChange={(e) => setTipMessage(e.target.value)}
            onBlur={() => {
              if (tipMessage !== '') {
                setTipMessageError(validateTipMessage(tipMessage))
              }
            }}
            onFocus={() => setTipMessageError('')}
          />
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="create tip"
            disabled={submitDisabled}
            appearance="primary"
          >
            {tipCreateLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Tip: $${tipAmount}`
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

TipForm.propTypes = {}

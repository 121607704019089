import { useState, useEffect, useCallback, MutableRefObject } from 'react'

export const useResize = (ref: MutableRefObject<HTMLElement | null>) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const handleResize = useCallback(() => {
    if (ref) {
      setWidth(ref.current?.offsetWidth || 0)
      setHeight(ref.current?.offsetHeight || 0)
    }
  }, [ref])

  useEffect(() => {
    window.addEventListener('load', handleResize)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('load', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [ref, handleResize])

  return { width, height }
}

import { css, Theme } from '@emotion/react'
import { typography, helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  title: {
    text: css`
      font-size: ${typography.h4};
      font-weight: bold;
      color: ${theme.colors.secondary.light};

      margin-bottom: 0px;
    `,
    placement: {
      default: css`
        margin-bottom: 24px;
      `,
      content: css`
        ${helpers.app.padding.content};
        margin-bottom: 24px;
      `,
    },
  },
})

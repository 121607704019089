import { FC, memo } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  CustomerPaymentMethod,
  customerSelectCustomerPaymentMethods,
  customerSelectCustomerPaymentMethodsCount,
  customerSelectCustomerPaymentMethodsLength,
  customerSelectCustomerPaymentMethodsFetched,
  customerSelectCustomerPaymentMethodSelected,
  customerSelectCustomerPaymentMethod,
  customerGetCustomerPaymentMethods,
} from '@/features'
// components
import { ContentPlaceholder, SkeletonHorizontal } from '@/components'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const dispatch = useAppDispatch()
  const customerPaymentMethodSelected = useAppSelector(
    customerSelectCustomerPaymentMethodSelected,
  )
  const customerPaymentMethods = useAppSelector(customerSelectCustomerPaymentMethods)
  const customerPaymentMethod = customerPaymentMethods[index]

  // if payment method not fetched
  if (!customerPaymentMethod) {
    return (
      <div style={style}>
        <SkeletonHorizontal
          sections={[
            {
              name: 'customer-payment-method-list-payment-method',
              width: '100%',
              height: '30px',
              isStatic: false,
              isDivider: false,
            },
          ]}
        />
      </div>
    )
  }

  return (
    <div css={styles().pms.main} style={style}>
      <CustomerPaymentMethod
        customerPaymentMethod={customerPaymentMethod}
        selected={customerPaymentMethodSelected?.id === customerPaymentMethod?.id}
        onClick={() =>
          dispatch(customerSelectCustomerPaymentMethod({ customerPaymentMethod }))
        }
      />
    </div>
  )
})

type CustomerPaymentMethodListProfileProps = {}

export const CustomerPaymentMethodListProfile: FC<
  CustomerPaymentMethodListProfileProps
> = () => {
  const dispatch = useAppDispatch()
  const customerPaymentMethods = useAppSelector(customerSelectCustomerPaymentMethods)
  const customerPaymentMethodsCount = useAppSelector(
    customerSelectCustomerPaymentMethodsCount,
  )
  const customerPaymentMethodsLength = useAppSelector(
    customerSelectCustomerPaymentMethodsLength,
  )
  const customerPaymentMethodsFetched = useAppSelector(
    customerSelectCustomerPaymentMethodsFetched,
  )

  const paymentMethodCardHeight = 30
  const paymentMethodCardMarginBottom = 10
  const itemHeight = paymentMethodCardHeight + paymentMethodCardMarginBottom

  const defaultContentCount = 1000
  const defaultListWidth = '100%'
  const defaultListHeight = 160
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!customerPaymentMethods[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(
      customerGetCustomerPaymentMethods({
        limit: itemsToFetch,
        offset: startIndex,
      }),
    )
  }

  // if payment methods are fetched but length is 0
  if (customerPaymentMethodsFetched && customerPaymentMethodsLength === 0) {
    // return place holder
    return (
      // used to be content layout virtualized
      <div css={styles().list.main}>
        <ContentPlaceholder
          icon={icons.CreditCard}
          height="160px"
          title="You don't have any payment methods yet"
        />
      </div>
    )
  }

  return (
    // used to be content layout virtualized
    <div css={styles().list.main}>
      <div css={styles().list.content.main}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          itemCount={customerPaymentMethodsCount || defaultContentCount}
          minimumBatchSize={itemsToFetch}
          threshold={itemThreshold}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className="virtualized-list"
              height={defaultListHeight}
              itemSize={itemHeight}
              itemCount={customerPaymentMethodsCount || defaultContentCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={defaultListWidth}
              layout="vertical"
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </div>
    </div>
  )
}

CustomerPaymentMethodListProfile.propTypes = {}

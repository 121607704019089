import { css, Theme } from '@emotion/react'
import { typography, helpers, fonts, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  artist: {
    main: {
      shared: css`
        display: flex;

        border: 1px solid transparent;
        border-radius: 50px;

        padding: 6px 20px 6px 6px;
        overflow: hidden;

        transition: border 0.2s;

        width: 212px;

        ${mq.mobile(css`
          width: 100%;
        `)}
      `,
      followed: css`
        border: 1px solid ${theme.colors.accent.normal};
      `,
      unfollowed: css`
        border: 1px solid ${theme.colors.secondary.darker};
      `,
    },

    avatar: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 70px;
        height: 70px;

        background-color: ${theme.colors.primary.darker};
        border-radius: 50%;
        overflow: hidden;

        margin-right: 8px;
      `,
      image: css`
        ${helpers.image};
      `,
      icon: css`
        width: 36px;
        height: 36px;
        & > path {
          fill: ${theme.colors.secondary.darker};
        }
      `,
    },

    content: {
      main: css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        min-width: 0;
      `,

      data: {
        main: css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-width: 0;

          font-size: ${typography.sp};
        `,
        name: css`
          ${helpers.textOverflowEllipsis};

          line-height: 120%;
          font-weight: bold;
        `,
        username: css`
          line-height: 120%;

          ${helpers.textOverflowEllipsis};
        `,
      },

      follow: {
        main: css``,
        button: {
          shared: css`
            ${helpers.resetDefaults};

            font-family: ${fonts.primary};
            font-size: ${typography.sp};
            font-weight: bold;
            white-space: nowrap;

            border-radius: 20px;
            border: 1px solid transparent;
            padding: 4px 20px;

            width: 96px;

            transition: color 0.2s, background-color 0.2s, border 0.2s;
          `,
          followed: css`
            border: 1px solid ${theme.colors.accent.normal};
            background-color: ${theme.colors.accent.normal};
            color: ${theme.colors.primary.normal};
            cursor: pointer;
          `,
          unfollowed: css`
            border: 1px solid ${theme.colors.secondary.darker};
            background-color: ${theme.colors.secondary.darker};
            color: ${theme.colors.primary.normal};
            cursor: pointer;

            &:hover {
              background-color: ${theme.colors.accent.normal};
            }
          `,
          disabled: css`
            border: 1px solid ${theme.colors.secondary.darker};
            background-color: ${theme.colors.secondary.darker};
            color: ${theme.colors.primary.normal};
            cursor: not-allowed;

            &:hover {
              background-color: ${theme.colors.secondary.darker};
            }
          `,
        },
      },
    },
  },
})

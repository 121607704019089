export enum Path {
  Home = '/',
  Feed = '/',
  Library = '/library',
  Charts = '/charts',
  Studio = '/studio',
  Reports = '/reports',
  Profile = '/profile',
  Notifications = '/notifications',

  User = '/user',
  Post = '/post',
  Track = '/track',
  Report = '/report',
  Complaint = '/complaint',
  Notification = '/notification',
}

export enum Params {
  UserID = ':userId',
  PostID = ':postId',
  TrackID = ':trackId',
  ReportID = ':reportId',
  ReportComplaintID = ':reportComplaintId',
  NotificationID = ':notificationId',
}

import { css, Theme } from '@emotion/react'
import { mq, typography, helpers, kf } from '@/styles'

export const styles = (theme: Theme) => ({
  footer: {
    main: css`
      display: flex;
      justify-content: center;

      animation: 0.4s ease 0.2s backwards ${kf.slideIn.up(50)};

      ${mq.tablet(css`
        display: none;
      `)}
    `,
    button: css`
      ${helpers.resetDefaults};

      font-size: ${typography.h6};
      color: ${theme.colors.secondary.dark};

      transition: color 0.2s;
      padding: 10px 12px;

      cursor: pointer;

      &:hover {
        color: ${theme.colors.accent.normal};
      }
    `,
  },
})

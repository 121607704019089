import { FC } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  customerSelectCustomerCreateCustomerPaymentMethodLoading,
  customerCreateCustomerPaymentMethod,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type CustomerPaymentMethodCreateFormProps = {}

export const CustomerPaymentMethodCreateForm: FC<
  CustomerPaymentMethodCreateFormProps
> = () => {
  const dispatch = useAppDispatch()
  const customerCreateCustomerPaymentMethodLoading = useAppSelector(
    customerSelectCustomerCreateCustomerPaymentMethodLoading,
  )

  const stripe = useStripe()
  const elements = useElements()

  const submitDisabled = customerCreateCustomerPaymentMethodLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()

          // create customer payment method with payment element
          dispatch(customerCreateCustomerPaymentMethod({ stripe, elements }))
        }}
      >
        {/* payment method */}
        <div css={styles().form.content.card}>
          <PaymentElement />
        </div>

        {/* submit */}
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="checkout"
            disabled={submitDisabled}
            appearance="primary"
          >
            {customerCreateCustomerPaymentMethodLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Create`
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

CustomerPaymentMethodCreateForm.propTypes = {}

import { css } from '@emotion/react'
import { mq, typography } from '@/styles'

export const styles = () => ({
  form: {
    main: css``,

    content: {
      main: css``,

      cover: {
        main: css`
          display: flex;
          flex-direction: column;

          margin-bottom: 44px;
        `,
        label: css`
          margin-bottom: 10px;
        `,
        preview: {
          main: css`
            margin-bottom: 6px;
          `,
          label: css`
            display: none;
          `,
          image: css``,
        },
        error: css`
          padding-left: 14px;

          & > div {
            justify-content: flex-start;
          }
        `,
      },

      name: css`
        margin-bottom: 32px;
      `,

      username: css`
        margin-bottom: 50px;
      `,

      genres: {
        main: css`
          margin-bottom: 40px;
        `,

        selected: {
          main: css`
            margin-bottom: 30px;
          `,
          label: css`
            margin-bottom: 10px;
          `,
          genres: css`
            display: flex;
            flex-wrap: wrap;

            & > div {
              margin-bottom: 20px;
            }

            & > div:not(:last-of-type) {
              margin-right: 20px;
            }
          `,
        },

        search: {
          main: css``,
          input: css`
            margin-bottom: 10px;
          `,
          genres: css`
            display: flex;
            flex-wrap: wrap;

            & > div {
              margin-bottom: 10px;
            }

            & > div:not(:last-of-type) {
              margin-right: 16px;
            }
          `,
        },
      },

      visibility: {
        main: css`
          margin-bottom: 50px;
        `,
        label: css`
          margin-bottom: 10px;
        `,
        inputs: css`
          & > div:not(:last-of-type) {
            margin-bottom: 12px;
          }
        `,
      },

      price: {
        main: css`
          margin-bottom: 22px;
        `,
        label: css`
          margin-bottom: 10px;
        `,
        amount: css`
          font-size: ${typography.h4};
          line-height: 24px;

          margin-bottom: 10px;
        `,
      },

      buttons: {
        main: css`
          display: flex;

          ${mq.tablet(css`
            flex-direction: column;
          `)}
        `,
        submit: css`
          margin-right: 20px;

          & > button {
            width: 220px;
          }

          ${mq.tablet(css`
            margin-right: 0px;
            margin-bottom: 20px;

            & > button {
              width: 100%;
            }
          `)}
        `,
      },
    },
  },
})

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { AlertCard, alertSelectMessages, alertRemoveMessage } from '@/features'

// styles
import { styles } from './styles'

type AlertModalProps = {}

export const AlertModal: FC<AlertModalProps> = () => {
  const dispatch = useAppDispatch()
  const messages = useAppSelector(alertSelectMessages)

  return (
    <div css={styles().modal.main}>
      <div css={styles().modal.content.main}>
        {messages.map((message) => {
          const { id: messageId } = message

          return (
            <div css={styles().modal.content.message} key={`alert-message-${messageId}`}>
              <AlertCard
                alertMessage={message}
                onTimeout={() => dispatch(alertRemoveMessage({ messageId }))}
                onCloseClick={() => dispatch(alertRemoveMessage({ messageId }))}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

AlertModal.propTypes = {}

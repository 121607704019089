import { css, Theme } from '@emotion/react'
import { z, helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  modal: {
    main: css`
      ${helpers.hideScrollbar};
      z-index: ${z.app.modal};

      display: flex;
      flex-direction: column;

      overflow: hidden;
      overflow-y: scroll;

      display: flex;
      align-items: center;
      justify-content: center;

      position: fixed;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    `,

    solid: css`
      background-color: ${theme.colors.primary.normal};
    `,

    opaque: css`
      background-color: ${theme.colors.primary.normal}E4;
    `,
  },
})

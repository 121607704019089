import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  avatar: {
    main: css`
      display: flex;
      flex-direction: column;
    `,

    content: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100px;
        height: 100px;

        background-color: ${theme.colors.secondary.darker};

        border-radius: 50%;
        overflow: hidden;

        margin-bottom: 8px;
      `,
      image: css`
        ${helpers.image};
      `,
      icon: css`
        width: 36px;
        height: 36px;

        & > path {
          fill: ${theme.colors.secondary.normal};
        }
      `,
    },

    edit: {
      main: css`
        display: flex;
      `,
      button: css`
        ${helpers.resetDefaults};

        display: flex;
        justify-content: center;

        width: 100%;
        height: 100%;

        cursor: pointer;

        &:hover span:nth-of-type(1) {
          color: ${theme.colors.accent.normal};
        }
      `,
      text: css`
        font-size: ${typography.sp};
        color: ${theme.colors.secondary.dark};

        transition: color 0.2s;
        margin-right: 8px;
      `,
      icon: css`
        & > svg {
          width: 14px;
          height: 14px;

          & > path {
            fill: ${theme.colors.accent.normal};
          }
        }
      `,
    },
  },
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  EntityPostView,
  EntityPostVisibility,
  EntityTrackView,
} from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// utils
import { uuid } from '@/utils'
// features
import {
  // alert
  AlertMessageType,
  alertAddMessage,
  // comment
  commentResetComments,
  // post
  postSetPublicPosts,
  postSetPopularPosts,
  postSetRecommendedPosts,
  postSetLikedPosts,
  postSetUnlockedPosts,
  postSetUploadedPosts,
  postSetArtistPopularPosts,
  postSetArtistExclusivePosts,
  postSetArtistLatestPosts,
  postSetPost,
  postResetPost,
  postFilterPost,
  postUpdatePost,
  postClosePostBlockModal,
  postClosePostDeleteModal,
  // report
  reportSetReportEntityBlocked,
  // track
  trackGetPostTracks,
} from '@/features'

export const postGetPublicPosts = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('post/postGetPublicPosts', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve public posts
    const { posts, count } = await api.postApi.getPublicPosts()

    // set public posts
    dispatch(
      postSetPublicPosts({
        posts: posts || [],
        count: count || 0,
        offset: 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postGetPopularPosts = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('post/postGetPopularPosts', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve popular posts
    const { posts, count } = await api.postApi.getPopularPosts(
      undefined,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set popular posts
    dispatch(
      postSetPopularPosts({
        posts: posts || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postGetRecommendedPosts = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('post/postGetRecommendedPosts', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve recommended posts
    const { posts, count } = await api.postApi.getRecommendedPosts(
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set recommended posts
    dispatch(
      postSetRecommendedPosts({
        posts: posts || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postGetLikedPosts = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('post/postGetLikedPosts', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve liked posts
    const { posts, count } = await api.postApi.getLikedPosts(
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set liked posts
    dispatch(
      postSetLikedPosts({
        posts: posts || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postGetUnlockedPosts = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('post/postGetUnlockedPosts', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve unlocked posts
    const { posts, count } = await api.postApi.getUnlockedPosts(
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set unlocked posts
    dispatch(
      postSetUnlockedPosts({
        posts: posts || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postGetUploadedPosts = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('post/postGetUploadedPosts', async (inp, { getState, dispatch }) => {
  // get api service
  const api = apiGetService()

  // get currently logged in user id
  const { profile } = getState().auth
  const userId = profile?.id || ''

  try {
    // retrieve posts
    const { posts, count } = await api.postApi.getArtistPosts(
      userId,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set uploaded posts
    dispatch(
      postSetUploadedPosts({
        posts: posts || [],
        count: count || 0,
        offset: inp.offset || 0,
        mutate: true,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postGetArtistPopularPosts = createAsyncThunk<
  { error: string },
  { artistId?: string; limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('post/postGetArtistPopularPosts', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve posts
    const { posts, count } = await api.postApi.getPopularPosts(
      inp.artistId,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set posts
    dispatch(
      postSetArtistPopularPosts({
        posts: posts || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postGetArtistExclusivePosts = createAsyncThunk<
  { error: string },
  { artistId?: string; limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('post/postGetArtistExclusivePosts', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve posts
    const { posts, count } = await api.postApi.getExclusivePosts(
      inp.artistId,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set posts
    dispatch(
      postSetArtistExclusivePosts({
        posts: posts || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postGetArtistLatestPosts = createAsyncThunk<
  { error: string },
  { artistId?: string; limit?: number; offset?: number; search?: string },
  { state: RootState; rejectValue: undefined }
>('post/postGetArtistLatestPosts', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve posts
    const { posts, count } = await api.postApi.getLatestPosts(
      inp.artistId,
      inp.limit,
      inp.offset,
      inp.search,
    )

    // set posts
    dispatch(
      postSetArtistLatestPosts({
        posts: posts || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postGetPost = createAsyncThunk<
  { error: string },
  { postId: string },
  { state: RootState; rejectValue: undefined }
>('post/postGetPost', async (inp, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve post
    const { post } = await api.postApi.getPost(inp.postId)

    // set post
    dispatch(
      postSetPost({
        post: post || {},
      }),
    )

    // get post tracks
    dispatch(
      trackGetPostTracks({
        trackIds: post?.trackIds || [],
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postCreate = createAsyncThunk<
  { error: string },
  {
    message: string
    media?: File
    tracks?: EntityTrackView[]
    visibility?: EntityPostVisibility
    unlockPrice?: number
  },
  { state: RootState; rejectValue: undefined }
>(
  'post/postCreate',
  async ({ message, media, tracks, visibility, unlockPrice }, { dispatch }) => {
    // get api service
    const api = apiGetService()

    try {
      // create form data
      const formData = new FormData()

      // append message
      formData.append('message', message)

      // if media provided
      if (media) {
        // append media
        formData.append('media', media)
      }

      // if tracks provided
      if (tracks && tracks.length > 0) {
        // append track ids
        formData.append('trackIds', tracks.map((track) => track.id).join(','))
      }

      // if visibility provided
      if (visibility) {
        // append visibility
        formData.append('visibility', visibility.toString())
      }

      // if visibility is subscribers
      // and unlock price provided
      if (visibility === EntityPostVisibility.Subscribers && unlockPrice) {
        // append unlock price in cents
        // if unlockPrice = 1 (one dollar)
        // 1 * 100 = 100 (hundred cents)
        formData.append('unlockPrice', (unlockPrice * 100).toString())
      }

      // create post
      await api.postApi.createPost({ body: formData })

      // refetch uploaded posts
      dispatch(postGetUploadedPosts({ limit: 30, offset: 0 }))

      // launch info alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Info,
            message: 'Successfully created post!',
          },
        }),
      )

      // return empty payload
      return {
        error: '',
      }
    } catch (err: any) {
      const { message: errMessage } = await apiHandleError(err)

      // launch error alert
      dispatch(
        alertAddMessage({
          message: {
            id: uuid(),
            type: AlertMessageType.Error,
            message: `Failed to create post: ${errMessage}`,
          },
        }),
      )

      // return error message in payload
      return {
        error: errMessage,
      }
    }
  },
)

export const postUpdate = createAsyncThunk<
  { error: string },
  {
    post: EntityPostView
    message?: string
    media?: File
    tracks?: EntityTrackView[]
  },
  { state: RootState; rejectValue: undefined }
>('post/postUpdate', async ({ post, message, media, tracks }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // create form data
    const formData = new FormData()

    // append post id
    formData.append('postId', post.id || '')

    // if message provided
    if (message) {
      // append name
      formData.append('message', message)
    }

    // if media provided
    if (media) {
      // append media
      formData.append('media', media)
    }

    // if tracks provided
    if (tracks && tracks.length > 0) {
      // append track ids
      formData.append('trackIds', tracks.map((track) => track.id).join(','))
    }

    // update post
    const { post: updatedPost } = await api.postApi.updatePost({ body: formData })

    // update post in post state
    dispatch(postUpdatePost({ updatedPost: updatedPost || {} }))

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully updated post!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message: errMessage } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to update post: ${errMessage}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: errMessage,
    }
  }
})

export const postBlock = createAsyncThunk<
  { error: string },
  { id: string },
  { state: RootState; rejectValue: undefined }
>('post/postBlock', async ({ id }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // block post
    await api.postApi.blockPost({ postId: id })

    // reset post page state
    dispatch(postResetPost())

    // reset comments state
    dispatch(commentResetComments())

    // filter blocked post from posts
    dispatch(postFilterPost({ id }))

    // set post as blocked in the report state
    dispatch(reportSetReportEntityBlocked())

    // close block post modal
    dispatch(postClosePostBlockModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully blocked post!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to block post: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const postDelete = createAsyncThunk<
  { error: string },
  { id: string },
  { state: RootState; rejectValue: undefined }
>('post/postDelete', async ({ id }, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // delete post
    await api.postApi.deletePost(id)

    // filter deleted post
    dispatch(postFilterPost({ id }))

    // refetch uploaded posts
    dispatch(postGetUploadedPosts({ limit: 30, offset: 0 }))

    // close delete post modal
    dispatch(postClosePostDeleteModal())

    // launch info alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Info,
          message: 'Successfully deleted post!',
        },
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // launch error alert
    dispatch(
      alertAddMessage({
        message: {
          id: uuid(),
          type: AlertMessageType.Error,
          message: `Failed to delete post: ${message}`,
        },
      }),
    )

    // return error message in payload
    return {
      error: message,
    }
  }
})

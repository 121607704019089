export const firebaseConfigProd = {
  apiKey: 'AIzaSyDuYj58qK8qf4Dz_5nDRjLTE5v_HZyKv5k',
  authDomain: 'ryddm-286615.firebaseapp.com',
  databaseURL: 'https://ryddm-286615.firebaseio.com',
  projectId: 'ryddm-286615',
  storageBucket: 'ryddm-286615.appspot.com',
  messagingSenderId: '864316200417',
  appId: '1:864316200417:web:ffe830cc68e0b3f34aeea0',
  measurementId: 'G-MBKVM74PPX',
}

export const firebaseConfigStag = {
  apiKey: 'AIzaSyA_2ysu-qullegzw23n7MuVmGKlSO_t4kk',
  authDomain: 'ryddmstaging.firebaseapp.com',
  projectId: 'ryddmstaging',
  storageBucket: 'ryddmstaging.appspot.com',
  messagingSenderId: '1057102064949',
  appId: '1:1057102064949:web:ff160f543d306fbd23707c',
  measurementId: 'G-FRSWWVMQJH',
}

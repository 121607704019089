import { FC, useState } from 'react'
import { EntityUserRole } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { authSignout, onboardingSelectLoading, onboardingUpdateRole } from '@/features'
// icons
import { icons } from '@/assets'
// components
import { ButtonRound } from '@/components'

// features
import { fanRoleFeatures, artistRoleFeatures } from './features'
// components
import { Role } from './components'
// styles
import { styles } from './styles'

type SelectRoleProps = {}

export const SelectRole: FC<SelectRoleProps> = () => {
  const dispatch = useAppDispatch()
  const onboardingLoading = useAppSelector(onboardingSelectLoading)

  const [fanRoleHover, setFanRoleHover] = useState(true)
  const [artistRoleHover, setArtistRoleHover] = useState(false)

  const onFanRoleMouseEnter = () => {
    setFanRoleHover(true)
    setArtistRoleHover(false)
  }
  const onFanRoleMouseLeave = () => {
    setFanRoleHover(true)
    setArtistRoleHover(false)
  }

  const onArtistRoleMouseEnter = () => {
    setArtistRoleHover(true)
    setFanRoleHover(false)
  }
  const onArtistRoleMouseLeave = () => {
    setArtistRoleHover(true)
    setFanRoleHover(false)
  }

  return (
    <div css={styles().stage.main}>
      <div css={styles().stage.backward.main}>
        <ButtonRound
          css={styles().stage.backward.button}
          aria-label="sign out"
          appearance="secondary"
          onClick={() => dispatch(authSignout())}
        >
          <icons.SignOut css={styles().stage.backward.icon} />
        </ButtonRound>
      </div>
      <div css={styles().stage.role.main}>
        <div css={styles().stage.role.fan}>
          <Role
            name="Fan"
            appearance="primary"
            features={fanRoleFeatures}
            visible={fanRoleHover}
            loading={onboardingLoading}
            onRoleMouseEnter={() => onFanRoleMouseEnter()}
            onRoleMouseLeave={() => onFanRoleMouseLeave()}
            onNextClick={() => {
              dispatch(onboardingUpdateRole({ role: EntityUserRole.Fan }))
            }}
          />
        </div>
        <div css={styles().stage.role.artist}>
          <Role
            name="Artist"
            appearance="secondary"
            features={artistRoleFeatures}
            visible={artistRoleHover}
            loading={onboardingLoading}
            onRoleMouseEnter={() => onArtistRoleMouseEnter()}
            onRoleMouseLeave={() => onArtistRoleMouseLeave()}
            onNextClick={() => {
              dispatch(onboardingUpdateRole({ role: EntityUserRole.Artist }))
            }}
          />
        </div>
      </div>
    </div>
  )
}

SelectRole.propTypes = {}

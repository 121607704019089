import { icons } from './assets'

export const monetizationFeatures = [
  {
    id: '218f6074-bcad-4c04-ad8c-cb0d25d612db',
    icon: icons.Dollar,
    title: 'Unlock Earnings',
    description: 'Enable payouts by configuring your business account',
  },
  {
    id: 'faaa776c-fb02-464f-b1dd-ba63b08fd5d4',
    icon: icons.Stars,
    title: 'Build Your Brand',
    description: 'Unlock advanced profile controls and promote your brand',
  },
  {
    id: '69203fe8-5a0a-4a41-b2a7-8310701726ab',
    icon: icons.Rocket,
    title: 'Maximize Your Potential',
    description: 'Fans are eagerly anticipating your incredible content',
  },
]

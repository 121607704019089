/**
 * Converts a number into a string representation with appropriate characters.
 *
 * Takes number to convert and number of digits to return.
 *
 * @param number - number to convert.
 * @param digits - digits to add to the return (defaults to 1).
 * @returns converted string representation.
 *
 * @example
 * // Returns formatted number (1k).
 * const formattedNum = numbersFormatter(1000);
 */
export const numbersFormatter = (num: number, digits: number = 1): string => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/

  const lookupItem = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value)

  return lookupItem
    ? (num / lookupItem.value).toFixed(digits).replace(rx, '$1') + lookupItem.symbol
    : '0'
}

/**
 * Converts a audio duration number into a string representation.
 *
 * Takes audio duration number to convert into string representation.
 *
 * @param duration - audio duration.
 * @returns converted string representation.
 *
 * @example
 * // Returns formatted number (1k).
 * const elapsedTime = formatAudioTime(0) // elapsedTime === 00:00
 * const durationTime = formatAudioTime(204.52678) // durationTime === 03:24
 */
export const audioDurationFormatter = (durationTime: number) => {
  const min = Math.floor(durationTime / 60)
  const sec = Math.floor(durationTime - min * 60)

  // format - mm:ss
  const formatted = [min, sec].map((n) => (n < 10 ? `0${n}` : n)).join(':')

  return formatted
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityUserView } from '@ryddm-inc/ryddm-apiclient'

// utils
import { mergeArraysAtIndex } from '@/utils'
// stores
import { RootState } from '@/stores'
// features
import {
  userGetPublicArtists,
  userGetPopularArtists,
  userGetRecommendedArtists,
  userGetFollowedArtists,
  userGetSubscribedArtists,
  userGetUser,
  userBlock,
  userProfileUpdate,
  userProfileDelete,
} from '@/features'

export interface UserState {
  publicArtists: EntityUserView[]
  publicArtistsCount: number
  publicArtistsFetched: boolean

  popularArtists: EntityUserView[]
  popularArtistsCount: number
  popularArtistsFetched: boolean

  recommendedArtists: EntityUserView[]
  recommendedArtistsCount: number
  recommendedArtistsFetched: boolean

  followedArtists: EntityUserView[]
  followedArtistsCount: number
  followedArtistsFetched: boolean

  subscribedArtists: EntityUserView[]
  subscribedArtistsCount: number
  subscribedArtistsFetched: boolean

  user: EntityUserView | undefined
  userFetched: boolean

  userBlockModal: {
    user: EntityUserView | undefined
    open: boolean
  }

  userProfileDeleteModal: {
    user: EntityUserView | undefined
    open: boolean
  }

  userGetPublicArtistsLoading: boolean
  userGetPopularArtistsLoading: boolean
  userGetRecommendedArtistsLoading: boolean
  userGetFollowedArtistsLoading: boolean
  userGetSubscribedArtistsLoading: boolean
  userGetUserLoading: boolean
  userBlockLoading: boolean
  userProfileUpdateLoading: boolean
  userProfileDeleteLoading: boolean
}

const initialState: UserState = {
  publicArtists: [],
  publicArtistsCount: 0,
  publicArtistsFetched: false,

  popularArtists: [],
  popularArtistsCount: 0,
  popularArtistsFetched: false,

  recommendedArtists: [],
  recommendedArtistsCount: 0,
  recommendedArtistsFetched: false,

  followedArtists: [],
  followedArtistsCount: 0,
  followedArtistsFetched: false,

  subscribedArtists: [],
  subscribedArtistsCount: 0,
  subscribedArtistsFetched: false,

  user: undefined,
  userFetched: false,

  userBlockModal: {
    user: undefined,
    open: false,
  },

  userProfileDeleteModal: {
    user: undefined,
    open: false,
  },

  userGetPublicArtistsLoading: false,
  userGetPopularArtistsLoading: false,
  userGetRecommendedArtistsLoading: false,
  userGetFollowedArtistsLoading: false,
  userGetSubscribedArtistsLoading: false,
  userGetUserLoading: false,
  userBlockLoading: false,
  userProfileUpdateLoading: false,
  userProfileDeleteLoading: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // reset state
    userResetState: () => ({
      publicArtists: [],
      publicArtistsCount: 0,
      publicArtistsFetched: false,

      popularArtists: [],
      popularArtistsCount: 0,
      popularArtistsFetched: false,

      recommendedArtists: [],
      recommendedArtistsCount: 0,
      recommendedArtistsFetched: false,

      followedArtists: [],
      followedArtistsCount: 0,
      followedArtistsFetched: false,

      subscribedArtists: [],
      subscribedArtistsCount: 0,
      subscribedArtistsFetched: false,

      user: undefined,
      userFetched: false,

      userBlockModal: {
        user: undefined,
        open: false,
      },

      userProfileDeleteModal: {
        user: undefined,
        open: false,
      },

      userGetPublicArtistsLoading: false,
      userGetPopularArtistsLoading: false,
      userGetRecommendedArtistsLoading: false,
      userGetFollowedArtistsLoading: false,
      userGetSubscribedArtistsLoading: false,
      userGetUserLoading: false,
      userBlockLoading: false,
      userProfileUpdateLoading: false,
      userProfileDeleteLoading: false,
    }),

    // set public artists
    userSetPublicArtists: (
      state,
      action: PayloadAction<{
        artists: EntityUserView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { artists, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.publicArtistsCount = count

        // mutate public artists array
        state.publicArtists = mergeArraysAtIndex(state.publicArtists, artists, offset)
      } else {
        // this block will be used for search
        // during search array of artists will have to be recrated
        // in accordance to the new count
      }
    },

    // set popular artists
    userSetPopularArtists: (
      state,
      action: PayloadAction<{
        artists: EntityUserView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { artists, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.popularArtistsCount = count

        // mutate popular artists array
        state.popularArtists = mergeArraysAtIndex(state.popularArtists, artists, offset)
      } else {
        // this block will be used for search
        // during search array of artists will have to be recrated
        // in accordance to the new count
      }
    },

    // set recommended artists
    userSetRecommendedArtists: (
      state,
      action: PayloadAction<{
        artists: EntityUserView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { artists, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.recommendedArtistsCount = count

        // mutate recommended artists array
        state.recommendedArtists = mergeArraysAtIndex(
          state.recommendedArtists,
          artists,
          offset,
        )
      } else {
        // this block will be used for search
        // during search array of artists will have to be recrated
        // in accordance to the new count
      }
    },

    // set followed artists
    userSetFollowedArtists: (
      state,
      action: PayloadAction<{
        artists: EntityUserView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { artists, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.followedArtistsCount = count

        // mutate followed artists array
        state.followedArtists = mergeArraysAtIndex(state.followedArtists, artists, offset)
      } else {
        // this block will be used for search
        // during search array of artists will have to be recrated
        // in accordance to the new count
      }
    },

    // set subscribed artists
    userSetSubscribedArtists: (
      state,
      action: PayloadAction<{
        artists: EntityUserView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { artists, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.subscribedArtistsCount = count

        // mutate subscribers artists array
        state.subscribedArtists = mergeArraysAtIndex(
          state.subscribedArtists,
          artists,
          offset,
        )
      } else {
        // this block will be used for search
        // during search array of artists will have to be recrated
        // in accordance to the new count
      }
    },

    // set user
    userSetUser: (state, action: PayloadAction<{ user: EntityUserView }>) => {
      const { user } = action.payload

      // update user state
      state.user = user
    },

    // reset user
    userResetUser: (state) => ({
      ...state,

      user: undefined,
      userFetched: false,
    }),

    // append followed artist
    userAppendFollowedArtist: (
      state,
      action: PayloadAction<{ artist: EntityUserView }>,
    ) => {
      const { artist } = action.payload

      // if followed artists are fetched
      if (state.followedArtistsFetched) {
        // append artist to followed artists
        state.followedArtists.unshift(artist)
        state.followedArtistsCount += 1
      }
    },

    // filter followed artist
    userFilterFollowedArtist: (
      state,
      action: PayloadAction<{ artist: EntityUserView }>,
    ) => {
      const { artist } = action.payload

      // if followed artists are fetched
      if (state.followedArtistsFetched) {
        // filter artist from followed artists
        state.followedArtists = state.followedArtists.filter((a) => a.id !== artist.id)
        state.followedArtistsCount -= 1
      }
    },

    // append subscribed artist
    userAppendSubscribedArtist: (
      state,
      action: PayloadAction<{ artist: EntityUserView }>,
    ) => {
      const { artist } = action.payload

      // if subscribed artists are fetched
      if (state.subscribedArtistsFetched) {
        // append artist to subscribed artists
        state.subscribedArtists.unshift(artist)
        state.subscribedArtistsCount += 1
      }
    },

    // filter subscribed artists
    userFilterSubscribedArtist: (
      state,
      action: PayloadAction<{ artist: EntityUserView }>,
    ) => {
      const { artist } = action.payload

      // if subscribed artists are fetched
      if (state.subscribedArtistsFetched) {
        // filter artist from subscribed artists
        state.subscribedArtists = state.subscribedArtists.filter(
          (a) => a.id !== artist.id,
        )
        state.subscribedArtistsCount -= 1
      }
    },

    // filter user
    userFilterUser: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload

      // check if user exists in public artists
      const pubIndex = state.publicArtists.findIndex((a) => a.id === id)
      if (pubIndex !== -1) {
        // filter artist
        state.publicArtists = state.publicArtists.filter((a) => a.id !== id)
        state.publicArtistsCount -= 1
      }

      // check if user exists in popular artists
      const popIndex = state.popularArtists.findIndex((a) => a.id === id)
      if (popIndex !== -1) {
        // filter artist
        state.popularArtists = state.popularArtists.filter((a) => a.id !== id)
        state.popularArtistsCount -= 1
      }

      // check if user exists in recommended artists
      const recIndex = state.recommendedArtists.findIndex((a) => a.id === id)
      if (recIndex !== -1) {
        // filter artist
        state.recommendedArtists = state.recommendedArtists.filter((a) => a.id !== id)
        state.recommendedArtistsCount -= 1
      }

      // check if user exists in followed artists
      const folIndex = state.followedArtists.findIndex((a) => a.id === id)
      if (folIndex !== -1) {
        // filter artist
        state.followedArtists = state.followedArtists.filter((a) => a.id !== id)
        state.followedArtistsCount -= 1
      }

      // check if user exists in subscribed artists
      const subIndex = state.subscribedArtists.findIndex((a) => a.id === id)
      if (subIndex !== -1) {
        // filter artist
        state.subscribedArtists = state.subscribedArtists.filter((a) => a.id !== id)
        state.subscribedArtistsCount -= 1
      }
    },

    // open user block modal
    userOpenUserBlockModal: (state, action: PayloadAction<{ user: EntityUserView }>) => ({
      ...state,
      userBlockModal: {
        user: action.payload.user,
        open: true,
      },
    }),

    // close user block modal
    userCloseUserBlockModal: (state) => ({
      ...state,
      userBlockModal: {
        user: undefined,
        open: false,
      },
      error: '',
    }),

    // open user profile delete modal
    userOpenUserProfileDeleteModal: (
      state,
      action: PayloadAction<{ user: EntityUserView }>,
    ) => ({
      ...state,
      userProfileDeleteModal: {
        user: action.payload.user,
        open: true,
      },
    }),

    // close user profile delete modal
    userCloseUserProfileDeleteModal: (state) => ({
      ...state,
      userProfileDeleteModal: {
        user: undefined,
        open: false,
      },
      error: '',
    }),
  },
  extraReducers: (builder) => {
    // get public artists
    builder
      .addCase(userGetPublicArtists.pending, (state) => ({
        ...state,
        userGetPublicArtistsLoading: true,
      }))
      .addCase(userGetPublicArtists.fulfilled, (state) => ({
        ...state,
        publicArtistsFetched: true,
        userGetPublicArtistsLoading: false,
      }))

    // get popular artists
    builder
      .addCase(userGetPopularArtists.pending, (state) => ({
        ...state,
        userGetPopularArtistsLoading: true,
      }))
      .addCase(userGetPopularArtists.fulfilled, (state) => ({
        ...state,
        popularArtistsFetched: true,
        userGetPopularArtistsLoading: false,
      }))

    // get recommended artists
    builder
      .addCase(userGetRecommendedArtists.pending, (state) => ({
        ...state,
        userGetRecommendedArtistsLoading: true,
      }))
      .addCase(userGetRecommendedArtists.fulfilled, (state) => ({
        ...state,
        recommendedArtistsFetched: true,
        userGetRecommendedArtistsLoading: false,
      }))

    // get followed artists
    builder
      .addCase(userGetFollowedArtists.pending, (state) => ({
        ...state,
        userGetFollowedArtistsLoading: true,
      }))
      .addCase(userGetFollowedArtists.fulfilled, (state) => ({
        ...state,
        followedArtistsFetched: true,
        userGetFollowedArtistsLoading: false,
      }))

    // get subscribed artists
    builder
      .addCase(userGetSubscribedArtists.pending, (state) => ({
        ...state,
        userGetSubscribedArtistsLoading: true,
      }))
      .addCase(userGetSubscribedArtists.fulfilled, (state) => ({
        ...state,
        subscribedArtistsFetched: true,
        userGetSubscribedArtistsLoading: false,
      }))

    // get user
    builder
      .addCase(userGetUser.pending, (state) => ({
        ...state,
        userGetUserLoading: true,
      }))
      .addCase(userGetUser.fulfilled, (state) => ({
        ...state,
        userFetched: true,
        userGetUserLoading: false,
      }))

    // block user
    builder
      .addCase(userBlock.pending, (state) => ({
        ...state,
        userBlockLoading: true,
      }))
      .addCase(userBlock.fulfilled, (state) => ({
        ...state,
        userBlockLoading: false,
      }))

    // update profile
    builder
      .addCase(userProfileUpdate.pending, (state) => ({
        ...state,
        userProfileUpdateLoading: true,
      }))
      .addCase(userProfileUpdate.fulfilled, (state) => ({
        ...state,
        userProfileUpdateLoading: false,
      }))

    // delete profile
    builder
      .addCase(userProfileDelete.pending, (state) => ({
        ...state,
        userProfileDeleteLoading: true,
      }))
      .addCase(userProfileDelete.fulfilled, (state) => ({
        ...state,
        userProfileDeleteLoading: false,
      }))
  },
})

export const {
  userResetState,
  userSetPublicArtists,
  userSetPopularArtists,
  userSetRecommendedArtists,
  userSetFollowedArtists,
  userSetSubscribedArtists,
  userSetUser,
  userResetUser,
  userAppendFollowedArtist,
  userFilterFollowedArtist,
  userAppendSubscribedArtist,
  userFilterSubscribedArtist,
  userFilterUser,
  userOpenUserBlockModal,
  userCloseUserBlockModal,
  userOpenUserProfileDeleteModal,
  userCloseUserProfileDeleteModal,
} = userSlice.actions

export const userSelectPublicArtists = (state: RootState) => state.user.publicArtists
export const userSelectPublicArtistsLength = (state: RootState) =>
  state.user.publicArtists.length
export const userSelectPublicArtistsCount = (state: RootState) =>
  state.user.publicArtistsCount
export const userSelectPublicArtistsFetched = (state: RootState) =>
  state.user.publicArtistsFetched

export const userSelectPopularArtists = (state: RootState) => state.user.popularArtists
export const userSelectPopularArtistsLength = (state: RootState) =>
  state.user.popularArtists.length
export const userSelectPopularArtistsCount = (state: RootState) =>
  state.user.popularArtistsCount
export const userSelectPopularArtistsFetched = (state: RootState) =>
  state.user.popularArtistsFetched

export const userSelectRecommendedArtists = (state: RootState) =>
  state.user.recommendedArtists
export const userSelectRecommendedArtistsLength = (state: RootState) =>
  state.user.recommendedArtists.length
export const userSelectRecommendedArtistsCount = (state: RootState) =>
  state.user.recommendedArtistsCount
export const userSelectRecommendedArtistsFetched = (state: RootState) =>
  state.user.recommendedArtistsFetched

export const userSelectFollowedArtists = (state: RootState) => state.user.followedArtists
export const userSelectFollowedArtistsLength = (state: RootState) =>
  state.user.followedArtists.length
export const userSelectFollowedArtistsCount = (state: RootState) =>
  state.user.followedArtistsCount
export const userSelectFollowedArtistsFetched = (state: RootState) =>
  state.user.followedArtistsFetched

export const userSelectSubscribedArtists = (state: RootState) =>
  state.user.subscribedArtists
export const userSelectSubscribedArtistsLength = (state: RootState) =>
  state.user.subscribedArtists.length
export const userSelectSubscribedArtistsCount = (state: RootState) =>
  state.user.subscribedArtistsCount
export const userSelectSubscribedArtistsFetched = (state: RootState) =>
  state.user.subscribedArtistsFetched

export const userSelectUser = (state: RootState) => state.user.user
export const userSelectUserFetched = (state: RootState) => state.user.userFetched

export const userSelectUserBlockModalOpen = (state: RootState) =>
  state.user.userBlockModal.open
export const userSelectUserBlockModalUser = (state: RootState) =>
  state.user.userBlockModal.user

export const userSelectUserProfileDeleteModalOpen = (state: RootState) =>
  state.user.userProfileDeleteModal.open
export const userSelectUserProfileDeleteModalUser = (state: RootState) =>
  state.user.userProfileDeleteModal.user

export const userSelectUserGetPublicArtistsLoading = (state: RootState) =>
  state.user.userGetPublicArtistsLoading
export const userSelectUserGetPopularArtistsLoading = (state: RootState) =>
  state.user.userGetPopularArtistsLoading
export const userSelectUserGetRecommendedArtistsLoading = (state: RootState) =>
  state.user.userGetRecommendedArtistsLoading
export const userSelectUserGetFollowedArtistsLoading = (state: RootState) =>
  state.user.userGetFollowedArtistsLoading
export const userSelectUserGetSubscribedArtistsLoading = (state: RootState) =>
  state.user.userGetSubscribedArtistsLoading
export const userSelectUserGetUserLoading = (state: RootState) =>
  state.user.userGetUserLoading
export const userSelectUserBlockLoading = (state: RootState) =>
  state.user.userBlockLoading
export const userSelectUserProfileUpdateLoading = (state: RootState) =>
  state.user.userProfileUpdateLoading
export const userSelectUserProfileDeleteLoading = (state: RootState) =>
  state.user.userProfileDeleteLoading

export const userReducer = userSlice.reducer

// export default userSlice.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  EntityNotificationView,
  EntityNotificationConfig,
  EntityNotificationEntityType,
} from '@ryddm-inc/ryddm-apiclient'

// utils
import { mergeArraysAtIndex } from '@/utils'
// stores
import { RootState } from '@/stores'
// features
import {
  notificationGetNotifications,
  notificationGetNotificationsUnreadCount,
  notificationGetNotification,
  notificationGetNotificationConfig,
  notificationUpdateNotificationConfig,
  notificationReadNotifications,
} from '@/features'

export interface NotificationState {
  notifications: EntityNotificationView[]
  notificationsCount: number
  notificationsFetched: boolean

  notificationsUnreadCount: number

  notification: EntityNotificationView | undefined
  notificationFetched: boolean
  notificationTipRetried: boolean
  notificationUnlockRetried: boolean

  notificationConfig: EntityNotificationConfig | undefined
  notificationConfigFetched: boolean

  notificationsFilter: EntityNotificationEntityType | undefined

  notificationGetNotificationsLoading: boolean
  notificationGetNotificationsUnreadCountLoading: boolean
  notificationGetNotificationLoading: boolean
  notificationGetNotificationConfigLoading: boolean
  notificationUpdateNotificationConfigLoading: boolean
  notificationReadNotificationsLoading: boolean
}

const initialState: NotificationState = {
  notifications: [],
  notificationsCount: 0,
  notificationsFetched: false,

  notificationsUnreadCount: 0,

  notification: undefined,
  notificationFetched: false,
  notificationTipRetried: false,
  notificationUnlockRetried: false,

  notificationConfig: undefined,
  notificationConfigFetched: false,

  notificationsFilter: undefined,

  notificationGetNotificationsLoading: false,
  notificationGetNotificationsUnreadCountLoading: false,
  notificationGetNotificationLoading: false,
  notificationGetNotificationConfigLoading: false,
  notificationUpdateNotificationConfigLoading: false,
  notificationReadNotificationsLoading: false,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // reset state
    notificationResetState: () => ({
      notifications: [],
      notificationsCount: 0,
      notificationsFetched: false,

      notificationsUnreadCount: 0,

      notification: undefined,
      notificationFetched: false,
      notificationTipRetried: false,
      notificationUnlockRetried: false,

      notificationConfig: undefined,
      notificationConfigFetched: false,

      notificationsFilter: undefined,

      notificationGetNotificationsLoading: false,
      notificationGetNotificationsUnreadCountLoading: false,
      notificationGetNotificationLoading: false,
      notificationGetNotificationConfigLoading: false,
      notificationUpdateNotificationConfigLoading: false,
      notificationReadNotificationsLoading: false,
    }),

    // set notifications
    notificationSetNotifications: (
      state,
      action: PayloadAction<{
        notifications: EntityNotificationView[]
        count: number
        offset: number
      }>,
    ) => {
      const { notifications, count, offset } = action.payload

      // mutate count
      state.notificationsCount = count
      // mutate notifications array
      state.notifications = mergeArraysAtIndex(state.notifications, notifications, offset)
    },

    // reset notifications
    notificationResetNotifications: (state) => ({
      ...state,

      notifications: [],
      notificationsCount: 0,
      notificationsFetched: false,
    }),

    // set notifications unread count
    notificationSetNotificationsUnreadCount: (
      state,
      action: PayloadAction<{ count: number }>,
    ) => {
      const { count } = action.payload

      // update notifications unread count state
      state.notificationsUnreadCount = count
    },

    // set notification
    notificationSetNotification: (
      state,
      action: PayloadAction<{ notification: EntityNotificationView }>,
    ) => {
      const { notification } = action.payload

      // update notification state
      state.notification = notification
    },

    // set notification tip retried
    notificationSetNotificationTipRetried: (state) => ({
      ...state,
      notificationTipRetried: true,
    }),

    // set notification unlock retried
    notificationSetNotificationUnlockRetried: (state) => ({
      ...state,
      notificationUnlockRetried: true,
    }),

    // reset notification
    notificationResetNotification: (state) => ({
      ...state,

      notification: undefined,
      notificationFetched: false,
      notificationTipRetried: false,
      notificationUnlockRetried: false,
    }),

    // set notification config
    notificationSetNotificationConfig: (
      state,
      action: PayloadAction<{ notificationConfig: EntityNotificationConfig }>,
    ) => {
      const { notificationConfig } = action.payload

      // update notification config state
      state.notificationConfig = notificationConfig
    },

    // set notifications filter
    notificationSetNotificationsFilter: (
      state,
      action: PayloadAction<{ filter: EntityNotificationEntityType }>,
    ) => {
      const { filter } = action.payload

      // cleanup notifications
      state.notifications = []
      state.notificationsCount = 0

      // mutate notifications filter
      state.notificationsFilter =
        state.notificationsFilter === filter ? undefined : filter
    },
  },
  extraReducers: (builder) => {
    // get notifications
    builder
      .addCase(notificationGetNotifications.pending, (state) => ({
        ...state,
        notificationGetNotificationsLoading: true,
      }))
      .addCase(notificationGetNotifications.fulfilled, (state) => ({
        ...state,
        notificationsFetched: true,
        notificationGetNotificationsLoading: false,
      }))

    // get notifications unread count
    builder
      .addCase(notificationGetNotificationsUnreadCount.pending, (state) => ({
        ...state,
        notificationGetNotificationsUnreadCountLoading: true,
      }))
      .addCase(notificationGetNotificationsUnreadCount.fulfilled, (state) => ({
        ...state,
        notificationGetNotificationsUnreadCountLoading: false,
      }))

    // get notification
    builder
      .addCase(notificationGetNotification.pending, (state) => ({
        ...state,
        notificationGetNotificationLoading: true,
      }))
      .addCase(notificationGetNotification.fulfilled, (state) => ({
        ...state,
        notificationFetched: true,
        notificationGetNotificationLoading: false,
      }))

    // get notifications config
    builder
      .addCase(notificationGetNotificationConfig.pending, (state) => ({
        ...state,
        notificationGetNotificationConfigLoading: true,
      }))
      .addCase(notificationGetNotificationConfig.fulfilled, (state) => ({
        ...state,
        notificationGetNotificationConfigLoading: false,
      }))

    // update notifications config
    builder
      .addCase(notificationUpdateNotificationConfig.pending, (state) => ({
        ...state,
        notificationUpdateNotificationConfigLoading: true,
      }))
      .addCase(notificationUpdateNotificationConfig.fulfilled, (state) => ({
        ...state,
        notificationUpdateNotificationConfigLoading: false,
      }))

    // read notifications
    builder
      .addCase(notificationReadNotifications.pending, (state) => ({
        ...state,
        notificationReadNotificationsLoading: true,
      }))
      .addCase(notificationReadNotifications.fulfilled, (state) => ({
        ...state,
        notificationReadNotificationsLoading: false,
      }))
  },
})

export const {
  notificationResetState,

  notificationSetNotifications,
  notificationResetNotifications,
  notificationSetNotificationsUnreadCount,
  notificationSetNotification,
  notificationSetNotificationTipRetried,
  notificationSetNotificationUnlockRetried,
  notificationResetNotification,
  notificationSetNotificationConfig,
  notificationSetNotificationsFilter,
} = notificationSlice.actions

export const notificationSelectNotifications = (state: RootState) =>
  state.notification.notifications
export const notificationSelectNotificationsLength = (state: RootState) =>
  state.notification.notifications.length
export const notificationSelectNotificationsCount = (state: RootState) =>
  state.notification.notificationsCount
export const notificationSelectNotificationsFetched = (state: RootState) =>
  state.notification.notificationsFetched

export const notificationSelectNotificationsUnreadCount = (state: RootState) =>
  state.notification.notificationsUnreadCount

export const notificationSelectNotification = (state: RootState) =>
  state.notification.notification
export const notificationSelectNotificationFetched = (state: RootState) =>
  state.notification.notificationFetched
export const notificationSelectNotificationTipRetried = (state: RootState) =>
  state.notification.notificationTipRetried
export const notificationSelectNotificationUnlockRetried = (state: RootState) =>
  state.notification.notificationUnlockRetried

export const notificationSelectNotificationConfig = (state: RootState) =>
  state.notification.notificationConfig
export const notificationSelectNotificationConfigFetched = (state: RootState) =>
  state.notification.notificationConfigFetched

export const notificationSelectNotificationsFilter = (state: RootState) =>
  state.notification.notificationsFilter

export const notificationSelectNotificationGetNotificationsLoading = (state: RootState) =>
  state.notification.notificationGetNotificationsLoading
export const notificationSelectNotificationGetNotificationsUnreadCountLoading = (
  state: RootState,
) => state.notification.notificationGetNotificationsUnreadCountLoading
export const notificationSelectNotificationGetNotificationLoading = (state: RootState) =>
  state.notification.notificationGetNotificationLoading
export const notificationSelectNotificationGetNotificationConfigLoading = (
  state: RootState,
) => state.notification.notificationGetNotificationConfigLoading
export const notificationSelectNotificationUpdateNotificationConfigLoading = (
  state: RootState,
) => state.notification.notificationUpdateNotificationConfigLoading
export const notificationSelectNotificationReadNotificationsLoading = (
  state: RootState,
) => state.notification.notificationReadNotificationsLoading

export const notificationReducer = notificationSlice.reducer

// export default notificationSlice.reducer

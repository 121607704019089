import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { userSelectUserBlockModalOpen, userCloseUserBlockModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { UserBlockCard } from './components'
// styles
import { styles } from './styles'

type UserBlockModalProps = {}

export const UserBlockModal: FC<UserBlockModalProps> = () => {
  const dispatch = useAppDispatch()
  const userBlockModalOpen = useAppSelector(userSelectUserBlockModalOpen)

  return (
    <div css={styles().modal.main}>
      {userBlockModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(userCloseUserBlockModal())}
        >
          <div css={styles().modal.content}>
            <UserBlockCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

UserBlockModal.propTypes = {}

import { css, keyframes, SerializedStyles } from '@emotion/react'
import type { Theme } from '@emotion/react'

export enum ThemeName {
  Dark = 'Dark',
  Light = 'Light',
}

export const constants = {
  app: {
    container: {
      small: '1140px',
      large: '1440px',
    },
    header: {
      desktop: {
        height: '120px',
      },
      mobile: {
        height: '80px',
      },
    },
    player: {
      desktop: {
        height: '100px',
      },
      mobile: {
        height: '80px',
      },
    },
    navigation: {
      desktop: {
        width: '280px',
        height: '100vh',
      },
    },
    content: {
      desktop: {
        padding: '42px',
      },
      mobile: {
        padding: '22px',
      },
    },
  },
  library: {
    navigation: {
      desktop: {
        height: '64px',
        margin: {
          bottom: '30px',
        },
      },
      mobile: {
        height: '64px',
        margin: {
          bottom: '30px',
        },
      },
    },
  },
}

export const mq = {
  mobile: (style: SerializedStyles): SerializedStyles =>
    css`
      @media only screen and (max-width: 26.563em) {
        ${style}
      }
    `,
  tablet: (style: SerializedStyles): SerializedStyles =>
    css`
      @media only screen and (max-width: 48em) {
        ${style}
      }
    `,
  laptop: (style: SerializedStyles): SerializedStyles =>
    css`
      @media only screen and (max-width: 64em) {
        ${style}
      }
    `,
  desktop: (style: SerializedStyles): SerializedStyles =>
    css`
      @media only screen and (max-width: 90em) {
        ${style}
      }
    `,
}

export const helpers = {
  resetDefaults: css`
    border: none;
    outline: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  `,
  image: css`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  // requires fixed width { width: 170px; } | or parent with { display: flex; min-width: 0; }
  textOverflowEllipsis: css`
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  hideScrollbar: css`
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  `,
  app: {
    header: {
      width: css`
        // 280px represents navigation width
        width: calc(100vw - ${constants.app.navigation.desktop.width});

        ${mq.tablet(css`
          width: 100vw;
        `)}
      `,
      height: css`
        height: ${constants.app.header.desktop.height};

        ${mq.mobile(css`
          height: ${constants.app.header.mobile.height};
        `)}
      `,
    },
    player: {
      width: css`
        // 280px represents navigation width
        width: calc(100vw - ${constants.app.navigation.desktop.width});

        ${mq.tablet(css`
          width: 100vw;
        `)}
      `,
      height: css`
        height: ${constants.app.player.desktop.height};

        ${mq.mobile(css`
          height: ${constants.app.player.mobile.height};
        `)}
      `,
    },
    playlist: {
      width: css`
        // 280px represents navigation width
        width: calc(100vw - ${constants.app.navigation.desktop.width});

        ${mq.tablet(css`
          width: 100vw;
        `)}
      `,
      height: css`
        height: calc(
          100vh -
            (
              ${constants.app.header.desktop.height} +
                ${constants.app.player.desktop.height}
            )
        );

        ${mq.mobile(css`
          height: calc(
            100vh -
              (
                ${constants.app.header.mobile.height} +
                  ${constants.app.player.mobile.height}
              )
          );
        `)}
      `,
    },
    screen: {
      width: {
        full: css`
          // 280px represents navigation width
          // 42px*2 represents desktop padding left and right
          width: calc(
            100vw - (${constants.app.content.desktop.padding}*2) -
              (${constants.app.navigation.desktop.width})
          );

          ${mq.tablet(css`
            // 42px represents desktop padding left and right
            width: calc(100vw - (${constants.app.content.desktop.padding}*2));
          `)}

          ${mq.mobile(css`
            // 22px represents mobile padding left and right
            width: calc(100vw - (${constants.app.content.mobile.padding}*2));
          `)}
        `,
        content: css`
          // 280px represents navigation width
          // 42px represents desktop padding left
          width: calc(
            100vw -
              (
                ${constants.app.navigation.desktop.width} +
                  ${constants.app.content.desktop.padding}
              )
          );

          ${mq.tablet(css`
            // 42px represents desktop padding left
            width: calc(100vw - ${constants.app.content.desktop.padding});
          `)}

          ${mq.mobile(css`
            // 22px represents mobile padding left
            width: calc(100vw - ${constants.app.content.mobile.padding});
          `)}
        `,
      },
      height: {
        full: css`
          // 120px represents header height
          // 100px represents player height
          height: calc(
            100vh -
              (
                ${constants.app.header.desktop.height} +
                  ${constants.app.player.desktop.height}
              )
          );

          // 80px represents header height
          // 80px represents player height
          ${mq.mobile(css`
            height: calc(
              100vh -
                (
                  ${constants.app.header.mobile.height} +
                    ${constants.app.player.mobile.height}
                )
            );
          `)}
        `,
        home: css`
          // 120px represents header height
          height: calc(100vh - ${constants.app.header.desktop.height});

          // 80px represents header height
          ${mq.mobile(css`
            height: calc(100vh - ${constants.app.header.mobile.height});
          `)}
        `,
        library: css`
          // 120px represents header height
          // 100px represents player height
          // 64px represents library navigation height
          // 30px represents library navigation margin bottom
          height: calc(
            100vh -
              (
                ${constants.app.header.desktop.height} +
                  ${constants.app.player.desktop.height} +
                  ${constants.library.navigation.desktop.height} +
                  ${constants.library.navigation.desktop.margin.bottom}
              )
          );

          // 80px represents header height
          // 80px represents player height
          // 64px represents library navigation height
          // 30px represents library navigation margin bottom
          ${mq.mobile(css`
            height: calc(
              100vh -
                (
                  ${constants.app.header.mobile.height} +
                    ${constants.app.player.mobile.height} +
                    ${constants.library.navigation.mobile.height}+
                    ${constants.library.navigation.mobile.margin.bottom}
                )
            );
          `)}
        `,
      },
    },
    padding: {
      full: css`
        padding: ${constants.app.content.desktop.padding};

        ${mq.mobile(css`
          padding: ${constants.app.content.mobile.padding};
        `)}
      `,
      sides: css`
        padding: 0px ${constants.app.content.desktop.padding};

        ${mq.mobile(css`
          padding: 0px ${constants.app.content.mobile.padding};
        `)}
      `,
      content: css`
        padding-left: ${constants.app.content.desktop.padding};

        ${mq.mobile(css`
          padding-left: ${constants.app.content.mobile.padding};
        `)}
      `,
    },
  },
  library: {
    header: {
      width: css`
        // 280px represents navigation width
        width: calc(100vw - ${constants.app.navigation.desktop.width});

        ${mq.tablet(css`
          width: 100vw;
        `)}
      `,

      height: css`
        height: calc(
          ${constants.library.navigation.desktop.height} +
            ${constants.library.navigation.desktop.margin.bottom}
        );

        ${mq.mobile(css`
          height: calc(
            ${constants.library.navigation.mobile.height} +
              ${constants.library.navigation.mobile.margin.bottom}
          );
        `)}
      `,
    },
  },
}

export const fonts = {
  primary: 'Cera Pro',
}

export const typography = {
  h1: '6.1rem',
  h2: '4.9rem',
  h3: '3.9rem',
  h4: '3.1rem',
  h5: '2.5rem',
  h6: '2.0rem',
  p: '1.6rem',
  sp: '1.3rem',
  c: '1rem',
}

// animation recap
// duration - function - delay - count - direction - fill-mode - play-state - name
// animation: 3s ease-in 1s 2 reverse both paused fadeIn;

export const kf = {
  slideIn: {
    up: (px: number) => keyframes`
      from {
        transform: translateY(${px}px);
        opacity: 0;
      }
      to {
        transform: transformY(0px);
        opacity: 1;
      }
    `,
    down: (px: number) => keyframes`
      from {
        transform: translateY(-${px}px);
        opacity: 0;
      }
      to {
        transform: transformY(0px);
        opacity: 1;
      }
    `,
    left: (px: number) => keyframes`
      from {
        transform: translateX(${px}px);
        opacity: 0;
      }
      to {
        transform: transformX(0px);
        opacity: 1;
      }
    `,
    right: (px: number) => keyframes`
      from {
        transform: translateX(-${px}px);
        opacity: 0;
      }
      to {
        transform: transformX(0px);
        opacity: 1;
      }
    `,
  },
  load: {
    // width 85 height 50
    bars: (width: number, height: number) => {
      const baseWidth = width / 8
      const minHeight = 3
      const quarterHight = height / 4
      const halfHeight = height / 2

      return keyframes`
        0% {
          background-size: ${baseWidth}px ${minHeight}px;
        }
        16% {
          background-size: ${baseWidth}px ${height}px, ${baseWidth}px ${minHeight}px,
            ${baseWidth}px ${minHeight}px, ${baseWidth}px ${minHeight}px,
            ${baseWidth}px ${minHeight}px, ${baseWidth}px ${minHeight}px;
        }
        33% {
          background-size: ${baseWidth}px ${halfHeight}px, ${baseWidth}px ${height}px,
            ${baseWidth}px ${minHeight}px, ${baseWidth}px ${minHeight}px,
            ${baseWidth}px ${minHeight}px, ${baseWidth}px ${minHeight}px;
        }
        50% {
          background-size: ${baseWidth}px ${quarterHight}px,
            ${baseWidth}px ${halfHeight}px, ${baseWidth}px ${height}px,
            ${baseWidth}px ${minHeight}px, ${baseWidth}px ${minHeight}px,
            ${baseWidth}px ${minHeight}px;
        }
        66% {
          background-size: ${baseWidth}px ${minHeight}px, ${baseWidth}px ${quarterHight}px,
            ${baseWidth}px ${halfHeight}px, ${baseWidth}px ${height}px,
            ${baseWidth}px ${minHeight}px, ${baseWidth}px ${minHeight}px;
        }
        83% {
          background-size: ${baseWidth}px ${minHeight}px, ${baseWidth}px ${minHeight}px,
            ${baseWidth}px ${quarterHight}px, ${baseWidth}px ${halfHeight}px,
            ${baseWidth}px ${height}px, ${baseWidth}px ${minHeight}px;
        }
        100% {
          background-size: ${baseWidth}px ${minHeight}px, ${baseWidth}px ${minHeight}px,
            ${baseWidth}px ${minHeight}px, ${baseWidth}px ${quarterHight}px,
            ${baseWidth}px ${halfHeight}px, ${baseWidth}px ${height}px;
        }
      `
    },
    shine: (left: number, top: number) => keyframes`
        to {
          background-position: right ${left}px top ${top}px;
        }
    `,
  },
}

export const z = {
  app: {
    navigation: {
      mobile: '999',
    },
    header: {
      menu: '50',
    },
    modal: '777',
  },
  alert: {
    modal: '888',
  },
  onboarding: {
    backward: '10',
    stepper: {
      progress: '2',
      circle: '4',
    },
    followArtists: {
      overlay: '2',
    },
  },
  library: {
    navigation: {
      arrows: '5',
    },
  },
  components: {
    elements: {
      inputLine: {
        icon: '1',
      },
    },
  },
  features: {
    track: {
      card: {
        overlay: '2',
        menu: '3',
      },
      tile: {
        overlay: '2',
        menu: '3',
      },
    },
    post: {
      card: {
        header: '1',
        footer: '1',
        overlay: '2',
      },
    },
    user: {
      card: {
        selectedGenre: {
          close: '1',
        },
      },
    },
    audio: {
      player: '20',
      playlist: '15',
    },
  },
}

export const getTheme = (name: ThemeName): Theme =>
  name === ThemeName.Dark
    ? {
        colors: {
          primary: {
            light: '#e7e7e7', // rgb(231, 231, 231)
            lightHover: '#dbdbdb', // rgb(219, 219, 219)
            lightActive: '#b5b5b5', // rgb(181, 181, 181)
            normal: '#0f0f0f', // rgb(15, 15, 15)
            normalHover: '#0e0e0e', // rgb(14, 14, 14)
            normalActive: '#0c0c0c', // rgb(12, 12, 12)
            dark: '#0b0b0b', // rgb(11, 11, 11)
            darkHover: '#090909', // rgb(9, 9, 9)
            darkActive: '#070707', // rgb(7, 7, 7)
            darker: '#050505', // rgb(5, 5, 5)
          },
          secondary: {
            light: '#fefefe', // rgb(254, 254, 254)
            lightHover: '#fdfdfd', // rgb(253, 253, 253)
            lightActive: '#fbfbfb', // rgb(251, 251, 251)
            normal: '#f2f2f2', // rgb(242, 242, 242)
            normalHover: '#dadada', // rgb(218, 218, 218)
            normalActive: '#c2c2c2', // rgb(194, 194, 194)
            dark: '#b6b6b6', // rgb(182, 182, 182)
            darkHover: '#919191', // rgb(145, 145, 145)
            darkActive: '#6d6d6d', // rgb(109, 109, 109)
            darker: '#555555', // rgb(85, 85, 85)
          },
          accent: {
            light: '#fefaed', // rgb(254, 250, 237)
            lightHover: '#fdf7e4', // rgb(253, 247, 228)
            lightActive: '#fbeec8', // rgb(251, 238, 200)
            normal: '#f2c94c', // rgb(242, 201, 76)
            normalHover: '#dab544', // rgb(218, 181, 68)
            normalActive: '#c2a13d', // rgb(194, 161, 61)
            dark: '#b69739', // rgb(182, 151, 57)+
            darkHover: '#91792e', // rgb(145, 121, 46)
            darkActive: '#6d5a22', // rgb(109, 90, 34)
            darker: '#55461b', // rgb(85, 70, 27)
          },
          positive: {
            light: '#e6f4ee', // rgb(230, 244, 238)
            lightHover: '#d9efe5', // rgb(217, 239, 229)
            lightActive: '#b0dec9', // rgb(176, 222, 201)
            normal: '#009452', // rgb(0, 148, 82)
            normalHover: '#00854a', // rgb(0, 133, 74)
            normalActive: '#007642', // rgb(0, 118, 66)
            dark: '#006f3e', // rgb(0, 111, 62)
            darkHover: '#005931', // rgb(0, 89, 49)
            darkActive: '#004325', // rgb(0, 67, 37)
            darker: '#00341d', // rgb(0, 52, 29)
          },
          negative: {
            light: '#ffebe8', // rgb(255, 235, 232)
            lightHover: '#ffe2dd', // rgb(255, 226, 221)
            lightActive: '#fec2b8', // rgb(254, 194, 184)
            normal: '#fc3b1b', // rgb(252, 59, 27)
            normalHover: '#e33518', // rgb(227, 53, 24)
            normalActive: '#ca2f16', // rgb(202, 47, 22)
            dark: '#bd2c14', // rgb(189, 44, 20)
            darkHover: '#972310', // rgb(151, 35, 16)
            darkActive: '#711b0c', // rgb(113, 27, 12)
            darker: '#581509', // rgb(88, 21, 9)
          },
        },
      }
    : {
        colors: {
          primary: {
            light: '#fefefe', // rgb(254, 254, 254)
            lightHover: '#fdfdfd', // rgb(253, 253, 253)
            lightActive: '#fbfbfb', // rgb(251, 251, 251)
            normal: '#f2f2f2', // rgb(242, 242, 242)
            normalHover: '#dadada', // rgb(218, 218, 218)
            normalActive: '#c2c2c2', // rgb(194, 194, 194)
            dark: '#b6b6b6', // rgb(182, 182, 182)
            darkHover: '#919191', // rgb(145, 145, 145)
            darkActive: '#6d6d6d', // rgb(109, 109, 109)
            darker: '#555555', // rgb(85, 85, 85)
          },
          secondary: {
            light: '#e7e7e7', // rgb(231, 231, 231)
            lightHover: '#dbdbdb', // rgb(219, 219, 219)
            lightActive: '#b5b5b5', // rgb(181, 181, 181)
            normal: '#0f0f0f', // rgb(15, 15, 15)
            normalHover: '#0e0e0e', // rgb(14, 14, 14)
            normalActive: '#0c0c0c', // rgb(12, 12, 12)
            dark: '#0b0b0b', // rgb(11, 11, 11)
            darkHover: '#090909', // rgb(9, 9, 9)
            darkActive: '#070707', // rgb(7, 7, 7)
            darker: '#050505', // rgb(5, 5, 5)
          },
          accent: {
            light: '#f3edfc', // rgb(243, 237, 252)
            lightHover: '#ede4fb', // rgb(237, 228, 251)
            lightActive: '#d9c7f7', // rgb(217, 199, 247)
            normal: '#864be5', // rgb(134, 75, 229)
            normalHover: '#7944ce', // rgb(121, 68, 206)
            normalActive: '#6b3cb7', // rgb(107, 60, 183)
            dark: '#6538ac', // rgb(101, 56, 172)
            darkHover: '#502d89', // rgb(80, 45, 137)
            darkActive: '#3c2267', // rgb(60, 34, 103)
            darker: '#2f1a50', // rgb(47, 26, 80)
          },
          positive: {
            light: '#e6f1e6', // rgb(230, 241, 230)
            lightHover: '#daead9', // rgb(218, 234, 217)
            lightActive: '#b2d4b1', // rgb(178, 212, 177)
            normal: '#077502', // rgb(7, 117, 2)
            normalHover: '#066902', // rgb(6, 105, 2)
            normalActive: '#065e02', // rgb(6, 94, 2)
            dark: '#055802', // rgb(5, 88, 2)
            darkHover: '#044601', // rgb(4, 70, 1)
            darkActive: '#033501', // rgb(3, 53, 1)
            darker: '#022901', // rgb(2, 41, 1)
          },
          negative: {
            light: '#f9e9e6', // rgb(249, 233, 230)
            lightHover: '#f6ddd9', // rgb(246, 221, 217)
            lightActive: '#ebb9b1', // rgb(235, 185, 177)
            normal: '#c01e04', // rgb(192, 30, 4)
            normalHover: '#ad1b04', // rgb(173, 27, 4)
            normalActive: '#9a1803', // rgb(154, 24, 3)
            dark: '#901703', // rgb(144, 23, 3)
            darkHover: '#731202', // rgb(115, 18, 2)
            darkActive: '#560d02', // rgb(86, 13, 2)
            darker: '#430b01', // rgb(67, 11, 1)
          },
        },
      }

export const getGlobal = (name: ThemeName) => css`
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  // 100% / 16px * 10px = 1rem = 62.5% 10px
  // 100% / 16px * 9px = 1rem = 56.25% 9px
  // 100% / 16px * 8px = 1rem = 50% 8px

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    background-color: ${getTheme(name).colors.primary.normal};
    overflow: hidden;

    @media only screen and (max-width: 48em) {
      font-size: 56.25%;
    }

    @media only screen and (max-width: 26.563em) {
      font-size: 50%;
    }
  }

  body {
    font-family: ${fonts.primary}, sans-serif, -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${typography.p};
    font-weight: 400;
    line-height: 1.4;
    color: ${getTheme(name).colors.secondary.normal};
  }

  #app {
    position: relative;
  }

  ::selection {
    background: ${getTheme(name).colors.accent.normal};
    color: ${getTheme(name).colors.primary.normal};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
`

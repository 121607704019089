import { FC, ElementType } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type ContentPlaceholderProps = {
  title: string
  height: string
  icon?: ElementType
}

export const ContentPlaceholder: FC<ContentPlaceholderProps> = ({
  title,
  height,
  icon: Icon = undefined,
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).placeholder.main(height)}>
      {Icon && (
        <div css={styles(theme).placeholder.icon}>
          <Icon />
        </div>
      )}
      <div css={styles(theme).placeholder.title}>{title}</div>
    </div>
  )
}

ContentPlaceholder.propTypes = {}

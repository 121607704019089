import { Provider } from 'react-redux'

// store
import { store } from '@/stores'

type ReduxProviderProps = {
  children: React.ReactNode
}

export const ReduxProvider = ({ children }: ReduxProviderProps) => (
  <Provider store={store}>{children}</Provider>
)

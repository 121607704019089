import { FC } from 'react'

// features
import { monetizationFeatures } from './features'
// components
import { Feature } from './components'
// styles
import { styles } from './styles'

type FeaturesProps = {}

export const Features: FC<FeaturesProps> = () => (
  <div css={styles().features.main}>
    {monetizationFeatures.map((feature) => (
      <Feature key={feature.id} {...feature} />
    ))}
  </div>
)

Features.propTypes = {}

import { FC, useState, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityUserView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile } from '@/hooks'
// icon
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type ArtistCardProductProps = {
  artist: EntityUserView
  onNameClick: MouseEventHandler<HTMLButtonElement>
  onUsernameClick: MouseEventHandler<HTMLButtonElement>
}

export const ArtistCardProduct: FC<ArtistCardProductProps> = ({
  artist,
  onNameClick,
  onUsernameClick,
}) => {
  const theme = useTheme()
  const [avatarLoadError, setAvatarLoadError] = useState(false)

  const { avatarId, name, username } = artist

  const artistHasAvatar = !!avatarId
  const source = useFile(avatarId || '')

  return (
    <div css={styles(theme).artist.main}>
      <div css={styles(theme).artist.avatar.main}>
        {artistHasAvatar && !avatarLoadError ? (
          <img
            css={styles(theme).artist.avatar.image}
            onError={() => setAvatarLoadError(true)}
            loading="lazy"
            width="100px"
            height="100px"
            src={source}
            alt="artist avatar"
          />
        ) : (
          <icons.User css={styles(theme).artist.avatar.icon} />
        )}
      </div>
      <div css={styles(theme).artist.info.main}>
        <button
          css={styles(theme).artist.info.name}
          onClick={(e) => onNameClick(e)}
          type="button"
          aria-label="explore artist"
        >
          {name}
        </button>
        <button
          css={styles(theme).artist.info.username}
          onClick={(e) => onUsernameClick(e)}
          type="button"
          aria-label="explore artist"
        >
          @{username}
        </button>
      </div>
    </div>
  )
}

ArtistCardProduct.propTypes = {}

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  playerAudioSelectCurrentTrack,
  playerAudioSelectCurrentTrackReady,
  playerAudioSelectCurrentTrackPlaying,
  playerAudioSelectRepeat,
  playerAudioSelectRandom,
  playerAudioToggleRepeat,
  playerAudioToggleRandom,
  playerAudioSetPrevTrack,
  playerAudioSetNextTrack,
} from '@/features'
// components
import { Loader } from '@/components'

// icons
import { icons as iconsLocal } from './assets'
// styles
import { styles } from './styles'

type ControlsProps = {
  onPauseClick: MouseEventHandler<HTMLButtonElement>
  onPlayClick: MouseEventHandler<HTMLButtonElement>
}

export const Controls: FC<ControlsProps> = ({ onPauseClick, onPlayClick }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const isAudioRepeatActive = useAppSelector(playerAudioSelectRepeat)
  const isAudioRandomActive = useAppSelector(playerAudioSelectRandom)

  const currentTrack = useAppSelector(playerAudioSelectCurrentTrack)
  const currentTrackReady = useAppSelector(playerAudioSelectCurrentTrackReady)
  const currentTrackPlaying = useAppSelector(playerAudioSelectCurrentTrackPlaying)

  const renderPlayPauseControls = () => {
    // if track is set but is not loaded
    if (currentTrack && !currentTrackReady) {
      // render loader
      return (
        <div css={styles(theme).controls.play.main}>
          <Loader width={30} height={30} appearance="secondary" />
        </div>
      )
    }

    // if track is playing - render pause button
    if (currentTrack && currentTrackPlaying) {
      return (
        <div css={styles(theme).controls.pause.main}>
          <button
            css={styles(theme).controls.pause.button}
            onClick={(e) => onPauseClick(e)}
            disabled={!currentTrackReady}
            type="button"
            aria-label="pause audio"
          >
            <iconsLocal.AudioPause />
          </button>
        </div>
      )
    }

    // else render play button
    return (
      <div css={styles(theme).controls.play.main}>
        <button
          css={styles(theme).controls.play.button}
          onClick={(e) => onPlayClick(e)}
          disabled={!currentTrackReady}
          type="button"
          aria-label="play audio"
        >
          <iconsLocal.AudioPlay />
        </button>
      </div>
    )
  }

  return (
    <div css={styles(theme).controls.main}>
      {/* repeat */}
      <div css={styles(theme).controls.repeat.main}>
        <button
          css={[
            styles(theme).controls.repeat.button.shared,
            isAudioRepeatActive
              ? styles(theme).controls.repeat.button.active
              : styles(theme).controls.repeat.button.inactive,
          ]}
          onClick={() => dispatch(playerAudioToggleRepeat())}
          type="button"
          aria-label="activate repeat audio"
        >
          <iconsLocal.AudioRepeat />
        </button>
      </div>

      {/* prev */}
      <div css={styles(theme).controls.prev.main}>
        <button
          css={styles(theme).controls.prev.button}
          onClick={() => dispatch(playerAudioSetPrevTrack())}
          type="button"
          aria-label="set previous audio"
        >
          <iconsLocal.AudioPrev />
        </button>
      </div>

      {/* play / pause */}
      {renderPlayPauseControls()}

      {/* next */}
      <div css={styles(theme).controls.next.main}>
        <button
          css={styles(theme).controls.next.button}
          onClick={() => dispatch(playerAudioSetNextTrack())}
          type="button"
          aria-label="set next audio"
        >
          <iconsLocal.AudioNext />
        </button>
      </div>

      {/* random */}
      <div css={styles(theme).controls.random.main}>
        <button
          css={[
            styles(theme).controls.random.button.shared,
            isAudioRandomActive
              ? styles(theme).controls.random.button.active
              : styles(theme).controls.random.button.inactive,
          ]}
          onClick={() => dispatch(playerAudioToggleRandom())}
          type="button"
          aria-label="activate random audio"
        >
          <iconsLocal.AudioRandom />
        </button>
      </div>
    </div>
  )
}

Controls.propTypes = {}

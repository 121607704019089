import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  nav: {
    main: css`
      display: flex;
      align-items: center;
      justify-content: flex-end;

      height: 100%;

      & > div:not(:last-of-type) {
        margin-right: 24px;
      }
    `,
    volume: {
      main: css`
        position: relative;

        width: 30px;
        height: 30px;
      `,
      button: {
        shared: css`
          ${helpers.resetDefaults};

          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 100%;

          cursor: pointer;

          & > svg {
            width: 24px;
            height: 24px;
          }
        `,
        open: css`
          & > svg {
            & path {
              fill: ${theme.colors.accent.normal};
            }
          }
        `,
        closed: css`
          & > svg {
            & path {
              fill: ${theme.colors.secondary.normal};
            }
          }
        `,
      },
      control: {
        main: css`
          position: absolute;
          right: -220%;
          top: -328%;

          background-color: ${theme.colors.primary.darker};
          padding: 8px 12px;
          border-radius: 4px;

          width: 160px;
          height: 36px;

          transform: rotate(-90deg);
        `,
        label: css`
          display: none;
        `,
        input: (volumeWidthPercent: number) => css`
          ${helpers.resetDefaults};

          width: 100%;
          height: 20px;

          // reset default style
          appearance: none;
          -webkit-appearance: none;
          background: transparent;
          cursor: pointer;

          // track style
          &::-webkit-slider-runnable-track {
            background: linear-gradient(
              to right,
              ${theme.colors.accent.normal} 0% ${volumeWidthPercent}%,
              ${theme.colors.secondary.normal} ${volumeWidthPercent}%
                ${100 - volumeWidthPercent}%
            );
            height: 4px;
          }
          &::-moz-range-track {
            background: linear-gradient(
              to right,
              ${theme.colors.accent.normal} 0% ${volumeWidthPercent}%,
              ${theme.colors.secondary.normal} ${volumeWidthPercent}%
                ${100 - volumeWidthPercent}%
            );
            height: 4px;
          }

          // thumb style
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;

            // center thumb
            // (track hight in px / 2) - (thumb height in px / 2)
            margin-top: -6px;

            height: 16px;
            width: 16px;
            border-radius: 50%;
            background-color: ${theme.colors.accent.normal};
            border: 2px solid ${theme.colors.primary.normal};
          }
          &::-moz-range-thumb {
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background-color: ${theme.colors.accent.normal};
            border: 2px solid ${theme.colors.primary.normal};
          }
        `,
      },
    },
    open: {
      main: css`
        width: 30px;
        height: 30px;
      `,
      button: {
        shared: css`
          ${helpers.resetDefaults};

          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 100%;

          cursor: pointer;

          & > svg {
            width: 24px;
            height: 24px;
          }
        `,
        open: css`
          & > svg {
            & path {
              fill: ${theme.colors.accent.normal};
            }
          }
        `,
        closed: css`
          & > svg {
            & path {
              fill: ${theme.colors.secondary.normal};
            }
          }
        `,
      },
    },
  },
})

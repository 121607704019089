import { FC } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'
// hooks
import { useAppDispatch } from '@/hooks'
// features
import { trackCloseTrackDeleteModal } from '@/features'
// components
import { CardHeaderMobile, ModalCardTitle, ModalCardSubtitle, Error } from '@/components'

// components
import { TrackDeleteForm } from './components'
// styles
import { styles } from './styles'

type TrackDeleteCardProps = {}

export const TrackDeleteCard: FC<TrackDeleteCardProps> = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const trackDeleteError = ''

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile onCloseClick={() => dispatch(trackCloseTrackDeleteModal())} />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <ModalCardTitle text="Delete Track" icon={icons.Trash} />
      </div>

      {/* subtitle */}
      <div css={styles(theme).card.subtitle}>
        <ModalCardSubtitle text="Are you sure you want to delete your track?" />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* form */}
        <div css={styles(theme).card.content.form}>
          <TrackDeleteForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={trackDeleteError} />
        </div>
      </div>
    </div>
  )
}

TrackDeleteCard.propTypes = {}

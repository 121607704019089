import { css, Theme } from '@emotion/react'
import { helpers, fonts, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  nav: {
    main: css`
      ${helpers.hideScrollbar};

      display: flex;
      align-items: center;

      width: 100%;
      height: 54px;

      scroll-behavior: smooth;
      overflow-x: scroll;
      overflow-y: hidden;

      & > div:not(:last-of-type) {
        margin-right: 20px;
      }
    `,
    option: {
      main: css`
        display: flex;
        align-items: center;
      `,
      button: {
        shared: css`
          ${helpers.resetDefaults};

          font-family: ${fonts.primary};
          font-size: ${typography.p};
          font-weight: bold;
          white-space: nowrap;

          border-radius: 20px;
          border: 1px solid transparent;
          padding: 4px 26px;

          width: 100%;
          height: 32px;

          transition: border 0.2s, color 0.2s, background-color 0.2s;
        `,
        enabled: css`
          cursor: pointer;

          color: ${theme.colors.primary.normal};
          background-color: ${theme.colors.accent.normal};
          border: 1px solid ${theme.colors.accent.normal};
        `,
        disabled: css`
          cursor: pointer;

          color: ${theme.colors.secondary.light};
          background-color: ${theme.colors.primary.normal};
          border: 1px solid ${theme.colors.accent.normal};
        `,
      },
    },
  },
})

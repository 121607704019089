import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  form: {
    main: css`
      display: flex;
      justify-content: center;
    `,
    content: {
      main: css`
        display: flex;
        flex-direction: column;

        width: 100%;
      `,

      inputs: {
        main: css`
          display: flex;
          margin-bottom: 16px;
          justify-content: center;

          ${mq.tablet(css`
            flex-direction: column;
            align-items: center;
          `)}
        `,

        cover: {
          main: css`
            flex-shrink: 0;
            margin-right: 24px;

            ${mq.tablet(css`
              margin-right: 0px;
              margin-bottom: 24px;
            `)}
          `,

          label: css`
            display: none;
          `,

          input: css`
            display: none;
          `,
        },

        track: {
          main: css`
            display: flex;
            flex-direction: column;

            width: 100%;
            max-width: 380px;

            margin-right: 24px;

            ${mq.tablet(css`
              margin-right: 0px;
              margin-bottom: 24px;
            `)}
          `,

          name: {
            main: css`
              margin-bottom: 24px;
            `,
          },

          file: {
            main: css``,
            label: css`
              display: none;
            `,

            input: css`
              display: none;
            `,
          },
        },

        upload: css`
          flex-shrink: 0;

          width: 136px;

          ${mq.mobile(css`
            width: 100%;
          `)}
        `,
      },

      error: css`
        margin-bottom: 16px;
      `,

      submit: css`
        align-self: center;

        ${mq.mobile(css`
          width: 100%;
        `)}

        & > button {
          width: 380px;

          ${mq.mobile(css`
            width: 100%;
          `)}
        }
      `,
    },
  },
})

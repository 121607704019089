import { FC, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch, useFile, useUserPublic } from '@/hooks'
// features
import {
  playerAudioSelectPlaylistLength,
  playerAudioSelectCurrentTrack,
  playerAudioSelectCurrentTrackAction,
  playerAudioSelectRepeat,
  playerAudioSelectRandom,
  playerAudioSetNextTrack,
  playerAudioSetPrevTrack,
  playerAudioSetRepeatTrack,
  playerAudioSetRandomTrack,
  playerAudioSetCurrentTrackReady,
  playerAudioSetCurrentTrackPlaying,
  playerAudioSetOpen,
  playerAudioSetClosed,
  streamCreateTrackStream,
} from '@/features'

// components
import { Controls, CurrentTrack, Navigation, Progress } from './components'
// styles
import { styles } from './styles'

type PlayerAudioProps = {}

export const PlayerAudio: FC<PlayerAudioProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isAudioRepeatActive = useAppSelector(playerAudioSelectRepeat)
  const isAudioRandomActive = useAppSelector(playerAudioSelectRandom)

  const playlistLength = useAppSelector(playerAudioSelectPlaylistLength)
  const currentTrack = useAppSelector(playerAudioSelectCurrentTrack)
  const currentTrackAction = useAppSelector(playerAudioSelectCurrentTrackAction)

  // track
  const {
    id: currentTrackId,
    name: trackName,
    coverId,
    trackId,
    userId: trackAuthorUserId,
  } = currentTrack ?? {}
  const trackHasCover = !!coverId
  const coverSrc = useFile(coverId || '')
  const audioSrc = useFile(trackId || '')

  // track author
  const { user: trackAuthor, isLoading: isTrackAuthorLoading } = useUserPublic(
    trackAuthorUserId || '',
  )
  const { name: authorName } = trackAuthor ?? {}
  const userLoadingPlaceholder = 'Loading...'
  const userNamePlaceholder = '-'

  const trackAuthorName = isTrackAuthorLoading
    ? userLoadingPlaceholder
    : authorName || userNamePlaceholder

  // refs
  const audioRef = useRef<HTMLAudioElement | null>(null)

  // audio states
  const [audioDuration, setAudioDuration] = useState(0)
  const [audioProgress, setAudioProgress] = useState(0)
  const [audioBuffered, setAudioBuffered] = useState(0)

  // control states
  const [audioVolume, setAudioVolume] = useState(1)

  // on player touch states
  const [touchStartPosition, setTouchStartPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    // if media session present
    if ('mediaSession' in navigator) {
      // create media session metadata
      navigator.mediaSession.metadata = new MediaMetadata({
        title: trackName,
        artist: trackAuthorName,
        album: '-',
        artwork: trackHasCover
          ? [{ src: coverSrc, sizes: '96x96', type: 'image/webp' }]
          : undefined,
      })

      navigator.mediaSession.setActionHandler('play', () => {
        // play functionality
        if (audioRef.current) {
          audioRef.current.play()
        }
      })
      navigator.mediaSession.setActionHandler('pause', () => {
        // pause functionality
        if (audioRef.current) {
          audioRef.current.pause()
        }
      })
      navigator.mediaSession.setActionHandler('previoustrack', () => {
        // prev functionality
        dispatch(playerAudioSetPrevTrack())
      })
      navigator.mediaSession.setActionHandler('nexttrack', () => {
        // next functionality
        dispatch(playerAudioSetNextTrack())
      })
    }

    return () => {
      // cleanup
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentTrack])

  const handleBufferProgress = (
    currentTime: number,
    duration: number,
    buffered: TimeRanges,
  ) => {
    // if duration is greater than 0
    if (duration > 0) {
      // iterate from 0 to buffered length
      for (let i = 0; i < buffered.length; i += 1) {
        // if start time of the buffered range at the current index
        // is less than the current playback time
        if (buffered.start(buffered.length - 1 - i) < currentTime) {
          // calculate the end time of buffered range
          const bufferedLength = buffered.end(buffered.length - 1 - i)
          setAudioBuffered(bufferedLength)
          break
        }
      }
    }
  }

  return (
    /* eslint-disable-next-line jsx-a11y/media-has-caption */
    <div
      css={styles(theme).player.main}
      onTouchStart={(e) => {
        const { clientX, clientY } = e.changedTouches[0]
        setTouchStartPosition({ x: clientX, y: clientY })
      }}
      onTouchEnd={(e) => {
        const { clientY } = e.changedTouches[0]

        // calculate touch distance
        const touchDistance = touchStartPosition.y - clientY
        // set min touch threshold
        const minTouchThreshold = 100

        // if touch distance is greater or equal min threshold
        if (touchDistance >= minTouchThreshold) {
          dispatch(playerAudioSetOpen())
        }
      }}
    >
      {/* audio */}
      {currentTrack && (
        /* eslint-disable-next-line jsx-a11y/media-has-caption */
        <audio
          key={`${currentTrackId}-${currentTrackAction}`}
          ref={audioRef}
          preload="metadata"
          // controls
          autoPlay
          onDurationChange={(e) => setAudioDuration(e.currentTarget.duration)}
          onCanPlay={(e) => {
            dispatch(playerAudioSetCurrentTrackReady({ state: true }))
            e.currentTarget.volume = audioVolume
          }}
          onPlaying={() => dispatch(playerAudioSetCurrentTrackPlaying({ state: true }))}
          onPause={() => dispatch(playerAudioSetCurrentTrackPlaying({ state: false }))}
          onEnded={() => {
            // create track stream
            dispatch(streamCreateTrackStream({ track: currentTrack }))

            // if repeat active
            if (isAudioRepeatActive) {
              // repeat current song
              dispatch(playerAudioSetRepeatTrack())
              return
            }

            // if random active
            if (isAudioRandomActive) {
              // play random song
              dispatch(playerAudioSetRandomTrack())
              return
            }

            // if playlist length is greater than 1
            if (playlistLength > 1) {
              // play next track
              dispatch(playerAudioSetNextTrack())
            }
          }}
          onTimeUpdate={(e) => {
            const { currentTime, duration, buffered } = e.currentTarget
            setAudioProgress(currentTime)
            handleBufferProgress(currentTime, duration, buffered)
          }}
          onProgress={(e) => {
            const { currentTime, duration, buffered } = e.currentTarget
            handleBufferProgress(currentTime, duration, buffered)
          }}
        >
          <source type="audio/mpeg" src={audioSrc} />
        </audio>
      )}

      {/* progress */}
      <div css={styles(theme).player.progress.main}>
        <Progress
          duration={audioDuration}
          progress={audioProgress}
          buffered={audioBuffered}
          onChange={(e) => {
            // get current progress value
            const progressValue = e.currentTarget.valueAsNumber

            // if audio ref
            if (audioRef.current) {
              // update progress value on audio ref
              audioRef.current.currentTime = progressValue
            }

            // update progress value state
            setAudioProgress(progressValue)
          }}
        />
      </div>

      {/* panel */}
      <div css={styles(theme).player.panel.main}>
        {/* audio */}
        <div css={styles(theme).player.panel.audio.main}>
          {currentTrack && (
            <CurrentTrack
              track={currentTrack}
              trackAuthorName={trackAuthorName}
              progress={audioProgress}
              duration={audioDuration}
              onAuthorNameClick={() => {
                navigate(`${Path.User}/${trackAuthorUserId}`)
                dispatch(playerAudioSetClosed())
              }}
            />
          )}
        </div>

        {/* controls */}
        <div css={styles(theme).player.panel.controls.main}>
          <Controls
            onPauseClick={() => {
              // if audio ref
              if (audioRef.current) {
                // pause audio
                audioRef.current.pause()
              }
            }}
            onPlayClick={() => {
              // if audio ref
              if (audioRef.current) {
                // play audio
                audioRef.current.play()
              }
            }}
          />
        </div>

        {/* navigation */}
        <div css={styles(theme).player.panel.nav.main}>
          <Navigation
            volume={audioVolume}
            onVolumeChange={(e) => {
              // get current volume value
              const volumeValue = e.currentTarget.valueAsNumber

              // if audio ref
              if (audioRef.current) {
                // update volume value on audio ref
                audioRef.current.volume = volumeValue
              }

              // update volume value state
              setAudioVolume(volumeValue)
            }}
          />
        </div>
      </div>
    </div>
  )
}

PlayerAudio.propTypes = {}

// media loading process

// loadstart - media loading has started and the browser is connecting to the media
// durationchange - fires when the duration of the media is already available
// loadedmetadata - fires when all media metadata has been loaded
// loadeddata - this event is fired when the first bit of media arrives
// progress - the event indicating that media downloading is still in progress
// canplay - fires when the media is ready to be played
// canplaythrough - this event lets us know that the media can be played all the way through

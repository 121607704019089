import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // user
  ArtistCardProduct,
  // subscription
  subscriptionSelectSubscriptionCancelSubscriptionLoading,
  subscriptionSelectSubscriptionCancelModalArtist,
  subscriptionCancelSubscription,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type SubscriptionCancelFormProps = {}

export const SubscriptionCancelForm: FC<SubscriptionCancelFormProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const subscriptionCancelLoading = useAppSelector(
    subscriptionSelectSubscriptionCancelSubscriptionLoading,
  )
  const profile = useAppSelector(authSelectProfile)
  const modalArtist = useAppSelector(subscriptionSelectSubscriptionCancelModalArtist)

  const { id: profileUserId } = profile ?? {}
  const { id: artistUserId } = modalArtist ?? {}

  const isCurrentUser = profileUserId === artistUserId
  const submitDisabled = isCurrentUser || subscriptionCancelLoading

  return (
    <div css={styles(theme).form.main}>
      <form
        css={styles(theme).form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(subscriptionCancelSubscription())
        }}
      >
        {/* user product */}
        <div css={styles(theme).form.content.product}>
          <ArtistCardProduct
            artist={modalArtist || {}}
            onNameClick={() => navigate(`${Path.User}/${artistUserId}`)}
            onUsernameClick={() => navigate(`${Path.User}/${artistUserId}`)}
          />
        </div>

        {/* description */}
        <div css={styles(theme).form.content.description.main}>
          <div css={styles(theme).form.content.description.text}>
            If you cancel your subscription, you will still have access to the content
            until the end of the current subscription period.
          </div>
        </div>

        {/* submit */}
        <div css={styles(theme).form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="unsubscribe"
            disabled={submitDisabled}
            appearance="primary"
          >
            {subscriptionCancelLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Unsubscribe`
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

SubscriptionCancelForm.propTypes = {}

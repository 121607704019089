import { FC, ReactNode, useState, useEffect, useRef, ElementType } from 'react'
import { useTheme, css, SerializedStyles } from '@emotion/react'
import PropTypes from 'prop-types'

// components
import { NavigationButton } from './components'
// icons
import { icons } from './assets'
// styles
import { styles } from './styles'

type ContentLayoutVirtualizedProps = {
  title: string // content title
  icon?: ElementType // content icon
  children: ReactNode // list of elements
  itemWidth: number // element width
  itemsCount: number // elements count

  // css override
  overrideCss?: {
    main: SerializedStyles
    header: {
      main: SerializedStyles
      title: {
        main: SerializedStyles
        text: SerializedStyles
        icon: SerializedStyles
      }
    }
    navigation: {
      main: SerializedStyles
      button: SerializedStyles
      icon: SerializedStyles
    }
    content: {
      main: SerializedStyles
    }
    container: {
      main: SerializedStyles
    }
  }
}

export const ContentLayoutVirtualized: FC<ContentLayoutVirtualizedProps> = ({
  title,
  icon: Icon = undefined,
  children,
  itemWidth,
  itemsCount,

  overrideCss = {
    main: css``,
    header: {
      main: css``,
      title: {
        main: css``,
        text: css``,
        icon: css``,
      },
    },
    navigation: {
      main: css``,
      button: css``,
      icon: css``,
    },
    content: {
      main: css``,
    },
    container: {
      main: css``,
    },
  },
}) => {
  const theme = useTheme()

  // content navigation
  const [backwardDisabled, setBackwardDisabled] = useState(true)
  const [forwardDisabled, setForwardDisabled] = useState(true)

  const containerRef = useRef<HTMLDivElement | null>(null)

  // configure initial navigation state
  useEffect(() => {
    // if items count is 0
    if (itemsCount === 0) {
      // disable backward and forward scrolls
      setBackwardDisabled(true)
      setForwardDisabled(true)

      return
    }

    // if list node found
    if (containerRef.current?.children.length) {
      // get list node
      const listNode = containerRef.current.children[0]
      // get list node client width, scroll width, and scroll offset
      // clientWidth - currently visible content
      // scrollWidth - total width of the element
      // scrollLeft  - number of pixels scrolled
      const { clientWidth, scrollWidth, scrollLeft } = listNode

      const lastScrollPosition = scrollWidth - clientWidth

      if (scrollLeft <= 0) {
        setBackwardDisabled(true)
      } else {
        setBackwardDisabled(false)
      }

      if (scrollLeft >= lastScrollPosition) {
        setForwardDisabled(true)
      } else {
        setForwardDisabled(false)
      }
    }
  }, [itemsCount])

  // handleBackwardClick - handles content navigation backward click
  const handleBackwardClick = () => {
    // if list node found
    if (containerRef.current?.children.length) {
      // get list node
      const listNode = containerRef.current.children[0]
      // get list node client width, scroll width, and scroll offset
      // clientWidth - currently visible content
      // scrollWidth - total width of the element
      // scrollLeft  - number of pixels scrolled
      const { clientWidth, scrollWidth, scrollLeft } = listNode

      // calculate next scroll offset
      const offset = Math.floor(clientWidth / itemWidth) * itemWidth
      // scroll for exactly n visible items
      listNode.scrollBy(-offset, 0)

      const nextScrollPosition = scrollLeft - offset
      const lastScrollPosition = scrollWidth - clientWidth

      if (nextScrollPosition <= 0) {
        setBackwardDisabled(true)
      } else {
        setBackwardDisabled(false)
      }

      if (nextScrollPosition >= lastScrollPosition) {
        setForwardDisabled(true)
      } else {
        setForwardDisabled(false)
      }
    }
  }

  // handleForwardClick - handles content navigation forward click
  const handleForwardClick = () => {
    // if list node found
    if (containerRef.current?.children.length) {
      // get list node
      const listNode = containerRef.current.children[0]
      // get list node client width, scroll width, and scroll offset
      // clientWidth - currently visible content
      // scrollWidth - total width of the element
      // scrollLeft  - number of pixels scrolled
      const { clientWidth, scrollWidth, scrollLeft } = listNode

      // calculate next scroll offset
      const offset = Math.floor(clientWidth / itemWidth) * itemWidth
      // scroll for exactly n visible items
      listNode.scrollBy(offset, 0)

      const nextScrollPosition = scrollLeft + offset
      const lastScrollPosition = scrollWidth - clientWidth

      if (nextScrollPosition <= 0) {
        setBackwardDisabled(true)
      } else {
        setBackwardDisabled(false)
      }

      if (nextScrollPosition >= lastScrollPosition) {
        setForwardDisabled(true)
      } else {
        setForwardDisabled(false)
      }
    }
  }

  return (
    <div css={[styles(theme).layout.main, overrideCss?.main]}>
      <div css={[styles(theme).layout.header.main, overrideCss?.header.main]}>
        <div
          css={[[styles(theme).layout.header.title.main, overrideCss?.header.title.main]]}
        >
          {Icon && (
            <div
              css={[
                styles(theme).layout.header.title.icon,
                overrideCss?.header.title.icon,
              ]}
            >
              <Icon />
            </div>
          )}
          <div
            css={[styles(theme).layout.header.title.text, overrideCss?.header.title.text]}
          >
            {title}
          </div>
        </div>
        <div
          css={[
            styles(theme).layout.header.navigation.main,
            overrideCss?.navigation.main,
          ]}
        >
          <NavigationButton
            css={[
              styles(theme).layout.header.navigation.button,
              overrideCss?.navigation.button,
            ]}
            onClick={() => handleBackwardClick()}
            disabled={backwardDisabled}
            aria-label="navigate backward"
          >
            <icons.ArrowLeft
              css={[
                styles(theme).layout.header.navigation.icon,
                overrideCss?.navigation.icon,
              ]}
            />
          </NavigationButton>
          <NavigationButton
            css={[
              styles(theme).layout.header.navigation.button,
              overrideCss?.navigation.button,
            ]}
            onClick={() => handleForwardClick()}
            disabled={forwardDisabled}
            aria-label="navigate forward"
          >
            <icons.ArrowRight
              css={[
                styles(theme).layout.header.navigation.icon,
                overrideCss?.navigation.icon,
              ]}
            />
          </NavigationButton>
        </div>
      </div>
      <div css={[styles(theme).layout.content.main, overrideCss?.content.main]}>
        <div
          css={[styles(theme).layout.content.container.main, overrideCss?.container.main]}
          ref={containerRef}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

ContentLayoutVirtualized.propTypes = {
  title: PropTypes.string.isRequired,
}

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type GenreProps = {
  genre: string
  onClick: MouseEventHandler<HTMLButtonElement>
  selected?: boolean
}

export const Genre: FC<GenreProps> = ({ genre, selected = false, onClick }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).genre.main}>
      <button
        css={[
          styles(theme).genre.button.shared,
          selected
            ? styles(theme).genre.button.selected
            : styles(theme).genre.button.unselected,
        ]}
        onClick={(e) => onClick(e)}
        type="button"
        aria-label="select genre"
      >
        {genre}
      </button>
    </div>
  )
}

Genre.propTypes = {}

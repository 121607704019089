import { FC, useState, useEffect, useRef } from 'react'
import { useTheme } from '@emotion/react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  navigationSelectLibraryCategories,
  navigationLibraryCategoriesToggleLikedTracks,
  navigationLibraryCategoriesToggleLikedPosts,
  navigationLibraryCategoriesToggleFollowedArtists,
  navigationLibraryCategoriesToggleUnlockedTracks,
  navigationLibraryCategoriesToggleUnlockedPosts,
  navigationLibraryCategoriesToggleSubscribedArtists,
} from '@/features'
// icons
import { icons } from '@/assets'

// components
import { Category } from './components'
// styles
import { styles } from './styles'

type NavigationLibraryProps = {}

export const NavigationLibrary: FC<NavigationLibraryProps> = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const {
    likedTracks,
    likedPosts,
    followedArtists,
    unlockedTracks,
    unlockedPosts,
    subscribedArtists,
  } = useAppSelector(navigationSelectLibraryCategories)

  // content navigation desktop
  const [backwardDisabled, setBackwardDisabled] = useState(true)
  const [forwardDisabled, setForwardDisabled] = useState(false)

  const contentRef = useRef<HTMLDivElement | null>(null)

  const categories = [
    {
      id: '2cc48f49-5bf6-40ab-9522-32bb5c0408bd',
      name: 'Liked Tracks',
      label: 'select liked tracks',
      selected: likedTracks,
      onClick: () => dispatch(navigationLibraryCategoriesToggleLikedTracks()),
    },
    {
      id: '074437af-af73-4f93-b69b-34e25b7195bd',
      name: 'Liked Posts',
      label: 'select liked posts',
      selected: likedPosts,
      onClick: () => dispatch(navigationLibraryCategoriesToggleLikedPosts()),
    },
    {
      id: 'dfe24625-0bba-4085-8933-51e6139761a2',
      name: 'Followed Artists',
      label: 'select followed artists',
      selected: followedArtists,
      onClick: () => dispatch(navigationLibraryCategoriesToggleFollowedArtists()),
    },
    {
      id: '705b9609-f357-46ee-b6f9-9e4fe26200e0',
      name: 'Unlocked Tracks',
      label: 'select unlocked tracks',
      selected: unlockedTracks,
      onClick: () => dispatch(navigationLibraryCategoriesToggleUnlockedTracks()),
    },
    {
      id: '9aebdb24-2da7-40a1-8d2c-062d4d2a847a',
      name: 'Unlocked Posts',
      label: 'select unlocked posts',
      selected: unlockedPosts,
      onClick: () => dispatch(navigationLibraryCategoriesToggleUnlockedPosts()),
    },

    {
      id: '9129888d-fd0a-47c3-a7e3-0d21634dda10',
      name: 'Subscribed Artists',
      label: 'select subscribed artists',
      selected: subscribedArtists,
      onClick: () => dispatch(navigationLibraryCategoriesToggleSubscribedArtists()),
    },
  ]

  // configure initial navigation state
  useEffect(() => {
    // if node exists
    if (contentRef.current) {
      // get current node
      const { current } = contentRef
      // get list node client width, scroll width, and scroll offset
      // clientWidth - currently visible content
      // scrollWidth - total width of the element
      // scrollLeft  - number of pixels scrolled
      const { clientWidth, scrollWidth, scrollLeft } = current

      const lastScrollPosition = scrollWidth - clientWidth

      if (scrollLeft <= 0) {
        setBackwardDisabled(true)
      } else {
        setBackwardDisabled(false)
      }

      if (scrollLeft >= lastScrollPosition) {
        setForwardDisabled(true)
      } else {
        setForwardDisabled(false)
      }
    }
  }, [])

  // handleBackwardClick - handles categories navigation backward click
  const handleBackwardClick = () => {
    // if node exists
    if (contentRef.current) {
      // get current node
      const { current } = contentRef
      // get node client width, scroll width, and scroll offset
      // clientWidth - currently visible content
      // scrollWidth - total width of the element
      // scrollLeft  - number of pixels scrolled
      const { clientWidth, scrollWidth, scrollLeft } = current

      // calculate next scroll offset
      const navigationButtonWidth = 60
      const offset = clientWidth - navigationButtonWidth * 2
      // scroll for exactly n visible items
      current.scrollBy(-offset, 0)

      const nextScrollPosition = scrollLeft - offset
      const lastScrollPosition = scrollWidth - clientWidth

      if (nextScrollPosition <= 0) {
        setBackwardDisabled(true)
      } else {
        setBackwardDisabled(false)
      }

      if (nextScrollPosition >= lastScrollPosition) {
        setForwardDisabled(true)
      } else {
        setForwardDisabled(false)
      }
    }
  }

  // handleForwardClick - handles categories navigation forward click
  const handleForwardClick = () => {
    // if node exists
    if (contentRef.current) {
      // get current node
      const { current } = contentRef
      // get node client width, scroll width, and scroll offset
      // clientWidth - currently visible content
      // scrollWidth - total width of the element
      // scrollLeft  - number of pixels scrolled
      const { clientWidth, scrollWidth, scrollLeft } = current

      // calculate next scroll offset
      const navigationButtonWidth = 60
      const offset = clientWidth - navigationButtonWidth * 2
      // scroll for exactly n visible items
      current.scrollBy(offset, 0)

      const nextScrollPosition = scrollLeft + offset
      const lastScrollPosition = scrollWidth - clientWidth

      if (nextScrollPosition <= 0) {
        setBackwardDisabled(true)
      } else {
        setBackwardDisabled(false)
      }

      if (nextScrollPosition >= lastScrollPosition) {
        setForwardDisabled(true)
      } else {
        setForwardDisabled(false)
      }
    }
  }

  return (
    <div css={styles(theme).navigation.main}>
      {/* navigate backward */}
      <div
        css={[
          styles(theme).navigation.overlay.shared,
          styles(theme).navigation.overlay.backward,
          backwardDisabled && styles(theme).navigation.overlay.disabled,
        ]}
      >
        <button
          css={styles(theme).navigation.overlay.button}
          type="button"
          aria-label="scroll backward"
          disabled={backwardDisabled}
          onClick={() => handleBackwardClick()}
        >
          <icons.ArrowLeft css={styles(theme).navigation.overlay.icon} />
        </button>
      </div>

      {/* categories */}
      <div css={styles(theme).navigation.content} ref={contentRef}>
        {categories.map(({ id, name, label, selected, onClick }) => (
          <Category
            key={id}
            name={name}
            label={label}
            selected={selected}
            onClick={() => onClick()}
          />
        ))}
      </div>

      {/* navigate forward */}
      <div
        css={[
          styles(theme).navigation.overlay.shared,
          styles(theme).navigation.overlay.forward,
          forwardDisabled && styles(theme).navigation.overlay.disabled,
        ]}
      >
        <button
          css={styles(theme).navigation.overlay.button}
          type="button"
          aria-label="scroll backward"
          disabled={forwardDisabled}
          onClick={() => handleForwardClick()}
        >
          <icons.ArrowRight css={styles(theme).navigation.overlay.icon} />
        </button>
      </div>
    </div>
  )
}

NavigationLibrary.propTypes = {}

import { css } from '@emotion/react'

export const styles = () => ({
  form: {
    main: css``,
    content: {
      main: css``,
      submit: css``,
    },
  },
})

import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  control: {
    main: css`
      display: flex;
    `,
    content: {
      main: css`
        ${helpers.resetDefaults};

        display: flex;
        align-items: center;

        cursor: pointer;
        min-width: 130px;
      `,

      positive: css`
        &:hover {
          & > div > svg > path {
            fill: ${theme.colors.positive.dark};
          }
          & > div {
            color: ${theme.colors.positive.dark};
          }
        }
      `,

      negative: css`
        &:hover {
          & > div > svg > path {
            fill: ${theme.colors.negative.dark};
          }
          & > div {
            color: ${theme.colors.negative.dark};
          }
        }
      `,

      neutral: css`
        &:hover {
          & > div > svg > path {
            fill: ${theme.colors.accent.normal};
          }
          & > div {
            color: ${theme.colors.accent.normal};
          }
        }
      `,

      disabled: css`
        cursor: not-allowed;

        &:hover {
          & > div > svg > path {
            fill: ${theme.colors.secondary.dark};
          }
          & > div {
            color: ${theme.colors.secondary.dark};
          }
        }
      `,

      icon: {
        main: css`
          display: flex;
          margin-right: 10px;

          & > svg {
            width: 22px;
            height: 22px;
          }

          & > svg > path {
            transition: fill 0.2s;
          }
        `,
        positive: css`
          & > svg > path {
            fill: ${theme.colors.positive.normal};
          }
        `,
        negative: css`
          & > svg > path {
            fill: ${theme.colors.negative.normal};
          }
        `,
        neutral: css`
          & > svg > path {
            fill: ${theme.colors.secondary.normal};
          }
        `,
        disabled: css`
          & > svg > path {
            fill: ${theme.colors.secondary.dark};
          }
        `,
      },
      text: {
        main: css`
          white-space: nowrap;

          font-size: ${typography.p};
          text-decoration: underline;
          transition: color 0.2s;
        `,
        positive: css`
          color: ${theme.colors.positive.normal};
        `,
        negative: css`
          color: ${theme.colors.negative.normal};
        `,
        neutral: css`
          color: ${theme.colors.secondary.normal};
        `,
        disabled: css`
          color: ${theme.colors.secondary.dark};
        `,
      },
    },
  },
})

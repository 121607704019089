/**
 * Merges two arrays at specified index.
 *
 * Takes two arrays of any type and start index, returns merged array.
 *
 *
 * @param {any[]} arr1 - array of any type.
 * @param {any[]} arr2 - array of any type.
 * @param {number} startIndex - index to start merge from.
 * @returns {any[]} - result of merge of arr1 with arr2 at start index.
 *
 * @example
 * // Returns [1,2,3,undefined,undefined,6,7,8].
 * const cutString = mergeArraysAtIndex([1,2,3], [6,7,8], 4)
 */
export const mergeArraysAtIndex = (
  arr1: any[],
  arr2: any[],
  startIndex: number,
): any[] => {
  // append first array to result
  const result = [...arr1]

  // iterate over array to be merged to
  for (let i = 0; i < arr2.length; i += 1) {
    result[startIndex + i] = arr2[i]
  }

  return result
}

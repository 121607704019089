import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  identifier: {
    main: css`
      display: flex;
    `,
    id: css`
      font-size: ${typography.h5};
      color: ${theme.colors.secondary.normal};
    `,
  },
})

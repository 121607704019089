import { css } from '@emotion/react'

export const styles = () => ({
  layout: {
    main: css`
      display: flex;
      min-height: 100vh;
    `,

    navigation: css`
      display: flex;
    `,

    content: {
      main: css`
        display: flex;
        flex-direction: column;
        flex: 1;

        width: 100%;
      `,

      header: {
        app: css`
          width: 100%;
        `,
        library: css`
          width: 100%;
        `,
      },

      panel: {
        main: css`
          display: flex;
          flex: 1;

          width: 100%;
        `,
        view: css`
          display: flex;
          flex: 1;
        `,
      },

      player: css`
        width: 100%;
      `,

      playlist: css`
        width: 100%;
      `,
    },
  },
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'

export interface PlayerState {
  audio: {
    playlist: EntityTrackView[]

    current: {
      action: number
      ready: boolean
      playing: boolean
      index: number
    }

    repeat: boolean
    random: boolean

    open: boolean
  }
  video: {}
}

const initialState: PlayerState = {
  audio: {
    playlist: [],

    current: {
      action: 0,
      ready: false,
      playing: false,
      index: -1,
    },

    repeat: false,
    random: false,

    open: false,
  },
  video: {},
}

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    // set audio player current track
    playerAudioSetCurrentTrack: (
      state,
      action: PayloadAction<{ track: EntityTrackView }>,
    ) => {
      const { track } = action.payload

      // check if track exists in playlist
      const idx = state.audio.playlist.findIndex((t) => t.id === track.id)

      // if track is already in playlist
      if (idx !== -1) {
        // reset ready state
        state.audio.current.ready = false
        // increment current action
        state.audio.current.action += 1
        // set current index as index of track in the playlist
        state.audio.current.index = idx
      }

      // if track is not in playlist
      if (idx === -1) {
        // add track to playlist
        state.audio.playlist.push(track)
        // reset ready state
        state.audio.current.ready = false
        // increment current action
        state.audio.current.action += 1
        // set current index as last playlist index
        state.audio.current.index = state.audio.playlist.length - 1
      }
    },

    // set audio player current track ready
    playerAudioSetCurrentTrackReady: (
      state,
      action: PayloadAction<{ state: boolean }>,
    ) => {
      // set ready state
      state.audio.current.ready = action.payload.state
    },

    // set audio player current track playing
    playerAudioSetCurrentTrackPlaying: (
      state,
      action: PayloadAction<{ state: boolean }>,
    ) => {
      // set playing state
      state.audio.current.playing = action.payload.state
    },

    // set audio player next track
    playerAudioSetNextTrack: (state) => {
      // get current track index
      const { index: currentTrackIndex } = state.audio.current
      // get last playlist index
      const lastPlaylistIndex = state.audio.playlist.length - 1
      // get first playlist index
      const firstPlaylistIndex = 0

      // if current track has index and playlist last index is >= 0
      if (currentTrackIndex !== undefined && lastPlaylistIndex >= 0) {
        // get next playlist index
        const nextPlaylistIndex = currentTrackIndex + 1

        switch (true) {
          case lastPlaylistIndex === firstPlaylistIndex:
            // if last playlist index equals first playlist index
            // there is just one track in playlist

            // reset ready state
            state.audio.current.ready = false
            // increment current action
            state.audio.current.action += 1
            // set current index as last playlist index
            state.audio.current.index = lastPlaylistIndex
            break
          case currentTrackIndex >= lastPlaylistIndex:
            // if current track index is greater or equal to last playlist index
            // playlist is over, start from the first index

            // reset ready state
            state.audio.current.ready = false
            // increment current action
            state.audio.current.action += 1
            // set current index as first playlist index
            state.audio.current.index = firstPlaylistIndex
            break
          default:
            // else just set next playlist index

            // reset ready state
            state.audio.current.ready = false
            // increment current action
            state.audio.current.action += 1
            // set current index as next playlist index
            state.audio.current.index = nextPlaylistIndex
            break
        }
      }
    },

    // set audio player prev track
    playerAudioSetPrevTrack: (state) => {
      // get current track index
      const { index: currentTrackIndex } = state.audio.current
      // get last playlist index
      const lastPlaylistIndex = state.audio.playlist.length - 1
      // get first playlist index
      const firstPlaylistIndex = 0

      // if current track has index and playlist last index is >= 0
      if (currentTrackIndex !== undefined && lastPlaylistIndex >= 0) {
        // get prev playlist index
        const prevPlaylistIndex = currentTrackIndex - 1

        switch (true) {
          case lastPlaylistIndex === firstPlaylistIndex:
            // if last playlist index equals first playlist index
            // there is just one track in playlist

            // reset ready state
            state.audio.current.ready = false
            // increment current action
            state.audio.current.action += 1
            // set current index as last playlist index
            state.audio.current.index = lastPlaylistIndex
            break
          case currentTrackIndex <= firstPlaylistIndex:
            // if current track index is less or equal to first playlist index
            // playlist is over, start from the last index

            // reset ready state
            state.audio.current.ready = false
            // increment current action
            state.audio.current.action += 1
            // set current index as last playlist index
            state.audio.current.index = lastPlaylistIndex
            break
          default:
            // else just set prev playlist index

            // reset ready state
            state.audio.current.ready = false
            // increment current action
            state.audio.current.action += 1
            // set current index as prev playlist index
            state.audio.current.index = prevPlaylistIndex
            break
        }
      }
    },

    // set audio player repeat track
    playerAudioSetRepeatTrack: (state) => {
      const currentPlaylistIndex = state.audio.current.index
      // increment current action
      state.audio.current.action += 1
      // set current index as current index
      state.audio.current.index = currentPlaylistIndex
    },

    // set audio player random track
    playerAudioSetRandomTrack: (state) => {
      // get playlist length
      const playlistLength = state.audio.playlist.length
      // get random index
      const randomPlaylistIndex = Math.floor(Math.random() * playlistLength)

      // if random playlist index is defined (0 - n)
      if (randomPlaylistIndex !== undefined) {
        // reset ready state
        state.audio.current.ready = false
        // increment current action
        state.audio.current.action += 1
        // set current index as random playlist index
        state.audio.current.index = randomPlaylistIndex
      }
    },

    // toggle audio player repeat
    playerAudioToggleRepeat: (state) => ({
      ...state,
      audio: {
        ...state.audio,
        repeat: !state.audio.repeat,
      },
    }),

    // toggle audio player random
    playerAudioToggleRandom: (state) => ({
      ...state,
      audio: {
        ...state.audio,
        random: !state.audio.random,
      },
    }),

    // toggle audio player open
    playerAudioToggleOpen: (state) => ({
      ...state,
      audio: {
        ...state.audio,
        open: !state.audio.open,
      },
    }),

    // set audio player open
    playerAudioSetOpen: (state) => ({
      ...state,
      audio: {
        ...state.audio,
        open: true,
      },
    }),

    // set audio player closed
    playerAudioSetClosed: (state) => ({
      ...state,
      audio: {
        ...state.audio,
        open: false,
      },
    }),

    // filter track
    playerAudioFilterPlaylistTrack: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload

      // check if track exists in playlist
      const idx = state.audio.playlist.findIndex((t) => t.id === id)
      if (idx !== -1) {
        // if track currently playing
        if (idx === state.audio.current.index) {
          // reset current track state
          state.audio.current = {
            ...state.audio.current,
            ready: false,
            playing: false,
            index: -1,
          }
        }

        // filter track from playlist
        state.audio.playlist = state.audio.playlist.filter((t) => t.id !== id)
      }
    },

    // filter user tracks
    playerAudioFilterPlaylistUserTracks: (
      state,
      action: PayloadAction<{ userId: string }>,
    ) => {
      const { userId } = action.payload

      // define user tracks map
      const userTracksMap: { [key: string]: boolean } = {}

      // iterate over each track in playlist
      state.audio.playlist.forEach((t, idx) => {
        // if track belongs to user
        if (t.userId === userId) {
          // add track to map
          userTracksMap[idx] = true
        }
      })

      // if user track currently playing
      if (userTracksMap[state.audio.current.index]) {
        // reset current track state
        state.audio.current = {
          ...state.audio.current,
          ready: false,
          playing: false,
          index: -1,
        }
      }

      // filter user track from playlist
      state.audio.playlist = state.audio.playlist.filter((t) => t.userId !== userId)
    },

    // update track in audio player playlist
    playerAudioUpdatePlaylistTrack: (
      state,
      action: PayloadAction<{
        updatedTrack: EntityTrackView
      }>,
    ) => {
      const { updatedTrack } = action.payload

      // update track in playlist
      const idx = state.audio.playlist.findIndex((t) => t.id === updatedTrack.id)
      if (idx !== -1) {
        const currentTrack = state.audio.playlist[idx]
        state.audio.playlist[idx] = { ...currentTrack, ...updatedTrack }
      }
    },

    // video
  },
})

export const {
  playerAudioSetCurrentTrack,
  playerAudioSetCurrentTrackReady,
  playerAudioSetCurrentTrackPlaying,
  playerAudioSetNextTrack,
  playerAudioSetPrevTrack,
  playerAudioSetRepeatTrack,
  playerAudioSetRandomTrack,
  playerAudioToggleRepeat,
  playerAudioToggleRandom,
  playerAudioToggleOpen,
  playerAudioSetOpen,
  playerAudioSetClosed,
  playerAudioFilterPlaylistTrack,
  playerAudioFilterPlaylistUserTracks,
  playerAudioUpdatePlaylistTrack,
} = playerSlice.actions

export const playerAudioSelectPlaylist = (state: RootState) => state.player.audio.playlist
export const playerAudioSelectPlaylistLength = (state: RootState) =>
  state.player.audio.playlist.length

export const playerAudioSelectCurrentTrack = (state: RootState) =>
  state.player.audio.playlist[state.player.audio.current.index]
export const playerAudioSelectCurrentTrackAction = (state: RootState) =>
  state.player.audio.current.action
export const playerAudioSelectCurrentTrackReady = (state: RootState) =>
  state.player.audio.current.ready
export const playerAudioSelectCurrentTrackPlaying = (state: RootState) =>
  state.player.audio.current.playing

export const playerAudioSelectRepeat = (state: RootState) => state.player.audio.repeat
export const playerAudioSelectRandom = (state: RootState) => state.player.audio.random
export const playerAudioSelectOpen = (state: RootState) => state.player.audio.open

export const playerReducer = playerSlice.reducer

// export default playerSlice.reducer

import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

// components
import { SuspenseContentFallback } from '@/components'
// utils
import { importsLazy } from '@/utils'

const { AuthVerificationModal } = importsLazy(
  () => import('@/features'),
  'AuthVerificationModal',
)

const App = () => (
  <Suspense fallback={<SuspenseContentFallback position="full" />}>
    <Outlet />
  </Suspense>
)

export const verificationRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <AuthVerificationModal />,
      },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
]

/**
 * Cuts a string to the specified maximum length, adds three dots at the end.
 *
 * Takes max length and string to cut, returns cut string with three dots.
 *
 *
 * @param maxLength - maximum length of the resulting string (excluding the dots).
 * @param str - input string to be cut.
 * @returns cut string with three dots added at the end.
 *
 * @example
 * // Returns cut string (st...).
 * const cutString = stringsCutter("string", 5)
 */
export const stringsCutter = (maxLength: number, str: string): string => {
  // Check if the input string is already shorter than or equal to the maximum length
  if (str.length <= maxLength) {
    return str
  }

  // Cut the string to the specified maximum length and add three dots at the end
  const cutString = `${str.slice(0, maxLength - 3)}...`
  return cutString
}

import { css, Theme } from '@emotion/react'
import { helpers, fonts, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  input: {
    main: css`
      display: flex;
      position: relative;
    `,
    label: css`
      display: none;
    `,
    data: css`
      ${helpers.resetDefaults};

      width: 100%;

      font-family: ${fonts.primary};
      font-size: ${typography.h6};
      color: ${theme.colors.secondary.normal};

      border: 1px solid ${theme.colors.secondary.darkHover};
      border-radius: 10px;

      padding: 8px 8px 8px 44px;
      transition: border 0.2s;

      &:focus {
        border: 1px solid ${theme.colors.accent.normal};

        & ~ svg > path {
          fill: ${theme.colors.accent.normal};
        }
      }

      &::placeholder {
        color: ${theme.colors.secondary.darkHover};
      }

      // https://stackoverflow.com/questions/20804016/editing-input-type-search-pseudo-element-button-x
      &::-webkit-search-cancel-button {
      }
    `,
    icon: css`
      position: absolute;
      top: 6px;
      left: 10px;

      width: 30px;
      height: 30px;

      & > path {
        transition: fill 0.2s;

        fill: ${theme.colors.secondary.darkHover};
      }
    `,
  },
})

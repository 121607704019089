import { FC, MouseEventHandler } from 'react'

// components
import { ButtonSmall } from '@/components'

// styles
import { styles } from './styles'

type CardSignupProps = {
  onSignupClick: MouseEventHandler<HTMLButtonElement>
}

export const CardSignup: FC<CardSignupProps> = ({ onSignupClick }) => (
  <div css={styles().signup.main}>
    <div css={styles().signup.button}>
      <ButtonSmall
        aria-label="sign up"
        appearance="primary"
        onClick={(e) => onSignupClick(e)}
      >
        Sign up
      </ButtonSmall>
    </div>
  </div>
)

CardSignup.propTypes = {}

import { FC, useEffect, useState, useRef, ChangeEvent } from 'react'
import { EntityPostVisibility, EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// config
import {
  minPostMessageLength,
  maxPostMessageLength,
  minPostUnlockPrice,
  maxPostUnlockPrice,
  maxPostTracksCount,
  maxPostMediaFileSize,
} from '@/config'
// hooks
import { useAppSelector, useAppDispatch, useConnectReady } from '@/hooks'
// features
import {
  TrackListStudioPostUploaded,
  authSelectProfile,
  postSelectPostCreateLoading,
  postCreate,
} from '@/features'
// utils
import { validatePostMediaFile, validatePostMessage } from '@/utils'
// icons
import { icons } from '@/assets'
// components
import {
  Loader,
  InputArea,
  InputAmount,
  InputLabel,
  InputDescription,
  MediaFilePreview,
  ButtonMedium,
  CheckboxOption,
  Error,
} from '@/components'

// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const postCreateLoading = useAppSelector(postSelectPostCreateLoading)

  // connect ready
  const { ready: connectReady } = useConnectReady(profile?.id || '')

  // media state
  const [media, setMedia] = useState<File | undefined>(undefined)
  const [mediaPreview, setMediaPreview] = useState('')
  const [mediaPreviewType, setMediaPreviewType] = useState<'image' | 'video' | ''>('')
  const mediaFileInputRef = useRef<HTMLInputElement | null>(null)
  const [mediaError, setMediaError] = useState('')

  // message state
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('')

  // tracks state
  const [tracks, setTracks] = useState<EntityTrackView[]>([])

  // visibility state
  const [visibility, setVisibility] = useState(EntityPostVisibility.Public)

  // unlock price state
  const defaultUnlockPrice = Math.ceil(maxPostUnlockPrice / 2)
  const [unlockPrice, setUnlockPrice] = useState(defaultUnlockPrice)

  const submitDisabled =
    message.length < minPostMessageLength ||
    message.length > maxPostMessageLength ||
    unlockPrice < minPostUnlockPrice ||
    unlockPrice > maxPostUnlockPrice ||
    mediaError !== '' ||
    messageError !== '' ||
    postCreateLoading

  const revokePreviewURL = (url: string) => {
    URL.revokeObjectURL(url)
  }

  const cleanupState = () => {
    setMedia(undefined)
    setMessage('')
    setTracks([])
    setVisibility(EntityPostVisibility.Public)
    setUnlockPrice(defaultUnlockPrice)

    // if media preview was set
    if (mediaPreview) {
      // clean up to avoid memory leaks
      revokePreviewURL(mediaPreview)
      setMediaPreview('')
    }
  }

  const onMediaClick = () => {
    mediaFileInputRef.current?.click()
    setMediaError('')
  }

  const onMediaFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    // if file selected
    if (files && files.length > 0) {
      const file = files[0]

      // validate file
      const error = validatePostMediaFile(file)
      if (error) {
        setMediaError(error)
        return
      }

      // if file of image type
      if (file.type.startsWith('image/')) {
        setMediaPreviewType('image')
      }

      // if file of video type
      if (file.type.startsWith('video/')) {
        setMediaPreviewType('video')
      }

      // if media preview was set
      if (mediaPreview) {
        // clean up to avoid memory leaks
        revokePreviewURL(mediaPreview)
      }

      // set file as a preview
      setMediaPreview(URL.createObjectURL(file))

      // store file in state
      setMedia(file)
    }
  }

  useEffect(() => () => {
    // if media preview was set
    if (mediaPreview) {
      // clean up to avoid memory leaks
      revokePreviewURL(mediaPreview)
    }
  })

  useEffect(() => {
    if (!postCreateLoading) {
      cleanupState()
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [postCreateLoading])

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(postCreate({ message, media, tracks, visibility, unlockPrice }))
        }}
      >
        {/* media */}
        <div css={styles().form.content.media.main}>
          <div css={styles().form.content.media.label}>
            <InputLabel text="Media" />
          </div>
          <div css={styles().form.content.media.preview.main}>
            <label css={styles().form.content.media.preview.label} htmlFor="media">
              media
              <input
                name="media"
                type="file"
                id="media"
                ref={mediaFileInputRef}
                accept="image/jpeg,image/webp,image/png,video/quicktime,video/mp4,video/webm"
                onChange={(e) => onMediaFileChange(e)}
              />
            </label>
            <div css={styles().form.content.media.preview.image}>
              <MediaFilePreview
                source={mediaPreview}
                type={mediaPreviewType}
                width="100%"
                height="200px"
                alt="post media"
                onClick={() => onMediaClick()}
                formats={['JPG', 'JPEG', 'PNG', 'WEBP', 'MOV', 'MP4', 'WEBM']}
                maxFileSize={maxPostMediaFileSize}
              />
            </div>
          </div>
          <div css={styles().form.content.media.error}>
            <Error error={mediaError} />
          </div>
        </div>

        {/* message */}
        <div css={styles().form.content.message.main}>
          <div css={styles().form.content.message.label}>
            <InputLabel text="Message" />
          </div>
          <div css={styles().form.content.message.input}>
            <InputArea
              name="message"
              label="message"
              id="tip-message"
              placeholder="Share your experience with your followers"
              value={message}
              maxLength={maxPostMessageLength}
              error={messageError.length > 0}
              errorMessage={messageError}
              rows={6}
              onChange={(e) => setMessage(e.target.value)}
              onBlur={() => setMessageError(validatePostMessage(message))}
              onFocus={() => setMessageError('')}
            />
          </div>
        </div>

        {/* tracks */}
        <div css={styles().form.content.tracks.main}>
          <div css={styles().form.content.tracks.list}>
            <TrackListStudioPostUploaded
              postTracks={tracks}
              addPostTrack={(track: EntityTrackView) =>
                tracks.length < maxPostTracksCount
                  ? setTracks((state) => [...state, track])
                  : () => {}
              }
              removePostTrack={(trackId: string) =>
                setTracks((state) => state.filter((t) => t.id !== trackId))
              }
            />
          </div>
          <div css={styles().form.content.tracks.description}>
            <InputDescription
              description={`Feel free to include a maximum of ${maxPostTracksCount} tracks in your post.`}
            />
          </div>
        </div>

        {/* visibility */}
        <div css={styles().form.content.visibility.main}>
          <div css={styles().form.content.visibility.label}>
            <InputLabel text="Visibility" />
          </div>
          <div css={styles().form.content.visibility.inputs}>
            <CheckboxOption
              name="Public"
              description="With this visibility option, the post is made publicly accessible to everyone on the platform."
              icon={icons.Unlock}
              checked={visibility === EntityPostVisibility.Public}
              onCheckboxClick={() => setVisibility(EntityPostVisibility.Public)}
            />

            {connectReady && (
              <CheckboxOption
                name="Subscribers"
                description="With this visibility option, the post is exclusively accessible to subscribers or individuals who unlock it directly, unlocking the post grants the user access to all the locked tracks it contains."
                icon={icons.Lock}
                checked={visibility === EntityPostVisibility.Subscribers}
                onCheckboxClick={() => setVisibility(EntityPostVisibility.Subscribers)}
              />
            )}
          </div>
        </div>

        {/* price */}
        {visibility === EntityPostVisibility.Subscribers && (
          <div css={styles().form.content.price.main}>
            <div css={styles().form.content.price.label}>
              <InputLabel text="Price" />
            </div>

            <div css={styles().form.content.price.amount}>${unlockPrice}</div>

            <div css={styles().form.content.price.input}>
              <InputAmount
                name="unlock-price"
                label="unlock price"
                type="range"
                id="unlock-price"
                value={unlockPrice}
                min={minPostUnlockPrice}
                max={maxPostUnlockPrice}
                onChange={(e) => setUnlockPrice(parseInt(e.target.value, 10))}
              />
            </div>
          </div>
        )}

        {/* buttons */}
        <div css={styles().form.content.buttons.main}>
          <div css={styles().form.content.buttons.submit}>
            <ButtonMedium
              type="submit"
              aria-label="create post"
              disabled={submitDisabled}
              appearance="primary"
            >
              {postCreateLoading ? (
                <Loader width={56} height={20} appearance="secondary" />
              ) : (
                'Create'
              )}
            </ButtonMedium>
          </div>
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

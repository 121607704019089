import useSWR from 'swr'

// libs
import { apiGetService, apiHandleError } from '@/lib'

// types
import { SWRKeys, SWRFetcherData } from './types'

const getConnectPayout = async ({
  data: { connectPayoutId },
}: SWRFetcherData<{ connectPayoutId: string }>) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve connect payout
    const { connectPayout } = await api.connectApi.getConnectPayout(connectPayoutId)

    // return payload
    return { connectPayout, error: '' }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return payload
    return { connectPayout: undefined, error: message }
  }
}

const fetcher = (args: SWRFetcherData<{ connectPayoutId: string }>) =>
  getConnectPayout(args)

export const useConnectPayout = (connectPayoutId: string) => {
  const shouldFetch = !!connectPayoutId

  const { data, isLoading, mutate } = useSWR(
    shouldFetch ? { key: SWRKeys.UseConnectPayout, data: { connectPayoutId } } : null,
    fetcher,
    { revalidateOnFocus: false },
  )

  return {
    connectPayout: data?.connectPayout,
    isLoading,
    isError: data?.error,
    mutate,
  }
}

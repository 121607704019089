import { FC, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  CustomerPaymentMethodDefault,
  paymentSelectPaymentCreatePaymentLoading,
  paymentSelectPaymentCreateDefaultPaymentLoading,
  paymentSelectPaymentModalDefaultCustomerPaymentMethod,
  paymentCreatePayment,
  paymentCreateDefaultPayment,
} from '@/features'
// components
import { Loader, ButtonLarge, Checkbox } from '@/components'

// styles
import { styles } from './styles'

type PaymentFormProps = {}

export const PaymentForm: FC<PaymentFormProps> = () => {
  const dispatch = useAppDispatch()
  const paymentCreatePaymentLoading = useAppSelector(
    paymentSelectPaymentCreatePaymentLoading,
  )
  const paymentCreateDefaultPaymentLoading = useAppSelector(
    paymentSelectPaymentCreateDefaultPaymentLoading,
  )
  const defaultPaymentMethod = useAppSelector(
    paymentSelectPaymentModalDefaultCustomerPaymentMethod,
  )
  const { brand, last4 } = defaultPaymentMethod ?? {}

  const [useDefaultPaymentMethod, setUseDefaultPaymentMethod] = useState(
    !!defaultPaymentMethod,
  )

  const stripe = useStripe()
  const elements = useElements()

  const paymentLoading = paymentCreatePaymentLoading || paymentCreateDefaultPaymentLoading
  const submitDisabled = paymentLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()

          // if user has default payment method and wants to use it for payment
          if (defaultPaymentMethod && useDefaultPaymentMethod) {
            // create default payment
            dispatch(paymentCreateDefaultPayment({ stripe }))
          } else {
            // create payment with payment element
            dispatch(paymentCreatePayment({ stripe, elements }))
          }
        }}
      >
        {/* payment method */}
        {useDefaultPaymentMethod ? (
          <div css={styles().form.content.card}>
            <CustomerPaymentMethodDefault brand={brand} last4={last4} />
          </div>
        ) : (
          <div css={styles().form.content.card}>
            <PaymentElement />
          </div>
        )}

        {/* use default checkbox */}
        {defaultPaymentMethod && (
          <div css={styles().form.content.checkbox.main}>
            <Checkbox
              name="use-default-payment-method"
              label="Use default payment method"
              checked={useDefaultPaymentMethod}
              disabled={paymentLoading}
              onChange={(e) => setUseDefaultPaymentMethod(e.target.checked)}
            />
            <div
              css={styles().form.content.checkbox.label}
              onClick={
                paymentLoading
                  ? () => {}
                  : () => setUseDefaultPaymentMethod((state) => !state)
              }
              aria-hidden="true"
            >
              Use default payment method
            </div>
          </div>
        )}

        {/* submit */}
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="checkout"
            disabled={submitDisabled}
            appearance="primary"
          >
            {paymentLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Checkout`
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

PaymentForm.propTypes = {}

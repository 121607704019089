import { css, Theme } from '@emotion/react'
import { helpers, typography, z } from '@/styles'

export const styles = (theme: Theme) => ({
  track: {
    main: css`
      display: flex;
      flex-direction: column;

      width: 180px;
    `,

    cover: {
      main: css`
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 180px;
        height: 180px;

        background-color: ${theme.colors.primary.darker};
        border-radius: 4px;
        overflow: hidden;

        margin-bottom: 10px;
      `,

      button: css`
        ${helpers.resetDefaults};

        display: flex;

        width: 100%;
        height: 100%;

        cursor: pointer;
      `,

      overlay: {
        main: css`
          z-index: ${z.features.track.tile.overlay};

          position: absolute;
          left: 0;
          top: 0;

          width: 100%;
          height: 100%;

          transition: opacity 0.2s;

          background-color: ${theme.colors.primary.normal}A0;
        `,

        icon: css`
          display: flex;
          justify-content: flex-end;

          margin: 10px 10px 0px 0px;

          & > svg {
            width: 30px;
            height: 30px;

            & > path {
              fill: ${theme.colors.accent.normal};
            }
          }
        `,
      },

      image: css`
        ${helpers.image};
      `,

      placeholder: css`
        display: flex;

        & > svg {
          width: 30px;
          height: 30px;

          & > path {
            color: ${theme.colors.secondary.normal};
          }
          & > circle {
            color: ${theme.colors.secondary.normal};
          }
        }
      `,
    },

    description: {
      main: css`
        display: flex;
        flex-direction: column;
      `,
      name: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.p};
        font-weight: bold;
      `,
      author: css`
        ${helpers.resetDefaults};
        ${helpers.textOverflowEllipsis};

        cursor: pointer;

        font-size: ${typography.sp};
        color: ${theme.colors.secondary.normal};
        text-align: start;

        transition: color 0.2s;
        &:hover {
          color: ${theme.colors.accent.normal};
        }
      `,
    },
  },
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// stores
import { RootState } from '@/stores'
// features
import {
  likeGetFollowedUserIds,
  likeGetLikedPostIds,
  likeGetLikedTrackIds,
  likeGetLikedCommentIds,
} from '@/features'

export interface LikeState {
  userFollows: { [key: string]: boolean }
  trackLikes: { [key: string]: boolean }
  postLikes: { [key: string]: boolean }
  commentLikes: { [key: string]: boolean }

  likeGetFollowedUserIdsLoading: boolean
  likeGetLikedPostIdsLoading: boolean
  likeGetLikedTrackIdsLoading: boolean
  likeGetLikedCommentIdsLoading: boolean
}

const initialState: LikeState = {
  userFollows: {},
  trackLikes: {},
  postLikes: {},
  commentLikes: {},

  likeGetFollowedUserIdsLoading: false,
  likeGetLikedPostIdsLoading: false,
  likeGetLikedTrackIdsLoading: false,
  likeGetLikedCommentIdsLoading: false,
}

export const likeSlice = createSlice({
  name: 'like',
  initialState,
  reducers: {
    // reset state
    likeResetState: () => ({
      userFollows: {},
      trackLikes: {},
      postLikes: {},
      commentLikes: {},

      likeGetFollowedUserIdsLoading: false,
      likeGetLikedPostIdsLoading: false,
      likeGetLikedTrackIdsLoading: false,
      likeGetLikedCommentIdsLoading: false,
    }),

    // set user follows
    likeSetUserFollows: (state, action: PayloadAction<{ followedUserIds: string[] }>) => {
      const { followedUserIds } = action.payload

      // define user follows map
      const userFollows: { [key: string]: boolean } = {}

      // iterate over each followed user id
      followedUserIds.forEach((id) => {
        // add id to the map
        userFollows[id] = true
      })

      // update state
      state.userFollows = userFollows
    },

    // add user follow
    likeAddUserFollow: (state, action: PayloadAction<{ userId: string }>) => {
      const { userId } = action.payload

      // update state
      state.userFollows[userId] = true
    },

    // remove user follow
    likeRemoveUserFollow: (state, action: PayloadAction<{ userId: string }>) => {
      const { userId } = action.payload

      // update state
      state.userFollows[userId] = false
    },

    // set post likes
    likeSetPostLikes: (state, action: PayloadAction<{ likedPostIds: string[] }>) => {
      const { likedPostIds } = action.payload

      // define post likes map
      const postLikes: { [key: string]: boolean } = {}

      // iterate over each liked post id
      likedPostIds.forEach((id) => {
        // add id to the map
        postLikes[id] = true
      })

      // update state
      state.postLikes = postLikes
    },

    // add post like
    likeAddPostLike: (state, action: PayloadAction<{ postId: string }>) => {
      const { postId } = action.payload

      // update state
      state.postLikes[postId] = true
    },

    // remove post like
    likeRemovePostLike: (state, action: PayloadAction<{ postId: string }>) => {
      const { postId } = action.payload

      // update state
      state.postLikes[postId] = false
    },

    // set track likes
    likeSetTrackLikes: (state, action: PayloadAction<{ likedTrackIds: string[] }>) => {
      const { likedTrackIds } = action.payload

      // define track likes map
      const trackLikes: { [key: string]: boolean } = {}

      // iterate over each liked track id
      likedTrackIds.forEach((id) => {
        // add id to the map
        trackLikes[id] = true
      })

      // update state
      state.trackLikes = trackLikes
    },

    // add track like
    likeAddTrackLike: (state, action: PayloadAction<{ trackId: string }>) => {
      const { trackId } = action.payload

      // update state
      state.trackLikes[trackId] = true
    },

    // remove track like
    likeRemoveTrackLike: (state, action: PayloadAction<{ trackId: string }>) => {
      const { trackId } = action.payload

      // update state
      state.trackLikes[trackId] = false
    },

    // set comment likes
    likeSetCommentLikes: (
      state,
      action: PayloadAction<{ likedCommentIds: string[] }>,
    ) => {
      const { likedCommentIds } = action.payload

      // define comment likes map
      const commentLikes: { [key: string]: boolean } = {}

      // iterate over each liked comment id
      likedCommentIds.forEach((id) => {
        // add id to the map
        commentLikes[id] = true
      })

      // update state
      state.commentLikes = commentLikes
    },

    // add comment like
    likeAddCommentLike: (state, action: PayloadAction<{ commentId: string }>) => {
      const { commentId } = action.payload

      // update state
      state.commentLikes[commentId] = true
    },

    // remove liked comment
    likeRemoveCommentLike: (state, action: PayloadAction<{ commentId: string }>) => {
      const { commentId } = action.payload

      // update state
      state.commentLikes[commentId] = false
    },
  },
  extraReducers: (builder) => {
    // get followed user ids
    builder
      .addCase(likeGetFollowedUserIds.pending, (state) => ({
        ...state,
        likeGetFollowedUserIdsLoading: true,
      }))
      .addCase(likeGetFollowedUserIds.fulfilled, (state) => ({
        ...state,
        likeGetFollowedUserIdsLoading: false,
      }))

    // get liked post ids
    builder
      .addCase(likeGetLikedPostIds.pending, (state) => ({
        ...state,
        likeGetLikedPostIdsLoading: true,
      }))
      .addCase(likeGetLikedPostIds.fulfilled, (state) => ({
        ...state,
        likeGetLikedPostIdsLoading: false,
      }))

    // get liked track ids
    builder
      .addCase(likeGetLikedTrackIds.pending, (state) => ({
        ...state,
        likeGetLikedTrackIdsLoading: true,
      }))
      .addCase(likeGetLikedTrackIds.fulfilled, (state) => ({
        ...state,
        likeGetLikedTrackIdsLoading: false,
      }))

    // get liked comment ids
    builder
      .addCase(likeGetLikedCommentIds.pending, (state) => ({
        ...state,
        likeGetLikedCommentIdsLoading: true,
      }))
      .addCase(likeGetLikedCommentIds.fulfilled, (state) => ({
        ...state,
        likeGetLikedCommentIdsLoading: false,
      }))
  },
})

export const {
  likeResetState,

  likeSetUserFollows,
  likeAddUserFollow,
  likeRemoveUserFollow,

  likeSetPostLikes,
  likeAddPostLike,
  likeRemovePostLike,

  likeSetTrackLikes,
  likeAddTrackLike,
  likeRemoveTrackLike,

  likeSetCommentLikes,
  likeAddCommentLike,
  likeRemoveCommentLike,
} = likeSlice.actions

export const likeSelectUserFollows = (state: RootState) => state.like.userFollows
export const likeSelectPostLikes = (state: RootState) => state.like.postLikes
export const likeSelectTrackLikes = (state: RootState) => state.like.trackLikes
export const likeSelectCommentLikes = (state: RootState) => state.like.commentLikes

export const likeSelectLikeGetFollowedUserIdsLoading = (state: RootState) =>
  state.like.likeGetFollowedUserIdsLoading
export const likeSelectLikeGetLikedPostIdsLoading = (state: RootState) =>
  state.like.likeGetLikedPostIdsLoading
export const likeSelectLikeGetLikedTrackIdsLoading = (state: RootState) =>
  state.like.likeGetLikedTrackIdsLoading
export const likeSelectLikeGetLikedCommentIdsLoading = (state: RootState) =>
  state.like.likeGetLikedCommentIdsLoading

export const likeReducer = likeSlice.reducer

// export default likeSlice.reducer

import { FC, InputHTMLAttributes, useState } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from './assets'

// styles
import { styles } from './styles'

enum InputType {
  Text = 'text',
  Password = 'password',
}

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  error?: boolean
  errorMessage?: string
}

export const FormInput: FC<FormInputProps> = ({
  name,
  label,
  error = false,
  errorMessage = '',
  ...attributes
}) => {
  const theme = useTheme()
  const [inputType, setInputType] = useState(attributes.type)

  return (
    <div css={styles(theme).input.main}>
      {/* label */}
      <label css={styles(theme).input.label} htmlFor={name}>
        {label}
      </label>

      {/* input */}
      <div css={styles(theme).input.data.main}>
        <input
          css={[
            styles(theme).input.data.shared,

            attributes.value
              ? styles(theme).input.data.filled
              : styles(theme).input.data.empty,

            error && styles(theme).input.data.errored,

            (inputType === InputType.Password || inputType === InputType.Text) &&
              styles(theme).input.data.withIcon,

            inputType === InputType.Password &&
              attributes.value &&
              styles(theme).input.data.password,
          ]}
          id={name}
          {...attributes}
          type={inputType}
        />

        {/* open password */}
        {inputType === InputType.Password && (
          <button
            css={styles(theme).input.button.main}
            onClick={() => setInputType(InputType.Text)}
            type="button"
            aria-label="open password"
          >
            <icons.EyeClosed css={styles(theme).input.button.icon} />
          </button>
        )}

        {/* hide password */}
        {inputType === InputType.Text && (
          <button
            css={styles(theme).input.button.main}
            onClick={() => setInputType(InputType.Password)}
            type="button"
            aria-label="hide password"
          >
            <icons.EyeOpen css={styles(theme).input.button.icon} />
          </button>
        )}
      </div>

      {/* error message */}
      <div
        css={[
          styles(theme).input.error.shared,
          errorMessage
            ? styles(theme).input.error.visible
            : styles(theme).input.error.hidden,
        ]}
      >
        <span
          css={[
            styles(theme).input.error.text.shared,
            errorMessage
              ? styles(theme).input.error.text.visible
              : styles(theme).input.error.text.hidden,
          ]}
        >
          {errorMessage}
        </span>
      </div>
    </div>
  )
}

FormInput.propTypes = {}

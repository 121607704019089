import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

// config
import { Environment } from '@/config'
// reducers
import {
  alertReducer,
  authReducer,
  commentReducer,
  connectReducer,
  customerReducer,
  likeReducer,
  navigationReducer,
  notificationReducer,
  onboardingReducer,
  paymentReducer,
  playerReducer,
  postReducer,
  reportReducer,
  searchReducer,
  streamReducer,
  subscriptionReducer,
  themeReducer,
  tipReducer,
  trackReducer,
  transactionReducer,
  unlockReducer,
  userReducer,
} from '@/features'

export const store = configureStore({
  reducer: {
    alert: alertReducer,
    auth: authReducer,
    comment: commentReducer,
    connect: connectReducer,
    customer: customerReducer,
    like: likeReducer,
    navigation: navigationReducer,
    notification: notificationReducer,
    onboarding: onboardingReducer,
    payment: paymentReducer,
    player: playerReducer,
    post: postReducer,
    report: reportReducer,
    search: searchReducer,
    stream: streamReducer,
    subscription: subscriptionReducer,
    theme: themeReducer,
    tip: tipReducer,
    track: trackReducer,
    transaction: transactionReducer,
    unlock: unlockReducer,
    user: userReducer,
  },
  // remove devtools for production
  devTools: process.env.ENVIRONMENT !== Environment.Production,
})

// infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// inferred type: {theme: ThemeState, ...}
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

import { FC, memo } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityTrackVisibility } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  TrackCard,
  TrackCardLocked,
  TrackCardBlockSkeleton,
  trackSelectPublicTracks,
  authOpenJoinModal,
  trackGetPublicTracks,
} from '@/features'
// components
import { ContentLayoutVirtualized } from '@/components'
// utils
import { uuid } from '@/utils'
// styles
import { contentLayout } from '@/styles'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const dispatch = useAppDispatch()
  const tracks = useAppSelector(trackSelectPublicTracks)

  const subsetLength = 3

  // create subset
  const subset = tracks.slice(index * subsetLength, index * subsetLength + subsetLength)

  // if tracks not fetched
  if (subset.length === 0) {
    return (
      <div style={style}>
        <TrackCardBlockSkeleton />
      </div>
    )
  }

  return (
    <div css={styles().tracks.main} style={style}>
      {subset.map((track) => {
        const { id: trackId, visibility } = track

        const isContentLocked = visibility === EntityTrackVisibility.Subscribers
        const renderLocked = isContentLocked

        const likeOption = {
          id: uuid(),
          label: 'like track',
          name: 'Like',
          onClick: () => dispatch(authOpenJoinModal()),
        }

        const playOption = {
          id: uuid(),
          label: 'play track',
          name: 'Play',
          onClick: () => dispatch(authOpenJoinModal()),
        }

        const unlockOption = {
          id: uuid(),
          label: 'unlock track',
          name: 'Unlock',
          onClick: () => dispatch(authOpenJoinModal()),
        }

        // if content is locked
        if (renderLocked) {
          return (
            <div css={styles().tracks.row} key={`public-track-${trackId}`}>
              <TrackCardLocked
                track={track}
                options={[unlockOption, likeOption]}
                onClick={() => dispatch(authOpenJoinModal())}
                onAuthorNameClick={() => dispatch(authOpenJoinModal())}
              />
            </div>
          )
        }

        return (
          <div css={styles().tracks.row} key={`public-track-${trackId}`}>
            <TrackCard
              track={track}
              options={[playOption, likeOption]}
              onClick={() => dispatch(authOpenJoinModal())}
              onAuthorNameClick={() => dispatch(authOpenJoinModal())}
            />
          </div>
        )
      })}
    </div>
  )
})

type TrackListHomePublicProps = {}

export const TrackListHomePublic: FC<TrackListHomePublicProps> = () => {
  const dispatch = useAppDispatch()
  const publicTracks = useAppSelector(trackSelectPublicTracks)

  const trackBlockWidth = 280
  const trackBlockMarginRight = 20
  const itemWidth = trackBlockWidth + trackBlockMarginRight

  const trackBlockHeight = 228
  const trackBlockMarginBottom = 0
  const itemHeight = trackBlockHeight + trackBlockMarginBottom

  const defaultItemsCount = 60
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const subsetLength = 3 // for each 3 items render one item block

  const isItemLoaded = (index: number) => !!publicTracks[index]

  const loadMoreItems = (): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(trackGetPublicTracks())
  }

  return (
    <ContentLayoutVirtualized
      title="Popular Tracks"
      overrideCss={contentLayout.home}
      itemWidth={itemWidth}
      itemsCount={defaultItemsCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={defaultItemsCount / subsetLength}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={defaultItemsCount / subsetLength}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

TrackListHomePublic.propTypes = {}

import { FC, useEffect, useState, useRef, ChangeEvent } from 'react'
import { useParams } from 'react-router-dom'
import { EntityTrackVisibility } from '@ryddm-inc/ryddm-apiclient'

// config
import {
  musicGenres as musGen,
  maxTrackGenresCount,
  minTrackNameLength,
  maxTrackNameLength,
  maxTrackCoverFileSize,
} from '@/config'
// hooks
import { useAppSelector, useAppDispatch, useFile } from '@/hooks'
// features
import {
  trackSelectTrackUpdateLoading,
  trackSelectUploadedTracks,
  trackUpdate,
} from '@/features'
// utils
import { validateCoverFile, validateTrackName } from '@/utils'
// icons
import { icons } from '@/assets'
// components
import {
  Loader,
  InputLine,
  InputLabel,
  ButtonMedium,
  MediaFilePreview,
  CheckboxOption,
  Badge,
  BadgeSelected,
  Error,
} from '@/components'

// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const { trackId } = useParams()

  const dispatch = useAppDispatch()
  const trackUpdateLoading = useAppSelector(trackSelectTrackUpdateLoading)

  const uploadedTracks = useAppSelector(trackSelectUploadedTracks)
  const track = uploadedTracks.find((t) => t.id === trackId)
  const {
    coverId,
    name: currentName,
    genres: currentGenres,
    visibility: currentVisibility,
    unlockPrice: currentUnlockPrice,
  } = track ?? {}

  // cover state
  const trackHasCover = !!coverId
  const trackCover = useFile(coverId || '')
  const [cover, setCover] = useState<File | undefined>(undefined)
  const [coverPreview, setCoverPreview] = useState(trackHasCover ? trackCover : '')
  const coverFileInputRef = useRef<HTMLInputElement | null>(null)
  const [coverError, setCoverError] = useState('')

  // name state
  const [name, setName] = useState(currentName || '')
  const [nameError, setNameError] = useState('')

  // genres state
  const [genres, setGenres] = useState<string[]>(currentGenres || [])
  const [musicGenres, setMusicGenres] = useState(musGen)

  // genres search state
  const [search, setSearch] = useState('')

  // visibility state
  const [visibility, setVisibility] = useState(
    currentVisibility || EntityTrackVisibility.Public,
  )

  const nameUnchanged = name === currentName
  const genresUnchanged = genres.join('') === currentGenres?.join('')
  const coverNotSelected = cover === undefined
  const trackDefaultsUnchanged = nameUnchanged && genresUnchanged && coverNotSelected
  const submitDisabled =
    trackDefaultsUnchanged ||
    name.length < minTrackNameLength ||
    name.length > maxTrackNameLength ||
    coverError !== '' ||
    nameError !== '' ||
    trackUpdateLoading

  const revokePreviewURL = (url: string) => {
    URL.revokeObjectURL(url)
  }

  const cleanupState = () => {
    setCover(undefined)
    setName(currentName || '')
    setGenres(currentGenres || [])
    setVisibility(currentVisibility || EntityTrackVisibility.Public)
  }

  const onCoverClick = () => {
    coverFileInputRef.current?.click()
    setCoverError('')
  }

  const onCoverFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    // if file selected
    if (files && files.length > 0) {
      const file = files[0]

      // validate file
      const error = validateCoverFile(file)
      if (error) {
        setCoverError(error)
        return
      }

      // if cover preview was set
      if (coverPreview) {
        // clean up to avoid memory leaks
        revokePreviewURL(coverPreview)
      }

      // set file as a preview
      setCoverPreview(URL.createObjectURL(file))

      // store file in state
      setCover(file)
    }
  }

  useEffect(() => () => {
    // if cover preview was set
    if (coverPreview) {
      // clean up to avoid memory leaks
      revokePreviewURL(coverPreview)
    }
  })

  useEffect(() => {
    if (!trackUpdateLoading) {
      cleanupState()
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [trackUpdateLoading])

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(trackUpdate({ track: track || {}, name, cover, genres }))
        }}
      >
        {/* cover */}
        <div css={styles().form.content.cover.main}>
          <div css={styles().form.content.cover.label}>
            <InputLabel text="Cover" />
          </div>
          <div css={styles().form.content.cover.preview.main}>
            <label css={styles().form.content.cover.preview.label} htmlFor="cover">
              cover
              <input
                name="cover"
                type="file"
                id="cover"
                ref={coverFileInputRef}
                accept="image/jpeg,image/webp,image/png"
                onChange={(e) => onCoverFileChange(e)}
              />
            </label>
            <div css={styles().form.content.cover.preview.image}>
              <MediaFilePreview
                source={coverPreview}
                type="image"
                width="140px"
                height="140px"
                alt="track cover"
                onClick={() => onCoverClick()}
                // formats={['JPG', 'JPEG', 'PNG', 'WEBP']}
                maxFileSize={maxTrackCoverFileSize}
              />
            </div>
          </div>
          <div css={styles().form.content.cover.error}>
            <Error error={coverError} />
          </div>
        </div>

        {/* name */}
        <div css={styles().form.content.name}>
          <InputLine
            name="name"
            label="Name"
            type="text"
            placeholder="Enter your name"
            icon={icons.Edit}
            value={name}
            error={nameError.length > 0}
            errorMessage={nameError}
            min={minTrackNameLength}
            max={maxTrackNameLength}
            onChange={(e) => {
              setName(e.target.value)
            }}
            onBlur={() => setNameError(validateTrackName(name))}
            onFocus={() => setNameError('')}
          />
        </div>

        {/* genres */}
        <div css={styles().form.content.genres.main}>
          {/* genres */}
          <div css={styles().form.content.genres.selected.main}>
            <div css={styles().form.content.genres.selected.label}>
              <InputLabel text="Genre" />
            </div>
            <div css={styles().form.content.genres.selected.genres}>
              {genres.map((trackGenre) => (
                <BadgeSelected
                  key={`track-genres-${trackGenre}`}
                  name={trackGenre}
                  onRemoveClick={() =>
                    setGenres((state) => state.filter((genre) => genre !== trackGenre))
                  }
                />
              ))}
            </div>
          </div>

          {/* search genres */}
          <div css={styles().form.content.genres.search.main}>
            <div css={styles().form.content.genres.search.input}>
              <InputLine
                name="search"
                label="Select genre"
                type="search"
                placeholder="Find genre"
                icon={icons.Search}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setMusicGenres(
                    musGen.filter((genre) =>
                      genre.toLowerCase().includes(e.target.value),
                    ),
                  )
                }}
              />
            </div>
            <div css={styles().form.content.genres.search.genres}>
              {musicGenres.map((genre) => (
                <Badge
                  key={`music-genres-${genre}`}
                  name={genre}
                  disabled={
                    genres.includes(genre) || genres.length >= maxTrackGenresCount
                  }
                  onClick={() => setGenres((state) => [...state, genre])}
                />
              ))}
            </div>
          </div>
        </div>

        {/* visibility */}
        <div css={styles().form.content.visibility.main}>
          <div css={styles().form.content.visibility.label}>
            <InputLabel text="Visibility" />
          </div>
          <div css={styles().form.content.visibility.inputs}>
            {visibility === EntityTrackVisibility.Public && (
              <CheckboxOption
                name="Public"
                description="With this visibility option, the track is made publicly accessible to everyone on the platform"
                icon={icons.Unlock}
                checked={visibility === EntityTrackVisibility.Public}
                onCheckboxClick={() => setVisibility(EntityTrackVisibility.Public)}
              />
            )}

            {visibility === EntityTrackVisibility.Subscribers && (
              <CheckboxOption
                name="Subscribers"
                description="With this visibility option, the track is exclusively accessible to subscribers or individuals who unlock it directly"
                icon={icons.Lock}
                checked={visibility === EntityTrackVisibility.Subscribers}
                onCheckboxClick={() => setVisibility(EntityTrackVisibility.Subscribers)}
              />
            )}
          </div>
        </div>

        {/* price */}
        {visibility === EntityTrackVisibility.Subscribers && (
          <div css={styles().form.content.price.main}>
            <div css={styles().form.content.price.label}>
              <InputLabel text="Price" />
            </div>
            <div css={styles().form.content.price.amount}>
              ${(currentUnlockPrice || 0) / 100}
            </div>
          </div>
        )}

        {/* buttons */}
        <div css={styles().form.content.buttons.main}>
          <div css={styles().form.content.buttons.submit}>
            <ButtonMedium
              type="submit"
              aria-label="update track"
              disabled={submitDisabled}
              appearance="primary"
            >
              {trackUpdateLoading ? (
                <Loader width={56} height={20} appearance="secondary" />
              ) : (
                'Update'
              )}
            </ButtonMedium>
          </div>
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

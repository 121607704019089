import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  form: {
    main: css``,

    content: {
      main: css``,

      avatar: {
        main: css`
          display: flex;
          flex-direction: column;

          margin-bottom: 28px;

          ${mq.tablet(css`
            align-items: center;
            justify-content: center;
          `)}
        `,
        label: css`
          display: none;
        `,
        image: css`
          width: 100px;
          margin-bottom: 22px;
        `,
        error: css``,
      },

      name: css`
        margin-bottom: 32px;
      `,

      username: css`
        margin-bottom: 50px;
      `,

      genres: {
        main: css`
          margin-bottom: 40px;
        `,

        selected: {
          main: css`
            margin-bottom: 30px;
          `,
          label: css`
            margin-bottom: 10px;
          `,
          genres: css`
            display: flex;
            flex-wrap: wrap;

            & > div {
              margin-bottom: 20px;
            }

            & > div:not(:last-of-type) {
              margin-right: 20px;
            }
          `,
        },

        search: {
          main: css``,
          input: css`
            margin-bottom: 10px;
          `,
          genres: css`
            display: flex;
            flex-wrap: wrap;

            & > div {
              margin-bottom: 10px;
            }

            & > div:not(:last-of-type) {
              margin-right: 16px;
            }
          `,
        },
      },

      buttons: {
        main: css`
          display: flex;

          ${mq.tablet(css`
            flex-direction: column;
          `)}
        `,
        submit: css`
          margin-right: 20px;

          & > button {
            width: 130px;
          }

          ${mq.tablet(css`
            margin-right: 0px;
            margin-bottom: 20px;

            & > button {
              width: 100%;
            }
          `)}
        `,
        share: css``,
      },
    },
  },
})

import { css } from '@emotion/react'
import { typography } from '@/styles'

export const styles = () => ({
  form: {
    main: css``,
    content: {
      main: css``,
      report: css`
        margin-bottom: 24px;

        text-align: center;
        font-size: ${typography.h5};
      `,
      submit: css``,
    },
  },
})

import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  notifications: {
    main: css`
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;
    `,

    button: css`
      ${helpers.resetDefaults};

      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;

      cursor: pointer;

      &:hover > svg > path {
        fill: ${theme.colors.accent.normal};
      }
    `,

    icon: {
      shared: css`
        width: 24px;
        height: 24px;

        & > path {
          transition: fill 0.2s;
        }
      `,
      active: css`
        & > path {
          fill: ${theme.colors.accent.normal};
        }
      `,
      inactive: css`
        & > path {
          fill: ${theme.colors.secondary.normal};
        }
      `,
    },

    count: {
      shared: css`
        position: absolute;
        top: -2px;
        right: 2px;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 20px;

        color: ${theme.colors.positive.darker};
        font-weight: bold;

        background-color: ${theme.colors.accent.normal};
      `,

      circle: css`
        width: 20px;
        border-radius: 50%;
      `,

      badge: css`
        padding: 0px 2px;
        border-radius: 20px;
      `,
    },
  },
})

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { authSignout, authSelectError, authSelectProfile } from '@/features'

// components
import { CardHeaderMobile, Error } from '@/components'
import { CardDivider, CardTitle, VerificationForm } from './components'

// styles
import { styles } from './VerificationCard.styles'

type VerificationCardProps = {}

export const VerificationCard: FC<VerificationCardProps> = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const authError = useAppSelector(authSelectError)
  const authProfile = useAppSelector(authSelectProfile)

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile onCloseClick={() => {}} hideClose />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <CardTitle title="Please check your email" />
      </div>

      {/* subtitle */}
      <div css={styles(theme).card.subtitle.main}>
        <span css={styles(theme).card.subtitle.text}>
          {`We've sent a confirmation link to your email:`}
        </span>
        <span css={styles(theme).card.subtitle.email}>{authProfile?.email}</span>
      </div>

      <div css={styles(theme).card.content.main}>
        {/* email verification */}
        <div css={styles(theme).card.content.form}>
          <VerificationForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={authError} />
        </div>

        <div css={styles(theme).card.content.divider.bottom}>
          <CardDivider />
        </div>

        {/* log out */}
        <div css={styles(theme).card.content.login.main}>
          <button
            css={styles(theme).card.content.login.button}
            onClick={() => dispatch(authSignout())}
            type="button"
            aria-label="log out"
          >
            Log out
          </button>
        </div>
      </div>
    </div>
  )
}

VerificationCard.propTypes = {}

import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityTrackVisibility } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // track
  TrackTile,
  TrackTileLocked,
  trackSelectPopularTracks,
  trackSelectPopularTracksCount,
  trackSelectPopularTracksLength,
  trackSelectPopularTracksFetched,
  trackGetPopularTracks,
  trackUtilGetTrackTileSkeletonSections,
  // player
  playerAudioSetCurrentTrack,
  // subscription
  subscriptionSelectUserSubscriptions,
  // unlock
  unlockOpenUnlockTrackModal,
  unlockSelectTrackUnlocks,
} from '@/features'
// components
import {
  ContentLayoutVirtualized,
  ContentPlaceholder,
  SkeletonVertical,
} from '@/components'
// icons
import { icons } from '@/assets'
// styles
import { contentLayout } from '@/styles'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const trackUnlocks = useAppSelector(unlockSelectTrackUnlocks)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const tracks = useAppSelector(trackSelectPopularTracks)
  const track = tracks[index]

  // if track not fetched
  if (!track) {
    return (
      <div style={style}>
        <SkeletonVertical
          containerWidth="180px"
          sections={trackUtilGetTrackTileSkeletonSections('track-list-charts-popular')}
        />
      </div>
    )
  }

  const { id: currentUserId } = profile ?? {}
  const { id: trackId, userId: trackAuthorUserId, visibility } = track

  const isContentLocked = visibility === EntityTrackVisibility.Subscribers
  const isCurrentUserContentAuthor = currentUserId === trackAuthorUserId
  const isCurrentUserUnlockedContent = trackUnlocks[trackId || '']
  const isCurrentUserSubscribedToContentAuthor =
    userSubscriptions[trackAuthorUserId || '']

  const renderLocked =
    isContentLocked &&
    !isCurrentUserContentAuthor &&
    !isCurrentUserUnlockedContent &&
    !isCurrentUserSubscribedToContentAuthor

  // if content is locked
  if (renderLocked) {
    return (
      <div css={styles().tracks.main} style={style}>
        <TrackTileLocked
          track={track}
          onClick={() => dispatch(unlockOpenUnlockTrackModal({ track }))}
          onAuthorNameClick={() => navigate(`${Path.User}/${trackAuthorUserId}`)}
        />
      </div>
    )
  }

  return (
    <div css={styles().tracks.main} style={style}>
      <TrackTile
        track={track}
        onClick={() => dispatch(playerAudioSetCurrentTrack({ track }))}
        onAuthorNameClick={() => navigate(`${Path.User}/${trackAuthorUserId}`)}
      />
    </div>
  )
})

type TrackListChartsPopularProps = {}

export const TrackListChartsPopular: FC<TrackListChartsPopularProps> = () => {
  const dispatch = useAppDispatch()
  const popularTracks = useAppSelector(trackSelectPopularTracks)
  const popularTracksCount = useAppSelector(trackSelectPopularTracksCount)
  const popularTracksLength = useAppSelector(trackSelectPopularTracksLength)
  const popularTracksFetched = useAppSelector(trackSelectPopularTracksFetched)

  const trackBlockWidth = 180
  const trackBlockMarginRight = 20
  const itemWidth = trackBlockWidth + trackBlockMarginRight

  const trackBlockHeight = 230
  const trackBlockMarginBottom = 0
  const itemHeight = trackBlockHeight + trackBlockMarginBottom

  const defaultItemsCount = 1000
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!popularTracks[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(trackGetPopularTracks({ limit: itemsToFetch, offset: startIndex }))
  }

  // if popular tracks are fetched but length is 0
  if (popularTracksFetched && popularTracksLength === 0) {
    // return place holder
    return (
      <ContentLayoutVirtualized
        title="Popular"
        icon={icons.Crown}
        overrideCss={contentLayout.charts}
        itemWidth={itemWidth}
        itemsCount={popularTracksCount}
      >
        <ContentPlaceholder
          icon={icons.MusicNotes}
          height="230px"
          title="Couldn't find any tracks"
        />
      </ContentLayoutVirtualized>
    )
  }

  return (
    <ContentLayoutVirtualized
      title="Popular"
      icon={icons.Crown}
      overrideCss={contentLayout.charts}
      itemWidth={itemWidth}
      itemsCount={popularTracksCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={popularTracksCount || defaultItemsCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={popularTracksCount || defaultItemsCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

TrackListChartsPopular.propTypes = {}

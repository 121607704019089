import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  title: {
    main: css`
      display: flex;
      justify-content: center;
    `,
    icon: css`
      display: flex;
      align-items: center;

      margin-right: 10px;

      & > svg {
        width: 28px;
        height: 28px;

        & path {
          fill: ${theme.colors.accent.normal};
        }
      }
    `,
    text: css`
      font-size: ${typography.h5};
      color: ${theme.colors.secondary.normal};
    `,
  },
})

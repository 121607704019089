import { css } from '@emotion/react'

export const styles = () => ({
  list: {
    main: css``,
    content: {
      main: css`
        max-width: 800px;
      `,
    },
  },

  pms: {
    main: css``,
  },
})

import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  sections: {
    main: css`
      margin: 50px 0px;
    `,
    nav: {
      main: css`
        display: flex;
        margin-bottom: 50px;

        & > div {
          ${mq.mobile(css`
            width: 100%;
          `)}
        }

        & > div:not(:last-of-type) {
          margin-right: 32px;
        }
      `,
      item: css``,
    },
    content: {
      main: css`
        width: 400px;

        ${mq.tablet(css`
          width: 100%;
        `)}
      `,
    },
  },
})

import { FC } from 'react'

// styles
import { styles } from './styles'

type ErrorFallbackProps = {}

// TODO: develop error page
export const ErrorFallback: FC<ErrorFallbackProps> = () => (
  <div css={styles().fallback.main}>
    <div css={styles().fallback.title.main}>
      <h2 css={styles().fallback.title.text}>Ooops, something went wrong!</h2>
    </div>
    <div css={styles().fallback.buttons.main}>
      <button
        css={styles().fallback.buttons.button}
        onClick={() => window.location.assign(window.location.origin)}
        type="button"
        aria-label="refresh page"
      >
        Refresh
      </button>
    </div>
  </div>
)

ErrorFallback.propTypes = {}

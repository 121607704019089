import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  avatar: {
    main: css`
      display: flex;
      align-items: center;
      justify-content: center;

      width: 60px;
      height: 60px;

      border-radius: 50%;

      background-color: ${theme.colors.primary.darker};

      overflow: hidden;
    `,
    content: css`
      ${helpers.image};
    `,
    icon: css`
      width: 30px;
      height: 30px;

      & > path {
        fill: ${theme.colors.secondary.normal};
      }
    `,
  },
})

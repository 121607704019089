import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  features: {
    main: css`
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      & > div:not(:last-of-type) {
        margin-bottom: 32px;
      }

      ${mq.tablet(css`
        justify-content: center;
      `)}
    `,
  },
})

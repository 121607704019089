import useSWR from 'swr'

// libs
import { apiGetService, apiHandleError } from '@/lib'

// types
import { SWRKeys, SWRFetcherData } from './types'

const getUsersByIds = async ({
  data: { userIds },
}: SWRFetcherData<{ userIds: string[] }>) => {
  // get api service
  const api = apiGetService()

  // if no user ids provided
  if (userIds.length === 0) {
    // return empty payload
    return { users: [], count: 0, error: '' }
  }

  try {
    // retrieve users
    const { users, count } = await api.userApi.getUsersByIds(userIds.join(','))

    // return payload
    return { users, count, error: '' }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error
    return { users: [], count: 0, error: message }
  }
}

const fetcher = (args: SWRFetcherData<{ userIds: string[] }>) => getUsersByIds(args)

export const useUsers = (userIds: string[]) => {
  const { data, isLoading } = useSWR(
    { key: SWRKeys.UseUsers, data: { userIds } },
    fetcher,
    { revalidateOnFocus: false },
  )

  return {
    users: data?.users,
    count: data?.count,
    isLoading,
    isError: data?.error,
  }
}

import Amex from './amex.svg'
import Card from './card.svg'
import Diners from './diners.svg'
import Discover from './discover.svg'
import Eft from './eft.svg'
import Jcb from './jcb.svg'
import Mastercard from './mastercard.svg'
import UnionPay from './union_pay.svg'
import Visa from './visa.svg'

export const cards = {
  Amex,
  Card,
  Diners,
  Discover,
  Eft,
  Jcb,
  Mastercard,
  UnionPay,
  Visa,
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityUserView } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// features
import {
  subscriptionGetSubscribedUserIds,
  subscriptionGetSubscribedAtPeriodEndUserIds,
  subscriptionCreateSubscription,
  subscriptionRenewSubscription,
  subscriptionCancelSubscription,
} from '@/features'

export interface SubscriptionState {
  userSubscriptions: { [key: string]: boolean }
  userSubscriptionsAtPeriodEnd: { [key: string]: boolean }

  subscriptionCreateModal: {
    artist: EntityUserView | undefined
    open: boolean
  }

  subscriptionRenewModal: {
    artist: EntityUserView | undefined
    open: boolean
  }

  subscriptionCancelModal: {
    artist: EntityUserView | undefined
    open: boolean
  }

  subscriptionGetSubscribedUserIdsLoading: boolean
  subscriptionGetSubscribedAtPeriodEndUserIdsLoading: boolean
  subscriptionCreateSubscriptionLoading: boolean
  subscriptionRenewSubscriptionLoading: boolean
  subscriptionCancelSubscriptionLoading: boolean
}

const initialState: SubscriptionState = {
  userSubscriptions: {},
  userSubscriptionsAtPeriodEnd: {},

  subscriptionCreateModal: {
    artist: undefined,
    open: false,
  },

  subscriptionRenewModal: {
    artist: undefined,
    open: false,
  },

  subscriptionCancelModal: {
    artist: undefined,
    open: false,
  },

  subscriptionGetSubscribedUserIdsLoading: false,
  subscriptionGetSubscribedAtPeriodEndUserIdsLoading: false,
  subscriptionCreateSubscriptionLoading: false,
  subscriptionRenewSubscriptionLoading: false,
  subscriptionCancelSubscriptionLoading: false,
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    // reset state
    subscriptionResetState: () => ({
      userSubscriptions: {},
      userSubscriptionsAtPeriodEnd: {},

      subscriptionCreateModal: {
        artist: undefined,
        open: false,
      },

      subscriptionRenewModal: {
        artist: undefined,
        open: false,
      },

      subscriptionCancelModal: {
        artist: undefined,
        open: false,
      },

      subscriptionGetSubscribedUserIdsLoading: false,
      subscriptionGetSubscribedAtPeriodEndUserIdsLoading: false,
      subscriptionCreateSubscriptionLoading: false,
      subscriptionRenewSubscriptionLoading: false,
      subscriptionCancelSubscriptionLoading: false,
    }),

    // set user subscriptions
    subscriptionSetUserSubscriptions: (
      state,
      action: PayloadAction<{ subscribedUserIds: string[] }>,
    ) => {
      const { subscribedUserIds } = action.payload

      // define user subscriptions map
      const userSubscriptions: { [key: string]: boolean } = {}

      // iterate over each subscribed user id
      subscribedUserIds.forEach((id) => {
        // add id to the map
        userSubscriptions[id] = true
      })

      // update state
      state.userSubscriptions = userSubscriptions
    },

    // add user subscription
    subscriptionAddUserSubscription: (
      state,
      action: PayloadAction<{ userId: string }>,
    ) => {
      const { userId } = action.payload

      // update state
      state.userSubscriptions[userId] = true
    },

    // remove user subscription
    subscriptionRemoveUserSubscription: (
      state,
      action: PayloadAction<{ userId: string }>,
    ) => {
      const { userId } = action.payload

      // update state
      state.userSubscriptions[userId] = false
    },

    // set user subscriptions at period end
    subscriptionSetUserSubscriptionsAtPeriodEnd: (
      state,
      action: PayloadAction<{ subscribedAtPeriodEndUserIds: string[] }>,
    ) => {
      const { subscribedAtPeriodEndUserIds } = action.payload

      // define user subscriptions at period end map
      const userSubscriptionsAtPeriodEnd: { [key: string]: boolean } = {}

      // iterate over each subscribed user id
      subscribedAtPeriodEndUserIds.forEach((id) => {
        // add id to the map
        userSubscriptionsAtPeriodEnd[id] = true
      })

      // update state
      state.userSubscriptionsAtPeriodEnd = userSubscriptionsAtPeriodEnd
    },

    // add user subscription at period end
    subscriptionAddUserSubscriptionAtPeriodEnd: (
      state,
      action: PayloadAction<{ userId: string }>,
    ) => {
      const { userId } = action.payload

      // update state
      state.userSubscriptionsAtPeriodEnd[userId] = true
    },

    // remove user subscription at period end
    subscriptionRemoveUserSubscriptionAtPeriodEnd: (
      state,
      action: PayloadAction<{ userId: string }>,
    ) => {
      const { userId } = action.payload

      // update state
      state.userSubscriptionsAtPeriodEnd[userId] = false
    },

    // open subscription create modal
    subscriptionOpenSubscriptionCreateModal: (
      state,
      action: PayloadAction<{ artist: EntityUserView }>,
    ) => ({
      ...state,
      subscriptionCreateModal: {
        artist: action.payload.artist,
        open: true,
      },
    }),

    // close subscription create modal
    subscriptionCloseSubscriptionCreateModal: (state) => ({
      ...state,
      subscriptionCreateModal: {
        artist: undefined,
        open: false,
      },
      error: '',
    }),

    // open subscription renew modal
    subscriptionOpenSubscriptionRenewModal: (
      state,
      action: PayloadAction<{ artist: EntityUserView }>,
    ) => ({
      ...state,
      subscriptionRenewModal: {
        artist: action.payload.artist,
        open: true,
      },
    }),

    // close subscription renew modal
    subscriptionCloseSubscriptionRenewModal: (state) => ({
      ...state,
      subscriptionRenewModal: {
        artist: undefined,
        open: false,
      },
      error: '',
    }),

    // open subscription cancel modal
    subscriptionOpenSubscriptionCancelModal: (
      state,
      action: PayloadAction<{ artist: EntityUserView }>,
    ) => ({
      ...state,
      subscriptionCancelModal: {
        artist: action.payload.artist,
        open: true,
      },
    }),

    // close subscription cancel modal
    subscriptionCloseSubscriptionCancelModal: (state) => ({
      ...state,
      subscriptionCancelModal: {
        artist: undefined,
        open: false,
      },
      error: '',
    }),
  },
  extraReducers: (builder) => {
    // get subscribed user ids
    builder
      .addCase(subscriptionGetSubscribedUserIds.pending, (state) => ({
        ...state,
        subscriptionGetSubscribedUserIdsLoading: true,
      }))
      .addCase(subscriptionGetSubscribedUserIds.fulfilled, (state) => ({
        ...state,
        subscriptionGetSubscribedUserIdsLoading: false,
      }))

    // get subscribed user ids at period end
    builder
      .addCase(subscriptionGetSubscribedAtPeriodEndUserIds.pending, (state) => ({
        ...state,
        subscriptionGetSubscribedAtPeriodEndUserIdsLoading: true,
      }))
      .addCase(subscriptionGetSubscribedAtPeriodEndUserIds.fulfilled, (state) => ({
        ...state,
        subscriptionGetSubscribedAtPeriodEndUserIdsLoading: false,
      }))

    // create subscription
    builder
      .addCase(subscriptionCreateSubscription.pending, (state) => ({
        ...state,
        subscriptionCreateSubscriptionLoading: true,
      }))
      .addCase(subscriptionCreateSubscription.fulfilled, (state) => ({
        ...state,
        subscriptionCreateSubscriptionLoading: false,
      }))

    // renew subscription
    builder
      .addCase(subscriptionRenewSubscription.pending, (state) => ({
        ...state,
        subscriptionRenewSubscriptionLoading: true,
      }))
      .addCase(subscriptionRenewSubscription.fulfilled, (state) => ({
        ...state,
        subscriptionRenewSubscriptionLoading: false,
      }))

    // cancel subscription
    builder
      .addCase(subscriptionCancelSubscription.pending, (state) => ({
        ...state,
        subscriptionCancelSubscriptionLoading: true,
      }))
      .addCase(subscriptionCancelSubscription.fulfilled, (state) => ({
        ...state,
        subscriptionCancelSubscriptionLoading: false,
      }))
  },
})

export const {
  subscriptionResetState,

  subscriptionSetUserSubscriptions,
  subscriptionAddUserSubscription,
  subscriptionRemoveUserSubscription,

  subscriptionSetUserSubscriptionsAtPeriodEnd,
  subscriptionAddUserSubscriptionAtPeriodEnd,
  subscriptionRemoveUserSubscriptionAtPeriodEnd,

  subscriptionOpenSubscriptionCreateModal,
  subscriptionCloseSubscriptionCreateModal,
  subscriptionOpenSubscriptionRenewModal,
  subscriptionCloseSubscriptionRenewModal,
  subscriptionOpenSubscriptionCancelModal,
  subscriptionCloseSubscriptionCancelModal,
} = subscriptionSlice.actions

export const subscriptionSelectUserSubscriptions = (state: RootState) =>
  state.subscription.userSubscriptions
export const subscriptionSelectUserSubscriptionsAtPeriodEnd = (state: RootState) =>
  state.subscription.userSubscriptionsAtPeriodEnd

export const subscriptionSelectSubscriptionCreateModalOpen = (state: RootState) =>
  state.subscription.subscriptionCreateModal.open
export const subscriptionSelectSubscriptionCreateModalArtist = (state: RootState) =>
  state.subscription.subscriptionCreateModal.artist

export const subscriptionSelectSubscriptionRenewModalOpen = (state: RootState) =>
  state.subscription.subscriptionRenewModal.open
export const subscriptionSelectSubscriptionRenewModalArtist = (state: RootState) =>
  state.subscription.subscriptionRenewModal.artist

export const subscriptionSelectSubscriptionCancelModalOpen = (state: RootState) =>
  state.subscription.subscriptionCancelModal.open
export const subscriptionSelectSubscriptionCancelModalArtist = (state: RootState) =>
  state.subscription.subscriptionCancelModal.artist

export const subscriptionSelectSubscriptionGetSubscribedUserIdsLoading = (
  state: RootState,
) => state.subscription.subscriptionGetSubscribedUserIdsLoading
export const subscriptionSelectSubscriptionGetSubscribedAtPeriodEndUserIdsLoading = (
  state: RootState,
) => state.subscription.subscriptionGetSubscribedAtPeriodEndUserIdsLoading
export const subscriptionSelectSubscriptionCreateSubscriptionLoading = (
  state: RootState,
) => state.subscription.subscriptionCreateSubscriptionLoading
export const subscriptionSelectSubscriptionRenewSubscriptionLoading = (
  state: RootState,
) => state.subscription.subscriptionRenewSubscriptionLoading
export const subscriptionSelectSubscriptionCancelSubscriptionLoading = (
  state: RootState,
) => state.subscription.subscriptionCancelSubscriptionLoading

export const subscriptionReducer = subscriptionSlice.reducer

// export default subscriptionSlice.reducer

import { useEffect } from 'react'

// hooks
import { useAppDispatch } from '@/hooks'
// features
import { authRefresh } from '@/features'

type AuthProviderProps = {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(authRefresh())
  }, [dispatch])

  return <> {children} </>
}

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type TrackCardUploadedSkeletonProps = {}

export const TrackCardUploadedSkeleton: FC<TrackCardUploadedSkeletonProps> = () => {
  const theme = useTheme()

  return (
    <div css={styles(theme).skeleton.main}>
      <div css={styles(theme).skeleton.cover} />
      <div css={styles(theme).skeleton.divider} />
      <div css={styles(theme).skeleton.description} />
    </div>
  )
}

TrackCardUploadedSkeleton.propTypes = {}

import AudioNext from './audio_next.svg'
import AudioPause from './audio_pause.svg'
import AudioPlay from './audio_play.svg'
import AudioPrev from './audio_prev.svg'
import AudioRandom from './audio_random.svg'
import AudioRepeat from './audio_repeat.svg'

export const icons = {
  AudioNext,
  AudioPause,
  AudioPlay,
  AudioPrev,
  AudioRandom,
  AudioRepeat,
}

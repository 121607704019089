import { useRoutes } from 'react-router-dom'
import { EntityUserRole, EntityOnboardingStage } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector } from '@/hooks'
// features
import {
  authSelectProfile,
  authSelectEmailVerified,
  authSelectRefreshing,
  authSelectPlatformLocked,
} from '@/features'

// routes
import { adminRoutes } from './admin'
import { artistRoutes } from './artist'
import { fanRoutes } from './fan'
import { lockRoutes } from './lockRoutes'
import { onboardingRoutes } from './onboarding'
import { publicRoutes } from './public'
import { verificationRoutes } from './verification'
import { refreshingRoutes } from './refreshing'

export const AppRoutes = () => {
  const profile = useAppSelector(authSelectProfile)
  const emailVerified = useAppSelector(authSelectEmailVerified)
  const authRefreshing = useAppSelector(authSelectRefreshing)
  const authPlatformLocked = useAppSelector(authSelectPlatformLocked)

  const platformLocked = !authRefreshing && authPlatformLocked

  const userSignedOut = !authPlatformLocked && !authRefreshing && !profile

  const emailIsNotVerified =
    !authPlatformLocked && !authRefreshing && !emailVerified && profile

  const onboardingIsNotFinished =
    !authPlatformLocked &&
    !authRefreshing &&
    emailVerified &&
    profile &&
    profile.onboardingStage !== EntityOnboardingStage.Finished

  const userIsFan =
    !authPlatformLocked &&
    !authRefreshing &&
    emailVerified &&
    profile &&
    profile.onboardingStage === EntityOnboardingStage.Finished &&
    profile.role === EntityUserRole.Fan

  const userIsArtist =
    !authPlatformLocked &&
    !authRefreshing &&
    emailVerified &&
    profile &&
    profile.onboardingStage === EntityOnboardingStage.Finished &&
    profile.role === EntityUserRole.Artist

  const userIsAdmin =
    !authPlatformLocked &&
    !authRefreshing &&
    emailVerified &&
    profile &&
    profile.onboardingStage === EntityOnboardingStage.Finished &&
    profile.role === EntityUserRole.Admin

  // default to refreshing routes
  let routes = refreshingRoutes

  // TODO: remove platform lock once no longer needed
  if (platformLocked) {
    // set locked routes
    routes = lockRoutes
  }

  if (userSignedOut) {
    // set public routes
    routes = publicRoutes
  }

  if (emailIsNotVerified) {
    // set verification routes
    routes = verificationRoutes
  }

  if (onboardingIsNotFinished) {
    // set onboarding routes
    routes = onboardingRoutes
  }

  if (userIsFan) {
    // render fan routes
    routes = fanRoutes
  }

  if (userIsArtist) {
    // render artist routes
    routes = artistRoutes
  }

  if (userIsAdmin) {
    // render admin routes
    routes = adminRoutes
  }

  const element = useRoutes([...routes])

  return element
}

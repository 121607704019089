import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  track: {
    main: css`
      display: flex;
      flex-direction: column;

      width: 180px;
    `,

    cover: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 180px;
        height: 180px;

        background-color: ${theme.colors.primary.normal};
        border-radius: 4px;
        overflow: hidden;

        margin-bottom: 10px;

        &: hover > div {
          opacity: 1;
        }
      `,

      lock: {
        main: css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `,
        icon: css`
          display: flex;

          & > svg {
            width: 30px;
            height: 30px;
          }
        `,
      },

      image: css`
        ${helpers.image};
      `,

      placeholder: css`
        display: flex;

        & > svg {
          width: 30px;
          height: 30px;

          & > path {
            color: ${theme.colors.secondary.normal};
          }
          & > circle {
            color: ${theme.colors.secondary.normal};
          }
        }
      `,
    },

    description: {
      main: css`
        display: flex;
        flex-direction: column;
      `,
      name: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.p};
        color: ${theme.colors.secondary.normal};
        font-weight: bold;

        text-align: center;
      `,
      author: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.sp};
        color: ${theme.colors.secondary.normal};
        text-align: center;
      `,
    },
  },
})

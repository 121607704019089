import { FC, memo } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityUserRole, EntityTransactionType } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  TransactionCard,
  transactionSelectTransactionGetOutgoingTransactionsLoading,
  transactionSelectOutgoingTransactions,
  transactionSelectOutgoingTransactionsCount,
  transactionSelectOutgoingTransactionsLength,
  transactionSelectOutgoingTransactionsFetched,
  transactionSelectOutgoingTransactionsFilter,
  transactionSetOutgoingTransactionsFilter,
  transactionGetOutgoingTransactions,
  authSelectProfile,
} from '@/features'
// components
import {
  NavigationScrollable,
  ContentPlaceholder,
  SkeletonHorizontal,
} from '@/components'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const transactions = useAppSelector(transactionSelectOutgoingTransactions)
  const transaction = transactions[index]

  // if transaction not fetched
  if (!transaction) {
    return (
      <div style={style}>
        <SkeletonHorizontal
          sections={[
            {
              name: 'transaction-list-transaction-income',
              width: '100%',
              height: '60px',
              isStatic: false,
              isDivider: false,
            },
          ]}
        />
      </div>
    )
  }

  return (
    <div css={styles().transactions.main} style={style}>
      <TransactionCard
        direction="outgoing"
        transaction={transaction}
        onClick={() => {}}
      />
    </div>
  )
})

type TransactionListProfileOutgoingProps = {}

export const TransactionListProfileOutgoing: FC<
  TransactionListProfileOutgoingProps
> = () => {
  const dispatch = useAppDispatch()

  const profile = useAppSelector(authSelectProfile)
  const isUserArtist = profile?.role === EntityUserRole.Artist

  const transactionOutgoingLoading = useAppSelector(
    transactionSelectTransactionGetOutgoingTransactionsLoading,
  )
  const outgoingTransactions = useAppSelector(transactionSelectOutgoingTransactions)
  const outgoingTransactionsCount = useAppSelector(
    transactionSelectOutgoingTransactionsCount,
  )
  const outgoingTransactionsLength = useAppSelector(
    transactionSelectOutgoingTransactionsLength,
  )
  const outgoingTransactionsFetched = useAppSelector(
    transactionSelectOutgoingTransactionsFetched,
  )
  const outgoingTransactionsFilter = useAppSelector(
    transactionSelectOutgoingTransactionsFilter,
  )

  const transactionCardHeight = 60
  const transactionCardMarginBottom = 20
  const itemHeight = transactionCardHeight + transactionCardMarginBottom

  const defaultContentCount = 1000
  const defaultListWidth = '100%'
  const defaultListHeight = 400
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!outgoingTransactions[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(
      transactionGetOutgoingTransactions({ limit: itemsToFetch, offset: startIndex }),
    )
  }

  // renders transaction list navigation
  const renderTransactionListNavigation = () => {
    const navigationOptions = [
      {
        id: 'c817c390-05a0-451f-84f9-3e0f789f65ec',
        label: 'Subscription',
        enabled: outgoingTransactionsFilter === EntityTransactionType.Subscription,
        onClick: () => {
          // set transaction filter
          dispatch(
            transactionSetOutgoingTransactionsFilter({
              filter: EntityTransactionType.Subscription,
            }),
          )

          // refetch transactions
          dispatch(transactionGetOutgoingTransactions({ limit: 30, offset: 0 }))
        },
      },
      {
        id: '31f31ae6-6941-4c7e-88ea-91e39567bf07',
        label: 'Unlock',
        enabled: outgoingTransactionsFilter === EntityTransactionType.Unlock,
        onClick: () => {
          // set transaction filter
          dispatch(
            transactionSetOutgoingTransactionsFilter({
              filter: EntityTransactionType.Unlock,
            }),
          )

          // refetch transactions
          dispatch(transactionGetOutgoingTransactions({ limit: 30, offset: 0 }))
        },
      },
      {
        id: 'cf8fe255-895a-40d0-98e7-0d591cd10450',
        label: 'Tip',
        enabled: outgoingTransactionsFilter === EntityTransactionType.Tip,
        onClick: () => {
          // set transaction filter
          dispatch(
            transactionSetOutgoingTransactionsFilter({
              filter: EntityTransactionType.Tip,
            }),
          )

          // refetch transactions
          dispatch(transactionGetOutgoingTransactions({ limit: 30, offset: 0 }))
        },
      },
    ]

    // if user role is artist
    if (isUserArtist) {
      // add payout option to navigation
      navigationOptions.push({
        id: 'a83d837d-2a30-4bef-8992-389ebc3ba340',
        label: 'Payout',
        enabled: outgoingTransactionsFilter === EntityTransactionType.Payout,
        onClick: () => {
          // set transaction filter
          dispatch(
            transactionSetOutgoingTransactionsFilter({
              filter: EntityTransactionType.Payout,
            }),
          )

          // refetch transactions
          dispatch(transactionGetOutgoingTransactions({ limit: 30, offset: 0 }))
        },
      })
    }

    return (
      <div css={styles().list.nav.main}>
        <NavigationScrollable options={navigationOptions} />
      </div>
    )
  }

  // renders transaction list table header
  const renderTransactionListTableHeader = () => (
    <div css={styles().list.header.main}>
      <div css={styles().list.header.placeholder} />
      <div css={styles().list.header.source}>Destination</div>
      <div css={styles().list.header.amount}>Amount</div>
      <div css={styles().list.header.type}>Type</div>
      <div css={styles().list.header.status}>Status</div>
      <div css={styles().list.header.date}>Date</div>
    </div>
  )

  // if outgoing transactions are fetched but length is 0
  if (
    !transactionOutgoingLoading &&
    outgoingTransactionsFetched &&
    outgoingTransactionsLength === 0
  ) {
    // return place holder
    return (
      // used to be content layout virtualized
      <div css={styles().list.main}>
        {renderTransactionListNavigation()}
        {renderTransactionListTableHeader()}
        <div css={styles().list.content.main}>
          <ContentPlaceholder
            icon={icons.CreditCardOutgoing}
            height="400px"
            title="You don't have any outgoing transactions yet"
          />
        </div>
      </div>
    )
  }

  return (
    // used to be content layout virtualized
    <div css={styles().list.main}>
      {renderTransactionListNavigation()}
      {renderTransactionListTableHeader()}
      <div css={styles().list.content.main}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          itemCount={outgoingTransactionsCount || defaultContentCount}
          minimumBatchSize={itemsToFetch}
          threshold={itemThreshold}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className="virtualized-list"
              height={defaultListHeight}
              itemSize={itemHeight}
              itemCount={outgoingTransactionsCount || defaultContentCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={defaultListWidth}
              layout="vertical"
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </div>
    </div>
  )
}

TransactionListProfileOutgoing.propTypes = {}

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityCustomerPaymentMethod } from '@ryddm-inc/ryddm-apiclient'

// icons
import { icons, cards } from '@/assets'

// styles
import { styles } from './styles'

type CustomerPaymentMethodProps = {
  customerPaymentMethod: EntityCustomerPaymentMethod
  selected: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}

export const CustomerPaymentMethod: FC<CustomerPaymentMethodProps> = ({
  customerPaymentMethod,
  selected,
  onClick,
}) => {
  const theme = useTheme()
  const { brand, last4, isDefault } = customerPaymentMethod

  const getPaymentMethodIcon = (cardBrand: string) => {
    switch (cardBrand) {
      case 'amex':
        return <cards.Amex />
      case 'diners':
        return <cards.Diners />
      case 'discover':
        return <cards.Discover />
      case 'eftpos_au':
        return <cards.Eft />
      case 'jcb':
        return <cards.Jcb />
      case 'mastercard':
        return <cards.Mastercard />
      case 'unionpay':
        return <cards.UnionPay />
      case 'visa':
        return <cards.Visa />
      default:
        return <cards.Card />
    }
  }

  return (
    <div css={styles(theme).method.main}>
      <div css={styles(theme).method.type}>{getPaymentMethodIcon(brand || '')}</div>
      <div
        css={[
          styles(theme).method.data.main.shared,
          selected && styles(theme).method.data.main.selected,
        ]}
        onClick={(e) => onClick(e)}
        aria-hidden="true"
      >
        <div css={styles(theme).method.data.circles}>&#9679;&#9679;&#9679;&#9679;</div>
        <div css={styles(theme).method.data.circles}>&#9679;&#9679;&#9679;&#9679;</div>
        <div css={styles(theme).method.data.circles}>&#9679;&#9679;&#9679;&#9679;</div>
        {last4 ? (
          <div css={styles(theme).method.data.digits}>{last4}</div>
        ) : (
          <div css={styles(theme).method.data.circles}>&#9679;&#9679;&#9679;&#9679;</div>
        )}
      </div>
      <div css={styles(theme).method.default}>{isDefault && <icons.CheckCircle />}</div>
    </div>
  )
}

CustomerPaymentMethod.propTypes = {}

import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  EntityNotificationEvent,
  EntityUnlockView,
  EntityUnlockStatus,
  EntityUnlockEntityType,
  EntityTrackView,
  EntityPostView,
} from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch, useTrack, usePost } from '@/hooks'
// features
import {
  // notification
  notificationSelectNotificationUnlockRetried,
  // player
  playerAudioSetCurrentTrack,
  // unlock
  unlockOpenUnlockTrackModal,
  unlockOpenUnlockPostModal,
} from '@/features'
// icons
import { icons } from '@/assets'

// components
import { Control } from './components'

type UnlockControlsProps = {
  event: EntityNotificationEvent
  unlock: EntityUnlockView
  isDisabled: boolean
}

export const UnlockControls: FC<UnlockControlsProps> = ({
  event,
  unlock,
  isDisabled,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const notificationUnlockRetried = useAppSelector(
    notificationSelectNotificationUnlockRetried,
  )

  const isTrackUnlock = unlock?.entityType === EntityUnlockEntityType.Track
  const isPostUnlock = unlock?.entityType === EntityUnlockEntityType.Post

  // unlock track
  const { track, isLoading: isTrackLoading } = useTrack(
    isTrackUnlock ? unlock.entityId || '' : '',
  )
  const isTrackControlDisabled = isTrackLoading || !track

  // unlock post
  const { post, isLoading: isPostLoading } = usePost(
    isPostUnlock ? unlock.entityId || '' : '',
  )
  const isPostControlDisabled = isPostLoading || !post

  // if unlock type is track
  if (unlock?.entityType === EntityUnlockEntityType.Track) {
    // if notification event is track unlock created
    if (event === EntityNotificationEvent.AppTrackUnlockCreated) {
      return (
        <Control
          appearance="neutral"
          label="play track"
          icon={icons.PlayCircleBold}
          text="Play track"
          disabled={isTrackControlDisabled}
          onClick={() =>
            dispatch(
              playerAudioSetCurrentTrack({
                track: track as EntityTrackView,
              }),
            )
          }
        />
      )
    }

    // if notification event is track unlock payment failed
    if (event === EntityNotificationEvent.AppTrackUnlockPaymentFailed) {
      const isUnlockRetryDisabled =
        isDisabled ||
        isTrackControlDisabled ||
        unlock?.status !== EntityUnlockStatus.Failed ||
        notificationUnlockRetried

      return (
        <>
          <Control
            appearance="neutral"
            label="navigate to target user page"
            icon={icons.UserCircle}
            text="View user"
            disabled={isDisabled}
            onClick={() => navigate(`${Path.User}/${unlock?.targetUserId}`)}
          />
          <Control
            appearance="neutral"
            label="retry track unlock"
            icon={icons.DollarCircle}
            text="Retry"
            disabled={isUnlockRetryDisabled}
            onClick={() =>
              dispatch(
                unlockOpenUnlockTrackModal({
                  track: track as EntityTrackView,
                }),
              )
            }
          />
        </>
      )
    }

    // if notification event is track unlock payment succeeded
    if (event === EntityNotificationEvent.AppTrackUnlockPaymentSucceeded) {
      return (
        <>
          <Control
            appearance="neutral"
            label="navigate to target user page"
            icon={icons.UserCircle}
            text="View user"
            disabled={isDisabled}
            onClick={() => navigate(`${Path.User}/${unlock?.targetUserId}`)}
          />
          <Control
            appearance="neutral"
            label="play track"
            icon={icons.PlayCircleBold}
            text="Play track"
            disabled={isTrackControlDisabled}
            onClick={() =>
              dispatch(
                playerAudioSetCurrentTrack({
                  track: track as EntityTrackView,
                }),
              )
            }
          />
        </>
      )
    }
  }

  // if post unlock
  if (unlock?.entityType === EntityUnlockEntityType.Post) {
    // if notification event is post unlock created
    if (event === EntityNotificationEvent.AppPostUnlockCreated) {
      return (
        <Control
          appearance="neutral"
          label="navigate to post page"
          icon={icons.Scope}
          text="View post"
          disabled={isDisabled}
          onClick={() => navigate(`${Path.Post}/${unlock?.entityId}`)}
        />
      )
    }

    // if notification event is post unlock payment failed
    if (event === EntityNotificationEvent.AppPostUnlockPaymentFailed) {
      const isUnlockRetryDisabled =
        isDisabled ||
        isPostControlDisabled ||
        unlock?.status !== EntityUnlockStatus.Failed ||
        notificationUnlockRetried

      return (
        <>
          <Control
            appearance="neutral"
            label="navigate to target user page"
            icon={icons.UserCircle}
            text="View user"
            disabled={isDisabled}
            onClick={() => navigate(`${Path.User}/${unlock?.targetUserId}`)}
          />
          <Control
            appearance="neutral"
            label="retry post unlock"
            icon={icons.DollarCircle}
            text="Retry"
            disabled={isUnlockRetryDisabled}
            onClick={() => {
              dispatch(
                unlockOpenUnlockPostModal({
                  post: post as EntityPostView,
                }),
              )
            }}
          />
        </>
      )
    }

    // if notification event is post unlock payment succeeded
    if (event === EntityNotificationEvent.AppPostUnlockPaymentSucceeded) {
      return (
        <>
          <Control
            appearance="neutral"
            label="navigate to target user page"
            icon={icons.UserCircle}
            text="View user"
            disabled={isDisabled}
            onClick={() => navigate(`${Path.User}/${unlock?.targetUserId}`)}
          />
          <Control
            appearance="neutral"
            label="navigate to post page"
            icon={icons.Scope}
            text="View post"
            disabled={isDisabled}
            onClick={() => navigate(`${Path.Post}/${unlock?.entityId}`)}
          />
        </>
      )
    }
  }

  return <div />
}

UnlockControls.propTypes = {}

import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useFile, useUserStats } from '@/hooks'
// features
import { authSelectProfile } from '@/features'
// components
import { ButtonMedium } from '@/components'
// icons
import { icons } from '@/assets'
// utils
import { numbersFormatter } from '@/utils'

// components
import { Stat } from './components'
// styles
import { styles } from './styles'

type UserViewStudioProps = {}

export const UserViewStudio: FC<UserViewStudioProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const profile = useAppSelector(authSelectProfile)

  const [avatarLoadError, setAvatarLoadError] = useState(false)

  const {
    id: userId,
    name,
    username,
    avatarId,
    followersCount,
    subscribersCount,
  } = profile ?? {}

  // user stats
  const {
    postsCount,
    tracksCount,
    isLoading: isUserStatsLoading,
  } = useUserStats(userId || '')
  const userStatsLoadingPlaceholder = 'Loading...'

  const artistHasAvatar = !!avatarId
  const source = useFile(avatarId || '')

  const createPostDisabled = false
  const uploadTrackDisabled = false

  const stats = [
    {
      id: 'studio-user-posts-stat',
      name: 'Posts',
      count: numbersFormatter(postsCount || 0),
    },
    {
      id: 'studio-user-tracks-stat',
      name: 'Tracks',
      count: numbersFormatter(tracksCount || 0),
    },
    {
      id: 'studio-user-followers-stat',
      name: 'Followers',
      count: numbersFormatter(followersCount || 0),
    },
    {
      id: 'studio-user-subscribers-stat',
      name: 'Subscribers',
      count: numbersFormatter(subscribersCount || 0),
    },
  ]

  return (
    <div css={styles(theme).view.main}>
      <div css={styles(theme).view.user.main}>
        {/* profile */}
        <div css={styles(theme).view.user.profile.main}>
          <div css={styles(theme).view.user.profile.avatar.main}>
            {artistHasAvatar && !avatarLoadError ? (
              <img
                css={styles(theme).view.user.profile.avatar.image}
                onError={() => setAvatarLoadError(true)}
                loading="lazy"
                width="100px"
                height="100px"
                src={source}
                alt="artist avatar"
              />
            ) : (
              <icons.User css={styles(theme).view.user.profile.avatar.icon} />
            )}
          </div>
          <div css={styles(theme).view.user.profile.content.main}>
            <div css={styles(theme).view.user.profile.content.data.main}>
              <div css={styles(theme).view.user.profile.content.data.name}>{name}</div>
              <div css={styles(theme).view.user.profile.content.data.username}>
                @{username}
              </div>
            </div>
            <div css={styles(theme).view.user.profile.content.stats}>
              {isUserStatsLoading
                ? userStatsLoadingPlaceholder
                : stats.map(({ id, name: statName, count }) => (
                    <Stat key={id} name={statName} count={count} />
                  ))}
            </div>
          </div>
        </div>

        {/* buttons */}
        <div css={styles(theme).view.user.buttons.main}>
          <div css={styles(theme).view.user.buttons.create.main}>
            <ButtonMedium
              type="button"
              aria-label="create post"
              disabled={createPostDisabled}
              appearance="secondary"
              onClick={() => navigate(`${Path.Studio}${Path.Post}`)}
            >
              <div css={styles(theme).view.user.buttons.create.content.main}>
                <div css={styles(theme).view.user.buttons.create.content.icon}>
                  <icons.PlusCircle />
                </div>
                <div css={styles(theme).view.user.buttons.create.content.text}>
                  Create Post
                </div>
              </div>
            </ButtonMedium>
          </div>
          <div css={styles(theme).view.user.buttons.upload.main}>
            <ButtonMedium
              type="button"
              aria-label="upload track"
              disabled={uploadTrackDisabled}
              appearance="secondary"
              onClick={() => navigate(`${Path.Studio}${Path.Track}`)}
            >
              <div css={styles(theme).view.user.buttons.upload.content.main}>
                <div css={styles(theme).view.user.buttons.upload.content.icon}>
                  <icons.PlusCircle />
                </div>
                <div css={styles(theme).view.user.buttons.upload.content.text}>
                  Upload Track
                </div>
              </div>
            </ButtonMedium>
          </div>
        </div>
      </div>
    </div>
  )
}

UserViewStudio.propTypes = {}

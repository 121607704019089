import { FC } from 'react'
import { EntityUserRole, EntityOnboardingStage } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector } from '@/hooks'
// features
import {
  // alert
  AlertModal,
  // auth
  authSelectProfile,
  // onboarding
  onboardingSelectStep,
} from '@/features'
// component
import { Stepper, ErrorFallback } from '@/components'

// components
import {
  SelectRole,
  SelectGenres,
  CreateProfile,
  FollowArtists,
  UploadTracks,
  EnableMonetization,
  ConfigureNotifications,
  AcceptTermsAndConditions,
} from './components'
// stages
import { fanOnboardingSteps, artistOnboardingSteps } from './steps'
// styles
import { styles } from './styles'

type OnboardingProps = {}

export const Onboarding: FC<OnboardingProps> = () => {
  const profile = useAppSelector(authSelectProfile)
  const step = useAppSelector(onboardingSelectStep)

  const isProfileRoleSelected = !!profile?.role
  const profileRole = profile?.role || ''
  const profileOnboardingStage = profile?.onboardingStage || ''

  const getCurrentStage = () => {
    const fanOnboardingStages = {
      [EntityOnboardingStage.CreateProfile]: <CreateProfile />,
      [EntityOnboardingStage.SelectGenres]: <SelectGenres />,
      [EntityOnboardingStage.FollowArtists]: <FollowArtists />,
      [EntityOnboardingStage.ConfigureNotifications]: <ConfigureNotifications />,
      [EntityOnboardingStage.AcceptTermsAndConditions]: <AcceptTermsAndConditions />,
    }

    const artistOnboardingStages = {
      [EntityOnboardingStage.CreateProfile]: <CreateProfile />,
      [EntityOnboardingStage.UploadTracks]: <UploadTracks />,
      [EntityOnboardingStage.EnableMonetization]: <EnableMonetization />,
      [EntityOnboardingStage.ConfigureNotifications]: <ConfigureNotifications />,
      [EntityOnboardingStage.AcceptTermsAndConditions]: <AcceptTermsAndConditions />,
    }

    // if role is fan
    if (profileRole && profileRole === EntityUserRole.Fan && profileOnboardingStage) {
      // return appropriate stage for fan
      return fanOnboardingStages[profileOnboardingStage]
    }

    // if role is artist
    if (profileRole && profileRole === EntityUserRole.Artist && profileOnboardingStage) {
      // return appropriate stage for artist
      return artistOnboardingStages[profileOnboardingStage]
    }

    return null
  }

  const renderOnboarding = () => {
    // get current onboarding stage
    const currentStage = getCurrentStage()

    // if role is not selected
    if (!isProfileRoleSelected) {
      // return select role stage
      return (
        <div css={styles().onboarding.role}>
          <SelectRole />
        </div>
      )
    }

    // if role is selected and role is 'fan'
    if (
      isProfileRoleSelected &&
      profileRole === EntityUserRole.Fan &&
      currentStage !== null
    ) {
      // return fan stage
      return (
        <div css={styles().onboarding.stage}>
          {currentStage}
          <Stepper steps={fanOnboardingSteps} currentStep={step} />
        </div>
      )
    }

    // if role is selected and role is 'artist'
    if (
      isProfileRoleSelected &&
      profileRole === EntityUserRole.Artist &&
      currentStage !== null
    ) {
      // return artist stage
      return (
        <div css={styles().onboarding.stage}>
          {currentStage}
          <Stepper steps={artistOnboardingSteps} currentStep={step} />
        </div>
      )
    }

    return <ErrorFallback />
  }

  return (
    <div css={styles().onboarding.main}>
      {renderOnboarding()}
      <AlertModal />
    </div>
  )
}

Onboarding.propTypes = {}

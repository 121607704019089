import { FC } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { cards } from '@/assets'

// styles
import { styles } from './styles'

type CustomerPaymentMethodDefaultProps = {
  brand?: string
  last4?: string
}

export const CustomerPaymentMethodDefault: FC<CustomerPaymentMethodDefaultProps> = ({
  brand = '',
  last4 = '',
}) => {
  const theme = useTheme()

  const getPaymentMethodIcon = (cardBrand: string) => {
    switch (cardBrand) {
      case 'amex':
        return <cards.Amex />
      case 'diners':
        return <cards.Diners />
      case 'discover':
        return <cards.Discover />
      case 'eftpos_au':
        return <cards.Eft />
      case 'jcb':
        return <cards.Jcb />
      case 'mastercard':
        return <cards.Mastercard />
      case 'unionpay':
        return <cards.UnionPay />
      case 'visa':
        return <cards.Visa />
      default:
        return <cards.Card />
    }
  }

  return (
    <div css={styles(theme).method.main}>
      <div css={styles(theme).method.type}>{getPaymentMethodIcon(brand || '')}</div>
      <div css={styles(theme).method.data.main}>
        <div css={styles(theme).method.data.circles}>&#9679;&#9679;&#9679;&#9679;</div>
        <div css={styles(theme).method.data.circles}>&#9679;&#9679;&#9679;&#9679;</div>
        <div css={styles(theme).method.data.circles}>&#9679;&#9679;&#9679;&#9679;</div>
        {last4 ? (
          <div css={styles(theme).method.data.digits}>{last4}</div>
        ) : (
          <div css={styles(theme).method.data.circles}>&#9679;&#9679;&#9679;&#9679;</div>
        )}
      </div>
    </div>
  )
}

CustomerPaymentMethodDefault.propTypes = {}

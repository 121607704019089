// updating this values will also require to update regex
// in @/utils/validatePostMessage
export const minPostMessageLength = 3
export const maxPostMessageLength = 1000

export const minPostUnlockPrice = 1
export const maxPostUnlockPrice = 9

export const maxPostTracksCount = 8

// 32 * 1024 * 1024 === 32 << 20 === 32 MiB
export const maxPostMediaFileSize = 32 * 1024 * 1024

import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  status: {
    main: css``,
    name: {
      shared: css`
        display: flex;
        align-items: center;

        height: 30px;
        border-radius: 20px;
        padding: 0px 26px;

        font-size: ${typography.p};
        font-weight: bold;
      `,
      pending: css`
        color: ${theme.colors.primary.normal};
        background-color: ${theme.colors.accent.normal};
      `,
      restricted: css`
        color: ${theme.colors.secondary.normal};
        background-color: ${theme.colors.negative.normal};
      `,
      enabled: css`
        color: ${theme.colors.primary.normal};
        background-color: ${theme.colors.positive.light};
      `,
      complete: css`
        color: ${theme.colors.primary.normal};
        background-color: ${theme.colors.positive.normal};
      `,
    },
  },
})

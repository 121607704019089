import { css, Theme } from '@emotion/react'
import { helpers, fonts, typography, z } from '@/styles'

export const styles = (theme: Theme) => ({
  input: {
    main: css`
      display: flex;
      flex-direction: column;
    `,

    label: css`
      font-size: ${typography.h6};
      font-weight: bold;
      color: ${theme.colors.secondary.light};

      margin-bottom: 10px;
    `,

    data: {
      main: css`
        position: relative;
      `,

      shared: css`
        ${helpers.resetDefaults};

        width: 100%;
        height: 28px;

        font-family: ${fonts.primary};
        font-size: ${typography.p};
        font-weight: bold;

        padding-bottom: 2px;
        padding-right: 28px;

        transition: border 0.2s;

        &:focus {
          border-bottom: 1px solid ${theme.colors.accent.normal};
        }

        &::placeholder {
          color: ${theme.colors.secondary.darkHover};
        }

        // https://stackoverflow.com/questions/20804016/editing-input-type-search-pseudo-element-button-x
        &::-webkit-search-cancel-button {
        }
      `,

      empty: css`
        border-bottom: 1px solid ${theme.colors.secondary.dark};
        color: ${theme.colors.secondary.normal};
      `,

      filled: css`
        border-bottom: 1px solid ${theme.colors.secondary.dark};
        color: ${theme.colors.secondary.normal};
      `,

      disabled: css`
        cursor: not-allowed;
        border-bottom: 1px solid ${theme.colors.secondary.dark};
        color: ${theme.colors.secondary.dark};
      `,

      errored: css`
        border-bottom: 1px solid ${theme.colors.negative.normal};
        color: ${theme.colors.secondary.normal};
      `,

      icon: {
        shared: css`
          z-index: ${z.components.elements.inputLine.icon};

          position: absolute;
          right: 0;
          top: 0;

          display: flex;
          align-items: center;
          justify-content: center;

          width: 28px;
          height: 28px;

          & > svg {
            width: 14px;
            height: 14px;

            & > path {
              transition: fill 0.2s;
            }
          }
        `,

        empty: css`
          & > svg > path {
            fill: ${theme.colors.accent.normal};
          }
        `,

        filled: css`
          & > svg > path {
            fill: ${theme.colors.accent.normal};
          }
        `,

        disabled: css`
          & > svg > path {
            fill: ${theme.colors.secondary.dark};
          }
        `,

        errored: css`
          & > svg > path {
            fill: ${theme.colors.negative.normal};
          }
        `,
      },
    },

    error: {
      shared: css`
        display: flex;
        min-width: 0;

        transition: height 0.2s;
      `,
      visible: css`
        height: 24px;
      `,
      hidden: css`
        height: 0px;
      `,

      text: {
        shared: css`
          ${helpers.textOverflowEllipsis};

          font-size: ${typography.p};
          color: ${theme.colors.negative.normal};

          transition: opacity 0.4s;
          padding-left: 14px;
        `,
        visible: css`
          opacity: 1;
        `,
        hidden: css`
          opacity: 0;
        `,
      },
    },
  },
})

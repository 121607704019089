import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityPostVisibility } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // post
  PostCard,
  PostCardLocked,
  postSelectLikedPosts,
  postSelectLikedPostsCount,
  postSelectLikedPostsLength,
  postSelectLikedPostsFetched,
  postGetLikedPosts,
  postUtilGetPostCardSkeletonSections,
  // subscription
  subscriptionSelectUserSubscriptions,
  // unlock
  unlockOpenUnlockPostModal,
  unlockSelectPostUnlocks,
} from '@/features'
// components
import {
  ContentLayoutVirtualized,
  ContentPlaceholder,
  SkeletonVertical,
} from '@/components'
// icons
import { icons } from '@/assets'
// styles
import { contentLayout } from '@/styles'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const postUnlocks = useAppSelector(unlockSelectPostUnlocks)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const posts = useAppSelector(postSelectLikedPosts)
  const post = posts[index]

  // if post not fetched
  if (!post) {
    return (
      <div style={style}>
        <SkeletonVertical
          containerWidth="180px"
          sections={postUtilGetPostCardSkeletonSections('post-list-library-liked')}
        />
      </div>
    )
  }

  const { id: currentUserId } = profile ?? {}
  const { id: postId, userId: postAuthorUserId, visibility } = post

  const isContentLocked = visibility === EntityPostVisibility.Subscribers
  const isCurrentUserContentAuthor = currentUserId === postAuthorUserId
  const isCurrentUserUnlockedContent = postUnlocks[postId || '']
  const isCurrentUserSubscribedToContentAuthor = userSubscriptions[postAuthorUserId || '']

  const renderLocked =
    isContentLocked &&
    !isCurrentUserContentAuthor &&
    !isCurrentUserUnlockedContent &&
    !isCurrentUserSubscribedToContentAuthor

  // if content is locked
  if (renderLocked) {
    return (
      <div css={styles().posts.main} style={style}>
        <PostCardLocked
          post={post}
          onClick={() => dispatch(unlockOpenUnlockPostModal({ post }))}
          onAuthorNameClick={() => navigate(`${Path.User}/${postAuthorUserId}`)}
          onAuthorUsernameClick={() => navigate(`${Path.User}/${postAuthorUserId}`)}
        />
      </div>
    )
  }

  return (
    <div css={styles().posts.main} style={style}>
      <PostCard
        post={post}
        currentUserId={currentUserId || ''}
        onClick={() => navigate(`${Path.Post}/${postId}`)}
        onAuthorNameClick={() => navigate(`${Path.User}/${postAuthorUserId}`)}
        onAuthorUsernameClick={() => navigate(`${Path.User}/${postAuthorUserId}`)}
      />
    </div>
  )
})

type PostListLibraryLikedProps = {}

export const PostListLibraryLiked: FC<PostListLibraryLikedProps> = () => {
  const dispatch = useAppDispatch()
  const likedPosts = useAppSelector(postSelectLikedPosts)
  const likedPostsCount = useAppSelector(postSelectLikedPostsCount)
  const likedPostsLength = useAppSelector(postSelectLikedPostsLength)
  const likedPostsFetched = useAppSelector(postSelectLikedPostsFetched)

  const postCardWidth = 180
  const postCardMarginRight = 20
  const itemWidth = postCardWidth + postCardMarginRight

  const postCardHeight = 294
  const postCardMarginBottom = 0
  const itemHeight = postCardHeight + postCardMarginBottom

  const defaultContentCount = 1000
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!likedPosts[index]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(postGetLikedPosts({ limit: itemsToFetch, offset: startIndex }))
  }

  // if liked posts are fetched but length is 0
  if (likedPostsFetched && likedPostsLength === 0) {
    // return place holder
    return (
      <ContentLayoutVirtualized
        title="Liked Posts"
        overrideCss={contentLayout.library}
        itemWidth={itemWidth}
        itemsCount={likedPostsCount}
      >
        <ContentPlaceholder
          icon={icons.Posts}
          height="294px"
          title="You haven't liked any post yet"
        />
      </ContentLayoutVirtualized>
    )
  }

  return (
    <ContentLayoutVirtualized
      title="Liked Posts"
      overrideCss={contentLayout.library}
      itemWidth={itemWidth}
      itemsCount={likedPostsCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={likedPostsCount || defaultContentCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={likedPostsCount || defaultContentCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

PostListLibraryLiked.propTypes = {}

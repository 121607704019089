import { css } from '@emotion/react'
import { helpers, mq, z } from '@/styles'

export const styles = () => ({
  stage: {
    main: css`
      ${helpers.hideScrollbar};

      width: 100%;
      height: 100%;

      overflow: hidden;
      overflow-y: scroll;
    `,

    backward: {
      main: css`
        z-index: ${z.onboarding.backward};

        position: fixed;
        top: 20px;
        left: 20px;
      `,
      button: css``,
      icon: css`
        width: 24px;
        height: 24px;
      `,
    },

    role: {
      main: css`
        display: grid;
        grid-template-rows: repeat(1, 1fr);
        grid-template-columns: repeat(2, 1fr);

        width: 100%;
        height: 100%;

        ${mq.tablet(css`
          display: flex;
          flex-direction: column;
        `)}
      `,

      fan: css``,

      artist: css``,
    },
  },
})

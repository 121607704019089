import { EntityReportComplaintType } from '@ryddm-inc/ryddm-apiclient'

export const minReportComplaintLength = 1
export const maxReportComplaintLength = 256

export const complaintTypeSelectOptions = [
  {
    id: 'complaint-type-select-option-child-abuse',
    label: 'Child Abuse',
    value: EntityReportComplaintType.ChildAbuse.toString(),
  },
  {
    id: 'complaint-type-select-option-copyright',
    label: 'Copyright',
    value: EntityReportComplaintType.Copyright.toString(),
  },
  {
    id: 'complaint-type-select-option-fake',
    label: 'Fake',
    value: EntityReportComplaintType.Fake.toString(),
  },
  {
    id: 'complaint-type-select-option-pornography',
    label: 'Pornography',
    value: EntityReportComplaintType.Pornography.toString(),
  },
  {
    id: 'complaint-type-select-option-spam',
    label: 'Spam',
    value: EntityReportComplaintType.Spam.toString(),
  },
  {
    id: 'complaint-type-select-option-violence',
    label: 'Violence',
    value: EntityReportComplaintType.Violence.toString(),
  },
]

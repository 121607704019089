import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type BadgeSelectedProps = {
  name: string
  onRemoveClick: MouseEventHandler<HTMLButtonElement>
}

export const BadgeSelected: FC<BadgeSelectedProps> = ({ name, onRemoveClick }) => {
  const theme = useTheme()
  return (
    <div css={styles(theme).badge.main}>
      <div css={styles(theme).badge.close.main}>
        <button
          css={styles(theme).badge.close.button}
          onClick={(e) => onRemoveClick(e)}
          type="button"
          aria-label="log out"
        >
          <icons.CrossCircle css={styles(theme).badge.close.icon} />
        </button>
      </div>
      <div css={styles(theme).badge.name}>{name}</div>
    </div>
  )
}

BadgeSelected.propTypes = {}

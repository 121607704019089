import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { reportSelectReportTrackModalOpen, reportCloseReportTrackModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { ReportTrackCard } from './components'
// styles
import { styles } from './styles'

type ReportTrackModalProps = {}

export const ReportTrackModal: FC<ReportTrackModalProps> = () => {
  const dispatch = useAppDispatch()
  const reportTrackModalOpen = useAppSelector(reportSelectReportTrackModalOpen)

  return (
    <div css={styles().modal.main}>
      {reportTrackModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(reportCloseReportTrackModal())}
        >
          <div css={styles().modal.content}>
            <ReportTrackCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

ReportTrackModal.propTypes = {}

import { FC, ButtonHTMLAttributes, ReactNode } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface NavigationButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
}

export const NavigationButton: FC<NavigationButtonProps> = ({
  children,
  ...attributes
}) => {
  const theme = useTheme()

  return (
    <button
      css={[
        styles(theme).button.shared,
        attributes.disabled
          ? styles(theme).button.disabled
          : styles(theme).button.enabled,
      ]}
      type="button"
      {...attributes}
    >
      {children}
    </button>
  )
}

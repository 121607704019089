import { createSlice } from '@reduxjs/toolkit'

// stores
import { RootState } from '@/stores'
// features
import { streamCreatePostStream, streamCreateTrackStream } from '@/features'

export interface StreamState {
  streamCreatePostStreamLoading: boolean
  streamCreateTrackStreamLoading: boolean
}

const initialState: StreamState = {
  streamCreatePostStreamLoading: false,
  streamCreateTrackStreamLoading: false,
}

export const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    // reset state
    streamResetState: () => ({
      streamCreatePostStreamLoading: false,
      streamCreateTrackStreamLoading: false,
    }),
  },
  extraReducers: (builder) => {
    // create post stream
    builder
      .addCase(streamCreatePostStream.pending, (state) => ({
        ...state,
        streamCreatePostStreamLoading: true,
      }))
      .addCase(streamCreatePostStream.fulfilled, (state) => ({
        ...state,
        streamCreatePostStreamLoading: false,
      }))

    // create track stream
    builder
      .addCase(streamCreateTrackStream.pending, (state) => ({
        ...state,
        streamCreateTrackStreamLoading: true,
      }))
      .addCase(streamCreateTrackStream.fulfilled, (state) => ({
        ...state,
        streamCreateTrackStreamLoading: false,
      }))
  },
})

export const { streamResetState } = streamSlice.actions

export const streamSelectStreamCreatePostStreamLoading = (state: RootState) =>
  state.stream.streamCreatePostStreamLoading
export const streamSelectStreamCreateTrackStreamLoading = (state: RootState) =>
  state.stream.streamCreateTrackStreamLoading

export const streamReducer = streamSlice.reducer

// export default streamSlice.reducer

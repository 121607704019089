import { css } from '@emotion/react'

export const styles = () => ({
  skeleton: {
    main: css`
      width: 280px;

      &:not(:last-of-type) {
        margin-right: 20px;
      }

      & > div:not(:last-of-type) {
        margin-bottom: 24px;
      }
    `,
  },
})

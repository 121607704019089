import { css, Theme } from '@emotion/react'
import { typography, helpers, fonts } from '@/styles'

export const styles = (theme: Theme) => ({
  genre: {
    main: css`
      display: inline;
    `,
    button: {
      shared: css`
        ${helpers.resetDefaults};

        font-family: ${fonts.primary};
        font-size: ${typography.p};
        font-weight: bold;
        white-space: nowrap;

        border-radius: 20px;
        border: 1px solid transparent;
        padding: 8px 26px;
        cursor: pointer;

        transition: color 0.2s, background-color 0.2s, border 0.2s;
      `,
      selected: css`
        border: 1px solid ${theme.colors.accent.normal};
        background-color: ${theme.colors.accent.normal};
        color: ${theme.colors.primary.normal};
      `,
      unselected: css`
        border: 1px solid ${theme.colors.accent.normal};
        background-color: ${theme.colors.primary.normal};
        color: ${theme.colors.secondary.normal};
      `,
    },
  },
})

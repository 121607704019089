import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  EntityReportEntityType,
  EntityUserView,
  EntityTrackView,
  EntityPostView,
  EntityCommentView,
} from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import {
  useAppSelector,
  useAppDispatch,
  useUser,
  useTrack,
  usePost,
  useComment,
} from '@/hooks'
// features
import {
  // comment
  commentAddDepth,
  commentOpenCommentBlockModal,
  // report
  reportSelectReport,
  reportSelectReportFetched,
  reportSelectReportEntityBlocked,
  reportSelectReportUserBlocked,
  reportOpenReportResolveModal,
  // player
  playerAudioSetCurrentTrack,
  // post
  postOpenPostBlockModal,
  // track
  trackOpenTrackBlockModal,
  // user
  userOpenUserBlockModal,
} from '@/features'
// components
import { ContentPlaceholder, SkeletonHorizontal } from '@/components'
// icons
import { icons } from '@/assets'

// components
import { Identifier, Control } from './components'
// styles
import { styles } from './styles'

type ReportViewReportProps = {}

export const ReportViewReport: FC<ReportViewReportProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const report = useAppSelector(reportSelectReport)
  const reportFetched = useAppSelector(reportSelectReportFetched)
  const reportEntityBlocked = useAppSelector(reportSelectReportEntityBlocked)
  const reportUserBlocked = useAppSelector(reportSelectReportUserBlocked)

  const { shortId, entityType, entityId, entityUserId } = report ?? {}
  const isTrackReport = entityType === EntityReportEntityType.Track
  const isPostReport = entityType === EntityReportEntityType.Post
  const isCommentReport = entityType === EntityReportEntityType.Comment

  // report user
  const { user: reportUser, isLoading: isReportUserLoading } = useUser(entityUserId || '')

  // report track
  const { track: reportTrack, isLoading: isReportTrackLoading } = useTrack(
    isTrackReport ? entityId || '' : '',
  )

  // report post
  const { post: reportPost, isLoading: isReportPostLoading } = usePost(
    isPostReport ? entityId || '' : '',
  )

  // report comment
  const { comment: reportComment, isLoading: isReportCommentLoading } = useComment(
    isCommentReport ? entityId || '' : '',
  )

  const isUserControlsDisabled = isReportUserLoading || !reportUser || reportUserBlocked
  const isTrackControlsDisabled =
    isReportTrackLoading || !reportTrack || reportEntityBlocked
  const isPostControlsDisabled = isReportPostLoading || !reportPost || reportEntityBlocked
  const isCommentControlsDisabled =
    isReportCommentLoading || !reportComment || reportEntityBlocked

  // if report not found
  if (reportFetched && !report) {
    return (
      <div css={styles().view.main}>
        <ContentPlaceholder
          icon={icons.AlertTriangle}
          height="119px"
          title="Could not find report"
        />
      </div>
    )
  }

  const renderReportContentNavigation = () => {
    // if content type is track
    if (isTrackReport) {
      return (
        <Control
          appearance="neutral"
          label="play track"
          icon={icons.PlayCircleBold}
          text="Play track"
          disabled={isTrackControlsDisabled}
          onClick={() =>
            dispatch(
              playerAudioSetCurrentTrack({
                track: reportTrack as EntityTrackView,
              }),
            )
          }
        />
      )
    }

    // if content type is post
    if (isPostReport) {
      return (
        <Control
          appearance="neutral"
          label="navigate to post page"
          icon={icons.Scope}
          text="View post"
          disabled={isPostControlsDisabled}
          onClick={() => navigate(`${Path.Post}/${entityId}`)}
        />
      )
    }

    // if content type is comment
    if (isCommentReport) {
      return (
        <Control
          appearance="neutral"
          label="navigate to comment"
          icon={icons.Scope}
          text="View comment"
          disabled={isCommentControlsDisabled}
          onClick={() => {
            // add comment to comment depth
            dispatch(
              commentAddDepth({
                comment: reportComment as EntityCommentView,
              }),
            )

            // navigate to post page
            navigate(`${Path.Post}/${reportComment?.postId}`)
          }}
        />
      )
    }

    return <div />
  }

  const renderReportContentBlockControl = () => {
    // if content type is track
    if (isTrackReport) {
      return (
        <Control
          appearance="negative"
          label="block track"
          icon={icons.CrossSquare}
          text="Block track"
          disabled={isTrackControlsDisabled}
          onClick={() =>
            dispatch(
              trackOpenTrackBlockModal({
                track: reportTrack as EntityTrackView,
              }),
            )
          }
        />
      )
    }

    // if content type is post
    if (isPostReport) {
      return (
        <Control
          appearance="negative"
          label="block post"
          icon={icons.CrossSquare}
          text="Block post"
          disabled={isPostControlsDisabled}
          onClick={() =>
            dispatch(
              postOpenPostBlockModal({
                post: reportPost as EntityPostView,
              }),
            )
          }
        />
      )
    }

    // if content type is comment
    if (isCommentReport) {
      return (
        <Control
          appearance="negative"
          label="block comment"
          icon={icons.CrossSquare}
          text="Block comment"
          disabled={isCommentControlsDisabled}
          onClick={() =>
            dispatch(
              commentOpenCommentBlockModal({
                comment: reportComment as EntityCommentView,
              }),
            )
          }
        />
      )
    }

    return <div />
  }

  // if report found
  if (reportFetched && report) {
    return (
      <div css={styles().view.main}>
        <div css={styles().view.content.main}>
          <div css={styles().view.content.identifier}>
            <Identifier identifier={shortId || ''} />
          </div>
          <div css={styles().view.content.navigation}>
            <Control
              appearance="neutral"
              label="navigate to user page"
              icon={icons.UserCircle}
              text="View user"
              disabled={isUserControlsDisabled}
              onClick={() => navigate(`${Path.User}/${entityUserId}`)}
            />
            {renderReportContentNavigation()}
          </div>
          <div css={styles().view.content.controls}>
            <Control
              appearance="negative"
              label="block user"
              icon={icons.CrossSquare}
              text="Block user"
              disabled={isUserControlsDisabled}
              onClick={() =>
                dispatch(
                  userOpenUserBlockModal({
                    user: reportUser as EntityUserView,
                  }),
                )
              }
            />
            {renderReportContentBlockControl()}
            <Control
              appearance="positive"
              label="resolve report"
              icon={icons.CheckSquare}
              text="Resolve report"
              disabled={false}
              onClick={() => dispatch(reportOpenReportResolveModal({ report }))}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div css={styles().view.main}>
      <SkeletonHorizontal
        sections={[
          {
            name: 'report-view-reports-report',
            width: '100%',
            height: '119px',
            isStatic: false,
            isDivider: false,
          },
        ]}
      />
    </div>
  )
}

ReportViewReport.propTypes = {}

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type DividerProps = {}

export const Divider: FC<DividerProps> = () => {
  const theme = useTheme()

  return (
    <div css={styles(theme).divider.main}>
      <div css={styles(theme).divider.bar} />
    </div>
  )
}

Divider.propTypes = {}

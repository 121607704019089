import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  title: {
    main: css`
      text-align: center;
      font-size: ${typography.h4};
      font-weight: bold;
    `,
    accent: css`
      color: ${theme.colors.accent.normal};
    `,
  },
})

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { authCloseResetModal, authSelectResetModalOpen } from '@/features'

// components
import { ModalLayout } from '@/components'
import { ResetCard } from './components'

// styles
import { styles } from './AuthResetModal.styles'

type AuthResetModalProps = {}

export const AuthResetModal: FC<AuthResetModalProps> = () => {
  const dispatch = useAppDispatch()
  const resetModalOpen = useAppSelector(authSelectResetModalOpen)

  return (
    <div css={styles().modal.main}>
      {resetModalOpen && (
        <ModalLayout
          background="solid"
          onOutsideClick={() => dispatch(authCloseResetModal())}
        >
          <ResetCard />
        </ModalLayout>
      )}
    </div>
  )
}

AuthResetModal.propTypes = {}

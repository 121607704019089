import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  fallback: {
    shared: css`
      ${helpers.app.padding.full};

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: ${theme.colors.primary.normal};
    `,
    fit: css`
      width: 100%;
      height: 100%;
    `,
    full: css`
      min-width: 100vw;
      min-height: 100vh;
    `,
  },
})

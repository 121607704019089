import { css, Theme } from '@emotion/react'
import { typography, mq, constants } from '@/styles'

export const styles = (theme: Theme) => ({
  navigation: {
    main: css`
      display: flex;
      flex-direction: column;

      flex: 1;

      width: ${constants.app.navigation.desktop.width};
      height: ${constants.app.navigation.desktop.height};

      background-color: ${theme.colors.primary.darker};

      ${mq.tablet(css`
        display: none;
      `)}
    `,
    logo: css`
      display: flex;
      align-items: center;
      justify-content: center;

      height: ${constants.app.header.desktop.height};
      padding: 20px 20px;

      & > svg {
        width: 140px;
        height: 24px;
      }
    `,
    links: css`
      flex: 1;

      margin-bottom: auto;
      padding: 20px 20px;

      & > ul {
        list-style-type: none;

        & > li:not(:last-of-type) {
          margin-bottom: 6px;
        }
      }
    `,

    footer: css`
      padding: 20px 20px 40px 20px;
      font-size: ${typography.sp};

      color: ${theme.colors.secondary.dark};
    `,
  },
})

import { FC } from 'react'

// config
import { ExternalLinks } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { onboardingSelectError, onboardingSetPrevStage } from '@/features'
// icons
import { icons } from '@/assets'
// components
import { ButtonRound, Subtitle, ImageComposition, Error } from '@/components'

// components
import { Form } from './components'
// styles
import { styles } from './styles'

type AcceptTermsAndConditionsProps = {}

export const AcceptTermsAndConditions: FC<AcceptTermsAndConditionsProps> = () => {
  const dispatch = useAppDispatch()
  const onboardingError = useAppSelector(onboardingSelectError)

  return (
    <div css={styles().stage.main}>
      <div css={styles().stage.backward.button}>
        <ButtonRound
          aria-label="set previous onboarding stage"
          appearance="secondary"
          onClick={() => dispatch(onboardingSetPrevStage())}
        >
          <icons.ArrowLeft css={styles().stage.backward.icon} />
        </ButtonRound>
      </div>

      <div css={styles().stage.content.main}>
        <div css={styles().stage.content.title}>
          <Subtitle
            text="By continuing you agree to RYDDM terms and conditions"
            link={{
              linkStart: 33,
              linkEnd: 53,
              href: ExternalLinks.TermsOfUse,
            }}
          />
        </div>

        <div css={styles().stage.content.composition}>
          <ImageComposition />
        </div>

        <div css={styles().stage.content.form}>
          <Form />
        </div>

        <div css={styles().stage.content.error}>
          <Error error={onboardingError} />
        </div>
      </div>
    </div>
  )
}

AcceptTermsAndConditions.propTypes = {}

import { Appearance } from '@stripe/stripe-js'
import { Theme } from '@emotion/react'
import { fonts, typography } from '@/styles'

// stripe payment element styles (appearance)
export const stripeAppearance = (theme: Theme): Appearance => ({
  theme: 'night', // 'stripe' | 'flat'
  labels: 'above', // 'floating'
  disableAnimations: false,
  variables: {
    // General font styles
    fontFamily: fonts.primary,
    // fontSmooth?: string;
    // fontVariantLigatures?: string;
    // fontVariationSettings?: string;
    // fontLineHeight?: string;
    // Font sizes
    fontSizeBase: typography.sp,
    // fontSizeSm?: string;
    // fontSizeXs?: string;
    // fontSize2Xs?: string;
    // fontSize3Xs?: string;
    // fontSizeLg?: string;
    // fontSizeXl?: string;
    // Font weights
    // fontWeightLight?: string;
    // fontWeightNormal?: string;
    // fontWeightMedium?: string;
    // fontWeightBold?: string;
    // Spacing
    // spacingUnit?: string;
    // spacingGridRow?: string;
    // spacingGridColumn?: string;
    // spacingTab?: string;
    // spacingAccordionItem?: string;
    // Colors
    colorPrimary: theme.colors.accent.normal,
    // colorPrimaryText?: string;
    colorBackground: theme.colors.primary.normal,
    // colorBackgroundText?: string;
    colorText: theme.colors.secondary.normal,
    // colorSuccess?: string;
    // colorSuccessText?: string;
    colorDanger: theme.colors.negative.normal,
    colorDangerText: theme.colors.negative.normal,
    // colorWarning?: string;
    // colorWarningText?: string;
    // Text variations
    // colorTextSecondary?: string;
    colorTextPlaceholder: theme.colors.secondary.dark,
    // Icons
    colorIcon: theme.colors.accent.normal,
    // colorIconHover?: string;
    // colorIconCardError?: string;
    // colorIconCardCvc?: string;
    // colorIconCardCvcError?: string;
    // colorIconCheckmark?: string;
    // colorIconChevronDown?: string;
    // colorIconChevronDownHover?: string;
    // colorIconRedirect?: string;
    // colorIconTab?: string;
    // colorIconTabHover?: string;
    // colorIconTabSelected?: string;
    // colorIconTabMore?: string;
    // colorIconTabMoreHover?: string;
    // Logos
    // colorLogo?: string;
    // colorLogoTab?: string;
    // colorLogoTabSelected?: string;
    // colorLogoBlock?: string;
    // Focus
    focusBoxShadow: 'none',
    // focusOutline?: string,
    // Radius
    borderRadius: '14px',
  },
  //
  // rules?: {
  //   [selector: string]: {
  //     [cssPropertyName: string]: string;
  //   };
  // };
  //
})

import HomeIcon from './home.svg'
import LibraryIcon from './library.svg'
import ChartsIcon from './charts.svg'
import ProfileIcon from './profile.svg'
import StudioIcon from './studio.svg'
import ReportsIcon from './reports.svg'

export const icons = {
  HomeIcon,
  LibraryIcon,
  ChartsIcon,
  ProfileIcon,
  StudioIcon,
  ReportsIcon,
}

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type FeatureProps = {
  title: string
  description: string
  icon: any
  appearance?: 'primary' | 'secondary'
}

export const Feature: FC<FeatureProps> = ({
  title,
  description,
  icon: Icon,
  appearance = 'primary',
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).feature.main}>
      <div css={styles(theme).feature.icon.main}>
        {Icon && (
          <Icon
            css={[
              styles(theme).feature.icon.content.shared,
              appearance === 'primary' && styles(theme).feature.icon.content.primary,
              appearance === 'secondary' && styles(theme).feature.icon.content.secondary,
            ]}
          />
        )}
      </div>

      <div
        css={[
          styles(theme).feature.content.main.shared,
          appearance === 'primary' && styles(theme).feature.content.main.primary,
          appearance === 'secondary' && styles(theme).feature.content.main.secondary,
        ]}
      >
        <div css={styles(theme).feature.content.title}>{title}</div>
        <div css={styles(theme).feature.content.description}>{description}</div>
      </div>
    </div>
  )
}

Feature.propTypes = {}

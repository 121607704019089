import { FC, useEffect, useState, useRef, ChangeEvent } from 'react'
import { EntityTrackVisibility } from '@ryddm-inc/ryddm-apiclient'

// config
import {
  musicGenres as musGen,
  maxTrackGenresCount,
  minTrackNameLength,
  maxTrackNameLength,
  minTrackUnlockPrice,
  maxTrackUnlockPrice,
  maxTrackCoverFileSize,
} from '@/config'
// hooks
import { useAppSelector, useAppDispatch, useConnectReady } from '@/hooks'
// features
import { authSelectProfile, trackSelectTrackCreateLoading, trackCreate } from '@/features'
// utils
import { validateCoverFile, validateTrackFile, validateTrackName } from '@/utils'
// icons
import { icons } from '@/assets'
// components
import {
  Loader,
  InputLine,
  InputAmount,
  InputLabel,
  MediaFilePreview,
  CheckboxOption,
  ButtonMedium,
  Badge,
  BadgeSelected,
  Error,
} from '@/components'

// components
import { TrackPreview } from './components'
// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const trackCreateLoading = useAppSelector(trackSelectTrackCreateLoading)

  // connect ready
  const { ready: connectReady } = useConnectReady(profile?.id || '')

  // cover state
  const [cover, setCover] = useState<File | undefined>(undefined)
  const [coverPreview, setCoverPreview] = useState('')
  const coverFileInputRef = useRef<HTMLInputElement | null>(null)
  const [coverError, setCoverError] = useState('')

  // track state
  const [track, setTrack] = useState<File | undefined>(undefined)
  const [trackFileName, setTrackFileName] = useState('')
  const trackFileInputRef = useRef<HTMLInputElement | null>(null)
  const [trackError, setTrackError] = useState('')

  // name state
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')

  // genres state
  const [genres, setGenres] = useState<string[]>([])
  const [musicGenres, setMusicGenres] = useState(musGen)

  // genres search state
  const [search, setSearch] = useState('')

  // visibility state
  const [visibility, setVisibility] = useState(EntityTrackVisibility.Public)

  // unlock price state
  const defaultUnlockPrice = Math.ceil(maxTrackUnlockPrice / 2)
  const [unlockPrice, setUnlockPrice] = useState(defaultUnlockPrice)

  const submitDisabled =
    name.length < minTrackNameLength ||
    name.length > maxTrackNameLength ||
    unlockPrice < minTrackUnlockPrice ||
    unlockPrice > maxTrackUnlockPrice ||
    track === undefined ||
    coverError !== '' ||
    trackError !== '' ||
    nameError !== '' ||
    trackCreateLoading

  const revokePreviewURL = (url: string) => {
    URL.revokeObjectURL(url)
  }

  const cleanupState = () => {
    setCover(undefined)
    setTrack(undefined)
    setName('')
    setTrackFileName('')
    setGenres([])
    setVisibility(EntityTrackVisibility.Public)
    setUnlockPrice(defaultUnlockPrice)

    // if cover preview was set
    if (coverPreview) {
      // clean up to avoid memory leaks
      revokePreviewURL(coverPreview)
      setCoverPreview('')
    }
  }

  const onCoverClick = () => {
    coverFileInputRef.current?.click()
    setCoverError('')
  }

  const onTrackClick = () => {
    trackFileInputRef.current?.click()
    setTrackError('')
  }

  const onCoverFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    // if file selected
    if (files && files.length > 0) {
      const file = files[0]

      // validate file
      const error = validateCoverFile(file)
      if (error) {
        setCoverError(error)
        return
      }

      // if cover preview was set
      if (coverPreview) {
        // clean up to avoid memory leaks
        revokePreviewURL(coverPreview)
      }

      // set file as a preview
      setCoverPreview(URL.createObjectURL(file))

      // store file in state
      setCover(file)
    }
  }

  const onTrackFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    // if file selected
    if (files && files.length > 0) {
      const file = files[0]

      // validate file
      const error = validateTrackFile(file)
      if (error) {
        setTrackError(error)
        return
      }

      // store file name in state
      setTrackFileName(file.name)

      // store file in state
      setTrack(file)
    }
  }

  useEffect(() => () => {
    // if cover preview was set
    if (coverPreview) {
      // clean up to avoid memory leaks
      revokePreviewURL(coverPreview)
    }
  })

  useEffect(() => {
    if (!trackCreateLoading) {
      cleanupState()
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [trackCreateLoading])

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(trackCreate({ name, track, cover, genres, visibility, unlockPrice }))
        }}
      >
        {/* cover */}
        <div css={styles().form.content.cover.main}>
          <div css={styles().form.content.cover.label}>
            <InputLabel text="Cover" />
          </div>
          <div css={styles().form.content.cover.preview.main}>
            <label css={styles().form.content.cover.preview.label} htmlFor="cover">
              cover
              <input
                name="cover"
                type="file"
                id="cover"
                ref={coverFileInputRef}
                accept="image/jpeg,image/webp,image/png"
                onChange={(e) => onCoverFileChange(e)}
              />
            </label>
            <div css={styles().form.content.cover.preview.image}>
              <MediaFilePreview
                source={coverPreview}
                type="image"
                width="140px"
                height="140px"
                alt="track cover"
                onClick={() => onCoverClick()}
                // formats={['JPG', 'JPEG', 'PNG', 'WEBP']}
                maxFileSize={maxTrackCoverFileSize}
              />
            </div>
          </div>
          <div css={styles().form.content.cover.error}>
            <Error error={coverError} />
          </div>
        </div>

        {/* name */}
        <div css={styles().form.content.name}>
          <InputLine
            name="name"
            label="Name"
            type="text"
            placeholder="Enter your name"
            icon={icons.Edit}
            value={name}
            error={nameError.length > 0}
            errorMessage={nameError}
            min={minTrackNameLength}
            max={maxTrackNameLength}
            onChange={(e) => {
              setName(e.target.value)
            }}
            onBlur={() => setNameError(validateTrackName(name))}
            onFocus={() => setNameError('')}
          />
        </div>

        {/* track */}
        <div css={styles().form.content.track.main}>
          <div css={styles().form.content.track.label}>
            <InputLabel text="Track" />
          </div>
          <div css={styles().form.content.track.preview.main}>
            <label css={styles().form.content.track.preview.label} htmlFor="track">
              track
              <input
                name="track"
                type="file"
                id="track"
                ref={trackFileInputRef}
                accept=".mp3"
                onChange={(e) => onTrackFileChange(e)}
              />
            </label>
            <div css={styles().form.content.track.preview.input}>
              <div>
                <TrackPreview
                  fileName={trackFileName}
                  label="change or select track file"
                  description="Select track (.mp3)"
                  error={trackError.length > 0}
                  errorMessage={trackError}
                  onClick={() => onTrackClick()}
                />
              </div>
            </div>
          </div>
        </div>

        {/* genres */}
        <div css={styles().form.content.genres.main}>
          {/* genres */}
          <div css={styles().form.content.genres.selected.main}>
            <div css={styles().form.content.genres.selected.label}>
              <InputLabel text="Genre" />
            </div>
            <div css={styles().form.content.genres.selected.genres}>
              {genres.map((trackGenre) => (
                <BadgeSelected
                  key={`track-genres-${trackGenre}`}
                  name={trackGenre}
                  onRemoveClick={() =>
                    setGenres((state) => state.filter((genre) => genre !== trackGenre))
                  }
                />
              ))}
            </div>
          </div>

          {/* search genres */}
          <div css={styles().form.content.genres.search.main}>
            <div css={styles().form.content.genres.search.input}>
              <InputLine
                name="search"
                label="Select genre"
                type="search"
                placeholder="Find genre"
                icon={icons.Search}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setMusicGenres(
                    musGen.filter((genre) =>
                      genre.toLowerCase().includes(e.target.value),
                    ),
                  )
                }}
              />
            </div>
            <div css={styles().form.content.genres.search.genres}>
              {musicGenres.map((genre) => (
                <Badge
                  key={`music-genres-${genre}`}
                  name={genre}
                  disabled={
                    genres.includes(genre) || genres.length >= maxTrackGenresCount
                  }
                  onClick={() => setGenres((state) => [...state, genre])}
                />
              ))}
            </div>
          </div>
        </div>

        {/* visibility */}
        <div css={styles().form.content.visibility.main}>
          <div css={styles().form.content.visibility.label}>
            <InputLabel text="Visibility" />
          </div>
          <div css={styles().form.content.visibility.inputs}>
            <CheckboxOption
              name="Public"
              description="With this visibility option, the track is made publicly accessible to everyone on the platform"
              icon={icons.Unlock}
              checked={visibility === EntityTrackVisibility.Public}
              onCheckboxClick={() => setVisibility(EntityTrackVisibility.Public)}
            />

            {connectReady && (
              <CheckboxOption
                name="Subscribers"
                description="With this visibility option, the track is exclusively accessible to subscribers or individuals who unlock it directly"
                icon={icons.Lock}
                checked={visibility === EntityTrackVisibility.Subscribers}
                onCheckboxClick={() => setVisibility(EntityTrackVisibility.Subscribers)}
              />
            )}
          </div>
        </div>

        {/* price */}
        {visibility === EntityTrackVisibility.Subscribers && (
          <div css={styles().form.content.price.main}>
            <div css={styles().form.content.price.label}>
              <InputLabel text="Price" />
            </div>

            <div css={styles().form.content.price.amount}>${unlockPrice}</div>

            <div css={styles().form.content.price.input}>
              <InputAmount
                name="unlock-price"
                label="unlock price"
                type="range"
                id="unlock-price"
                value={unlockPrice}
                min={minTrackUnlockPrice}
                max={maxTrackUnlockPrice}
                onChange={(e) => setUnlockPrice(parseInt(e.target.value, 10))}
              />
            </div>
          </div>
        )}

        {/* buttons */}
        <div css={styles().form.content.buttons.main}>
          <div css={styles().form.content.buttons.submit}>
            <ButtonMedium
              type="submit"
              aria-label="upload track"
              disabled={submitDisabled}
              appearance="primary"
            >
              {trackCreateLoading ? (
                <Loader width={56} height={20} appearance="secondary" />
              ) : (
                'Upload'
              )}
            </ButtonMedium>
          </div>
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

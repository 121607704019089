import { css } from '@emotion/react'

// styles
import { helpers } from '@/styles'

export const contentContainer = {
  charts: {
    main: css`
      ${helpers.app.screen.height.full};
    `,
  },
  feed: {
    main: css`
      ${helpers.app.screen.height.full};
    `,
  },
  home: {
    main: css`
      ${helpers.app.screen.height.home};
    `,
  },
  library: {
    main: css`
      ${helpers.app.screen.height.library};
    `,
  },
  notifications: {
    notifications: {
      main: css`
        ${helpers.app.screen.height.full};
        ${helpers.app.padding.sides};
      `,
    },
    notification: {
      main: css`
        ${helpers.app.screen.height.full};
        ${helpers.app.padding.sides};
      `,
    },
  },
  post: {
    main: css`
      ${helpers.app.screen.height.full};
      ${helpers.app.padding.sides};
    `,
  },
  profile: {
    main: css`
      ${helpers.app.screen.height.full};
      ${helpers.app.padding.sides};
    `,
  },
  reports: {
    reports: {
      main: css`
        ${helpers.app.screen.height.full};
        ${helpers.app.padding.sides};
      `,
    },
    report: {
      main: css`
        ${helpers.app.screen.height.full};
        ${helpers.app.padding.sides};
      `,
    },
    complaint: {
      main: css`
        ${helpers.app.screen.height.full};
        ${helpers.app.padding.sides};
      `,
    },
  },
  studio: {
    main: css`
      ${helpers.app.screen.height.full};
    `,
    createPost: {
      main: css`
        ${helpers.app.screen.height.full};
        ${helpers.app.padding.sides};
      `,
    },
    createTrack: {
      main: css`
        ${helpers.app.screen.height.full};
        ${helpers.app.padding.sides};
      `,
    },
    editPost: {
      main: css`
        ${helpers.app.screen.height.full};
        ${helpers.app.padding.sides};
      `,
    },
    editTrack: {
      main: css`
        ${helpers.app.screen.height.full};
        ${helpers.app.padding.sides};
      `,
    },
  },
  user: {
    main: css`
      ${helpers.app.screen.height.full};
    `,
  },
}

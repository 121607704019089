import useSWR from 'swr'

// libs
import { apiGetService, apiHandleError } from '@/lib'

// types
import { SWRKeys, SWRFetcherData } from './types'

const getTracksByIds = async ({
  data: { trackIds },
}: SWRFetcherData<{ trackIds: string[] }>) => {
  // get api service
  const api = apiGetService()

  // if no track ids provided
  if (trackIds.length === 0) {
    // return empty payload
    return { tracks: [], count: 0, error: '' }
  }

  try {
    // retrieve tracks
    const { tracks, count } = await api.trackApi.getTracksByIds(trackIds.join(','))

    // return payload
    return { tracks, count, error: '' }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error
    return { tracks: [], count: 0, error: message }
  }
}

const fetcher = (args: SWRFetcherData<{ trackIds: string[] }>) => getTracksByIds(args)

export const useTracks = (trackIds: string[]) => {
  const { data, isLoading } = useSWR(
    { key: SWRKeys.UseTracks, data: { trackIds } },
    fetcher,
    { revalidateOnFocus: false },
  )

  return {
    tracks: data?.tracks,
    count: data?.count,
    isLoading,
    isError: data?.error,
  }
}

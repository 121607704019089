import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  divider: {
    main: css`
      display: flex;
      position: relative;
    `,
    bar: css`
      height: 2px;
      width: 100%;

      background-color: ${theme.colors.secondary.darker};
    `,
    text: {
      main: css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-color: ${theme.colors.primary.normal};
      `,
      text: css`
        font-size: ${typography.p};
        font-weight: bold;
        padding: 4px 16px;
      `,
    },
  },
})

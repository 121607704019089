import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityTrackVisibility } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  TrackCardUploaded,
  TrackCardBlockSkeleton,
  trackSelectTrackDeleteLoading,
  trackSelectUploadedTracks,
  trackSelectUploadedTracksCount,
  trackSelectUploadedTracksLength,
  trackSelectUploadedTracksFetched,
  trackGetUploadedTracks,
  trackOpenTrackDeleteModal,
  playerAudioSetCurrentTrack,
} from '@/features'
// components
import { ContentLayoutVirtualized, ContentPlaceholder } from '@/components'
// icons
import { icons } from '@/assets'
// styles
import { contentLayout } from '@/styles'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const trackDeleteLoading = useAppSelector(trackSelectTrackDeleteLoading)
  const tracks = useAppSelector(trackSelectUploadedTracks)

  const subsetLength = 3

  // create subset
  const subset = tracks.slice(index * subsetLength, index * subsetLength + subsetLength)

  // if tracks not fetched
  if (subset.length === 0) {
    return (
      <div style={style}>
        <TrackCardBlockSkeleton />
      </div>
    )
  }

  return (
    <div css={styles().tracks.main} style={style}>
      {subset.map((track) => {
        const { id: trackId, visibility } = track

        // if track is locked or loading - artist should not be able to delete it
        const deleteDisabled =
          visibility === EntityTrackVisibility.Subscribers || trackDeleteLoading

        return (
          <div css={styles().tracks.row} key={`uploaded-track-${trackId}`}>
            <TrackCardUploaded
              track={track}
              deleteDisabled={deleteDisabled}
              onClick={() => dispatch(playerAudioSetCurrentTrack({ track }))}
              onEditClick={() => navigate(`${Path.Studio}${Path.Track}/${trackId}`)}
              onDeleteClick={() => dispatch(trackOpenTrackDeleteModal({ track }))}
            />
          </div>
        )
      })}
    </div>
  )
})

type TrackListStudioUploadedProps = {}

export const TrackListStudioUploaded: FC<TrackListStudioUploadedProps> = () => {
  const dispatch = useAppDispatch()
  const uploadedTracks = useAppSelector(trackSelectUploadedTracks)
  const uploadedTracksCount = useAppSelector(trackSelectUploadedTracksCount)
  const uploadedTracksLength = useAppSelector(trackSelectUploadedTracksLength)
  const uploadedTracksFetched = useAppSelector(trackSelectUploadedTracksFetched)

  const trackBlockWidth = 280
  const trackBlockMarginRight = 20
  const itemWidth = trackBlockWidth + trackBlockMarginRight

  const trackBlockHeight = 228
  const trackBlockMarginBottom = 0
  const itemHeight = trackBlockHeight + trackBlockMarginBottom

  const defaultItemsCount = 1000
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const subsetLength = 3 // for each 3 items render one item block

  // items to render in virtualized list
  // each track item holds 3 tracks
  // ceil lets us render required amount of items
  // e.g. if items count is 4: ceil(4/3) = 2 items to render
  // 3 items in first block, 1 item in second
  const itemsCount = Math.ceil(uploadedTracksCount / subsetLength)

  const isItemLoaded = (index: number) => !!uploadedTracks[index * subsetLength]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(
      trackGetUploadedTracks({
        limit: itemsToFetch * subsetLength,
        offset: startIndex * subsetLength,
      }),
    )
  }

  // if popular tracks are fetched but length is 0
  if (uploadedTracksFetched && uploadedTracksLength === 0) {
    // return place holder
    return (
      <ContentLayoutVirtualized
        title="Tracks"
        overrideCss={contentLayout.studio}
        itemWidth={itemWidth}
        itemsCount={defaultItemsCount}
      >
        <ContentPlaceholder
          icon={icons.MusicNotes}
          height="230px"
          title="Couldn't find any uploaded tracks"
        />
      </ContentLayoutVirtualized>
    )
  }

  return (
    <ContentLayoutVirtualized
      title="Tracks"
      overrideCss={contentLayout.studio}
      itemWidth={itemWidth}
      itemsCount={uploadedTracksCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={itemsCount || defaultItemsCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={itemsCount || defaultItemsCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

TrackListStudioUploaded.propTypes = {}

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  EntityUserView,
  EntityPostView,
  EntityTrackView,
  EntityCommentView,
} from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// features
import {
  // like
  likeSetUserFollows,
  likeAddUserFollow,
  likeRemoveUserFollow,
  likeSetPostLikes,
  likeAddPostLike,
  likeRemovePostLike,
  likeSetTrackLikes,
  likeAddTrackLike,
  likeRemoveTrackLike,
  likeSetCommentLikes,
  likeAddCommentLike,
  likeRemoveCommentLike,
  // user
  userAppendFollowedArtist,
  userFilterFollowedArtist,
  // post
  postAppendLikedPost,
  postFilterLikedPost,
  // track
  trackAppendLikedTrack,
  trackFilterLikedTrack,
} from '@/features'

export const likeGetFollowedUserIds = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('like/likeGetFollowedUserIds', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve followed user ids
    const { followedUserIds } = await api.likeApi.getFollowedUserIds()

    // set user follows
    dispatch(likeSetUserFollows({ followedUserIds: followedUserIds || [] }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeCreateUserFollow = createAsyncThunk<
  { error: string },
  { user: EntityUserView },
  { state: RootState; rejectValue: undefined }
>('like/likeCreateUserFollow', async ({ user }, { dispatch }) => {
  const { id: userId } = user

  // get api service
  const api = apiGetService()

  try {
    // create user follow
    await api.likeApi.createUserFollow({ userId: userId || '' })

    // add user follow
    dispatch(likeAddUserFollow({ userId: userId || '' }))

    // append user to followed artists
    dispatch(userAppendFollowedArtist({ artist: user }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeDeleteUserFollow = createAsyncThunk<
  { error: string },
  { user: EntityUserView },
  { state: RootState; rejectValue: undefined }
>('like/likeDeleteUserFollow', async ({ user }, { dispatch }) => {
  const { id: userId } = user

  // get api service
  const api = apiGetService()

  try {
    // delete user follow
    await api.likeApi.deleteUserFollow(userId || '')

    // remove user follow
    dispatch(likeRemoveUserFollow({ userId: userId || '' }))

    // filter user from followed artists
    dispatch(userFilterFollowedArtist({ artist: user }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeGetLikedPostIds = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('like/likeGetLikedPostIds', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve liked post ids
    const { likedPostIds } = await api.likeApi.getLikedPostIds()

    // set post likes
    dispatch(likeSetPostLikes({ likedPostIds: likedPostIds || [] }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeCreatePostLike = createAsyncThunk<
  { error: string },
  { post: EntityPostView },
  { state: RootState; rejectValue: undefined }
>('like/likeCreatePostLike', async ({ post }, { dispatch }) => {
  const { id: postId } = post

  // get api service
  const api = apiGetService()

  try {
    // create post like
    await api.likeApi.createPostLike({ postId: postId || '' })

    // add post like
    dispatch(likeAddPostLike({ postId: postId || '' }))

    // append post to liked posts
    dispatch(postAppendLikedPost({ post }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeDeletePostLike = createAsyncThunk<
  { error: string },
  { post: EntityPostView },
  { state: RootState; rejectValue: undefined }
>('like/likeDeletePostLike', async ({ post }, { dispatch }) => {
  const { id: postId } = post

  // get api service
  const api = apiGetService()

  try {
    // delete post like
    await api.likeApi.deletePostLike(postId || '')

    // remove post like
    dispatch(likeRemovePostLike({ postId: postId || '' }))

    // filter post from liked posts
    dispatch(postFilterLikedPost({ post }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeGetLikedTrackIds = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('like/likeGetLikedTrackIds', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve liked track ids
    const { likedTrackIds } = await api.likeApi.getLikedTrackIds()

    // set track likes
    dispatch(likeSetTrackLikes({ likedTrackIds: likedTrackIds || [] }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeCreateTrackLike = createAsyncThunk<
  { error: string },
  { track: EntityTrackView },
  { state: RootState; rejectValue: undefined }
>('like/likeCreateTrackLike', async ({ track }, { dispatch }) => {
  const { id: trackId } = track

  // get api service
  const api = apiGetService()

  try {
    // create track like
    await api.likeApi.createTrackLike({ trackId: trackId || '' })

    // add track like
    dispatch(likeAddTrackLike({ trackId: trackId || '' }))

    // append track to liked tracks
    dispatch(trackAppendLikedTrack({ track }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeDeleteTrackLike = createAsyncThunk<
  { error: string },
  { track: EntityTrackView },
  { state: RootState; rejectValue: undefined }
>('like/likeDeleteTrackLike', async ({ track }, { dispatch }) => {
  const { id: trackId } = track

  const api = apiGetService()

  try {
    // delete track like
    await api.likeApi.deleteTrackLike(trackId || '')

    // remove track like
    dispatch(likeRemoveTrackLike({ trackId: trackId || '' }))

    // filter track from liked tracks
    dispatch(trackFilterLikedTrack({ track }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeGetLikedCommentIds = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('like/likeGetLikedCommentIds', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve liked comment ids
    const { likedCommentIds } = await api.likeApi.getLikedCommentIds()

    // set comment likes
    dispatch(likeSetCommentLikes({ likedCommentIds: likedCommentIds || [] }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeCreateCommentLike = createAsyncThunk<
  { error: string },
  { comment: EntityCommentView },
  { state: RootState; rejectValue: undefined }
>('like/likeCreateCommentLike', async ({ comment }, { dispatch }) => {
  const { id: commentId, postId } = comment

  // get api service
  const api = apiGetService()

  try {
    // create comment like
    await api.likeApi.createCommentLike({
      commentId: commentId || '',
      postId: postId || '',
    })

    // add comment like
    dispatch(likeAddCommentLike({ commentId: commentId || '' }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const likeDeleteCommentLike = createAsyncThunk<
  { error: string },
  { comment: EntityCommentView },
  { state: RootState; rejectValue: undefined }
>('like/likeDeleteCommentLike', async ({ comment }, { dispatch }) => {
  const { id: commentId } = comment

  // get api service
  const api = apiGetService()

  try {
    // delete comment like
    await api.likeApi.deleteCommentLike(commentId || '')

    // remove comment like
    dispatch(likeRemoveCommentLike({ commentId: commentId || '' }))

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

import { css } from '@emotion/react'
import { fonts, typography, helpers, mq, getTheme, ThemeName } from '@/styles'

export const styles = () => {
  const theme = getTheme(ThemeName.Dark)

  return {
    fallback: {
      main: css`
        ${helpers.app.padding.full};

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        min-width: 100vw;
        min-height: 100vh;

        background-color: ${theme.colors.primary.normal};
      `,
      title: {
        main: css`
          margin-bottom: 46px;
        `,
        text: css`
          font-family: ${fonts.primary};
          font-size: ${typography.h5};
          color: ${theme.colors.secondary.normal};

          text-align: center;
        `,
      },
      buttons: {
        main: css`
          ${mq.mobile(css`
            display: flex;
            width: 100%;
          `)};
        `,
        button: css`
          ${helpers.resetDefaults};

          font-family: ${fonts.primary};
          font-size: ${typography.p};
          font-weight: bold;
          white-space: nowrap;

          border-radius: 20px;
          border: 1px solid transparent;
          padding: 8px 26px;
          cursor: pointer;

          width: 100%;

          transition: border 0.2s, color 0.2s, background-color 0.2s;

          color: ${theme.colors.primary.normal};
          background-color: ${theme.colors.accent.normal};

          &:hover {
            border: 1px solid ${theme.colors.accent.normal};
            background-color: ${theme.colors.primary.normal};
            color: ${theme.colors.accent.normal};
          }
        `,
      },
    },
  }
}

import { FC } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type LinkProps = {
  name: string
  href: string
}

export const Link: FC<LinkProps> = ({ name, href }) => {
  const theme = useTheme()

  return (
    <a css={styles(theme).link} href={href} rel="noopener noreferrer" target="_blank">
      {name}
    </a>
  )
}

Link.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
}

import { css, Theme } from '@emotion/react'
import { helpers, typography, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  track: {
    main: css`
      display: flex;
      align-items: center;

      width: 100%;

      ${mq.mobile(css`
        flex-direction: column;
      `)}
    `,

    cover: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 200px;
        height: 200px;

        background-color: ${theme.colors.primary.darker};
        border-radius: 4px;
        overflow: hidden;

        margin-right: 32px;

        &: hover > div {
          opacity: 1;
        }

        ${mq.mobile(css`
          margin-right: 0px;
          margin-bottom: 16px;
        `)}
      `,

      image: css`
        ${helpers.image};
      `,

      placeholder: css`
        display: flex;

        & > svg {
          width: 20px;
          height: 20px;

          & > path {
            color: ${theme.colors.secondary.normal};
          }
          & > circle {
            color: ${theme.colors.secondary.normal};
          }
        }
      `,
    },

    panel: {
      main: css`
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 200px;

        min-width: 0;

        ${mq.mobile(css`
          height: auto;
        `)}
      `,

      description: {
        main: css`
          display: flex;
          flex-direction: column;

          min-width: 0;

          margin-bottom: auto;

          ${mq.mobile(css`
            margin-bottom: 16px;
          `)}
        `,
        name: css`
          ${helpers.textOverflowEllipsis};

          font-size: ${typography.h5};
          font-weight: bold;

          ${mq.mobile(css`
            text-align: center;
            font-size: ${typography.h6};
          `)}
        `,
        author: css`
          ${helpers.resetDefaults};
          ${helpers.textOverflowEllipsis};

          cursor: pointer;

          font-size: ${typography.h6};
          color: ${theme.colors.secondary.normal};
          text-align: start;
          margin-bottom: 2px;

          transition: color 0.2s;
          &:hover {
            color: ${theme.colors.accent.normal};
          }

          ${mq.mobile(css`
            text-align: center;
            font-size: ${typography.p};
          `)}
        `,
      },

      features: {
        main: css`
          display: flex;

          & > div:not(:last-of-type) {
            margin-right: 16px;
          }
          ${mq.mobile(css`
            justify-content: center;
          `)}
        `,
        like: {
          main: css`
            width: 30px;
            height: 30px;
          `,
        },
        dislike: {
          main: css`
            width: 30px;
            height: 30px;
          `,
        },
        follow: {
          main: css`
            width: 30px;
            height: 30px;
          `,
        },
        unfollow: {
          main: css`
            width: 30px;
            height: 30px;
          `,
        },
        tip: {
          main: css`
            width: 30px;
            height: 30px;
          `,
        },
        subscribe: {
          main: css`
            width: 30px;
            height: 30px;
          `,
        },
        unsubscribe: {
          main: css`
            width: 30px;
            height: 30px;
          `,
        },
        button: {
          shared: css`
            ${helpers.resetDefaults};

            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;

            cursor: pointer;

            & > svg {
              width: 24px;
              height: 24px;
            }
          `,
          normal: css`
            & > svg {
              & path {
                fill: ${theme.colors.secondary.normal};
              }
            }
          `,
          accent: css`
            & > svg {
              & path {
                fill: ${theme.colors.accent.normal};
              }
            }
          `,
        },
      },
    },
  },
})

import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  card: {
    main: (reply: boolean) => css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // use if comment height is flexible
      // min-height: 70px;

      // else use fixed height
      height: 70px;

      margin-left: ${reply ? `40px` : `0px`};
    `,

    data: {
      main: css`
        display: flex;
      `,
      avatar: {
        main: css`
          display: flex;
          align-items: center;
          justify-content: center;

          flex-shrink: 0;

          width: 30px;
          height: 30px;

          background-color: ${theme.colors.primary.darker};
          border-radius: 50%;
          overflow: hidden;

          margin-right: 12px;
        `,
        image: css`
          ${helpers.image};
        `,
        icon: css`
          width: 14px;
          height: 14px;

          & > path {
            fill: ${theme.colors.secondary.normal};
          }
        `,
      },
      content: {
        main: css`
          display: flex;
          flex-direction: column;
          justify-content: center;

          min-width: 0;
        `,
        name: css`
          ${helpers.resetDefaults};

          display: flex;
          min-width: 0;

          max-width: 240px;
          margin-bottom: 2px;

          & > span {
            ${helpers.textOverflowEllipsis};
            cursor: pointer;

            font-size: ${typography.sp};
            font-weight: bold;
            color: ${theme.colors.secondary.normal};

            transition: color 0.2s;
          }

          &:hover {
            & > span {
              color: ${theme.colors.accent.normal};
            }
          }
        `,
        message: css`
          // use if comment height is flexible
          // word-wrap: break-word;

          // else use ellipsis
          ${helpers.textOverflowEllipsis};

          font-size: ${typography.sp};
          color: ${theme.colors.secondary.normal};
        `,
      },
    },

    actions: {
      main: css`
        display: flex;
        align-items: center;

        margin-left: 34px;
      `,
      heart: {
        main: css`
          display: flex;
        `,
        button: {
          shared: css`
            ${helpers.resetDefaults};
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 30px;
            height: 30px;

            & > svg {
              width: 14px;
              height: 14px;
            }
          `,
          dislike: css`
            & svg {
              & > path {
                fill: ${theme.colors.accent.normal};
              }
            }
          `,
          like: css`
            & svg {
              & > path {
                fill: ${theme.colors.secondary.normal};
              }
            }
          `,
        },
      },
      reply: {
        main: css`
          display: flex;
          margin: 0px 8px;
        `,
        button: css`
          ${helpers.resetDefaults};
          cursor: pointer;

          color: ${theme.colors.secondary.dark};
          font-size: ${typography.c};
          font-weight: bold;

          height: 30px;
          transition: color 0.2s;

          &:hover {
            color: ${theme.colors.accent.normal};
          }
        `,
      },
      read: {
        main: css`
          display: flex;
          margin: 0px 8px;
        `,
        button: css`
          ${helpers.resetDefaults};
          cursor: pointer;

          color: ${theme.colors.secondary.dark};
          font-size: ${typography.c};
          font-weight: bold;

          height: 30px;
          transition: color 0.2s;

          &:hover {
            color: ${theme.colors.accent.normal};
          }
        `,
      },
      delete: {
        main: css`
          display: flex;
          margin-left: 8px;
        `,
        button: css`
          ${helpers.resetDefaults};
          cursor: pointer;

          color: ${theme.colors.secondary.dark};
          font-size: ${typography.c};
          font-weight: bold;

          height: 30px;
          transition: color 0.2s;

          &:hover {
            color: ${theme.colors.negative.normal};
          }
        `,
      },
      block: {
        main: css`
          display: flex;
          margin-left: 8px;
        `,
        button: css`
          ${helpers.resetDefaults};
          cursor: pointer;

          color: ${theme.colors.secondary.dark};
          font-size: ${typography.c};
          font-weight: bold;

          height: 30px;
          transition: color 0.2s;

          &:hover {
            color: ${theme.colors.negative.normal};
          }
        `,
      },
      report: {
        main: css`
          display: flex;
          margin-left: 8px;
        `,
        button: css`
          ${helpers.resetDefaults};
          cursor: pointer;

          color: ${theme.colors.secondary.dark};
          font-size: ${typography.c};
          font-weight: bold;

          height: 30px;
          transition: color 0.2s;

          &:hover {
            color: ${theme.colors.negative.normal};
          }
        `,
      },
    },
  },
})

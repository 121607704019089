import cover1 from './cover_01.webp'
import cover2 from './cover_02.webp'
import cover3 from './cover_03.webp'
import cover4 from './cover_04.webp'
import cover5 from './cover_05.webp'
import cover6 from './cover_06.webp'
import cover7 from './cover_07.webp'
import cover8 from './cover_08.webp'
import cover9 from './cover_09.webp'

export const images = [
  {
    id: '7a134bb7-3842-4eec-bad2-8b2ad32e4038',
    image: cover1,
  },
  {
    id: '08d0a206-dc40-4a1a-abe7-f9f5e0386625',
    image: cover2,
  },
  {
    id: '0c956389-91b3-47bb-95be-654c668904c5',
    image: cover3,
  },
  {
    id: '85a10454-08c5-4a96-b377-1030d09f0456',
    image: cover4,
  },
  {
    id: 'f252a756-7dae-4c3c-8669-88b685eec0d8',
    image: cover5,
  },
  {
    id: '9a12ab6a-74e9-4c81-96c4-fc65f3e7b488',
    image: cover6,
  },
  {
    id: '5a8118f3-c30b-4db4-9ba4-82d2c424cf7f',
    image: cover7,
  },
  {
    id: '97164e2f-73cc-498b-9beb-2b2b6bf0c553',
    image: cover8,
  },
  {
    id: 'a4ca7805-4676-4449-abb6-5eeeb9ef005b',
    image: cover9,
  },
]

import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

// config
import { Path } from '@/config'
// hooks
import { usePost } from '@/hooks'
// icons
import { icons } from '@/assets'

// components
import { Control } from './components'

type PostControlsProps = {
  postId: string
}

export const PostControls: FC<PostControlsProps> = ({ postId }) => {
  const navigate = useNavigate()

  // post
  const { post, isLoading } = usePost(postId)
  const isDisabled = isLoading || !post

  return (
    <Control
      appearance="neutral"
      label="navigate to post page"
      icon={icons.Scope}
      text="View post"
      disabled={isDisabled}
      onClick={() => navigate(`${Path.Post}/${postId}`)}
    />
  )
}

PostControls.propTypes = {}

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'
import { EntityTransactionView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useUserPublic } from '@/hooks'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TransactionCardProps = {
  transaction: EntityTransactionView
  direction?: 'income' | 'outgoing' | undefined
  onClick: MouseEventHandler<HTMLDivElement>
}

export const TransactionCard: FC<TransactionCardProps> = ({
  transaction,
  direction = undefined,
  onClick,
}) => {
  const theme = useTheme()
  const { amount, feeAmount, type, status, createdAt, sourceUserId, targetUserId } =
    transaction

  // source user
  const { user: sourceUser, isLoading: isSourceUserLoading } = useUserPublic(
    sourceUserId || '',
  )
  const { username: sourceUserUsername } = sourceUser ?? {}

  // target user
  const { user: targetUser, isLoading: isTargetUserLoading } = useUserPublic(
    targetUserId || '',
  )
  const { username: targetUserUsername } = targetUser ?? {}

  const userLoadingPlaceholder = 'Loading...'
  const userUsernamePlaceholder = 'unknown'

  const renderSourceUserUsername = () => {
    const username = isSourceUserLoading
      ? userLoadingPlaceholder
      : `@${sourceUserUsername || userUsernamePlaceholder}`

    return username
  }

  const renderTargetUserUsername = () => {
    const username = isTargetUserLoading
      ? userLoadingPlaceholder
      : `@${targetUserUsername || userUsernamePlaceholder}`

    return username
  }

  const date = new Date(createdAt || '')
  const dateDay = date.getUTCDate().toString().padStart(2, '0')
  const dateMonth = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const dateYear = date.getUTCFullYear()
  // const dateYear = date.getUTCFullYear() % 100

  return (
    <div css={styles(theme).card.main} onClick={(e) => onClick(e)} aria-hidden="true">
      <div css={styles(theme).card.data.main}>
        <div css={styles(theme).card.data.icon}>
          {direction === 'income' && <icons.CreditCardOutgoing />}
          {direction === 'outgoing' && <icons.CreditCardOutgoing />}
        </div>
        <div css={styles(theme).card.data.source}>
          {direction === 'income' && renderSourceUserUsername()}
          {direction === 'outgoing' && renderTargetUserUsername()}
        </div>
        <div css={styles(theme).card.data.amount}>
          {direction === 'income' && `$${((amount || 0) - (feeAmount || 0)) / 100}`}
          {direction === 'outgoing' && `-- $${(amount || 0) / 100}`}
        </div>
        <div css={styles(theme).card.data.type}>{type}</div>
        <div css={styles(theme).card.data.status}>{status}</div>
        <div
          css={styles(theme).card.data.date}
        >{`${dateDay}.${dateMonth}.${dateYear}`}</div>
      </div>
      <div css={styles(theme).card.divider} />
    </div>
  )
}

TransactionCard.propTypes = {}

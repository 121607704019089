import { FC, ButtonHTMLAttributes } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from './assets'
// styles
import { styles } from './styles'

interface CardButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  idp?: 'google' | 'facebook' | undefined
  text: string
}

export const CardButton: FC<CardButtonProps> = ({
  idp = undefined,
  text,
  ...attributes
}) => {
  const theme = useTheme()

  return (
    <button
      css={[
        styles(theme).button.main,
        attributes.disabled
          ? styles(theme).button.disabled
          : styles(theme).button.enabled,
      ]}
      type="button"
      {...attributes}
    >
      <div css={styles(theme).button.content.icon}>
        {idp === 'google' && <icons.GoogleLogo />}
        {idp === 'facebook' && <icons.FacebookLogo />}
      </div>
      <div css={styles(theme).button.content.text.main}>
        <div css={styles(theme).button.content.text.text}>{text}</div>
      </div>
    </button>
  )
}

CardButton.propTypes = {}

import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  title: {
    main: css``,

    text: {
      shared: css`
        font-size: ${typography.h3};
        font-weight: bold;
        text-align: center;
      `,
      primary: css`
        color: ${theme.colors.primary.normal};
      `,
      secondary: css`
        color: ${theme.colors.secondary.normal};
      `,
    },
  },
})

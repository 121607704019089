import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type CardTitleProps = {
  title: string
  accentStart?: number
  accentEnd?: number
}

export const CardTitle: FC<CardTitleProps> = ({
  title,
  accentStart = 0,
  accentEnd = 0,
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).title.main}>
      {title.slice(0, accentStart)}
      <span css={styles(theme).title.accent}>{title.slice(accentStart, accentEnd)}</span>
      {title.slice(accentEnd, title.length)}
    </div>
  )
}

CardTitle.propTypes = {}

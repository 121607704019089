import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  placeholder: {
    main: (height: string) => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: ${height};
    `,

    icon: css`
      margin-bottom: 10px;

      & > svg {
        width: 40px;
        height: 40px;

        & > path {
          fill: ${theme.colors.secondary.normal};
        }
      }
    `,

    title: css`
      font-size: ${typography.p};
      text-align: center;
      color: ${theme.colors.secondary.light};
    `,
  },
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import { EntityPostView, EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'

export const streamCreatePostStream = createAsyncThunk<
  { error: string },
  { post: EntityPostView },
  { state: RootState; rejectValue: undefined }
>('stream/streamCreatePostStream', async ({ post }) => {
  const { id: postId } = post

  // get api service
  const api = apiGetService()

  try {
    // create post stream
    await api.streamApi.createPostStream({ postId: postId || '' })

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const streamCreateTrackStream = createAsyncThunk<
  { error: string },
  { track: EntityTrackView },
  { state: RootState; rejectValue: undefined }
>('stream/streamCreateTrackStream', async ({ track }) => {
  const { id: trackId } = track

  // get api service
  const api = apiGetService()

  try {
    // create track stream
    await api.streamApi.createTrackStream({ trackId: trackId || '' })

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

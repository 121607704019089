import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// utils
import { stringsCutter } from '@/utils'
// features
import {
  commentSelectCommentDeleteLoading,
  commentSelectCommentDeleteModalComment,
  commentDelete,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type CommentDeleteFormProps = {}

export const CommentDeleteForm: FC<CommentDeleteFormProps> = () => {
  const dispatch = useAppDispatch()
  const commentDeleteLoading = useAppSelector(commentSelectCommentDeleteLoading)
  const comment = useAppSelector(commentSelectCommentDeleteModalComment)

  const { message } = comment ?? {}
  const maxCommentMessageDisplayLength = 88

  const submitDisabled = commentDeleteLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(commentDelete({ id: comment?.id || '' }))
        }}
      >
        <div css={styles().form.content.message}>
          {stringsCutter(maxCommentMessageDisplayLength, message || '')}
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="delete comment"
            disabled={submitDisabled}
            appearance="primary"
          >
            {commentDeleteLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Delete'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

CommentDeleteForm.propTypes = {}

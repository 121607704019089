import { FC, ButtonHTMLAttributes, ReactNode } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface ButtonRoundProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: 'primary' | 'secondary'
  children: ReactNode
}

export const ButtonRound: FC<ButtonRoundProps> = ({
  appearance = 'primary',
  children,
  ...attributes
}) => {
  const theme = useTheme()

  return (
    <button
      css={[
        styles(theme).button.main,
        appearance === 'primary' && styles(theme).button.primary,
        appearance === 'secondary' && styles(theme).button.secondary,
        attributes.disabled && styles(theme).button.disabled,
      ]}
      type="button"
      {...attributes}
    >
      <div css={styles(theme).button.content}>{children}</div>
    </button>
  )
}

ButtonRound.propTypes = {}

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  customerSelectCustomerPaymentMethodCreateModalOpen,
  customerCloseCustomerPaymentMethodCreateModal,
} from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { CustomerPaymentMethodCreateCard } from './components'
// styles
import { styles } from './styles'

type CustomerPaymentMethodCreateModalProps = {}

export const CustomerPaymentMethodCreateModal: FC<
  CustomerPaymentMethodCreateModalProps
> = () => {
  const dispatch = useAppDispatch()
  const customerPaymentMethodCreateModalOpen = useAppSelector(
    customerSelectCustomerPaymentMethodCreateModalOpen,
  )

  return (
    <div css={styles().modal.main}>
      {customerPaymentMethodCreateModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(customerCloseCustomerPaymentMethodCreateModal())}
        >
          <div css={styles().modal.content}>
            <CustomerPaymentMethodCreateCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

CustomerPaymentMethodCreateModal.propTypes = {}

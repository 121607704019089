import { css, Theme } from '@emotion/react'
import { typography } from '@/styles'

export const styles = (theme: Theme) => ({
  title: {
    main: css`
      text-align: center;
    `,

    text: css`
      font-size: ${typography.h6};
      font-weight: bold;
      color: ${theme.colors.secondary.normal};
    `,

    accent: css`
      color: ${theme.colors.accent.normal};
    `,

    link: css`
      color: ${theme.colors.accent.normal};
      text-decoration: none;
      transition: color 0.2s;

      &:hover,
      &:active {
        color: ${theme.colors.accent.normalHover};
      }

      &:link,
      &:visited {
      }
    `,
  },
})

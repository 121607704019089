import { css, Theme } from '@emotion/react'
import { helpers, fonts, typography, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  input: {
    main: css`
      display: flex;
      flex-direction: column;
    `,

    label: css`
      display: none;
    `,

    data: {
      main: css`
        position: relative;
      `,

      shared: css`
        ${helpers.resetDefaults};

        width: 100%;
        height: 56px;

        font-family: ${fonts.primary};
        font-size: ${typography.h6};
        color: ${theme.colors.secondary.normal};

        border-radius: 20px;
        padding: 14px 32px;

        transition: border 0.2s;

        &:focus {
          border: 1px solid ${theme.colors.accent.normal};
        }

        &::placeholder {
          color: ${theme.colors.secondary.darkHover};
        }

        // https://stackoverflow.com/questions/20804016/editing-input-type-search-pseudo-element-button-x
        &::-webkit-search-cancel-button {
        }

        ${mq.mobile(css`
          height: 50px;
          padding: 12px 26px;
        `)}
      `,

      empty: css`
        border: 1px solid ${theme.colors.secondary.darkHover};

        & ~ button > svg > path {
          fill: ${theme.colors.secondary.darkHover};
        }
      `,

      filled: css`
        border: 1px solid ${theme.colors.secondary.normal};

        & ~ button > svg > path {
          fill: ${theme.colors.secondary.normal};
        }
      `,

      errored: css`
        border: 1px solid ${theme.colors.negative.normal};
      `,

      withIcon: css`
        padding-right: 70px;

        ${mq.mobile(css`
          padding-right: 60px;
        `)}
      `,

      password: css`
        letter-spacing: 8px;
        font-size: ${typography.h4};

        ${mq.mobile(css`
          letter-spacing: 6px;
          font-size: ${typography.h5};
        `)}
      `,
    },

    button: {
      main: css`
        ${helpers.resetDefaults};

        display: flex;

        position: absolute;
        right: 32px;
        top: 50%;
        transform: translate(0%, -50%);

        & > path {
          transition: fill 0.2s;

          fill: ${theme.colors.secondary.darkHover};
        }

        ${mq.mobile(css`
          right: 26px;
        `)}
      `,
      icon: css`
        width: 30px;
        height: 30px;
      `,
    },

    error: {
      shared: css`
        display: flex;
        min-width: 0;

        transition: height 0.2s;
      `,
      visible: css`
        height: 24px;
      `,
      hidden: css`
        height: 0px;
      `,

      text: {
        shared: css`
          ${helpers.textOverflowEllipsis};

          font-size: ${typography.p};
          color: ${theme.colors.negative.normal};

          transition: opacity 0.4s;
          padding-left: 14px;
        `,
        visible: css`
          opacity: 1;
        `,
        hidden: css`
          opacity: 0;
        `,
      },
    },
  },
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityCommentView } from '@ryddm-inc/ryddm-apiclient'

// utils
import { mergeArraysAtIndex } from '@/utils'
// stores
import { RootState } from '@/stores'
// features
import {
  commentGetComments,
  commentCreate,
  commentUpdate,
  commentBlock,
  commentDelete,
} from '@/features'

export interface CommentState {
  comments: EntityCommentView[]
  commentsCount: number
  commentsFetched: boolean

  commentsDepth: EntityCommentView[]

  commentBlockModal: {
    comment: EntityCommentView | undefined
    open: boolean
  }

  commentDeleteModal: {
    comment: EntityCommentView | undefined
    open: boolean
  }

  commentGetCommentsLoading: boolean
  commentCreateLoading: boolean
  commentUpdateLoading: boolean
  commentBlockLoading: boolean
  commentDeleteLoading: boolean
}

const initialState: CommentState = {
  comments: [],
  commentsCount: 0,
  commentsFetched: false,

  commentsDepth: [],

  commentBlockModal: {
    comment: undefined,
    open: false,
  },

  commentDeleteModal: {
    comment: undefined,
    open: false,
  },

  commentGetCommentsLoading: false,
  commentCreateLoading: false,
  commentUpdateLoading: false,
  commentBlockLoading: false,
  commentDeleteLoading: false,
}

export const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    // reset state
    commentResetState: () => ({
      comments: [],
      commentsCount: 0,
      commentsFetched: false,

      commentsDepth: [],

      commentBlockModal: {
        comment: undefined,
        open: false,
      },

      commentDeleteModal: {
        comment: undefined,
        open: false,
      },

      commentGetCommentsLoading: false,
      commentCreateLoading: false,
      commentUpdateLoading: false,
      commentBlockLoading: false,
      commentDeleteLoading: false,
    }),

    // set comments
    commentSetComments: (
      state,
      action: PayloadAction<{
        comments: EntityCommentView[]
        count: number
        offset: number
      }>,
    ) => {
      const { comments, count, offset } = action.payload

      // mutate count
      state.commentsCount = count

      // mutate comments array
      state.comments = mergeArraysAtIndex(state.comments, comments, offset)
    },

    // reset comments
    commentResetComments: (state) => ({
      ...state,

      comments: [],
      commentsCount: 0,
      commentsFetched: false,

      commentsDepth: [],
    }),

    // add comment
    commentAddComment: (state, action: PayloadAction<{ comment: EntityCommentView }>) => {
      const { comment } = action.payload

      // add comment
      state.comments = [comment, ...state.comments]
      state.commentsCount += 1
    },

    // update comment
    commentUpdateComment: (
      state,
      action: PayloadAction<{ updatedComment: EntityCommentView }>,
    ) => {
      const { updatedComment } = action.payload

      // update comment in comments
      const idx = state.comments.findIndex((c) => c.id === updatedComment.id)
      if (idx !== -1) {
        const currentComment = state.comments[idx]
        state.comments[idx] = { ...currentComment, ...updatedComment }
      }
    },

    // filter comment
    commentFilterComment: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload

      // check if comment exists in state
      const cIndex = state.comments.findIndex((c) => c.id === id)
      if (cIndex !== -1) {
        // filter comment
        state.comments = state.comments.filter((c) => c.id !== id)
        state.commentsCount -= 1
      }

      // check if comment exists in state
      const dIndex = state.commentsDepth.findIndex((c) => c.id === id)
      if (dIndex !== -1) {
        // filter comment
        state.commentsDepth = state.commentsDepth.filter((c) => c.id !== id)
      }
    },

    // filter user comments
    commentFilterUserComments: (state, action: PayloadAction<{ userId: string }>) => {
      const { userId } = action.payload

      // filter user comments from comments
      const filteredComments = state.comments.filter((c) => c.userId !== userId)
      const filteredCommentsCount = state.comments.length - filteredComments.length

      // if filtered comments
      if (filteredCommentsCount > 0) {
        // update comments
        state.comments = filteredComments
        state.commentsCount -= filteredCommentsCount
      }

      // filter user comments from comments depth
      const filteredCommentsDepth = state.commentsDepth.filter((c) => c.userId !== userId)
      const filteredCommentsDepthCount =
        state.commentsDepth.length - filteredCommentsDepth.length

      // if filtered comments
      if (filteredCommentsDepthCount > 0) {
        // update comments
        state.commentsDepth = filteredCommentsDepth
      }
    },

    // open comment block modal
    commentOpenCommentBlockModal: (
      state,
      action: PayloadAction<{ comment: EntityCommentView }>,
    ) => ({
      ...state,
      commentBlockModal: {
        comment: action.payload.comment,
        open: true,
      },
    }),

    // close comment block modal
    commentCloseCommentBlockModal: (state) => ({
      ...state,
      commentBlockModal: {
        comment: undefined,
        open: false,
      },
    }),

    // open comment delete modal
    commentOpenCommentDeleteModal: (
      state,
      action: PayloadAction<{ comment: EntityCommentView }>,
    ) => ({
      ...state,
      commentDeleteModal: {
        comment: action.payload.comment,
        open: true,
      },
    }),

    // close comment delete modal
    commentCloseCommentDeleteModal: (state) => ({
      ...state,
      commentDeleteModal: {
        comment: undefined,
        open: false,
      },
    }),

    // add depth
    commentAddDepth: (state, action: PayloadAction<{ comment: EntityCommentView }>) => ({
      ...state,
      comments: [],
      commentsCount: 0,
      commentsFetched: false,
      commentsDepth: [action.payload.comment, ...state.commentsDepth],
    }),

    // remove depth
    commentRemoveDepth: (state) => ({
      ...state,
      comments: [],
      commentsCount: 0,
      commentsFetched: false,
      commentsDepth: [...state.commentsDepth.slice(1)],
    }),
  },
  extraReducers: (builder) => {
    // get comments
    builder
      .addCase(commentGetComments.pending, (state) => ({
        ...state,
        commentGetCommentsLoading: true,
      }))
      .addCase(commentGetComments.fulfilled, (state) => ({
        ...state,
        commentsFetched: true,
        commentGetCommentsLoading: false,
      }))

    // create comment
    builder
      .addCase(commentCreate.pending, (state) => ({
        ...state,
        commentCreateLoading: true,
      }))
      .addCase(commentCreate.fulfilled, (state) => ({
        ...state,
        commentCreateLoading: false,
      }))

    // update comment
    builder
      .addCase(commentUpdate.pending, (state) => ({
        ...state,
        commentUpdateLoading: true,
      }))
      .addCase(commentUpdate.fulfilled, (state) => ({
        ...state,
        commentUpdateLoading: false,
      }))

    // block comment
    builder
      .addCase(commentBlock.pending, (state) => ({
        ...state,
        commentBlockLoading: true,
      }))
      .addCase(commentBlock.fulfilled, (state) => ({
        ...state,
        commentBlockLoading: false,
      }))

    // delete comment
    builder
      .addCase(commentDelete.pending, (state) => ({
        ...state,
        commentDeleteLoading: true,
      }))
      .addCase(commentDelete.fulfilled, (state) => ({
        ...state,
        commentDeleteLoading: false,
      }))
  },
})

export const {
  commentResetState,
  commentSetComments,
  commentResetComments,
  commentFilterComment,
  commentFilterUserComments,
  commentAddComment,
  commentUpdateComment,
  commentOpenCommentBlockModal,
  commentCloseCommentBlockModal,
  commentOpenCommentDeleteModal,
  commentCloseCommentDeleteModal,
  commentAddDepth,
  commentRemoveDepth,
} = commentSlice.actions

export const commentSelectComments = (state: RootState) => state.comment.comments
export const commentSelectCommentsLength = (state: RootState) =>
  state.comment.comments.length
export const commentSelectCommentsCount = (state: RootState) =>
  state.comment.commentsCount
export const commentSelectCommentsFetched = (state: RootState) =>
  state.comment.commentsFetched

export const commentSelectCommentBlockModalOpen = (state: RootState) =>
  state.comment.commentBlockModal.open
export const commentSelectCommentBlockModalComment = (state: RootState) =>
  state.comment.commentBlockModal.comment

export const commentSelectCommentDeleteModalOpen = (state: RootState) =>
  state.comment.commentDeleteModal.open
export const commentSelectCommentDeleteModalComment = (state: RootState) =>
  state.comment.commentDeleteModal.comment

export const commentSelectCommentsDepth = (state: RootState) =>
  state.comment.commentsDepth
export const commentSelectCommentsDepthLength = (state: RootState) =>
  state.comment.commentsDepth.length

export const commentSelectCommentGetCommentsLoading = (state: RootState) =>
  state.comment.commentGetCommentsLoading
export const commentSelectCommentCreateLoading = (state: RootState) =>
  state.comment.commentCreateLoading
export const commentSelectCommentUpdateLoading = (state: RootState) =>
  state.comment.commentUpdateLoading
export const commentSelectCommentBlockLoading = (state: RootState) =>
  state.comment.commentBlockLoading
export const commentSelectCommentDeleteLoading = (state: RootState) =>
  state.comment.commentDeleteLoading

export const commentReducer = commentSlice.reducer

// export default commentSlice.reducer

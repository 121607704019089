export const fanOnboardingSteps = [
  {
    id: 'eb52306b-ff26-4f4a-8c00-77818ba31f33',
    description: 'Create Profile',
  },
  {
    id: '19841cbf-0af9-494a-8e0a-68e9a967330a',
    description: 'Select Genres',
  },
  {
    id: '4698fbb5-95bf-4f17-ad6e-d5a787acdc50',
    description: 'Follow Artists',
  },
  {
    id: 'f066d4bf-7db4-4407-a191-f07f5b0bbee8',
    description: 'Configure Notifications',
  },
]

export const artistOnboardingSteps = [
  {
    id: '2aa1e99c-af39-4c77-8707-3eef32015eb7',
    description: 'Create Profile',
  },
  {
    id: 'a28a9eca-23a5-40c5-ad18-02ab40821bb1',
    description: 'Upload Tracks',
  },
  {
    id: '525901d6-6cef-452c-9ce7-675c06e4c72f',
    description: 'Enable Monetization',
  },
  {
    id: '14ec6508-41d9-4641-8e64-3560e462da70',
    description: 'Configure Notifications',
  },
]

import { createAsyncThunk } from '@reduxjs/toolkit'
import { EntityTransactionType } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'

// features
import {
  // transaction
  transactionSetIncomeTransactions,
  transactionSetOutgoingTransactions,
} from '@/features'

export const transactionGetIncomeTransactions = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number },
  { state: RootState; rejectValue: undefined }
>('transaction/transactionGetIncomeTransactions', async (inp, { getState, dispatch }) => {
  // get api service
  const api = apiGetService()

  // get income transactions filter
  const filter = getState().transaction.incomeTransactionsFilter

  // define transaction type
  let transactionType: 'subscription' | 'tip' | 'unlock' | 'payout' | undefined

  // assert transaction type
  switch (filter) {
    case EntityTransactionType.Subscription:
      transactionType = 'subscription'
      break
    case EntityTransactionType.Tip:
      transactionType = 'tip'
      break
    case EntityTransactionType.Unlock:
      transactionType = 'unlock'
      break
    case EntityTransactionType.Payout:
      transactionType = 'payout'
      break
    default:
      transactionType = undefined
  }

  try {
    // retrieve transactions
    const { transactions, count } = await api.transactionApi.getIncomeTransactions(
      inp.limit,
      inp.offset,
      transactionType,
    )

    // set income transactions
    dispatch(
      transactionSetIncomeTransactions({
        transactions: transactions || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const transactionGetOutgoingTransactions = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number },
  { state: RootState; rejectValue: undefined }
>(
  'transaction/transactionGetOutgoingTransactions',
  async (inp, { getState, dispatch }) => {
    // get api service
    const api = apiGetService()

    // get outgoing transactions filter
    const filter = getState().transaction.outgoingTransactionsFilter

    // define transaction type
    let transactionType: 'subscription' | 'tip' | 'unlock' | 'payout' | undefined

    // assert transaction type
    switch (filter) {
      case EntityTransactionType.Subscription:
        transactionType = 'subscription'
        break
      case EntityTransactionType.Tip:
        transactionType = 'tip'
        break
      case EntityTransactionType.Unlock:
        transactionType = 'unlock'
        break
      case EntityTransactionType.Payout:
        transactionType = 'payout'
        break
      default:
        transactionType = undefined
    }

    try {
      // retrieve transactions
      const { transactions, count } = await api.transactionApi.getOutgoingTransactions(
        inp.limit,
        inp.offset,
        transactionType,
      )

      // set income transactions
      dispatch(
        transactionSetOutgoingTransactions({
          transactions: transactions || [],
          count: count || 0,
          offset: inp.offset || 0,
        }),
      )

      // return empty payload
      return {
        error: '',
      }
    } catch (err: any) {
      const { message } = await apiHandleError(err)

      // return error message in payload
      return {
        error: message,
      }
    }
  },
)

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  authCloseJoinModal,
  authOpenLoginModal,
  authOpenSignupModal,
  authSelectJoinModalOpen,
} from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { ModalCard, ModalFooterDesktop } from './components'
// styles
import { styles } from './styles'

type AuthJoinModalProps = {}

export const AuthJoinModal: FC<AuthJoinModalProps> = () => {
  const dispatch = useAppDispatch()
  const joinModalOpen = useAppSelector(authSelectJoinModalOpen)

  return (
    <div css={styles().modal.main}>
      {joinModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(authCloseJoinModal())}
        >
          <div css={styles().modal.content}>
            <ModalCard
              onCloseClick={() => dispatch(authCloseJoinModal())}
              onSignupClick={() => {
                dispatch(authCloseJoinModal())
                dispatch(authOpenSignupModal())
              }}
              onLoginClick={() => {
                dispatch(authCloseJoinModal())
                dispatch(authOpenLoginModal())
              }}
            />
            <ModalFooterDesktop onCloseClick={() => dispatch(authCloseJoinModal())} />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

AuthJoinModal.propTypes = {}

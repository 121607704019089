import { FC } from 'react'
import { useTheme } from '@emotion/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'

// config
import { Environment, stripePublishableKeyProd, stripePublishableKeyStag } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  customerSelectCustomerPaymentMethodCreateModalClientSecret,
  customerCloseCustomerPaymentMethodCreateModal,
} from '@/features'
// components
import { CardHeaderMobile, Error } from '@/components'
// styles
import { stripeAppearance } from '@/styles'

// components
import { CustomerPaymentMethodCreateForm } from './components'
// styles
import { styles } from './styles'

type CustomerPaymentMethodCreateCardProps = {}

const stripePromise = loadStripe(
  process.env.ENVIRONMENT === Environment.Production
    ? stripePublishableKeyProd
    : stripePublishableKeyStag,
)

export const CustomerPaymentMethodCreateCard: FC<
  CustomerPaymentMethodCreateCardProps
> = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const clientSecret = useAppSelector(
    customerSelectCustomerPaymentMethodCreateModalClientSecret,
  )

  const customerPaymentMethodCreateError = ''

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: stripeAppearance(theme),
  }

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile
          onCloseClick={() => dispatch(customerCloseCustomerPaymentMethodCreateModal())}
        />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* form */}
        <div css={styles(theme).card.content.form}>
          <Elements stripe={stripePromise} options={options}>
            <CustomerPaymentMethodCreateForm />
          </Elements>
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={customerPaymentMethodCreateError} />
        </div>
      </div>
    </div>
  )
}

CustomerPaymentMethodCreateCard.propTypes = {}

import { css, Theme } from '@emotion/react'
import { kf } from '@/styles'

export const styles = (theme: Theme) => ({
  skeleton: {
    main: css`
      display: flex;
      align-items: center;

      width: 100%;
      border-radius: 4px;

      background-color: ${theme.colors.primary.normal};
      background-image: linear-gradient(
        90deg,
        #ffffff00,
        ${theme.colors.secondary.darker},
        #ffffff00
      );
      background-size: 60px 100%;
      background-repeat: no-repeat;
      background-position: left -60px top 0;
      animation: 1.4s ease infinite ${kf.load.shine(-60, 0)};
    `,
    section: {
      full: (width: string, height: string, isStatic: boolean) => css`
        ${isStatic && `flex-shrink: 0;`}

        width: ${width};
        height: ${height};

        opacity: 0.4;
        border-radius: 4px;
        background-color: ${theme.colors.primary.darker};
      `,
      start: (width: string, height: string, isStatic: boolean) => css`
        ${isStatic && `flex-shrink: 0;`}

        width: ${width};
        height: ${height};

        opacity: 0.4;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: ${theme.colors.primary.darker};
      `,
      center: (width: string, height: string, isStatic: boolean) => css`
        ${isStatic && `flex-shrink: 0;`}

        width: ${width};
        height: ${height};

        opacity: 0.4;
        background-color: ${theme.colors.primary.darker};
      `,
      end: (width: string, height: string, isStatic: boolean) => css`
        ${isStatic && `flex-shrink: 0;`}

        width: ${width};
        height: ${height};

        opacity: 0.4;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: ${theme.colors.primary.darker};
      `,
    },
    divider: (width: string, height: string, isStatic: boolean) => css`
      ${isStatic && `flex-shrink: 0;`}

      width: ${width};
      height: ${height};

      background-color: ${theme.colors.primary.normal};
    `,
  },
})

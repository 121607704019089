import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// icons
import { icons } from '@/assets'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  subscriptionSelectSubscriptionCancelModalArtist,
  subscriptionCloseSubscriptionCancelModal,
} from '@/features'
// components
import { CardHeaderMobile, ModalCardTitle, ModalCardSubtitle, Error } from '@/components'

// components
import { SubscriptionCancelForm } from './components'
// styles
import { styles } from './styles'

type SubscriptionCancelCardProps = {}

export const SubscriptionCancelCard: FC<SubscriptionCancelCardProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const modalArtist = useAppSelector(subscriptionSelectSubscriptionCancelModalArtist)
  const subscriptionError = ''

  const { id: artistUserId, username } = modalArtist ?? {}

  const subtitleAccentStartIndex = 160
  const subtitleAccentEndIndex = 161 + (username?.length || 0)

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile
          onCloseClick={() => dispatch(subscriptionCloseSubscriptionCancelModal())}
        />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <ModalCardTitle text="Unsubscribe" icon={icons.Lock} />
      </div>

      {/* subtitle */}
      <div css={styles(theme).card.subtitle}>
        <ModalCardSubtitle
          text={`Your subscription isn't just access, it's an investment in a world of exclusive content curated just for you. Are you sure you want to cancel subscription to - @${username}?`}
          accent={{
            accentStart: subtitleAccentStartIndex,
            accentEnd: subtitleAccentEndIndex,
            onClick: () => navigate(`${Path.User}/${artistUserId}`),
          }}
        />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* form */}
        <div css={styles(theme).card.content.form}>
          <SubscriptionCancelForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={subscriptionError} />
        </div>
      </div>
    </div>
  )
}

SubscriptionCancelCard.propTypes = {}

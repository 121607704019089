import { FC } from 'react'
import { useTheme } from '@emotion/react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { authCloseResetModal, authOpenLoginModal, authSelectError } from '@/features'

// components
import { CardHeaderMobile, Error } from '@/components'
import { CardDivider, CardTitle, ResetForm } from './components'

// styles
import { styles } from './ResetCard.styles'

type ResetCardProps = {}

export const ResetCard: FC<ResetCardProps> = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const authError = useAppSelector(authSelectError)

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile onCloseClick={() => dispatch(authCloseResetModal())} />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <CardTitle title="Reset password" />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* password reset */}
        <div css={styles(theme).card.content.form}>
          <ResetForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={authError} />
        </div>

        <div css={styles(theme).card.content.divider.bottom}>
          <CardDivider />
        </div>

        {/* log in */}
        <div css={styles(theme).card.content.login.main}>
          <button
            css={styles(theme).card.content.login.button}
            onClick={() => {
              dispatch(authCloseResetModal())
              dispatch(authOpenLoginModal())
            }}
            type="button"
            aria-label="log in"
          >
            Log in to RYDDM
          </button>
        </div>
      </div>
    </div>
  )
}

ResetCard.propTypes = {}

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type TrackProps = {
  fileName: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const Track: FC<TrackProps> = ({ fileName, onClick }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).track.main}>
      <button
        css={[
          styles(theme).track.button.shared,
          fileName ? styles(theme).track.button.filled : styles(theme).track.button.empty,
        ]}
        onClick={(e) => onClick(e)}
        type="button"
        aria-label="change track file"
      >
        <div css={styles(theme).track.button.content.main}>
          <div css={styles(theme).track.button.content.name}>
            {fileName || `Select track (.mp3)`}
          </div>
          <div css={styles(theme).track.button.content.icon}>
            <icons.Upload />
          </div>
        </div>
      </button>
    </div>
  )
}

Track.propTypes = {}

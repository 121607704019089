import { css, Theme } from '@emotion/react'

export const styles = (theme: Theme) => ({
  divider: {
    main: css`
      display: flex;
      justify-content: center;
    `,

    bar: css`
      height: 1px;
      width: 100%;
      background-color: ${theme.colors.secondary.darkHover};
    `,
  },
})

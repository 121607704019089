export const postUtilGetPostCardSkeletonSections = (sectionName: string) => [
  {
    name: `${sectionName}-media`,
    width: '180px',
    height: '146px',
    isStatic: true,
    isDivider: false,
  },
  {
    name: `${sectionName}-divider`,
    width: '180px',
    height: '8px',
    isStatic: true,
    isDivider: true,
  },
  {
    name: `${sectionName}-description`,
    width: '180px',
    height: '128px',
    isStatic: true,
    isDivider: false,
  },
]

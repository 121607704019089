import { css, Theme } from '@emotion/react'
import { helpers, fonts, typography, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  button: {
    main: css`
      ${helpers.resetDefaults};

      display: flex;
      align-items: center;
      justify-content: center;

      font-family: ${fonts.primary};
      font-size: ${typography.h6};
      font-weight: bold;
      white-space: nowrap;

      border-radius: 50%;
      overflow: hidden;

      width: 60px;
      height: 60px;

      transition: color 0.2s, background-color 0.2s;

      ${mq.mobile(css`
        width: 50px;
        height: 50px;
      `)}
    `,
    primary: css`
      cursor: pointer;

      color: ${theme.colors.primary.normal};
      background-color: ${theme.colors.accent.normal};

      &:hover {
        background-color: ${theme.colors.accent.normalHover};
      }
    `,
    secondary: css`
      cursor: pointer;

      color: ${theme.colors.secondary.normal};
      background-color: ${theme.colors.primary.normal};

      &:hover {
        background-color: ${theme.colors.primary.normalHover};
      }
    `,
    disabled: css`
      cursor: not-allowed;

      color: ${theme.colors.secondary.darker};
      background-color: ${theme.colors.secondary.dark};

      &:hover {
        background-color: ${theme.colors.secondary.dark};
      }
    `,
    content: css`
      display: flex;
      align-items: center;
      justify-content: center;
    `,
  },
})

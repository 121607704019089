import { css, Theme } from '@emotion/react'
import { constants, helpers, mq, z } from '@/styles'

export const styles = (theme: Theme) => ({
  playlist: {
    main: css`
      ${helpers.hideScrollbar};
      ${helpers.app.padding.sides};
      ${helpers.app.playlist.height};
      ${helpers.app.playlist.width};

      z-index: ${z.features.audio.playlist};
      display: flex;
      flex-direction: column;

      position: fixed;

      overflow: hidden;
      overflow-y: scroll;

      transition: top 0.2s, opacity 0.4s;
      background-color: ${theme.colors.primary.normal};
    `,

    open: css`
      top: ${constants.app.header.desktop.height};

      ${mq.mobile(css`
        top: ${constants.app.header.mobile.height};
      `)}

      opacity: 1;
    `,

    closed: css`
      top: 100vh;

      opacity: 0;
    `,

    current: css`
      & > div {
        margin-bottom: 50px;

        ${mq.mobile(css`
          margin-bottom: 32px;
        `)}
      }
    `,

    tracks: css`
      height: 100%;
    `,
  },
})

import { css, Theme } from '@emotion/react'
import { mq, z } from '@/styles'

export const styles = (theme: Theme) => ({
  stage: {
    main: css`
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
    `,

    backward: {
      button: css`
        z-index: ${z.onboarding.backward};

        position: fixed;
        top: 20px;
        left: 20px;
      `,
      icon: css`
        width: 24px;
        height: 24px;
      `,
    },

    content: {
      main: css`
        display: flex;
        flex-direction: column;

        width: 760px;

        ${mq.mobile(css`
          width: 100%;
        `)}
      `,

      title: css`
        margin-bottom: 32px;
      `,

      artists: {
        main: css`
          display: flex;
          flex-direction: column;
          align-items: center;

          position: relative;

          margin-bottom: 50px;
        `,

        overlay: {
          top: css`
            z-index: ${z.onboarding.followArtists.overlay};

            position: absolute;
            left: 0;
            top: 0;

            width: 100%;
            height: 60px;

            background: linear-gradient(
              180deg,
              ${theme.colors.primary.normal} 0%,
              ${theme.colors.primary.normal}00 75.79%
            );
          `,

          bot: css`
            z-index: ${z.onboarding.followArtists.overlay};

            position: absolute;
            left: 0;
            bottom: 0;

            width: 100%;
            height: 60px;

            background: linear-gradient(
              180deg,
              ${theme.colors.primary.normal}00 25.21%,
              ${theme.colors.primary.normal} 100%
            );
          `,
        },
      },

      form: css`
        margin-bottom: 50px;
      `,

      error: css``,
    },
  },
})

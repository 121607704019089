import { FC } from 'react'

// components
import { Loader } from '@/components'

// styles
import { styles } from './styles'

type SuspenseMainFallbackProps = {}

export const SuspenseMainFallback: FC<SuspenseMainFallbackProps> = () => (
  <div css={styles().fallback.main}>
    <Loader width={80} height={40} appearance="accent" />
  </div>
)

SuspenseMainFallback.propTypes = {}

export enum OnboardingStep {
  Initial = 0,
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
  Sixth = 6,
}

export enum ConnectLinkType {
  Onboarding = 'account_onboarding',
  Update = 'account_update',
}

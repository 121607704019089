import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type FeatureProps = {
  icon: any
  title: string
  description: string
}

export const Feature: FC<FeatureProps> = ({ icon: Icon, title, description }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).feature.main}>
      <div css={styles(theme).feature.icon}>
        <Icon />
      </div>
      <div css={styles(theme).feature.title}>{title}</div>
      <div css={styles(theme).feature.description}>{description}</div>
    </div>
  )
}

Feature.propTypes = {}

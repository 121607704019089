import { FC, useEffect } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  onboardingSelectLoading,
  onboardingSetNextStage,
  onboardingGetConnectLink,
  onboardingSelectConnectLink,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type FormProps = {}

export const Form: FC<FormProps> = () => {
  const dispatch = useAppDispatch()
  const onboardingLoading = useAppSelector(onboardingSelectLoading)
  const onboardingConnectLink = useAppSelector(onboardingSelectConnectLink)

  const submitDisabled = onboardingLoading

  useEffect(() => {
    dispatch(onboardingGetConnectLink())
  }, [dispatch])

  const onEnableClick = () => {
    const newWindow = window.open(
      onboardingConnectLink,
      '_self', // '_blank'
      'noopener,noreferrer',
    )
    if (newWindow) {
      newWindow.opener = null
    }
  }

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(onboardingSetNextStage())
        }}
      >
        {onboardingConnectLink && (
          <div css={styles().form.content.enable}>
            <ButtonLarge
              type="button"
              onClick={() => onEnableClick()}
              aria-label="enable monetization"
              disabled={submitDisabled}
              appearance="primary"
            >
              Enable
            </ButtonLarge>
          </div>
        )}
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="proceed to next onboarding stage"
            disabled={submitDisabled}
            appearance="secondary"
          >
            {onboardingLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Next'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

Form.propTypes = {}

import { FC } from 'react'
import { useTheme } from '@emotion/react'
import { EntityPostMediaType, EntityTrackVisibility } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch, useTracks } from '@/hooks'
// features
import {
  PostCardProduct,
  authSelectProfile,
  unlockSelectUnlockCreatePostUnlockLoading,
  unlockSelectUnlockPostModalPost,
  unlockCreatePostUnlock,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type UnlockPostFormProps = {}

export const UnlockPostForm: FC<UnlockPostFormProps> = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const unlockCreatePostUnlockLoading = useAppSelector(
    unlockSelectUnlockCreatePostUnlockLoading,
  )
  const profile = useAppSelector(authSelectProfile)
  const modalPost = useAppSelector(unlockSelectUnlockPostModalPost)

  const { id: profileUserId } = profile ?? {}
  const { userId: artistUserId, unlockPrice, mediaType, trackIds } = modalPost ?? {}

  const isCurrentUserContentUnlock = profileUserId === artistUserId

  const postHasMediaContent =
    mediaType === EntityPostMediaType.Image || mediaType === EntityPostMediaType.Video
  const postHasTracksContent = trackIds?.length && trackIds.length > 0
  const displayPostContentDescription = postHasMediaContent || postHasTracksContent

  const submitDisabled = isCurrentUserContentUnlock || unlockCreatePostUnlockLoading

  // post tracks
  const { tracks: postTracks, isLoading: isPostTracksLoading } = useTracks(trackIds || [])
  const tracksLoadingPlaceholder = 'Loading...'

  const tracksCount = postTracks?.length || 0
  const lockedTracksCount = postTracks?.reduce(
    (acc, t) => (t.visibility === EntityTrackVisibility.Subscribers ? acc + 1 : acc),
    0,
  )

  return (
    <div css={styles(theme).form.main}>
      <form
        css={styles(theme).form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(unlockCreatePostUnlock())
        }}
      >
        {/* post product */}
        <div css={styles(theme).form.content.product}>
          <PostCardProduct />
        </div>

        {/* post product description */}
        {displayPostContentDescription && (
          <div css={styles(theme).form.content.description.main}>
            <div css={styles(theme).form.content.description.text}>
              This post features a total of:
            </div>

            {/* if post has media content - describe it */}
            {postHasMediaContent && (
              <div css={styles(theme).form.content.description.text}>
                engaging{' '}
                <span css={styles(theme).form.content.description.accent}>
                  {mediaType}
                </span>{' '}
                content
              </div>
            )}

            {/* if post has tracks content - describe it */}
            {postHasTracksContent && (
              <div css={styles(theme).form.content.description.text}>
                <span css={styles(theme).form.content.description.accent}>
                  {isPostTracksLoading ? tracksLoadingPlaceholder : tracksCount}
                </span>{' '}
                tracks, with{' '}
                <span css={styles(theme).form.content.description.accent}>
                  {isPostTracksLoading ? tracksLoadingPlaceholder : lockedTracksCount}
                </span>{' '}
                of them being exclusive
              </div>
            )}
          </div>
        )}

        {/* submit */}
        <div css={styles(theme).form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="unlock post"
            disabled={submitDisabled}
            appearance="primary"
          >
            {unlockCreatePostUnlockLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              `Unlock for $${unlockPrice && unlockPrice / 100}`
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

UnlockPostForm.propTypes = {}

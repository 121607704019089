import { css, Theme } from '@emotion/react'

export const styles = (theme: Theme) => ({
  footer: {
    main: css``,
    terms: css`
      margin-bottom: 2px;
    `,
    contacts: css`
      margin-bottom: 6px;
    `,
    copyright: css`
      color: ${theme.colors.secondary.light};
    `,
    divider: css`
      margin: 0px 4px;
      color: ${theme.colors.secondary.dark};
    `,
  },
})

import useSWR from 'swr'

// libs
import { apiGetService, apiHandleError } from '@/lib'

// types
import { SWRKeys, SWRFetcherData } from './types'

const getSubscriptionPrice = async ({
  data: { userId },
}: SWRFetcherData<{ userId: string }>) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve subscription price
    const { subscriptionPrice } = await api.subscriptionApi.getUserSubscriptionPrice(
      userId,
    )

    // return payload
    return { subscriptionPrice, error: '' }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return payload
    return { subscriptionPrice: undefined, error: message }
  }
}

const fetcher = (args: SWRFetcherData<{ userId: string }>) => getSubscriptionPrice(args)

export const useSubscriptionPrice = (userId: string) => {
  const shouldFetch = !!userId

  const { data, isLoading } = useSWR(
    shouldFetch ? { key: SWRKeys.UseSubscriptionPrice, data: { userId } } : null,
    fetcher,
    { revalidateOnFocus: false },
  )

  return {
    subscriptionPrice: data?.subscriptionPrice,
    isLoading,
    isError: data?.error,
  }
}

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { onboardingSelectError, onboardingSetPrevStage } from '@/features'
// icons
import { icons } from '@/assets'
// components
import { ButtonRound, Subtitle, Error } from '@/components'

// components
import { Form } from './components'
// styles
import { styles } from './styles'

type ConfigureNotificationsProps = {}

export const ConfigureNotifications: FC<ConfigureNotificationsProps> = () => {
  const dispatch = useAppDispatch()
  const onboardingError = useAppSelector(onboardingSelectError)

  return (
    <div css={styles().stage.main}>
      <div css={styles().stage.backward.button}>
        <ButtonRound
          aria-label="set previous onboarding stage"
          onClick={() => dispatch(onboardingSetPrevStage())}
          appearance="secondary"
        >
          <icons.ArrowLeft css={styles().stage.backward.icon} />
        </ButtonRound>
      </div>

      <div css={styles().stage.content.main}>
        <div css={styles().stage.content.title}>
          <Subtitle text="Customize notifications settings to match your preferences!" />
        </div>

        <div css={styles().stage.content.form}>
          <Form />
        </div>

        <div css={styles().stage.content.error}>
          <Error error={onboardingError} />
        </div>
      </div>
    </div>
  )
}

ConfigureNotifications.propTypes = {}

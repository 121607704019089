import { FC, InputHTMLAttributes } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  error?: boolean
  errorMessage?: string
  sendDisabled?: boolean
}

export const FormInput: FC<FormInputProps> = ({
  name,
  label,
  error = false,
  // errorMessage = '',
  sendDisabled = false,
  ...attributes
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).input.main}>
      {/* label */}
      <label css={styles(theme).input.label} htmlFor={name}>
        {label}
      </label>

      {/* input */}
      <div css={styles(theme).input.data.main}>
        <input
          css={[
            styles(theme).input.data.shared,

            attributes.value
              ? styles(theme).input.data.filled
              : styles(theme).input.data.empty,

            error && styles(theme).input.data.errored,

            styles(theme).input.data.withIcon,
          ]}
          id={name}
          {...attributes}
        />

        <button
          css={[
            styles(theme).input.button.shared,
            sendDisabled
              ? styles(theme).input.button.disabled
              : styles(theme).input.button.enabled,
          ]}
          disabled={sendDisabled}
          type="submit"
          aria-label="send comment"
        >
          <span css={styles(theme).input.button.text}>Send</span>
        </button>
      </div>
    </div>
  )
}

FormInput.propTypes = {}

import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityTrackVisibility, EntityPostVisibility } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // track
  TrackTile,
  TrackTileLocked,
  trackSelectPostTracks,
  trackSelectPostTracksFetched,
  trackUtilGetTrackTileSkeletonSections,
  // post
  postSelectPost,
  // player
  playerAudioSetCurrentTrack,
  // subscription
  subscriptionSelectUserSubscriptions,
  // unlock
  unlockOpenUnlockTrackModal,
  unlockSelectTrackUnlocks,
  unlockSelectPostUnlocks,
} from '@/features'
// components
import {
  ContentLayoutVirtualized,
  ContentPlaceholder,
  SkeletonVertical,
} from '@/components'
// icons
import { icons } from '@/assets'
// styles
import { contentLayout } from '@/styles'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const trackUnlocks = useAppSelector(unlockSelectTrackUnlocks)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const tracks = useAppSelector(trackSelectPostTracks)
  const track = tracks?.[index]

  // if track not fetched
  if (!track) {
    return (
      <div style={style}>
        <SkeletonVertical
          containerWidth="180px"
          sections={trackUtilGetTrackTileSkeletonSections('track-list-post')}
        />
      </div>
    )
  }

  const { id: currentUserId } = profile ?? {}
  const { id: trackId, userId: trackAuthorUserId, visibility } = track

  const isContentLocked = visibility === EntityTrackVisibility.Subscribers
  const isCurrentUserContentAuthor = currentUserId === trackAuthorUserId
  const isCurrentUserUnlockedContent = trackUnlocks[trackId || '']
  const isCurrentUserSubscribedToContentAuthor =
    userSubscriptions[trackAuthorUserId || '']

  const renderLocked =
    isContentLocked &&
    !isCurrentUserContentAuthor &&
    !isCurrentUserUnlockedContent &&
    !isCurrentUserSubscribedToContentAuthor

  // if content is locked
  if (renderLocked) {
    return (
      <div css={styles().tracks.main} style={style}>
        <TrackTileLocked
          track={track}
          onClick={() => dispatch(unlockOpenUnlockTrackModal({ track }))}
          onAuthorNameClick={() => navigate(`${Path.User}/${trackAuthorUserId}`)}
        />
      </div>
    )
  }

  return (
    <div css={styles().tracks.main} style={style}>
      <TrackTile
        track={track}
        onClick={() => dispatch(playerAudioSetCurrentTrack({ track }))}
        onAuthorNameClick={() => navigate(`${Path.User}/${trackAuthorUserId}`)}
      />
    </div>
  )
})

type TrackListPostProps = {}

export const TrackListPost: FC<TrackListPostProps> = () => {
  const profile = useAppSelector(authSelectProfile)
  const postUnlocks = useAppSelector(unlockSelectPostUnlocks)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const post = useAppSelector(postSelectPost)

  const postTracks = useAppSelector(trackSelectPostTracks)
  const postTracksFetched = useAppSelector(trackSelectPostTracksFetched)

  const postTracksCount = postTracks?.length || 0

  const trackBlockWidth = 180
  const trackBlockMarginRight = 20
  const itemWidth = trackBlockWidth + trackBlockMarginRight

  const trackBlockHeight = 230
  const trackBlockMarginBottom = 0
  const itemHeight = trackBlockHeight + trackBlockMarginBottom

  const defaultItemsCount = 1000
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!postTracks?.[index]

  const loadMoreItems = (): void => {
    // (startIndex: number, stopIndex: number): void
  }

  const { id: profileUserId } = profile ?? {}
  const { id: postId, userId: postAuthorUserId, visibility } = post ?? {}

  const isContentLocked = visibility === EntityPostVisibility.Subscribers
  const isCurrentUserContentAuthor = profileUserId === postAuthorUserId
  const isCurrentUserUnlockedContent = postUnlocks[postId || '']
  const isCurrentUserSubscribedToContentAuthor = userSubscriptions[postAuthorUserId || '']

  const renderLocked =
    isContentLocked &&
    !isCurrentUserContentAuthor &&
    !isCurrentUserUnlockedContent &&
    !isCurrentUserSubscribedToContentAuthor

  // if post is locked
  if (renderLocked) {
    // return place holder
    return (
      <ContentLayoutVirtualized
        title="Tracks"
        overrideCss={contentLayout.post.current}
        itemWidth={itemWidth}
        itemsCount={0}
      >
        <ContentPlaceholder icon={icons.Lock} height="230px" title="Content is locked" />
      </ContentLayoutVirtualized>
    )
  }

  // if post tracks fetched but count is 0
  if (postTracksFetched && postTracksCount === 0) {
    // return place holder
    return (
      <ContentLayoutVirtualized
        title="Tracks"
        overrideCss={contentLayout.post.current}
        itemWidth={itemWidth}
        itemsCount={postTracksCount}
      >
        <ContentPlaceholder
          icon={icons.MusicNotes}
          height="230px"
          title="This post doesn't have any tracks"
        />
      </ContentLayoutVirtualized>
    )
  }

  return (
    <ContentLayoutVirtualized
      title="Tracks"
      overrideCss={contentLayout.post.current}
      itemWidth={itemWidth}
      itemsCount={postTracksCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={postTracksCount || defaultItemsCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={postTracksCount || defaultItemsCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

TrackListPost.propTypes = {}

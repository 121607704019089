import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // user
  ArtistCardProduct,
  userSelectUserBlockLoading,
  userSelectUserBlockModalUser,
  userCloseUserBlockModal,
  userBlock,
} from '@/features'
// components
import { Loader, ButtonLarge } from '@/components'

// styles
import { styles } from './styles'

type UserBlockFormProps = {}

export const UserBlockForm: FC<UserBlockFormProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userBlockLoading = useAppSelector(userSelectUserBlockLoading)
  const user = useAppSelector(userSelectUserBlockModalUser)
  const { id: userId } = user ?? {}

  const submitDisabled = userBlockLoading

  return (
    <div css={styles().form.main}>
      <form
        css={styles().form.content.main}
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(userBlock({ id: userId || '' }))
        }}
      >
        <div css={styles().form.content.user}>
          <ArtistCardProduct
            artist={user || {}}
            onNameClick={() => {
              // navigate to user page
              navigate(`${Path.User}/${userId}`)

              // close modal
              dispatch(userCloseUserBlockModal())
            }}
            onUsernameClick={() => {
              // navigate to user page
              navigate(`${Path.User}/${userId}`)

              // close modal
              dispatch(userCloseUserBlockModal())
            }}
          />
        </div>
        <div css={styles().form.content.submit}>
          <ButtonLarge
            type="submit"
            aria-label="block user"
            disabled={submitDisabled}
            appearance="primary"
          >
            {userBlockLoading ? (
              <Loader width={50} height={20} appearance="primary" />
            ) : (
              'Block'
            )}
          </ButtonLarge>
        </div>
      </form>
    </div>
  )
}

UserBlockForm.propTypes = {}

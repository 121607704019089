import { css, Theme } from '@emotion/react'
import { helpers, mq, z } from '@/styles'

export const styles = (theme: Theme) => ({
  navigation: {
    main: css`
      ${helpers.library.header.height};
      ${helpers.library.header.width};

      position: relative;
    `,

    overlay: {
      shared: css`
        z-index: ${z.library.navigation.arrows};

        position: absolute;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 60px;
        height: 64px;

        ${mq.mobile(css`
          display: none;
        `)}
      `,
      backward: css`
        top: 0;
        left: 0;

        background: linear-gradient(
          90deg,
          ${theme.colors.primary.normal} 50%,
          ${theme.colors.primary.normal}00 100%
        );
      `,
      forward: css`
        top: 0;
        right: 0;

        background: linear-gradient(
          270deg,
          ${theme.colors.primary.normal} 50%,
          ${theme.colors.primary.normal}00 100%
        );
      `,
      disabled: css`
        display: none;
      `,
      button: css`
        ${helpers.resetDefaults};
        display: flex;
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 40px;

        cursor: pointer;

        &:hover > svg {
          color: ${theme.colors.accent.normal};
        }
      `,
      icon: css`
        width: 20px;
        height: 20px;
        transition: color 0.2s;

        color: ${theme.colors.secondary.normal};
      `,
    },

    content: css`
      ${helpers.hideScrollbar};
      ${helpers.app.padding.sides};

      display: flex;
      align-items: center;

      width: 100%;
      height: 64px;

      scroll-behavior: smooth;
      overflow-x: scroll;
      overflow-y: hidden;

      & > div:not(:last-of-type) {
        margin-right: 18px;
      }

      ${mq.tablet(css`
        width: 100vw;
      `)}
    `,
  },
})

import { css } from '@emotion/react'

export const styles = () => ({
  list: {
    main: css`
      width: 100%;
      max-width: 800px;
    `,
    placeholder: css`
      height: 100%;
    `,
    row: css`
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    `,
  },

  transactions: {
    main: css``,
  },
})

import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityTrackVisibility, EntityUserRole } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// utils
import { uuid } from '@/utils'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // track
  TrackCard,
  TrackCardLocked,
  trackOpenTrackBlockModal,
  // player
  playerAudioSelectPlaylist,
  playerAudioSelectPlaylistLength,
  playerAudioSelectCurrentTrack,
  playerAudioSelectCurrentTrackPlaying,
  playerAudioSetCurrentTrack,
  playerAudioSetClosed,
  // like
  likeSelectTrackLikes,
  likeCreateTrackLike,
  likeDeleteTrackLike,
  // subscription
  subscriptionSelectUserSubscriptions,
  // unlock
  unlockOpenUnlockTrackModal,
  unlockSelectTrackUnlocks,
  // report
  reportOpenReportTrackModal,
} from '@/features'
// components
import { ContentPlaceholder, SkeletonHorizontal } from '@/components'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const trackLikes = useAppSelector(likeSelectTrackLikes)
  const trackUnlocks = useAppSelector(unlockSelectTrackUnlocks)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const playlist = useAppSelector(playerAudioSelectPlaylist)
  const playerAudioCurrentTrack = useAppSelector(playerAudioSelectCurrentTrack)
  const playerAudioCurrentTrackPlaying = useAppSelector(
    playerAudioSelectCurrentTrackPlaying,
  )
  const track = playlist[index]

  // if track not fetched
  if (!track) {
    return (
      <div style={style}>
        <SkeletonHorizontal
          sections={[
            {
              name: 'track-list-audio-playlist-cover',
              width: '60px',
              height: '60px',
              isStatic: true,
              isDivider: false,
            },
            {
              name: 'track-list-audio-playlist-divider',
              width: '16px',
              height: '60px',
              isStatic: true,
              isDivider: true,
            },
            {
              name: 'track-list-audio-playlist-description',
              width: '100%',
              height: '60px',
              isStatic: false,
              isDivider: false,
            },
          ]}
        />
      </div>
    )
  }

  const { id: currentUserId, role: currentUserRole } = profile ?? {}
  const { id: trackId, userId: trackAuthorUserId, visibility } = track

  const isCurrentUserAdmin = currentUserRole === EntityUserRole.Admin

  const isContentLiked = trackLikes[trackId || '']
  const isContentLocked = visibility === EntityTrackVisibility.Subscribers
  const isCurrentUserContentAuthor = currentUserId === trackAuthorUserId
  const isCurrentUserUnlockedContent = trackUnlocks[trackId || '']
  const isCurrentUserSubscribedToContentAuthor =
    userSubscriptions[trackAuthorUserId || '']

  const renderLocked =
    isContentLocked &&
    !isCurrentUserContentAuthor &&
    !isCurrentUserUnlockedContent &&
    !isCurrentUserSubscribedToContentAuthor

  const { id: playerAudioCurrentTrackId } = playerAudioCurrentTrack ?? {}
  const isTrackInProgress =
    trackId === playerAudioCurrentTrackId && playerAudioCurrentTrackPlaying

  const likeOption = {
    id: uuid(),
    label: 'like track',
    name: 'Like',
    onClick: () => dispatch(likeCreateTrackLike({ track })),
  }

  const dislikeOption = {
    id: uuid(),
    label: 'dislike track',
    name: 'Dislike',
    onClick: () => dispatch(likeDeleteTrackLike({ track })),
  }

  const playOption = {
    id: uuid(),
    label: 'play track',
    name: 'Play',
    onClick: () => dispatch(playerAudioSetCurrentTrack({ track })),
  }

  const unlockOption = {
    id: uuid(),
    label: 'unlock track',
    name: 'Unlock',
    onClick: () => dispatch(unlockOpenUnlockTrackModal({ track })),
  }

  const reportOption = {
    id: uuid(),
    label: 'report track',
    name: 'Report',
    danger: true,
    onClick: () => dispatch(reportOpenReportTrackModal({ track })),
  }

  const blockOption = {
    id: uuid(),
    label: 'block track',
    name: 'Block',
    danger: true,
    onClick: () => dispatch(trackOpenTrackBlockModal({ track })),
  }

  const likeDislikeOption = isContentLiked ? dislikeOption : likeOption
  const blockReportOption = isCurrentUserAdmin ? blockOption : reportOption

  // there should never be locked track in playlist
  // the following block will guarantee this

  // if content is locked
  if (renderLocked) {
    return (
      <div css={styles().list.row} style={style} key={`playlist-track-${trackId}`}>
        <TrackCardLocked
          track={track}
          options={[unlockOption, likeDislikeOption, blockReportOption]}
          onClick={() => dispatch(unlockOpenUnlockTrackModal({ track }))}
          onAuthorNameClick={() => {
            navigate(`${Path.User}/${trackAuthorUserId}`)
            dispatch(playerAudioSetClosed())
          }}
        />
      </div>
    )
  }

  return (
    <div css={styles().list.row} style={style} key={`playlist-track-${trackId}`}>
      <TrackCard
        track={track}
        inProgress={isTrackInProgress}
        options={[playOption, likeDislikeOption, blockReportOption]}
        onClick={() => dispatch(playerAudioSetCurrentTrack({ track }))}
        onAuthorNameClick={() => {
          navigate(`${Path.User}/${trackAuthorUserId}`)
          dispatch(playerAudioSetClosed())
        }}
      />
    </div>
  )
})

type TrackListAudioPlaylistProps = {}

export const TrackListAudioPlaylist: FC<TrackListAudioPlaylistProps> = () => {
  const playlist = useAppSelector(playerAudioSelectPlaylist)
  const playlistLength = useAppSelector(playerAudioSelectPlaylistLength)

  const trackCardHeight = 60
  const trackCardMarginBottom = 24
  const itemHeight = trackCardHeight + trackCardMarginBottom

  const defaultContentCount = 1000
  const defaultListWidth = '100%'
  const defaultListHeight = 420
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!playlist[index]

  const loadMoreItems = (): void => {
    // (startIndex: number, stopIndex: number): void
  }

  // if playlist length is 0
  if (playlistLength === 0) {
    // return place holder
    return (
      <div css={styles().list.placeholder}>
        <ContentPlaceholder
          icon={icons.MusicNotes}
          height="100%"
          title="There are no tracks in the playlist"
        />
      </div>
    )
  }

  return (
    // used to be content layout virtualized
    <div css={styles().list.main}>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={playlistLength || defaultContentCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={defaultListHeight}
            itemSize={itemHeight}
            itemCount={playlistLength || defaultContentCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="vertical"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </div>
  )
}

TrackListAudioPlaylist.propTypes = {}

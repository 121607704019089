import { FC, ButtonHTMLAttributes, ReactNode } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  active: boolean
}

export const Button: FC<ButtonProps> = ({ children, active, ...attributes }) => {
  const theme = useTheme()

  return (
    <button
      css={[
        styles(theme).button.main,
        active ? styles(theme).button.active : styles(theme).button.inactive,
      ]}
      type="button"
      {...attributes}
    >
      <div css={styles(theme).button.content.main}>
        <div css={styles(theme).button.content.children}>{children}</div>
      </div>
    </button>
  )
}

Button.propTypes = {}

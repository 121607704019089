import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { trackSelectTrackDeleteModalOpen, trackCloseTrackDeleteModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { TrackDeleteCard } from './components'
// styles
import { styles } from './styles'

type TrackDeleteModalProps = {}

export const TrackDeleteModal: FC<TrackDeleteModalProps> = () => {
  const dispatch = useAppDispatch()
  const trackDeleteModalOpen = useAppSelector(trackSelectTrackDeleteModalOpen)

  return (
    <div css={styles().modal.main}>
      {trackDeleteModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(trackCloseTrackDeleteModal())}
        >
          <div css={styles().modal.content}>
            <TrackDeleteCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

TrackDeleteModal.propTypes = {}

import { css, Theme } from '@emotion/react'
import { helpers, fonts, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  button: {
    shared: css`
      ${helpers.resetDefaults};

      font-family: ${fonts.primary};
      font-size: ${typography.p};
      font-weight: bold;
      white-space: nowrap;

      border-radius: 20px;
      border: 1px solid transparent;
      padding: 8px 26px;

      width: 100%;

      transition: border 0.2s, color 0.2s, background-color 0.2s;
    `,
    primary: css`
      cursor: pointer;
      color: ${theme.colors.primary.normal};
      background-color: ${theme.colors.accent.normal};

      &:hover {
        border: 1px solid ${theme.colors.accent.normal};
        background-color: ${theme.colors.primary.normal};
        color: ${theme.colors.accent.normal};
      }
    `,
    secondary: css`
      cursor: pointer;
      color: ${theme.colors.secondary.light};
      background-color: ${theme.colors.primary.normal};

      &:hover {
        color: ${theme.colors.accent.normal};
      }
    `,
    disabled: css`
      cursor: not-allowed;
      color: ${theme.colors.secondary.darkHover};
      background-color: ${theme.colors.secondary.darker};

      &:hover {
        border: 1px solid ${theme.colors.secondary.darker};
        background-color: ${theme.colors.secondary.darker};
        color: ${theme.colors.secondary.darkHover};
      }
    `,
  },
})

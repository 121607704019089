import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// stores
import { RootState } from '@/stores'

// types
import { AlertMessage } from './types'

export interface AlertState {
  messages: AlertMessage[]
}

const initialState: AlertState = {
  messages: [],
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    // reset state
    alertResetState: () => ({
      messages: [],
    }),

    // add message
    alertAddMessage: (state, action: PayloadAction<{ message: AlertMessage }>) => {
      const { message } = action.payload

      // mutate state
      state.messages.push(message)
    },

    // remove message
    alertRemoveMessage: (state, action: PayloadAction<{ messageId: string }>) => {
      const { messageId } = action.payload

      // mutate state
      state.messages = state.messages.filter((el) => el.id !== messageId)
    },
  },
})

export const { alertAddMessage, alertRemoveMessage } = alertSlice.actions

export const alertSelectMessages = (state: RootState) => state.alert.messages

export const alertReducer = alertSlice.reducer

// export default alertReducer.reducer

import { ThemeProvider as TP, Global } from '@emotion/react'

// hooks
import { useAppSelector } from '@/hooks'
// features
import { themeSelectCurrentTheme } from '@/features'

// styles
import { getTheme, getGlobal } from '@/styles'

type ThemeProviderProps = {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const currentTheme = useAppSelector(themeSelectCurrentTheme)

  const theme = getTheme(currentTheme)
  const global = getGlobal(currentTheme)

  return (
    <TP theme={theme}>
      <Global styles={global} />
      {children}
    </TP>
  )
}

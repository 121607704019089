import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  avatar: {
    main: css`
      position: relative;
      width: 120px;
      height: 120px;
    `,

    image: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;

        width: 120px;
        height: 120px;

        background-color: ${theme.colors.secondary.darker};

        border-radius: 50%;
        overflow: hidden;
      `,
      content: css`
        ${helpers.image};
      `,
      icon: css`
        width: 36px;
        height: 36px;

        & > path {
          fill: ${theme.colors.secondary.normal};
        }
      `,
    },

    edit: {
      main: css`
        position: absolute;
        bottom: 4px;
        right: 2px;

        width: 36px;
        height: 36px;

        border-radius: 50%;
        overflow: hidden;

        transition: background-color 0.2s;

        background-color: ${theme.colors.secondary.dark};

        &:hover {
          background-color: ${theme.colors.secondary.darkHover};
        }
      `,
      button: css`
        ${helpers.resetDefaults};

        width: 100%;
        height: 100%;

        cursor: pointer;
      `,
      icon: css`
        width: 18px;
        height: 18px;

        & > path {
          fill: ${theme.colors.primary.normal};
        }
      `,
    },
  },
})

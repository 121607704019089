import { css, Theme } from '@emotion/react'

export const styles = (theme: Theme) => ({
  divider: {
    main: css`
      display: flex;

      height: 2px;
      background-color: ${theme.colors.secondary.dark};
    `,
    maxWidth: (maxWidth: string) => css`
      max-width: ${maxWidth};
    `,
    horizontalMargin: (horizontalMargin: { left: string; right: string }) => css`
      margin-left: ${horizontalMargin.left};
      margin-right: ${horizontalMargin.right};
    `,
    verticalMargin: (verticalMargin: { top: string; bottom: string }) => css`
      margin-top: ${verticalMargin.top};
      margin-bottom: ${verticalMargin.bottom};
    `,
  },
})

import { FC, InputHTMLAttributes } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

interface ErrorProps extends InputHTMLAttributes<HTMLInputElement> {
  error: string
}

export const Error: FC<ErrorProps> = ({ error }) => {
  const theme = useTheme()

  return (
    <div
      css={[
        styles(theme).error.shared,
        error ? styles(theme).error.visible : styles(theme).error.hidden,
      ]}
    >
      <span
        css={[
          styles(theme).error.text.shared,
          error ? styles(theme).error.text.visible : styles(theme).error.text.hidden,
        ]}
      >
        {error}
      </span>
    </div>
  )
}

Error.propTypes = {}

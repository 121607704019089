import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// icons
import { icons } from '@/assets'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { tipSelectTipModalArtist, tipCloseTipModal } from '@/features'
// components
import { CardHeaderMobile, ModalCardTitle, ModalCardSubtitle, Error } from '@/components'

// components
import { TipForm } from './components'
// styles
import { styles } from './styles'

type TipCardProps = {}

export const TipCard: FC<TipCardProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const modalArtist = useAppSelector(tipSelectTipModalArtist)
  const tipError = ''

  const { id: artistUserId, username } = modalArtist ?? {}

  const subtitleAccentStartIndex = 31
  const subtitleAccentEndIndex = 32 + (username?.length || 0)

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile onCloseClick={() => dispatch(tipCloseTipModal())} />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <ModalCardTitle text="Send Tip" icon={icons.DollarCircle} />
      </div>

      {/* subtitle */}
      <div css={styles(theme).card.subtitle}>
        <ModalCardSubtitle
          text={`Show your appreciation for the @${username} by sending a tip to support their work!`}
          accent={{
            accentStart: subtitleAccentStartIndex,
            accentEnd: subtitleAccentEndIndex,
            onClick: () => navigate(`${Path.User}/${artistUserId}`),
          }}
        />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* form */}
        <div css={styles(theme).card.content.form}>
          <TipForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={tipError} />
        </div>
      </div>
    </div>
  )
}

TipCard.propTypes = {}

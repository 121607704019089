import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { unlockSelectUnlockPostModalOpen, unlockCloseUnlockPostModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { UnlockPostCard } from './components'
// styles
import { styles } from './styles'

type UnlockPostModalProps = {}

export const UnlockPostModal: FC<UnlockPostModalProps> = () => {
  const dispatch = useAppDispatch()
  const unlockPostModalOpen = useAppSelector(unlockSelectUnlockPostModalOpen)

  return (
    <div css={styles().modal.main}>
      {unlockPostModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(unlockCloseUnlockPostModal())}
        >
          <div css={styles().modal.content}>
            <UnlockPostCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

UnlockPostModal.propTypes = {}

import { FC, MouseEventHandler, useState } from 'react'
import { useTheme } from '@emotion/react'
import { EntityCommentView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useFile, useUserPublic } from '@/hooks'
// icons
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type CommentCardProps = {
  comment: EntityCommentView
  reply?: boolean
  isCurrentUserCommentAuthor: boolean
  isCurrentUserAdmin: boolean
  isLiked: boolean
  onUserNameClick: MouseEventHandler<HTMLButtonElement>
  onLikeClick: MouseEventHandler<HTMLButtonElement>
  onDislikeClick: MouseEventHandler<HTMLButtonElement>
  onReplyClick: MouseEventHandler<HTMLButtonElement>
  onBlockClick: MouseEventHandler<HTMLButtonElement>
  onDeleteClick: MouseEventHandler<HTMLButtonElement>
  onReportClick: MouseEventHandler<HTMLButtonElement>
}

export const CommentCard: FC<CommentCardProps> = ({
  comment,
  reply = false,
  isCurrentUserCommentAuthor,
  isCurrentUserAdmin,
  isLiked,
  onUserNameClick,
  onLikeClick,
  onDislikeClick,
  onReplyClick,
  onBlockClick,
  onDeleteClick,
  onReportClick,
}) => {
  const theme = useTheme()
  const { userId: commentAuthorUserId, message } = comment

  // comment author
  const { user: commentAuthor, isLoading: isCommentAuthorLoading } = useUserPublic(
    commentAuthorUserId || '',
  )
  const { name: authorName, avatarId: authorAvatarId } = commentAuthor ?? {}
  const authorLoadingPlaceholder = 'Loading...'
  const authorNamePlaceholder = '-'

  const authorHasAvatar = !!authorAvatarId
  const authorAvatar = useFile(authorAvatarId || '')
  const [authorAvatarLoadError, setAuthorAvatarLoadError] = useState(false)

  const renderLikeButtons = () => {
    // if comment liked
    if (isLiked) {
      // return dislike button
      return (
        <div css={styles(theme).card.actions.heart.main}>
          <button
            css={[
              styles(theme).card.actions.heart.button.shared,
              styles(theme).card.actions.heart.button.dislike,
            ]}
            onClick={(e) => onDislikeClick(e)}
            type="button"
            aria-label="dislike comment"
          >
            <icons.HeartFilled />
          </button>
        </div>
      )
    }

    // else return like button
    return (
      <div css={styles(theme).card.actions.heart.main}>
        <button
          css={[
            styles(theme).card.actions.heart.button.shared,
            styles(theme).card.actions.heart.button.like,
          ]}
          onClick={(e) => onLikeClick(e)}
          type="button"
          aria-label="like comment"
        >
          <icons.Heart />
        </button>
      </div>
    )
  }

  const renderActionButtons = () => {
    // if current user is comment author
    if (isCurrentUserCommentAuthor) {
      // return delete button
      return (
        <div css={styles(theme).card.actions.delete.main}>
          <button
            css={styles(theme).card.actions.delete.button}
            onClick={(e) => onDeleteClick(e)}
            type="button"
            aria-label="delete comment"
          >
            Delete
          </button>
        </div>
      )
    }

    // if current user is admin
    if (isCurrentUserAdmin) {
      // return block button
      return (
        <div css={styles(theme).card.actions.block.main}>
          <button
            css={styles(theme).card.actions.block.button}
            onClick={(e) => onBlockClick(e)}
            type="button"
            aria-label="block comment"
          >
            Block
          </button>
        </div>
      )
    }

    // else return report button
    return (
      <div css={styles(theme).card.actions.report.main}>
        <button
          css={styles(theme).card.actions.report.button}
          onClick={(e) => onReportClick(e)}
          type="button"
          aria-label="report comment"
        >
          Report
        </button>
      </div>
    )
  }

  return (
    <div css={styles(theme).card.main(reply || false)}>
      <div css={styles(theme).card.data.main}>
        <div css={styles(theme).card.data.avatar.main}>
          {authorHasAvatar && !authorAvatarLoadError ? (
            <img
              css={styles(theme).card.data.avatar.image}
              onError={() => setAuthorAvatarLoadError(true)}
              loading="lazy"
              width="30px"
              height="30px"
              src={authorAvatar}
              alt="post author avatar"
            />
          ) : (
            <icons.User css={styles(theme).card.data.avatar.icon} />
          )}
        </div>
        <div css={styles(theme).card.data.content.main}>
          <div css={styles(theme).card.data.content.name}>
            <button
              css={styles(theme).card.data.content.name}
              onClick={(e) => onUserNameClick(e)}
              type="button"
              aria-label="explore author profile"
            >
              <span>
                {isCommentAuthorLoading
                  ? authorLoadingPlaceholder
                  : authorName || authorNamePlaceholder}
              </span>
            </button>
          </div>
          <div css={styles(theme).card.data.content.message}>{message}</div>
        </div>
      </div>
      <div css={styles(theme).card.actions.main}>
        {renderLikeButtons()}
        {` — `}
        <div css={styles(theme).card.actions.reply.main}>
          <button
            css={styles(theme).card.actions.reply.button}
            onClick={(e) => onReplyClick(e)}
            type="button"
            aria-label="reply to comment"
          >
            Reply
          </button>
        </div>
        {` — `}
        {renderActionButtons()}
      </div>
    </div>
  )
}

CommentCard.propTypes = {}

import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityTrackVisibility, EntityUserRole } from '@ryddm-inc/ryddm-apiclient'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authSelectProfile,
  // track
  TrackCard,
  TrackCardLocked,
  TrackCardBlockSkeleton,
  trackSelectRecommendedTracks,
  trackSelectRecommendedTracksCount,
  trackGetRecommendedTracks,
  trackOpenTrackBlockModal,
  // player
  playerAudioSetCurrentTrack,
  // like
  likeSelectTrackLikes,
  likeCreateTrackLike,
  likeDeleteTrackLike,
  // subscription
  subscriptionSelectUserSubscriptions,
  // unlock
  unlockOpenUnlockTrackModal,
  unlockSelectTrackUnlocks,
  // report
  reportOpenReportTrackModal,
} from '@/features'
// components
import { ContentLayoutVirtualized } from '@/components'
// utils
import { uuid } from '@/utils'
// styles
import { contentLayout } from '@/styles'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const trackLikes = useAppSelector(likeSelectTrackLikes)
  const trackUnlocks = useAppSelector(unlockSelectTrackUnlocks)
  const userSubscriptions = useAppSelector(subscriptionSelectUserSubscriptions)
  const tracks = useAppSelector(trackSelectRecommendedTracks)

  const subsetLength = 3

  // create subset
  const subset = tracks.slice(index * subsetLength, index * subsetLength + subsetLength)

  // if tracks not fetched
  if (subset.length === 0) {
    return (
      <div style={style}>
        <TrackCardBlockSkeleton />
      </div>
    )
  }

  return (
    <div css={styles().tracks.main} style={style}>
      {subset.map((track) => {
        const { id: currentUserId, role: currentUserRole } = profile ?? {}
        const { id: trackId, userId: trackAuthorUserId, visibility } = track

        const isCurrentUserAdmin = currentUserRole === EntityUserRole.Admin

        const isContentLiked = trackLikes[trackId || '']
        const isContentLocked = visibility === EntityTrackVisibility.Subscribers
        const isCurrentUserContentAuthor = currentUserId === trackAuthorUserId
        const isCurrentUserUnlockedContent = trackUnlocks[trackId || '']
        const isCurrentUserSubscribedToContentAuthor =
          userSubscriptions[trackAuthorUserId || '']

        const renderLocked =
          isContentLocked &&
          !isCurrentUserContentAuthor &&
          !isCurrentUserUnlockedContent &&
          !isCurrentUserSubscribedToContentAuthor

        const likeOption = {
          id: uuid(),
          label: 'like track',
          name: 'Like',
          onClick: () => dispatch(likeCreateTrackLike({ track })),
        }

        const dislikeOption = {
          id: uuid(),
          label: 'dislike track',
          name: 'Dislike',
          onClick: () => dispatch(likeDeleteTrackLike({ track })),
        }

        const playOption = {
          id: uuid(),
          label: 'play track',
          name: 'Play',
          onClick: () => dispatch(playerAudioSetCurrentTrack({ track })),
        }

        const unlockOption = {
          id: uuid(),
          label: 'unlock track',
          name: 'Unlock',
          onClick: () => dispatch(unlockOpenUnlockTrackModal({ track })),
        }

        const reportOption = {
          id: uuid(),
          label: 'report track',
          name: 'Report',
          danger: true,
          onClick: () => dispatch(reportOpenReportTrackModal({ track })),
        }

        const blockOption = {
          id: uuid(),
          label: 'block track',
          name: 'Block',
          danger: true,
          onClick: () => dispatch(trackOpenTrackBlockModal({ track })),
        }

        const likeDislikeOption = isContentLiked ? dislikeOption : likeOption
        const blockReportOption = isCurrentUserAdmin ? blockOption : reportOption

        // if content is locked
        if (renderLocked) {
          return (
            <div css={styles().tracks.row} key={`recommended-track-${trackId}`}>
              <TrackCardLocked
                track={track}
                options={[unlockOption, likeDislikeOption, blockReportOption]}
                onClick={() => dispatch(unlockOpenUnlockTrackModal({ track }))}
                onAuthorNameClick={() => navigate(`${Path.User}/${trackAuthorUserId}`)}
              />
            </div>
          )
        }

        return (
          <div css={styles().tracks.row} key={`recommended-track-${trackId}`}>
            <TrackCard
              track={track}
              options={[playOption, likeDislikeOption, blockReportOption]}
              onClick={() => dispatch(playerAudioSetCurrentTrack({ track }))}
              onAuthorNameClick={() => navigate(`${Path.User}/${trackAuthorUserId}`)}
            />
          </div>
        )
      })}
    </div>
  )
})

type TrackListFeedRecommendedProps = {}

export const TrackListFeedRecommended: FC<TrackListFeedRecommendedProps> = () => {
  const dispatch = useAppDispatch()
  const recommendedTracks = useAppSelector(trackSelectRecommendedTracks)
  const recommendedTracksCount = useAppSelector(trackSelectRecommendedTracksCount)

  const trackBlockWidth = 280
  const trackBlockMarginRight = 20
  const itemWidth = trackBlockWidth + trackBlockMarginRight

  const trackBlockHeight = 228
  const trackBlockMarginBottom = 0
  const itemHeight = trackBlockHeight + trackBlockMarginBottom

  const defaultItemsCount = 1000
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const subsetLength = 3 // for each 3 items render one item block

  // items to render in virtualized list
  // each track item holds 3 tracks
  // ceil lets us render required amount of items
  // e.g. if items count is 4: ceil(4/3) = 2 items to render
  // 3 items in first block, 1 item in second
  const itemsCount = Math.ceil(recommendedTracksCount / subsetLength)

  const isItemLoaded = (index: number) => !!recommendedTracks[index * subsetLength]

  const loadMoreItems = (startIndex: number): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(
      trackGetRecommendedTracks({
        limit: itemsToFetch * subsetLength,
        offset: startIndex * subsetLength,
      }),
    )
  }

  return (
    <ContentLayoutVirtualized
      title="Tracks"
      overrideCss={contentLayout.feed}
      itemWidth={itemWidth}
      itemsCount={recommendedTracksCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={itemsCount || defaultItemsCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={itemsCount || defaultItemsCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

TrackListFeedRecommended.propTypes = {}

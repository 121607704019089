import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type StatProps = {
  name: string
  count: string
}

export const Stat: FC<StatProps> = ({ name, count }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).stat.main}>
      <div css={styles(theme).stat.count}>{count}</div>
      <div css={styles(theme).stat.name}>{name}</div>
    </div>
  )
}

Stat.propTypes = {}

import { FC, memo } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { EntityPostVisibility } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  // auth
  authOpenJoinModal,
  // post
  PostCardPublic,
  PostCardLocked,
  postSelectPublicPosts,
  postGetPublicPosts,
  postUtilGetPostCardSkeletonSections,
} from '@/features'
// components
import { ContentLayoutVirtualized, SkeletonVertical } from '@/components'
// styles
import { contentLayout } from '@/styles'

// styles
import { styles } from './styles'

const Row: FC<ListChildComponentProps<any>> = memo(({ index, style }) => {
  const dispatch = useAppDispatch()
  const posts = useAppSelector(postSelectPublicPosts)
  const post = posts[index]

  // if post not fetched
  if (!post) {
    return (
      <div style={style}>
        <SkeletonVertical
          containerWidth="180px"
          sections={postUtilGetPostCardSkeletonSections('post-list-home-public')}
        />
      </div>
    )
  }

  const { visibility } = post

  const isContentLocked = visibility === EntityPostVisibility.Subscribers
  const renderLocked = isContentLocked

  // if content is locked
  if (renderLocked) {
    return (
      <div css={styles().posts.main} style={style}>
        <PostCardLocked
          post={post}
          onClick={() => dispatch(authOpenJoinModal())}
          onAuthorNameClick={() => dispatch(authOpenJoinModal())}
          onAuthorUsernameClick={() => dispatch(authOpenJoinModal())}
        />
      </div>
    )
  }

  return (
    <div css={styles().posts.main} style={style}>
      <PostCardPublic
        post={post}
        overlayAction={{
          name: 'Follow',
          label: 'follow artist',
          onClick: () => dispatch(authOpenJoinModal()),
        }}
      />
    </div>
  )
})

type PostListHomePublicProps = {}

export const PostListHomePublic: FC<PostListHomePublicProps> = () => {
  const dispatch = useAppDispatch()
  const publicPosts = useAppSelector(postSelectPublicPosts)

  const postCardWidth = 180
  const postCardMarginRight = 20
  const itemWidth = postCardWidth + postCardMarginRight

  const postCardHeight = 294
  const postCardMarginBottom = 0
  const itemHeight = postCardHeight + postCardMarginBottom

  // TODO: uncomment once 60 posts created
  // const defaultContentCount = 60
  const defaultContentCount = 12
  const defaultListWidth = 2238
  const itemsToFetch = 30 // min number of rows to be loaded at a time
  const itemThreshold = 15 // start fetching data when user scrolls within 5 rows

  const isItemLoaded = (index: number) => !!publicPosts[index]

  const loadMoreItems = (): void => {
    // (startIndex: number, stopIndex: number): void
    dispatch(postGetPublicPosts())
  }

  return (
    <ContentLayoutVirtualized
      title="Popular Posts"
      overrideCss={contentLayout.home}
      itemWidth={itemWidth}
      itemsCount={defaultContentCount}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={defaultContentCount}
        minimumBatchSize={itemsToFetch}
        threshold={itemThreshold}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="virtualized-list"
            height={itemHeight}
            itemSize={itemWidth}
            itemCount={defaultContentCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={defaultListWidth}
            layout="horizontal"
          >
            {Row}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </ContentLayoutVirtualized>
  )
}

PostListHomePublic.propTypes = {}

import { FC } from 'react'
import { EntityUserRole } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppSelector } from '@/hooks'
// features
import {
  authSelectProfile,
  TransactionListProfileIncome,
  TransactionListProfileOutgoing,
} from '@/features'
import { SectionsLayout } from '@/components'

type TransactionListProfileProps = {}

export const TransactionListProfile: FC<TransactionListProfileProps> = () => {
  const profile = useAppSelector(authSelectProfile)
  const isUserArtist = profile?.role === EntityUserRole.Artist

  // if user is artist
  if (isUserArtist) {
    // return artist transaction list
    return (
      <SectionsLayout
        sections={[
          {
            id: 'profile-income-transactions',
            name: 'Incomes',
            section: <TransactionListProfileIncome />,
          },
          {
            id: 'profile-outgoing-transactions',
            name: 'Outgoings',
            section: <TransactionListProfileOutgoing />,
          },
        ]}
      />
    )
  }

  // else return fan/admin transaction list (only outgoing)
  return (
    <SectionsLayout
      sections={[
        {
          id: 'profile-outgoing-transactions',
          name: 'Outgoings',
          section: <TransactionListProfileOutgoing />,
        },
      ]}
    />
  )
}

TransactionListProfile.propTypes = {}

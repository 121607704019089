import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { trackSelectTrackBlockModalOpen, trackCloseTrackBlockModal } from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { TrackBlockCard } from './components'
// styles
import { styles } from './styles'

type TrackBlockModalProps = {}

export const TrackBlockModal: FC<TrackBlockModalProps> = () => {
  const dispatch = useAppDispatch()
  const trackBlockModalOpen = useAppSelector(trackSelectTrackBlockModalOpen)

  return (
    <div css={styles().modal.main}>
      {trackBlockModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(trackCloseTrackBlockModal())}
        >
          <div css={styles().modal.content}>
            <TrackBlockCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

TrackBlockModal.propTypes = {}

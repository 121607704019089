/**
 * Represents decoded API authentication token.
 * @type {JWT}
 */
type JWT = {
  exp: number
  iat: number
  iss: string
  nbf: number
  origin: string
  userId: string
  userRole: string
}

/**
 * Calculates count of days before api authentication token is expired.
 *
 * Takes api jwt token as a string, returns decoded token.
 *
 * @param {JWT} token - API decoded authentication token.
 * @returns {number} - Count of days before token is expired (can be negative value).
 *
 * @example
 * // Returns count of days before api authentication is expired.
 * const expiredInDays = jwtGetExpiresInDays({...});
 */
export const jwtGetExpiresInDays = (token: JWT): number => {
  const day = 60 * 60 * 24
  const currentDate = Math.floor(Date.now() / 1000)
  const expiredInDays = Math.floor((token.exp - currentDate) / day)

  return expiredInDays
}

/**
 * Decodes api authentication token (jwt).
 *
 * Takes api jwt token as a string, returns decoded token.
 *
 * @param {string} token - API authentication token.
 * @returns {JWT} - Decoded API authentication token.
 *
 * @example
 * // Returns decoded api authentication token.
 * const token = jwtDecode('eyJhbGciOiJI...');
 */
export const jwtDecode = (token: string): JWT => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

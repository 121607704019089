import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// hooks
import { useAppSelector, useAppDispatch, useUserPublic } from '@/hooks'
// icons
import { icons } from '@/assets'
// features
import { unlockSelectUnlockPostModalPost, unlockCloseUnlockPostModal } from '@/features'
// components
import { CardHeaderMobile, ModalCardTitle, ModalCardSubtitle, Error } from '@/components'

// components
import { UnlockPostForm } from './components'
// styles
import { styles } from './styles'

type UnlockPostCardProps = {}

export const UnlockPostCard: FC<UnlockPostCardProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const modalPost = useAppSelector(unlockSelectUnlockPostModalPost)
  const unlockPostError = ''

  const { userId: authorUserId } = modalPost ?? {}

  // post author
  const { user: postAuthor, isLoading: isPostAuthorLoading } = useUserPublic(
    authorUserId || '',
  )
  const { username: authorUsername } = postAuthor ?? {}
  const authorLoadingPlaceholder = 'Loading...'
  const authorUsernamePlaceholder = 'unknown'

  const subtitleAccentStartIndex = 52
  const subtitleAccentEndIndex =
    53 +
    (authorUsername?.length ||
      authorLoadingPlaceholder.length ||
      authorUsernamePlaceholder.length)

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile onCloseClick={() => dispatch(unlockCloseUnlockPostModal())} />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <ModalCardTitle text="Unlock Post" icon={icons.Unlock} />
      </div>

      {/* subtitle */}
      <div css={styles(theme).card.subtitle}>
        <ModalCardSubtitle
          text={`Access premium content and express your support for @${
            isPostAuthorLoading
              ? authorLoadingPlaceholder
              : authorUsername || authorUsernamePlaceholder
          } by unlocking exclusive post.`}
          accent={{
            accentStart: subtitleAccentStartIndex,
            accentEnd: subtitleAccentEndIndex,
            onClick: () => navigate(`${Path.User}/${authorUserId}`),
          }}
        />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* form */}
        <div css={styles(theme).card.content.form}>
          <UnlockPostForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={unlockPostError} />
        </div>
      </div>
    </div>
  )
}

UnlockPostCard.propTypes = {}

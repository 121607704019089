import { css } from '@emotion/react'

export const styles = () => ({
  layout: {
    main: css`
      display: flex;
      min-height: 100vh;
    `,
  },
})

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type CardDividerProps = {
  text?: string
}

export const CardDivider: FC<CardDividerProps> = ({ text = '' }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).divider.main}>
      <div css={styles(theme).divider.bar} />
      {text && (
        <div css={styles(theme).divider.text.main}>
          <span css={styles(theme).divider.text.text}>{text}</span>
        </div>
      )}
    </div>
  )
}

CardDivider.propTypes = {}

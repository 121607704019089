import { css } from '@emotion/react'

export const styles = () => ({
  form: {
    main: css``,
    content: {
      main: css``,
      message: css`
        text-align: center;
        word-wrap: break-word;
        overflow: hidden;

        margin-bottom: 24px;
      `,
      submit: css``,
    },
  },
})

import useSWR from 'swr'

// libs
import { apiGetService, apiHandleError } from '@/lib'

// types
import { SWRKeys, SWRFetcherData } from './types'

const getConnectReadiness = async ({
  data: { userId },
}: SWRFetcherData<{ userId: string }>) => {
  // get api service
  const api = apiGetService()

  try {
    // get connect readiness
    const { ready } = await api.connectApi.getConnectReadiness(userId)

    // return payload
    return { ready, error: '' }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return payload
    return { ready: false, error: message }
  }
}

const fetcher = (args: SWRFetcherData<{ userId: string }>) => getConnectReadiness(args)

export const useConnectReady = (userId: string) => {
  const shouldFetch = !!userId

  const { data, isLoading } = useSWR(
    shouldFetch ? { key: SWRKeys.UseConnectReady, data: { userId } } : null,
    fetcher,
    { revalidateOnFocus: false },
  )

  return {
    ready: data?.ready,
    isLoading,
    isError: data?.error,
  }
}

import { FC, MouseEventHandler, createElement, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { EntityNotificationView } from '@ryddm-inc/ryddm-apiclient'

// hooks
import { useAppDispatch, useUsers } from '@/hooks'
// features
import { notificationReadNotification } from '@/features'

// utils
import {
  getNotificationIcon,
  getNotificationTitle,
  getNotificationOthers,
  formantNotificationSources,
} from './utils'
// components
import { Avatar } from './components'
// styles
import { styles } from './styles'

type NotificationCardProps = {
  notification: EntityNotificationView
  onClick: MouseEventHandler<HTMLDivElement>
}

export const NotificationCard: FC<NotificationCardProps> = ({
  notification,
  onClick,
}) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const {
    id: notificationId,
    sourceUserIds,
    sourceUserIdsCount,
    event,
    read,
  } = notification

  const isSourcesPresent = !!(sourceUserIds && sourceUserIds.length > 0)
  const isSingleSource = !!(sourceUserIds && sourceUserIds.length === 1)
  const isDoubleSource = !!(sourceUserIds && sourceUserIds.length === 2)
  const isTripleSource = !!(sourceUserIds && sourceUserIds.length === 3)

  // source users
  const { users: sourceUsers, isLoading: isSourceUsersLoading } = useUsers(
    sourceUserIds || [],
  )
  const userLoadingPlaceholder = 'Loading...'

  useEffect(() => {
    // if notification is not read
    if (!read) {
      // flag notification as read
      dispatch(notificationReadNotification({ notificationId: notificationId || '' }))
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return (
    <div css={styles(theme).card.main} onClick={(e) => onClick(e)} aria-hidden="true">
      <div css={styles(theme).card.data.main}>
        {/* source */}
        <div css={styles(theme).card.data.source.main}>
          {isSourcesPresent ? (
            <div
              css={[
                styles(theme).card.data.source.avatars.shared,
                isSingleSource && styles(theme).card.data.source.avatars.single,
                isDoubleSource && styles(theme).card.data.source.avatars.double,
                isTripleSource && styles(theme).card.data.source.avatars.triple,
              ]}
            >
              {sourceUsers &&
                sourceUsers.map(({ id, avatarId }) => (
                  <Avatar key={`notification-avatar-${id}`} avatarId={avatarId} />
                ))}
            </div>
          ) : (
            <div css={styles(theme).card.data.source.icon}>
              {event && createElement(getNotificationIcon(event))}
            </div>
          )}
        </div>

        {/* info */}
        <div css={styles(theme).card.data.info.main}>
          {/* sources */}
          {isSourcesPresent && (
            <div css={styles(theme).card.data.info.sources}>
              {isSourceUsersLoading
                ? userLoadingPlaceholder
                : formantNotificationSources(sourceUsers || [])}
            </div>
          )}

          {/* titles */}
          <div css={styles(theme).card.data.info.titles}>
            {getNotificationOthers(sourceUserIds || [], sourceUserIdsCount || 0)}{' '}
            {event && getNotificationTitle(event)}
          </div>
        </div>
      </div>
    </div>
  )
}

NotificationCard.propTypes = {}

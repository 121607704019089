import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  track: {
    main: css`
      display: flex;
      align-items: center;

      width: 330px;
    `,

    cover: {
      main: css`
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 60px;
        height: 60px;

        background-color: ${theme.colors.primary.darker};
        border-radius: 4px;
        overflow: hidden;

        margin-right: 16px;

        &: hover > div {
          opacity: 1;
        }
      `,
      image: css`
        ${helpers.image};
      `,
      icon: css`
        width: 20px;
        height: 20px;

        & > path {
          color: ${theme.colors.secondary.normal};
        }
        & > circle {
          color: ${theme.colors.secondary.normal};
        }
      `,
    },

    description: {
      main: css`
        display: flex;
        flex-direction: column;

        min-width: 0;
        margin-right: auto;
      `,
      name: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.p};
        font-weight: bold;
      `,
      author: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.sp};
      `,
    },

    delete: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;

        margin-left: 16px;
      `,

      button: {
        shared: css`
          ${helpers.resetDefaults};

          width: 30px;
          height: 30px;

          & > svg > path {
            transition: fill 0.2s, color 0.2s;
          }
        `,

        disabled: css`
          cursor: not-allowed;

          & > svg > path {
            fill: ${theme.colors.secondary.darkHover};
          }

          &:hover svg > path {
            fill: ${theme.colors.secondary.darkHover};
          }
        `,

        enabled: css`
          cursor: pointer;

          & > svg > path {
            fill: ${theme.colors.secondary.normal};
          }

          &:hover svg > path {
            fill: ${theme.colors.accent.normal};
          }
        `,

        icon: css`
          width: 20px;
          height: 20px;
        `,
      },
    },
  },
})

import { css, Theme } from '@emotion/react'
import { helpers, typography, z } from '@/styles'

export const styles = (theme: Theme) => ({
  track: {
    main: css`
      display: flex;
      align-items: center;

      width: 100%;
    `,

    cover: {
      main: css`
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 60px;
        height: 60px;

        background-color: ${theme.colors.primary.darker};
        border-radius: 4px;
        overflow: hidden;

        margin-right: 16px;

        &: hover > div {
          opacity: 1;
        }
      `,

      overlay: {
        main: css`
          z-index: ${z.features.track.card.overlay};

          position: absolute;
          left: 0;
          top: 0;

          width: 100%;
          height: 100%;

          transition: opacity 0.2s;
          opacity: 0;

          background-color: ${theme.colors.primary.normal}A0;
        `,

        button: css`
          ${helpers.resetDefaults};

          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 100%;

          cursor: pointer;

          & > svg {
            width: 20px;
            height: 20px;

            & path {
              fill: ${theme.colors.accent.normal};
            }
          }
        `,
      },

      image: css`
        ${helpers.image};
      `,

      placeholder: css`
        display: flex;

        & > svg {
          width: 20px;
          height: 20px;

          & > path {
            color: ${theme.colors.secondary.normal};
          }
          & > circle {
            color: ${theme.colors.secondary.normal};
          }
        }
      `,
    },

    description: {
      main: css`
        display: flex;
        flex-direction: column;

        height: 60px;

        min-width: 0;
        margin-right: auto;
      `,
      name: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.p};
        font-weight: bold;
      `,
      author: css`
        ${helpers.textOverflowEllipsis};

        font-size: ${typography.sp};
        margin-bottom: 3px;
      `,
      stats: {
        main: css`
          display: flex;
        `,
        likes: {
          main: css`
            display: flex;
            margin-right: 8px;

            min-width: 0;
          `,
          icon: css`
            display: flex;
            align-items: center;
            margin-right: 4px;

            & > svg {
              width: 10px;
              height: 10px;
            }
          `,
          count: css`
            ${helpers.textOverflowEllipsis};

            font-size: ${typography.sp};
          `,
        },
        unlocks: {
          main: css`
            display: flex;

            min-width: 0;
          `,
          icon: css`
            display: flex;
            align-items: center;
            margin-right: 4px;

            & > svg {
              width: 10px;
              height: 10px;
            }
          `,
          count: css`
            ${helpers.textOverflowEllipsis};

            font-size: ${typography.sp};
          `,
        },
      },
    },

    options: {
      main: css`
        display: flex;
      `,
      edit: {
        main: css``,
        button: {
          shared: css`
            ${helpers.resetDefaults};

            display: flex;
            align-items: center;
            justify-content: center;

            width: 30px;
            height: 30px;
          `,
          disabled: css`
            cursor: not-allowed;

            & > svg > path {
              fill: ${theme.colors.secondary.darker};
            }

            &:hover svg > path {
              fill: ${theme.colors.secondary.darker};
            }
          `,
          enabled: css`
            cursor: pointer;

            & > svg > path {
              fill: ${theme.colors.secondary.normal};
            }

            &:hover svg > path {
              fill: ${theme.colors.accent.normal};
            }
          `,
        },
        icon: css`
          width: 20px;
          height: 20px;

          & > path {
            transition: fill 0.2s, color 0.2s;
          }
        `,
      },
      delete: {
        main: css`
          margin-left: 6px;
        `,
        button: {
          shared: css`
            ${helpers.resetDefaults};

            display: flex;
            align-items: center;
            justify-content: center;

            width: 30px;
            height: 30px;
          `,
          disabled: css`
            cursor: not-allowed;

            & > svg > path {
              fill: ${theme.colors.secondary.darker};
            }

            &:hover svg > path {
              fill: ${theme.colors.secondary.darker};
            }
          `,
          enabled: css`
            cursor: pointer;

            & > svg > path {
              fill: ${theme.colors.secondary.normal};
            }

            &:hover svg > path {
              fill: ${theme.colors.accent.normal};
            }
          `,
        },
        icon: css`
          width: 20px;
          height: 20px;

          & > path {
            transition: fill 0.2s, color 0.2s;
          }
        `,
      },
    },
  },
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// utils
import { mergeArraysAtIndex } from '@/utils'
// stores
import { RootState } from '@/stores'
// features
import {
  trackGetPublicTracks,
  trackGetPopularTracks,
  trackGetExclusiveTracks,
  trackGetLatestTracks,
  trackGetTrendingTracks,
  trackGetRecommendedTracks,
  trackGetLikedTracks,
  trackGetUnlockedTracks,
  trackGetUploadedTracks,
  trackGetArtistPopularTracks,
  trackGetArtistExclusiveTracks,
  trackGetArtistLatestTracks,
  trackGetPostTracks,
  trackCreate,
  trackUpdate,
  trackBlock,
  trackDelete,
} from '@/features'

export interface TrackState {
  publicTracks: EntityTrackView[]
  publicTracksCount: number
  publicTracksFetched: boolean

  popularTracks: EntityTrackView[]
  popularTracksCount: number
  popularTracksFetched: boolean

  exclusiveTracks: EntityTrackView[]
  exclusiveTracksCount: number
  exclusiveTracksFetched: boolean

  latestTracks: EntityTrackView[]
  latestTracksCount: number
  latestTracksFetched: boolean

  trendingTracks: EntityTrackView[]
  trendingTracksCount: number
  trendingTracksFetched: boolean

  recommendedTracks: EntityTrackView[]
  recommendedTracksCount: number
  recommendedTracksFetched: boolean

  likedTracks: EntityTrackView[]
  likedTracksCount: number
  likedTracksFetched: boolean

  unlockedTracks: EntityTrackView[]
  unlockedTracksCount: number
  unlockedTracksFetched: boolean

  uploadedTracks: EntityTrackView[]
  uploadedTracksCount: number
  uploadedTracksFetched: boolean

  artistPopularTracks: EntityTrackView[]
  artistPopularTracksCount: number
  artistPopularTracksFetched: boolean

  artistExclusiveTracks: EntityTrackView[]
  artistExclusiveTracksCount: number
  artistExclusiveTracksFetched: boolean

  artistLatestTracks: EntityTrackView[]
  artistLatestTracksCount: number
  artistLatestTracksFetched: boolean

  postTracks: EntityTrackView[]
  postTracksFetched: boolean

  trackBlockModal: {
    track: EntityTrackView | undefined
    open: boolean
  }

  trackDeleteModal: {
    track: EntityTrackView | undefined
    open: boolean
  }

  trackGetPublicTracksLoading: boolean
  trackGetPopularTracksLoading: boolean
  trackGetExclusiveTracksLoading: boolean
  trackGetLatestTracksLoading: boolean
  trackGetTrendingTracksLoading: boolean
  trackGetRecommendedTracksLoading: boolean
  trackGetLikedTracksLoading: boolean
  trackGetUnlockedTracksLoading: boolean
  trackGetUploadedTracksLoading: boolean
  trackGetArtistPopularTracksLoading: boolean
  trackGetArtistExclusiveTracksLoading: boolean
  trackGetArtistLatestTracksLoading: boolean
  trackGetPostTracksLoading: boolean
  trackCreateLoading: boolean
  trackUpdateLoading: boolean
  trackBlockLoading: boolean
  trackDeleteLoading: boolean
}

const initialState: TrackState = {
  publicTracks: [],
  publicTracksCount: 0,
  publicTracksFetched: false,

  popularTracks: [],
  popularTracksCount: 0,
  popularTracksFetched: false,

  exclusiveTracks: [],
  exclusiveTracksCount: 0,
  exclusiveTracksFetched: false,

  latestTracks: [],
  latestTracksCount: 0,
  latestTracksFetched: false,

  trendingTracks: [],
  trendingTracksCount: 0,
  trendingTracksFetched: false,

  recommendedTracks: [],
  recommendedTracksCount: 0,
  recommendedTracksFetched: false,

  likedTracks: [],
  likedTracksCount: 0,
  likedTracksFetched: false,

  unlockedTracks: [],
  unlockedTracksCount: 0,
  unlockedTracksFetched: false,

  uploadedTracks: [],
  uploadedTracksCount: 0,
  uploadedTracksFetched: false,

  artistPopularTracks: [],
  artistPopularTracksCount: 0,
  artistPopularTracksFetched: false,

  artistExclusiveTracks: [],
  artistExclusiveTracksCount: 0,
  artistExclusiveTracksFetched: false,

  artistLatestTracks: [],
  artistLatestTracksCount: 0,
  artistLatestTracksFetched: false,

  postTracks: [],
  postTracksFetched: false,

  trackBlockModal: {
    track: undefined,
    open: false,
  },

  trackDeleteModal: {
    track: undefined,
    open: false,
  },

  trackGetPublicTracksLoading: false,
  trackGetPopularTracksLoading: false,
  trackGetExclusiveTracksLoading: false,
  trackGetLatestTracksLoading: false,
  trackGetTrendingTracksLoading: false,
  trackGetRecommendedTracksLoading: false,
  trackGetLikedTracksLoading: false,
  trackGetUnlockedTracksLoading: false,
  trackGetUploadedTracksLoading: false,
  trackGetArtistPopularTracksLoading: false,
  trackGetArtistExclusiveTracksLoading: false,
  trackGetArtistLatestTracksLoading: false,
  trackGetPostTracksLoading: false,
  trackCreateLoading: false,
  trackUpdateLoading: false,
  trackBlockLoading: false,
  trackDeleteLoading: false,
}

export const trackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    // reset state
    trackResetState: () => ({
      publicTracks: [],
      publicTracksCount: 0,
      publicTracksFetched: false,

      popularTracks: [],
      popularTracksCount: 0,
      popularTracksFetched: false,

      exclusiveTracks: [],
      exclusiveTracksCount: 0,
      exclusiveTracksFetched: false,

      latestTracks: [],
      latestTracksCount: 0,
      latestTracksFetched: false,

      trendingTracks: [],
      trendingTracksCount: 0,
      trendingTracksFetched: false,

      recommendedTracks: [],
      recommendedTracksCount: 0,
      recommendedTracksFetched: false,

      likedTracks: [],
      likedTracksCount: 0,
      likedTracksFetched: false,

      unlockedTracks: [],
      unlockedTracksCount: 0,
      unlockedTracksFetched: false,

      profileTracks: [],
      profileTracksCount: 0,
      profileTracksFetched: false,

      uploadedTracks: [],
      uploadedTracksCount: 0,
      uploadedTracksFetched: false,

      artistPopularTracks: [],
      artistPopularTracksCount: 0,
      artistPopularTracksFetched: false,

      artistExclusiveTracks: [],
      artistExclusiveTracksCount: 0,
      artistExclusiveTracksFetched: false,

      artistLatestTracks: [],
      artistLatestTracksCount: 0,
      artistLatestTracksFetched: false,

      postTracks: [],
      postTracksFetched: false,

      trackBlockModal: {
        track: undefined,
        open: false,
      },

      trackDeleteModal: {
        track: undefined,
        open: false,
      },

      trackGetPublicTracksLoading: false,
      trackGetPopularTracksLoading: false,
      trackGetExclusiveTracksLoading: false,
      trackGetLatestTracksLoading: false,
      trackGetTrendingTracksLoading: false,
      trackGetRecommendedTracksLoading: false,
      trackGetLikedTracksLoading: false,
      trackGetUnlockedTracksLoading: false,
      trackGetUploadedTracksLoading: false,
      trackGetArtistPopularTracksLoading: false,
      trackGetArtistExclusiveTracksLoading: false,
      trackGetArtistLatestTracksLoading: false,
      trackGetPostTracksLoading: false,
      trackCreateLoading: false,
      trackUpdateLoading: false,
      trackBlockLoading: false,
      trackDeleteLoading: false,
    }),

    // set public tracks
    trackSetPublicTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { tracks, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.publicTracksCount = count

        // mutate public tracks array
        state.publicTracks = mergeArraysAtIndex(state.publicTracks, tracks, offset)
      } else {
        // this block will be used for search
        // during search array of tracks will have to be recrated
        // in accordance to the new count
      }
    },

    // set popular tracks
    trackSetPopularTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { tracks, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.popularTracksCount = count

        // mutate popular tracks array
        state.popularTracks = mergeArraysAtIndex(state.popularTracks, tracks, offset)
      } else {
        // this block will be used for search
        // during search array of tracks will have to be recrated
        // in accordance to the new count
      }
    },

    // set exclusive tracks
    trackSetExclusiveTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { tracks, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.exclusiveTracksCount = count

        // mutate exclusive tracks array
        state.exclusiveTracks = mergeArraysAtIndex(state.exclusiveTracks, tracks, offset)
      } else {
        // this block will be used for search
        // during search array of tracks will have to be recrated
        // in accordance to the new count
      }
    },

    // set latest tracks
    trackSetLatestTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { tracks, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.latestTracksCount = count

        // mutate latest tracks array
        state.latestTracks = mergeArraysAtIndex(state.latestTracks, tracks, offset)
      } else {
        // this block will be used for search
        // during search array of tracks will have to be recrated
        // in accordance to the new count
      }
    },

    // set trending tracks
    trackSetTrendingTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { tracks, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.trendingTracksCount = count

        // mutate trending tracks array
        state.trendingTracks = mergeArraysAtIndex(state.trendingTracks, tracks, offset)
      } else {
        // this block will be used for search
        // during search array of tracks will have to be recrated
        // in accordance to the new count
      }
    },

    // set recommended tracks
    trackSetRecommendedTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { tracks, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.recommendedTracksCount = count

        // mutate recommended tracks array
        state.recommendedTracks = mergeArraysAtIndex(
          state.recommendedTracks,
          tracks,
          offset,
        )
      } else {
        // this block will be used for search
        // during search array of tracks will have to be recrated
        // in accordance to the new count
      }
    },

    // set liked tracks
    trackSetLikedTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { tracks, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.likedTracksCount = count

        // mutate liked tracks array
        state.likedTracks = mergeArraysAtIndex(state.likedTracks, tracks, offset)
      } else {
        // this block will be used for search
        // during search array of tracks will have to be recrated
        // in accordance to the new count
      }
    },

    // set unlocked tracks
    trackSetUnlockedTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { tracks, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.unlockedTracksCount = count

        // mutate unlocked tracks array
        state.unlockedTracks = mergeArraysAtIndex(state.unlockedTracks, tracks, offset)
      } else {
        // this block will be used for search
        // during search array of tracks will have to be recrated
        // in accordance to the new count
      }
    },

    // set uploaded tracks
    trackSetUploadedTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
        mutate: boolean
      }>,
    ) => {
      const { tracks, count, offset, mutate } = action.payload

      // if mutate
      if (mutate) {
        // mutate count
        state.uploadedTracksCount = count

        // mutate uploaded tracks array
        state.uploadedTracks = mergeArraysAtIndex(state.uploadedTracks, tracks, offset)
      } else {
        // else generate new array
        // this block will be used for search
        // during search array of tracks will have to be recrated
        // in accordance to the new count
      }
    },

    // set artist popular tracks
    trackSetArtistPopularTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
      }>,
    ) => {
      const { tracks, count, offset } = action.payload

      // mutate count
      state.artistPopularTracksCount = count

      // mutate artist popular tracks array
      state.artistPopularTracks = mergeArraysAtIndex(
        state.artistPopularTracks,
        tracks,
        offset,
      )
    },

    // set artist exclusive tracks
    trackSetArtistExclusiveTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
      }>,
    ) => {
      const { tracks, count, offset } = action.payload

      // mutate count
      state.artistExclusiveTracksCount = count

      // mutate artist exclusive tracks array
      state.artistExclusiveTracks = mergeArraysAtIndex(
        state.artistExclusiveTracks,
        tracks,
        offset,
      )
    },

    // set artist latest tracks
    trackSetArtistLatestTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
        count: number
        offset: number
      }>,
    ) => {
      const { tracks, count, offset } = action.payload

      // mutate count
      state.artistLatestTracksCount = count

      // mutate artist latest tracks array
      state.artistLatestTracks = mergeArraysAtIndex(
        state.artistLatestTracks,
        tracks,
        offset,
      )
    },

    // reset artist tracks
    trackResetArtistTracks: (state) => ({
      ...state,

      artistPopularTracks: [],
      artistPopularTracksCount: 0,
      artistPopularTracksFetched: false,

      artistExclusiveTracks: [],
      artistExclusiveTracksCount: 0,
      artistExclusiveTracksFetched: false,

      artistLatestTracks: [],
      artistLatestTracksCount: 0,
      artistLatestTracksFetched: false,
    }),

    // set post tracks
    trackSetPostTracks: (
      state,
      action: PayloadAction<{
        tracks: EntityTrackView[]
      }>,
    ) => {
      const { tracks } = action.payload

      // update post tracks state
      state.postTracks = tracks
    },

    // reset post tracks
    trackResetPostTracks: (state) => ({
      ...state,

      postTracks: [],
      postTracksFetched: false,
    }),

    // append liked track
    trackAppendLikedTrack: (state, action: PayloadAction<{ track: EntityTrackView }>) => {
      const { track } = action.payload

      // if liked tracks are fetched
      if (state.likedTracksFetched) {
        // append track to liked tracks
        state.likedTracks.unshift(track)
        state.likedTracksCount += 1
      }
    },

    // filter liked track
    trackFilterLikedTrack: (state, action: PayloadAction<{ track: EntityTrackView }>) => {
      const { track } = action.payload

      // if liked tracks are fetched
      if (state.likedTracksFetched) {
        // filter track from liked tracks
        state.likedTracks = state.likedTracks.filter((t) => t.id !== track.id)
        state.likedTracksCount -= 1
      }
    },

    // append unlocked track
    trackAppendUnlockedTrack: (
      state,
      action: PayloadAction<{ track: EntityTrackView }>,
    ) => {
      const { track } = action.payload

      // if unlocked tracks are fetched
      if (state.unlockedTracksFetched) {
        // append track to unlocked tracks
        state.unlockedTracks.unshift(track)
        state.unlockedTracksCount += 1
      }
    },

    // filter unlocked track
    trackFilterUnlockedTrack: (
      state,
      action: PayloadAction<{ track: EntityTrackView }>,
    ) => {
      const { track } = action.payload

      // if unlocked tracks are fetched
      if (state.unlockedTracksFetched) {
        // filter track from unlocked tracks
        state.unlockedTracks = state.unlockedTracks.filter((t) => t.id !== track.id)
        state.unlockedTracksCount -= 1
      }
    },

    // filter track
    trackFilterTrack: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload

      // check if track exists in public tracks
      const pubIndex = state.publicTracks.findIndex((t) => t.id === id)
      if (pubIndex !== -1) {
        // filter track
        state.publicTracks = state.publicTracks.filter((t) => t.id !== id)
        state.publicTracksCount -= 1
      }

      // check if track exists in popular tracks
      const popIndex = state.popularTracks.findIndex((t) => t.id === id)
      if (popIndex !== -1) {
        // filter track
        state.popularTracks = state.popularTracks.filter((t) => t.id !== id)
        state.popularTracksCount -= 1
      }

      // check if track exists in exclusive tracks
      const exIndex = state.exclusiveTracks.findIndex((t) => t.id === id)
      if (exIndex !== -1) {
        // filter track
        state.exclusiveTracks = state.exclusiveTracks.filter((t) => t.id !== id)
        state.exclusiveTracksCount -= 1
      }

      // check if track exists in latest tracks
      const laIndex = state.latestTracks.findIndex((t) => t.id === id)
      if (laIndex !== -1) {
        // filter track
        state.latestTracks = state.latestTracks.filter((t) => t.id !== id)
        state.latestTracksCount -= 1
      }

      // check if track exists in trending tracks
      const trIndex = state.trendingTracks.findIndex((t) => t.id === id)
      if (trIndex !== -1) {
        // filter track
        state.trendingTracks = state.trendingTracks.filter((t) => t.id !== id)
        state.trendingTracksCount -= 1
      }

      // check if track exists in recommended tracks
      const recIndex = state.recommendedTracks.findIndex((t) => t.id === id)
      if (recIndex !== -1) {
        // filter track
        state.recommendedTracks = state.recommendedTracks.filter((t) => t.id !== id)
        state.recommendedTracksCount -= 1
      }

      // check if track exists in liked tracks
      const lkIndex = state.likedTracks.findIndex((t) => t.id === id)
      if (lkIndex !== -1) {
        // filter track
        state.likedTracks = state.likedTracks.filter((t) => t.id !== id)
        state.likedTracksCount -= 1
      }

      // check if track exists in unlocked tracks
      const unlIndex = state.unlockedTracks.findIndex((t) => t.id === id)
      if (unlIndex !== -1) {
        // filter track
        state.unlockedTracks = state.unlockedTracks.filter((t) => t.id !== id)
        state.unlockedTracksCount -= 1
      }

      // check if track exists in uploaded tracks
      const uplIndex = state.uploadedTracks.findIndex((t) => t.id === id)
      if (uplIndex !== -1) {
        // filter track
        state.uploadedTracks = state.uploadedTracks.filter((t) => t.id !== id)
        state.uploadedTracksCount -= 1
      }

      // check if track exists in artist popular tracks
      const aPopIndex = state.artistPopularTracks.findIndex((t) => t.id === id)
      if (aPopIndex !== -1) {
        // filter track
        state.artistPopularTracks = state.artistPopularTracks.filter((t) => t.id !== id)
        state.artistPopularTracksCount -= 1
      }

      // check if track exists in artist exclusive tracks
      const aExIndex = state.artistExclusiveTracks.findIndex((t) => t.id === id)
      if (aExIndex !== -1) {
        // filter track
        state.artistExclusiveTracks = state.artistExclusiveTracks.filter(
          (t) => t.id !== id,
        )
        state.artistExclusiveTracksCount -= 1
      }

      // check if track exists in artist latest tracks
      const aLatIndex = state.artistLatestTracks.findIndex((t) => t.id === id)
      if (aLatIndex !== -1) {
        // filter track
        state.artistLatestTracks = state.artistLatestTracks.filter((t) => t.id !== id)
        state.artistLatestTracksCount -= 1
      }

      // check if track exists in post tracks
      const ptIndex = state.postTracks.findIndex((t) => t.id === id)
      if (ptIndex !== -1) {
        // filter track
        state.postTracks = state.postTracks.filter((t) => t.id !== id)
      }
    },

    // filter user tracks
    trackFilterUserTracks: (state, action: PayloadAction<{ userId: string }>) => {
      const { userId } = action.payload

      // filter user tracks from public tracks
      const filteredPublicTracks = state.publicTracks.filter((t) => t.userId !== userId)
      const filteredPublicTracksCount =
        state.publicTracks.length - filteredPublicTracks.length

      // if filtered tracks
      if (filteredPublicTracksCount > 0) {
        // update tracks
        state.publicTracks = filteredPublicTracks
        state.publicTracksCount -= filteredPublicTracksCount
      }

      // filter user tracks from popular tracks
      const filteredPopularTracks = state.popularTracks.filter((t) => t.userId !== userId)
      const filteredPopularTracksCount =
        state.popularTracks.length - filteredPopularTracks.length

      // if filtered tracks
      if (filteredPopularTracksCount > 0) {
        // update tracks
        state.popularTracks = filteredPopularTracks
        state.popularTracksCount -= filteredPopularTracksCount
      }

      // filter user tracks from exclusive tracks
      const filteredExclusiveTracks = state.exclusiveTracks.filter(
        (t) => t.userId !== userId,
      )
      const filteredExclusiveTracksCount =
        state.exclusiveTracks.length - filteredExclusiveTracks.length

      // if filtered tracks
      if (filteredExclusiveTracksCount > 0) {
        // update tracks
        state.exclusiveTracks = filteredExclusiveTracks
        state.exclusiveTracksCount -= filteredExclusiveTracksCount
      }

      // filter user tracks from latest tracks
      const filteredLatestTracks = state.latestTracks.filter((t) => t.userId !== userId)
      const filteredLatestTracksCount =
        state.latestTracks.length - filteredLatestTracks.length

      // if filtered tracks
      if (filteredLatestTracksCount > 0) {
        // update tracks
        state.latestTracks = filteredLatestTracks
        state.latestTracksCount -= filteredLatestTracksCount
      }

      // filter user tracks from trending tracks
      const filteredTrendingTracks = state.trendingTracks.filter(
        (t) => t.userId !== userId,
      )
      const filteredTrendingTracksCount =
        state.trendingTracks.length - filteredTrendingTracks.length

      // if filtered tracks
      if (filteredTrendingTracksCount > 0) {
        // update tracks
        state.trendingTracks = filteredTrendingTracks
        state.trendingTracksCount -= filteredTrendingTracksCount
      }

      // filter user tracks from recommended tracks
      const filteredRecommendedTracks = state.recommendedTracks.filter(
        (t) => t.userId !== userId,
      )
      const filteredRecommendedTracksCount =
        state.recommendedTracks.length - filteredRecommendedTracks.length

      // if filtered tracks
      if (filteredRecommendedTracksCount > 0) {
        // update tracks
        state.recommendedTracks = filteredRecommendedTracks
        state.recommendedTracksCount -= filteredRecommendedTracksCount
      }

      // filter user tracks from liked tracks
      const filteredLikedTracks = state.likedTracks.filter((t) => t.userId !== userId)
      const filteredLikedTracksCount =
        state.likedTracks.length - filteredLikedTracks.length

      // if filtered tracks
      if (filteredLikedTracksCount > 0) {
        // update tracks
        state.likedTracks = filteredLikedTracks
        state.likedTracksCount -= filteredLikedTracksCount
      }

      // filter user tracks from unlocked tracks
      const filteredUnlockedTracks = state.unlockedTracks.filter(
        (t) => t.userId !== userId,
      )
      const filteredUnlockedTracksCount =
        state.unlockedTracks.length - filteredUnlockedTracks.length

      // if filtered tracks
      if (filteredUnlockedTracksCount > 0) {
        // update tracks
        state.unlockedTracks = filteredUnlockedTracks
        state.unlockedTracksCount -= filteredUnlockedTracksCount
      }

      // filter user tracks from uploaded tracks
      const filteredUploadedTracks = state.uploadedTracks.filter(
        (t) => t.userId !== userId,
      )
      const filteredUploadedTracksCount =
        state.uploadedTracks.length - filteredUploadedTracks.length

      // if filtered tracks
      if (filteredUploadedTracksCount > 0) {
        // update tracks
        state.uploadedTracks = filteredUploadedTracks
        state.uploadedTracksCount -= filteredUploadedTracksCount
      }

      // filter user tracks from artist popular tracks
      const filteredArtistPopularTracks = state.artistPopularTracks.filter(
        (t) => t.userId !== userId,
      )
      const filteredArtistPopularTracksCount =
        state.artistPopularTracks.length - filteredArtistPopularTracks.length

      // if filtered tracks
      if (filteredArtistPopularTracksCount > 0) {
        // update tracks
        state.artistPopularTracks = filteredArtistPopularTracks
        state.artistPopularTracksCount -= filteredArtistPopularTracksCount
      }

      // filter user tracks from artist exclusive tracks
      const filteredArtistExclusiveTracks = state.artistExclusiveTracks.filter(
        (t) => t.userId !== userId,
      )
      const filteredArtistExclusiveTracksCount =
        state.artistExclusiveTracks.length - filteredArtistExclusiveTracks.length

      // if filtered tracks
      if (filteredArtistExclusiveTracksCount > 0) {
        // update tracks
        state.artistExclusiveTracks = filteredArtistExclusiveTracks
        state.artistExclusiveTracksCount -= filteredArtistExclusiveTracksCount
      }

      // filter user tracks from artist latest tracks
      const filteredArtistLatestTracks = state.artistLatestTracks.filter(
        (t) => t.userId !== userId,
      )
      const filteredArtistLatestTracksCount =
        state.artistLatestTracks.length - filteredArtistLatestTracks.length

      // if filtered tracks
      if (filteredArtistLatestTracksCount > 0) {
        // update tracks
        state.artistLatestTracks = filteredArtistLatestTracks
        state.artistLatestTracksCount -= filteredArtistLatestTracksCount
      }

      // filter user tracks from post tracks
      const filteredPostTracks = state.postTracks.filter((t) => t.userId !== userId)
      const filteredPostTracksCount = state.postTracks.length - filteredPostTracks.length

      // if filtered tracks
      if (filteredPostTracksCount > 0) {
        // update tracks
        state.postTracks = filteredPostTracks
      }
    },

    // update track
    trackUpdateTrack: (
      state,
      action: PayloadAction<{ updatedTrack: EntityTrackView }>,
    ) => {
      const { updatedTrack } = action.payload

      // update track in public tracks
      const pubIndex = state.publicTracks.findIndex((t) => t.id === updatedTrack.id)
      if (pubIndex !== -1) {
        const currentTrack = state.publicTracks[pubIndex]
        state.publicTracks[pubIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in popular tracks
      const popIndex = state.popularTracks.findIndex((t) => t.id === updatedTrack.id)
      if (popIndex !== -1) {
        const currentTrack = state.popularTracks[popIndex]
        state.popularTracks[popIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in exclusive tracks
      const exIndex = state.exclusiveTracks.findIndex((t) => t.id === updatedTrack.id)
      if (exIndex !== -1) {
        const currentTrack = state.exclusiveTracks[exIndex]
        state.exclusiveTracks[exIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in latest tracks
      const laIndex = state.latestTracks.findIndex((t) => t.id === updatedTrack.id)
      if (laIndex !== -1) {
        const currentTrack = state.latestTracks[laIndex]
        state.latestTracks[laIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in trending tracks
      const trIndex = state.trendingTracks.findIndex((t) => t.id === updatedTrack.id)
      if (trIndex !== -1) {
        const currentTrack = state.trendingTracks[trIndex]
        state.trendingTracks[trIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in recommended tracks
      const recIndex = state.recommendedTracks.findIndex((t) => t.id === updatedTrack.id)
      if (recIndex !== -1) {
        const currentTrack = state.recommendedTracks[recIndex]
        state.recommendedTracks[recIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in liked tracks
      const lkIndex = state.likedTracks.findIndex((t) => t.id === updatedTrack.id)
      if (lkIndex !== -1) {
        const currentTrack = state.likedTracks[lkIndex]
        state.likedTracks[lkIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in unlocked tracks
      const unlIndex = state.unlockedTracks.findIndex((t) => t.id === updatedTrack.id)
      if (unlIndex !== -1) {
        const currentTrack = state.unlockedTracks[unlIndex]
        state.unlockedTracks[unlIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in uploaded tracks
      const uplIndex = state.uploadedTracks.findIndex((t) => t.id === updatedTrack.id)
      if (uplIndex !== -1) {
        const currentTrack = state.uploadedTracks[uplIndex]
        state.uploadedTracks[uplIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in artist popular tracks
      const aPopIndex = state.artistPopularTracks.findIndex(
        (t) => t.id === updatedTrack.id,
      )
      if (aPopIndex !== -1) {
        const currentTrack = state.artistPopularTracks[aPopIndex]
        state.artistPopularTracks[aPopIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in artist exclusive tracks
      const aExIndex = state.artistExclusiveTracks.findIndex(
        (t) => t.id === updatedTrack.id,
      )
      if (aExIndex !== -1) {
        const currentTrack = state.artistExclusiveTracks[aExIndex]
        state.artistExclusiveTracks[aExIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in artist latest tracks
      const aLatIndex = state.artistLatestTracks.findIndex(
        (t) => t.id === updatedTrack.id,
      )
      if (aLatIndex !== -1) {
        const currentTrack = state.artistLatestTracks[aLatIndex]
        state.artistLatestTracks[aLatIndex] = { ...currentTrack, ...updatedTrack }
      }

      // update track in post tracks
      const ptIndex = state.postTracks.findIndex((t) => t.id === updatedTrack.id)
      if (ptIndex !== -1) {
        const currentTrack = state.postTracks[ptIndex]
        state.postTracks[ptIndex] = { ...currentTrack, ...updatedTrack }
      }
    },

    // open track block modal
    trackOpenTrackBlockModal: (
      state,
      action: PayloadAction<{ track: EntityTrackView }>,
    ) => ({
      ...state,
      trackBlockModal: {
        track: action.payload.track,
        open: true,
      },
    }),

    // close track block modal
    trackCloseTrackBlockModal: (state) => ({
      ...state,
      trackBlockModal: {
        track: undefined,
        open: false,
      },
      error: '',
    }),

    // open track delete modal
    trackOpenTrackDeleteModal: (
      state,
      action: PayloadAction<{ track: EntityTrackView }>,
    ) => ({
      ...state,
      trackDeleteModal: {
        track: action.payload.track,
        open: true,
      },
    }),

    // close track delete modal
    trackCloseTrackDeleteModal: (state) => ({
      ...state,
      trackDeleteModal: {
        track: undefined,
        open: false,
      },
      error: '',
    }),
  },
  extraReducers: (builder) => {
    // get public tracks
    builder
      .addCase(trackGetPublicTracks.pending, (state) => ({
        ...state,
        trackGetPublicTracksLoading: true,
      }))
      .addCase(trackGetPublicTracks.fulfilled, (state) => ({
        ...state,
        publicTracksFetched: true,
        trackGetPublicTracksLoading: false,
      }))

    // get popular tracks
    builder
      .addCase(trackGetPopularTracks.pending, (state) => ({
        ...state,
        trackGetPopularTracksLoading: true,
      }))
      .addCase(trackGetPopularTracks.fulfilled, (state) => ({
        ...state,
        popularTracksFetched: true,
        trackGetPopularTracksLoading: false,
      }))

    // get exclusive tracks
    builder
      .addCase(trackGetExclusiveTracks.pending, (state) => ({
        ...state,
        trackGetExclusiveTracksLoading: true,
      }))
      .addCase(trackGetExclusiveTracks.fulfilled, (state) => ({
        ...state,
        exclusiveTracksFetched: true,
        trackGetExclusiveTracksLoading: false,
      }))

    // get latest tracks
    builder
      .addCase(trackGetLatestTracks.pending, (state) => ({
        ...state,
        trackGetLatestTracksLoading: true,
      }))
      .addCase(trackGetLatestTracks.fulfilled, (state) => ({
        ...state,
        latestTracksFetched: true,
        trackGetLatestTracksLoading: false,
      }))

    // get trending tracks
    builder
      .addCase(trackGetTrendingTracks.pending, (state) => ({
        ...state,
        trackGetTrendingTracksLoading: true,
      }))
      .addCase(trackGetTrendingTracks.fulfilled, (state) => ({
        ...state,
        trendingTracksFetched: true,
        trackGetTrendingTracksLoading: false,
      }))

    // get recommended tracks
    builder
      .addCase(trackGetRecommendedTracks.pending, (state) => ({
        ...state,
        trackGetRecommendedTracksLoading: true,
      }))
      .addCase(trackGetRecommendedTracks.fulfilled, (state) => ({
        ...state,
        recommendedTracksFetched: true,
        trackGetRecommendedTracksLoading: false,
      }))

    // get liked tracks
    builder
      .addCase(trackGetLikedTracks.pending, (state) => ({
        ...state,
        trackGetLikedTracksLoading: true,
      }))
      .addCase(trackGetLikedTracks.fulfilled, (state) => ({
        ...state,
        likedTracksFetched: true,
        trackGetLikedTracksLoading: false,
      }))

    // get unlocked tracks
    builder
      .addCase(trackGetUnlockedTracks.pending, (state) => ({
        ...state,
        trackGetUnlockedTracksLoading: true,
      }))
      .addCase(trackGetUnlockedTracks.fulfilled, (state) => ({
        ...state,
        unlockedTracksFetched: true,
        trackGetUnlockedTracksLoading: false,
      }))

    // get uploaded tracks
    builder
      .addCase(trackGetUploadedTracks.pending, (state) => ({
        ...state,
        trackGetUploadedTracksLoading: true,
      }))
      .addCase(trackGetUploadedTracks.fulfilled, (state) => ({
        ...state,
        uploadedTracksFetched: true,
        trackGetUploadedTracksLoading: false,
      }))

    // get artist popular tracks
    builder
      .addCase(trackGetArtistPopularTracks.pending, (state) => ({
        ...state,
        trackGetArtistPopularTracksLoading: true,
      }))
      .addCase(trackGetArtistPopularTracks.fulfilled, (state) => ({
        ...state,
        artistPopularTracksFetched: true,
        trackGetArtistPopularTracksLoading: false,
      }))

    // get artist exclusive tracks
    builder
      .addCase(trackGetArtistExclusiveTracks.pending, (state) => ({
        ...state,
        trackGetArtistExclusiveTracksLoading: true,
      }))
      .addCase(trackGetArtistExclusiveTracks.fulfilled, (state) => ({
        ...state,
        artistExclusiveTracksFetched: true,
        trackGetArtistExclusiveTracksLoading: false,
      }))

    // get artist latest tracks
    builder
      .addCase(trackGetArtistLatestTracks.pending, (state) => ({
        ...state,
        trackGetArtistLatestTracksLoading: true,
      }))
      .addCase(trackGetArtistLatestTracks.fulfilled, (state) => ({
        ...state,
        artistLatestTracksFetched: true,
        trackGetArtistLatestTracksLoading: false,
      }))

    // get post tracks
    builder
      .addCase(trackGetPostTracks.pending, (state) => ({
        ...state,
        trackGetPostTracksLoading: true,
      }))
      .addCase(trackGetPostTracks.fulfilled, (state) => ({
        ...state,
        postTracksFetched: true,
        trackGetPostTracksLoading: false,
      }))

    // create track
    builder
      .addCase(trackCreate.pending, (state) => ({
        ...state,
        trackCreateLoading: true,
      }))
      .addCase(trackCreate.fulfilled, (state) => ({
        ...state,
        trackCreateLoading: false,
      }))

    // update track
    builder
      .addCase(trackUpdate.pending, (state) => ({
        ...state,
        trackUpdateLoading: true,
      }))
      .addCase(trackUpdate.fulfilled, (state) => ({
        ...state,
        trackUpdateLoading: false,
      }))

    // block track
    builder
      .addCase(trackBlock.pending, (state) => ({
        ...state,
        trackBlockLoading: true,
      }))
      .addCase(trackBlock.fulfilled, (state) => ({
        ...state,
        trackBlockLoading: false,
      }))

    // delete track
    builder
      .addCase(trackDelete.pending, (state) => ({
        ...state,
        trackDeleteLoading: true,
      }))
      .addCase(trackDelete.fulfilled, (state) => ({
        ...state,
        trackDeleteLoading: false,
      }))
  },
})

export const {
  trackResetState,
  trackSetPublicTracks,
  trackSetPopularTracks,
  trackSetExclusiveTracks,
  trackSetLatestTracks,
  trackSetTrendingTracks,
  trackSetRecommendedTracks,
  trackSetLikedTracks,
  trackSetUnlockedTracks,
  trackSetUploadedTracks,
  trackSetArtistPopularTracks,
  trackSetArtistExclusiveTracks,
  trackSetArtistLatestTracks,
  trackResetArtistTracks,
  trackSetPostTracks,
  trackResetPostTracks,
  trackAppendLikedTrack,
  trackFilterLikedTrack,
  trackAppendUnlockedTrack,
  trackFilterUnlockedTrack,
  trackFilterTrack,
  trackFilterUserTracks,
  trackUpdateTrack,
  trackOpenTrackBlockModal,
  trackCloseTrackBlockModal,
  trackOpenTrackDeleteModal,
  trackCloseTrackDeleteModal,
} = trackSlice.actions

export const trackSelectPublicTracks = (state: RootState) => state.track.publicTracks
export const trackSelectPublicTracksLength = (state: RootState) =>
  state.track.publicTracks.length
export const trackSelectPublicTracksCount = (state: RootState) =>
  state.track.publicTracksCount
export const trackSelectPublicTracksFetched = (state: RootState) =>
  state.track.publicTracksFetched

export const trackSelectPopularTracks = (state: RootState) => state.track.popularTracks
export const trackSelectPopularTracksLength = (state: RootState) =>
  state.track.popularTracks.length
export const trackSelectPopularTracksCount = (state: RootState) =>
  state.track.popularTracksCount
export const trackSelectPopularTracksFetched = (state: RootState) =>
  state.track.popularTracksFetched

export const trackSelectExclusiveTracks = (state: RootState) =>
  state.track.exclusiveTracks
export const trackSelectExclusiveTracksLength = (state: RootState) =>
  state.track.exclusiveTracks.length
export const trackSelectExclusiveTracksCount = (state: RootState) =>
  state.track.exclusiveTracksCount
export const trackSelectExclusiveTracksFetched = (state: RootState) =>
  state.track.exclusiveTracksFetched

export const trackSelectLatestTracks = (state: RootState) => state.track.latestTracks
export const trackSelectLatestTracksLength = (state: RootState) =>
  state.track.latestTracks.length
export const trackSelectLatestTracksCount = (state: RootState) =>
  state.track.latestTracksCount
export const trackSelectLatestTracksFetched = (state: RootState) =>
  state.track.latestTracksFetched

export const trackSelectTrendingTracks = (state: RootState) => state.track.trendingTracks
export const trackSelectTrendingTracksLength = (state: RootState) =>
  state.track.trendingTracks.length
export const trackSelectTrendingTracksCount = (state: RootState) =>
  state.track.trendingTracksCount
export const trackSelectTrendingTracksFetched = (state: RootState) =>
  state.track.trendingTracksFetched

export const trackSelectRecommendedTracks = (state: RootState) =>
  state.track.recommendedTracks
export const trackSelectRecommendedTracksLength = (state: RootState) =>
  state.track.recommendedTracks.length
export const trackSelectRecommendedTracksCount = (state: RootState) =>
  state.track.recommendedTracksCount
export const trackSelectRecommendedTracksFetched = (state: RootState) =>
  state.track.recommendedTracksFetched

export const trackSelectLikedTracks = (state: RootState) => state.track.likedTracks
export const trackSelectLikedTracksLength = (state: RootState) =>
  state.track.likedTracks.length
export const trackSelectLikedTracksCount = (state: RootState) =>
  state.track.likedTracksCount
export const trackSelectLikedTracksFetched = (state: RootState) =>
  state.track.likedTracksFetched

export const trackSelectUnlockedTracks = (state: RootState) => state.track.unlockedTracks
export const trackSelectUnlockedTracksLength = (state: RootState) =>
  state.track.unlockedTracks.length
export const trackSelectUnlockedTracksCount = (state: RootState) =>
  state.track.unlockedTracksCount
export const trackSelectUnlockedTracksFetched = (state: RootState) =>
  state.track.unlockedTracksFetched

export const trackSelectUploadedTracks = (state: RootState) => state.track.uploadedTracks
export const trackSelectUploadedTracksLength = (state: RootState) =>
  state.track.uploadedTracks.length
export const trackSelectUploadedTracksCount = (state: RootState) =>
  state.track.uploadedTracksCount
export const trackSelectUploadedTracksFetched = (state: RootState) =>
  state.track.uploadedTracksFetched

export const trackSelectArtistPopularTracks = (state: RootState) =>
  state.track.artistPopularTracks
export const trackSelectArtistPopularTracksLength = (state: RootState) =>
  state.track.artistPopularTracks.length
export const trackSelectArtistPopularTracksCount = (state: RootState) =>
  state.track.artistPopularTracksCount
export const trackSelectArtistPopularTracksFetched = (state: RootState) =>
  state.track.artistPopularTracksFetched

export const trackSelectArtistExclusiveTracks = (state: RootState) =>
  state.track.artistExclusiveTracks
export const trackSelectArtistExclusiveTracksLength = (state: RootState) =>
  state.track.artistExclusiveTracks.length
export const trackSelectArtistExclusiveTracksCount = (state: RootState) =>
  state.track.artistExclusiveTracksCount
export const trackSelectArtistExclusiveTracksFetched = (state: RootState) =>
  state.track.artistExclusiveTracksFetched

export const trackSelectArtistLatestTracks = (state: RootState) =>
  state.track.artistLatestTracks
export const trackSelectArtistLatestTracksLength = (state: RootState) =>
  state.track.artistLatestTracks.length
export const trackSelectArtistLatestTracksCount = (state: RootState) =>
  state.track.artistLatestTracksCount
export const trackSelectArtistLatestTracksFetched = (state: RootState) =>
  state.track.artistLatestTracksFetched

export const trackSelectPostTracks = (state: RootState) => state.track.postTracks
export const trackSelectPostTracksFetched = (state: RootState) =>
  state.track.postTracksFetched

export const trackSelectTrackBlockModalOpen = (state: RootState) =>
  state.track.trackBlockModal.open
export const trackSelectTrackBlockModalTrack = (state: RootState) =>
  state.track.trackBlockModal.track

export const trackSelectTrackDeleteModalOpen = (state: RootState) =>
  state.track.trackDeleteModal.open
export const trackSelectTrackDeleteModalTrack = (state: RootState) =>
  state.track.trackDeleteModal.track

export const trackSelectTrackGetPublicTracksLoading = (state: RootState) =>
  state.track.trackGetPublicTracksLoading
export const trackSelectTrackGetPopularTracksLoading = (state: RootState) =>
  state.track.trackGetPopularTracksLoading
export const trackSelectTrackGetExclusiveTracksLoading = (state: RootState) =>
  state.track.trackGetExclusiveTracksLoading
export const trackSelectTrackGetLatestTracksLoading = (state: RootState) =>
  state.track.trackGetLatestTracksLoading
export const trackSelectTrackGetTrendingTracksLoading = (state: RootState) =>
  state.track.trackGetTrendingTracksLoading
export const trackSelectTrackGetRecommendedTracksLoading = (state: RootState) =>
  state.track.trackGetRecommendedTracksLoading
export const trackSelectTrackGetLikedTracksLoading = (state: RootState) =>
  state.track.trackGetLikedTracksLoading
export const trackSelectTrackGetUnlockedTracksLoading = (state: RootState) =>
  state.track.trackGetUnlockedTracksLoading
export const trackSelectTrackGetUploadedTracksLoading = (state: RootState) =>
  state.track.trackGetUploadedTracksLoading
export const trackSelectTrackGetArtistPopularTracksLoading = (state: RootState) =>
  state.track.trackGetArtistPopularTracksLoading
export const trackSelectTrackGetArtistExclusiveTracksLoading = (state: RootState) =>
  state.track.trackGetArtistExclusiveTracksLoading
export const trackSelectTrackGetArtistLatestTracksLoading = (state: RootState) =>
  state.track.trackGetArtistLatestTracksLoading
export const trackSelectTrackGetPostTracksLoading = (state: RootState) =>
  state.track.trackGetPostTracksLoading
export const trackSelectTrackCreateLoading = (state: RootState) =>
  state.track.trackCreateLoading
export const trackSelectTrackUpdateLoading = (state: RootState) =>
  state.track.trackUpdateLoading
export const trackSelectTrackBlockLoading = (state: RootState) =>
  state.track.trackBlockLoading
export const trackSelectTrackDeleteLoading = (state: RootState) =>
  state.track.trackDeleteLoading

export const trackReducer = trackSlice.reducer

// export default trackSlice.reducer

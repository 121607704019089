// SWRKeys - represents unique keys for swr hooks.
export enum SWRKeys {
  UseComment = 'USE_COMMENT',

  UseConnectPayout = 'USE_CONNECT_PAYOUT',
  UseConnectReady = 'USE_CONNECT_READY',

  UsePost = 'USE_POST',

  UseSubscription = 'USE_SUBSCRIPTION',
  UseSubscriptionPrice = 'USE_SUBSCRIPTION_PRICE',

  UseTip = 'USE_TIP',

  UseTrack = 'USE_TRACK',
  UseTracks = 'USE_TRACKS',
  UseTracksPublic = 'USE_TRACKS_PUBLIC',

  UseUnlock = 'USE_UNLOCK',

  UseUser = 'USE_USER',
  UseUsers = 'USE_USERS',
  UseUserPublic = 'USE_USER_PUBLIC',
  UseUserStats = 'USE_USER_STATS',
}

// SWRKeys
export type SWRFetcherData<T> = {
  key: SWRKeys
  data: T
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  EntityNotificationEntityType,
  EntityNotificationConfigScope,
} from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// libs
import { apiGetService, apiHandleError } from '@/lib'
// features
import {
  // notification
  notificationSetNotifications,
  notificationSetNotificationsUnreadCount,
  notificationSetNotification,
  notificationSetNotificationConfig,
} from '@/features'

export const notificationGetNotifications = createAsyncThunk<
  { error: string },
  { limit?: number; offset?: number },
  { state: RootState; rejectValue: undefined }
>('notification/notificationGetNotifications', async (inp, { dispatch, getState }) => {
  // get api service
  const api = apiGetService()

  // get notifications filter
  const filter = getState().notification.notificationsFilter

  // define entity type
  let entityType:
    | 'user'
    | 'track'
    | 'post'
    | 'comment'
    | 'tip'
    | 'unlock'
    | 'subscription'
    | undefined

  // assert entity type
  switch (filter) {
    case EntityNotificationEntityType.User:
      entityType = 'user'
      break
    case EntityNotificationEntityType.Track:
      entityType = 'track'
      break
    case EntityNotificationEntityType.Post:
      entityType = 'post'
      break
    case EntityNotificationEntityType.Comment:
      entityType = 'comment'
      break
    case EntityNotificationEntityType.Tip:
      entityType = 'tip'
      break
    case EntityNotificationEntityType.Unlock:
      entityType = 'unlock'
      break
    case EntityNotificationEntityType.Subscription:
      entityType = 'subscription'
      break
    default:
      entityType = undefined
  }

  try {
    // retrieve notifications
    const { notifications, count } = await api.notificationApi.getAppNotifications(
      entityType,
      inp.limit,
      inp.offset,
    )

    // set notifications
    dispatch(
      notificationSetNotifications({
        notifications: notifications || [],
        count: count || 0,
        offset: inp.offset || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const notificationGetNotificationsUnreadCount = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('notification/notificationGetNotificationsUnreadCount', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve notifications unread count
    const { count } = await api.notificationApi.getAppNotificationsUnreadCount()

    // set notifications unread count
    dispatch(
      notificationSetNotificationsUnreadCount({
        count: count || 0,
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const notificationGetNotification = createAsyncThunk<
  { error: string },
  { notificationId: string },
  { state: RootState; rejectValue: undefined }
>(
  'notification/notificationGetNotification',
  async ({ notificationId }, { dispatch }) => {
    // get api service
    const api = apiGetService()

    try {
      // retrieve notification
      const { notification } = await api.notificationApi.getAppNotification(
        notificationId,
      )

      // set notification
      dispatch(
        notificationSetNotification({
          notification: notification || {},
        }),
      )

      // return empty payload
      return {
        error: '',
      }
    } catch (err: any) {
      const { message } = await apiHandleError(err)

      // return error message in payload
      return {
        error: message,
      }
    }
  },
)

export const notificationGetNotificationConfig = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('notification/notificationGetNotificationConfig', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // get notification config
    const { notificationConfig } = await api.notificationApi.getNotificationConfig()

    // set notification config
    dispatch(
      notificationSetNotificationConfig({
        notificationConfig: notificationConfig || {},
      }),
    )

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const notificationUpdateNotificationConfig = createAsyncThunk<
  { error: string },
  { scope: EntityNotificationConfigScope },
  { state: RootState; rejectValue: undefined }
>(
  'notification/notificationUpdateNotificationConfig',
  async ({ scope }, { dispatch }) => {
    // get api service
    const api = apiGetService()

    try {
      // update notifications configuration
      const { notificationConfig } = await api.notificationApi.updateNotificationConfig({
        scope,
      })

      // set notification config
      dispatch(
        notificationSetNotificationConfig({
          notificationConfig: notificationConfig || {},
        }),
      )

      // return empty payload
      return {
        error: '',
      }
    } catch (err: any) {
      const { message } = await apiHandleError(err)

      // return error message in payload
      return {
        error: message,
      }
    }
  },
)

export const notificationReadNotification = createAsyncThunk<
  { error: string },
  { notificationId: string },
  { state: RootState; rejectValue: undefined }
>('notification/notificationReadNotification', async ({ notificationId }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve notification
    await api.notificationApi.readAppNotification({ notificationId })

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

export const notificationReadNotifications = createAsyncThunk<
  { error: string },
  undefined,
  { state: RootState; rejectValue: undefined }
>('notification/notificationReadNotifications', async (_, { dispatch }) => {
  // get api service
  const api = apiGetService()

  try {
    // retrieve notification
    await api.notificationApi.readAppNotifications()

    // update notifications unread count
    dispatch(notificationGetNotificationsUnreadCount())

    // return empty payload
    return {
      error: '',
    }
  } catch (err: any) {
    const { message } = await apiHandleError(err)

    // return error message in payload
    return {
      error: message,
    }
  }
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityPostView, EntityTrackView } from '@ryddm-inc/ryddm-apiclient'

// stores
import { RootState } from '@/stores'
// features
import {
  unlockGetUnlockedPostIds,
  unlockGetUnlockedTrackIds,
  unlockCreatePostUnlock,
  unlockCreateTrackUnlock,
} from '@/features'

export interface UnlockState {
  postUnlocks: { [key: string]: boolean }
  trackUnlocks: { [key: string]: boolean }

  unlockPostModal: {
    post: EntityPostView | undefined
    open: boolean
  }

  unlockTrackModal: {
    track: EntityTrackView | undefined
    open: boolean
  }

  unlockGetUnlockedPostIdsLoading: boolean
  unlockGetUnlockedTrackIdsLoading: boolean
  unlockCreatePostUnlockLoading: boolean
  unlockCreateTrackUnlockLoading: boolean
}

const initialState: UnlockState = {
  postUnlocks: {},
  trackUnlocks: {},

  unlockPostModal: {
    post: undefined,
    open: false,
  },

  unlockTrackModal: {
    track: undefined,
    open: false,
  },

  unlockGetUnlockedPostIdsLoading: false,
  unlockGetUnlockedTrackIdsLoading: false,
  unlockCreatePostUnlockLoading: false,
  unlockCreateTrackUnlockLoading: false,
}

export const unlockSlice = createSlice({
  name: 'unlock',
  initialState,
  reducers: {
    // reset state
    unlockResetState: () => ({
      postUnlocks: {},
      trackUnlocks: {},

      unlockPostModal: {
        post: undefined,
        open: false,
      },

      unlockTrackModal: {
        track: undefined,
        open: false,
      },

      unlockGetUnlockedPostIdsLoading: false,
      unlockGetUnlockedTrackIdsLoading: false,
      unlockCreatePostUnlockLoading: false,
      unlockCreateTrackUnlockLoading: false,
    }),

    // set post unlocks
    unlockSetPostUnlocks: (
      state,
      action: PayloadAction<{ unlockedPostIds: string[] }>,
    ) => {
      const { unlockedPostIds } = action.payload

      // define post unlocks map
      const postUnlocks: { [key: string]: boolean } = {}

      // iterate over each unlocked post id
      unlockedPostIds.forEach((id) => {
        // add id to the map
        postUnlocks[id] = true
      })

      // update state
      state.postUnlocks = postUnlocks
    },

    // add post unlock
    unlockAddPostUnlock: (state, action: PayloadAction<{ postId: string }>) => {
      const { postId } = action.payload

      // update state
      state.postUnlocks[postId] = true
    },

    // remove post unlock
    unlockRemovePostUnlock: (state, action: PayloadAction<{ postId: string }>) => {
      const { postId } = action.payload

      // update state
      state.postUnlocks[postId] = false
    },

    // set track unlocks
    unlockSetTrackUnlocks: (
      state,
      action: PayloadAction<{ unlockedTrackIds: string[] }>,
    ) => {
      const { unlockedTrackIds } = action.payload

      // define track unlocks map
      const trackUnlocks: { [key: string]: boolean } = {}

      // iterate over each unlocked track id
      unlockedTrackIds.forEach((id) => {
        // add id to the map
        trackUnlocks[id] = true
      })

      // update state
      state.trackUnlocks = trackUnlocks
    },

    // add track unlock
    unlockAddTrackUnlock: (state, action: PayloadAction<{ trackId: string }>) => {
      const { trackId } = action.payload

      // update state
      state.trackUnlocks[trackId] = true
    },

    // remove track unlock
    unlockRemoveTrackUnlock: (state, action: PayloadAction<{ trackId: string }>) => {
      const { trackId } = action.payload

      // update state
      state.trackUnlocks[trackId] = false
    },

    // open unlock post modal
    unlockOpenUnlockPostModal: (
      state,
      action: PayloadAction<{ post: EntityPostView }>,
    ) => ({
      ...state,
      unlockPostModal: {
        post: action.payload.post,
        open: true,
      },
    }),

    // close unlock post modal
    unlockCloseUnlockPostModal: (state) => ({
      ...state,
      unlockPostModal: {
        post: undefined,
        open: false,
      },
      error: '',
    }),

    // open unlock track modal
    unlockOpenUnlockTrackModal: (
      state,
      action: PayloadAction<{ track: EntityTrackView }>,
    ) => ({
      ...state,
      unlockTrackModal: {
        track: action.payload.track,
        open: true,
      },
    }),

    // close unlock track modal
    unlockCloseUnlockTrackModal: (state) => ({
      ...state,
      unlockTrackModal: {
        track: undefined,
        open: false,
      },
      error: '',
    }),
  },
  extraReducers: (builder) => {
    // get unlocked post ids
    builder
      .addCase(unlockGetUnlockedPostIds.pending, (state) => ({
        ...state,
        unlockGetUnlockedPostIdsLoading: true,
      }))
      .addCase(unlockGetUnlockedPostIds.fulfilled, (state) => ({
        ...state,
        unlockGetUnlockedPostIdsLoading: false,
      }))

    // get unlocked track ids
    builder
      .addCase(unlockGetUnlockedTrackIds.pending, (state) => ({
        ...state,
        unlockGetUnlockedTrackIdsLoading: true,
      }))
      .addCase(unlockGetUnlockedTrackIds.fulfilled, (state) => ({
        ...state,
        unlockGetUnlockedTrackIdsLoading: false,
      }))

    // create post unlock
    builder
      .addCase(unlockCreatePostUnlock.pending, (state) => ({
        ...state,
        unlockCreatePostUnlockLoading: true,
      }))
      .addCase(unlockCreatePostUnlock.fulfilled, (state) => ({
        ...state,
        unlockCreatePostUnlockLoading: false,
      }))

    // create track unlock
    builder
      .addCase(unlockCreateTrackUnlock.pending, (state) => ({
        ...state,
        unlockCreateTrackUnlockLoading: true,
      }))
      .addCase(unlockCreateTrackUnlock.fulfilled, (state) => ({
        ...state,
        unlockCreateTrackUnlockLoading: false,
      }))
  },
})

export const {
  unlockResetState,

  unlockSetPostUnlocks,
  unlockAddPostUnlock,
  unlockRemovePostUnlock,

  unlockSetTrackUnlocks,
  unlockAddTrackUnlock,
  unlockRemoveTrackUnlock,

  unlockOpenUnlockPostModal,
  unlockCloseUnlockPostModal,

  unlockOpenUnlockTrackModal,
  unlockCloseUnlockTrackModal,
} = unlockSlice.actions

export const unlockSelectPostUnlocks = (state: RootState) => state.unlock.postUnlocks
export const unlockSelectTrackUnlocks = (state: RootState) => state.unlock.trackUnlocks

export const unlockSelectUnlockPostModalOpen = (state: RootState) =>
  state.unlock.unlockPostModal.open
export const unlockSelectUnlockPostModalPost = (state: RootState) =>
  state.unlock.unlockPostModal.post

export const unlockSelectUnlockTrackModalOpen = (state: RootState) =>
  state.unlock.unlockTrackModal.open
export const unlockSelectUnlockTrackModalTrack = (state: RootState) =>
  state.unlock.unlockTrackModal.track

export const unlockSelectUnlockGetUnlockedPostIdsLoading = (state: RootState) =>
  state.unlock.unlockGetUnlockedPostIdsLoading
export const unlockSelectUnlockGetUnlockedTrackIdsLoading = (state: RootState) =>
  state.unlock.unlockGetUnlockedTrackIdsLoading
export const unlockSelectUnlockCreatePostUnlockLoading = (state: RootState) =>
  state.unlock.unlockCreatePostUnlockLoading
export const unlockSelectUnlockCreateTrackUnlockLoading = (state: RootState) =>
  state.unlock.unlockCreateTrackUnlockLoading

export const unlockReducer = unlockSlice.reducer

// export default unlockSlice.reducer

import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  form: {
    main: css`
      display: flex;
      justify-content: center;
    `,
    content: {
      main: css`
        display: flex;
        flex-direction: column;

        width: 510px;

        ${mq.tablet(css`
          width: 380px;
        `)}

        ${mq.mobile(css`
          width: 100%;
        `)}
      `,

      submit: css`
        align-self: center;

        ${mq.mobile(css`
          width: 100%;
        `)}

        & > button {
          width: 380px;

          ${mq.mobile(css`
            width: 100%;
          `)}
        }
      `,
    },
  },
})

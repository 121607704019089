import { FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// config
import { Path } from '@/config'
// icons
import { icons } from '@/assets'
// hooks
import { useAppSelector, useAppDispatch, useInterval } from '@/hooks'
// utils
import { numbersFormatter } from '@/utils'
// features
import {
  notificationSelectNotificationsUnreadCount,
  notificationGetNotificationsUnreadCount,
} from '@/features'

// styles
import { styles } from './styles'

interface NotificationsProps {}

export const Notifications: FC<NotificationsProps> = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const dispatch = useAppDispatch()
  const notificationsUnreadCount = useAppSelector(
    notificationSelectNotificationsUnreadCount,
  )

  useInterval(() => {
    // refetch unread notifications count every 10 seconds
    dispatch(notificationGetNotificationsUnreadCount())
  }, 10000)

  return (
    <div css={styles(theme).notifications.main}>
      <button
        css={styles(theme).notifications.button}
        onClick={() => navigate(Path.Notifications)}
        type="button"
        aria-label="navigate to notifications page"
      >
        <icons.Bell
          css={[
            styles(theme).notifications.icon.shared,
            pathname.includes(Path.Notifications)
              ? styles(theme).notifications.icon.active
              : styles(theme).notifications.icon.inactive,
          ]}
        />
      </button>

      {notificationsUnreadCount > 0 && (
        <div
          css={[
            styles(theme).notifications.count.shared,
            notificationsUnreadCount < 100
              ? styles(theme).notifications.count.circle
              : styles(theme).notifications.count.badge,
          ]}
        >
          {numbersFormatter(notificationsUnreadCount)}
        </div>
      )}
    </div>
  )
}

// icons
import { icons } from './assets'

export type RoleFeatures = {
  id: string
  title: string
  description: string
  icon: any
}

export const fanRoleFeatures: RoleFeatures[] = [
  {
    id: '8fbadf0c-f417-4331-8180-326ccc489975',
    title: 'DISPERSE IN EXCLUSIVE CONTENT',
    description:
      'Immerse yourself in a rich variety of exclusive audio tracks and media posts, carefully crafted by top creators from around the world.',
    icon: icons.FireIcon,
  },
  {
    id: '9e1c9469-7619-4e03-bb07-728ae9dcc3b4',
    title: 'SUPPORT YOUR CREATORS',
    description:
      'Show your appreciation by becoming a valued patron, unlocking exclusive content, enjoying subscription perks, and offering tips to support the artists you admire.',
    icon: icons.GrowIcon,
  },
  {
    id: '737469ca-8263-49a5-9752-3d3d9a99ce11',
    title: 'NEVER MISS A BEAT',
    description:
      'Stay updated and never miss out!! Stay in the loop with instant notifications whenever your favourite artists drop new tracks or media posts.',
    icon: icons.NotificationIcon,
  },
]

export const artistRoleFeatures: RoleFeatures[] = [
  {
    id: 'c9fee612-e208-4360-8a9e-bf34a97c7b9c',
    title: 'SHARE CONTENT AND EARN',
    description:
      'Earn more money than ever before by sharing exclusive music, video clips, photo, or text. Just share content with your fans and get paid directly.',
    icon: icons.FireIcon,
  },
  {
    id: '9f765252-cf75-4667-8089-c567a956e0de',
    title: 'GROW FROM THE VERY BEGINNING',
    description:
      'Our algorithms are concentrated for artist growth and help promote new music to listeners with similar taste!',
    icon: icons.GrowIcon,
  },
  {
    id: '9e45d811-42eb-4b14-bbc1-4e67987531ad',
    title: 'RETAIN ENGAGEMENT',
    description:
      'Fans will be notified with every release and post that you publish! Show demos and snippets to subscribers far ahead of the release to be closer to them.',
    icon: icons.NotificationIcon,
  },
]

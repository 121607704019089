import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  EntityCommentView,
  EntityUserView,
  EntityPostView,
  EntityTrackView,
  EntityReportView,
  EntityReportComplaintView,
  EntityReportEntityType,
} from '@ryddm-inc/ryddm-apiclient'

// utils
import { mergeArraysAtIndex } from '@/utils'
// stores
import { RootState } from '@/stores'
// features
import {
  reportCreateCommentReportComplaint,
  reportCreatePostReportComplaint,
  reportCreateTrackReportComplaint,
  reportCreateUserReportComplaint,
  reportResolveReport,
  reportGetReports,
  reportGetReport,
  reportGetReportComplaints,
  reportGetReportComplaint,
} from '@/features'

export interface ReportState {
  reports: EntityReportView[]
  reportsCount: number
  reportsFetched: boolean

  report: EntityReportView | undefined
  reportFetched: boolean
  reportEntityBlocked: boolean
  reportUserBlocked: boolean

  reportComplaints: EntityReportComplaintView[]
  reportComplaintsCount: number
  reportComplaintsFetched: boolean

  reportComplaint: EntityReportComplaintView | undefined
  reportComplaintFetched: boolean

  reportsFilter: EntityReportEntityType | undefined

  reportCommentModal: {
    comment: EntityCommentView | undefined
    open: boolean
  }
  reportPostModal: {
    post: EntityPostView | undefined
    open: boolean
  }
  reportTrackModal: {
    track: EntityTrackView | undefined
    open: boolean
  }
  reportUserModal: {
    user: EntityUserView | undefined
    open: boolean
  }
  reportResolveModal: {
    report: EntityReportView | undefined
    open: boolean
  }

  reportCreateCommentReportComplaintLoading: boolean
  reportCreatePostReportComplaintLoading: boolean
  reportCreateTrackReportComplaintLoading: boolean
  reportCreateUserReportComplaintLoading: boolean
  reportResolveReportLoading: boolean
  reportGetReportsLoading: boolean
  reportGetReportLoading: boolean
  reportGetReportComplaintsLoading: boolean
  reportGetReportComplaintLoading: boolean
}

const initialState: ReportState = {
  reports: [],
  reportsCount: 0,
  reportsFetched: false,

  report: undefined,
  reportFetched: false,
  reportEntityBlocked: false,
  reportUserBlocked: false,

  reportComplaints: [],
  reportComplaintsCount: 0,
  reportComplaintsFetched: false,

  reportComplaint: undefined,
  reportComplaintFetched: false,

  reportsFilter: undefined,

  reportCommentModal: {
    comment: undefined,
    open: false,
  },
  reportPostModal: {
    post: undefined,
    open: false,
  },
  reportTrackModal: {
    track: undefined,
    open: false,
  },
  reportUserModal: {
    user: undefined,
    open: false,
  },
  reportResolveModal: {
    report: undefined,
    open: false,
  },

  reportCreateCommentReportComplaintLoading: false,
  reportCreatePostReportComplaintLoading: false,
  reportCreateTrackReportComplaintLoading: false,
  reportCreateUserReportComplaintLoading: false,
  reportResolveReportLoading: false,
  reportGetReportsLoading: false,
  reportGetReportLoading: false,
  reportGetReportComplaintsLoading: false,
  reportGetReportComplaintLoading: false,
}

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // reset state
    reportResetState: () => ({
      reports: [],
      reportsCount: 0,
      reportsFetched: false,

      report: undefined,
      reportFetched: false,
      reportEntityBlocked: false,
      reportUserBlocked: false,

      reportComplaints: [],
      reportComplaintsCount: 0,
      reportComplaintsFetched: false,

      reportComplaint: undefined,
      reportComplaintFetched: false,

      reportsFilter: undefined,

      reportCommentModal: {
        comment: undefined,
        open: false,
      },
      reportPostModal: {
        post: undefined,
        open: false,
      },
      reportTrackModal: {
        track: undefined,
        open: false,
      },
      reportUserModal: {
        user: undefined,
        open: false,
      },
      reportResolveModal: {
        report: undefined,
        open: false,
      },

      reportCreateCommentReportComplaintLoading: false,
      reportCreatePostReportComplaintLoading: false,
      reportCreateTrackReportComplaintLoading: false,
      reportCreateUserReportComplaintLoading: false,
      reportResolveReportLoading: false,
      reportGetReportsLoading: false,
      reportGetReportLoading: false,
      reportGetReportComplaintsLoading: false,
      reportGetReportComplaintLoading: false,
    }),

    // set reports
    reportSetReports: (
      state,
      action: PayloadAction<{
        reports: EntityReportView[]
        count: number
        offset: number
      }>,
    ) => {
      const { reports, count, offset } = action.payload

      // mutate count
      state.reportsCount = count
      // mutate reports array
      state.reports = mergeArraysAtIndex(state.reports, reports, offset)
    },

    // set report
    reportSetReport: (state, action: PayloadAction<{ report: EntityReportView }>) => {
      const { report } = action.payload

      // update report state
      state.report = report
    },

    // set report entity blocked
    reportSetReportEntityBlocked: (state) => ({
      ...state,
      reportEntityBlocked: true,
    }),

    // set report user blocked
    reportSetReportUserBlocked: (state) => ({
      ...state,
      reportUserBlocked: true,
    }),

    // reset report
    reportResetReport: (state) => ({
      ...state,

      report: undefined,
      reportFetched: false,
      reportEntityBlocked: false,
      reportUserBlocked: false,
    }),

    // filter report
    reportFilterReport: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload

      // check if report exists in reports
      const index = state.reports.findIndex((r) => r.id === id)
      if (index !== -1) {
        // filter report
        state.reports = state.reports.filter((r) => r.id !== id)
        state.reportsCount -= 1
      }
    },

    // set report complaints
    reportSetReportComplaints: (
      state,
      action: PayloadAction<{
        reportComplaints: EntityReportComplaintView[]
        count: number
        offset: number
      }>,
    ) => {
      const { reportComplaints, count, offset } = action.payload

      // mutate count
      state.reportComplaintsCount = count
      // mutate report complaints array
      state.reportComplaints = mergeArraysAtIndex(
        state.reportComplaints,
        reportComplaints,
        offset,
      )
    },

    // reset report complaints
    reportResetReportComplaints: (state) => ({
      ...state,

      reportComplaints: [],
      reportComplaintsCount: 0,
      reportComplaintsFetched: false,
    }),

    // set report complaint
    reportSetReportComplaint: (
      state,
      action: PayloadAction<{ reportComplaint: EntityReportComplaintView }>,
    ) => {
      const { reportComplaint } = action.payload

      // update report complaint state
      state.reportComplaint = reportComplaint
    },

    // reset report complaint
    reportResetReportComplaint: (state) => ({
      ...state,

      reportComplaint: undefined,
      reportComplaintFetched: false,
    }),

    // set reports filter
    reportSetReportsFilter: (
      state,
      action: PayloadAction<{ filter: EntityReportEntityType }>,
    ) => {
      const { filter } = action.payload

      // cleanup reports
      state.reports = []
      state.reportsCount = 0

      // cleanup report complaints
      state.reportComplaints = []
      state.reportComplaintsCount = 0

      // mutate reports filter
      state.reportsFilter = state.reportsFilter === filter ? undefined : filter
    },

    // open report comment modal
    reportOpenReportCommentModal: (
      state,
      action: PayloadAction<{ comment: EntityCommentView }>,
    ) => ({
      ...state,
      reportCommentModal: {
        comment: action.payload.comment,
        open: true,
      },
    }),

    // close report comment modal
    reportCloseReportCommentModal: (state) => ({
      ...state,
      reportCommentModal: {
        comment: undefined,
        open: false,
      },
      error: '',
    }),

    // open report post modal
    reportOpenReportPostModal: (
      state,
      action: PayloadAction<{ post: EntityPostView }>,
    ) => ({
      ...state,
      reportPostModal: {
        post: action.payload.post,
        open: true,
      },
    }),

    // close report post modal
    reportCloseReportPostModal: (state) => ({
      ...state,
      reportPostModal: {
        post: undefined,
        open: false,
      },
      error: '',
    }),

    // open report track modal
    reportOpenReportTrackModal: (
      state,
      action: PayloadAction<{ track: EntityTrackView }>,
    ) => ({
      ...state,
      reportTrackModal: {
        track: action.payload.track,
        open: true,
      },
    }),

    // close report track modal
    reportCloseReportTrackModal: (state) => ({
      ...state,
      reportTrackModal: {
        track: undefined,
        open: false,
      },
      error: '',
    }),

    // open report user modal
    reportOpenReportUserModal: (
      state,
      action: PayloadAction<{ user: EntityUserView }>,
    ) => ({
      ...state,
      reportUserModal: {
        user: action.payload.user,
        open: true,
      },
    }),

    // close report user modal
    reportCloseReportUserModal: (state) => ({
      ...state,
      reportUserModal: {
        user: undefined,
        open: false,
      },
      error: '',
    }),

    // open report resolve modal
    reportOpenReportResolveModal: (
      state,
      action: PayloadAction<{ report: EntityReportView }>,
    ) => ({
      ...state,
      reportResolveModal: {
        report: action.payload.report,
        open: true,
      },
    }),

    // close report resolve modal
    reportCloseReportResolveModal: (state) => ({
      ...state,
      reportResolveModal: {
        report: undefined,
        open: false,
      },
      error: '',
    }),
  },
  extraReducers: (builder) => {
    // create comment report complaint
    builder
      .addCase(reportCreateCommentReportComplaint.pending, (state) => ({
        ...state,
        reportCreateCommentReportComplaintLoading: true,
      }))
      .addCase(reportCreateCommentReportComplaint.fulfilled, (state) => ({
        ...state,
        reportCreateCommentReportComplaintLoading: false,
      }))

    // create post report complaint
    builder
      .addCase(reportCreatePostReportComplaint.pending, (state) => ({
        ...state,
        reportCreatePostReportComplaintLoading: true,
      }))
      .addCase(reportCreatePostReportComplaint.fulfilled, (state) => ({
        ...state,
        reportCreatePostReportComplaintLoading: false,
      }))

    // create track report complaint
    builder
      .addCase(reportCreateTrackReportComplaint.pending, (state) => ({
        ...state,
        reportCreateTrackReportComplaintLoading: true,
      }))
      .addCase(reportCreateTrackReportComplaint.fulfilled, (state) => ({
        ...state,
        reportCreateTrackReportComplaintLoading: false,
      }))

    // create user report complaint
    builder
      .addCase(reportCreateUserReportComplaint.pending, (state) => ({
        ...state,
        reportCreateUserReportComplaintLoading: true,
      }))
      .addCase(reportCreateUserReportComplaint.fulfilled, (state) => ({
        ...state,
        reportCreateUserReportComplaintLoading: false,
      }))

    // resolve report
    builder
      .addCase(reportResolveReport.pending, (state) => ({
        ...state,
        reportResolveReportLoading: true,
      }))
      .addCase(reportResolveReport.fulfilled, (state) => ({
        ...state,
        reportResolveReportLoading: false,
      }))

    // get reports
    builder
      .addCase(reportGetReports.pending, (state) => ({
        ...state,
        reportGetReportsLoading: true,
      }))
      .addCase(reportGetReports.fulfilled, (state) => ({
        ...state,
        reportsFetched: true,
        reportGetReportsLoading: false,
      }))

    // get report
    builder
      .addCase(reportGetReport.pending, (state) => ({
        ...state,
        reportGetReportLoading: true,
      }))
      .addCase(reportGetReport.fulfilled, (state) => ({
        ...state,
        reportFetched: true,
        reportGetReportLoading: false,
      }))

    // get report complaints
    builder
      .addCase(reportGetReportComplaints.pending, (state) => ({
        ...state,
        reportGetReportComplaintsLoading: true,
      }))
      .addCase(reportGetReportComplaints.fulfilled, (state) => ({
        ...state,
        reportComplaintsFetched: true,
        reportGetReportComplaintsLoading: false,
      }))

    // get report complaint
    builder
      .addCase(reportGetReportComplaint.pending, (state) => ({
        ...state,
        reportGetReportComplaintLoading: true,
      }))
      .addCase(reportGetReportComplaint.fulfilled, (state) => ({
        ...state,
        reportComplaintFetched: true,
        reportGetReportComplaintLoading: false,
      }))
  },
})

export const {
  reportResetState,

  reportSetReports,
  reportSetReport,
  reportSetReportEntityBlocked,
  reportSetReportUserBlocked,
  reportResetReport,
  reportFilterReport,

  reportSetReportComplaints,
  reportResetReportComplaints,
  reportSetReportComplaint,
  reportResetReportComplaint,

  reportSetReportsFilter,

  reportOpenReportCommentModal,
  reportCloseReportCommentModal,
  reportOpenReportPostModal,
  reportCloseReportPostModal,
  reportOpenReportTrackModal,
  reportCloseReportTrackModal,
  reportOpenReportUserModal,
  reportCloseReportUserModal,
  reportOpenReportResolveModal,
  reportCloseReportResolveModal,
} = reportSlice.actions

export const reportSelectReportCommentModalOpen = (state: RootState) =>
  state.report.reportCommentModal.open
export const reportSelectReportCommentModalComment = (state: RootState) =>
  state.report.reportCommentModal.comment

export const reportSelectReportPostModalOpen = (state: RootState) =>
  state.report.reportPostModal.open
export const reportSelectReportPostModalPost = (state: RootState) =>
  state.report.reportPostModal.post

export const reportSelectReportTrackModalOpen = (state: RootState) =>
  state.report.reportTrackModal.open
export const reportSelectReportTrackModalTrack = (state: RootState) =>
  state.report.reportTrackModal.track

export const reportSelectReportUserModalOpen = (state: RootState) =>
  state.report.reportUserModal.open
export const reportSelectReportUserModalUser = (state: RootState) =>
  state.report.reportUserModal.user

export const reportSelectReportResolveModalOpen = (state: RootState) =>
  state.report.reportResolveModal.open
export const reportSelectReportResolveModalReport = (state: RootState) =>
  state.report.reportResolveModal.report

export const reportSelectReports = (state: RootState) => state.report.reports
export const reportSelectReportsLength = (state: RootState) => state.report.reports.length
export const reportSelectReportsCount = (state: RootState) => state.report.reportsCount
export const reportSelectReportsFetched = (state: RootState) =>
  state.report.reportsFetched

export const reportSelectReport = (state: RootState) => state.report.report
export const reportSelectReportFetched = (state: RootState) => state.report.reportFetched
export const reportSelectReportEntityBlocked = (state: RootState) =>
  state.report.reportEntityBlocked
export const reportSelectReportUserBlocked = (state: RootState) =>
  state.report.reportUserBlocked

export const reportSelectReportComplaints = (state: RootState) =>
  state.report.reportComplaints
export const reportSelectReportComplaintsLength = (state: RootState) =>
  state.report.reportComplaints.length
export const reportSelectReportComplaintsCount = (state: RootState) =>
  state.report.reportComplaintsCount
export const reportSelectReportComplaintsFetched = (state: RootState) =>
  state.report.reportComplaintsFetched

export const reportSelectReportComplaint = (state: RootState) =>
  state.report.reportComplaint
export const reportSelectReportComplaintFetched = (state: RootState) =>
  state.report.reportComplaintFetched

export const reportSelectReportsFilter = (state: RootState) => state.report.reportsFilter

export const reportSelectReportCreateCommentReportComplaintLoading = (state: RootState) =>
  state.report.reportCreateCommentReportComplaintLoading
export const reportSelectReportCreatePostReportComplaintLoading = (state: RootState) =>
  state.report.reportCreatePostReportComplaintLoading
export const reportSelectReportCreateTrackReportComplaintLoading = (state: RootState) =>
  state.report.reportCreateTrackReportComplaintLoading
export const reportSelectReportCreateUserReportComplaintLoading = (state: RootState) =>
  state.report.reportCreateUserReportComplaintLoading
export const reportSelectReportResolveReportLoading = (state: RootState) =>
  state.report.reportResolveReportLoading
export const reportSelectReportGetReportsLoading = (state: RootState) =>
  state.report.reportGetReportsLoading
export const reportSelectReportGetReportLoading = (state: RootState) =>
  state.report.reportGetReportLoading
export const reportSelectReportGetReportComplaintsLoading = (state: RootState) =>
  state.report.reportGetReportComplaintsLoading
export const reportSelectReportGetReportComplaintLoading = (state: RootState) =>
  state.report.reportGetReportComplaintLoading

export const reportReducer = reportSlice.reducer

// export default reportSlice.reducer

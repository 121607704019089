import { css, Theme } from '@emotion/react'
import { typography, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  method: {
    main: css`
      display: flex;
      align-items: center;

      height: 30px;
    `,

    type: css`
      display: flex;
      align-items: center;

      width: 46px;
      margin-right: 32px;

      ${mq.mobile(css`
        margin-right: 14px;
      `)}

      & > svg {
        height: 30px;
      }
    `,

    data: {
      main: {
        shared: css`
          display: flex;
          align-items: center;

          cursor: pointer;
          transition: color 0.2s;
          margin-right: 32px;

          ${mq.mobile(css`
            margin-right: 14px;
          `)}

          color: ${theme.colors.secondary.normal};

          &:hover {
            color: ${theme.colors.accent.normal};
          }

          & > div:not(:last-of-type) {
            margin-right: 16px;

            ${mq.mobile(css`
              margin-right: 10px;
            `)}
          }
        `,

        selected: css`
          color: ${theme.colors.accent.normal};
        `,
      },
      circles: css`
        font-size: ${typography.sp};
      `,
      digits: css`
        font-weight: bold;
      `,
    },

    default: css`
      display: flex;
      align-items: center;

      width: 20px;
      height: 20px;

      & > svg {
        width: 20px;
        height: 20px;

        & > path {
          fill: ${theme.colors.positive.normal};
        }
      }
    `,
  },
})

import { css, Theme } from '@emotion/react'
import { helpers, typography } from '@/styles'

export const styles = (theme: Theme) => ({
  artist: {
    main: css`
      display: flex;
      flex-direction: column;

      width: 140px;
      color: ${theme.colors.secondary.normal};
    `,

    avatar: {
      main: css`
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;

        width: 100px;
        height: 100px;

        background-color: ${theme.colors.primary.darker};
        border-radius: 50%;
        overflow: hidden;

        margin-bottom: 12px;
      `,
      image: css`
        ${helpers.image};
      `,
      icon: css`
        width: 36px;
        height: 36px;

        & > path {
          fill: ${theme.colors.secondary.normal};
        }
      `,
    },

    info: {
      main: css`
        display: flex;
        flex-direction: column;
        align-self: center;

        text-align: center;

        width: 100px;
        margin-bottom: 12px;
      `,
      name: css`
        ${helpers.resetDefaults};
        ${helpers.textOverflowEllipsis};

        cursor: pointer;

        font-size: ${typography.sp};
        font-weight: bold;
        color: ${theme.colors.secondary.normal};
        text-align: center;

        margin-bottom: 2px;

        transition: color 0.2s;
        &:hover {
          color: ${theme.colors.accent.normal};
        }
      `,
      username: css`
        ${helpers.resetDefaults};
        ${helpers.textOverflowEllipsis};

        cursor: pointer;

        font-size: ${typography.sp};
        color: ${theme.colors.secondary.normal};
        text-align: center;

        transition: color 0.2s;
        &:hover {
          color: ${theme.colors.accent.normal};
        }
      `,
    },

    followers: {
      main: css`
        margin-bottom: 8px;
        text-align: center;
      `,
      count: css`
        font-size: ${typography.sp};
      `,
    },

    buttons: {
      main: css`
        display: flex;
        justify-content: center;
      `,
      button: css`
        font-size: ${typography.sp};
        font-weight: bold;

        padding: 6px 16px;
        max-width: 90%;
      `,
    },
  },
})

import { FC } from 'react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  reportSelectReportResolveModalOpen,
  reportCloseReportResolveModal,
} from '@/features'
// components
import { ModalLayout } from '@/components'

// components
import { ReportResolveCard } from './components'
// styles
import { styles } from './styles'

type ReportResolveModalProps = {}

export const ReportResolveModal: FC<ReportResolveModalProps> = () => {
  const dispatch = useAppDispatch()
  const reportResolveModalOpen = useAppSelector(reportSelectReportResolveModalOpen)

  return (
    <div css={styles().modal.main}>
      {reportResolveModalOpen && (
        <ModalLayout
          background="opaque"
          onOutsideClick={() => dispatch(reportCloseReportResolveModal())}
        >
          <div css={styles().modal.content}>
            <ReportResolveCard />
          </div>
        </ModalLayout>
      )}
    </div>
  )
}

ReportResolveModal.propTypes = {}

import { css, Theme } from '@emotion/react'
import { typography, mq } from '@/styles'

export const styles = (theme: Theme) => ({
  feature: {
    main: css`
      text-align: center;

      width: 228px;
      padding: 0px 1px;

      ${mq.mobile(css`
        width: 100%;
      `)}
    `,

    icon: css`
      & > svg {
        width: 40px;
        height: 40px;
      }

      & > svg > path {
        fill: ${theme.colors.accent.normal};
      }
    `,

    title: css`
      font-size: ${typography.h6};
      font-weight: bold;

      margin-bottom: 8px;
    `,

    description: css`
      font-size: ${typography.p};
    `,
  },
})

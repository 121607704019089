import { css, Theme } from '@emotion/react'
import { helpers } from '@/styles'

export const styles = (theme: Theme) => ({
  controls: {
    main: css`
      display: flex;
      align-items: center;
      justify-content: center;

      height: 100%;

      & > div:not(:last-of-type) {
        margin-right: 24px;
      }
    `,
    repeat: {
      main: css`
        width: 30px;
        height: 30px;
      `,
      button: {
        shared: css`
          ${helpers.resetDefaults};

          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 100%;

          cursor: pointer;

          & > svg {
            width: 24px;
            height: 24px;
          }
        `,
        active: css`
          & > svg {
            & path {
              fill: ${theme.colors.accent.normal};
            }
          }
        `,
        inactive: css`
          & > svg {
            & path {
              fill: ${theme.colors.secondary.normal};
            }
          }
        `,
      },
    },
    prev: {
      main: css`
        width: 30px;
        height: 30px;
      `,
      button: css`
        ${helpers.resetDefaults};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        cursor: pointer;

        & > svg {
          width: 24px;
          height: 24px;

          & path {
            transition: fill 0.2s;
            fill: ${theme.colors.secondary.normal};
          }
        }

        &:hover {
          & > svg {
            & path {
              fill: ${theme.colors.secondary.dark};
            }
          }
        }
      `,
    },
    play: {
      main: css`
        width: 30px;
        height: 30px;
      `,
      button: css`
        ${helpers.resetDefaults};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        cursor: pointer;

        & > svg {
          width: 24px;
          height: 24px;

          & path {
            transition: fill 0.2s;
            fill: ${theme.colors.secondary.normal};
          }
        }

        &:hover {
          & > svg {
            & path {
              fill: ${theme.colors.secondary.dark};
            }
          }
        }
      `,
    },
    pause: {
      main: css`
        width: 30px;
        height: 30px;
      `,
      button: css`
        ${helpers.resetDefaults};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        cursor: pointer;

        & > svg {
          width: 24px;
          height: 24px;

          & path {
            transition: fill 0.2s;
            fill: ${theme.colors.secondary.normal};
          }
        }

        &:hover {
          & > svg {
            & path {
              fill: ${theme.colors.secondary.dark};
            }
          }
        }
      `,
    },
    next: {
      main: css`
        width: 30px;
        height: 30px;
      `,
      button: css`
        ${helpers.resetDefaults};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        cursor: pointer;

        & > svg {
          width: 24px;
          height: 24px;

          & path {
            transition: fill 0.2s;
            fill: ${theme.colors.secondary.normal};
          }
        }

        &:hover {
          & > svg {
            & path {
              fill: ${theme.colors.secondary.dark};
            }
          }
        }
      `,
    },
    random: {
      main: css`
        width: 30px;
        height: 30px;
      `,
      button: {
        shared: css`
          ${helpers.resetDefaults};

          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 100%;

          cursor: pointer;

          & > svg {
            width: 24px;
            height: 24px;
          }
        `,
        active: css`
          & > svg {
            & path {
              fill: ${theme.colors.accent.normal};
            }
          }
        `,
        inactive: css`
          & > svg {
            & path {
              fill: ${theme.colors.secondary.normal};
            }
          }
        `,
      },
    },
  },
})

import { css } from '@emotion/react'
import { mq } from '@/styles'

export const styles = () => ({
  tracks: {
    main: css`
      display: flex;

      ${mq.mobile(css`
        flex-direction: column;
      `)};
    `,
  },
})

import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// images
import { icons } from '@/assets'

// styles
import { styles } from './styles'

type CardHeaderMobileProps = {
  onCloseClick: MouseEventHandler<HTMLButtonElement>
  hideClose?: boolean
}

export const CardHeaderMobile: FC<CardHeaderMobileProps> = ({
  onCloseClick,
  hideClose = false,
}) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).header.main}>
      <div />
      {!hideClose && (
        <button
          css={styles(theme).header.menu}
          onClick={(e) => onCloseClick(e)}
          type="button"
          aria-label="close modal"
        >
          <icons.CrossCircle />
        </button>
      )}
    </div>
  )
}

CardHeaderMobile.propTypes = {}

import { FC } from 'react'
import { useTheme } from '@emotion/react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  authCloseSignupModal,
  authOpenLoginModal,
  authLoginWithGoogle,
  authLoginWithFacebook,
  authSelectLoading,
  authSelectError,
} from '@/features'

// components
import { CardHeaderMobile, Error } from '@/components'
import { CardButton, CardDivider, CardTitle, SignupForm } from './components'

// styles
import { styles } from './SignupCard.styles'

type SignupCardProps = {}

export const SignupCard: FC<SignupCardProps> = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const authLoading = useAppSelector(authSelectLoading)
  const authError = useAppSelector(authSelectError)

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile onCloseClick={() => dispatch(authCloseSignupModal())} />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <CardTitle title="Sign up to RYDDM for free" accentStart={11} accentEnd={16} />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* third party signup */}
        <div css={styles(theme).card.content.signup}>
          <CardButton
            onClick={() => dispatch(authLoginWithGoogle())}
            disabled={authLoading}
            idp="google"
            text="Continue with Google"
            aria-label="login with google"
          />
          <CardButton
            onClick={() => dispatch(authLoginWithFacebook())}
            disabled={authLoading}
            idp="facebook"
            text="Continue with Facebook"
            aria-label="login with facebook"
          />
        </div>

        <div css={styles(theme).card.content.divider.top}>
          <CardDivider text="or" />
        </div>

        {/* email password signup */}
        <div css={styles(theme).card.content.form}>
          <SignupForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={authError} />
        </div>

        <div css={styles(theme).card.content.divider.bottom}>
          <CardDivider />
        </div>

        {/* log in */}
        <div css={styles(theme).card.content.login.main}>
          <span css={styles(theme).card.content.login.title}>Already have account?</span>
          <button
            css={styles(theme).card.content.login.button}
            onClick={() => {
              dispatch(authCloseSignupModal())
              dispatch(authOpenLoginModal())
            }}
            type="button"
            aria-label="sign up"
          >
            Log in to RYDDM
          </button>
        </div>
      </div>
    </div>
  )
}

SignupCard.propTypes = {}

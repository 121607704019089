import { FC, MouseEventHandler } from 'react'
import { useTheme } from '@emotion/react'

// styles
import { styles } from './styles'

type CardLoginProps = {
  description: string
  onLoginClick: MouseEventHandler<HTMLButtonElement>
}

export const CardLogin: FC<CardLoginProps> = ({ description, onLoginClick }) => {
  const theme = useTheme()

  return (
    <div css={styles(theme).login.main}>
      <span css={styles(theme).login.text}>{description}</span>
      <button
        css={styles(theme).login.button}
        onClick={(e) => onLoginClick(e)}
        type="button"
        aria-label="log in"
      >
        Log in
      </button>
    </div>
  )
}

CardLogin.propTypes = {}

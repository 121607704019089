export const trackUtilGetTrackTileSkeletonSections = (sectionName: string) => [
  {
    name: `${sectionName}-media`,
    width: '180px',
    height: '180px',
    isStatic: true,
    isDivider: false,
  },
  {
    name: `${sectionName}-divider`,
    width: '180px',
    height: '10px',
    isStatic: true,
    isDivider: true,
  },
  {
    name: `${sectionName}-description`,
    width: '180px',
    height: '38px',
    isStatic: true,
    isDivider: false,
  },
]

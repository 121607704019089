import { FC } from 'react'
import { useTheme } from '@emotion/react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import {
  authCloseLoginModal,
  authOpenSignupModal,
  authOpenResetModal,
  authLoginWithGoogle,
  authLoginWithFacebook,
  authSelectLoading,
  authSelectError,
} from '@/features'

// components
import { CardHeaderMobile, Error } from '@/components'
import { CardButton, CardDivider, CardTitle, LoginForm } from './components'

// styles
import { styles } from './LoginCard.styles'

type LoginCardProps = {}

export const LoginCard: FC<LoginCardProps> = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const authLoading = useAppSelector(authSelectLoading)
  const authError = useAppSelector(authSelectError)

  return (
    <div
      css={styles(theme).card.main}
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      {/* mobile header */}
      <div css={styles(theme).card.header}>
        <CardHeaderMobile onCloseClick={() => dispatch(authCloseLoginModal())} />
      </div>

      {/* title */}
      <div css={styles(theme).card.title}>
        <CardTitle title="Log in to RYDDM" accentStart={10} accentEnd={15} />
      </div>

      <div css={styles(theme).card.content.main}>
        {/* third party login */}
        <div css={styles(theme).card.content.login}>
          <CardButton
            onClick={() => dispatch(authLoginWithGoogle())}
            disabled={authLoading}
            idp="google"
            text="Continue with Google"
            aria-label="login with google"
          />
          <CardButton
            onClick={() => dispatch(authLoginWithFacebook())}
            disabled={authLoading}
            idp="facebook"
            text="Continue with Facebook"
            aria-label="login with facebook"
          />
        </div>

        <div css={styles(theme).card.content.divider.top}>
          <CardDivider text="or" />
        </div>

        {/* email password login */}
        <div css={styles(theme).card.content.form}>
          <LoginForm />
        </div>

        <div css={styles(theme).card.content.error}>
          <Error error={authError} />
        </div>

        {/* reset password */}
        <div css={styles(theme).card.content.reset.main}>
          <button
            css={styles(theme).card.content.reset.button}
            onClick={() => {
              dispatch(authCloseLoginModal())
              dispatch(authOpenResetModal())
            }}
            type="button"
            aria-label="reset password"
          >
            Forgot your password
          </button>
        </div>

        <div css={styles(theme).card.content.divider.bottom}>
          <CardDivider />
        </div>

        {/* sign up */}
        <div css={styles(theme).card.content.signup.main}>
          <span css={styles(theme).card.content.signup.title}>Dont have an account?</span>
          <button
            css={styles(theme).card.content.signup.button}
            onClick={() => {
              dispatch(authCloseLoginModal())
              dispatch(authOpenSignupModal())
            }}
            type="button"
            aria-label="sign up"
          >
            Sign up to RYDDM
          </button>
        </div>
      </div>
    </div>
  )
}

LoginCard.propTypes = {}

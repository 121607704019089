import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// hooks
import { useAppSelector, useAppDispatch } from '@/hooks'
// features
import { authSelectProfile, playerAudioSetClosed } from '@/features'
// icons
import { logos } from '@/assets'

// utils
import { getNavLinks } from './utils'
// components
import { NavLink, NavFooter } from './components'
// styles
import { styles } from './NavigationApp.styles'

type NavigationAppProps = {}

export const NavigationApp: FC<NavigationAppProps> = () => {
  const theme = useTheme()
  const { pathname } = useLocation()

  const dispatch = useAppDispatch()
  const profile = useAppSelector(authSelectProfile)
  const navLinks = getNavLinks(profile)

  return (
    <div css={styles(theme).navigation.main}>
      {/* navigation logo */}
      <div css={styles(theme).navigation.logo}>
        <logos.RyddmLogo />
      </div>

      {/* navigation links */}
      <nav css={styles(theme).navigation.links}>
        <ul>
          {navLinks.map(({ name, path, icon }) => (
            <li key={`${name}-${path}`}>
              <NavLink
                to={path}
                name={name}
                icon={icon}
                onClick={() => dispatch(playerAudioSetClosed())}
                active={pathname === path}
              />
            </li>
          ))}
        </ul>
      </nav>

      {/* navigation footer */}
      <div css={styles(theme).navigation.footer}>
        <NavFooter />
      </div>
    </div>
  )
}

NavigationApp.propTypes = {}
